import React from "react";
import _ from 'lodash';
import Loading from "../Loading";
import HeaderComponent from "../HeaderComponent";
import axios from 'axios';
import "./InstagramToOutreach.css";

// import moment from 'moment';

class InstagramToOutreach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      outreachEmail: [],
      outreachWithoutEmail: [],
    };
    this.downloadCSVOutreach = this.downloadCSVOutreach.bind(this);
  }

  componentDidMount() {
    console.log('componentDidMount - InstagramToOutreach');
    axios({
      method: 'GET',
      url: "/api/v1/insta/outreach_no_email"
    }).then((r) => {
      console.log(r);
      axios({
        method: 'GET',
        url: "/api/v1/insta/outreach_email"
      }).then((r2) => {
        console.log(r2);
        this.setState({
          loadingData: false,
          outreachEmail: r2.data.profils,
          outreachWithoutEmail: r.data.profils
        });
      });
    });
  }

  componentDidUpdate() {
    console.log('componentDidUpdate - InstagramToOutreach');
  }

  downloadCSVOutreach(type) {
    let theData = null;
    let theFileName = null;
    if (type === 'email') {
      theData = this.state.outreachEmail;
      theFileName = 'outreach_instagram_email_' + new Date().toISOString() + '.csv';
    }
    if (type === 'no_email') {
      theData = this.state.outreachWithoutEmail;
      theFileName = 'outreach_instagram_no_email_' + new Date().toISOString() + '.csv';
    }


    let str = '';
    if (theData.length > 0) {
      theData.map((profile, i) => {
        let line = '';

        let theInstagram = 'https://www.instagram.com/' + profile.handle;
        let theYoutube = ''
        if (typeof(profile.youtube) != undefined) {
          theYoutube = 'https://www.youtube.com/channel/' + profile.youtube;
        }
        let theEmail = '';
        if (typeof(profile.email) === 'string' && profile.email.length > 0) {
          theEmail = profile['email'].replace(',', ' |');
        }
        const avgLike = _.meanBy(profile.posts, (p) => p.nbLike).toFixed(2);

        let engagementRate = '';
        if (typeof(profile.engagementRate) === 'number') {
          engagementRate = profile.engagementRate.toFixed(2);
        }
        line += theInstagram + "," + theYoutube + "," + theEmail + "," + avgLike + "," + engagementRate;
        str += line + '\r\n';
      });
    }

    let contentType = "text/csv;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(str))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, theFileName);
    } else {
      var a = document.createElement('a');
      a.download = theFileName;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(str);
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  render() {
    const { loadingData, outreachEmail, outreachWithoutEmail } = this.state;
    return (
      <div className="instagram_container">
        <HeaderComponent title="To Outreach" to="/a/dashboard/instagram" toTitle="Instagram" />
        <div className="instagram_yesno_content">
        {(loadingData) ? (
          <Loading />
        ) : (
          <div className="instascrap__content__outreach__container">
            <div className="outreach__container__content">
              <p>Profiles with emails ({outreachEmail.length})</p>
              <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent button--tooutreach" onClick={() => this.downloadCSVOutreach('email')}>List to outreach</button>
            </div>
            <div className="outreach__container__content">
              <p>Profiles without emails ({outreachWithoutEmail.length})</p>
              <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent button--tooutreach" onClick={() => this.downloadCSVOutreach('no_email')}>List to outreach</button>
            </div>
          </div>
        )}
        </div>
      </div>
    );
  }
}

export default InstagramToOutreach;
