import { useState } from "react";
import { format } from "date-fns";
import { getDifferenceInDays } from "utils";

import { ReactComponent as CalendarIcon } from "assets/svgs/calendar.svg";
import FollowupPicker from "../FollowupPicker";

import "./FollowUpDate.css";

function getTextFromNow(date) {
  const numberOfDays = getDifferenceInDays(date);

  if (numberOfDays > 0) {
    return `In ${numberOfDays} day${numberOfDays === 1 ? "" : "s"}`;
  }
  if (numberOfDays < 0) {
    return `${numberOfDays * -1} day${numberOfDays * -1 === 1 ? "" : "s"} late`;
  }
  return "Today";
}

function FollowUpDate({ date, dealId, onFollowupDateUpdate }) {
  const [isFollowupPickerOpen, setIsFollowupPickerOpen] = useState(false);

  const numberOfDays = getDifferenceInDays(date);

  let textColor = "#FFD84D";
  if (numberOfDays >= 2) {
    textColor = "#43A047";
  }
  if (numberOfDays < 0) {
    textColor = "#E53935";
  }

  return (
    <div className="follow_up_date">
      <div className="follow_up_date_left">
        <span>{date ? format(new Date(date), "yyyy-MM-dd") : ""}</span>
        <span style={{ color: textColor }}>
          {date ? getTextFromNow(date) : ""}
        </span>
      </div>

      <button onClick={() => setIsFollowupPickerOpen(!isFollowupPickerOpen)}>
        <CalendarIcon width="20" />
      </button>

      <FollowupPicker
        open={isFollowupPickerOpen}
        dealId={dealId}
        onFollowupSelected={onFollowupDateUpdate}
      />
    </div>
  );
}

export default FollowUpDate;
