import React, { useEffect, useState } from "react";
import { ALContainer, ALButton } from "../ALComponents";
import Loading from "../Loading";
import "./ModalOutreachPodcast.css";

const PodcastTile = (props) => {
  const influencer = props.influencer;
  const podcast = props.influencer.podcast.found;
  return (
    <div key={podcast.name} className="podcast-tile">
      {influencer._id ? (
        <a
          className="podcast-tile__profile-link"
          href={`/a/dashboard/outreach?type=id&value=${influencer._id}`}
          title="Open Outreach profile"
          target="_blank">
          <span className="material-icons">launch</span>
        </a>
      ) : null}
      <p className="podcast-tile__name">{podcast.name}</p>
      <div className="podcast-tile__podcast-info">
        <div className="podcast-tile__url-list">
          {podcast.broadcasters.map((broadcaster, i) => (
            <React.Fragment key={i}>
              {i > 0 ? <span key={"span_" + i}>, </span> : null}
              <a key={i} className="podcast-tile__url" href={broadcaster.url} target="_blank" rel="noopener noreferrer">
                {broadcaster.name} profile
              </a>
            </React.Fragment>
          ))}
        </div>
        <div className="podcast-tile__email-list">
          {influencer.emails?.map((email, i) => (
            <React.Fragment key={i}>
              {i > 0 ? <span key={"span_" + i}>, </span> : null}
              <p key={email} className="podcast-tile__email-list__email">
                {email}
              </p>
            </React.Fragment>
          ))}
        </div>
        <div className="podcast-tile__network-list">
          {podcast.networks?.map((network, i) => (
            <React.Fragment key={i}>
              {i > 0 ? <span key={"span_" + i}>, </span> : null}
              <p key={network} className="podcast-tile__network-list__network">
                {network}
              </p>
            </React.Fragment>
          ))}
        </div>
      </div>
      {!influencer._id ? (
        <div className="podcast-tile__not-linked">
          <p className="podcast-tile__not-linked__description">
            If this is the correct podcast and you would like to add an influencer, click the link below
          </p>
          <p className="podcast-tile__not-linked__action" onClick={() => props.resume(podcast)}>
            Add this podcast to the influencer
          </p>
        </div>
      ) : null}
    </div>
  );
};

const ModalOutreachPodcast = (props) => {
  const [matchedPodcasts, setMatchedPodcasts] = useState([]);
  const [isLoadingPodcasts, setIsLoadingPodcasts] = useState(true);

  useEffect(() => {
    props.loadMatchingPodcasts().then((res) => {
      setMatchedPodcasts(res);
      setIsLoadingPodcasts(false);
    });
  }, []);

  return (
    <ALContainer className="modal-outreach-podcast">
      <div className="modal-outreach-podcast__close" onClick={() => props.resume()}>
        <span className="material-icons">close</span>
      </div>
      {isLoadingPodcasts ? (
        <Loading />
      ) : (
        <>
          <p className="modal-outreach-podcast__info-text">Existing podcast that match the information</p>
          <div className="modal-outreach-podcast__scrolling-container">
            <div className="modal-outreach-podcast__list">
              {matchedPodcasts.map((influencerObject, i) => (
                <PodcastTile key={i} influencer={influencerObject} resume={props.resume} />
              ))}
            </div>
            <ALButton onClick={() => props.resume("new")}>PODCAST NOT LISTED ABOVE</ALButton>
          </div>
        </>
      )}
    </ALContainer>
  );
};

export default ModalOutreachPodcast;
