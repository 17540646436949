import React from "react";
import moment from 'moment';
import axios from 'axios';

import { ALDropdown } from "../ALComponents";
import "./HistoryDeals.css";
import Loading from "../Loading";
import Tags from "components/Tags";

class HistoryDeals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deals: this.props.deals,
      tmpNote: null,
      tagNames: {}
    };
  }
 
  componentDidMount() {
    const { deals } = this.state;
    let _this = this;
    if (deals && deals.length > 0) {
      deals.forEach(async (deal, i) => {
        deal.content.forEach((c, j) => {
          // Get Data YouTube Video
          if (c.link && c.link.includes("https://www.youtube.com/watch")) {
            let videoId = c.link.replace("https://www.youtube.com/watch?v=", "");
            axios.get("/api/v1/aws/video/" + videoId).then((r) => {
              // override state
              let tmpDeals = [...this.state.deals];
              let tmpDeal = tmpDeals[i];
              let tmpContent = tmpDeal["content"][j];
              tmpContent["videoData"] = {};
              if (r.data.message !== "err") {
                tmpContent["videoData"] = r.data.result;
              }
              _this.setState({ deals: tmpDeals });
            })
          }
          // Get Data Instagram Post
          if (c.link && c.link.includes("https://www.instagram.com/p")) {
            let postId = c.link.replace("https://www.instagram.com/p/", "").replace("/", "");
            axios.get("/api/v1/aws/post/" + postId).then((r) => {
              // override state
              let tmpDeals = [...this.state.deals];
              let tmpDeal = tmpDeals[i];
              let tmpContent = tmpDeal["content"][j];
              tmpContent["postData"] = {};
              if (r.data.message !== "err") {
                tmpContent["postData"] = r.data.result;
              }
              _this.setState({ deals: tmpDeals });
            })
          }
        })

        // Get deal tag names
        const tagNames = await this.getTags(deal);

        let tmpDeals = [...this.state.deals];
        let tmpDeal = tmpDeals[i];
        tmpDeal["tagNames"] = tagNames;
        _this.setState({ deals: tmpDeals });
      });
      
    }
  }

  componentDidUpdate(_prevProps, _prevState) {
    if (this.props.deals !== _prevProps.deals) {
      this.setState({ deals: this.props.deals });
    }
  }

  async getTags(deal) {
    if (deal.tags.length > 0) {
      let tagNames = [];
      for (let tag of deal.tags) {
        const res = await axios.get(`/api/v1/deal_tags/id/${tag}`);
        tagNames.push(res.data.result.name);
      }
      return tagNames;
    }
    return [];
  }

  getRate({price, initial_price}) {
    return parseInt(initial_price) && (parseInt(price) || price === 0)
      ? (((price - initial_price) / initial_price) * 100).toFixed(2) + '%'
      : '-'
  }

  render() {
    const { deals } = this.state;
    const listStatus = [{
      "title": "negociation",
      "id": "negociation"
    }, {
      "title": "hold",
      "id": "hold"
    }, {
      "title": "details",
      "id": "details"
    }, {
      "title": "done",
      "id": "done"
    }, {
      "title": "published",
      "id": "published"
    }, {
      "title": "adam",
      "id": "adam"
    }, {
      "title": "adam_yes",
      "id": "adam_yes"
    }, {
      "title": "adam_no",
      "id": "adam_no"
    }, {
      "title": "declined_outreach",
      "id": "declined_outreach"
    }, {
      "title": "created",
      "id": "created"
    }, {
      "title": "call",
      "id": "call"
    }, {
      "title": "lead",
      "id": "lead"
    }];

    return (
      <div className="oi_history_deals">
        {(deals.length > 0) ? (
          deals.map((deal, i) => {
            return (
              <div className="oi_history_deals_deal" key={`deal_${i}`}>
                <p className="oi_history_deals_deal__title">
                  {(deal.name) ? (deal.name) : (`Deal n*${i+1}`)}
                  {(deal.date_sign) ? (
                    <span className="oi_history_deals_deal__title_date">{moment(deal.date_sign).format("MM/DD/YYYY")} ({moment(deal.date_sign).from(moment())})</span>
                  ) : (
                    (deal.date_last_outreach) ? (
                      <span className="oi_history_deals_deal__title_date">{moment(deal.date_last_outreach).format("MM/DD/YYYY")} ({moment(deal.date_last_outreach).from(moment())})</span>
                    ) : (null)
                  )}
                </p>
                <p className="oi_history_deals_deal__am">
                  <span className="material-icons">account_circle</span>
                  {deal.am}
                </p>
                <div className="oi_history_deal_content">
                  <div className="oi_history_deal_content_prices">
                  <div className="oi_history_deal_content_prices_price">
                      <p className="oi_history_deal_content_prices_price--title oi_history_deal_content--subtitle">Initial Price</p>
                      <p className="oi_history_deal_content_prices_price--value">
                        {deal.initial_price === null ? '-' : deal.initial_price}
                      </p>
                    </div>
                    <div className="oi_history_deal_content_prices_price">
                      <p className="oi_history_deal_content_prices_price--title oi_history_deal_content--subtitle">Final Price</p>
                      <p className="oi_history_deal_content_prices_price--value">
                        {deal.price === null ? '-' : deal.price}
                      </p>
                    </div>
                    <div className="oi_history_deal_content_prices_price">
                      <p className="oi_history_deal_content_prices_price--title oi_history_deal_content--subtitle">Payment Status</p>
                      <p className="oi_history_deal_content_prices_price--value">
                        {`${deal.payment_status}%`}
                      </p>
                    </div>
                    <div className="oi_history_deal_content_prices_price">
                      <p className="oi_history_deal_content_prices_price--title oi_history_deal_content--subtitle">Rate Negotiated</p>
                      <p className="oi_history_deal_content_prices_price--value">
                        {this.getRate(deal)}
                      </p>
                    </div>
                  </div>
                  <div className="oi_history_deal_content_dates">
                    <div className="oi_history_deal_content_dates_date">
                      <p className="oi_history_deal_content_dates_date--title oi_history_deal_content--subtitle">Date Sign</p>
                      <p className="oi_history_deal_content_dates_date--value">{(deal.date_sign) ? moment(deal.date_sign).format("MM/DD/YYYY") : null}</p>
                    </div>
                    <div className="oi_history_deal_content_dates_date">
                      <p className="oi_history_deal_content_dates_date--title oi_history_deal_content--subtitle">Last Follow Up</p>
                      <p className="oi_history_deal_content_dates_date--value">{(deal.date_follow_up) ? moment(deal.date_follow_up).format("MM/DD/YYYY") : null}</p>
                    </div>
                  </div>
                  <div className="oi_history_deal_content_status">
                  <p className="oi_history_deal_content--subtitle">Status</p>
                    <ALDropdown
                      options={listStatus}
                      useValue={'title'}
                      useKey={'id'}
                      value={deal.status}
                      selectedKey={deal.status}
                      onChange={(key) => {
                        if (key === deal.status) return;
                        this.props.updateStatusInf(deal._id, key, deal.status);
                      }}
                    />
                    {deal.tagNames && (
                      <Tags tags={deal.tagNames} disableRemove={true} />
                    )}
                  </div>
                  <div className="oi_history_deal_content_list">
                    <p className="oi_history_deal_content_list--title">Content</p>
                    {deal.content.map((content, j) => {
                      let strDuration = "";
                      if (content && content.videoData) {
                        if (typeof(content.videoData) != "string") {
                          let durationVideo = moment.duration(parseInt(content.videoData.duration), 'seconds');
                          if (durationVideo.hours() === 0) {
                            let secDuration = durationVideo.seconds();
                            if (durationVideo.seconds() < 10) {
                              secDuration = "0" + secDuration;
                            }
                            strDuration = durationVideo.minutes() + ":" + secDuration;
                          } else {
                            strDuration = durationVideo.hours() + ":" + durationVideo.minutes() + ":" + durationVideo.seconds();
                          }
                        }
                      }
                      return (
                        <div className="oi_history_deal_content_list_content" key={`content_${j}`}>
                          <p className="deal_list_content--type">
                            <span className="deal_list_content--type-value">{content.platform}</span>
                            <span className="deal_list_content--type-value">{content.type}</span>
                          </p>
                          <div className="deal_list_content">
                            <div className="deal_list_content_links">
                              <p className="deal_list_content_links--title oi_history_deal_content--subtitle">Link</p>
                              <a href={content.link} target="_blank" rel="noopener noreferrer" className="deal_list_content_links--value">{content.link}</a>
                            </div>
                            <div className="deal_list_content_links">
                              <p className="deal_list_content_links--title oi_history_deal_content--subtitle">Link AL</p>
                              <a href={content.link_analuisa} target="_blank" rel="noopener noreferrer" className="deal_list_content_links--value">{content.link_analuisa}</a>
                            </div>
                            <div className="deal_list_content_links">
                              <p className="deal_list_content_links--title oi_history_deal_content--subtitle">Date Release</p>
                              <p className="deal_list_content_links--value">{content.date_release ? moment(content.date_release).format("MM/DD/YYYY") : null}</p>
                            </div>
                            <div className="deal_list_content_links">
                              <p className="deal_list_content_links--title oi_history_deal_content--subtitle">CPV</p>
                              <p className="deal_list_content_links--value">
                                {(content.videoData && content.videoData.stats && content.videoData.stats.views) ? (
                                `$${(deal.price / content.videoData.stats.views).toFixed(3)}`
                                ) : (null)}
                              </p>
                            </div>
                            <div className="deal_list_content_links">
                              <p className="deal_list_content_links--title oi_history_deal_content--subtitle">Date Expected</p>
                              <p className="deal_list_content_links--value">{content.date_expected ? moment(content.date_expected).format("MM/DD/YYYY") : null}</p>
                            </div>
                          </div>

                          {(content && content.videoData && Object.keys(content.videoData).length > 0) ? (
                            <div className="deal_list_content_video">
                              <div className="deal_list_content_video_content">
                                <a className="deal_list_content_video_content_link" href={`https://www.youtube.com/watch?v=${content.videoData.videoId}`} target="_blank" rel="noopener noreferrer">
                                  <img className="deal_list_content_video_content_link_thumbnail" src={content.videoData.pictureURL} />
                                  <div className="deal_list_content_video_content_link_duration">{strDuration}</div>
                                </a>
                                <div className="deal_list_content_video_content_details">
                                  <p className="deal_list_content_video_content_details_title">{content.videoData.title}</p>
                                  <div className="deal_list_content_video_content_details_views">
                                    <p className="deal_list_content_video_content_details_views_date">{moment(content.videoData.datePublished).from(moment())}</p>
                                    <p className="deal_list_content_video_content_details_views_views">{(content.videoData && content.videoData.stats && content.videoData.stats.views) ? (content.videoData.stats.views) : (null)}</p>
                                  </div>
                                  {(content.videoData.stats && content.videoData.stats.likes) ? (
                                    <div className="deal_list_content_video_content_details_thumb--up">
                                      <span className="material-icons">thumb_up</span>
                                      {content.videoData.stats.likes}
                                    </div>
                                  ) : (
                                    null
                                  )}
                                  {(content.videoData.stats && content.videoData.stats.dislikes) ? (
                                    <div className="deal_list_content_video_content_details_thumb--down">
                                      <span className="material-icons">thumb_down</span>
                                      {content.videoData.stats.dislikes}
                                    </div>
                                  ) : (
                                    null
                                  )}
                                </div>
                              </div>
                              <div className="deal_list_content_video_description">
                                {content.videoData.description}
                              </div>
                            </div>
                          ) : (
                            (content && content.link && content.link.includes("youtube")) ? (
                              <div className="deal_list_content_video">
                                {(content && content.videoData && Object.keys(content.videoData).length === 0) ? (
                                  <div className="deal_list_content_video_loading">
                                    <p>Error loading video details... (bad url formating / post do not exist anymore)</p>
                                  </div>
                                ) : (
                                  <div className="deal_list_content_video_loading">
                                    <Loading />
                                    <p>Loading video details</p>
                                  </div>
                                )}
                              </div>
                            ) : (null)
                          )}

                          {(content && content.postData && Object.keys(content.postData).length > 0) ? (
                            <div className="deal_list_content_post">
                              <div className="deal_list_content_post_content">
                                <a className="deal_list_content_post_content_link" href={`https://www.instagram.com/p/${content.postData.postId}`} target="_blank" rel="noopener noreferrer">
                                  <img className="deal_list_content_post_content_link_thumbnail" src={content.postData.contentURLs[0]} />
                                </a>
                              </div>
                              <div className="deal_list_content_post_description">
                                <p className="deal_list_content_post_description--content">{content.postData.description}</p>
                                <div className="deal_list_content_post_description_details">
                                  <p className="deal_list_content_post_description_details--likes">
                                    <span className="material-icons description_details--likes-icon">favorite</span>
                                    {content.postData.stats.likes} likes</p>
                                  <p className="deal_list_content_post_description_details--comments">
                                    <span className="material-icons description_details--comments-icon">mode_comment</span>
                                    {content.postData.stats.comments} comments
                                  </p>
                                  <p className="deal_list_content_post_description_details--date">{moment(content.postData.date).format("MMMM DD, YYYY")} ({moment(content.postData.date).fromNow()})</p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            (content && content.link && content.link.includes("instagram")) ? (
                              <div className="deal_list_content_post">
                                {(content && content.postData && Object.keys(content.postData).length === 0) ? (
                                  <div className="deal_list_content_post_loading">
                                    <p>Error loading post details... (bad url formating?)</p>
                                  </div>
                                ) : (
                                  <div className="deal_list_content_post_loading">
                                    <Loading />
                                    <p>Loading post details</p>
                                  </div>
                                )}
                              </div>
                            ) : (null)
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {/* DEAL INFO */}
                  <div className="deal_identifier">
                    <span className="deal_identifier_title">Deal Unique Identifier:</span>
                    <span className="deal_identifier_content">{deal?._id}</span>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div>NO HISTORY DEALS</div>
        )}
      </div>
    );
  }
}

export default HistoryDeals;
