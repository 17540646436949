import React from "react";
import "./ModalEmbededVideo.css";
import { ALLoading } from "components/ALComponents";

function ModalEmbededVideo({ video, closeModal, isLoadingFullData, style }) {
  if (!video) {
    return null;
  }
  return (
    <div className="modal_video_container" style={style || null}>
      <div className="modal_video_container--close" onClick={closeModal}>
        <span className="material-icons">close</span>
      </div>
      {isLoadingFullData && (
        <div className="modal_video_container__loading">
          <ALLoading alt text="Loading full video data..." />
        </div>
      )}
      <div className="modal_video_container__content">
        <iframe
          title={`modal_video_container__content_${video["videoId"]}`}
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${video["videoId"]}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
      <p className="modal_video_container__title">{video.title}</p>
      <div className="modal_video_container__data">
        <span className="modal_video_container__data__views">
          <i className="material-icons">visibility</i>
          {video?.stats?.views || "0"} views
        </span>
        <div className="modal_video_container__data__thumbs">
          <span className="modal_video_container__data__thumbs__likes">
            <i className="material-icons">thumb_up</i>
            {(video?.stats?.likes === undefined && isLoadingFullData && <ALLoading alt />) || video?.stats?.likes || "0"}
          </span>
          <span className="modal_video_container__data__thumbs__dislikes">
            <i className="material-icons">thumb_down</i>
            {(video?.stats?.dislikes === undefined && isLoadingFullData && <ALLoading alt />) || video?.stats?.dislikes || "0"}
          </span>
        </div>
      </div>
      <div className="modal_video_container__data__description">
        <p className="modal_video_container__data__description__title">Description:</p>
        {video.description
          ? video.description.split("\n").map((i, key) => {
              return <div key={key}>{i}</div>;
            })
          : "No description available"}
        {isLoadingFullData && <ALLoading alt />}
      </div>
    </div>
  );
}

export default ModalEmbededVideo;
