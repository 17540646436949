import React from "react";
import axios from "axios";
import { ALTable } from "components/ALComponents";
import { formatISO } from "date-fns";
import { useEffect, useState } from "react";

import "./Transaction.css";

async function getTransactions(transactionId = "") {
  try {
    let params = {};
    transactionId = transactionId.trim();
    if (transactionId) {
      params._id = `"${transactionId}"`;
    }
    const { data } = await axios.get("/api/v1/transactions", { params });
    return data.result || [];
  } catch (err) {
    console.error(err);
    alert(err);
    throw err;
  }
}

const TransactionSearchInput = ({ setSearchValue }) => {
  const [inputBuffer, setInputBuffer] = useState("");

  const handleOnChange = (e) => setInputBuffer(e.target.value);
  const handleOnKeyPressed = (e) => {
    if (e.key === "Enter") {
      setSearchValue(inputBuffer);
    }
  };
  const handleOnClickSearchButton = () => {
    setSearchValue(inputBuffer);
  };
  const handleOnClickRemoveSearch = () => {
    setInputBuffer("");
    setSearchValue("");
  };

  return (
    <div className="transaction-search-input">
      <div className="transaction-search-input-wrapper">
        <input type="text" placeholder="Search by transaction ID" value={inputBuffer} onChange={handleOnChange} onKeyUp={handleOnKeyPressed} />
        <button
          className="transaction-search-input__btn-remove-search"
          style={{ display: inputBuffer ? "block" : "none" }}
          onClick={handleOnClickRemoveSearch}
        >
          <span className="material-icons">close</span>
        </button>
      </div>
      <button className="transaction-search-input__btn-search" onClick={handleOnClickSearchButton}>
        <span className="material-icons">search</span>
      </button>
    </div>
  );
};

const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [transactionsDataArray, setTransactionsDataArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getTransactions(searchValue).then(setTransactions);
  }, [searchValue]);

  useEffect(() => {
    setTransactionsDataArray(
      transactions.map((transaction) => [
        <span className={`transaction-status--${transaction.status} material-icons`} title={transaction.status}>
          circle
        </span>,
        `${transaction.currency === "USD" ? "$" : transaction.currency + " "}${(transaction.amount / 100).toFixed(2)}`,
        transaction.method,
        transaction.batch_id,
        transaction._id,
        formatISO(new Date(transaction.last_modification_date), { representation: "date" }),
        <a className="transaction-actions" href={`/a/dashboard/outreach?type=dealId&value=${transaction.deal_id}`} target="_blank" rel="noopener noreferrer" title="Open Influencer in Outreach">
         <span className="material-icons">launch</span>
        </a>,
      ])
    );
  }, [transactions]);

  return (
    <div className="transaction">
      <TransactionSearchInput setSearchValue={setSearchValue} />
      <h2>{searchValue ? "Search result" : "Last 300 transactions"}</h2>
      {transactionsDataArray.length > 0 ? (
        <ALTable
          header={["Status", "Amount", "Method", "Batch Id", "Transaction Id", "Last Update", ""]}
          content={transactionsDataArray}
          column_style={[{ width: "3%" }, { width: "7%" }, { width: "8%" }, { width: "8%" }, { width: "15%" }, { width: "8%" }, { width: "4%" }]}
        />
      ) : null}
    </div>
  );
};

export default Transaction;
