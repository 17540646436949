import React from "react";
import "./ModalHowToInventoryPlanning.css";
import { ALButton, ALContainer } from "./ALComponents";

const ModalHead = (props) => (
  <div className="modal-head">
    <div className="modal-head__line">
      <h1 className="modal-head__line__title">How to</h1>
      <div className="modal-head__line__button">
        <ALButton type="text" material_icon="clear" onClick={props.close} />
      </div>
    </div>
    <hr className="modal-head__separator" />
  </div>
);

const ModalFoot = (props) => (
  <div className="modal-foot">
    <ALButton type="text" material_icon="clear" onClick={props.close}>Back</ALButton>
  </div>
)

class ModalHowToInventoryPlanning extends React.Component {
  render() {
    return (
      <ALContainer className="howto_inventory">
        <ModalHead close={() => this.props.resume()} />
        <div>
          <div className="inventory_content">
            <p className="inventory_content--title">Data production file, where to find it?</p>
            <div className="inventory_content__details">
              Export data from <a href="https://docs.google.com/spreadsheets/d/1iIEQTY3SBksmX1xuOBWkltGn8kzgH96wusQdGhcwcVw/edit#gid=91256202" rel="noopener noreferrer" target="_blank">PRODUCTION MASTER_AL</a> on the sheet called "Jewelry Orders 🚀"
              <br/>
              Click on <b>File</b> > <b>Download</b> > <b>Comma-separated values (.csv, current sheet)</b>
            </div>
          </div>
          <div className="inventory_content">
            <p className="inventory_content--title">Data inventory file, where to find it?</p>
            <div className="inventory_content__details">
              Export data from <a href="https://analuisaparis.myshopify.com/admin/reports/month_end_inventory_snapshot?since=-1m&until=-1m" rel="noopener noreferrer" target="_blank">SHOPIFY</a>
              <br/>
              From <a href="https://analuisaparis.myshopify.com/" target="_blank">Shopify admin</a>
              <br/>
              On the left panel go to <b>Report</b>, on the category called <b>Inventory</b>, click on <b>Show all</b> > <b>Month-end inventory snapshot</b>
              <br/>
              Select the Month-end inventory, then click on <b>Export</b>
            </div>
          </div>

          <div className="inventory_content">
            <p className="inventory_content--title">Data velocity file, where to find it?</p>
            <div className="inventory_content__details">
              Follow the step from <a href="https://analuisa-data.herokuapp.com/" rel="noopener noreferrer" target="_blank">our online data dashboard</a>
              <br/>
              <b>Enter the password</b> (if you don't know the password ask @francois) > <b>Navigation (left panel)</b> > <b>Inventory</b>
              <br/>
              Follow the steps from this page
            </div>
          </div>
          <ModalFoot close={() => this.props.resume()} />
        </div>
      </ALContainer>
    );
  }
}

export default ModalHowToInventoryPlanning;
