import React from "react";
import Loading from "./Loading";
import axios from 'axios';
import moment from 'moment';
import _ from "lodash";
import './InfluencerComponent.css';

class InfluencerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      data: null,
    };
  }
  componentDidMount() {
    axios({
      method: 'GET',
      url: '/api/v1/influencer_report',
      headers: {
        sheetId: this.props.user.sheetId
      },
    }).then((r) => {
      this.setState({ data: r.data.data });
    });
  }

  render() {
    const { loadingData, data } = this.state;
    const formatterMoney = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return (
      <div className="influencer_container">
        <p className="influencer_container--title">Rozzah Report</p>
        {(data) ? (
          <div className="influencer__report">
            <div className="influencer__report__row influencer__report__row--header">
              <p className="influencer__report__row--title">Product title</p>
              <p className="influencer__report__row--start">Start Date</p>
              <p className="influencer__report__row--end">End Date</p>
              <p className="influencer__report__row--qty">Net quantity</p>
              <p className="influencer__report__row--gross">Gross sales</p>
              <p className="influencer__report__row--discount">Discount</p>
              <p className="influencer__report__row--returns">Returns</p>
              <p className="influencer__report__row--sales">Net sales</p>
              <p className="influencer__report__row--taxes">Tax</p>
              <p className="influencer__report__row--total">Total sales</p>
            </div>

            {(data.dataTotal) ? (
              <div className="influencer__report__row">
                <p className="influencer__report__row--title">Summary</p>
                <p className="influencer__report__row--start">&nbsp;</p>
                <p className="influencer__report__row--end">&nbsp;</p>
                <p className="influencer__report__row--qty">{data.dataTotal.net_quantity}</p>
                <p className="influencer__report__row--gross">{formatterMoney.format(data.dataTotal.gross_sales.toFixed(2))}</p>
                <p className="influencer__report__row--discount">{formatterMoney.format(data.dataTotal.discounts.toFixed(2))}</p>
                <p className="influencer__report__row--returns">{formatterMoney.format(data.dataTotal.returns.toFixed(2))}</p>
                <p className="influencer__report__row--sales">{formatterMoney.format(data.dataTotal.net_sales.toFixed(2))}</p>
                <p className="influencer__report__row--taxes">{formatterMoney.format(data.dataTotal.taxes.toFixed(2))}</p>
                <p className="influencer__report__row--total">{formatterMoney.format(data.dataTotal.total_sales.toFixed(2))}</p>
              </div>
            ) : (null)}
            {(data.finalBreakdown) ? (
              data.finalBreakdown.map((week, i) => {
                return (
                  <div key={`report__row__${i}`} className="influencer__report__row">
                    <p className="influencer__report__row--title">Moon Necklace - Rozzah</p>
                    <p className="influencer__report__row--start">{moment(week.startDate).format("MMM Do")}</p>
                    <p className="influencer__report__row--end">{moment(week.endDate).format("MMM Do")}</p>
                    <p className="influencer__report__row--qty">{week.net_quantity}</p>
                    <p className="influencer__report__row--gross">{formatterMoney.format(week.gross_sales.toFixed(2))}</p>
                    <p className="influencer__report__row--discount">{formatterMoney.format(week.discounts.toFixed(2))}</p>
                    <p className="influencer__report__row--returns">{formatterMoney.format(week.returns.toFixed(2))}</p>
                    <p className="influencer__report__row--sales">{formatterMoney.format(week.net_sales.toFixed(2))}</p>
                    <p className="influencer__report__row--taxes">{formatterMoney.format(week.taxes.toFixed(2))}</p>
                    <p className="influencer__report__row--total">{formatterMoney.format(week.total_sales.toFixed(2))}</p>
                  </div>
                )
              })
            ) : (
              null
            )}
          </div>
        ) : (
          <Loading />
          )}
      </div>
    );
  }
}

export default InfluencerComponent;