import { useState, useEffect } from "react";
import { getAccountManagers } from "utils";
import { ALButton, ALDropdown } from "components/ALComponents";

import "./AMSelector.css";

function AMSelector({ user, currentUser, setCurrentUser }) {
  const [allAMs, setAllAMs] = useState([]);
  const isPreviewing = user.email !== currentUser.email;

  useEffect(() => {
    getAccountManagers().then((ams) =>
      setAllAMs([
        ...ams,
        {
          ...user,
          id: "kalvin@analuisany.com",
          email: "kalvin@analuisany.com",
          title: "Oklos Test (Catalin)",
        },
      ])
    );
  }, [user]);

  return (
    <div
      className="am-selector"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <p>Preview as:</p>
      <ALDropdown
        value={currentUser?.email}
        onChange={(email) =>
          setCurrentUser(allAMs.find((am) => am.id === email))
        }
        options={allAMs}
        useValue={"title"}
        useKey={"id"}
      />
      <br />
      <ALButton
        onClick={() => isPreviewing && setCurrentUser(null)}
        disable={!isPreviewing}
      >
        Clear
      </ALButton>
    </div>
  );
}

export default AMSelector;
