import React from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { formatISO, add } from "date-fns";
import filter from "lodash/filter";

import { ALDropdown, ErrorBoundaryContent, ALButton } from "components/ALComponents";
import { getAccountManagers, isDealActive, triggerStockTrackerDealUpdate } from "utils";
import OutreachHeader from "./OutreachHeader";
import "./OutreachBeta.css";
import CurrentDeal from "./CurrentDeal";
import HistoryDeals from "./HistoryDeals";
import NotificationManager from "../al_components/notification/NotificationManager";
import Loading from "../Loading";
import { DEAL_STATUSES, GET_TAGS, GET_CAMPAIGN_TAGS } from "dictionaries";
import Emails from "components/Dashboard/Emails";

const specialTags = ["VIP", "VIP-2", "IG-VIP", "TT-VIP", "Cocreation"];

class OutreachBeta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      am_email: null,
      influencers: null,
      searchInfluencer: null,
      isSearchInfluencerTruncated: false,
      searchHistory: JSON.parse(localStorage.getItem("outreach_search")),
      showHistorySearch: false,
      idxSelectedInfluencer: null,
      selectedInfluencer: null,
      tabDeal: "deal_info",
      currentPage: 0, // default
      isLoadingMore: false,
      isTabInfluencerClosed: true,
      account_managers: null,
      isUpdatingInf: false,
      isLoadingSearch: false,
      search: this._initialSearchValues(),
      tags: [],
      campaignTags: [],
    };
    if (this.props.user && this.props.user.email) {
      this.state.am_email = this.props.user.email;
    }

    this._searchInfluencer = this._searchInfluencer.bind(this);
    this.updateStatusInfluencer = this.updateStatusInfluencer.bind(this);
    this.createNewDeal = this.createNewDeal.bind(this);
    this.editInfoInfluencer = this.editInfoInfluencer.bind(this);
    this.removeSearchFromHistory = this.removeSearchFromHistory.bind(this);
    this.updatePlatformInfluencer = this.updatePlatformInfluencer.bind(this);
    this.forceUpdateCurrentInfluencer = this.forceUpdateCurrentInfluencer.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.deleteInfluencerDeal = this.deleteInfluencerDeal.bind(this);
    this.updateInfluencerDeal = this.updateInfluencerDeal.bind(this);
    this.updateRequestedPaymentUpdate = this.updateRequestedPaymentUpdate.bind(this);
    this.newRequestedPayment = this.newRequestedPayment.bind(this);
    this.deleteTransaction = this.deleteTransaction.bind(this);
    this.canAddNewDeal = this.canAddNewDeal.bind(this);

    this._onFocusSearch = this._onFocusSearch.bind(this);
    this._onBlurSearch = this._onBlurSearch.bind(this);
  }

  _initialSearchValues() {
    return {
      dealStatus: [],
      tags: [],
      campaigns: [],
      ams: [],
      value: null,
      type: null,
    };
  }

  _handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const search = { ...this.state.search, value: (this.state.search.value || "").trim() };
      this.setState({ search: search });
      this._searchInfluencer(search);
    }
  };

  _onFocusSearch() {
    document.addEventListener("keydown", this._handleKeyDown);
  }

  _onBlurSearch() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _retrieveInfluencers(currentUser) {
    axios
    .get("/api/v1/influencers_full_by_deal?", {
      params: {
        "deal_am": '"' + currentUser + '"',
        "deal_status":
          '{"$in":["negociation", "done", "detail", "hold", "call", "lead", "created", "adam", "adam_yes", "adam_no", "shipped", "to_ship", "in_transit", "shipment_received"]}',
        "dealsort_date_last_outreach": '"1"',
      },
    })
    .then((res) => {
      if (res.status === 200) {
        this.setState({
          influencers: res.data.result.sort((influencer1, influencer2) => {
            const lastDeal1 = influencer1.deals[influencer1.deals.length - 1];
            const lastDeal2 = influencer2.deals[influencer2.deals.length - 1];
            if (lastDeal1.am === currentUser && lastDeal1.am === lastDeal2.am) {
              return new Date(lastDeal1.date_follow_up) - new Date(lastDeal2.date_follow_up);
            } else if (lastDeal1.am === currentUser) {
              return -1;
            } else if (lastDeal2.am === currentUser) {
              return 1;
            } else {
              return new Date(lastDeal1.date_follow_up) - new Date(lastDeal2.date_follow_up);
            }
          }),
        });
      }
    });
  }

  componentDidMount() {
    let currentUser = this.props.user.email;
    if (this.props.user && this.props.user.email) {
      this.setState({
        am_email: currentUser,
      });
    }

    const search = new URLSearchParams(this.props.location.search);
    if (search) {
      const stateSearch = Object.keys(this.state.search).reduce((acc, searchOption) => {
        if (search.get(searchOption)) {
          acc[searchOption] = search.get(searchOption);
        }
        return acc;
      }, {});
      if (currentUser === "adam@analuisa.com") {
        stateSearch["type"] = "status";
        stateSearch["value"] = "adam";
      }

      if (stateSearch["type"] === "email") {
        stateSearch["value"] = stateSearch["value"].trim().toLowerCase();
      }

      if (Object.keys(stateSearch).length) {
        !["id", "dealId"].includes(stateSearch["type"]) && this.setState((state) => ({ search: { ...state.search, ...stateSearch } }));
        this._searchInfluencer(stateSearch);
      }
    }

    this._retrieveInfluencers(currentUser);

    // load account managers
    getAccountManagers().then((account_managers) => {
      this.setState({ account_managers });
    });

    GET_TAGS().then((tags) => this.setState({ tags: tags }));
    GET_CAMPAIGN_TAGS().then((campaignTags) => this.setState({ campaignTags: campaignTags }));

    // Scroll to open deal
    if (search.get("type") === "dealId" || search.getAll("dealId").length > 0) {
      setTimeout(() => {
        document
          .querySelector(".outreach_influencer_deal_l")
          ?.closest(".outreach_influencer_deal")
          .scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
      }, 300);
    }
  }

  // Update the influencer deal state
  updateInfluencerDeal(dealId, influencerId, updateData) {
    const influencerIdx = this.state.idxSelectedInfluencer;
  
    this.setState((state) => {
      const { key: infKey } = this._getCurrentListInfluencers();
      if (state[infKey][influencerIdx]._id !== influencerId) return;

      let copyInfluencer = [...state[infKey]];

      copyInfluencer[influencerIdx] = {
        ...copyInfluencer[influencerIdx],
        deals: copyInfluencer[influencerIdx].deals.map((deal) => {
          if (deal._id === dealId) {
            return { ...deal, ...updateData };
          }
          return deal;
        }),
      };
      return { [infKey]: copyInfluencer };
    });
  }

  _updateStateTransaction(influencerId, dealId, transactionId, transaction) {
    this.setState((state) => {
      const { key: infKey } = this._getCurrentListInfluencers();
      const currentInfluencerSelected = state[infKey][state.idxSelectedInfluencer];
      let idxInfluencerToUpdate = state.idxSelectedInfluencer;
      if (currentInfluencerSelected._id !== influencerId) {
        idxInfluencerToUpdate = state[infKey].findIndex((inf) => inf._id === influencerId);
      }
      if (idxInfluencerToUpdate === -1) return;
      let newList = [...state[infKey]];
      const idxDealToUpdate = newList[idxInfluencerToUpdate].deals.findIndex((d) => d._id === dealId);
      if (idxDealToUpdate === -1) return console.error("Weird —> deal not found");
      let dealToUpdate = { ...newList[idxInfluencerToUpdate]["deals"][idxDealToUpdate] };
      dealToUpdate["payment_transactions"] = [...dealToUpdate["payment_transactions"]];
      newList[idxInfluencerToUpdate]["deals"][idxDealToUpdate] = dealToUpdate;
      const idxTransactionToUpdate = dealToUpdate.payment_transactions.findIndex((trans) => trans._id === transactionId);
      if (transaction && idxTransactionToUpdate !== -1) {
        dealToUpdate["payment_transactions"][idxTransactionToUpdate] = transaction;
      } else if (transaction) {
        dealToUpdate["payment_transactions"].push(transaction);
      } else {
        dealToUpdate["payment_transactions"] = dealToUpdate["payment_transactions"].filter((t) => t._id !== transactionId);
      }
      return { [infKey]: newList };
    });
  }

  newRequestedPayment(influencerId, dealId, amount, method) {
    if (this.state.updatingTransaction) return;
    this.setState({ updatingTransaction: true });
    let newTransaction = {
      status: "requested",
      amount: amount,
      method: method,
      deal_id: dealId,
      influencer_id: influencerId,
      created_by: this.props.user.email,
    };
    if (method === "wire") {
      newTransaction["status"] = "processed"
    }
    return axios
      .post(`/api/v1/transactions`, newTransaction)
      .then(({ data }) => {
        if (data.status === "ok") {
          this._updateStateTransaction(influencerId, dealId, data.result._id, data.result);
          if (newTransaction["status"] === "processed") {
            axios.get(`/api/v1/influencers/${influencerId}/deals/${dealId}`)
              .then(({ data }) => {
                this.updateInfluencerDeal(dealId, influencerId, {
                  payment_status: data.result.payment_status
                });
              });
          }
          NotificationManager.success("", "Transaction created!", 1500);
        } else {
          throw new Error("Create transaction failed: " + data.status);
        }
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Failed to create transaction. Please try again after refreshing the page and report this error if it persists.",
          "Transaction create failed",
          6000
        );
      })
      .finally(() => this.setState({ updatingTransaction: false }));
  }

  updateRequestedPaymentUpdate(influencerId, dealId, transactionId, amount) {
    if (this.state.updatingTransaction) return;
    this.setState({ updatingTransaction: true });
    return axios
      .put(`/api/v1/transactions/${transactionId}`, { amount: amount })
      .then(({ data }) => {
        if (data.status === "ok") {
          this._updateStateTransaction(influencerId, dealId, transactionId, data.result);
          NotificationManager.success("", "Transaction updated!", 1500);
        } else {
          throw new Error("Update transaction failed: " + data.status);
        }
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Failed to update transaction. Please try again after refreshing the page and report this error if it persists.",
          "Transaction update failed",
          6000
        );
      })
      .finally(() => this.setState({ updatingTransaction: false }));
  }

  deleteTransaction(influencerId, dealId, transactionId) {
    if (this.state.updatingTransaction) return;
    this.setState({ updatingTransaction: true });
    return axios
      .delete(`/api/v1/transactions/${transactionId}`)
      .then(({ data }) => {
        if (data.status === "ok") {
          this._updateStateTransaction(influencerId, dealId, transactionId, null);
          // For transaction in status "processed" we need to refresh payment_status:
          axios
            .get(`/api/v1/influencers/${influencerId}/deals/${dealId}`)
            .then(({ data }) => {
              this.updateInfluencerDeal(dealId, influencerId, {
                payment_status: data.result.payment_status
              });
            });
          NotificationManager.success("", "Transaction deleted!", 1500);
        } else {
          throw new Error("Delete transaction failed: " + data.status);
        }
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Failed to delete transaction. Please try again after refreshing the page and report this error if it persists.",
          "Transaction delete failed",
          6000
        );
      })
      .finally(() => this.setState({ updatingTransaction: false }));
  }

  updateStatusInfluencer(dealId, status, prevStatus) {
    // UPDATE STATUS FROM OUTREACH
    let _this = this;
    let updateDeal = {
      status: status,
      date_last_outreach: formatISO(new Date()),
      date_follow_up: formatISO(add(new Date(), { days: 3 }))
    };

    if (status === "details") {
      updateDeal["date_follow_up"] = formatISO(new Date());
    }

    axios
      .put(`/api/v1/deals/${dealId}`, updateDeal)
      .then(({ data }) => {
        if (data.status === "ok") {
          axios
            .post("/api/v1/event", {
              type: "UPDATE_STATUS_DEAL",
              user: this.props.user.email,
              sample: {
                object: dealId,
                from: { "status": prevStatus },
                to: { "status": status },
              },
            })
            .catch(
              (err) =>
                console.log(err) ||
                NotificationManager.error("Error", "Failed to fire event UPDATE_STATUS_DEAL send the page log to the tech team", 6000)
            );
          axios.get("/api/v1/influencers_full?_id=" + data.result.id_influencer).then((res) => {
            if (res.status === 200) {
              let inf = this._getCurrentListInfluencers();
              let key = inf["key"];

              let copyInfluencer = [..._this.state[key]];
              copyInfluencer[_this.state.idxSelectedInfluencer] = res.data.result[0];

              _this.setState({ [key]: copyInfluencer });
              NotificationManager.success("Updated", `Successfully updated deal status to '${status}'`, 1500);
            }
          });

          // Trigger Stock Tracker update at deal status change
          triggerStockTrackerDealUpdate(dealId);
        }
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error("Error", "Server failed to update status, please try again later and send the page log to the tech team", 6000);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      let currentUser = this.props.user.email;
      if (this.props.user && this.props.user.email) {
        this.setState({
          am_email: currentUser,
        });
        this._retrieveInfluencers(currentUser);
      }
    }

    if (this.state.search !== prevState.search) {
      // update url
      if (this.state.search.value && this.state.search.type) {
        let url = this.props.location.pathname + "?type=" + this.state.search.type + "&value=" + this.state.search.value;
        this.props.history.push(url);
      } else {
        this.props.history.push(this.props.location.pathname);
      }
    }

    if (this.state.influencers !== prevState.influencers) {
      this.setState({ influencers: this.state.influencers });
    }
  }

  _getCurrentListInfluencers() {
    let tmpInfluencer = null;
    let key = null;
    if (this.state.searchInfluencer == null) {
      // classic list influencer
      tmpInfluencer = this.state.influencers;
      key = "influencers";
    } else {
      // search influencer
      tmpInfluencer = this.state.searchInfluencer;
      key = "searchInfluencer";
    }
    return {
      "tmpInfluencer": tmpInfluencer,
      "key": key,
    };
  }

  createNewDeal() {
    const { idxSelectedInfluencer } = this.state;

    let inf = this._getCurrentListInfluencers();
    let tmpInfluencer = inf["tmpInfluencer"];
    let key = inf["key"];

    let currentInf = tmpInfluencer[idxSelectedInfluencer];
    let countCurrentDeal = 0;
    currentInf["deals"].forEach((deal) => {
      // should not have an active deal
      if (deal["status"] !== "declined_outreach" && deal["status"] !== "published") {
        countCurrentDeal += 1;
      }
    });
    let channelId = null;
    let handleInsta = null;
    let handleTiktok = null;
    let podcastId = null;
    if ((currentInf?.youtube?.accounts?.length || 0) > 0) {
      channelId = typeof currentInf.youtube.accounts[0] === "string" ? currentInf.youtube.accounts[0] : currentInf.youtube.accounts[0].channelId;
    }
    if ((currentInf?.instagram?.accounts?.length || 0) > 0) {
      handleInsta =
        typeof currentInf.instagram.accounts[0] === "string" ? currentInf.instagram.accounts[0] : currentInf.instagram.accounts[0].profileId;
    }
    if ((currentInf?.tiktok?.accounts?.length || 0) > 0) {
      handleTiktok = typeof currentInf.tiktok.accounts[0] === "string" ? currentInf.tiktok.accounts[0] : currentInf.tiktok.accounts[0].profileId;
    }
    if ((currentInf?.podcast?.accounts?.length || 0) > 0) {
      podcastId = typeof currentInf.podcast.accounts[0] === "string" ? currentInf.podcast.accounts[0] : currentInf.podcast.accounts[0]._id;
    }

    if (this.canAddNewDeal(countCurrentDeal, currentInf)) {
      let tmpContent = null;
      if (channelId) {
        tmpContent = {
          "platform": "youtube",
          "platform_id": channelId,
          "type": "mention",
        };
      } else if (handleInsta) {
        tmpContent = {
          "platform": "instagram",
          "platform_id": handleInsta,
          "type": "post",
        };
      } else if (handleTiktok) {
        tmpContent = {
          "platform": "tiktok",
          "platform_id": handleTiktok,
          "type": "mention",
        };
      } else if (podcastId) {
        tmpContent = {
          "platform": "podcast",
          "platform_id": podcastId,
          "type": "mention",
        };
      } else {
        NotificationManager.error("Need at least YouTube channelId OR instagram handle OR TikTok handle", "Error missing platforms", 4000);
      }

      if (tmpContent) {
        axios
          .post("/api/v1/influencers/" + currentInf["_id"] + "/deals", {
            "status": "hold",
            "am": this.state.am_email,
            "content": tmpContent ? [tmpContent] : [],
          })
          .then((res) => {
            if (res.status === 200) {
              // udpate influencer (to refresh interface)
              let result = res.data.result[0];
              tmpInfluencer[idxSelectedInfluencer]["deals"].push(result);
              this.setState({
                [key]: tmpInfluencer,
                tabDeal: "deal_info",
              });
            }
          })
          .catch((err) => {
            NotificationManager.error("Error", "Couldn't create a new deal", 4000);
          });
      }
    } else {
      NotificationManager.error("Couldn't create a new deal, you can have maximum 6 active deal or 12 deals for VIP, VIP-2, IG-VIP, TT-VIP & Cocreation and Podcasts", "Error", 4000);
    }
  }

  _clearSearch() {
    this.setState({
      search: { ...this._initialSearchValues(), ams: [this.state.am_email] },
      searchInfluencer: null,
    });
  }

  _isSearchFilled() {
    const state = this.state;
    if (state.searchInfluencer) return true;
    return Object.keys(state.search).some((key) => {
      if (key === "ams") return !(state.search[key].length === 1 && state.search[key][0] === state.am_email);
      if (Array.isArray(state.search[key])) return !!state.search[key].length;
      return !!state.search[key];
    });
  }

  async _searchInfluencer(search) {
    this.setState({ isLoadingSearch: true, showHistorySearch: false, selectedInfluencer: null, idxSelectedInfluencer: null });
    search = search || this.state.search;

    if (!!search["value"] && !search["type"]) return NotificationManager.warning("Please select search option", "Missing search type", 2000);

    const optionsLevel = {
      dealStatus: "deal_status",
      tags: "influencer_tags.id",
      campaigns: "influencer_campain_tags.id",
      ams: "deal_am",
      id: "influencer__id",
      channelId: "influencer_youtube.accounts",
      handle: "influencer_instagram.accounts",
      tiktok_handle: "influencer_tiktok.accounts",
      email: "influencer_emails",
      podcast_name: "podcast_name",
      podcast_url: "podcast_broadcasters.url",
      dealId: "deal__id",
      // Backward compatibility:
      campaign: "influencer_campain_tags.id",
      tag: "influencer_tags.id",
    };
    let params = null;
    if (search["type"] && search["value"]) {
      params = { [optionsLevel[search["type"]]]: search["value"] };
    } else {
      params = Object.keys(search).reduce((acc, searchOption) => {
        if (["value", "type"].includes(searchOption)) return acc;
        if (Array.isArray(search[searchOption])) {
          if (!search[searchOption].length) return acc;
          if (searchOption === "campaigns") {
            // AND
            acc[optionsLevel[searchOption]] = { "$all": search[searchOption] };
          } else {
            // OR
            acc[optionsLevel[searchOption]] = { "$in": search[searchOption] };
          }
          return acc;
        }
        if (!optionsLevel[searchOption]) {
          console.error("UNKNOWN OPTION:", searchOption);
        }
        acc[optionsLevel[searchOption]] = search[searchOption];
        return acc;
      }, {});
      // params["influencer_deals.0"] = { "$exists": true }; // does not make it faster
    }

    const { data: dataInfluencer } = await axios.get("/api/v1/influencers_full_by_deal?", {
      params: Object.keys(params).reduce(
        (acc, paramKey) => {
          acc[paramKey] = JSON.stringify(params[paramKey]);
          return acc;
        },
        { "limit": 400 }
      ),
    });
    const influencers = dataInfluencer.result.sort((influencer1, influencer2) => {
      if (!influencer2.deals.length) return 1;
      if (!influencer1.deals.length) return -1;
      const ams = search.ams || [];
      const lastDeal1 = influencer1.deals[influencer1.deals.length - 1];
      const lastDeal2 = influencer2.deals[influencer2.deals.length - 1];
      if (ams.includes(lastDeal1.am) && ams.includes(lastDeal2.am)) {
        return new Date(lastDeal1.date_follow_up) - new Date(lastDeal2.date_follow_up);
      } else if (ams.includes(lastDeal1.am)) {
        return -1;
      } else if (ams.includes(lastDeal2.am)) {
        return 1;
      } else {
        return new Date(lastDeal1.date_follow_up) - new Date(lastDeal2.date_follow_up);
      }
    });

    // Start Local Storage
    let currentSearchHistory = localStorage.getItem("outreach_search");
    if (currentSearchHistory == null) {
      // new
      currentSearchHistory = [];
    } else {
      currentSearchHistory = JSON.parse(currentSearchHistory);
      const index = currentSearchHistory.indexOf(search["value"]);
      if (
        currentSearchHistory.indexOf(search["value"]) === -1 &&
        !["dealId", "id"].includes(search["key"]) // do not add id to history
      ) {
        currentSearchHistory.unshift(search["value"]);
      } else {
        // Bring back to the top
        currentSearchHistory.splice(index, 1);
        currentSearchHistory.unshift(search["value"]);
      }
      // limit to 50
      if (currentSearchHistory.length > 50) {
        // remove all above 50
        for (let i = 50; i < currentSearchHistory.length; i++) {
          currentSearchHistory.pop();
        }
      }
    }
    localStorage.setItem(
      "outreach_search",
      JSON.stringify(currentSearchHistory)
    );
    // End Local Storage

    let idxSelectedInfluencer = null;
    let selectedInfluencer = null;
    let closeListInf = false;
    if (influencers.length === 1) {
      idxSelectedInfluencer = 0;
      selectedInfluencer = influencers[0];
      closeListInf = true;
    }
    this.setState({
      searchInfluencer: influencers,
      isSearchInfluencerTruncated: dataInfluencer.truncated,
      idxSelectedInfluencer: idxSelectedInfluencer,
      selectedInfluencer: selectedInfluencer,
      isTabInfluencerClosed: closeListInf,
      isLoadingSearch: false,
      searchHistory: currentSearchHistory,
    });
    return;
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    let inf = this._getCurrentListInfluencers();
    let key = inf["key"];

    if (bottom && this.state.isLoadingMore !== true && this.state[key].length > 99) {
      this.setState({ isLoadingMore: true });
      axios
        .get("/api/v1/influencers_full_by_deal?", {
          params: {
            "deal_am": '"' + this.state.am_email + '"',
            "deal_status":
              '{"$in":["negociation", "detail", "hold", "call", "lead", "created", "adam", "adam_yes", "adam_no", "shipped", "to_ship", "in_transit", "shipment_received"]}',
            "dealsort_date_last_outreach": '"1"',
            "page": this.state.currentPage + 1,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            let tmpInfluencer = this.state.influencers;
            this.setState({
              influencers: tmpInfluencer.concat(res.data.result),
              currentPage: this.state.currentPage + 1,
              isLoadingMore: false,
            });
          }
        });
    }
  };

  /******
    action: "ADD", "DELETE"
    type: "email", "youtube", "instagram"
    value: (string)
    idx: (int)
  ******/
  editInfoInfluencer(action, type, value, idx, _idInfluencer) {
    if (action === "ADD" && value === "") {
      NotificationManager.error("Error", "Please input a value...", 4000);
      return;
    }
    const TYPE_TO_KEY = {
      "email": "emails",
      "youtube": "youtube.accounts",
      "instagram": "instagram.accounts",
      "tiktok": "tiktok.accounts",
      "podcast": "podcast.accounts",
      "email_paypal": "paypal_email",
    }
    const ARRAY_TYPES = ["email", "youtube", "instagram", "tiktok", "podcast"];

    let queryMongo = {};

    const key = TYPE_TO_KEY[type] || type;

    if (action === "ADD") {
      if (ARRAY_TYPES.includes(type)) {
        queryMongo["$push"] = { [key]: { "$each": [value], $position: 0 } };
      }
      else {
        queryMongo["$set"] = {};
        queryMongo["$set"][key] = value;
      }
    } else if (action === "DELETE") {
      if (ARRAY_TYPES.includes(type)) {
        queryMongo["$set"] = {};
        queryMongo["$set"][key] = value.filter((_v, i) => i !== idx);
      } else {
        queryMongo["$set"] = {};
        queryMongo["$set"][key] = "";
      }
    }

    return axios
      .put("/api/v1/influencers", queryMongo, { params: { "_id": _idInfluencer } })
      .then((r) => {
        if (r.status === 200) {
          let inf = this._getCurrentListInfluencers();
          let tmpInfluencer = inf["tmpInfluencer"];
          let key = inf["key"];

          return axios
            .get("/api/v1/influencers_full?_id=" + tmpInfluencer[this.state.idxSelectedInfluencer]["_id"])
            .then((result) => {
              if (result.status === 200) {
                tmpInfluencer[this.state.idxSelectedInfluencer] = result.data.result[0];
                // update influencer
                this.setState({ [key]: tmpInfluencer });
                NotificationManager.success("Updated", `${type} done update!`, 3000);
              } else {
                NotificationManager.error("Error", "Error to get new result, please refresh", 4000);
              }
            })
            .catch((err) => {
              NotificationManager.error("Error", "Server failed to get new data " + err, 4000);
            });
        }
        throw new Error(`Bad status ${r.status}`);
      })
      .catch((err) => {
        NotificationManager.error("Error", "Server failed to update " + err, 4000);
      });
  }

  updatePlatformInfluencer(type, value) {
    let inf = this._getCurrentListInfluencers();
    let tmpInfluencer = inf["tmpInfluencer"];
    let key = inf["key"];

    value = value.trim();
    if (type === "youtube") {
      // TODO: YOUTUBE
      if (value.includes("youtube.com")) {
        value = value.split("channel/")[1].split("/")[0].split("?")[0].split("#")[0].split("/")[0];
      }
      axios
        .get(`/api/v1/aws/channel/${value}`)
        .then(({ data }) => {
          let { result } = data;
          axios
            .put(
              "/api/v1/influencers",
              { "$push": { "youtube.accounts": value } },
              { params: { "_id": tmpInfluencer[this.state.idxSelectedInfluencer]["_id"] } }
            )
            .then(() => {
              // update influencer
              tmpInfluencer[this.state.idxSelectedInfluencer]["youtube"]["accounts"][0] = {};
              Object.keys(result).forEach((k) => {
                tmpInfluencer[this.state.idxSelectedInfluencer]["youtube"]["accounts"][0][k] = result[k];
              });
              this.setState({
                [key]: tmpInfluencer, // update current influencer on all object
              });
            })
            .catch((err) => console.log(err) || alert("Failed to update"));
        })
        .catch((err) => console.log(err) || alert("Failed. Please check your input again."));
    }
    if (type === "instagram") {
      // TODO: INSTAGRAM
      if (value.includes("instagram.com")) {
        value = value.split("instagram.com/")[1].split("/")[0].split("?")[0].split("#")[0].split("/")[0];
      }

      // remove @ from handle
      value.replace("@", "");

      axios
        .get(`/api/v1/aws/handle/${value}`)
        .then(({ data }) => {
          let { result } = data;
          axios
            .put(
              "/api/v1/influencers",
              { "$push": { "instagram.accounts": value } },
              { params: { "_id": tmpInfluencer[this.state.idxSelectedInfluencer]["_id"] } }
            )
            .then(() => {
              // update influencer
              tmpInfluencer[this.state.idxSelectedInfluencer]["instagram"]["accounts"][0] = {};
              Object.keys(result).forEach((k) => {
                tmpInfluencer[this.state.idxSelectedInfluencer]["instagram"]["accounts"][0][k] = result[k];
              });
              this.setState({
                [key]: tmpInfluencer, // update current influencer on all object
              });
            })
            .catch((err) => console.log(err) || alert("Failed to update"));
        })
        .catch((err) => console.log(err) || alert("Failed. Please check your input again."));
    }

    if (type === "tiktok") {
      if (value.includes("tiktok.com")) {
        if (value.includes("tiktok.com/@")) {
          value = value.split("tiktok.com/@")[1].split("/")[0].split("?")[0].split("#")[0].split("/")[0];
        } else {
          return NotificationManager.error("Invalid URL", "Failed to update TikTok", 1200);
        }
      }
      axios
        .put(
          "/api/v1/influencers",
          { "$push": { "tiktok.accounts": value } },
          { params: { "_id": tmpInfluencer[this.state.idxSelectedInfluencer]["_id"] } }
        )
        .then(() => {
          // TODO: GET INFLUENCER FULL NEEDED HERE
          // update influencer
          if (!tmpInfluencer[this.state.idxSelectedInfluencer]["tiktok"]) {
            tmpInfluencer[this.state.idxSelectedInfluencer]["tiktok"] = {
              accounts: [],
            };
          }
          tmpInfluencer[this.state.idxSelectedInfluencer]["tiktok"]["accounts"].push(value);
          this.setState({
            [key]: tmpInfluencer, // update current influencer on all object
          });
        })
        .catch((err) => console.log(err) || alert("Failed to update"));
    }
  }

  forceUpdateCurrentInfluencer() {
    const { idxSelectedInfluencer } = this.state;
    let shouldUpdateState = false; // set true on first axios get THEN on the second update state
    let newYouTubeData = null;
    let newInstagramData = null;

    let inf = this._getCurrentListInfluencers();
    let tmpInfluencer = inf["tmpInfluencer"];
    let key = inf["key"];

    this.setState({ isUpdatingInf: true });

    if (tmpInfluencer[idxSelectedInfluencer].youtube.accounts.length === 0 || tmpInfluencer[idxSelectedInfluencer].instagram.accounts.length === 0) {
      shouldUpdateState = true;
    }

    function _updateStateInf(YT_Data, Insta_Data, _this) {
      if (!shouldUpdateState) {
        shouldUpdateState = true;
      } else {
        // should update state
        let infs = { ..._this.state[key] };
        let theInf = { ...infs[idxSelectedInfluencer] };
        if (YT_Data) {
          // Check if:
          //  - new res (YT_Data) has a Insta account
          //  - current Influencer do not have insta
          if (theInf.instagram.accounts.length === 0 && YT_Data.instagramhandle) {
            // scrap insta
            let requestsInf = [];
            requestsInf.push(axios.get(`/api/v1/aws/handle/${YT_Data.instagramhandle}?force_update=true`));

            // update handle to influencer
            requestsInf.push(
              axios.put("/api/v1/influencers", { "$push": { "instagram.accounts": YT_Data.instagramhandle } }, { params: { "_id": theInf["_id"] } })
            );

            // get new influencers data
            requestsInf.push(axios.get('/api/v1/influencers_full_by_deal?influencer_youtube.accounts="' + YT_Data["channelId"] + '"'));

            Promise.all(requestsInf).then((result) => {
              if (result[2].status === 200 && result[2].data.result.length > 0) {
                theInf = result[2].data.result[0];
                let newInfs = [];
                Object.keys(infs).forEach((inf, i) => {
                  newInfs.push(infs[inf]);
                });
                _this.setState({
                  [key]: newInfs,
                  isUpdatingInf: false,
                });
              }
            });
            return;
          }

          Object.keys(YT_Data).forEach((k) => {
            theInf.youtube.accounts[0][k] = YT_Data[k];
          });
        }
        if (Insta_Data) {
          Object.keys(Insta_Data).forEach((k) => {
            theInf.instagram.accounts[0][k] = Insta_Data[k];
          });
        }

        let newInfs = [];
        Object.keys(infs).forEach((inf, i) => {
          newInfs.push(infs[inf]);
        });

        _this.setState({
          [key]: newInfs,
          isUpdatingInf: false,
        });
      }
    }

    if (tmpInfluencer[idxSelectedInfluencer].youtube.accounts.length > 0) {
      // refresh youtube
      let channelId = tmpInfluencer[idxSelectedInfluencer].youtube.accounts[0]?.channelId || tmpInfluencer[idxSelectedInfluencer].youtube.accounts[0];
      axios
        .get(`/api/v1/aws/channel/${channelId}?force_update=true&latestVideos=true`)
        .then((response) => {
          if (response.data.message === "ok") {
            newYouTubeData = response.data.result;
          } else {
            if (response.data.result.message === "Request failed with status code 404") {
              NotificationManager.error("Channel does not exist / is disabled / or is an autogenerated one", "Error", 4000);
            } else {
              NotificationManager.error("Error", "Server failed to get new YouTube data. " + response.data.result.message, 4000);
            }
            console.log("isUpdatingInf: false");
            return this.setState({ isUpdatingInf: false });
          }
          _updateStateInf(newYouTubeData, newInstagramData, this);
        })
        .catch((err) => {
          console.log("ERROR: " + err);
          NotificationManager.error("Error", "Server failed to get new YouTube data. " + err, 4000);
        });
    }
    if (tmpInfluencer[idxSelectedInfluencer].instagram.accounts.length > 0) {
      // refresh instagram
      let instagramHandle = tmpInfluencer[idxSelectedInfluencer].instagram.accounts[0].profileId;
      axios
        .get(`/api/v1/aws/handle/${instagramHandle}?force_update=true`)
        .then((response) => {
          if (response.data.message === "ok") {
            newInstagramData = response.data.result;
          } else {
            NotificationManager.error("Error", "Server failed to get new Instagram data. " + response.data.result.message, 4000);
          }
          _updateStateInf(newYouTubeData, newInstagramData, this);
        })
        .catch((err) => {
          console.log("ERROR: " + err);
          NotificationManager.error("Error", "Server failed to get new Instagram data. " + err, 4000);
        });
    }
  }

  deleteInfluencerDeal(dealId) {
    const inf = this._getCurrentListInfluencers();
    let tmpInfluencer = inf["tmpInfluencer"];
    const key = inf["key"];
    let selectedInfluencer = tmpInfluencer[this.state.idxSelectedInfluencer];

    axios
      .delete(`/api/v1/influencers/${selectedInfluencer["_id"]}/deals/${dealId}`)
      .then(({ data }) => {
        selectedInfluencer["deals"] = selectedInfluencer["deals"].filter((deal) => deal._id !== dealId);
        tmpInfluencer[this.state.idxSelectedInfluencer] = selectedInfluencer;
        this.setState({
          [key]: tmpInfluencer,
        });
        NotificationManager.success("Updated", `Successfully deleted deal`, 1500);
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error("Error", "Server failed to delete deal, please try again later and send the page log to the tech team", 6000);
      });
  }

  removeSearchFromHistory(i) {
    let searchHistory = JSON.parse(localStorage.getItem("outreach_search"));
    searchHistory.splice(i, 1);
    localStorage.setItem("outreach_search", JSON.stringify(searchHistory));
    this.setState({ searchHistory: searchHistory });
    setTimeout(() => this.setState({ showHistorySearch: true }), 202);
  }

  canAddNewDeal(dealCount, inf) {
    // The maximum of active deals is 6
    // and 12 for specialTags (VIP, VIP-2, IG-VIP, TT-VIP & Cocreation) and podcasts
    return dealCount < 6 ||
      ((inf.campain_tags.find((c) => specialTags.indexOf(c.name) > -1) ||
        inf?.podcast?.accounts?.length) &&
        dealCount < 12);
  }

  render() {
    const {
      searchHistory,
      isLoadingSearch,
      showHistorySearch,
      idxSelectedInfluencer,
      tabDeal,
      searchInfluencer,
      isLoadingMore,
    } = this.state;

    let inf = this._getCurrentListInfluencers();
    let listInfluencer = inf["tmpInfluencer"];

    let tmpInfluencer = null;
    if (listInfluencer) {
      tmpInfluencer = listInfluencer[idxSelectedInfluencer] || null;
    }

    // get current deal (status different than published)
    let currentDeal = [];
    if (tmpInfluencer) {
      currentDeal = filter(tmpInfluencer["deals"], isDealActive);
    }

    // get past deals (status => "published")
    let publishedDeal = [];
    if (tmpInfluencer) {
      publishedDeal = filter(tmpInfluencer["deals"], function (deal) {
        return !isDealActive(deal);
      });
    }

    const searchType = [
      {
        "title": "Search options",
        "value": "",
      },
      {
        "title": "E-mail",
        "value": "email",
      },
      {
        "title": "YouTube - Channel Id",
        "value": "channelId",
      },
      {
        "title": "Instagram - Handle",
        "value": "handle",
      },
      {
        "title": "TikTok - Handle",
        "value": "tiktok_handle",
      },
      {
        "title": "Podcast - Name",
        "value": "podcast_name",
      },
      {
        "title": "Podcast - Profile URL",
        "value": "podcast_url",
      },
    ];
    const search = new URLSearchParams(this.props.location.search);
    const openDeals = new Set([search.get("type") === "dealId" && search.get("value"), ...search.getAll("dealId")]);

    return (
      <div className="outreach">
        {/* START LEFT PANEL */}
        <div className={`outreach_list ${this.state.isTabInfluencerClosed ? "outreach_list--close" : "outreach_list_beta--open"}`}>
          {!this.state.isTabInfluencerClosed && this.state.searchInfluencer ? (
            <div className="outreach_influencers_count">
              {(listInfluencer && listInfluencer.length) || 0} influencers
              {this.state.isSearchInfluencerTruncated ? (
                <div className="outreach_influencers_count_warning"> — truncated. Please specify your query.</div>
              ) : (
                ""
              )}
            </div>
          ) : null}
          {/* START LIST INFLUENCER */}
          <div
            className={`outreach_influencers ${this.state.isTabInfluencerClosed ? "outreach_influencers--close" : "outreach_influencers--open"}`}
            onScroll={this.handleScroll}>
            {listInfluencer && listInfluencer.length > 0 ? (
              <>
                {listInfluencer.map((influencer, i) => {
                  let influencerName = null;
                  if (!influencer) {
                    return null;
                  }

                  if (influencer.name !== "") {
                    influencerName = influencer.name;
                  } else if (influencer.youtube.accounts.length > 0 && typeof influencer.youtube.accounts[0] === "string") {
                    influencerName = influencer.youtube.accounts[0];
                  } else if (influencer.youtube.accounts.length > 0 && influencer.youtube.accounts[0].title) {
                    influencerName = influencer.youtube.accounts[0].title;
                  } else if (influencer?.podcast?.accounts[0] && typeof influencer.podcast.accounts[0] === "object") {
                    influencerName = influencer.podcast.accounts[0].name;
                  } else if (influencer.instagram.accounts.length > 0 && typeof influencer.instagram.accounts[0] === "string") {
                    influencerName = influencer.instagram.accounts[0];
                  } else if (influencer.instagram.accounts.length > 0 && influencer.instagram.accounts[0].fullName) {
                    influencerName = influencer.instagram.accounts[0].fullName;
                  } else if (influencer?.tiktok?.accounts[0] && typeof influencer.tiktok.accounts[0] === "string") {
                    influencerName = influencer.tiktok.accounts[0];
                  } else if (influencer?.tiktok?.accounts.length > 0 && influencer.tiktok.accounts[0].fullName) {
                    influencerName = influencer.tiktok.accounts[0].fullName;
                  }
                  let influencerEmail = null;
                  if (influencer.emails.length > 0) {
                    influencerEmail = influencer.emails[0];
                  }
                  let influencerStatus = null;
                  if (influencer && influencer.deals.length > 0) {
                    influencerStatus = influencer.deals[influencer.deals.length - 1].status;
                  }

                  let currentDeal = [];
                  currentDeal = filter(influencer["deals"], function (i) {
                    return i["status"] !== "published" && i["status"] !== "declined_outreach";
                  });

                  let publishedDeal = [];
                  publishedDeal = filter(influencer["deals"], function (i) {
                    return i["status"] === "published" || i["status"] === "declined_outreach";
                  });

                  return (
                    <div
                      className={`outreach_influencers_tab ${idxSelectedInfluencer === i ? "outreach_influencers_tab--isSelected" : ""}`}
                      key={`influencer_${i}`}
                      onClick={() =>
                        this.setState({
                          idxSelectedInfluencer: i,
                          selectedInfluencer: tmpInfluencer,
                          tabDeal: "deal_info",
                          isTabInfluencerClosed: true,
                        })
                      }>
                      <p className="outreach_influencers_tab--name">{influencerName}</p>
                      <p className="outreach_influencers_tab--email">{influencerEmail}</p>
                      <p className="outreach_influencers_tab--status">
                        {influencerStatus}
                        <span className="outreach_influencers_tab--status-current">
                          Active: {currentDeal.length} - Published {publishedDeal.length}
                        </span>
                      </p>
                      <span className="outreach_influencers_tab--arrow material-icons">keyboard_arrow_right</span>
                    </div>
                  );
                })}
                {isLoadingMore ? (
                  <div className="outreach_influencers_more">
                    <Loading />
                    <p className="outreach_influencers_more_text">Loading more influencer</p>
                  </div>
                ) : null}
              </>
            ) : listInfluencer == null ? (
              <div className="loading_influencer">
                <Loading />
                <p className="loading_influencer_text">We are loading your influencers {this.props.user.firstName}, give us one moment please!</p>
              </div>
            ) : (
              <div>No influencers</div>
            )}
          </div>

          <div className="outreach_list_open" onClick={() => this.setState({ isTabInfluencerClosed: !this.state.isTabInfluencerClosed })}>
            <span className="material-icons">{this.state.isTabInfluencerClosed ? "navigate_next" : "navigate_before"}</span>
          </div>
          {/* END LIST INFLUENCER */}
        </div>
        {/* END LEFT PANEL */}

        {/* START MIDDLE PANEL */}
        <div
          className={`outreach_influencer ${this.state.isTabInfluencerClosed ? "outreach_influencer_menu_close_beta" : "outreach_influencer_menu_open_beta"}`}>
          {/* START SEARCH OUTREACH */}
          <div className="outreach_search">
            <div className="outreach_search_line">
              <div className="outreach_search_dropdown">
                <ALDropdown
                  options={[
                    { "title": "Deal status", "value": "" },
                    ...DEAL_STATUSES.map((status) => ({ "title": status.replaceAll("_", " "), "value": status })),
                  ].filter((status) => !this.state.search.dealStatus.includes(status["value"]))}
                  useValue={"title"}
                  useKey={"value"}
                  value={""}
                  onChange={(key) => {
                    this.setState({ search: { ...this.state.search, "dealStatus": [...this.state.search.dealStatus, key] } });
                  }}
                  selectionChips={this.state.search.dealStatus.map((status) => ({ "title": status.replaceAll("_", " "), "value": status }))}
                  onChipClose={(key) => {
                    this.setState({ search: { ...this.state.search, "dealStatus": this.state.search.dealStatus.filter((s) => s !== key) } });
                  }}
                  disabled={!!this.state.search.value}
                />
              </div>
              <div className="outreach_search_dropdown">
                <ALDropdown
                  options={[{ "title": "Tags", "value": "" }, ...this.state.tags.map((tag) => ({ "title": tag.name, "value": tag._id }))].filter(
                    (tag) => !this.state.search.tags.includes(tag["value"])
                  )}
                  useValue={"title"}
                  useKey={"value"}
                  value={""}
                  onChange={(key) => {
                    this.setState({ search: { ...this.state.search, "tags": [...this.state.search.tags, key] } });
                  }}
                  selectionChips={this.state.search.tags
                    .map((tag) => this.state.tags.find((t) => t._id === tag))
                    .map((tag) => ({ "title": tag.name, "value": tag._id }))}
                  onChipClose={(key) => {
                    this.setState({ search: { ...this.state.search, "tags": this.state.search.tags.filter((t) => t !== key) } });
                  }}
                  disabled={!!this.state.search.value}
                />
              </div>
              <div className="outreach_search_dropdown">
                <ALDropdown
                  options={[
                    { "title": "Campaigns", "value": "" },
                    ...this.state.campaignTags.map((campaign) => ({ "title": campaign.name, "value": campaign._id })),
                  ].filter((tag) => !this.state.search.campaigns.includes(tag["value"]))}
                  useValue={"title"}
                  useKey={"value"}
                  value={""}
                  onChange={(key) => {
                    this.setState({ search: { ...this.state.search, "campaigns": [...this.state.search.campaigns, key] } });
                  }}
                  selectionChips={this.state.search.campaigns
                    .map((campaign) => this.state.campaignTags.find((t) => t._id === campaign))
                    .map((campaign) => ({ "title": campaign.name, "value": campaign._id }))}
                  onChipClose={(key) => {
                    this.setState({ search: { ...this.state.search, "campaigns": this.state.search.campaigns.filter((t) => t !== key) } });
                  }}
                  disabled={!!this.state.search.value}
                />
              </div>
              <div className="outreach_search_dropdown outreach_search_dropdown--no_cap">
                <ALDropdown
                  options={[
                    { "title": "All AMs", "value": "" },
                    { "title": this.state.am_email, "value": this.state.am_email },
                  ]}
                  useValue={"title"}
                  useKey={"value"}
                  value={this.state.search.ams.length ? this.state.search.ams[0] : ""}
                  onChange={(key) => {
                    this.setState({ search: { ...this.state.search, "ams": key === "" ? [] : [key] } });
                  }}
                  disabled={!!this.state.search.value}
                />
              </div>
              <div className="outreach_search_dropdown" style={{ "display": "none" }}>
                <ALDropdown
                  options={[
                    { "title": "Agency", "value": "" },
                    { "title": "test", "value": "testValue" },
                  ]}
                  useValue={"title"}
                  useKey={"value"}
                  value={""}
                  onChange={(key) => {}}
                  disabled={!!this.state.search.value}
                />
              </div>
            </div>

            <div className="outreach_search_line">
              <div className="outreach_search_dropdown">
                <ALDropdown
                  options={searchType}
                  useValue={"title"}
                  useKey={"value"}
                  value={this.state.search.type ? this.state.search.type : searchType[0]["value"]}
                  onChange={(key) => {
                    this.setState({ search: { ...this.state.search, type: key } });
                  }}
                />
              </div>
              <div
                className="outreach_search_input"
                onFocus={() => this.setState({ showHistorySearch: true })}
                onBlur={() => setTimeout(() => this.setState({ showHistorySearch: false }), 200)}>
                <input
                  className={`outreach_search__input ${
                    searchHistory && searchHistory.length > 0 && showHistorySearch ? "outreach_search__input--history" : ""
                  }`}
                  placeholder="Search"
                  type="text"
                  value={this.state.search.value ? this.state.search.value : ""}
                  onFocus={this._onFocusSearch}
                  onBlur={this._onBlurSearch}
                  onChange={(e) => {
                    let value = e.target.value;
                    let type = this.state.search.type;
                    if (value.includes("@")) {
                      value = value.trim();
                      type = "email";
                    } else if (value.trim().startsWith("UC")) {
                      type = "channelId";
                      value = value.trim();
                    }
                    if (type === "email") {
                      value = value.toLowerCase();
                    }
                    this.setState({
                      search: { ...this.state.search, "value": value, "type": type },
                    });
                  }}
                />

                {/* START CTA SEARCH */}
                {this.state.search.value && this.state.search.value.length > 0 ? (
                  <div
                    className="outreach_search_cta--reset"
                    onClick={() => {
                      this.setState({
                        search: { ...this.state.search, value: null, type: null },
                        searchInfluencer: null,
                      });
                    }}>
                    <span className="material-icons">search_off</span>
                  </div>
                ) : null}
                {this.state.search.value && this.state.search.value.length > 0 ? (
                  <div
                    className="outreach_search_cta--search"
                    onClick={() => {
                      const search = { ...this.state.search, value: this.state.search.value.trim() };
                      this.setState({ search: search });
                      this._searchInfluencer(search);
                    }}>
                    {this.state.search.value ? (
                      <span className="material-icons">search</span>
                    ) : searchInfluencer && searchInfluencer.length > 0 ? (
                      <span className="material-icons">remove_circle_outline</span>
                    ) : null}
                  </div>
                ) : null}
                {/* END CTA SEARCH */}

                {/* START SEARCH HISTORY */}
                {searchHistory && searchHistory.length > 0 && showHistorySearch ? (
                  <div className="search_history">
                    <div className="search_history_content">
                      {searchHistory.map((val, i) => {
                        return (
                          <div className="search_history_value" key={`history_${i}`}>
                            <p
                              className="search_history_value-text"
                              onClick={() => {
                                let tmpSearch = { ...this.state.search };
                                tmpSearch["value"] = val;
                                tmpSearch["type"] = this.state.search.type;
                                if (tmpSearch["value"].includes("@")) {
                                  tmpSearch["type"] = "email";
                                } else if (tmpSearch["value"].startsWith("UC")) {
                                  tmpSearch["type"] = "channelId";
                                }
                                this.setState({ search: tmpSearch });
                                this._searchInfluencer(tmpSearch);
                              }}>
                              {val}
                            </p>
                            <span className="material-icons" onClick={(e) => e.preventDefault() || e.stopPropagation() || this.removeSearchFromHistory(i)}>
                              close
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="search_history_close" onClick={() => this.setState({ showHistorySearch: false })}>
                      close
                    </div>
                  </div>
                ) : null}
                {/* END SEARCH HISTORY */}
              </div>

              <div className="outreach_search_actions">
                {this._isSearchFilled() ? (
                  <ALButton type="text" onClick={() => this._clearSearch()}>
                    Clear all
                  </ALButton>
                ) : null}
                <ALButton onClick={() => this._searchInfluencer(this.state.search)}>SEARCH</ALButton>
              </div>
            </div>
          </div>
          {/* END SEARCH OUTREACH */}

          {tmpInfluencer ? (
            <>
              <OutreachHeader
                influencer={tmpInfluencer}
                user={this.props.user}
                editInfoInfluencer={this.editInfoInfluencer}
                updatePlatformId={this.updatePlatformInfluencer}
                forceUpdateInf={this.forceUpdateCurrentInfluencer}
                isUpdatingInf={this.state.isUpdatingInf}
                showModal={this.props.showModal}
              />

              {/* START DEAL TABS */}
              <div className="outreach_influencer_container_tabs">
                <div className="outreach_influencer_tabs">
                  <div
                    className={`outreach_influencer_tabs_tab ${tabDeal === "deal_info" ? "outreach_influencer_tabs_tab--isSelected" : ""} ${
                      currentDeal.length === 0 ? "outreach_influencer_tabs_tab--isDisabled" : ""
                    }`}
                    onClick={() => {
                      if (currentDeal.length === 0) {
                        return;
                      }
                      this.setState({ tabDeal: "deal_info" });
                    }}>
                    Active Deal ({currentDeal ? currentDeal.length : "0"})
                  </div>
                  <div
                    className={`outreach_influencer_tabs_tab ${tabDeal == "deal_history" ? "outreach_influencer_tabs_tab--isSelected" : ""} ${
                      publishedDeal.length === 0 ? "outreach_influencer_tabs_tab--isDisabled" : ""
                    }`}
                    onClick={() => {
                      if (publishedDeal.length === 0) {
                        return;
                      }
                      this.setState({ tabDeal: "deal_history" });
                    }}>
                    Deal History ({publishedDeal ? publishedDeal.length : "0"})
                  </div>
                </div>

                <div
                  className={`outreach_influencer_newdeal 
                    ${this.canAddNewDeal(currentDeal.length, tmpInfluencer) ? "" : "outreach_influencer_newdeal--isDisabled"}`}
                  onClick={this.createNewDeal}
                >
                  New Deal
                  <span className="material-icons">add_circle_outline</span>
                </div>
              </div>
              {/* END DEAL TABS */}

              {/* START CURRENT DEAL */}
              {tabDeal === "deal_info"
                ? currentDeal.reverse().map((deal, i) => {
                    return (
                      <CurrentDeal
                        key={deal._id}
                        _key={i}
                        isOpen={currentDeal.length === 1 || openDeals.has(deal._id)}
                        updateStatusInf={this.updateStatusInfluencer}
                        updateRequestedPaymentUpdate={this.updateRequestedPaymentUpdate}
                        newRequestedPayment={this.newRequestedPayment}
                        updateInfluencerDeal={this.updateInfluencerDeal}
                        deleteTransaction={this.deleteTransaction}
                        influencer={tmpInfluencer}
                        account_managers={this.state.account_managers}
                        deal={deal}
                        user={this.props.user}
                        showModal={this.props.showModal}
                        deleteInfluencerDeal={this.deleteInfluencerDeal}
                      />
                    );
                  })
                : null}
              {/* END CURRENT DEAL */}

              {/* START HISTORY DEAL(s) */}
              {tabDeal === "deal_history" &&
                <HistoryDeals
                  deals={publishedDeal}
                  updateStatusInf={this.updateStatusInfluencer} />
                }
              {/* END HISTORY DEAL(s) */}
            </>
          ) : listInfluencer == null || isLoadingSearch ? (
            <div className="loading_influencer">
              <div className="loading_influencer_container">
                <Loading />
                {this._isSearchFilled() ? (
                  <p className="loading_influencer_text">
                    Searching for influencers matching your query {this.props.user.firstName}, give us one moment please!
                  </p>
                ) : (
                  <p className="loading_influencer_text">We are loading your influencer(s) {this.props.user.firstName}, give us one moment please!</p>
                )}
              </div>
            </div>
          ) : listInfluencer.length === 0 ? (
            <div className="loading_influencer">
              <div className="loading_influencer_container">
                <span className="material-icons loading_influencer_icon">sentiment_very_dissatisfied</span>
                <p className="loading_influencer_text">No influencers</p>
              </div>
            </div>
          ) : (
            <div className="loading_influencer">
              <div className="loading_influencer_container">
                <span className="material-icons loading_influencer_icon">menu_open</span>
                <p className="loading_influencer_text">Please select an influencer with the left panel or use the search</p>
              </div>
            </div>
          )}
        </div>
        {/* END MIDDLE PANEL */}


        {/* START RIGHT PANEL */}
        <Emails
          user={this.props.user}
          isTabInfluencerClosed={this.state.isTabInfluencerClosed}
          influencer={tmpInfluencer}
        />
        {/* END RIGHT PANEL */}
      </div>
    );
  }
}

export default (props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag("tool", "Outreach");
      scope.setTag("user", props.user.email);
    }}
    fallback={ErrorBoundaryContent}>
    <OutreachBeta {...props} />
  </Sentry.ErrorBoundary>
);
