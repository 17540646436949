import React from "react";
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import InfluencerLogin from "./InfluencerLogin";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    };
  }

  render() {
    const { user } = this.state;
    return (
      <div>
        {(user.role !== "") ? (
          user.role == "influencer" ? <Redirect to='/a/influencer' push/>  :  <Redirect to='/a/dashboard' />
        ) : (
          <div className="login_main_container">
            <div className="login_container">
              <h2 className="login_container--title">Ana Luisa team</h2>
              <p>Please Sign in with Google using your Ana Luisa account. (Otherwise you can't sign in)</p>
              <a href="/auth/google">
                <button className="loginBtn loginBtn--google">
                  Login with Google
                </button>
              </a>
            </div>
            <div className="login_container">
              <h2 className="login_container--title">Influencers</h2>
              <InfluencerLogin {...this.props}/>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(Login);
