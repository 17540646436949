import React from "react";
import csvParse from "csvtojson";

import "./drap_and_drop.css";


class ALDragAndDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      isHover: false,
      error: "",
      nameFile: ""
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  dragOver = (e) => {
    e.preventDefault();
    this.setState({ isHover: true });
  }

  dragEnter = (e) => {
    e.preventDefault();
    this.setState({ isHover: true });
  }

  dragLeave = (e) => {
    e.preventDefault();
    this.setState({ isHover: false });
  }

  isValidType = (file) => {
    console.log("isValidType");
    console.log(file);
    if (file) {
      if (file.type == this.props.type) {
        return true;
      } else {
        return false;
      }
    }
  }

  csvToJSON = async (csv, fileName) => {
    const lines = csv.split('\n');
    const result = [];
    let headers = null;
    if (fileName == "PRODUCTION MASTER_AL - Jewelry Orders 🚀.csv") {
      headers = lines[2].split(',');
      let csvFile = await csvParse({ headers: headers }).fromString(csv);
      console.log(csvFile);
      return csvFile;
    } else {
      headers = lines[0].split(',');
      for (let i = 1; i < lines.length; i++) {        
        if (!lines[i])
          continue
        const obj = {}
        const currentline = lines[i].split(',');
  
        for (let j = 0; j < headers.length; j++) {
          if (typeof(currentline[j]) == "undefined") {
            obj[headers[j].toLowerCase()] = ""
          } else {
            obj[headers[j].toLowerCase()] = currentline[j].trim();
          }
          
        }
        result.push(obj);
      }
      return result
    }
  }

  fileDrop = (e) => {
    e.preventDefault();
    var _this = this;
    const files = e.dataTransfer.files;
    console.log(files);
    var isValid = this.isValidType(files[0]);
    console.log(isValid)
    if (files.length == 1) {

      var reader = new FileReader();
      reader.readAsText(files[0]);

      reader.onload = function() {
        const data = reader.result;
        console.log(files[0]);
        (_this.props.csvToJSON || _this.csvToJSON)(data, files[0].name)
          .then((r) => {
            console.log(r);
            _this.props.handleFile(r);
            _this.setState({ isHover: false, nameFile: files[0].name });
          });
      };
    }
  }


  render() {
    const { isHover, nameFile } = this.state;

    return (
      <div className={`dragdrop__container ${(isHover) ? 'dragdrop__container--isActive' : null }`}
      onDragOver={this.dragOver}
      onDragEnter={this.dragEnter}
      onDragLeave={this.dragLeave}
      onDrop={this.fileDrop}
      >
        {(isHover) ? (
          <div>
            <span className="material-icons">cloud_download</span>Drop your file
          </div>
        ) : (
          (nameFile.length > 0) ? (
            <div>
              <span className="material-icons">attach_file</span>{nameFile}
            </div>
          ) : (
            <div>
              <span className="material-icons">cloud_upload</span>Upload your file
            </div>
          )

        )}
      </div>
    );
  }
}

export default ALDragAndDrop;
