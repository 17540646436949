import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getInfluencers, getTransactions, isDealActive } from "utils";

import DealsList from "./DealsList";
import QuickSnapshot from "./QuickSnapshot";
import { FilterSearch, useFilteredDeals } from "./filterSearch/FilterSearch";
import useSortedDeals from "./useSortedDeals";

import "./DashboardV2.css";

function DashboardV2({ user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [influencers, setInfluencers] = useState([]);
  const deals = useMemo(
    () => influencers.flatMap(({ deals }) => deals),
    [influencers]
  );
  const [filteredDeals, setSearch] = useFilteredDeals(deals, user.email);
  const [filteredAndSortedDeals, sortKey, sortDirection, setSort] =
    useSortedDeals(filteredDeals);

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    getInfluencers(user.email)
      .then(async (influencersList) => {
        // Make sure we filter deals only for the current AM
        const influencersListOfAMOnly = influencersList
          .map((influencer) => {
            influencer.deals = (influencer.deals || [])
              .filter((deal) => deal.am === user.email && isDealActive(deal))
              .toReversed()
              .map((deal, index) => ({
                ...deal,
                influencer,
                name: deal.name || `Deal ${index + 1}`,
                price: deal.price || 0,
              }));
            return influencer;
          })
          .filter((influencer) => influencer.deals.length > 0);

        // Get all deals transactions
        const allTransactionsById = (
          await getTransactions(
            influencersListOfAMOnly.flatMap(({ deals }) =>
              deals.flatMap(({ payment_transactions }) =>
                payment_transactions.map(({ id }) => id)
              )
            )
          )
        ).reduce((acc, transaction) => {
          acc[transaction._id] = transaction;
          return acc;
        }, {});

        // Add transaction information to each deal
        influencersListOfAMOnly.forEach((influencer) => {
          influencer.deals.forEach((deal) => {
            const latestTransaction =
              deal.payment_transactions?.length &&
              deal.payment_transactions[deal.payment_transactions.length - 1];
            deal.last_transaction_status =
              (latestTransaction &&
                allTransactionsById[latestTransaction.id]?.status) ||
              "";
            // Calculate the total payment percentage either requested or completed
            const transactionTotalAmount = (
              deal.payment_transactions || []
            ).reduce(
              (acc, transaction) =>
                acc + allTransactionsById[transaction.id].amount,
              0
            );
            deal.payment_percentage =
              deal.price > 0 ? transactionTotalAmount / deal.price : 0;
          });
        });

        setInfluencers(influencersListOfAMOnly);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user.email]);

  const onDealUpdate = useCallback((influencerId, updatedDeal) => {
    setInfluencers((prevInfluencers) => {
      return prevInfluencers.map((influencer) => {
        if (influencer._id === influencerId) {
          return {
            ...influencer,
            deals: influencer.deals.map((deal) =>
              deal._id === updatedDeal._id
                ? {
                    ...updatedDeal,
                    // Keep the calculated properties that are not updated
                    influencer: deal.influencer,
                    name: deal.name,
                    price: deal.price,
                    payment_transactions: deal.payment_transactions,
                    last_transaction_status: deal.last_transaction_status,
                    payment_percentage: deal.payment_percentage,
                  }
                : deal
            ),
          };
        }
        return influencer;
      });
    });
  }, []);

  return (
    <div className="dashboard-v2__container">
      <div className="dashboard-v2__container__header">
        <h1>
          {new Date().getHours() < 13
            ? `Good morning, ${user.firstName}`
            : `Good afternoon, ${user.firstName}`}
        </h1>
        <FilterSearch setSearch={setSearch} />
      </div>
      <QuickSnapshot isLoading={isLoading} influencers={influencers} />
      <DealsList
        isLoading={isLoading}
        deals={filteredAndSortedDeals}
        onDealUpdate={onDealUpdate}
        sortKey={sortKey}
        sortDirection={sortDirection}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardV2;
