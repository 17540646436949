import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function ALDateRange(props) {
  const { startDate, endDate, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState({ startDate, endDate });

  useEffect(() => setSelectedRange({ startDate, endDate }), [startDate, endDate]);

  const setDateRange = (dateRange) => {
    if (dateRange.range1.startDate !== dateRange.range1.endDate) {
      onChange(dateRange.range1);
      setIsOpen(false);
    }
    setSelectedRange(dateRange.range1);
  };

  return (
    <div className="al-date-range">
      <input
        className="al-date-range__input"
        type="button"
        value={
          selectedRange.startDate || selectedRange.endDate
            ? `${(selectedRange.startDate && format(selectedRange.startDate, "MM/dd/yyyy")) || ""} — ${
                (selectedRange.endDate && format(selectedRange.endDate, "MM/dd/yyyy")) || ""
              }`
            : "Select dates"
        }
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen ? (
        <div className="al-date-range__overlay">
          <DateRange
            onChange={setDateRange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            showDateDisplay={false}
            ranges={[{ startDate: selectedRange.startDate, endDate: selectedRange.endDate }]}
          />
          <i className="material-icons al-date-range__overlay__close" onClick={() => setIsOpen(false)}>
            close
          </i>
        </div>
      ) : null}
    </div>
  );
}

export default ALDateRange;
