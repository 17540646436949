import React, { useState, useEffect } from "react";
import axios from "axios";

import { ALDragAndDrop, ALButton } from "components/ALComponents";

import { generateTemplate, exportConflicts, csvToJSON, textToCSVObject } from "./csvHelpers";
import { checkAllObjectFormat, convertObjectLineToInfluencersAndDeals, determineInfluencersOperation } from "./preprocessLogic";
import { createAndMergeInfluencers } from "./processLogic";

import "./AddManyInfluencers.css";

function processFileCSVObject(data, tags, campainTags, setError, setInfluencersProcessing, user) {
  let influencersProcessingObjects = [];
  setError(null);
  setInfluencersProcessing(influencersProcessingObjects);
  let error = null;
  error = checkAllObjectFormat(data, tags, campainTags);
  if (error) {
    console.error("Format Error:", error);
    setError({ message: `Error line ${error.line}: ${error.message}` });
    return;
  }
  const convertedObjects = convertObjectLineToInfluencersAndDeals(data, tags, campainTags, user);
  if (convertedObjects.status === "error") {
    const error = convertedObjects.result;
    console.error("Convert Error:", error);
    setError({ message: `Error line ${error.line}: ${error.message}` });
    return;
  }
  influencersProcessingObjects = convertedObjects.result.map((influencerAndDeals) => ({ ...influencerAndDeals, status: "In queue..." }));

  setInfluencersProcessing(influencersProcessingObjects);
  determineInfluencersOperation(influencersProcessingObjects, setInfluencersProcessing);
}

function processPaste(textData, tags, campainTags, setError, setInfluencersProcessing, user) {
  textToCSVObject(textData).then((data) => processFileCSVObject(data, tags, campainTags, setError, setInfluencersProcessing, user));
}

function AddInfluencersResults(props) {
  const { error, influencersProcessing } = props;
  return influencersProcessing.length || error ? (
    <div className="add-influencers-results">
      {error ? (
        <div className="add-influencers-results__error">
          <span>
            <i className="material-icons add-influencers-results__error__icon">report_problem</i>
          </span>
          <label className="add-influencers-results__error__text">{error.message}</label>
        </div>
      ) : null}
      {influencersProcessing.length ? (
        <div className="add-influencers-results__table">
          <div className="add-influencers-results__table__header">
            <div className="add-influencers-results__table__header__cell">Emails</div>
            <div className="add-influencers-results__table__header__cell">YouTube IDs</div>
            <div className="add-influencers-results__table__header__cell">Instagram handles</div>
            <div className="add-influencers-results__table__header__cell">Tiktok handles</div>
            <div className="add-influencers-results__table__header__cell">Processing status</div>
          </div>
          <div className="add-influencers-results__table__content">
            {influencersProcessing.map((influencerProcessing, idx) => (
              <div
                className={`add-influencers-results__table__content__line ${
                  idx % 2 === 0 ? "add-influencers-results__table__content__line--odd" : ""
                }`}
                key={idx}
              >
                <div className="add-influencers-results__table__content__cell">{(influencerProcessing.influencer.emails || []).join(" ")}</div>
                <div className="add-influencers-results__table__content__cell">
                  {(influencerProcessing.influencer.youtube?.accounts || []).join(" ")}
                </div>
                <div className="add-influencers-results__table__content__cell">
                  {(influencerProcessing.influencer.instagram?.accounts || []).join(" ")}
                </div>
                <div className="add-influencers-results__table__content__cell">
                  {(influencerProcessing.influencer.tiktok?.accounts || []).join(" ")}
                </div>
                <div className="add-influencers-results__table__content__cell">{influencerProcessing.status}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
}

function CopyPasteInput(props) {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <input
      className={`copy-paste-input ${isSelected ? "copy-paste-input--selected" : ""}`}
      placeholder={isSelected ? "Paste (Ctrl + V or Cmd + V)" : "Click here first"}
      onBlur={() => setIsSelected(false)}
      onFocus={() => setIsSelected(true)}
      onPaste={(e) => props.onPaste(e.clipboardData.getData("text"))}
      value=""
      onChange={() => null}
    />
  );
}

function AddManyInfluencers(props) {
  const [tags, setTags] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const { influencersProcessing, setInfluencersProcessing, setError, user } = props;
  // Initial data load
  useEffect(() => {
    Promise.all([axios.get("/api/v1/tags"), axios.get("/api/v1/campain_tags")]).then(([{ data: dataTags }, { data: dataCampaigns }]) => {
      setTags(dataTags.tags);
      setCampaigns(dataCampaigns.tags);
    });
  }, []);

  const handleExportConflictAndStartAdd = () => {
    exportConflicts(influencersProcessing);
    createAndMergeInfluencers(influencersProcessing, setInfluencersProcessing);
  };

  return (
    <div className="add-many-influencers">
      <div className="add-many-influencers__template">
        <div>
          <div className="add-many-influencers__title">Download Template</div>
          <ul>
            <li>Please specifie at least one email or platform, a destination and if a deal should be created.</li>
            <li>Multiple entries must be separated with space. Ex: "vlog fashion" or "email1@gmail.com email2@gmail.com"</li>
          </ul>
        </div>
        <ALButton material_icon="download" onClick={generateTemplate}>
          Template
        </ALButton>
      </div>
      <div className="add-many-influencers__upload">
        <div className="add-many-influencers__side add-many-influencers__side__by-file">
          <div className="add-many-influencers__side__title">Upload your CSV file here:</div>
          <ALDragAndDrop
            type="text/csv"
            handleFile={(data) => processFileCSVObject(data, tags, campaigns, setError, setInfluencersProcessing, user)}
            csvToJSON={csvToJSON}
          />
        </div>
        <div className="add-many-influencers__side add-many-influencers__side__by-paste">
          <div className="add-many-influencers__side__title">Or paste lines from spreadsheet:</div>
          <CopyPasteInput onPaste={(data) => processPaste(data, tags, campaigns, setError, setInfluencersProcessing, user)} />
        </div>
      </div>
      <div className="add-many-influencers__upload">
        <ALButton
          material_icon="download"
          onClick={handleExportConflictAndStartAdd}
          disabled={!influencersProcessing?.length || influencersProcessing.some((inf) => !inf.mergeStrategy)}
        >
          Download conflict & start process
        </ALButton>
      </div>
    </div>
  );
}

function OnlyAddManyInfluencers(props) {
  const [influencersProcessing, setInfluencersProcessing] = useState([]);
  const [error, setError] = useState(null);
  return (
    <div>
      <AddManyInfluencers
        {...props}
        influencersProcessing={influencersProcessing}
        setInfluencersProcessing={setInfluencersProcessing}
        setError={setError}
      />
      <AddInfluencersResults error={error} influencersProcessing={influencersProcessing} />
    </div>
  );
}

export { AddInfluencersResults, AddManyInfluencers, OnlyAddManyInfluencers };
// export default AddManyInfluencers;
