import React from "react";
import HeaderComponent from "../HeaderComponent";
import { Link } from 'react-router-dom';
import "./InstagramContainer.css";
// import moment from 'moment';

class InstagramContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
    /*this.handleUpdateChannel = this.handleUpdateChannel.bind(this);*/
  }
  componentDidMount() {
    console.log('componentDidMount');
  }
  componentDidUpdate() {
    console.log('componentDidUpdate');
  }
  render() {
    return (
      <div className="instagram_container">
        <HeaderComponent title="Instagram" to="/a/dashboard" toTitle="Home" />
        <div className="instagram_container__content">
          <div className="instagram_container__content__card">
            <Link to='/a/dashboard/instagram/yesno'>Yes / No</Link>
          </div>
          <div className="instagram_container__content__card">
            <Link to='/a/dashboard/instagram/outreach'>To Outreach</Link>
          </div>
          <div className="instagram_container__content__card">
            <a href="#" className="instagram_container__content__card--isDisable">Channel Reports</a>
          </div>
          <div className="instagram_container__content__card">
            <a href="#" className="instagram_container__content__card--isDisable">Upload</a>
          </div>
          <div className="instagram_container__content__card">
            <a href="#" className="instagram_container__content__card--isDisable">Search Profile</a>
          </div>
        </div>
      </div>
    );
  }
}

export default InstagramContainer;
