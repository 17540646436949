import { useEffect, useState } from "react";
import axios from "axios";

import { GET_TAGS, GET_CAMPAIGN_TAGS } from "../../dictionaries";
import NotificationManager from "components/al_components/notification/NotificationManager";
import HeaderComponent from "components/HeaderComponent";
import { ALButton, ALContainer } from "components/ALComponents";
import "./AddTagsCampains.css";

function AddTagsCampaigns() {
  const [allTags, setAllTags] = useState(null);
  const [allCampains, setAllCampains] = useState(null);

  const [newTag, setNewTag] = useState(null);
  const [newCampain, setNewCampain] = useState(null);

  function setTagOrCampain(type) {
    let url = null;
    let data = null;

    if (type === "campaign") {
      const campaignExist = allCampains.find((t) => t.name.toLowerCase() === newCampain.toLowerCase()) || false;
      if (campaignExist) {
        setNewCampain("");
        NotificationManager.error("Campaign Already Exist", "Failed", 2000);
        return;
      }
      url = '/api/v1/campain_tags';
      data = {
        "name": newCampain.trim(),
        "description": "",
        "count": 0,
      }
    }


    if (type === "tag") {
      const tagExist = allTags.find((t) => t.name.toLowerCase() === newTag.toLowerCase()) || false;
      if (tagExist) {
        setNewTag("");
        NotificationManager.error("Tag Already Exist", "Failed", 2000);
        return;
      }
      url = '/api/v1/tags';
      data = {
        "name": newTag.trim(),
        "description": "",
        "count": 0,
      }
    }

    axios.post(url, data)
      .then((response) => {
        if (response.status === 200) {
          if (type === "tag") {
            setNewTag("");
            axios.get("/api/v1/tags").then((r) => {
              setAllTags(r.data.tags);
            });
          }
          if (type === "campaign") {
            setNewCampain("");
            axios.get("/api/v1/campain_tags").then((r) => {
              setAllCampains(r.data.tags);
            });
          }
          NotificationManager.success(`Successfully add new ${type}`, "", 1500);
        }
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error('Error', 'Creating discount: ' + error, 6000);
      });
  }

  useEffect(() => {
    GET_TAGS(true).then((tags) => setAllTags(tags));
    GET_CAMPAIGN_TAGS(true).then((campainTags) => setAllCampains(campainTags));
  }, []);

  return (
    <>
      <HeaderComponent title="" to="/a/dashboard/" toTitle="Home" />
      <ALContainer className="add_tag">
        <div className="add_tag_container">
          <h2 className="add_tag_container_title">Add a Tag</h2>
          <div className="add_tag_container_form">
            <input
              placeholder="Add new Tag"
              className="add_tag_container_input"
              type="text"
              value={newTag}
              onChange={(e) => {
                const formatValue = e.target.value.split("/");
                let newValue = formatValue[formatValue.length - 1];
                setNewTag(newValue);
              }}
            />
            <ALButton
              className={`al_button contained add_tag_container_button ${!newTag && "al_button--disable"}`}
              disabled={!newTag}
              onClick={() => setTagOrCampain("tag")}
            >
              ADD TAG
            </ALButton>
          </div>

          <div className="add_tag_container_current">
            <p className="add_tag_container_current_title">Current Tags</p>
            <div className="add_tag_container_current_all">
              {allTags && allTags.map(t => {
                return (
                  <span className="add_tag_container_current_tag">{t.name}</span>
                )
              })}
            </div>
          </div>
        </div>



        <div className="add_tag_container">
          <h2 className="add_tag_container_title">Add a Campaign</h2>
          <div className="add_tag_container_form">
            <input
              placeholder="Add new Tag"
              className="add_tag_container_input"
              type="text"
              value={newCampain}
              onChange={(e) => {
                const formatValue = e.target.value.split("/");
                let newValue = formatValue[formatValue.length - 1];
                setNewCampain(newValue);
              }}
            />
            <ALButton
              className={`al_button contained add_tag_container_button ${!newCampain && "al_button--disable"}`}
              disabled={!newCampain}
              onClick={() => setTagOrCampain("campaign")}
            >
              ADD CAMPAIGN
            </ALButton>
          </div>

          <div className="add_tag_container_current">
            <p className="add_tag_container_current_title">Current Campains</p>
            <div className="add_tag_container_current_all">
              {allCampains && allCampains.map(t => {
                return (
                  <span className="add_tag_container_current_tag">{t.name}</span>
                )
              })}
            </div>
          </div>
        </div>
      </ALContainer>
    </>
  );
}

export default AddTagsCampaigns;
