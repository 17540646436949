import React from "react";
import moment from 'moment';
import axios from 'axios';
import "../App.css";
import "./Channels.css";
import ScrapperWorking from './ScrapperWorking';
import Loading from "./Loading";
import ChannelInvestigateSwipe from "./ChannelInvestigateSwipe";
import ChannelData from "./ChannelData";

class Channels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilterValue: '',
      selectedFilterText: 'Filter by key',
      valueInputFilter: '',
      loadingData: false,
      data: null,
      lastUpdated: [],
      nbChannels: 0,
      user: null,
    };
    this.chooseKey = this.chooseKey.bind(this);
    this.handleChangeValueFilter = this.handleChangeValueFilter.bind(this);
    this.handleFindChannels = this.handleFindChannels.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.downloadCSVOutreach = this.downloadCSVOutreach.bind(this);
    /*this.handleUpdateChannel = this.handleUpdateChannel.bind(this);*/
  }

  componentDidMount() {
    axios.get("/auth/current_user").then(res => {
      this.setState({ user: res.data });
    });
  }

  // componentDidMount() {
    // this.scrollToBottom();
  // }

  componentDidUpdate() {
    if (this.state.valueInputFilter === "inv_tmp") {
      // this.scrollToBottom();
      var elmnt = document.getElementById("tt");
      elmnt.scrollIntoView();
    }
  }

  chooseKey(value, text) {
    this.setState({ selectedFilterValue: value, selectedFilterText: text, data: null });
  }

  handleChangeValueFilter(event) {
    if (event.target) {
      const value = event.target.value;
      this.setState({ valueInputFilter: value, data: null });
    }
  }

  handleFindChannels() {
    if (this.state.selectedFilterValue && this.state.valueInputFilter) {
      this.setState({ loadingData: true });
      fetch(`/api/v1/channels?key=${this.state.selectedFilterValue}&value=${this.state.valueInputFilter}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          return response.json();
        })
        .then(data => {
          console.log('DATA FROM INPUT');
          console.log(data);
          if (this.state.valueInputFilter === 'inv_tmp') {
            axios({
              method: 'GET',
              url: '/api/v1/inv_length'
            }).then((r) => {
              this.setState({ loadingData: false, data: data.channel, nbChannels: r.data.count });
            });
          } else {
            if (data.channel !== 'no channels') {
              this.setState({ loadingData: false, data: data.channel });
            } else {
              this.setState({ loadingData: false, data: null });
            }
          }
        });
    }
  }

  convertInstagramYouTubeUrl(instaStr) {
    if (instaStr && instaStr.includes("instagram.com")) {
      let urlRedirect = decodeURIComponent(instaStr).replace("redirect?", "");
      var fakeUrl = new URL("https://oklos.com?" + urlRedirect.toLowerCase());
      var paramQ = fakeUrl.searchParams.get("q");
      if (paramQ) {
        if (paramQ.includes("www.")) {
          paramQ = paramQ.replace("www.", "");
        }
        if (paramQ.includes("http://")) {
          paramQ = paramQ.replace("http://", "");
        }
        if (paramQ.includes("https://")) {
          paramQ = paramQ.replace("https://", "");
        }
        if (paramQ.includes("instagram.com")) {
          paramQ = paramQ.replace("instagram.com", "");
        }
        if (paramQ.includes("/")) {
          paramQ = paramQ.split("/").join("");
        }
        if (paramQ.includes("?hl=")) {
          var url = new URL("https://www.instagram.com/" + paramQ);
          var queryString = url.search;
          var searchParams = new URLSearchParams(queryString);
          searchParams.delete('hl');
          url.search = searchParams.toString();
          var newUrl = url.toString();
          newUrl = newUrl.replace("https://www.instagram.com/", "");
          return newUrl;
        } else {
          return paramQ;
        }
      }
      return "";
    } else {
      return "";
    }
  }

  downloadCSVOutreach(type) {
    let objArray = this.state.data;
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
      // let line = '';
      if (type === 'cocreation' && array[i]['statusReason'] !== 'cocreation accepted') {
        continue;
      }
      if (type === 'classic' && typeof array[i]['statusReason'] != 'undefined' && (array[i]['statusReason'] === 'sent for cocreation' || array[i]['statusReason'] === 'cocreation accepted')) {
        continue;
      }
      let emailValue = ''
      if (typeof array[i]['email'] == 'undefined') {
        emailValue = ''
      } else {
        emailValue = array[i]['email'].replace(',', ' |') // to remove "," if multiple email
      }
      let instagramHandle = '';
      if (typeof array[i]['instagram'] != 'undefined') {
        instagramHandle = this.convertInstagramYouTubeUrl(array[i]['instagram']);
      }

      let statusReasonValue = '';
      if (typeof array[i]['statusReason'] != 'undefined') {
        statusReasonValue = array[i]['statusReason'].replace(',', ' |').replace(/(\n)/gm,'');
      }
      let averageView = ''
      if (typeof array[i]['averageView'] != 'undefined') {
        averageView = parseInt(array[i]['averageView']);
      }

      let engagement = '';
      if (typeof array[i]['engagement'] != 'undefined' && array[i]['engagement'] !== '') {
        engagement = array[i]['engagement'];
      } else {
        if (typeof array[i]['dataVideos'] != 'undefined') {
          let totalThumbUp = 0;
          let totalThumbDown = 0;
          let totalComment = 0
          let totalViews = 0;
          for (var j = 1; j < array[i]['dataVideos'].length; j++) {
            totalThumbUp += parseInt(array[i]['dataVideos'][j]['like']);
            totalThumbDown += parseInt(array[i]['dataVideos'][j]['dislike']);
            totalViews += parseInt(array[i]['dataVideos'][j]['views']);
          }
          let engagementRate = (((((totalThumbUp - totalThumbDown) / 2) + totalComment) / totalViews) * 100).toFixed(2);
          // console.log(engagementRate);
          engagement = engagementRate + "%"
        }
      }

      let country = '';
      if (typeof array[i]['country'] != 'undefined') {
        country = array[i]['country'];
      }

      let line = 'https://www.youtube.com/channel/'+array[i]['channelId'] + ',' + emailValue + ',' + instagramHandle + ',' + averageView + ',' + engagement + ',' + country + ',,' + statusReasonValue;
      str += line + '\r\n';
    }
    // console.log(str);

    let nameType = ''
    if (type === 'cocreation') {
      nameType = 'cocreation_';
    }
    let filename = 'toOutreach_' + nameType + new Date().toISOString() + '.csv';
    let contentType = "text/csv;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(str))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(str);
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  // sanitizeKeyword(keyword) {
  //   keyword = keyword.toLowerCase().replace("'", "_");
  //   // override special char
  //   // replace all ( by nothing
  //   keyword = keyword.split('(').join('');
  //   // replace all ) by nothing
  //   keyword = keyword.split(')').join('');
  //   // replace all ' by _
  //   keyword = keyword.split("'").join('');
  //   // replace all & by _and_
  //   keyword = keyword.split('&').join('_and_');
  //   // replace all . by _
  //   keyword = keyword.split('.').join('_');
  //   // replace all " "  by _
  //   keyword = keyword.split(' ').join('_');
  //   return keyword;
  // }

  downloadCSV() {
    let objArray = this.state.data;
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ','
          line += (index === 'channelId') ? `https://www.youtube.com/channel/${array[i][index]}` : array[i][index];
      }
      str += line + '\r\n';
    }
    // return str;

    let filename = this.state.valueInputFilter + '_' + new Date().toISOString() + '.csv';
    let contentType = "text/csv;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(str))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(str);
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }


  updateRedisDb(status, keyword) {
    // update redis
    let urlYESNO = '';
    if (status === 'yes') {
      urlYESNO = '/api/v1/redis/yes';
    } else {
      if (status === 'no') {
        urlYESNO = '/api/v1/redis/no';
      } else {
        return;
      }
    }

    axios({
      method: 'post',
      url: urlYESNO,
    }).then((r) => {
      if (r.data.message === "ok") {
        // update keyword if exist
        if (typeof(keyword) === 'undefined') {
          keyword = '';
        }
        // keyword = this.sanitizeKeyword(keyword)
        axios({
          method: 'post',
          url: '/api/v1/redis/kw',
          data: {
            'status': status,
            'keyword': keyword
          }
        }).then((rKeyword) => {
          console.log(rKeyword);
          if (rKeyword.data.message === "ok") {
            console.log('ok update kw');
          } else {
            console.error(rKeyword.data)
            // alert('CONTACT @FRANCOIS (code 8762 redis):');
          }
        })
        .catch((err) => {
          console.error(status, keyword)
          // alert('CONTACT @FRANCOIS (code 8763 redis): ' + err);
        });
      }
      // } else {
      //   alert('CONTACT @FRANCOIS (code 8765 redis):');
      // }
    })
    .catch((err) => {
      console.log(urlYESNO)
      // alert('CONTACT @FRANCOIS (code 8764 redis): ' + err);
    });
  }


  handleToOutreachChannel(channelId) {
    let tmpData = this.state.data;

    // remove current channel from channel = []
    let chnToUpdate = tmpData.splice(tmpData.findIndex(function(i){
      return i.channelId === channelId;
    }), 1);

    const theChannel = {
      "channelId": channelId,
      "status": "toScrap"
    };

    axios({
      method: 'put',
      url: `/api/v1/youtube/${theChannel.channelId}`,
      data: theChannel
    }).then((r) => {
      console.log('on click yes');
      console.log(r);
      if (r.status === 200) {
        this.setState({ data: tmpData });
        // get new channels
        if (tmpData.length === 1) {
          this.handleFindChannels();
        }
        // update redis
        this.updateRedisDb('yes', chnToUpdate[0].keyword);
      }
    })
    .catch((err) => {
      alert('CONTACT @FRANCOIS (code 2313): ' + err);
    });
  }


  handleDeclinedChannel(channelId) {
    let tmpData = this.state.data;

    // remove current channel from channel = []
    let chnToUpdate = tmpData.splice(tmpData.findIndex(function(i){
      return i.channelId === channelId;
    }), 1);

    const theChannel = {
      "channelId": channelId,
      "status": "declined_ro",
      "statusReason": "declined yes or no"
    };

    axios({
      method: 'put',
      url: `/api/v1/youtube/${theChannel.channelId}`,
      data: theChannel
    }).then((r) => {
      console.log('on click no');
      console.log(r);
      if (r.status === 200) {
        this.setState({ data: tmpData });
        // get new channels
        if (tmpData.length === 1) {
          this.handleFindChannels();
        }
        // update redis
        this.updateRedisDb('no', chnToUpdate[0].keyword);
      }
    })
    .catch((err) => {
      alert('CONTACT @FRANCOIS (code 2313): ' + err);
    });
  }


  handleCocreationChannel(channel) {
    console.log('handleCocreationChannel');
    console.log(channel);
    let theChannel = channel;
    theChannel.status = 'toOutreach_cocreation';
    theChannel.statusReason = 'cocreation';

    axios({
      method: 'put',
      url: `/api/v1/youtube/${theChannel.channelId}`,
      data: theChannel
    }).then((r) => {
      if (r.status === 200) {
        let newChannels = this.state.getNewChannel;
        if (newChannels < 6) {
          newChannels+=1;
        } else {
          newChannels = 1;
        }
        this.setState({ lastUpdated: [...this.state.lastUpdated, channel], getNewChannel: newChannels });
        // get new channels
        if (newChannels === 1) {
          this.handleFindChannels();
        }
        // TODO: add channel to array of last elements

        const webhookSlackUrl = "https://hooks.slack.com/services/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";
        let objMessageSlack = {
          "blocks": [
            {
              "type": "section",
              "text": {
                "type": "mrkdwn",
                "text": ""
              }
            },
            {
              "type": "section",
              "block_id": "section567",
              "text": {
                "type": "mrkdwn",
                "text": ""
              },
              "accessory": {
                "type": "image",
                "image_url": "",
                "alt_text": "YouTube Channel profile"
              }
            }
          ]
        };
        let theFirstName = '';
        let theLastName = '';
        if (this.state.user) {
          if (typeof(this.state.user.firstName) != "undefined") {
            theFirstName = this.state.user.firstName;
          }
          if (typeof(this.state.user.lastName) != "undefined") {
            theLastName = this.state.user.lastName;
          }
        }
        if (theFirstName.length > 0 && theLastName.length > 0) {
          objMessageSlack["blocks"][0]["text"]["text"] = "*🎥 A channel is qualified for a co-creation* detected by _" + this.state.user.firstName + " " + this.state.user.lastName + "_";
        } else {
          objMessageSlack["blocks"][0]["text"]["text"] = "*🎥 A channel is qualified for a co-creation* detected";
        }
        objMessageSlack["blocks"][1]["text"]["text"] = `
          <https://www.youtube.com/channel/${theChannel.channelId}|${(theChannel.dataUser.nameChannel) ? theChannel.dataUser.nameChannel : "View channel"}>\n${(theChannel.dataUser.subscriber) ? "*Subscriber:* " + theChannel.dataUser.subscriber + '\n' : ''}${(theChannel.dataUser.views) ? "*Total views:* " + theChannel.dataUser.views + '\n': ''}${(theChannel.engagement) ? "*Engagement:* " + theChannel.engagement + '\n': ''}${(theChannel.keyword) ? "*Keyword:* " + theChannel.keyword + '\n': ''}
        `;
        objMessageSlack["blocks"][1]["accessory"]["image_url"] = (theChannel.dataUser.thumbnail) ? (
          theChannel.dataUser.thumbnail
        ) : (
          "https://s.ytimg.com/yts/img/avatar_720-vflYJnzBZ.png"
        );

        axios.post(webhookSlackUrl, JSON.stringify(objMessageSlack), {
          withCredentials: false,
          transformRequest: [(data, headers) => {
            delete headers.post["Content-Type"]
            return data
          }]
        });

      }
    })
    .catch((err) => {
      alert('CONTACT @FRANCOIS (code 2316): ' + err);
    });
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth", block: "end" });
  }

  handleChangeSelectFilter(e) {
    this.setState({
      selectedFilterValue: e.target.value,
      // selectedFilterText: text,
      data: null
    })
  }


  render() {
    let renderDataTab;
    if (this.state.data && this.state.selectedFilterValue !== 'am' && this.state.valueInputFilter !== 'toScrap' && this.state.valueInputFilter !== 'inv' && this.state.valueInputFilter !== 'inv_tmp' && this.state.valueInputFilter !== 'scrapped'  && this.state.valueInputFilter !== 'toOutreach' && this.state.valueInputFilter !== 'toOutreach_cocreation') {
      renderDataTab = this.state.data.map((channel, i) => {
        let valuesData;
        const keysChannelObj = Object.keys(channel);
        console.log(channel);
        console.log(keysChannelObj);
        // if (i === 0) {
        //   console.log('here?');
        //   headerData = keysChannelObjObject.keys(channel).map(key => {
        //     if (key !== '_id' && key !== '__v') {
        //       return (<div class="">{key}</div>);
        //     }
        //     return null;
        //   });
        // }
        valuesData = keysChannelObj.map((key, i) => {
          if (key !== '_id' && key !== '__v' && key !== "dataVideos") {
            return (<td key={`td_key_${i}`} className="data__values__el">{channel[key]}</td>);
          }
          return null;
        });
        return (
          <tr key={`tr_key_${i}`} className="data__values">{valuesData}</tr>
        );
      })
    }

    let numberChannelClassic = 0;
    let numberChannelCocreation = 0;
    if (this.state.data) {
      if (this.state.valueInputFilter === 'toOutreach' && this.state.data.length > 0) {
        this.state.data.map(ch => {
          if (ch.statusReason === 'cocreation accepted') {
            numberChannelCocreation += 1;
          } else {
            numberChannelClassic += 1;
          }
        });
      }
    }

    return (
      <div className="channels-page-wrapper">
        <div><ScrapperWorking /></div>
        <h2 style={{textAlign: 'center'}}>Channels</h2>
        <div className="filter_container">
          <div className="filter_container__dropdown">
            <select onChange={this.handleChangeSelectFilter.bind(this)} value={this.state.selectedFilterValue}>
              <option value="">Filter by key</option>
              <option value="channelId">Channel ID</option>
              <option value="email">Email</option>
              <option value="status">Status</option>
            </select>
          </div>

          <div className="mdl-textfield mdl-js-textfield filter_container__input">
            <input className="mdl-textfield__input" type="text" id="filter_value" value={this.state.valueInputFilter || ''} onChange={this.handleChangeValueFilter} />
            <label className="mdl-textfield__label" forhtml="filter_value">filter value...</label>
          </div>

          <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent filter_container__button" onClick={this.handleFindChannels}>
            Find
          </button>
        </div>


        {(!this.state.selectedFilterValue) ? <ChannelData /> : ''}

        <div className="result">
          {(this.state.loadingData) ?
            <Loading /> : (
              (this.state.data) ? (
              <div className="result_container" ref={(el) => { this.messagesEnd = el; }}>
                <h4>{(this.state.valueInputFilter === 'inv_tmp') ? `Result (${this.state.nbChannels} channels)` : `Result (${this.state.data.length} channels)` }</h4>
                {(this.state.valueInputFilter === 'scrapped' || this.state.valueInputFilter === 'toScrap') ? (
                  this.state.data.map((channel, i) => {
                    let buttonDownload;
                    if (i === 0) {
                      buttonDownload = <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent" style={{'display': 'flex'}} onClick={this.downloadCSV}>Download list</button>;
                    }
                    return (
                      <div className="result_container__links" key={`listchannel_${i}`}>
                        {buttonDownload}
                        <span>{`https://www.youtube.com/channel/${channel.channelId}`}</span>
                      </div>
                    )
                  })
                ) : null}
                {(this.state.valueInputFilter === 'toOutreach' || this.state.valueInputFilter === 'toOutreach_cocreation') ? (
                  <div className="channel_toOutreach">
                    <div className="channel_toOutreach__classic">
                      <p>Number channel: {numberChannelClassic}</p>
                      <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent button--tooutreach" onClick={() => this.downloadCSVOutreach('classic')}>list to outreach</button>
                    </div>
                    <div className="channel_toOutreach__cocreation">
                      <a href="/a/dashboard/channels/cocreation">COCREATION QUALIFICATION</a>
                      <p>Number channel (cocreation): {numberChannelCocreation}</p>
                      <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent button--tooutreach" onClick={() => this.downloadCSVOutreach('cocreation')}>list to outreach co-creation</button>
                    </div>
                  </div>
                ) : null}
                {(renderDataTab) ?
                  <table className="data_channels">
                    <tbody>
                      <tr className="data__key">{Object.keys(this.state.data[0]).map((key, i) => {
                        if (key !== '_id' && key !== '__v') {
                          return (<td key={`key_data_key_${i}`} className="data__key__el">{key}</td>);
                        }
                        return null;
                      })}</tr>
                      {renderDataTab}
                    </tbody>
                  </table>
                  : null}
                <div>
                {(this.state.valueInputFilter === 'inv_tmp' && this.state.data) ? (
                  <div>
                    {(this.state.data[0].channelId) ?
                      <ChannelInvestigateSwipe
                        key={this.state.data[0].channelId}
                        channelBase={this.state.data[0]}
                        onDeclined={() => this.handleDeclinedChannel(this.state.data[0].channelId)}
                        onOutreach={() => this.handleToOutreachChannel(this.state.data[0].channelId)}
                        onCocreation={() => this.handleCocreationChannel(this.state.data[0].channelId)}
                      />
                      : null}
                    {/*this.state.data.map((channel, i) => {
                      if (i < 6) {
                        return <ChannelInvestigateSwipe key={channel.channelId} channel={channel} channelBase={channel} />;
                      }
                    })*/}
                    {/*this.state.data.map((channel, i) => {
                      if (i < 6) {
                        return <ChannelInvestigate key={channel.channelId} channel={channel} channelBase={channel} />;
                      }
                    })*/}
                  </div>
                ) : null}
                </div>
              </div>) : ('')
          )}
        </div>
        <div id="tt">&nbsp;</div>
      </div>
    );
  }
}

export default Channels;
