import React from "react";
import moment from 'moment';
import axios from "axios";
import Loading from "../Loading";
import LabelStatus from "../YouTube/LabelStatus";
import emptyProfile from '../../assets/empty_profile.png';
import "./YouTubeModal.css";

class YouTubeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channelId: this.props.channelId,
      handle: this.props.handleInsta,
      channelData: this.props.youtubeData,
      instaData: this.props.instaData,
      isLoading: (this.props.youtubeData) ? false : true,
      valueChannelId: "",
      message: null,
      channelUpdated: null,
      activeTab: 'deal_info', // 'youtube_info'
    };
    this.updateChannelId = this.updateChannelId.bind(this);
    this.getNewDataYoutube = this.getNewDataYoutube.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.fmtMSS = this.fmtMSS.bind(this);
  }

  getNewDataYoutube(channelId) {
    // GET NEW DATA
    console.log("get New Data Youtube");
    axios({
      method: 'GET',
      url: '/api/v1/aws/channel/' + channelId
    }).then((res) => {
      console.log(res, "CHANNEL");
      axios({
        method: 'GET',
        url: '/api/v1/youtube/channel/videos/' + channelId
      }).then((r) => {
        console.log("NEW DATA!!");
        console.log(r);
        let channelUpdated = res.data.result;
        channelUpdated["videos"] = r.data.videos;
        console.log(channelUpdated, "FINAL DATA");
        this.setState({ channelUpdated: channelUpdated });
      });
    });
  }

  componentDidMount() {
    console.log(this.props);
    const { channelId, handleInsta, youtubeData } = this.props;
    if (!youtubeData) {
      if (channelId && handleInsta) {
        // request from youtube collection with channelId
        axios({
          method: 'GET',
          url: '/api/v1/youtube/' + channelId,
        }).then((r) => {
          if (r.data.message === 'ok') {
            // found channel
            this.setState({
              channelData: r.data.channel,
              channelId: r.data.channel.channelId,
              isLoading: false
            });
          } else {
            if (handleInsta) {
              axios({
                method: 'GET',
                url: '/api/v1/yt_insta/' + handleInsta,
              }).then((r2) => {
                if (r2.data.message === 'ok') {
                  // found channel
                  this.setState({
                    channelData: r2.data.channel,
                    channelId: r2.data.channel.channelId,
                    isLoading: false
                  });
                } else {
                  // NO DATA AT ALL
                  console.log("NO DATA FOUND FROM YT COLLECTION FOR THIS CHANNEL 1");
                  this.setState({
                    isLoading: false
                  })
                }
              });
            }
          }
        })
      } else {
        if (handleInsta && !channelId) {
          axios({
            method: 'GET',
            url: '/api/v1/yt_insta/' + handleInsta,
          }).then((r2) => {
            if (r2.data.message === 'ok') {
              // found channel
              this.setState({
                channelData: r2.data.channel,
                channelId: r2.data.channel.channelId,
                isLoading: false
              });
            } else {
              // NO DATA AT ALL
              console.log("NO DATA FOUND FROM YT COLLECTION FOR THIS CHANNEL 2");
              this.setState({
                isLoading: false
              })
            }
          });
        }
      }
    } else {
      if (!this.state.channelId) {
        console.log("NO CHANNEL ID BUT CHANNEL BASE");
        this.setState({ channelId: youtubeData.channelId });
      } else {
        this.getNewDataYoutube(this.props.channelId);
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(prevState, "prevState");
    console.log(this.state, "this.state");
    if (prevState.channelId !== this.state.channelId) {
      this.getNewDataYoutube(this.state.channelId);
    }
  }

  handleChange(e) {
    if (e.target.name === "channelId") {
      this.setState({ valueChannelId: e.target.value });
    }
  }


  updateChannelId() {
    const firstChar = this.state.valueChannelId.slice(0, 2);
    if (firstChar === "UC" && this.state.valueChannelId && this.state.handle) {
      console.log("OK UPDATE");
      console.log(this.state.valueChannelId);
      console.log(this.state.handle);
      axios({
        method: 'PUT',
        url: '/api/v1/insta_youtube/' + this.state.handle + '/' + this.state.valueChannelId,
      }).then((r) => {
        if (r.data.description === "updated") {
          // show data from query
          axios({
            method: 'GET',
            url: '/api/v1/youtube/' + this.state.valueChannelId,
          }).then((r) => {
            if (r.data.message === 'ok') {
              // found channel
              this.setState({
                channelData: r.data.channel,
                channelId: r.data.channel.channelId
              });
            }
          });
        } else {
          this.setState({
            message: "It's a new channel!"
          });
        }
      });
    }
  }

  toggleTab(type) {
    console.log("toggleTab");
    console.log(type);
    const { activeTab } = this.state;
    if (activeTab === type) {
      return;
    } else {
      this.setState({ "activeTab": type });
    }
  }

  fmtMSS(s) {
    return(s-(s%=60))/60+(9<s?':':':0')+s
  }

  render() {
    const { channelData, isLoading, valueChannelId, message, instaData, activeTab, channelUpdated } = this.state;
    let htmlEmail = null;
    if (channelData || instaData ) {
      if (((channelData && "email" in channelData) && (instaData && "email" in instaData)) && (channelData.email === instaData.email)) {
        htmlEmail = <p className={`content_val content-email content-email--same`}><span className="content_val--key">Email:</span><span className="content_val--val">{channelData.email} <i className="material-icons">done_all</i></span></p>;
      } else if ((channelData && "email" in channelData) && (instaData && "email" in instaData) && (channelData.email !== instaData.email)) {
        // insta email exist and youtube email exist BUT they are different
        htmlEmail = <p className={`content_val content-email content-email--diff`}><span className="content_val--key">Email:</span><span className="cotnent_val--val">{channelData.email} {instaData.email} <i className="material-icons">notifications_active</i></span></p>;
      } else if ((channelData && "email" in channelData) && (instaData && "email" in instaData) && !instaData.email) {
        // youtube email
        htmlEmail = <p className={`content_val content-email content-email--yt`}><span className="content_val--key">Email:</span><span className="content_val--val">{channelData.email}</span></p>;
      } else if ((channelData && "email" in channelData) && (instaData && "email" in instaData) && !channelData.email) {
        // insta email
        htmlEmail = <p className={`content_val content-email content-email--insta`}><span className="content_val--key">Email:</span><span className="content_val--val">{instaData.email}</span></p>;
      } else {
        // NO EMAIL!!
        htmlEmail = <p className={`content_val content-email content-email--empty`}><span className="content_val--key">Email:</span><span className="content_val--val"><i className="material-icons">block</i></span></p>;
      }
    }

    return (
      <div className="youtubemodal-wrapper">
        {(this.state.channelData && !isLoading) ? (
        <div>
          <div className="youtubemodal-wrapper__header">
            {(channelUpdated) ? (
              <div className="youtubemodal-wrapper__header">
                <div className="wrapper__header__thumbail">
                  {(channelUpdated.pictureURL) ? (
                    <img src={channelUpdated.pictureURL} alt="thumbnail youtube channel" />
                  ) : (
                    <img src={emptyProfile} alt="thumbnail youtube channel empty" />
                  )}
                </div>
                <div className="wrapper__header__info">
                  <p className="info-nameChannel">{(channelUpdated.title) ? (channelUpdated.title) : ("")}</p>
                  <p className="info-subscriber">{(channelUpdated.stats && channelUpdated.stats.subscribers) ? `${(channelUpdated.stats.subscribers).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} subscribers` : ("")}</p>
                  <p className="info-views">{(channelUpdated.stats && channelUpdated.stats.views) ? `${(channelUpdated.stats.views).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} views` : ("")}</p>
                </div>
              </div>
            ) : (
              <div className="youtubemodal-wrapper__header">
                <div className="wrapper__header__thumbail">
                  {(channelData.dataUser && channelData.dataUser.thumbnail) ? (
                    <img src={channelData.dataUser.thumbnail} alt="thumbnail youtube channel" />
                  ) : (
                    <img src={emptyProfile} alt="thumbnail youtube channel empty" />
                  )}
                </div>
                <div className="wrapper__header__info">
                  <p className="info-nameChannel">{(channelData.dataUser && channelData.dataUser.nameChannel) ? (channelData.dataUser.nameChannel) : ("")}</p>
                  <p className="info-subscriber">{(channelData.dataUser && channelData.dataUser.subscriber) ? `${(channelData.dataUser.subscriber)} subscriber` : ("")}</p>
                  <p className="info-views">{(channelData.dataUser && channelData.dataUser.views) ? (channelData.dataUser.views) : ("")}</p>
                </div>
              </div>
            )}

            <div className="header_view-channel">
              <a target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/channel/${channelData.channelId}`}>VIEW</a>
            </div>
            <div className="header_close" onClick={this.props.closeModal}>
              <i className="material-icons">close</i>
            </div>
          </div>
          <div className="youtubemodal-wrapper__content">
            <div className="youtubemodal-wrapper__content__tabs">
              <p className={`youtubemodal-wrapper__content__tabs__tab ${(activeTab === 'deal_info') ? ('isActive') : (null)}`} onClick={() => this.toggleTab('deal_info')}>Deal informations</p>
              <p className={`youtubemodal-wrapper__content__tabs__tab ${(activeTab === 'youtube_info') ? ('isActive') : (null)}`} onClick={() => this.toggleTab('youtube_info')}>YouTube Data</p>
            </div>

            {(activeTab === 'deal_info') ? (
              <div>
                <div className="youtubemodal-wrapper__content__header">
                  {(channelData.status) ? (<LabelStatus status={channelData.status} />) : (null)}
                  <div className="content-am">{(channelData.am) ? (<span><i className="material-icons">drafts</i> {channelData.am}</span>) : (<span><i className="material-icons">drafts</i></span>)}</div>
                </div>
                <div className="youtubemodal-wrapper__content__main">
                  {htmlEmail}
                  {(channelData.statusReason) ? (
                    <p className="content-comment content_val">
                      <span className="content_val--key">Comment:</span>
                      <span className="content_val--val">{channelData.statusReason}</span>
                    </p>
                  ) : (null)}
                  {(channelData.country) ? (
                    <p className="content-country content_val">
                      <span className="content_val--key">Country:</span>
                      <span className="content_val--val">{channelData.country}</span>
                    </p>
                  ) : (null)}
                  {(channelData.price) ? (
                    <p className="content-price content_val">
                      <span className="content_val--key">Price:</span>
                      <span className="content_val--val">{channelData.price}</span>
                    </p>
                  ) : (null)}
                  {(channelData.engagement) ? (
                    <p className="content-engagement content_val">
                      <span className="content_val--key">Engagement:</span>
                      <span className="content_val--val">{channelData.engagement}</span>
                    </p>
                  ) : (null)}
                </div>
              </div>
            ) : (null)}
            {(activeTab === 'youtube_info') ? (
              <div className="youtubemodal-wrapper__content__main">
                {(channelUpdated) ? (
                  (channelUpdated.videos && channelUpdated.videos.length > 0) ? (
                    <div className="youtubemodal_videos">
                      {(channelUpdated.videos).map((video) => {
                        return (
                          <div className="youtubemodal_video">
                            <a className="youtubemodal_video__thumbnail" href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer">
                              <img className="youtubemodal_video__thumbnail__image" alt="thumbnail video" src={video.pictureURL} />
                              <span className="youtubemodal_video__thumbnail__duration">{this.fmtMSS(video.duration)}</span>
                            </a>
                            <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer" className="youtubemodal_video__title">{video.title}</a>
                            <div className="youtubemodal_video__stats">
                              <p className="youtubemodal_video__stats__views">
                                <i className="material-icons">visibility</i>{(video.stats.views) ? (video.stats.views) : (null)}
                              </p>
                              <p className="youtubemodal_video__stats__likes">
                                <i className="material-icons">thumb_up</i>{(video.stats.likes) ? (video.stats.likes) : (null)}
                              </p>
                              <p className="youtubemodal_video__stats__dislikes">
                                <i className="material-icons">thumb_down</i>{(video.stats.dislikes) ? (video.stats.dislikes) : (null)}
                              </p>
                            </div>
                            <p className="youtubemodal_video__date">{moment(video.datePublished).from(moment())}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p>No videos...</p>
                  )
                ) : (<Loading />)}
              </div>
            ) : (null)}
            <div className="youtubemodal-wrapper__content_qualif">
              <button onClick={() => this.props.onOutreachYouTube((this.state.channelId) ? this.state.channelId : this.state.channelData.channelId)} className="mdl-button mdl-js-button youtubemodal-wrapper__content_qualif__button">SEND YOUTUBE OUTREACH <i className="material-icons">send</i></button>
            </div>
          </div>
        </div>
        ) : (
          (isLoading) ? (
            <Loading />
          ) : (
            (message) ? (
              <div>
                <div className="header_close" onClick={this.props.closeModal}>
                  <i className="material-icons">close</i>
                </div>
                <div className="youtubemodal-wrapper__content--message">
                  <p>{message}</p>
                </div>
              </div>
            ) : (
              <div className="youtubemodal-wrapper__content--update">
                <div className="header_close" onClick={this.props.closeModal}>
                  <i className="material-icons">close</i>
                </div>
                <p className="content--update__title">
                  Update channelId?
                </p>
                <div className="content--update__container">
                  <input name="channelId" className="content--update__container-input" type="text" value={valueChannelId} onChange={(e) => {this.handleChange(e)}} />
                  {(valueChannelId.length > 24 || valueChannelId.length < 24) ? (
                    <div className="content--update__container-button--disabled">UPDATE</div>
                  ) : (
                    <button className="content--update__container-button">UPDATE</button>
                  )}
                </div>
                {(valueChannelId.length > 24 || valueChannelId.length < 24) ? (
                  <p className="content--update__limit content--update__limit--isError">A Channel Id should start with "UC" and contain 24 letters</p>
                ) : (null)}
              </div>
            )
          )
        )}
      </div>
    );
  }
}

export default YouTubeModal;

