import React from "react";

import "./auto_complete_input.css";

class AutoCompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isOpen: false,
      hoveringIndex: -1,
    };
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }
  handleOnBlur(e) {
    // Firefox onBlur issue
    if (e.nativeEvent.explicitOriginalTarget && e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
      return;
    }
    if (this.state.isOpen) {
      setTimeout(() => this.setState({ isOpen: false }), 200);
    }
  }
  handleOnFocus(e) {
    this.setState({ isOpen: true });
  }
  handleOnChange(e) {
    const nextValue = e.target.value;
    const recommendation = (this.props.list || []).filter((option) =>
      (typeof option === "string" ? option : option[this.props.useValue]).toLocaleLowerCase().includes(nextValue.toLocaleLowerCase())
    );
    let nextState = { value: nextValue };
    if (this.state.hoveringIndex >= recommendation.length) {
      nextState.hoveringIndex = recommendation.length - 1;
    }
    this.setState(nextState);
  }
  onSelection(elem) {
    this.setState({ value: "", hoveringIndex: -1, isOpen: false }, () => this.props.onSelection(elem));
  }
  handleKeyDown(e) {
    const up = 38;
    const down = 40;
    const enter = 13;
    const tab = 9;
    const ctrl = 17;
    if (e.ctrlKey) return this.props.onCtrlCommand && this.props.onCtrlCommand(e);
    if (this.state.isOpen && e.keyCode === tab) return setTimeout(() => this.setState({ isOpen: false }), 100);
    if (!this.state.isOpen) return setTimeout(() => this.setState({ isOpen: true }), 100);
    if (e.keyCode !== up && e.keyCode !== down && e.keyCode !== enter) return;
    e.preventDefault();
    e.stopPropagation();
    const recommendation = (this.props.list || []).filter((option) =>
      (typeof option === "string" ? option : option[this.props.useValue]).toLocaleLowerCase().includes(this.state.value.toLocaleLowerCase())
    );
    if (e.keyCode === up && this.state.hoveringIndex > 0) {
      return this.setState({ hoveringIndex: this.state.hoveringIndex - 1 });
    }
    if (e.keyCode === down && this.state.hoveringIndex < recommendation.length - 1) {
      return this.setState({ hoveringIndex: this.state.hoveringIndex + 1 });
    }
    if (e.keyCode === enter) {
      if (this.state.hoveringIndex >= 0) {
        return this.onSelection(recommendation[this.state.hoveringIndex]);
      } else {
        return this.onSelection(this.state.value);
      }
    }
    return;
  }
  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.list.length != this.props.list.length) {
      const recommendation = (this.props.list || []).filter((option) =>
        (typeof option === "string" ? option : option[this.props.useValue]).toLocaleLowerCase().includes(this.state.value.toLocaleLowerCase())
      );
      if (this.state.hoveringIndex >= recommendation.length) {
        this.setState({ hoveringIndex: recommendation.length - 1 });
      }
    }
  }
  render() {
    const { value, isOpen, hoveringIndex } = this.state;
    const { useValue } = this.props;
    const recommendation = (this.props.list || []).filter((option) =>
      (typeof option === "string" ? option : option[useValue]).toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    return (
      <div className="autocomplete-input">
        <div className="autocomplete-input__header">
          <input
            type="text"
            placeholder={this.props.placeholder}
            className={`autocomplete-input__header__input${isOpen && recommendation.length > 0 ? "--focus" : ""}`}
            value={value}
            onBlur={this.handleOnBlur}
            onFocus={this.handleOnFocus}
            onChange={this.handleOnChange}
            onKeyDown={this.handleKeyDown}
            onClick={() => !isOpen && this.setState({ isOpen: true })}
          />
        </div>
        {recommendation.length > 0 && isOpen ? (
          <div className="autocomplete-input__dropdown" tabIndex="-1">
            {recommendation.map((option, idx) => (
              <div
                key={idx}
                className={`autocomplete-input__dropdown__line${idx === hoveringIndex ? "--hover" : ""}`}
                onMouseOver={() => this.setState({ hoveringIndex: idx })}
                onClick={() => this.onSelection(option)}>
                {typeof option === "string" ? option : option[useValue]}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

export default AutoCompleteInput;
