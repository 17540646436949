import React from "react";
import axios from "axios";
import { differenceInDays, format } from "date-fns";
import HeaderComponent from "../HeaderComponent";
import ALDateRange from "components/al_components/date_range";
import { ALLoading } from "components/ALComponents";
import { splitIntervalIntoChunks } from "utils";

import "./QualificationReporting.css";

class QualificationReporting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: { startDate: new Date(), endDate: new Date() },
      currentDayData: { no: null, yes: null },
      data: { no: null, yes: null },
      isLoadingData: false,
      loadingText: "",
    };
    this.setDateRange = this.setDateRange.bind(this);
    this.getTodaysData = this.getTodaysData.bind(this);
  }
  componentDidMount() {
    this.getTodaysData();
  }

  async getTodaysData() {
    const currentDay = format(new Date(), "yyyy-MM-dd");

    let _data = { no: null, yes: null };
    const resQualificationNo = await axios.get(`/api/v1/event/reports?day_from=${currentDay}&day_to=${currentDay}&types=QUALIFICATION_NO`);
    if (resQualificationNo.status == 200) {
      _data["no"] = resQualificationNo.data.result;
    }
    const resQualificationYes = await axios.get(`/api/v1/event/reports?day_from=${currentDay}&day_to=${currentDay}&types=QUALIFICATION_YES`);
    if (resQualificationYes.status == 200) {
      _data["yes"] = resQualificationYes.data.result;
    }

    this.setState({ currentDayData: _data });
  }
  async setDateRange(dateRange) {
    const batchSize = 5; // 5 days batch
    const chunksOfDates = splitIntervalIntoChunks(dateRange.startDate, dateRange.endDate, batchSize);
    const totalDays = differenceInDays(dateRange.endDate, dateRange.startDate);

    this.setState({ isLoadingData: true });
    let _data = { no: [], yes: [] };

    // Process NOs
    for (const [index, chunk] of chunksOfDates.entries()) {
      const { start, end } = chunk;
      const resQualificationNo = await axios.get(
        `/api/v1/event/reports?day_from=${format(start, "yyyy-MM-dd")}&day_to=${format(end, "yyyy-MM-dd")}&types=QUALIFICATION_NO`
      );
      if (resQualificationNo.status === 200) {
        _data["no"] = [..._data["no"], ...(resQualificationNo.data.result || [])];
      }
      this.setState({
        loadingText: `Days loaded: [NO]: ${index * batchSize + differenceInDays(end, start)}/${totalDays} [YES]: 0/${totalDays}`,
      });
    }
    // Process YESes
    for (const [index, chunk] of chunksOfDates.entries()) {
      const { start, end } = chunk;
      const resQualificationYes = await axios.get(
        `/api/v1/event/reports?day_from=${format(start, "yyyy-MM-dd")}&day_to=${format(end, "yyyy-MM-dd")}&types=QUALIFICATION_YES`
      );
      if (resQualificationYes.status == 200) {
        _data["yes"] = [..._data["yes"], ...(resQualificationYes.data.result || [])];
      }
      this.setState({
        loadingText: `Days loaded: [NO]: ${totalDays}/${totalDays} [YES]: ${index * batchSize + differenceInDays(end, start)}/${totalDays}`,
      });
    }

    this.setState({
      data: _data,
      dateRange,
      isLoadingData: false,
      loadingText: "",
    });
  }

  convertDataIntoTabs(data) {
    let _tableData = [];
    let _total = null;
    let _total_yes = null;
    let _total_no = null;
    let _data_by_users = {};
    if (data) {
      _data_by_users = (data["no"] || []).reduce(
        (acc, e) => ({ ...acc, [e["user"]]: { ...(acc[e["user"]] || {}), no: (acc[e["user"]]?.["no"] || 0) + e["nsamples"] } }),
        _data_by_users
      );
      _data_by_users = (data["yes"] || []).reduce(
        (acc, e) => ({ ...acc, [e["user"]]: { ...(acc[e["user"]] || {}), yes: (acc[e["user"]]?.["yes"] || 0) + e["nsamples"] } }),
        _data_by_users
      );
      _tableData = Object.entries(_data_by_users).map(([key, value]) => ({ ...value, user: key }));
      _total = _tableData.reduce((total, obj) => (obj["no"] || 0) + (obj["yes"] || 0) + total, 0);
      _total_yes = _tableData.reduce((total, obj) => (obj["yes"] || 0) + total, 0);
      _total_no = _tableData.reduce((total, obj) => (obj["no"] || 0) + total, 0);
    }

    return {
      data: _tableData,
      total: _total,
      yes: _total_yes,
      no: _total_no,
    };
  }

  render() {
    const { currentDayData, data, dateRange, isLoadingData, loadingText } = this.state;

    console.log(currentDayData, "currentDayData");
    let dataFromRange = null;
    if (data && data.yes != null && data.no != null) {
      dataFromRange = this.convertDataIntoTabs(data);
    } else if (currentDayData && currentDayData.yes != null && currentDayData.no != null) {
      dataFromRange = this.convertDataIntoTabs(currentDayData);
    }

    console.log(dataFromRange, ")))))");

    return (
      <div className="reporting_qualification">
        <HeaderComponent title="Report Qualification (YES/NO)" to="/a/dashboard/" toTitle="Home" />

        <ALDateRange onChange={this.setDateRange} {...dateRange} />
        {isLoadingData && <ALLoading alt text={loadingText} />}
        <p className="reporting_qualification_range">
          {data && data.yes != null && data.no != null
            ? `${format(dateRange.startDate, "MMM dd, yyyy")} — ${format(dateRange.endDate, "MMM dd, yyyy")} — ${differenceInDays(
                dateRange.endDate,
                dateRange.startDate
              ) + 1} day(s)`
            : currentDayData && currentDayData.yes != null && currentDayData.no != null
            ? `Today's Data (${format(new Date(), "MMM dd, yyyy")})`
            : ""}
        </p>
        <div className="reporting_qualification_content">
          {dataFromRange && dataFromRange.data && dataFromRange.data.length > 0 ? (
            <>
              <div className="qualification_report">
                <div className="qualification_report_data">
                  <p className="qualification_report_data--title"># Qualified</p>
                  <p className="qualification_report_data--value">{Number(dataFromRange.total).toLocaleString()}</p>
                </div>
                <div className="qualification_report_data">
                  <p className="qualification_report_data--title"># YES</p>
                  <p className="qualification_report_data--value">{Number(dataFromRange.yes).toLocaleString()}</p>
                </div>
                <div className="qualification_report_data">
                  <p className="qualification_report_data--title"># NO</p>
                  <p className="qualification_report_data--value">{Number(dataFromRange.no).toLocaleString()}</p>
                </div>
                <div className="qualification_report_data">
                  <p className="qualification_report_data--title">% YES</p>
                  <p className="qualification_report_data--value">{((dataFromRange.yes / dataFromRange.total) * 100).toFixed(2)}%</p>
                </div>
              </div>

              <div className="reporting_table_qualification">
                <div className="reporting_table_qualification_header">
                  <p>Email</p>
                  <p>YES</p>
                  <p>NO</p>
                  <p>% YES</p>
                  <p>% NO</p>
                  <p>Total</p>
                  <p>% Total</p>
                </div>
                {dataFromRange.data.map((e, i) => {
                  return (
                    <div className="reporting_table_qualification_data" key={`qualif_per_user_${i}`}>
                      <p>{e["user"]}</p>
                      <p>{e["yes"]}</p>
                      <p>{e["no"]}</p>
                      <p>{((e["yes"] / (e["yes"] + e["no"])) * 100).toFixed(2)}%</p>
                      <p>{((e["no"] / (e["yes"] + e["no"])) * 100).toFixed(2)}%</p>
                      <p>{e["yes"] + e["no"]}</p>
                      <p>{(((e["yes"] + e["no"]) / dataFromRange.total) * 100).toFixed(2)}%</p>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            "Please select a date range"
          )}
        </div>
      </div>
    );
  }
}

export default QualificationReporting;
