import React from "react";
import HeaderComponent from "./HeaderComponent";
import axios from 'axios';
import Loading from "./Loading";
import moment from 'moment';
import _ from 'lodash';
import "./DataInventory.css";
import { ALDragAndDrop, ALTableDetails } from "./ALComponents";
import old_new_skus from "../assets/old_new_skus.json";

class DataInventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dataSearch: null,

      dateStarting: "",
      dateEnding: "",
      searchValue: null,
      sortType: 'desc',

      old_new_skus: old_new_skus,

      dataVelocity: null, // Export Wassim's analysis
      dataProduction: null, // Export PRODUCTION MASTER SHEET
      dataInventory: null, // Export Shopify

      storageDataVelocity: JSON.parse(localStorage.getItem('ALIP_velocity')) ? (JSON.parse(localStorage.getItem('ALIP_velocity'))) : (null),
      storageDataProduction: JSON.parse(localStorage.getItem('ALIP_production')) ? (JSON.parse(localStorage.getItem('ALIP_production'))) : (null),
      storageDataInventory: JSON.parse(localStorage.getItem('ALIP_inventory')) ? (JSON.parse(localStorage.getItem('ALIP_inventory'))) : (null),

      dataMerged: null,
      dataTable: null, // data used to display informations
      timeFrameSelected: "7is" // 7days in stock by default


    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleSortTable = this.handleSortTable.bind(this);
    this.buildFinalSheet = this.buildFinalSheet.bind(this);
    this.buildTableInventory = this.buildTableInventory.bind(this);
  }

  
  buildFinalSheet() {
    console.log("buildFinalSheet");
    const { dataVelocity, dataProduction, dataInventory, old_new_skus } = this.state;

    // merge dataInventory (from Shopify) AND dataVelocity (from Streamlit analuisa_data)
    let dataMergedInventoryVelocity = [];
    let productsRing = [];
    dataInventory.map((product) => {
      if (product["sku"].includes("R") && product["option1 value"] != "Default Title") {
        // merge rings in an array 
        if (!productsRing.includes(product["title"])) {
          productsRing.push(product["title"]);
        }
      } else {
        // not a ring
        let productTmp = _.find(dataVelocity, { "variant sku": product["sku"] });
        if (productTmp) {
          Object.assign(productTmp, {"ending_qty": parseInt(product["61 greenpoint ave"]) });
          
        } else {
          productTmp = product;
          Object.assign(productTmp, { "ending_qty": 0 });
        }
        dataMergedInventoryVelocity.push(productTmp);
      }
      // productTmp["ending_qty"] = product["ending_quantity"];
   });

   // merge rings data into ONE product
   productsRing.map((productTitleRing) => {
    let rings = _.filter(dataInventory, { "title": productTitleRing }); // update 
    let productRingTmp = {};
    let tmpRingQtyTotal = 0;
    rings.map((variantRing, i) => {
      if (i == 0) {
        const arrVariant = variantRing["sku"].split("-");
        let theRingVariant = arrVariant[0] + "-" + arrVariant[1];
        productRingTmp = _.filter(dataVelocity, { "variant sku": theRingVariant })[0];
      }
      tmpRingQtyTotal += parseInt(variantRing["61 greenpoint ave"]);
    });
    if (typeof(productRingTmp) == 'undefined') {
      console.log("RING NOT FOUND IN DATA VELOCITY");
    } else {
      productRingTmp["ending_qty"] = tmpRingQtyTotal || 0;
      dataMergedInventoryVelocity.push(productRingTmp);
    }
   });
    let dataFinal = [];
    dataMergedInventoryVelocity.map((product) => {
      // console.log(product["variant sku"]);
      let productInProduction = _.filter(dataProduction, { "AL Style #": product["variant sku"] });

      // couldnt find it with the SKU number
        // try with the name => merge result with old result if we can!
      let newSku = _.filter(old_new_skus, {"old_sku": product["variant sku"]});
      if (newSku.length > 0) {
        let newSkuNumber = newSku[0]["new_sku"];
        let productInProductionNewSku = _.filter(dataProduction, { "AL Style #": newSkuNumber });
        productInProduction = [...productInProduction, ...productInProductionNewSku];
      }

      if (productInProduction.length > 0) {
        // order products from the production file (by order date)
        productInProduction.sort(function compare(a, b) {
          var dateA = new Date(a["PO Date"]);
          var dateB = new Date(b["PO Date"]);
          return dateB - dateA;
        });
      }
      // Only add product with production (in the past OR current)
        // just product we manage to match from PRODUCTION MASTER sheet
      dataFinal.push({
        ...product,
        "productions": productInProduction
      })
    });
    this.setState({ dataMerged: dataFinal });
  }

  buildTableInventory() {
    console.log("buildTableInventory");
    const { dataMerged, timeFrameSelected, searchValue } = this.state;
    let arrayHeader = null;
    // IN STOCK
    if (timeFrameSelected == "7is") {
      arrayHeader = ["variant sku", "revenu_per_day is 7", "sales/d is 7"];
    }
    if (timeFrameSelected == "15is") {
      arrayHeader = ["variant sku", "revenu_per_day is 15", "sales/d is 15"];
    }
    if (timeFrameSelected == "30is") {
      arrayHeader = ["variant sku", "revenu_per_day is 30", "sales/d is 30"];
    }
    if (timeFrameSelected == "60is") {
      arrayHeader = ["variant sku", "revenu_per_day is 60", "sales/d is 60"];
    }
    // NET
    if (timeFrameSelected == "7net") {
      arrayHeader = ["variant sku", "revenu_per_day net 7", "sales/d net 7"];
    }
    if (timeFrameSelected == "15net") {
      arrayHeader = ["variant sku", "revenu_per_day net 15", "sales/d net 15"];
    }
    if (timeFrameSelected == "30net") {
      arrayHeader = ["variant sku", "revenu_per_day net 30", "sales/d net 30"];
    }
    if (timeFrameSelected == "60net") {
      arrayHeader = ["variant sku", "revenu_per_day net 60", "sales/d net 60"];
    }

    let dataTable = [];
    // build ALTableDetails data
    dataMerged.map((product) => {
      if (typeof(product[arrayHeader[2]]) != "undefined") {
        let depletionDays = parseFloat(product["ending_qty"] / parseFloat(product[arrayHeader[2]]));
        if (isNaN(depletionDays) || depletionDays == Infinity) {
          depletionDays = 0;
        }
        let salesPerDay = parseFloat(parseFloat(product[arrayHeader[2]]).toFixed(2));
        let revenuProduct = parseFloat(parseFloat(product[arrayHeader[1]]).toFixed(2));
        console.log(product, "=======product");
        let productObject = {
          "product_title": product["product title"],
          "sku": product[arrayHeader[0]],
          "revenue per day": revenuProduct,
          "sales per day": salesPerDay,
          "in_stock": product["ending_qty"],
          "depletion per day": parseFloat(depletionDays.toFixed(2)),
          "first sale": product["first sale"],
          "production_details": product["productions"],
        }
        let qtyInProduction = 0;
        let nextDeliveryStr = "";
        if (product["productions"].length > 0) {
          product["productions"].map((prod, i) => {
            // in prod
            // 1 - Check if items in prod
            // 2 - Check if in prod == deliveried (/!\ second shipment)
            // 3 - if in prod == add number days until next delivery + date in ()
            let qtyOrdered = (prod["QTY Ordered"] == "") ? 0 : parseInt(prod["QTY Ordered"].replace(",", ""));
            let qtyReceived = (prod["QTY Received"] == "") ? 0 : parseInt(prod["QTY Received"].replace(",", ""));
            let qtyReceivedSecondShip =  (prod["QTY Received Second Shipment"] == "") ? 0 : parseInt(prod["QTY Received Second Shipment"].replace(",", ""));
            if (qtyOrdered <= (qtyReceived + qtyReceivedSecondShip)) {
              console.log("DONE");
            } else {
              // add qty currently in production
              if (qtyReceived > 0) {
                qtyInProduction += (qtyOrdered - qtyReceived);
              } else {
                qtyInProduction += qtyOrdered;
              }

              // factory confirmation XF
              let factoryConfirmationDate = prod["Factory Confirmed XF"];
              let dateFactoryXF = moment(factoryConfirmationDate + "/2020", "MM/DD/YYYY");
              let nextDelivery = moment(dateFactoryXF).fromNow();
              if (nextDeliveryStr.length > 0) {
                // already a delivery in the str
                nextDeliveryStr += "\n" + factoryConfirmationDate + " (" + nextDelivery + ")";
              } else {
                // set delivery date
                nextDeliveryStr += factoryConfirmationDate + " (" + nextDelivery + ")";
              }
            }
          });
        }
        productObject["qty in production"] = qtyInProduction;
        productObject["factory conf XF"] = nextDeliveryStr;
        dataTable.push(productObject);
      }
    });
    // removed complete duplicates
    let dataTableRemoveDuplicate = _.uniqWith(dataTable, _.isEqual);
    this.setState({ dataTable: dataTableRemoveDuplicate });
    if (searchValue) {
      this.handleSearch({"target": { "value": searchValue }});
    }
  }

  updateLocalStorage(type, data) {
    let currentLocalObj = JSON.parse(localStorage.getItem(type));
    // check if files is from local file
    if (currentLocalObj && JSON.stringify(data) == JSON.stringify(currentLocalObj["data"])) {
      console.log("should come from local storage");
      return;
    } else {
      let objToStore = {
        data: data,
        date: moment().toString()
      }
      let strObjToStore = JSON.stringify(objToStore);
      localStorage.setItem(type, strObjToStore);
    }
  }

  buildListFirstSale(data) {
    const { dateStarting, dateEnding } = this.state;
    let dataFromRange = [];
    data.map((product, i) => {
      let isBetween = moment(product["first sale"]).isBetween(dateStarting, dateEnding);
      if (isBetween) {
        dataFromRange.push(product);
      }
    });
    return dataFromRange;
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataTable, dataSearch, dateStarting, dateEnding, dataVelocity, dataProduction, dataInventory, dataMerged, timeFrameSelected } = this.state;
    
    if (dataVelocity && dataInventory && dataProduction && (prevState.dataVelocity != dataVelocity || prevState.dataInventory != dataInventory || prevState.dataProduction != dataProduction)) {
      this.buildFinalSheet();
    }

    if (prevState.dataVelocity != dataVelocity) {
      this.updateLocalStorage("ALIP_velocity", dataVelocity);
    }
    if (prevState.dataInventory != dataInventory) {
      this.updateLocalStorage("ALIP_inventory", dataInventory);
    }
    if (prevState.dataProduction != dataProduction) {
      this.updateLocalStorage("ALIP_production", dataProduction);
    }

    if (dataMerged != prevState.dataMerged || timeFrameSelected != prevState.timeFrameSelected) {
      this.buildTableInventory();
    }

    if (dateStarting && dateEnding && (prevState.dateStarting != dateStarting) || (prevState.dateEnding != dateEnding)) {
      let data = null;
      if (dataSearch && dataSearch.length > 0) {
        // do the range on dataSearch
        data = dataSearch;
      } else {
        // do it table
        data = dataTable;
      }
      this.setState({ dataSearch: this.buildListFirstSale(data) });
    }
  }

  handleDateRange(e) {
    if (e == null) {
      const { dataSearch, dataTable, searchValue } = this.state
      // this.buildListFirstSale((dataSearch && dataSearch.length > 0) ? dataSearch : dataTable);
      if (searchValue) {
        this.handleSearch({ "target" : { "value": searchValue }});
      }
      this.setState({ dateEnding: "", dateStarting: "" });
      return;
    }

    const { value, name } = e.target;

    this.setState({ [name]: value });
  }

  handleSearch(e) {
    const { dataTable, dateStarting, dateEnding } = this.state;
    const { value } = e.target;
    if (!value) {
      this.setState({
        searchValue: null,
        dataSearch: null
      })
      return;
    }
    let data = dataTable;
    if (dateStarting && dateEnding) {
      data = this.buildListFirstSale(data);
    }


    // init result search
    let arrayProductSearch = [];
    // split string search => "abby,tia"
    let valueSplit = value.split(",");
    // remove space in values if needed: ["abby", " tia "] => ["abby", "tia"]
    valueSplit = Object.values(valueSplit).map(val => val.trim());

    const searchKeys = ["product_title", "sku"];

    searchKeys.map((searchKey) => {
      valueSplit.map((val) => {
        let result = _.filter(data, function(obj) {
          return obj[searchKey].toLowerCase().indexOf(val.toLowerCase()) != -1;
        });
        // concat array
        arrayProductSearch = _.concat(arrayProductSearch, result);
      });
    });

    // remove duplicate
    arrayProductSearch = _.uniq(arrayProductSearch);

    this.setState({
      searchValue: value,
      dataSearch: arrayProductSearch,
     });
  }

  handleSortTable(key_sort) {
    const { dataTable, dataSearch, sortType } = this.state;
    let sortedData = dataTable;
    if (dataSearch) {
      sortedData = dataSearch;
    }

    let newSortTypew = (sortType == 'desc') ? 'asc' : 'desc';
    sortedData = _.orderBy(sortedData, [key_sort], [newSortTypew]);
    if (dataSearch) {
      this.setState({ dataSearch: sortedData, sortType: newSortTypew });
    } else {
      this.setState({ dataTable: sortedData, sortType: newSortTypew });
    }
  }
 
  render() {
    const { data, dataSearch, dataMerged, timeFrameSelected, dataTable, dataInventory, dataProduction, dataVelocity, storageDataInventory, storageDataProduction, storageDataVelocity, dateStarting, dateEnding} = this.state;
    return (
      <div className="data_inventory">
        <HeaderComponent title="Inventory planning" to="/a/dashboard/" toTitle="Home" howToModal={() => this.props.showModal("ModalHowToInventoryPlanning")} />
        <div className={`data_inventory__uploads ${(dataTable) ? ('data_inventory__uploads--hide') : (null)}`}>

          {(storageDataInventory && storageDataProduction && storageDataVelocity && (!dataInventory || !dataVelocity || !dataProduction)) ? (
            <div className="data_inventory__uploads__storage">
              <span className="data_inventory__uploads__storage--icon material-icons">info</span>
              <div className="data_inventory__uploads__storage__container">
                {(!dataInventory) ? (
                  <div className="data_inventory__uploads__storage__container__content">
                    <p className="storage__container__content--title">Inventory file last update: {storageDataInventory.date} ({moment(storageDataInventory.date).fromNow()})</p>
                    <div className="storage__container__content--button" onClick={() => {this.setState({ dataInventory: storageDataInventory["data"] })}}><span className="material-icons">refresh</span></div>
                  </div>
                ) : (null)}

                {(!dataProduction) ? (
                  <div className="data_inventory__uploads__storage__container__content">
                    <p className="storage__container__content--title">Production file last update: {storageDataProduction.date} ({moment(storageDataProduction.date).fromNow()})</p>
                    <div className="storage__container__content--button" onClick={() => {this.setState({ dataProduction: storageDataProduction["data"] })}}><span className="material-icons">refresh</span></div>
                  </div>
                ) : (null)}

                {(!dataVelocity) ? (
                  <div className="data_inventory__uploads__storage__container__content">
                    <p className="storage__container__content--title">Velocity file last update: {storageDataVelocity.date} ({moment(storageDataVelocity.date).fromNow()})</p>
                    <div className="storage__container__content--button" onClick={() => {this.setState({ dataVelocity: storageDataVelocity["data"] })}}><span className="material-icons">refresh</span></div>
                  </div>
                ) : (null)}
              </div>
            </div>
          ) : (null)}

          <div className="data_inventory__uploads__container">
            <p className="data_inventory__uploads__container__title">Data production</p>
            <ALDragAndDrop type="text/csv" handleFile={(data) => {this.setState({ dataProduction: data })}} />
          </div>
          <div className="data_inventory__uploads__container">
            <p className="data_inventory__uploads__container__title">Data Inventory</p>
            <ALDragAndDrop type="text/csv" handleFile={(data) => {this.setState({ dataInventory: data })}} />
          </div>
          <div className="data_inventory__uploads__container">
            <p className="data_inventory__uploads__container__title">Data Velocity</p>
            <ALDragAndDrop type="text/csv" handleFile={(data) => {this.setState({ dataVelocity: data })}} />
          </div>
        </div>

        {(dataTable) ? (
          <div className="data_inventory__header">
            <div className="data_inventory__search">
              <p>Search</p>
              <input className="data_inventory__search--input" type="text" onChange={this.handleSearch} />
            </div>

            <div className="data_inventory__timeframe">
              <p className="data_inventory__timeframe__title">Sales performance over the last:</p>
              <div className="data_inventory__timeframe__choices">
                <div className="data_inventory__timeframe__choices__group">
                  <p className={(timeFrameSelected == "7is") ? "isActive" : null} onClick={() => this.setState({ timeFrameSelected: "7is" })}>7 days (In Stock)</p>
                  <p className={(timeFrameSelected == "15is") ? "isActive" : null} onClick={() => this.setState({ timeFrameSelected: "15is" })}>15 days (In Stock)</p>
                  <p className={(timeFrameSelected == "30is") ? "isActive" : null} onClick={() => this.setState({ timeFrameSelected: "30is" })}>30 days (In Stock)</p>
                  <p className={(timeFrameSelected == "60is") ? "isActive" : null} onClick={() => this.setState({ timeFrameSelected: "60is" })}>60 days (In Stock)</p>
                </div>
                <div className="data_inventory__timeframe__choices__group">
                  <p className={(timeFrameSelected == "7net") ? "isActive" : null} onClick={() => this.setState({ timeFrameSelected: "7net" })}>7 days</p>
                  <p className={(timeFrameSelected == "15net") ? "isActive" : null} onClick={() => this.setState({ timeFrameSelected: "15net" })}>15 days</p>
                  <p className={(timeFrameSelected == "30net") ? "isActive" : null} onClick={() => this.setState({ timeFrameSelected: "30net" })}>30 days</p>
                  <p className={(timeFrameSelected == "60net") ? "isActive" : null} onClick={() => this.setState({ timeFrameSelected: "60net" })}>60 days</p>
                </div>
              </div>
            </div>

            <div className="data_inventory__fistsale">
              <p>First sale filter</p><span className="data_inventory__fistsale--remove material-icons" onClick={() => { this.handleDateRange(null)}}>remove_circle_outline</span>
              <div className="data_inventory__fistsale_content">
                <span>Starting</span>
                <input className="data_inventory__fistsale_start" value={dateStarting || ""} type="date" name="dateStarting" onChange={this.handleDateRange} />
              </div>
              <div className="data_inventory__fistsale_content">
                <span>Ending</span>
                <input className="data_inventory__fistsale_end" value={dateEnding ||  ""} type="date" name="dateEnding" onChange={this.handleDateRange} />
              </div>

            </div>
          </div>
        ) : (null)}

        {(dataTable) ? (
          <ALTableDetails
            title={"Inventory Planning"}
            header={["product_title", "sku", "revenue per day", "sales per day", "in_stock", "depletion per day", "qty in production", "factory conf XF", "first sale", "details"]}
            content={((dataSearch) ? dataSearch : dataTable).map((pr) => [
              pr["product_title"] || "",
              pr["sku"] || "",
              pr["revenue per day"] || "",
              pr["sales per day"] || "",
              pr["in_stock"] || "",
              pr["depletion per day"] || "",
              pr["qty in production"] || "",
              pr["factory conf XF"] || "",
              pr["first sale"] || "",
              "details",
            ])}
            details_header={["PO#", "PO Date", "QTY Ordered", "Ring Size allocation", "PO Requested XF", "Factory Confirmed XF", "Actual Ship Date", "First Shipment arrival (Date)", "QTY Received", "Second Shipment arrival", "QTY Received Second Shipment"]}
            details={((dataSearch) ? dataSearch : dataTable).map((pr) => {
              if (pr["production_details"].length > 0) {
                return [
                  pr["production_details"].map((production_detail) => {
                    return [
                      production_detail["PO#"] || "",
                      production_detail["PO Date"] || "",
                      production_detail["QTY Ordered"] || "",
                      production_detail["Ring Size allocation"] || "",
                      production_detail["PO Requested XF"] || "",
                      production_detail["Factory Confirmed XF"] || "",
                      production_detail["Actual Ship Date"] || "",
                      production_detail["First Shipment arrival (Date)"] || "",
                      production_detail["QTY Received"] || "",
                      production_detail["Second Shipment arrival"] || "",
                      production_detail["QTY Received Second Shipment"] || "",
                    ];
                  })
                ];
              } else {
                return [];
              }
            })}
            handleSort={this.handleSortTable}
            column_style={[{ width: "calc(19% - 10px)" }, { width: "calc(10% - 10px)" }, { width: "calc(9% - 10px)" }, { width: "calc(10% - 10px)" }, { width: "calc(8% - 10px)" }, { width: "calc(12% - 10px)" }, { width: "calc(7% - 10px)" }, { width: "calc(11% - 10px)" }, { width: "calc(8% - 10px)" }, { width: "calc(5% - 10px)" }]}
          />
          ): (null)}
      </div>
    );
  }
}

export default DataInventory;
