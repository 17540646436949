import React from "react";
import "./ModalHowToShipping.css";
import { ALButton, ALContainer } from "../ALComponents";

const ModalHead = (props) => (
  <div className="modal-head">
    <div className="modal-head__line">
      <h1 className="modal-head__line__title">How to</h1>
      <div className="modal-head__line__button">
        <ALButton type="text" material_icon="clear" onClick={props.close} />
      </div>
    </div>
    <hr className="modal-head__separator" />
  </div>
);

const ModalFoot = (props) => (
  <div className="modal-foot">
    <ALButton type="text" material_icon="clear" onClick={props.close}>Back</ALButton>
  </div>
)

class ModalHowToShipping extends React.Component {
  render() {
    return (
      <ALContainer className="how-to-shipping-modal">
        <ModalHead close={() => this.props.resume()} />
        <div className="how-to-shipping-modal__content">
          <h2>Purpose</h2>
          <p>The Shipping Creation tool aim to handle the shipping process for influencer's requested pieces.</p>
          <p>The influencer's information that you see on this page are the ones that have been filled by them on Typeform. Some complementary information is retrieved from the '<i>Influencers and Stock Tracker</i>' spreadsheet.</p>
          <h2>Usage</h2>
          <p>The tool have two part: the first one in the top of the page is for the order creation, the second one at the bottom is a summary of the order you have entered. <b>Those orders are not created until you click on '<i>DOWNLOAD ALL INFLUENCERS & PROCESS ORDERS</i>'</b></p>
          <p>First select the deal platform that have been negociated with the influencer. Then follow the steps.</p>
          <h2>Notes</h2>
          <p>Once you choose an influencer and if it has filled the wished products with the URL from the analuisa.com product page, the tool will try to fill automatically the products.</p>
          <p><b>Unlike the orders that are not created until you click on '<i>DOWNLOAD ALL INFLUENCERS & PROCESS ORDERS</i>' the discounts (and link redirection) are instantly created when you click on '<i>Create discount</i>'</b></p>
          <p>The deletion of an Influencer order will <b>NOT</b> delete the related redirect and discount code.</p>
          <ModalFoot close={() => this.props.resume()} />
        </div>
      </ALContainer>
    );
  }
}

export default ModalHowToShipping;
