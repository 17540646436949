import React from "react";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import Chart from "chart.js";
import { DateRange } from 'react-date-range';

import Loading from "../Loading";
import "./Reports.css";

class AutoCompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isOpen: false,
      hoveringIndex: -1,
    };
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }
  handleOnBlur(e) {
    // Firefox onBlur issue
    if (e.nativeEvent.explicitOriginalTarget && e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
      return;
    }
    if (this.state.isOpen) {
      setTimeout(() => this.setState({ isOpen: false }), 200);
    }
  }
  handleOnFocus(e) {
    this.setState({ isOpen: true });
  }
  handleOnChange(e) {
    const nextValue = e.target.value;
    const recommendation = (this.props.list || []).filter((option) => option.startsWith(nextValue));
    let nextState = { value: nextValue };
    console.log("change:", nextValue);
    if (this.state.hoveringIndex >= recommendation.length) {
      nextState.hoveringIndex = recommendation.length - 1;
    }
    this.setState(nextState);
  }
  onSelection(elem) {
    console.log("here in onselection");
    this.setState({ value: "", hoveringIndex: -1, isOpen: false }, () => this.props.onSelection(elem));
  }
  handleKeyDown(e) {
    const up = 38;
    const down = 40;
    const enter = 13;
    const tab = 9;
    if (this.state.isOpen && e.keyCode == tab) return setTimeout(() => this.setState({ isOpen: false }), 100);
    if (!this.state.isOpen) return setTimeout(() => this.setState({ isOpen: true }), 100);
    if (e.keyCode != up && e.keyCode != down && e.keyCode != enter) return;
    e.preventDefault();
    e.stopPropagation();
    const recommendation = (this.props.list || []).filter((option) => option.startsWith(this.state.value));
    if (e.keyCode === up && this.state.hoveringIndex > 0) {
      return this.setState({ hoveringIndex: this.state.hoveringIndex - 1 });
    }
    if (e.keyCode === down && this.state.hoveringIndex < recommendation.length - 1) {
      return this.setState({ hoveringIndex: this.state.hoveringIndex + 1 });
    }
    if (e.keyCode === enter) {
      if (this.state.hoveringIndex >= 0) {
        return this.onSelection(recommendation[this.state.hoveringIndex]);
      } else {
        return this.onSelection(this.state.value);
      }
    }
    console.log("Got key:", e.keyCode);
    return;
  }
  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.list.length != this.props.list.length) {
      const recommendation = (this.props.list || []).filter((option) => option.startsWith(this.state.value));
      if (this.state.hoveringIndex >= recommendation.length) {
        this.setState({ hoveringIndex: recommendation.length - 1 });
      }
    }
  }
  render() {
    const { value, isOpen, hoveringIndex } = this.state;
    // console.log("isOpen", isOpen);
    // console.log("list", this.props.list);
    const recommendation = (this.props.list || []).filter((option) => option.startsWith(value));
    return (
      <div className="autocomplete-input">
        <div className="autocomplete-input__header">
          <input
            type="text"
            placeholder={this.props.placeholder}
            className={`autocomplete-input__header__input${isOpen && recommendation.length > 0 ? "--focus" : ""}`}
            value={value}
            onBlur={this.handleOnBlur}
            onFocus={this.handleOnFocus}
            onChange={this.handleOnChange}
            onKeyDown={this.handleKeyDown}
            onClick={() => !isOpen && this.setState({ isOpen: true })}
          />
        </div>
        {recommendation.length > 0 && isOpen ? (
          <div className="autocomplete-input__dropdown">
            {recommendation.map((option, idx) => (
              <div
                key={idx}
                className={`autocomplete-input__dropdown__line${idx === hoveringIndex ? "--hover" : ""}`}
                onMouseOver={() => this.setState({ hoveringIndex: idx })}
                onClick={() => console.log("click") || this.onSelection(option)}>
                {option}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

class SearchOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agentToggleIsAll: true,
      selectedAgentsEmail: [],
      isDateSelectorOpen: false,
    };
  }
  render() {
    const { agentToggleIsAll, selectedAgentsEmail, isDateSelectorOpen } = this.state;

    console.log("search from:", this.props.from);
    console.log("search to:", this.props.to);

    return (
      <div className="report-search-options">
        <div className="report-search-options__row">
          <div className="report-search-options__row_select" onClick={() => this.setState({ isDateSelectorOpen: !this.state.isDateSelectorOpen })}>
            <p className="report-search-options__row_select--title">Select Date</p>
            <span className="report-search-options__row_select--icon material-icons">keyboard_arrow_down</span>
          </div>
          {(isDateSelectorOpen) ? (
            <div className="report-search-options__row_open">
              {/* <div className="report-search-options__row__title">Date range</div>
              <div className="report-search-options__row__label">From</div>
              <input className="report-search-options__row__input-date" type="date" placeholder="Date" value={this.props.from} onChange={(e) => this.props.onSearchUpdate({day_from: e.target.value})} />
              <div className="report-search-options__row__label">To</div>
              <input className="report-search-options__row__input-date" type="date" placeholder="Date" value={this.props.to} onChange={(e) => this.props.onSearchUpdate({day_to: e.target.value})} /> */}
              <DateRange
                onChange={(e) => this.props.onSearchUpdate({day_from: (e.startDate).format("YYYY-MM-DD"), day_to: (e.endDate).format("YYYY-MM-DD") })}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                className='PreviewArea'
                months={2}
                direction="horizontal"
              />
            </div>
          ) : (null)}
        </div>
        
        {/* <div className="report-search-options__row">
          <div className="report-search-options__row__title">Agents</div>
          <div className="report-search-options__row__toggle">
            <div
              className={`report-search-options__row__toggle__option${(agentToggleIsAll && "--selected") || ""}`}
              onClick={() => {
                if (!agentToggleIsAll) {
                  this.props.onSearchUpdate({users: []});
                  this.setState({ agentToggleIsAll: !agentToggleIsAll })
                }
              }}>
              All
            </div>
            <div
              className={`report-search-options__row__toggle__option${(!agentToggleIsAll && "--selected") || ""}`}
              onClick={() => {
                if (agentToggleIsAll) {
                  this.props.onSearchUpdate({users: selectedAgentsEmail});
                  this.setState({ agentToggleIsAll: !agentToggleIsAll })
                }
              }}>
              Selected
            </div>
          </div>
          {!agentToggleIsAll ? (
            <>
              <AutoCompleteInput
                list={this.props.agentsEmail.filter((email) => !selectedAgentsEmail.includes(email))}
                placeholder="Agent email"
                onSelection={(elem) => {
                  if (this.props.agentsEmail.includes(elem)) {
                    const newSelection = [...selectedAgentsEmail, elem];
                    this.props.onSearchUpdate({users: newSelection});
                    this.setState({ selectedAgentsEmail: newSelection })
                  }
                }}
              />
              <div className="report-search-options__row__chips">
                {selectedAgentsEmail.map((agentEmail, idx) => (
                  <div key={idx} className="report-search-options__row__chips__chip">
                    <div className="report-search-options__row__chips__chip__title">{agentEmail}</div>
                    <div
                      className="report-search-options__row__chips__chip__remove material-icons"
                      onClick={() => {
                        const newSelection = selectedAgentsEmail.filter((e) => e != agentEmail)
                        this.props.onSearchUpdate({users: newSelection});
                        this.setState({ selectedAgentsEmail: newSelection})
                      }}>
                      close
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </div> */}
      </div>
    );
  }
}

class ReportsResults extends React.Component {
  chartRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      mode: "numbers",
      accountManagers: null,
    };
  }
  componentDidMount() {
    let _this = this;
    axios.get("/api/v1/team/account-manager?with=admin&with=super-user").then(res => {
      if (res.status == 200) {
        let tmpUsers = res.data.users;
        let listUsers = [];
        tmpUsers.map((user) => {
          if (user.teams.includes("YouTube") || user.teams.includes("Co-creation") || user.teams.includes("Instagram") || user.teams.includes("TikTok") || user.teams.includes("Podcast"))
          listUsers.push({
            'id': user.email,
            'title': `${user.firstName} ${user.lastName}`
          })
        });

        _this.setState({
          accountManagers: listUsers
        });
      }
    });

    if (!this.props.data || !this.props.data.length) return;
    console.log("this.props.data ????", this.props.data);
    const myChartRef = this.chartRef.current.getContext("2d");
    // console.log("DATA???", this.props.data);
    const groupedData = _.groupBy(this.props.data, (e) => e.type);
    // console.log("TEST", groupedData["QUALIFICATION_NO"].map(e => ({x: moment(e.day).toDate(), y: e.nsamples})));
    const dataByDateNo = _.groupBy(groupedData["QUALIFICATION_NO"] || [], (e) => new Date(moment(e.day).toDate().toDateString()));
    const finalDataNo = Object.keys(dataByDateNo).map(day => {
      return ({x: day, y: _.sumBy(dataByDateNo[day], (e) => e.nsamples)})
    });
    const dataNo = finalDataNo.map(e => ({x: e.x, y: e.y})).sort((a, b) => {
      return new Date(b.x) - new Date(a.x);
    });
    const dataByDateYes = _.groupBy([...(groupedData["QUALIFICATION_YES"] || []), ...(groupedData["QUALIFICATION_CC"] || [])], (e) => new Date(moment(e.day).toDate().toDateString()));
    const finalDataYes = Object.keys(dataByDateYes).map(day => {
      return ({x: day, y: _.sumBy(dataByDateYes[day], (e) => e.nsamples)})
    });
    const dataYes = finalDataYes.map(e => ({x: e.x, y: e.y})).sort((a, b) => {
      return new Date(b.x) - new Date(a.x);
    });
    // const data = groupedData["QUALIFICATION_NO"].map(e => ({x: moment(e.day).toDate(), y: e.nsamples})).sort((a, b) => a.x - b.x)

    new Chart(myChartRef, {
        type: "line",
        data: {
            //Bring in data
            datasets: [
                {
                    data: dataNo,
                    label: "No",
                    //yAxisID: "No",
                    borderColor: "#C54A53",
                    backgroundColor: 'rgba(197, 74, 83, 0.1)'
                },
                {
                  data: dataYes,
                  label: "Yes",
                  //yAxisID: "Yes",
                  borderColor: "#4AC54F",
                  backgroundColor: 'rgba(74, 197, 79, 0.1)'
              }
            ],
            // labels: ["Jan", "Feb", "March"],
        },
        options: {
            //Customize chart options
            legend: {
              position: 'bottom'
            },
            scales: {
              xAxes: [{
                  type: 'time',
                  time: {
                      unit: 'day'
                  }
              }],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1
                }
              }

              //   {
              //   id: 'No',
              //   type: 'linear',
              //   position: 'left'
              // },
              // {
              //   id: 'Yes',
              //   type: 'linear',
              //   position: 'right',
              // }
            ]
          }
        }
    });
  }
  render() {
    const { mode } = this.state;
    const total_404 = this.props.sumByTypes && this.props.sumByTypes["DECLINED_404"] || 0;
    const total_videos = this.props.sumByTypes && this.props.sumByTypes["DECLINED_VIDEOS"] || 0;
    const total_recent = this.props.sumByTypes && this.props.sumByTypes["DECLINED_RECENT"] || 0;
    const total_category = this.props.sumByTypes && this.props.sumByTypes["DECLINED_CATEGORY"] || 0;
    const total_country = this.props.sumByTypes && this.props.sumByTypes["DECLINED_COUNTRY"] || 0;
    const total_views = this.props.sumByTypes && this.props.sumByTypes["DECLINED_VIEWS"] || 0;
    const total_qualification = this.props.sumByTypes && this.props.sumByTypes["QUALIFICATION"] || 0;
    const total_scrap = total_404 + total_videos + total_recent + total_category + total_country + total_views + total_qualification;


    const total_yes = this.props.sumByTypes && this.props.sumByTypes["QUALIFICATION_YES"] || 0;
    const total_no = this.props.sumByTypes && this.props.sumByTypes["QUALIFICATION_NO"] || 0;
    const total_cc = this.props.sumByTypes && this.props.sumByTypes["QUALIFICATION_CC"] || 0;
    const percentYes = total_no > 0 ? (total_yes + total_cc) * 100 / total_no : 0;

    const total_yes_today = this.props.sumByTypes && this.props.sumByTypes["QUALIFICATION_YES_TODAY"] || 0;
    const total_no_today = this.props.sumByTypes && this.props.sumByTypes["QUALIFICATION_NO_TODAY"] || 0;
    const percentYes_today = total_no_today > 0 ? (total_yes_today * 100) / total_no_today : 0;
    
    console.log("ReportsResults.props", this.props);
    let groupedByEmail = _.groupBy(this.props.data, (e) => { return e.user; });

    return (
      <div className="report-result">
        {/* <div>Here toggle</div> */}
        {mode === "numbers" ? (
          <div className="report-result__qualification">
            <div className="report-result__qualification__title">Qualification</div>
            <div className="report-result__qualification__content">
              <div className="report-result__qualification__content__yes">
                <div className="report-result__qualification__content__yes__title">Yes</div>
                <hr className="report-result__qualification__content__separator" />
                <div className="report-result__qualification__content__yes__content">{total_yes + total_cc}<br/>({percentYes.toFixed(2)}%)</div>
              </div>
              <div className="report-result__qualification__content__total">
                <div className="report-result__qualification__content__total__title">Total</div>
                <hr className="report-result__qualification__content__separator" />
                <div className="report-result__qualification__content__total__content">{total_yes + total_no + total_cc}</div>
              </div>
              <div className="report-result__qualification__content__no">
                <div className="report-result__qualification__content__no__title">No</div>
                <hr className="report-result__qualification__content__separator" />
                <div className="report-result__qualification__content__no__content">{total_no}</div>
              </div>
            </div>

            <div className="report-result__qualification__title" style={{marginTop: "30px"}}>Today</div>
            <div className="report-result__qualification__content">
              <div className="report-result__qualification__content__yes">
                <div className="report-result__qualification__content__yes__title">Yes</div>
                <hr className="report-result__qualification__content__separator" />
                <div className="report-result__qualification__content__yes__content">{total_yes_today}<br/>({percentYes_today.toFixed(2)}%)</div>
              </div>
              <div className="report-result__qualification__content__total">
                <div className="report-result__qualification__content__total__title">Total</div>
                <hr className="report-result__qualification__content__separator" />
                <div className="report-result__qualification__content__total__content">{total_yes_today + total_no_today}</div>
              </div>
              <div className="report-result__qualification__content__no">
                <div className="report-result__qualification__content__no__title">No</div>
                <hr className="report-result__qualification__content__separator" />
                <div className="report-result__qualification__content__no__content">{total_no_today}</div>
              </div>
            </div>




          </div>
        ) : null}
        {(mode === "numbers" && this.props.searchParams && this.props.searchParams.users.length == 0) ? (
          <div className="report-result__scraping">
            <div className="report-result__scraping__content">
              <p className="report-result__scraping__content--type">Declined 404</p>
              <p className="report-result__scraping__content--number">{total_404}</p>
              <p className="report-result__scraping__content--percentage">{((total_404/total_scrap) * 100).toFixed(2)}%</p>
            </div>
            <div className="report-result__scraping__content">
              <p className="report-result__scraping__content--type">Declined Videos</p>
              <p className="report-result__scraping__content--number">{total_videos}</p>
              <p className="report-result__scraping__content--percentage">{((total_videos/total_scrap) * 100).toFixed(2)}%</p>
            </div>
            <div className="report-result__scraping__content">
              <p className="report-result__scraping__content--type">Declined Recent</p>
              <p className="report-result__scraping__content--number">{total_recent}</p>
              <p className="report-result__scraping__content--percentage">{((total_recent/total_scrap) * 100).toFixed(2)}%</p>
            </div>
            <div className="report-result__scraping__content">
              <p className="report-result__scraping__content--type">Declined Category</p>
              <p className="report-result__scraping__content--number">{total_category}</p>
              <p className="report-result__scraping__content--percentage">{((total_category/total_scrap) * 100).toFixed(2)}%</p>
            </div>
            <div className="report-result__scraping__content">
              <p className="report-result__scraping__content--type">Declined Country</p>
              <p className="report-result__scraping__content--number">{total_country}</p>
              <p className="report-result__scraping__content--percentage">{((total_country/total_scrap) * 100).toFixed(2)}%</p>
            </div>
            <div className="report-result__scraping__content">
              <p className="report-result__scraping__content--type">Declined Views</p>
              <p className="report-result__scraping__content--number">{total_views}</p>
              <p className="report-result__scraping__content--percentage">{((total_views/total_scrap) * 100).toFixed(2)}%</p>
            </div>
            <div className="report-result__scraping__content">
              <p className="report-result__scraping__content--type">Qualification</p>
              <p className="report-result__scraping__content--number">{total_qualification}</p>
              <p className="report-result__scraping__content--percentage">{((total_qualification/total_scrap) * 100).toFixed(2)}%</p>
            </div>
            <hr className="report-result__scraping__content_separator" />
            <div className="report-result__scraping__content report-result__scraping__content_total">
              <p className="report-result__scraping__content--type report-result__scraping__content--type_total">Total</p>
              <p className="report-result__scraping__content--number report-result__scraping__content--number_total">{total_scrap}</p>
            </div>
            
          </div>
        ) : (null)}
        <div className="report-result__qualification-graph">
          <div className="report-result__qualification-graph__title">Qualification Graph</div>
          <canvas
              id="myChart"
              ref={this.chartRef}
          />
        </div>
        {(mode === "numbers" && this.props.searchParams && this.props.searchParams.users.length == 0) ? (
          <div className="report-result__qualification_users">
            <div className="report-result__qualification_users_user report-result__qualification_users_user_header">
              <p className="report-result__qualification_users_user_info report-result__qualification_users_user_header-el">AM</p>
              <p className="report-result__qualification_users_user--cc report-result__qualification_users_user_header-el">CC</p>
              <p className="report-result__qualification_users_user--yes report-result__qualification_users_user_header-el">Yes</p>
              <p className="report-result__qualification_users_user--no report-result__qualification_users_user_header-el">No</p>
              <p className="report-result__qualification_users_user--percentage report-result__qualification_users_user_header-el">% Yes</p>
            </div>
          {Object.keys(groupedByEmail).map((key, i) => {
            let nbNo = _.sumBy(groupedByEmail[key], (e) =>  (e.type == "QUALIFICATION_NO") ? e.nsamples : null) || 0;
            let nbYes = _.sumBy(groupedByEmail[key], (e) => (e.type == "QUALIFICATION_YES") ? e.nsamples : null) || 0;
            let nbCC = _.sumBy(groupedByEmail[key], (e) => (e.type == "QUALIFICATION_CC") ? e.nsamples : null) || 0;
            let percentYes = ((nbYes / (nbNo + nbYes + nbCC)) * 100).toFixed(2);
            if (isNaN(percentYes) || percentYes == 0.00) {
              percentYes = 0;
            }
            let am = null;
            if (this.state.accountManagers) {
              am = _.filter(this.state.accountManagers, { "id": key });
              console.log(am, "==> am");
            }
            if (key == "scraping@analuisa.com" || key == "francoisd@analuisa.com" || key == "quentind@analuisa.com") {
              return null;
            }
            return (
              <div className="report-result__qualification_users_user" key={`user_${i}`}>
                <p className="report-result__qualification_users_user_info">
                  {(am && am.length > 0) ? (
                    <>
                      <span className="report-result__qualification_users_user_info_name">{am[0].title}</span>
                      <span className="report-result__qualification_users_user_info_email">{am[0].id}</span>
                    </>
                  ) : (
                    <span className="report-result__qualification_users_user_info_email">{key}</span>
                  )}
                </p>
                <p className="report-result__qualification_users_user--cc">{nbCC}</p>
                <p className="report-result__qualification_users_user--yes">{nbYes}</p>
                <p className="report-result__qualification_users_user--no">{nbNo}</p>
                <p className="report-result__qualification_users_user--percentage">{percentYes}%</p>
              </div>
            );
          })}
          </div>
        ) : (null)}


      </div>
    );
  }
}

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_params: {
        day_from: moment().subtract(4, "days").startOf("month").format("YYYY-MM-DD"),
        day_to: moment().add(1, "days").format("YYYY-MM-DD"),
        types: ["QUALIFICATION_NO", "QUALIFICATION_YES", "QUALIFICATION_CC", "DECLINED_404", "DECLINED_VIDEOS", "DECLINED_RECENT", "DECLINED_CATEGORY", "DECLINED_COUNTRY", "DECLINED_VIEWS", "QUALIFICATION"],
        users: [],
        accountManagers: [],
      },
      isLoading: false,
      data: null,
    };
    this.onSearchUpdate = this.onSearchUpdate.bind(this);
  }
  onSearchUpdate(search_params) {
    this.setState({ search_params: {...this.state.search_params, ...search_params} });
  }
  updateResults() {
    console.log("updateResults");
    this.setState({ isLoading: true });
    axios
      .get("/api/v1/event/reports", { params: this.state.search_params })
      .then(({ data }) => {
        console.log("RESULT:", data);
        data = data.result;
        const groupedData = _.groupBy(data, (e) => e.type);
        console.log(groupedData);
        const qualification_yes_total = _.sumBy(groupedData["QUALIFICATION_YES"], (e) => e.nsamples);
        const qualification_no_total = _.sumBy(groupedData["QUALIFICATION_NO"], (e) => e.nsamples);
        const qualification_cc_total = _.sumBy(groupedData["QUALIFICATION_CC"], (e) => e.nsamples);

        const qualification_yes_today = _.sumBy(groupedData["QUALIFICATION_YES"], (e) => { return moment(e.day).isSame(new Date(), "day") ? e.nsamples : 0 }); 
        const qualification_no_today = _.sumBy(groupedData["QUALIFICATION_NO"], (e) => { return moment(e.day).isSame(new Date(), "day") ? e.nsamples : 0 }); 


        const qualification_404_total = _.sumBy(groupedData["DECLINED_404"], (e) =>  e.nsamples);
        const qualification_videos_total = _.sumBy(groupedData["DECLINED_VIDEOS"], (e) =>  e.nsamples);
        const qualification_recent_total = _.sumBy(groupedData["DECLINED_RECENT"], (e) =>  e.nsamples);
        const qualification_category_total = _.sumBy(groupedData["DECLINED_CATEGORY"], (e) =>  e.nsamples);
        const qualification_country_total = _.sumBy(groupedData["DECLINED_COUNTRY"], (e) =>  e.nsamples);
        const qualification_views_total = _.sumBy(groupedData["DECLINED_VIEWS"], (e) =>  e.nsamples);
        const qualification_total = _.sumBy(groupedData["QUALIFICATION"], (e) =>  e.nsamples);

        this.setState({
          sumByTypes: {
            "QUALIFICATION_YES_TODAY": qualification_yes_today,
            "QUALIFICATION_NO_TODAY": qualification_no_today,
            "QUALIFICATION_YES": qualification_yes_total,
            "QUALIFICATION_NO": qualification_no_total,
            "QUALIFICATION_CC": qualification_cc_total,
            "DECLINED_404": qualification_404_total,
            "DECLINED_VIDEOS": qualification_videos_total,
            "DECLINED_RECENT": qualification_recent_total,
            "DECLINED_CATEGORY": qualification_category_total,
            "DECLINED_COUNTRY": qualification_country_total,
            "DECLINED_VIEWS": qualification_views_total,
            "QUALIFICATION": qualification_total,
          },
          data: data,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
      });
  }
  retrieveAgentsEmails() {
    // load account managers
    axios.get("/api/v1/team/account-manager?with=admin&with=super-user").then(res => {
      if (res.status == 200) {
        let tmpUsers = res.data.users;
        let listUsers = [];
        tmpUsers.map((user) => {
          if (user.teams.includes("YouTube") || user.teams.includes("Co-creation") || user.teams.includes("Instagram") || user.teams.includes("TikTok") || user.teams.includes("Podcast"))
          listUsers.push({
            'id': user.email,
            'title': `${user.firstName} ${user.lastName}`
          })
        });

        this.setState({
          accountManagers: listUsers
        });
      }
    });
  }
  componentDidUpdate(_prevProps, prevState) {
    if (prevState.search_params != this.state.search_params) {
      this.updateResults();
    }
  }
  componentDidMount() {
    this.updateResults();
    this.retrieveAgentsEmails();
  }
  render() {
    const { isLoading, data, sumByTypes, search_params } = this.state;
    console.log("from:", search_params.day_from);
    console.log("to:", search_params.day_to);
    return (
      <div className="reports">
        <SearchOptions accountManagers={this.state.accountManagers} onSearchUpdate={this.onSearchUpdate} from={search_params.day_from} to={search_params.day_to} />
        {isLoading ? <Loading /> : <ReportsResults data={data} sumByTypes={sumByTypes} searchParams={this.state.search_params} />}
      </div>
    );
  }
}

export default Reports;
