import React from "react";
import axios from 'axios';

import { ALTagInfluencer, ALButton } from "../ALComponents";
import { storageAvailable, reportError } from '../../utils';
import Loading from "../Loading";

import NotificationManager from "../al_components/notification/NotificationManager";



import iconInsta from '../../../src/assets/insta_logo.png';
import iconInstaDisable from '../../../src/assets/insta--disabled.png';

import iconYouTube from '../../../src/assets/youtube_logo.png';
import iconYouTubeDisable from '../../../src/assets/youtube_logo_grey.png';

import './Qualification.css';
import InstagramContainer from "./InstagramContainer";
import YouTubeContainer from "./YouTubeContainer";

class Qualification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingInfluencers: false,
      isLoadingNextInfluencer: false,
      isLoadingMoreVideo: false,
      influencers: [],
      influencer: null,
      tabSelected: null,
      tabsOrder: null,
      tabsViewed: [],
      countInfluencers: null,
      haveLocalStorage: storageAvailable('localStorage'),
    };
    this.HISTORY_KEY = "qualification_history";
    this.getYouTubeVideoData = this.getYouTubeVideoData.bind(this);
    this.getInstagramPostData = this.getInstagramPostData.bind(this);
    this.updateStatusInfluencer = this.updateStatusInfluencer.bind(this);
    this.handleUpdateStatusInfluencer = this.handleUpdateStatusInfluencer.bind(this);
    this.loadMoreVideos = this.loadMoreVideos.bind(this);
    this.loadMorePosts = this.loadMorePosts.bind(this);
    this.updatePlatformInfluencer = this.updatePlatformInfluencer.bind(this);
    this.populateInfluencer = this.populateInfluencer.bind(this);
    this.nextInfluencerAdmin = this.nextInfluencerAdmin.bind(this);
  }

  _handleKeyDown = (event) => {
    if (event.keyCode == 32) {
      const { tabSelected, tabsOrder } = this.state;
      tabsOrder.map((el, i) => {
        if (el == tabSelected && i != (tabsOrder.length - 1)) {
          // found next element to display
          this.setState({ tabSelected: tabsOrder[i + 1] });
        }
        if (el == tabSelected && i == (tabsOrder.length -1)) {
          // last element of the array, show the first one
          this.setState({ tabSelected: tabsOrder[0] });
        }
      })
    }
    if (event.keyCode == 81) {
      console.log(this.state.influencer, "====> INFLUENCER");
      this.handleUpdateStatusInfluencer(this.state.influencer, "no");
    }
  }





  async getYouTubeVideoData(videoId, allow_partial = false) {
    try {
      const res = await axios.get("/api/v1/youtube/video/" + videoId, { params: { allow_partial } });
      if (res.status === 200) {
        this.setState((state) => {
          let data = res.data.video;
          let arrVideos = state.influencer.youtube.accounts[0].latestVideos.list;
          let idxArr = arrVideos.findIndex((video) => {
            if (typeof video === "string") {
              return video === data.videoId
            }
            return video.videoId === data.videoId;
          });
          if (~idxArr) {
            let copyInfluencer = { ...state.influencer };
            copyInfluencer.youtube.accounts[0].latestVideos.list[idxArr] = data;
            return { influencer: copyInfluencer };
          }
        });
      }
    } catch (error) {
      // handle error
      // TODO: something with videoId and show error + reload button? (that query AWS with force_update??)
      console.log(error);
      reportError(error);
    }
  }
  loadMoreVideos() {
    console.log("loadMoreVideos");
    this.setState({ isLoadingMoreVideo: true });
    // load 3 more videos
    let youtubeData = this.state.influencer.youtube.accounts[0];
    let nbVideoRequested = 0;
    Promise.all(youtubeData.latestVideos.list.map((video, i) => {
      if (nbVideoRequested > 2) {
        return;
      }
      if (typeof(video) == "string") {
        nbVideoRequested += 1
        return this.getYouTubeVideoData(video, true);
      }
    })).then(_result => {
      this.setState({ isLoadingMoreVideo: false });
    }).catch((err) => reportError(err));
  }





  getInstagramPostData(postId) {
    let copyInfluencer = this.state.influencer;
    axios.get("/api/v1/aws/post/" + postId)
      .then(res => {
        if (res.status == 200) {
          let data = res.data.result;
          let arrPosts = this.state.influencer.instagram.accounts[0].latestPosts;
          let idxArr = arrPosts.indexOf(data.postId);
          copyInfluencer.instagram.accounts[0].latestPosts[idxArr] = data;

          // update influencer state object
          this.setState({ influencer: copyInfluencer });
        }
      })
      .catch(function (error) {
        // handle error
        // TODO: something with videoId and show error + reload button? (that query AWS with force_update??)
        console.log(error);
        reportError(error);
      });
  }
  loadMorePosts() {
    // load 3 more posts
    const latestPosts = this.state.influencer.instagram.accounts[0].latestPosts;
    let nbPostRequested = 0;
    latestPosts.map((postId, i) => {
      if (nbPostRequested > 3) {
        return;
      }
      if (typeof(postId) == "string") {
        this.getInstagramPostData(postId);
        nbPostRequested += 1
      }
    });
  }



  updatePlatformInfluencer(type, value) {
    console.log("updatePlatformInfluencer");
    console.log(type, "type");
    console.log(value, "value");
    value = value.trim();
    // TODO: ADD NEW PLATFORM
    if (type == "youtube") {
      // TODO: YOUTUBE
      if (value.includes("youtube.com")) {
        value = value.split("channel/")[1].split("/")[0].split("?")[0].split("#")[0].split("/")[0]
      }
      axios.get(`/api/v1/aws/channel/${value}`)
      .then(({data}) => {
        let { result } = data;
        console.log("scrap youtube success", result);
        axios.put("/api/v1/influencers", {"$push": {"youtube.accounts": value}}, {params: { '_id': this.state.influencer["_id"]}})
        .then(() => {
          let updatedInfluencer = {...this.state.influencer};
          updatedInfluencer.youtube.accounts.push(result);
          this.setState({influencer: updatedInfluencer});
        })
        .catch(err => {
          console.error(err);
          NotificationManager.error('Error', 'Server failed to update', 6000);
          reportError(err);
        });
      })
      .catch(err => {
        console.error(err);
        NotificationManager.error('Error', 'Check your input', 6000);
        reportError(err);
      });
    }
    if (type == "instagram") {
      // TODO: INSTAGRAM
      if (value.includes("instagram.com")) {
        value = value.split("instagram.com/")[1].split("/")[0].split("?")[0].split("#")[0].split("/")[0]
      }
      axios.get(`/api/v1/aws/handle/${value}`)
      .then(({data}) => {
        let { result } = data;
        console.log("scrap instagram success", result);
        axios.put("/api/v1/influencers", {"$push": {"instagram.accounts": value}}, {params: { '_id': this.state.influencer["_id"]}})
        .then(() => {
          let updatedInfluencer = {...this.state.influencer};
          updatedInfluencer.instagram.accounts.push(result);
          this.setState({influencer: updatedInfluencer});
        })
        .catch(err => {
          console.error(err);
          NotificationManager.error('Error', 'Server failed to update', 6000);
          reportError(err);
        });
      })
      .catch(err => {
        console.error(err);
        NotificationManager.error('Error', 'Check your input', 6000);
        reportError(err);
      });
    }
  }


  getInfluencers() {
    console.log("GET INFLUENCER")
    if (this.state.isLoadingInfluencers || this.state.countInfluencers === 0) return;
    this.setState({ isLoadingInfluencers: true });
    // Get an influencer to qualify
    // axios.get("/api/v1/influencers_full?status=qualification&random=true").then(res => {
    // axios.get("/api/v1/influencers_full?youtube.accounts=UCJ9i30yMclSeBacaB9Jd0eg").then(res => {
    axios.get("/api/v1/influencers_qualification").then(res => {
      if (res.data.result.length == 0) {
        this.getInfluencersCount();
        this.setState(({influencers}) => ({
          isLoading: false,
          influencer: null,
          influencers: influencers,
          isLoadingInfluencers: false,
        }));
      } else {
        this.setState(({influencers}) => ({
          influencers: [
            ...influencers,
            ...res.data.result.filter(newInfluencer => {
              const noScrappedPlatform = (newInfluencer.youtube.accounts.length === 0 || !newInfluencer.youtube.accounts.some(a => typeof a === "object"))
                && (newInfluencer.instagram.accounts.length === 0 || !newInfluencer.instagram.accounts.some(a => typeof a === "object"));
              if (noScrappedPlatform) {
                console.log("noScrappedPlatform newInfluencer", newInfluencer);
                // console.log(noScrappedPlatform, influencers.find(currentInfluencer => currentInfluencer._id === newInfluencer._id), !noScrappedPlatform && !influencers.find(currentInfluencer => currentInfluencer._id === newInfluencer._id))
                // alert("No scrapped platform!");
              }
              return !noScrappedPlatform && !influencers.find(currentInfluencer => currentInfluencer._id === newInfluencer._id);
            })
          ],
          isLoadingInfluencers: false,
        }));
        // this.populateInfluencer();
      }
    }).catch((err) => reportError(err));
  }


  populateInfluencer() {
    console.log("POPULATE")
    const { influencers } = this.state;
    if (influencers.length === 0) {
      return;
    }
    let influencer = this.state.influencers[0]; // first influencer

    let displayTab = "youtube";
    let tabsOrder = ["youtube", "instagram"]; // default
    if (!influencer.youtube) {
      axios.post("/api/v1/send_slack_debug", JSON.stringify(influencer)).catch((err) => reportError(err));
    }
    if ((!influencer?.youtube?.accounts || influencer.youtube.accounts.length === 0) && influencer.instagram.accounts.length > 0) {
      displayTab = "instagram";
      tabsOrder = ["instagram", "youtube"];
    }
    this.setState({
      influencer: influencer,
      tabSelected: displayTab,
      tabsOrder: tabsOrder,
      isLoading: false,
      isLoadingNextInfluencer: false
    });

    // Get YouTube videos
    console.log("DEBUG INFLUENCER", influencer);
    console.log("DEBUG YOUTUBE", influencer.youtube);
    if (influencer.youtube.accounts.length > 0) {
      const latestVideos = influencer.youtube.accounts[0].latestVideos.list;
      console.log(latestVideos);
      let nbVideoRequested = 0;
      if (latestVideos.length > 0) {
        latestVideos.forEach((videoId, i) => {
          if (nbVideoRequested >= 3 || i >= 6) {
            return;
          }
          if (typeof(videoId) == "string") {
            this.getYouTubeVideoData(videoId, true);
            nbVideoRequested += 1;
          }
        });
      }
    }

    // Get Instagram posts
    if (influencer.instagram.accounts.length > 0 && typeof influencer.instagram.accounts[0] === "object") {
      console.log(influencer.instagram.accounts, "influencer.instagram.accounts");
      const latestPosts = influencer.instagram.accounts[0].latestPosts;
      console.log(latestPosts);
      let nbPostRequested = 0
      if (latestPosts) {
        latestPosts.map((postId, j) => {
          if (nbPostRequested > 3) {
            return;
          }
          if (typeof(postId) == "string") {
            this.getInstagramPostData(postId);
            nbPostRequested += 1;
          }
        });
      }
    }
  }





  addHistory(influencer, action) {
    const actions = ["youtube", "instagram", "youtube_instagram", "no", "co_creation"];
    let history = JSON.parse(localStorage.getItem(this.HISTORY_KEY)) || [];
    let thumbnail = "";
    let name = influencer.name;
    if (influencer.youtube.accounts && typeof(influencer.youtube.accounts[0]) == "object") {
      thumbnail = influencer.youtube.accounts[0].pictureURL;
      name = name || influencer.youtube.accounts[0].title;
    } else if (influencer.instagram.accounts && typeof(influencer.instagram.accounts[0]) == "object") {
      thumbnail = influencer.instagram.accounts[0].pictureURL;
      name = name || influencer.instagram.accounts[0].fullName;
    }
    const new_history_obj = {
      id: influencer._id,
      thumbnail: thumbnail,
      name: name,
      decision: action,
      youtube: influencer.youtube.accounts.map(a => typeof(a) === "object" ? a["channelId"] : a),
      instagram: influencer.instagram.accounts.map(a => typeof(a) === "object" ? a["profileId"] : a),
      actions: actions.filter(e => e !== action),
    };
    history.unshift(new_history_obj);
    history = history.reduce((acc, e) => !acc.find(new_e => new_e.id === e.id) ? [...acc, e] : acc, []);
    history = history.slice(0, 30);
    localStorage.setItem(this.HISTORY_KEY, JSON.stringify(history));
    return history;
  }
  updateHistory(history_obj, action) {
    const actions = ["youtube", "instagram", "youtube_instagram", "no", "co_creation"];
    let history = JSON.parse(localStorage.getItem(this.HISTORY_KEY));
    const idx = history.findIndex((e) => e.id === history_obj.id);
    const new_history_obj = {
      ...history.splice(idx, 1)[0],
      decision: action,
      actions: actions.filter(e => e !== action)
    };
    history.unshift(new_history_obj);
    localStorage.setItem(this.HISTORY_KEY, JSON.stringify(history));
    return history;
  }
  async handleHistoryUpdate(history_obj, action) {
    const { data } = await axios.get(`/api/v1/influencers_full?_id=${history_obj.id}`).catch((err) => reportError(err));
    console.log("handleHistoryUpdate", data);
    const influencer = data.result[0];
    this.updateStatusInfluencer(influencer, action);
    const full_history = this.updateHistory(history_obj, action);
    return full_history;
  }

  getInfluencersCount() {
    axios.get("/api/v1/influencers_full_count?status=qualification").then((r) => {
      if (r.status == 200) {
        this.setState({ countInfluencers: r.data.count });
      }
    }).catch(err => reportError(err));
  }


  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
    // Init local storage for History
    if (!localStorage.getItem(this.HISTORY_KEY)) {
      localStorage.setItem(this.HISTORY_KEY, JSON.stringify([]));
    }
    this.getInfluencers();
    this.getInfluencersCount();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  componentDidUpdate(_prevProps, _prevState) {
    if (!this.state.tabsViewed.includes(this.state.tabSelected)) {
      this.setState({tabsViewed: [...this.state.tabsViewed, this.state.tabSelected]});
    }
    if (this.state.influencers.length <= 5 && this.state.countInfluencers > this.state.influencers.length) {
      this.getInfluencers();
    }
    if (this.state.influencers.length && (!this.state.influencer) || (this.state.influencer?._id !== this.state.influencers[0]?._id)) {
      this.populateInfluencer();
    }
  }

  handleUpdateStatusInfluencer(influencer, action) {
    this.updateStatusInfluencer(influencer, action).then(() => {
      this.addHistory(influencer, action)
    });
  }

  async updateStatusInfluencer(influencer, action) {
    let update = {};
    let eventType = "";
    this.setState({
      isLoadingNextInfluencer: true,
      tabsViewed: []
    });
    console.log("updateStatusInfluencer: " + action + ", for _id: " + influencer["_id"]);
    if (action === "no") {
      update["status"] = "qualification_no";
      if (influencer["youtube"]["accounts"].length > 0) {
        update["youtube.status"] = "qualification_no";
      }
      if (influencer["instagram"]["accounts"].length > 0) {
        update["instagram.status"] = "qualification_no";
      }
      eventType = "QUALIFICATION_NO";
    }
    if (action === "youtube") {
      update["status"] = "email_retrieval";
      update["youtube.status"] = "qualification_yes";
      if (influencer["instagram"]["accounts"].length > 0) {
        update["instagram.status"] = "qualification_no";
      }
      eventType = "QUALIFICATION_YES";
    }
    if (action === "instagram") {
      update["status"] = "email_retrieval";
      update["instagram.status"] = "qualification_yes";
      if (influencer["youtube"]["accounts"].length > 0) {
        update["youtube.status"] = "qualification_no";
      }
      eventType = "QUALIFICATION_YES";
    }
    if (action === "youtube_instagram") {
      update["status"] = "email_retrieval";
      update["youtube.status"] = "qualification_yes";
      update["instagram.status"] = "qualification_yes";
      eventType = "QUALIFICATION_YES";
    }
    if (action === "co_creation") {
      update["status"] = "email_retrieval";
      if (influencer["youtube"]["accounts"].length > 0) {
        update["youtube.status"] = "qualification_yes";
      }
      if (influencer["instagram"]["accounts"].length > 0) {
        update["instagram.status"] = "qualification_yes";
      }
      eventType = "QUALIFICATION_CC";
    }

    if (action !== "no" && influencer.emails && influencer.emails.length > 0) {
      update["status"] = "outreach_split"
    }


    try {
      const res = await axios.put("/api/v1/influencers", update, {params: { '_id': influencer["_id"]}})
      console.log("Successfully updated");
      console.log(res, "res Update");
      axios.post("/api/v1/event", {
        type: eventType,
        user: this.props.user.email,
        sample: { object: influencer["_id"] }
      }).catch((err) => {
        console.log(err)
        reportError(err);
        alert(`Failed to fire event ${eventType}`)
      });
      // remove first influencer from "this.state.influencers"
      this.setState({ influencers: this.state.influencers.slice(1) });
      return;
      // if (this.state.influencers.length == 0) {
      //   return this.getInfluencers();
      // } else {
      //   return this.populateInfluencer();
      // }
    } catch (err) {
      console.log(err);
      NotificationManager.error('Error', 'Server failed to update', 6000);
      reportError(err);
    }
    if (action == "co_creation") {
      try {
        await axios.post(`/api/v1/influencers/${influencer._id}/campain_tags`, { id: "5f776b45003ba3d0b3111c7e" })
        console.log("co-creation campain tag added!");
      } catch (err) {
        console.log(err);
        reportError(err);
        NotificationManager.error('Error', 'Influencer validated but failed to pass it to co-creation. Please add it manually.', 6000);
      }
    }
  }

  // skip one influencer on demand without qualification
  nextInfluencerAdmin() {
    this.setState({
      influencers: this.state.influencers.slice(1),
      isLoadingNextInfluencer: true,
      tabsViewed: []
    });
    if (this.state.influencers.length == 0) {
      return this.getInfluencers();
    } else {
      return this.populateInfluencer();
    }
  }


  render() {
    const { isLoadingNextInfluencer, isLoading, influencer, tabsOrder, tabSelected, tabsViewed, haveLocalStorage, countInfluencers } = this.state;
    let hasYoutube = false;
    let hasInstagram = false;
    let youtubeData = null;
    let instagramData = null;
    let history_button = null;
    let gender = null;
    if (influencer) {
      if (influencer && influencer.instagram.accounts.length > 0) {
        hasInstagram = true;
        instagramData = influencer.instagram.accounts[0];
        console.log(instagramData);
      }
      if (influencer && influencer.youtube.accounts.length > 0) {
        hasYoutube = true;
        youtubeData = influencer.youtube.accounts[0];
        console.log(youtubeData);
      }
      if (influencer) {
        if (influencer.gender != null) {
          gender = {
            "gender": (influencer.gender.value || null),
            "age_low": (influencer.age && influencer.age.low) ? (influencer.age.low) : (null),
            "age_high": (influencer.age && influencer.age.high) ? (influencer.age.high) : (null),
          };
        }
        if (influencer.age != null) {
          gender = {
          }
        }
      }
  
      history_button = (haveLocalStorage ?
        <ALButton type="text" material_icon="history" onClick={() => this.props.showModal("ModalQualificationHistory", {
          history: JSON.parse(localStorage.getItem(this.HISTORY_KEY)),
          actions: {
            "youtube": (historyObj) => this.handleHistoryUpdate(historyObj, "youtube"),
            "instagram": (historyObj) => this.handleHistoryUpdate(historyObj, "instagram"),
            "youtube_instagram": (historyObj) => this.handleHistoryUpdate(historyObj, "youtube_instagram"),
            "co_creation": (historyObj) => this.handleHistoryUpdate(historyObj, "co_creation"),
            "no": (historyObj) => this.handleHistoryUpdate(historyObj, "no"),
          }
        })}>HISTORY</ALButton>
        : <p style={{color: "orangered"}}>Permission to browser's local storage haven't been granted. History will not be available.</p>);
  
      // console.log(hasYoutube, "hasYoutube");
      // console.log(hasInstagram, "hasInstagram");
      console.log("influencer", influencer);
    }
    let isAdmin = this.props.user.role === "super-user";
    return (
      <div className="qualification">
        {(!isLoading) ? (influencer) ? (
          <div className="qualification_container">
            {(isAdmin) ? (
              <div className="qualification__next" onClick={() => this.nextInfluencerAdmin()}>
                SKIP
              </div>
            ) : (null)}
            <div className="qualification__top_bar">
              {/* START LIST PLATFORMS */}
              <div className="qualification__platforms">
                {tabsOrder.map((e, i) => {
                  if (e == "youtube") {
                    return (
                      <div
                        className={`qualification__platforms__platform ${(tabSelected == "youtube") ? ('qualification__platforms__platform--isSelected') : (null) }`}
                        data-platform="youtube"
                        onClick={() => { this.setState({ tabSelected: "youtube" }); }}
                        key={`tab_${i}`}
                      >
                        <p className="qualification__platforms__platform__title">YOUTUBE</p>
                        <img className="qualification__platforms__platform__icon" src={(hasYoutube) ? iconYouTube : iconYouTubeDisable} alt="youtube" />
                      </div>
                    );
                  }
                  if (e == "instagram") {
                    return (
                      <div
                        className={`qualification__platforms__platform ${(tabSelected == "instagram") ? ('qualification__platforms__platform--isSelected') : (null) }`}
                        data-platform="instagram"
                        onClick={() => { this.setState({ tabSelected: "instagram" }); }}
                        key={`tab_${i}`}
                      >
                        <p className="qualification__platforms__platform__title">INSTAGRAM</p>
                        <img className="qualification__platforms__platform__icon" src={(hasInstagram) ? iconInsta : iconInstaDisable} alt="insta" />
                      </div>
                    );
                  }
                })}
              </div>

              {(countInfluencers) ? (
                <p className="qualification__top_bar_count">
                  {countInfluencers}
                  {(countInfluencers < 2) ? (
                    " influencer"
                  ) : (
                    " influencers"
                  )}
                </p>
              ) : (null)}
              {/* END LIST PLATFORMS */}
              <div className="qualification__top_bar_history">
                {history_button}
              </div>

            </div>

            {/* <div style={{ cursor: 'pointer'}} onClick={() => {
                NotificationManager.init('Success message', 'Description', 3500);
                NotificationManager.error('Error message', 'Click', 2000, () => {
                  alert('callback');
                });
                NotificationManager.warning('Warning message', 'Description', 4000);
                NotificationManager.success('Success message', 'Description', 5000);
              }}
            >TEST NOTIFICATION</div> */}

            <div className="qualification__platforms__actions_containers_wrapper">
              {/* START DISPLAY INFO SELECTED */}
              {(tabSelected == "youtube") ? (
                <YouTubeContainer
                  data={youtubeData}
                  gender={gender}
                  keyword={influencer.keyword || null}
                  name={influencer.name || ""}
                  emails={influencer.emails || []}
                  loadMore={this.loadMoreVideos}
                  isLoadingMoreVideo={this.state.isLoadingMoreVideo}
                  updatePlatformId={this.updatePlatformInfluencer}
                  getYouTubeVideoData={this.getYouTubeVideoData}
                />
              ) : (
                (tabSelected == "instagram") ? (
                  <InstagramContainer
                    data={instagramData}
                    gender={gender}
                    keyword={influencer.keyword || null}
                    name={influencer.name || ""}
                    emails={influencer.emails || []}
                    loadMore={this.loadMorePosts}
                    updatePlatformId={this.updatePlatformInfluencer}
                  />
                ) : (null)
              )}
              {/* END DISPLAY INFO SELECTED */}

              {/* START QUALIF BUTTONS */}
              <div className="q_actions">
                {(isLoadingNextInfluencer) ? (
                  <div>
                    <Loading />
                  </div>
                ):(
                  <>
                    <ALTagInfluencer idInfluencer={influencer._id} tagsInfluencer={influencer.tags} showMostPopular={true} user={this.props.user} />
                    <br />
                    <br />
                    {/* {tabsOrder.every(tab => influencer[tab].accounts.length == 0 || tabsViewed.includes(tab)) ? <> */}
                    {true ? <>
                      <div className="q_actions__button q_actions__button--decline" onClick={() => this.handleUpdateStatusInfluencer(influencer, "no")}>
                        <span className="q_actions__buttons--round">&nbsp;</span>
                        <span className="q_actions__buttons--text">No</span>
                      </div>
                      { hasYoutube ?
                        <div className="q_actions__button q_actions__button--approve" onClick={() => this.handleUpdateStatusInfluencer(influencer, "youtube")}>
                          <span className="q_actions__buttons--round">&nbsp;</span>
                          <span className="q_actions__buttons--text">Youtube</span>
                        </div>
                      : null }
                      { hasInstagram ?
                        <div className="q_actions__button q_actions__button--approve" onClick={() => this.handleUpdateStatusInfluencer(influencer, "instagram")}>
                          <span className="q_actions__buttons--round">&nbsp;</span>
                          <span className="q_actions__buttons--text">Instagram</span>
                        </div>
                      : null }
                      { hasYoutube && hasInstagram ?
                        <div className="q_actions__button q_actions__button--approve" onClick={() => this.handleUpdateStatusInfluencer(influencer, "youtube_instagram")}>
                          <span className="q_actions__buttons--round">&nbsp;</span>
                          <span className="q_actions__buttons--text">Both YT & IG</span>
                        </div>
                      : null }
                    </> : <span className="q_actions__need_all_viewed">Please check other platforms first</span>}

                    <div className="q_actions__cocreation" onClick={() => this.handleUpdateStatusInfluencer(influencer, "co_creation")}>
                      CO-CREATION
                    </div>
                  </>
                )}
              </div>
              {/* END QUALIF BUTTONS */}
            </div>
          </div>
        ) : (
          <div className="qualification__empty_message"><p>No influencers found in qualification</p>{history_button}</div>
        ) : <div className="qualification__empty_message"><Loading /><p>Loading influencer...</p>{history_button}</div>}
      </div>
    );
  }
}

export default Qualification;
