import React from "react";

import HeaderComponent from "./HeaderComponent";
import axios from 'axios';
import "../App.css";

class DataStreamlit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    axios.get("/auth/current_user").then(res => {
      this.setState({ user: res.data });
    });
  }

  render() {
    return (
      <div className="channels-page-wrapper data_streamlit">
        <HeaderComponent title="" to="/a/dashboard/" toTitle="Home" />
        <p className="data_streamlit__error"><span className="material-icons">info</span>If you have an 403 error (most probably because it's an heavy file) you should use the "live" version of this software. Click <a href="https://analuisa-data.herokuapp.com/" target="_blank" rel="noopener noreferrer">here</a></p>
        <div className="data_streamlit__container">
          <iframe src="https://analuisa-data.herokuapp.com/" />
        </div>
      </div>
    );
  }
}

export default DataStreamlit;
