import React, { useState } from "react";
import axios from "axios";
import CurrentDealTypeformInfo from "./typeformInfo/CurrentDealTypeformInfo";
import CurrentDealShippingInfo from "./shippingInfo/CurrentDealShippingInfo";

async function getShippingInfo(dealId) {
  const dealShippingResult = await axios.get("/api/v1/influencer_deal_shipping", { params: { "dealId": dealId } });
  return dealShippingResult.data.result.sort((a, b) => new Date(b.date) - new Date(a.date));
}

const CurrentDealShipping = ({ user, deal, influencer, updateDeal, updateDateSign, showModal }) => {
  const [dealShippingLoading, setDealShippingLoading] = useState(false);
  const [isDealShippingLoaded, setIsDealShippingLoaded] = useState(false);
  const [dealShippings, setDealShippings] = useState([]);
  useState(() => {
    if (dealShippingLoading) return;
    setDealShippingLoading(true);
    getShippingInfo(deal._id)
      .then((newDealShippings) => {
        setDealShippings(newDealShippings);
      })
      .finally(() => {
        setDealShippingLoading(false);
        setIsDealShippingLoaded(true);
      });
  }, [deal]);
  const shouldDisplayTypeformInfo = ["done", "to_ship"].includes(deal.status);
  const shouldDisplayShippingInfo = ["to_ship", "shipped", "in_transit", "shipment_received", "published"].includes(deal.status);
  return (
    <div className={`oi_deal_shipping ${!deal?.shipping_typeform ? "oi_deal_shipping--overflow-visible" : ""}`}>
      {shouldDisplayTypeformInfo && (
        <CurrentDealTypeformInfo {...{ user, deal, updateDeal, updateDateSign, influencer, showModal, isDealShippingLoaded, dealShippings }} />
      )}
      {shouldDisplayShippingInfo && (
        <CurrentDealShippingInfo {...{ deal, updateDeal, showModal, isDealShippingLoaded, dealShippings, dealShippingLoading }} />
      )}
    </div>
  );
};

export default CurrentDealShipping;
