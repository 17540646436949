import React from "react";
import HeaderComponent from "../HeaderComponent";
import axios from "axios";
import { ALButton, ALContainer, ALDragAndDrop } from "../ALComponents";
import "./ChannelsEmails.css";
import _ from "lodash";
// import moment from 'moment';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class ChannelsEmails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChannels: null,
      channelBeingProcessed: 0,
      channelDone: 0
    };
  }

  processChannels(channelId) {
    console.log("process Channels");
/*

    axios({
      method: 'put',
      url: `/api/v1/youtube/${theChannel.channelId}`,
      data: theChannel
    }).then((r) => {
      console.log('on click yes');
      console.log(r);
      if (r.status === 200) {

      }
    }).catch((err) => {
      console.log(err);
    });
*/

  }

  componentDidUpdate(prevProps, prevState) {
    const { dataChannels } = this.state;
    if (prevState.dataChannels != dataChannels) {
      // const theChannelId = "UCjqpOFyZtpfoUxjhTbuUGTw";
      // let channelPosition = _.findIndex(dataChannels, { "channel": "https://www.youtube.com/channel/" + theChannelId });
      // console.log(channelPosition);
    }
  }

  
  async updateChannel() {
    const MAX_CONCURRENCY = 5;
  
    for (let i = 0; i < this.state.dataChannels.length; i++) {
      for (let i = 0; i < 30 && this.state.channelBeingProcessed >= MAX_CONCURRENCY ; i++) {
        await sleep(1000);
      }
      this.setState((state) => {
        if (state.dataChannels[i]) {
          let tmpChannels = [...state.dataChannels]
          tmpChannels[i] = { ...tmpChannels[i], "isDone": "doing" };
          return {
            dataChannels: tmpChannels,
            channelBeingProcessed: state.channelBeingProcessed + 1
          };
        }
      });

      let channelId = this.state.dataChannels[i]["channel"].replace("https://www.youtube.com/channel/", "")

      axios({
        method: 'put',
        url: `/api/v1/youtube/${channelId}`,
        data: {
          "channelId": channelId,
          "status": "toScrap"
        }
      }).then((r) => {
        if (r.status === 200) {
          return this.setState((state) => {
            if (state.dataChannels[i]) {
              let tmpChannels = [...state.dataChannels]
              tmpChannels[i] = { ...tmpChannels[i], "isDone": "done" };
              return {
                dataChannels: tmpChannels,
                channelBeingProcessed: state.channelBeingProcessed - 1,
                channelDone: state.channelDone + 1
              };
            }
          });
        }
      }).catch((err) => {
        return this.setState((state) => {
          if (state.dataChannels[i]) {
            let tmpChannels = [...state.dataChannels]
            tmpChannels[i] = { ...tmpChannels[i], "isDone": "failed" };
            return {
              dataChannels: tmpChannels,
              channelBeingProcessed: state.channelBeingProcessed - 1
            };
          }
        });
      });



    }
  }

  render() {
    const { dataChannels, channelDone } = this.state;
    return (
      <div className="channelsEmails_container">
        <HeaderComponent title="Process Emails MTurk" to="/a/dashboard/YouTube" toTitle="YouTube" />
          <div className="channelsEmails_container__header">
            <p>Drap and Drop your CSV files with the list of channel you want to re-process</p>
            <ALDragAndDrop type="text/csv" handleFile={(data) => { this.setState({ dataChannels: data }, () => setTimeout(() => this.updateChannel()), 0); }} />
          </div>
          {(dataChannels) ? (
            <div>
              <p className="channelsEmails_sum">{channelDone}/{dataChannels.length}</p>
              <p className="channelsEmails_res">You should except around {parseInt(channelDone * 0.125)} emails from this list</p>
              <div className="channelsEmails_list">
                {dataChannels.map((ch, i) => {                
                  return (
                    <div className="channelsEmails_list_channel" key={`channel_${i}`}>
                      <p className="channelsEmails_list_channel_channelid">{ch["channel"].replace("https://www.youtube.com/channel/", "")}</p>
                      {(ch["isDone"] == "done") ? (
                        <p className="channelsEmails_list_channel_status channelsEmails_list_channel_status--done"><span className="material-icons">done</span></p>
                      ) : (
                        <p className="channelsEmails_list_channel_status channelsEmails_list_channel_status--doing rotating"><span className="material-icons">refresh</span></p>
                      )}
                      
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (null)}
      </div>
    );
  }
}

export default ChannelsEmails;
