import React from 'react';
import PropTypes from 'prop-types';
import './Tags.css';

const Tags = ({ tags, removeTag, disableRemove }) => {
  if (tags.length === 0) {
    return null;
  }

  return (
    <div className="tags_wrapper">
      {tags.map((tag, i) =>
        <div className="tags_wrapper--tag" key={i}>
          <p>{tag}</p>
          {!disableRemove &&
            <span
              className="material-icons"
              onClick={() => removeTag(tag)}>
              close
            </span>
          }
        </div>
      )}
    </div>
  )
}

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
  removeTag: PropTypes.func,
  disableRemove: PropTypes.bool
};

export default Tags;