import React from "react";
import _ from "lodash";
import axios from "axios";
// import moment from 'moment';
import YouTubeVideo from "./YouTubeVideo";
import "./YouTubeContainer.css";
import Loading from "../Loading";

class YouTubeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      gender: this.props.gender,
      isLoadingMoreVideo: this.props.isLoadingMoreVideo,
      showDescription: false,
      newChannelId: null,
    };
  }

  componentDidMount() {
    console.log(this.props.data);
    if (typeof(this.props.data) == "string") {
      axios.get(`/api/v1/aws/channel/${this.props.data}`)
        .then(({data}) => {
          let { result } = data;
          this.setState({ data: result });
        })
        .catch(err => console.log(err)||alert("Failed. Please check your input again.")) 
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.data && this.props.data) {
      return this.setState({
        data: this.props.data,
        gender: this.props.gender,
      });
    }
    if (prevProps.isLoadingMoreVideo != this.props.isLoadingMoreVideo) {
      return this.setState({
        isLoadingMoreVideo: this.props.isLoadingMoreVideo
      })
    }
    if (prevProps.data && this.props.data && prevProps.data.channelId != this.props.data.channelId) {
      return this.setState({
        data: this.props.data,
        gender: this.props.gender,
      });
    }
  }


  render() {
    const { data, gender, showDescription, isLoadingMoreVideo } = this.state;
    let getTrimmedMean = (data, trimAmount) => {
      let trimCount = Math.floor(trimAmount*data.length);
      let sortedData = data.sort(function(a, b) {
        return b - a;
      });
      let trimData = sortedData.slice(trimCount, data.length-trimCount);
      return trimData.reduce((a, b) => a + b ,0)/trimData.length;
    }

    


    // let totalViews = 0;
    let nbVideos = 0
    let avgViews = 0;
    let arrViews = [];
    if (data) {
      if (typeof(data) != "string") {
        data.latestVideos.list.map((video, i) => {
          if (typeof(video) != "string") {
            nbVideos += 1;
            arrViews.push(video.stats.views);
          } else {
            return;
          }
        });
      }
    }
    console.log(arrViews, "==> arrViews");

    avgViews = getTrimmedMean(arrViews, 0.1).toFixed(2);
    console.log(avgViews, "==> avgViews");
    console.log("data.country", data.country, data);

    return (
      <div className="q_youtube">
        {(data && typeof(data) != "string") ? (
          <>
            <div className="q_youtube__header">
              {/* header informations */}
              <div className="q_youtube__header_meta">
                <a className="q_youtube__header_meta_link" href={`https://www.youtube.com/channel/${data.channelId}/videos`} key={data.channelId} target="_blank" rel="noopener noreferrer">
                  <img className="q_youtube__header_meta_picture" src={data.pictureURL.replace("=s900", "=s200")} referrerPolicy="no-referrer"/>
                </a>
                <div className="q_youtube__header_meta_details">
                  <a href={`https://www.youtube.com/channel/${data.channelId}/videos`} target="_blank" rel="noopener noreferrer" className="q_youtube__header_meta_details--name">{data.title || "Channel name"}</a>
                  <p className="q_youtube__header_meta_details--views">{new Intl.NumberFormat().format(data.stats.views) || "ø"} views</p>
                  <p className="q_youtube__header_meta_details--subs">{new Intl.NumberFormat().format(data.stats.subscribers) || "ø"} subscribers</p>
                </div>
              </div>
              {/* description */}
              {(data.description && data.description.length > 0) ? (
                <div className="q_youtube__header_description">
                  {(showDescription) ? (
                    <div className={`q_youtube__header_description_details ${(showDescription) ? 'q_youtube__header_description_details--isVisible' : ''}`}>
                      <span className="material-icons" onClick={() => { this.setState({ showDescription: !this.state.showDescription }) }}>close</span>
                      {data.description}
                    </div>
                  ) : (
                    <div className="q_youtube__header_description_button" onClick={() => { this.setState({ showDescription: !this.state.showDescription }) }}>Show description<span className="material-icons">keyboard_arrow_down</span></div>
                  )}
                </div>
              ) : (null)}
              {/* name / email */}

              <p className="q_youtube__header_name">{(this.props.name) ? (this.props.name) : ("\u00a0")}</p>
              
                <p className="q_youtube__header_emails">
                  {(this.props.emails != []) ? (
                    this.props.emails.map((e, i) => (
                      <span key={`email_${i}`}>{e}</span>
                    ))
                  ) : ("\u00a0")}
                </p>
              {/* general infos (country, ...) */}
              <div className="q_youtube__header_general">
                {(data.country) ? (
                  <div className="q_youtube__header_general_det">
                    <span className="material-icons">outlined_flag</span>
                    <span className="q_youtube__header_general_det_value">{data.country}</span>
                  </div>
                ) : (null)}
                {(gender && gender.gender) ? (
                  <div className="q_youtube__header_general_det">
                    <span className="material-icons">face</span>
                    <span className="q_youtube__header_general_det_value">{gender.gender}</span>
                  </div>
                ) : (null)}
                {(this.props.keyword) ? (
                  <div className="q_youtube__header_general_det">
                    <span className="material-icons">label</span>
                    <span className="q_youtube__header_general_det_value">{this.props.keyword}</span>
                  </div>
                ) : (null)}
                {(avgViews) ? (
                  <div className="q_youtube__header_general_det">
                    <span className="material-icons">show_chart</span>
                    <span className="q_youtube__header_general_det_value">{new Intl.NumberFormat().format(avgViews)} views/video</span>
                  </div>
                ) : (null)}
              </div>
            </div>
            <div className="q_youtube__videos" onScroll={this.handleScroll}>
              {(nbVideos == 0) ? (
                <div className="loading_video">
                  {data.latestVideos?.list?.length > 0 ? <>
                    <Loading />
                    <p className="loading_video_text">We are loading the videos, give us one moment please!</p>
                  </>
                  : <p className="loading_video_text">Couldn't scrap videos, please check directly on the YouTube channel!</p>}
                </div>
              ) : (
                <>
                  {data.latestVideos.list.map((video, i) => {
                    if (typeof(video) != "string") {
                      return (
                        <YouTubeVideo data={video} key={`video_${video.videoId || ""}_${i}`} getYouTubeVideoData={this.props.getYouTubeVideoData} />
                      );
                    }
                  })}
                  {(isLoadingMoreVideo) ? (
                    <div className="q_youtube__videos__load--loading">
                      <Loading />
                      <p>Loading more videos...</p>
                    </div>
                  ) : (
                    <div className="q_youtube__videos__load" onClick={() => { this.props.loadMore() }}>Load more videos</div>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          (typeof(data) == "string") ? (
            <Loading />
          ) : (
            <div className="q_youtube__update">
              <input type="text" placeholder="Please add the channelId / url" onChange={(e) => { this.setState({ newChannelId: e.target.value}); }} />
              <div className="q_youtube__update--button" onClick={() => this.props.updatePlatformId("youtube", this.state.newChannelId)}>Update</div>
            </div>
          )
        )}
      </div>
    );
  }
}

export default YouTubeContainer;
