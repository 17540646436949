import React from "react";
import "./container.css";

class ALContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className={`al_container ${this.props.className || ''}`}>{this.props.children}</div>;
  }
}

export default ALContainer;
