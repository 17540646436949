import React from "react";
import { formatISO } from 'date-fns';
import axios from 'axios';

import { ALDropdown, ALLoading } from "../ALComponents";
import DealNotes from "components/DealNotes";
import CurrentDealName from './currentDeal/CurrentDealName';
import CurrentDealDates from "./currentDeal/CurrentDealDates";
import CurrentDealContent from "./currentDeal/CurrentDealContent";
import CurrentDealStatus from './currentDeal/CurrentDealStatus';
import NotificationManager from "../al_components/notification/NotificationManager";
import Loading from "../Loading";
import { ReactComponent as WarningIcon } from "assets/warning_icon.svg";
import paypal_logo from "assets/paypal_logo.png";
import "./CurrentDeal.css";
import { CAMPAIGNS, BRIEF_TYPES_BY_CAMPAIGN, SIGNED_DEAL_STATUSES } from "dictionaries";
import CurrentDealShipping from "./currentDeal/CurrentDealShipping";

class CurrentDeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deal: this.props.deal,
      isOpen: this.props.isOpen,
      isEditingPricing: false,
      updatePriceValues: {
        initial_price: null,
        price: null,
        payment_status: null,
        number_pieces: null,
      },
      typeformAnswers: null,
      typeformAnswersWithoutOrder: null,
      typeformAnswersLoading: false,
      tmpShippingTypeform: { platform: this.props.deal?.shipping_typeform?.platform || "youtube", entry_id: null, type: null },
      dealShipping: [],
      dealShippingLoading: false,
      isDealShippingLoaded: false,
      areTransactionsLoaded: false,
      isLoadingTransactions: false,
    };
    this.TYPEFORM_BY_SPREADSHEET = ["youtube", "instagram", "podcast", "tiktok", "bilibili"];
    this.updateDealInfo = this.updateDealInfo.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.resetChanges = this.resetChanges.bind(this);
    this.updateDateSign = this.updateDateSign.bind(this);
    this.updateDeal = this.updateDeal.bind(this);
    this.onDealUpdated = this.onDealUpdated.bind(this);
    this.toggleDeal = this.toggleDeal.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.influencer !== prevProps.influencer || this.props.deal !== prevProps.deal) {
      // reset deal data
      this.resetAll(this.props.deal);
    }
    if (this.props.deal._id !== prevProps.deal._id || !this.state.isLoadingTransactions && (this.state.deal.payment_transactions || []).some((transaction) => !!transaction.id)) {
      this.getDealFullTransactions(this.props.deal);
    }
  }

  componentDidMount() {
    this.getDealFullTransactions(this.props.deal);
  }

  async getDealFullTransactions(deal) {
    this.setState({ areTransactionsLoaded: false, isLoadingTransactions: true });
    const fullPaymentTransactions = await Promise.all((deal.payment_transactions || []).map((t) => axios.get(`/api/v1/transactions/${t.id || t._id}`)))
    this.props.updateInfluencerDeal(deal._id, deal.id_influencer, {
      payment_transactions: fullPaymentTransactions.map((r) => r.data.result)
    });

    this.setState({
      areTransactionsLoaded: true,
      isLoadingTransactions: false,
    });
  }

  resetAll(deal) {
    this.setState({
      deal: deal,
      isEditingPricing: false,
      updatePriceValues: {
        initial_price: null,
        price: null,
        payment_status: null,
        number_pieces: null,
      },
      tmpShippingTypeform: deal?.shipping_typeform ? deal?.shipping_typeform : { platform: deal?.shipping_typeform?.platform || "youtube", entry_id: null, type: null },
      dealShipping: [],
      dealShippingLoading: false,
      areTransactionsLoaded: (deal.payment_transactions || []).every((transaction) => !!transaction._id),
      isLoadingTransactions: false,
    });
  }

  handleChangeInput(type, key, value) {
    let tmpKey = null;
    let tmpValues = null;
    if (type === "pricing") {
      tmpKey = "updatePriceValues";
      tmpValues = this.state.updatePriceValues;
    }
    if (tmpValues) {
      tmpValues[key] = value;
      return this.setState({ [tmpKey]: tmpValues });
    }
  }

  updateDealInfo(type) {
    let dealUpdate = {}
    if (type === "pricing") {
      const { updatePriceValues, deal } = this.state;
      for (const key in updatePriceValues) {
        if (updatePriceValues[key]) {
          if (key === "price" && (deal.initial_price === null && updatePriceValues.initial_price === null)) {
            return NotificationManager.error("Please input initial price first", "Can't set final price without initial price", 6000);
          }
          dealUpdate[key] = parseInt(updatePriceValues[key]);
        }
      }
    }
    if (type === "typeform") {
      dealUpdate["shipping_typeform"] = `${this.state.tmpShippingTypeform}`
    }

    if (Object.keys(dealUpdate).length > 0) {
      axios.put(`/api/v1/deals/${this.state.deal._id}`, dealUpdate)
      .then(({ data }) => {
        this.onDealUpdated(data.result);
        let eventPromises = [];
        switch (type) {
          case "pricing":
            if (this.state.updatePriceValues.price !== null) {
              eventPromises.push(axios.post("/api/v1/event", {
                type: "UDPATE_PRICE_DEAL",
                user: this.props.user.email,
                sample: { object: this.state.deal._id },
              }));
            }
            if (this.state.updatePriceValues.payment_status !== null) {
              eventPromises.push(axios.post("/api/v1/event", {
                type: "UPDATE_PAYEMENT_STATUS_DEAL",
                user: this.props.user.email,
                sample: { object: this.state.deal._id },
              }));
            }
            break;
          default:
            break;
        }
        Promise.all(eventPromises).catch((err) => console.log(err)||alert("Failed to fire events"));
        this.resetAll(data.result);
        NotificationManager.success('Updated', `Successfully updated deal`, 1500);
      })
      .catch(err => {
        console.error(err);
        NotificationManager.error('Error', 'Server failed to update Deal, please try again later and send the page log to the tech team', 6000);
      });
    }
  }

  resetChanges(type) {
    if (type === "pricing") {
      this.setState({
        updatePriceValues: {
          initial_price: null,
          price: null,
          payment_status: null,
          number_pieces: null,
        }
      });
    }
  }

  changeRequestedPayment(newValue, previousValue, processedValue) {
    const { deal } = this.state;
    if (typeof deal.price !== "number") return NotificationManager.error("Please add final price before requesting any payment", "Missing price", 6000);
    if (deal.payment_method === "wire") NotificationManager.info("Wire are currently not supported: Wire transactions are bypassed.", "Transaction bypassed", 6000);
    if (newValue === previousValue) return;

    let amountTransactionProcessed = 0;
    if ((deal.payment_transactions || []).filter((t) => t.status === "processed").length === 0 && deal.payment_status === 50) {
      // Specific case usefull in transition process with previous payment out of Oklos
      amountTransactionProcessed = deal.payment_status * deal.price;
    } else {
      amountTransactionProcessed = (deal.payment_transactions || []).reduce(
        (total, transaction) => (transaction.status === "processed" ? total + transaction.amount : total),
        0
      );
    }
    const finalPriceInCents = Math.round(deal.price * 100);
    const amount = Math.min(
      Math.round((newValue * finalPriceInCents) / 100) - amountTransactionProcessed,
      finalPriceInCents - amountTransactionProcessed
    );
    if (amount < 0) return NotificationManager.error("Negative amount! Please send a bug report.", "?", 3000);;

    const pendingTransaction = (deal.payment_transactions || []).find((transaction) => ["requested", "processing"].includes(transaction.status));
    if (pendingTransaction) {
      if (pendingTransaction.status === "processing") {
        NotificationManager.error("Can't change a requested payment when payment is being processed", "Action not allowed", 3000);
      } else if (pendingTransaction.status === "requested") {
        // reverted = delete requested transaction
        if (newValue === processedValue) {
          this.props.deleteTransaction(deal.id_influencer, deal._id, pendingTransaction._id);
        } else {
          this.props.updateRequestedPaymentUpdate(deal.id_influencer, deal._id, pendingTransaction._id, amount);
        }
      }
      return;
    }
    this.props.newRequestedPayment(deal.id_influencer, deal._id, amount, deal.payment_method);
    return;
  }

  updateDateSign() {
    const { deal } = this.state;

    const updatedDates = {
      date_sign: formatISO(new Date())
    };

    axios.put(`/api/v1/deals/${deal._id}`, updatedDates)
      .then((data) => {
        if (data.status === 200) {
          this.onDealUpdated({ "date_sign": data.data.result.date_sign });
          NotificationManager.success('Updated', `Successfully updated deal`, 1500);

          axios.post("/api/v1/event", {
            type: "UPDATE_DATE_OUTREACH_DEAL",
            user: this.props.user.email,
            sample: { object: deal._id },
          }).catch((err) => {
            console.error(err); 
            alert("Failed to fire event UPDATE_DATE_OUTREACH_DEAL");
          });
        }
      })
      .catch(err => {
        console.error(err);
        NotificationManager.error(
          'Error', 
          'Server failed to update Deal, please try again later and send the page log to the tech team', 
          6000
        );
      });
  }

  // update the deal with incoming data
  updateDeal(data) {
    const updatedKeys = Object.keys(data).join(", ");
    return axios
      .put(`/api/v1/deals/${this.props.deal._id}`, data)
      .then(({ data }) => {
        if (data.status === "ok") {
          this.onDealUpdated(data.result);
          NotificationManager.success(
            "Updated", 
            `Successfully updated deal ${updatedKeys}`, 
            1500
          );
        }
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Error", 
          "Server failed to update deal, please try again later and send the page log to the tech team", 
          6000
        );
      });
  }

  onDealUpdated(data) {
    // update the influencer deal state in Outreach
    this.props.updateInfluencerDeal(this.props.deal._id, this.props.influencer._id, data);
  }

  toggleDeal() {
    let params = new URLSearchParams(window.location.search);
    if (!this.state.isOpen) {
      // add dealId to URL
      params.append("dealId", this.state.deal._id);
    } else {
      // remove dealId from URL
      const allParams = [...params.entries()].filter(([key, value]) => key !== "dealId" || value !== this.state.deal._id);
      params = new URLSearchParams(allParams);
    }
    window.history.pushState({}, "", `${window.location.pathname}?${params}`);
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    let {
      isOpen,
      deal,
      isEditingPricing,
      updatePriceValues,
      areTransactionsLoaded,
    } = this.state;
    const { account_managers, user } = this.props;

    let amName = "";
    if (deal.am && account_managers) {
      amName = account_managers.find(d => { return (d.id === deal.am) });
      amName = amName && amName["title"];
    }

    // Payment render calculation
    const finalPriceInCents = Math.round(deal.price * 100);
    let percentage_transactions_processed = deal.payment_status;
    let amountAllTransactions = (deal.payment_transactions || []).reduce((total, transaction) => total + (transaction.amount || 0), 0);
    let percentage_all_transactions = deal?.payment_transactions?.length
      ? (amountAllTransactions / finalPriceInCents) * 100
      : 0;
    if ((deal.payment_transactions || []).filter((t) => t.status === "processed").length === 0 && deal.payment_status === 50) {
        // Specific case usefull in transition process with previous payment out of Oklos
        percentage_all_transactions += deal.payment_status;
    }
    const lastTransactionStatus = deal?.payment_transactions?.length ? deal.payment_transactions[deal.payment_transactions.length - 1].status : "";
    let paymentOptionsValues = [0, 25, 50, 75, 100].filter((percent) => percentage_transactions_processed < percent + 5);
    if (percentage_transactions_processed < paymentOptionsValues[0] - 5) {
      paymentOptionsValues = [percentage_transactions_processed, ...paymentOptionsValues];
    }
    percentage_all_transactions = paymentOptionsValues.find((percent) => (percentage_all_transactions > percent - 5) && (percentage_all_transactions < percent + 5)); // take closest value in the list
    if (percentage_all_transactions === undefined) {
      percentage_all_transactions = paymentOptionsValues[0];
    }
    const paymentOptions = paymentOptionsValues.map((percent) => {
      return {
        "key": `${percent}`,
        "value": (
          <div
            className={`outreach_influencer_payment_dropdown_options ${
              percent <= percentage_all_transactions
                ? `outreach_influencer_payment_dropdown_options--${percent < percentage_transactions_processed ? "processed" : lastTransactionStatus}`
                : ""
            }`}>
            {percent}%<span className="material-icons">circle</span>
          </div>
        ),
      };
    });

    const hasOngoingTransactions = (deal.payment_transactions || []).some((transaction) =>
      ["requested", "processing"].includes(transaction.status)
    );

    // The deal final price can be edited by AMs if the deal is not yet signed
    // and by super users for any status
    const canEditFinalPrice =
      isEditingPricing && (!hasOngoingTransactions || this.props.user.role === "super-user");

    return (
      (deal) ? (
        <div className="outreach_influencer_deal" key={`deal_${deal._id}`} onClick={(e) =>
          e.metaKey ? navigator.clipboard.writeText(deal._id).then(() => NotificationManager.info("Deal ID copied!")) : null
        }>
          <CurrentDealName
            isOpen={isOpen}
            amName={amName}
            deal={deal}
            initialDealName={deal.name || `Deal n*${this.props._key + 1}`}
            onDealUpdated={this.onDealUpdated}
            toggleDeal={this.toggleDeal}
          />

          {(isOpen) ? (
            <>
              <div className="outreach_influencer_deal_l">
                {/* STATUS */}
                <CurrentDealStatus
                  isSuperUser={this.props.user.role === "super-user"}
                  deal={deal}
                  showModal={this.props.showModal}
                  updateDeal={this.updateDeal}
                  updateStatusInf={this.props.updateStatusInf}
                  hasOngoingTransactions={hasOngoingTransactions}
                />

                {/* START CAMPAIGN */}
                <div className="outreach_influencer_deal_campaign">
                  {/* STATUS */}
                  <ALDropdown
                    options={[{ id: "", name: "Choose campaign" }, { id: "NONE", name: "Without campaign" }, ...CAMPAIGNS]}
                    useValue={'name'}
                    useKey={'id'}
                    value={deal.campaign_id || ""}
                    onChange={(newCampaignID) => {
                      if (newCampaignID === deal.campaign_id) return;
                      if (["to_ship"].includes(deal.status) && this.props.user.role !== "super-user") {
                        NotificationManager.warning("You can't change campaign with the current deal status", "Operation not allowed");
                        return;
                      };
                      if (["shipped", "in_transit", "shipment_received"].includes(deal.status)) {
                        this.props.showModal("ModalConfirmation", {
                          title: "Stooop! Read this first!",
                          text: "This deal has been shipped! Are you sure you want to change the campaign? This will have multiple effects: redirect, brief...",
                        }).then((res) => {
                          if (res === "confirm") {
                            axios
                              .get("/api/v1/influencer_deal_shipping", { params: { dealId: deal._id } })
                              .then(async ({ data: previousShippingsData }) => {
                                const previousShippings = previousShippingsData.result;
                                const redirectURL = previousShippings[0].redirect;
                                const briefURL = previousShippings[0].brief;
                                const {
                                  data: { result: redirectData },
                                } = await axios.get("/api/v1/redirect", { params: { "from": redirectURL.replace("/", "") } });
                                const {
                                  data: { result: briefRedirectData },
                                } = await axios.get("/api/v1/content/redirect", { params: { "from": briefURL.split("content.analuisa.com/")[1] } });
                                let newRedirectFullURL = new URL(redirectData.to);
                                let utmCampaign = newRedirectFullURL.searchParams.get("utm_campaign");
                                let campaignName = utmCampaign.includes("-") ? utmCampaign.split("-")[1] : utmCampaign;
                                newRedirectFullURL.searchParams.set(
                                  "utm_campaign",
                                  newCampaignID === "NONE" ? campaignName : `${newCampaignID}-${campaignName}`
                                );
                                await axios.put(`/api/v1/redirect/${redirectData._id}`, { "to": newRedirectFullURL.toString() });
                                let newBriefRedirectFullURL = new URL(briefRedirectData.to);
                                newBriefRedirectFullURL.searchParams.set("type", BRIEF_TYPES_BY_CAMPAIGN[newCampaignID]);
                                await axios.put(`/api/v1/content/redirect/${briefRedirectData._id}`, { "to": newBriefRedirectFullURL.toString() });
                                await this.updateDeal({ campaign_id: newCampaignID });
                                await axios.post("/api/v1/event", {
                                  type: "UPDATE_CAMPAIGN_DEAL",
                                  user: this.props.user.email,
                                  sample: {
                                    object: deal._id,
                                    from: { "status": deal.campaign_id },
                                    to: { "status": newCampaignID },
                                  },
                                });
                              });
                          }
                        });
                        return;
                      };
                      this.updateDeal({ campaign_id: newCampaignID }).then(() => {
                        axios
                        .post("/api/v1/event", {
                          type: "UPDATE_CAMPAIGN_DEAL",
                          user: this.props.user.email,
                          sample: {
                            object: deal._id,
                            from: { "status": deal.campaign_id },
                            to: { "status": newCampaignID },
                          },
                        })
                      });
                    }}
                  />
                </div>
                {/* END CAMPAIGN */}

                {/* START DATES (followup, outreach, ...) */}
                <CurrentDealDates
                  user={this.props.user}
                  deal={deal}
                  onDealUpdated={this.onDealUpdated}
                />

                {/* START PRICING */}
                <div className={`outreach_influencer_deal_pricing ${isEditingPricing ? "outreach_influencer_deal_pricing--edit" : ""}`}>
                  {!SIGNED_DEAL_STATUSES.includes(deal.status) || ["super-user", "billing-user"].includes(user.role) ? <div className="outreach_influencer_deal_dates_edit" onClick={() => {
                    if (this.state.isEditingPricing) {
                      this.resetChanges("pricing");
                    }
                    this.setState({ isEditingPricing: !this.state.isEditingPricing })
                  
                  } }>
                    {(isEditingPricing) ? (
                      <span className="material-icons">close</span>
                    ) : (
                      <span className="material-icons">create</span>
                    )}
                  </div> : null}

                  {(isEditingPricing) ? (
                    <div className="outreach_influencer_deal_update_button" onClick={() => this.updateDealInfo("pricing")}>
                      UPDATE <span className="material-icons">add</span>
                    </div>
                  ) : (null)}
                  <div className="outreach_influencer_deal_dates_date outreach_influencer_deal_dates_date--payment-methods">
                      <ALDropdown
                        options={[{ "key": "", "value": "Payment choice" }, { "key": "paypal", "value": "Paypal" }, { "key": "wire", "value": "Wire" }]}
                        value={deal.payment_method}
                        onChange={(key) => {
                          if (deal.payment_method === key) return;
                          if (key === "paypal" && !this.props.influencer.paypal_email) {
                            return this.props.showModal("ModalWarn", {
                              text: "Fill out the PayPal email field above in order to select PayPal.",
                              noActions: true,
                            });
                          }

                            // return NotificationManager.error("Please set a PayPal email before setting payment method", "No PayPal email set", 6000);
                          this.updateDeal({ payment_method: key });
                        }}
                      />
                  </div>
                  <div className="outreach_influencer_deal_dates_date outreach_influencer_deal_dates_date--payment-status">
                    {areTransactionsLoaded ?
                      <ALDropdown
                        options={paymentOptions}
                        value={`${percentage_all_transactions}`}
                        onChange={(key) => {
                          const value = parseInt(key);
                          if (value < percentage_transactions_processed) return;
                          if (!["to_ship", "shipped", "in_transit", "shipment_received", "published"].includes(deal.status)) {
                            return this.props.showModal("ModalWarn", {
                              text: "Payment status can't be updated with the current deal status",
                              noActions: true,
                            });
                          }
                          if (!deal.price) {
                            return this.props.showModal("ModalWarn", {
                              text: "Nothing to be paid. Check out final price value.",
                              noActions: true,
                            });
                          }
                          if (!deal.payment_method) {
                            return this.props.showModal("ModalWarn", {
                              text: "Fill out the payment method in order to request a payment",
                              noActions: true,
                            });
                          }
                          if (deal.payment_method === "paypal" && !this.props.influencer.paypal_email)
                            return NotificationManager.error("Please set a PayPal email before creating a transaction", "No PayPal email set", 6000);
                          this.changeRequestedPayment(value, percentage_all_transactions, percentage_transactions_processed);
                        }}
                        disableOptionsFiltering
                      />
                    : null}
                  </div>
                  <div className="outreach_influencer_deal_dates_date">
                    <p className="outreach_influencer_deal_dates_date_title">Initial Price</p>
                    {(isEditingPricing) ? (
                      <input
                        className="oi_influencer_edit_input--number"
                        data-key="initial_price"
                        type="number"
                        onChange={(e) => { this.handleChangeInput("pricing", e.target.getAttribute('data-key'), e.target.value); }}
                        value={
                          (updatePriceValues.initial_price) ? (
                            updatePriceValues.initial_price) : (
                              (deal.initial_price || deal.initial_price === 0) ? (
                                deal.initial_price
                              ) : ('')
                            )
                        }
                      />
                    ) : (
                      <p className="outreach_influencer_deal_dates_date_value">{(deal.initial_price || deal.initial_price === 0) ? (deal.initial_price) : <WarningIcon height={30} width={30} />}</p>
                    )}
                  </div>
                  <div className="outreach_influencer_deal_dates_date">
                    <p className="outreach_influencer_deal_dates_date_title">Final Price</p>

                    {canEditFinalPrice ? (
                      <input
                        className="oi_influencer_edit_input--number"
                        type="number"
                        data-key="price"
                        onChange={(e) => { this.handleChangeInput("pricing", e.target.getAttribute('data-key'), e.target.value); }}
                        value={
                          (updatePriceValues.price) ? (
                            updatePriceValues.price) : (
                              (deal.price || deal.price === 0) ? (
                                deal.price
                              ) : ('')
                            )
                        }
                      />
                    ) : (
                      <p className="outreach_influencer_deal_dates_date_value">{(deal.price || deal.price === 0) ? (deal.price) : <WarningIcon height={30} width={30} />}</p>
                    )}
                  </div>
                  <div className="outreach_influencer_deal_dates_date">
                    <p className="outreach_influencer_deal_dates_date_title">Number of pieces</p>
                    {(isEditingPricing) ? (
                      <input
                        className="oi_influencer_edit_input--number"
                        type="number"
                        data-key="number_pieces"
                        min="0"
                        max="15"
                        onChange={(e) => { this.handleChangeInput("pricing", e.target.getAttribute('data-key'), e.target.value); }}
                        value={
                          (updatePriceValues.number_pieces) ? (
                            updatePriceValues.number_pieces) : (
                              (deal.number_pieces || deal.number_pieces === 0) ? (
                                deal.number_pieces
                              ) : ('')
                            )
                        }
                      />
                    ) : (
                      <p className="outreach_influencer_deal_dates_date_value">{(deal.number_pieces || deal.number_pieces === 0) ? (deal.number_pieces) : <WarningIcon height={30} width={30} />}</p>
                    )}
                  </div>
                  <div className="outreach_influencer_deal_dates_date outreach_influencer_deal_dates_date--transaction-list">
                    <p className="outreach_influencer_deal_dates_date_title">Transactions</p>
                    {!deal?.payment_transactions?.length ? <div className="outreach_influencer_deal_dates_date_transaction outreach_influencer_deal_dates_date_transaction--empty" key="no-transaction">
                      <div>No transactions</div>
                    </div> : null}
                    {[...(deal.payment_transactions || [])].reverse().map((transaction, idx) => <div className="outreach_influencer_deal_dates_date_transaction" key={idx}>
                      {!transaction._id ? <ALLoading key={idx} alt /> :
                        <React.Fragment key={transaction._id}>
                          {transaction.status === "requested" || ["super-user", "billing-user"].includes(user.role)  ?
                            <div className="outreach_influencer_deal_dates_date_transaction_delete" onClick={() => this.props.deleteTransaction(this.props.influencer._id, deal._id, transaction._id)}>
                              <span className="material-icons">clear</span>
                            </div>
                          : null}
                          <div className="outreach_influencer_deal_dates_date_transaction_num">#{deal.payment_transactions.length - idx}</div>
                          <div className={`outreach_influencer_deal_dates_date_transaction_status outreach_influencer_deal_dates_date_transaction_status--${transaction.status}`} title={transaction.status}><span className="material-icons">circle</span></div>
                          <div className="outreach_influencer_deal_dates_date_transaction_date">{formatISO(new Date(transaction.creation_date), { representation: 'date' })}</div>
                          <div className="outreach_influencer_deal_dates_date_transaction_method">{transaction.method === "paypal" ? <img src={paypal_logo} width="17px" alt="paypal"/> : "W"}</div>
                          <div className="outreach_influencer_deal_dates_date_transaction_value">{transaction.currency && transaction.currency !== "USD" ? "?" : "$"}{(transaction.amount / 100).toFixed(2)}</div>
                          <div className="outreach_influencer_deal_dates_date_transaction_id" title="Batch #">{transaction.batch_id ? `#${transaction.batch_id}` : ""}</div>
                        </React.Fragment>
                      }
                    </div>)}
                  </div>
                </div>
                {/* END PRICING */}

                {/* START EDIT AM */}
                <div className="outreach_influencer_deal_am">
                  {(account_managers) ? (
                    <ALDropdown
                      options={account_managers.find(d => { return (d.id === deal.am) }) ? account_managers : [...account_managers, {'title': deal.am, 'id': deal.am}]}
                      useValue={'title'}
                      useKey={'id'}
                      value={deal.am}
                      selectedKey={deal.am}
                      onChange={(email_am) => {
                        if (email_am === deal.am) return;

                        axios.put(`/api/v1/deals/${deal._id}`, {"am": email_am})
                        .then(({data}) => {
                          NotificationManager.success('Updated', `Successfully updated deal AM to '${email_am}'`, 1500);
                          this.setState({ deal: data.result });
                        })
                        .catch(err => {
                          console.error(err);
                          NotificationManager.error('Error', 'Server failed to update status, please try again later and send the page log to the tech team', 6000);
                        });
                      }}
                    />
                  ) : (
                    <>
                      <Loading />
                      <p>Loading account managers...</p>
                    </>
                  )}
                </div>

                {/* Delete deal */}
                {!["to_ship", "shipped", "in_transit", "shipment_received"].includes(deal.status) ? <div className="outreach_influencer_deal_delete" onClick={() => this.props.showModal("ModalConfirmation", {
                  text: "Are you sure you want to delete this deal?",
                }).then((res) => {
                  if (res === "confirm") {
                    this.props.deleteInfluencerDeal(deal._id);
                  }
                })}>
                  <div className="outreach_influencer_deal_delete__button">DELETE DEAL</div>
                </div> : null}
              </div>

              <div className="outreach_influencer_deal_r">
                {/* START SHIPPING INFO */}
                {["done", "to_ship", "shipped", "in_transit", "shipment_received"].includes(deal.status) && (
                  <CurrentDealShipping
                    user={this.props.user}
                    deal={deal}
                    influencer={this.props.influencer}
                    updateDeal={this.updateDeal}
                    updateDateSign={this.updateDateSign}
                    showModal={this.props.showModal}
                  />
                )}

                {/* START NOTES */}
                <DealNotes
                  deal={deal}
                  user={this.props.user}
                  onDealUpdated={this.onDealUpdated}
                />

                {/* START CONTENT NEGOCIATE */}
                <CurrentDealContent
                  deal={deal}
                  user={this.props.user}
                  onDealUpdated={this.onDealUpdated}
                />

                {/* DEAL INFO */}
                <div className="deal_identifier">
                  <span className="deal_identifier_title">Deal Unique Identifier:</span>
                  <span className="deal_identifier_content">{deal?._id}</span>
                </div>
              </div>
            </>
          ) : (null)}
        </div>
      ) : (
        <div key={`deal_${this.props._key}`}>NO CURRENT DEAL</div>
      )
    );
  }
}

export default CurrentDeal;