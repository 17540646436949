import React from "react";
import axios from "axios";
import { ALLoading } from "./ALComponents";
import HeaderComponent from "./HeaderComponent";
import NotificationManager from "./al_components/notification/NotificationManager";
import "./Redirect.css";
import { CAMPAIGNS } from "../dictionaries";

const FROM_BASE = 'shop.analuisa.com';
const SOURCE_DB = 'db';
const SOURCE_SHOPIFY = 'shopify';

class Redirect extends React.Component {
  constructor(props) {
    super(props);
    this.campain_types = [{ name: "None", id: "", prefix: "" }, ...CAMPAIGNS.map((c) => ({ name: c.name, id: c.id, prefix: `${c.id}-` }))];
    this.state = this.initialState();
    this.searchRedirect = this.searchRedirect.bind(this);
    this.searchRedirectInDb = this.searchRedirectInDb.bind(this);
    this.updateValues = this.updateValues.bind(this);
    this.updateDBRedirect = this.updateDBRedirect.bind(this);
    this.createRedirect = this.createRedirect.bind(this);
    this.initiateNewRedirect = this.initiateNewRedirect.bind(this);
  }

  initialState() {
    return {
      search: {
        isLoading: false,
      },
      searchValue: "",
      data: {
        id: null,
        from: null,
        to: null,
      },
      source: "", // "db" for shop.analuisa.com or "shopify" for www.analuisa.com
      updating: {
        inProgress: false,
        statusMessage: "",
      },
      showAllUTM: false,
      isNewRedirect: false,
      isFormVisible: false
    };
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (var i = 0; i < a.length; ++i) {
      if (typeof a[i] === "object" && typeof b[i] === "object") {
        if (!Object.keys(a[i]).every((key) => a[i][key] === b[i][key])) return false;
      } else {
        if (a[i] !== b[i]) return false;
      }
    }
    return true;
  }

  extractUTMFromURL(url) {
    let utms = [];
    let _utms = url.search.replace("?", "").split("&");
    _utms.map((utm) => {
      let u = utm.split("=");
      utms.push({
        [u[0]]: u[1],
      });
    });
    return utms;
  }

  async searchRedirect() {
    const searchValue = this.state.searchValue.trim();
    if (!searchValue) {
      NotificationManager.warning("", "Please add a value in the input field", 3000);
      return;
    }

    this.setState({
      "searchValue": searchValue,
      "search": {
        "isLoading": true,
      },
    });

    // first search for the redirect in the db
    const redirectFoundInDb = await this.searchRedirectInDb(searchValue);
    if (redirectFoundInDb) {
      return;
    }
    
    // if it's not present in the db, search in shopify
    const r = await axios.get("/api/v1/redirect_shopify", { params: { "search": `/${searchValue}` } });
    if (r.status == 200) {
      let url = null;
      if (r.data.redirect.length === 0) {
        this.setState({
          "search": {
            "isLoading": false,
            "status": "empty",
          },
        });
      } else {
        if (r.data.redirect.target[0] === "/") {
          url = new URL("https://wwww.analuisa.com" + r.data.redirect.target);
        } else {
          url = new URL(r.data.redirect.target);
        }
        const utms = this.extractUTMFromURL(url);

        return this.setState({
          "data": {
            "id": r.data.redirect.id,
            "to": r.data.redirect.target,
            "to_backup": r.data.redirect.target,
            "from": r.data.redirect.path,
            "utms": utms,
            "utm_backup": [...utms],
          },
          "source": SOURCE_SHOPIFY,
          "search": {
            "isLoading": false,
          },
          "isFormVisible": true,
        });
      }
    }
  }

  async searchRedirectInDb(searchValue) {
    const responseRedirect = await axios.get("/api/v1/redirect", { params: { "from": searchValue } });
    
    if (responseRedirect?.data?.result) {
      const redirectObject = responseRedirect.data.result;
      const url = new URL(redirectObject.to);
      const utms = this.extractUTMFromURL(url);
      this.setState({
        "campain_type": this.campain_types.reverse().find((ct) => redirectObject.from.startsWith(ct.prefix))?.id || "",
        "data": {
          "id": redirectObject._id,
          "to": redirectObject.to,
          "to_backup": redirectObject.to,
          "from": redirectObject.from,
          "from_backup": redirectObject.from,
          "utms": utms,
          "utm_backup": [...utms],
          "creation_date": new Date(redirectObject.creation_date),
        },
        "source": SOURCE_DB,
        "search": {
          "isLoading": false,
        },
        "isFormVisible": true,
      });

      return true;
    }
    
    return false;
  }

  updateValues(type, value) {
    // type => from | to | id | utms
    if (value) value = value.toLowerCase();

    // remove spaces from the input strings
    value = value.replace(/\s/g, '');

    if (type === "_FROM") {
      this.setState((prevState) => ({
        "data": {
          ...prevState.data,
          "from": value,
        },
      }));
    } else if (type === '_TO') {
      try {
        const url = new URL(value);
        let utms = this.extractUTMFromURL(url);

        this.setState((prevState) => ({
          "data": {
            ...prevState.data,
            "to": value,
            "utms": utms,
          },
        }));
      } catch(err) {
        // allow the user to edit the entire "to" url, 
        // but don't try to extract utms when it's not valid
        this.setState((prevState) => ({
          "data": {
            ...prevState.data,
            "to": value,
          },
        }));
      }
    } else {
      this.setState((state) => {
        let _copyData = { ...state.data };
        const idx = state.data.utms.findIndex((utm) => Object.keys(utm)[0] === type);
        if (!~idx) {
          console.error(type, "not found in utms");
          return;
        }
        _copyData["utms"][idx] = { [type]: value };

        // also update the "to" value with the new utms
        const url = new URL(this.state.data.to);
        url.searchParams.set(type, value);
        _copyData["to"] = url.href;
        
        return {
          data: _copyData,
        };
      });
    }
  }

  async updateDBRedirect() {
    const { data } = this.state;
    const redirectObjectID = data.id;
    const redirectFrom = data.from_backup;
    const fullRedirectFrom = `https://${FROM_BASE}/${redirectFrom}`;
    const fullNewRedirectFrom = `https://${FROM_BASE}/${data.from}`;
    const previousRedirectTo = data.to_backup;
    const newDestinationURL = data.to;
    let totalSteps = 1;

    this.setState(({ updating }) => ({ updating: { ...updating, inProgress: true } }));
    if (redirectFrom !== data.from) {
      totalSteps = 5;
      this.setState(({ updating }) => ({ updating: { ...updating, statusMessage: `Checking unicity of the redirect from...` } }));
      const {
        data: { result: newRedirectExists },
      } = await axios.get("/api/v1/redirect", { params: { "from": `${data.from}` } });
      if (newRedirectExists) {
        NotificationManager.error("Already Exist, please change redirect from", "Failed", 1500);
        this.setState({ updating: { inProgress: false, statusMessage: "" } });
        return;
      }
      this.setState(({ updating }) => ({ updating: { ...updating, statusMessage: `1/${totalSteps} Retrieving redirects of shippings...` } }));
      const shippingResponse = await axios.get("/api/v1/influencer_deal_shipping", { params: { "redirect": `/${redirectFrom}` } });
      const shippings = shippingResponse.data.result;
      this.setState(({ updating }) => ({
        updating: { ...updating, statusMessage: `2/${totalSteps} Updating redirect and brief of ${shippings.length} shippings object...` },
      }));
      let updatedContentList = [];
      for (const shipping of shippings) {
        const shippingDate = new Date(shipping.date);
        // Do not modify old format redirect deals
        console.log("shippingDate", shippingDate, "data.creation_date", data.creation_date);
        if (shippingDate < data.creation_date) {
          console.log("Skipped because too old compared to redirect creation date", shipping);
          continue;
        }
        // 'brief' 'https://content.analuisa.com/-V3L58T-5I22JG'
        const currentHash = shipping.brief.replace("https://content.analuisa.com/", "");
        if (!updatedContentList.includes(currentHash)) {
          const contentRedirectResponse = await axios.get("/api/v1/content/redirect", { params: { "from": currentHash } });
          const contentRedirect = contentRedirectResponse.data.result;
          const paramsString = contentRedirect.to.replace("https://content.analuisa.com/", ""); // ?type=classic&name=Jessica&redirect=/jessicah&code=JESSICAH10&platform=youtube
          const newParamsString = paramsString.replace(/^(.*)(redirect=\/[^&]+)(&.*)$/, `$1redirect=/${data.from}$3`);
          await axios.put(`/api/v1/content/redirect/${contentRedirect._id}`, {
            "to": `https://content.analuisa.com/${newParamsString}`,
          });
          updatedContentList.push(currentHash);
        }
        await axios.put(`/api/v1/influencer_deal_shipping/${shipping._id}`, {
          "redirect": `/${data.from}`,
        });
      }
      this.setState(({ updating }) => ({ updating: { ...updating, statusMessage: `3/${totalSteps} Retrieving redirects of deals...` } }));
      const dealsResponse = await axios.get("/api/v1/deals", { params: { "content.link_analuisa": fullRedirectFrom } });
      const deals = dealsResponse.data.result;
      this.setState(({ updating }) => ({
        updating: { ...updating, statusMessage: `4/${totalSteps} Updating redirects in content of ${deals.length} deals...` },
      }));
      await Promise.all(
        deals.map((deal) => {
          const updateObject = {
            "content": deal.content.map((content) => {
              if (content.link_analuisa === fullRedirectFrom) {
                return { ...content, link_analuisa: fullNewRedirectFrom };
              }
              return content;
            }),
          };
          return axios.put(`/api/v1/deals/${deal._id}`, updateObject);
        })
      );
    }
    this.setState(({ updating }) => ({ updating: { ...updating, statusMessage: `${totalSteps}/${totalSteps} Updating redirect object...` } }));
    await axios.put(`/api/v1/redirect/${redirectObjectID}`, { "from": data.from, "to": newDestinationURL });
    await axios
      .post("/api/v1/event", {
        type: "EDIT_REDIRECT",
        user: this.props.user.email,
        sample: {
          object: redirectObjectID,
          from: { "from": fullRedirectFrom, "to": previousRedirectTo },
          to: { "from": fullNewRedirectFrom, "to": newDestinationURL },
        },
      })
      .catch((err) => console.error(err, "EDIT_REDIRECT"));
    NotificationManager.success("Successfully updated redirect!", "", 1500);
    this.setState(this.initialState());
  }

  initiateNewRedirect() {
    this.setState({      
      data: {
        from: this.state.searchValue,
        // initiate the "to" url with these utm params so they don't go away when starting to edit
        to: "https://www.analuisa.com?utm_source=&utm_medium=&utm_campaign=",
        utms: [
          {utm_source: ''},
          {utm_medium: ''},
          {utm_campaign: ''},
        ],
      },
      source: SOURCE_DB,
      showAllUTM: true,
      isFormVisible: true,
      isNewRedirect: true,
    })
  }

  async createRedirect() {
    try {
      await axios.post("/api/v1/redirect", {
        // "from" uses the value after "/" so it's necessary to prepend one
        from: `/${this.state.data.from}`,
        to: this.state.data.to
      });

      this.setState({isNewRedirect: false});
      this.searchRedirectInDb(this.state.data.from);
      NotificationManager.success(`Successfully created redirect ${this.state.data.from}`, "", 1500);
    } catch(err) {
      console.error(err);
      NotificationManager.error("Error", "There was an issue adding this redirect, please check the data and try again " + err, 4000);
    }
  }

  render() {
    const { search, searchValue, data, source, updating, showAllUTM, isNewRedirect, isFormVisible } = this.state;
    let utmsArray = data.utms;
    const hasChanges = !this.arraysEqual(data.utms, data.utm_backup) || data.from !== searchValue;
    if (data && data.utms && source === SOURCE_DB && !showAllUTM) {
      utmsArray = data.utms.filter((utmObject) => ["utm_campaign", "discount_code"].includes(Object.keys(utmObject)[0]));
    }

    const selectedCampaign = this.campain_types.find(campaign => {
      return !!utmsArray?.find(utm => {
        return utm.utm_campaign && campaign.id !== '' && utm.utm_campaign.startsWith(campaign.id);
      });
    });
    
    return (
      <div className="redirect">
        <HeaderComponent title="Edit URL Redirect" to="/a/dashboard" toTitle="Home" />
        {updating["inProgress"] && (
          <div className="redirect_loader">
            <ALLoading text={updating["statusMessage"]} />
          </div>
        )}
        <div className={`redirect_container ${updating["inProgress"] ? "redirect_container--loading" : ""}`}>
          <div className="redirect_container_search">
            <div className="redirect_container_search__domain">
              {!source || source === SOURCE_DB ? `https://${FROM_BASE}/` : "https://www.analuisa.com/"}
            </div>
            <input
              placeholder="Search Redirect"
              className="redirect_container_search--input"
              type="text"
              value={searchValue}
              onKeyDown={(e) => e.key === "Enter" && this.searchRedirect()}
              onChange={(e) => {
                const formatValue = e.target.value.split("/");
                let newValue = formatValue[formatValue.length - 1];
                this.setState({ ...this.initialState(), "searchValue": newValue });
              }}
            />
            <span className="redirect_container_search--icon material-icons" onClick={this.searchRedirect}>
              search
            </span>
          </div>

          {search.isLoading && (
            <ALLoading text={`We are loading the URL (${this.state.searchValue}), please wait...`} />
          )}

          {search.status && search.status === "empty" && !isFormVisible && (
            <>
              <p className="redirect_container_empty">No redirect found on Shopify for this URL ({this.state.searchValue})</p>
              <button 
                type="button"
                className="al_button contained redirect_container_empty--create-new"
                onClick={this.initiateNewRedirect}
              >
                Add this redirect
              </button>
            </>
          )}

          {isFormVisible && (
            <div className="redirect_container_result">
              <div className="redirect_container_result_from">
                <p className="redirect_container_result_from--title">
                  {isNewRedirect ? 'Create redirect from:' : 'Edit redirect from:'}
                </p>
                {source === SOURCE_DB ? (
                  <div className="redirect_container_result_from--value">
                    <div className="redirect_container_result_from--value-domain">https://shop.analuisa.com/</div>
                    <input
                      className="redirect_container_result_from--value-input"
                      type="text"
                      value={data && data.from ? data.from : ''}
                      onChange={(e) => {
                        this.updateValues("_FROM", e.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <p className="redirect_container_result_from--value">https://www.analuisa.com{data && data.from ? data.from : null}</p>
                )}
              </div>
              <div className="redirect_container_result_to">
                <p className="redirect_container_result_to--title">
                  {isNewRedirect ? 'Create redirect to:' : 'Edit redirect to:'}
                </p>
                {source === SOURCE_DB ? (
                  <div className="redirect_container_result_to--value">
                    <input
                      className="redirect_container_result_to--value-input"
                      type="text"
                      value={data && data.to ? data.to : ''}
                      onChange={(e) => {
                        this.updateValues("_TO", e.target.value);
                      }}
                    />
                  </div>
                  ) : (
                    <p className="redirect_container_result_to--value">{data && data.to ? data.to : ''}</p>
                  )}
              </div>
              {source === SOURCE_DB ? (
                data.utms && data.utms.length > 0 ? (
                  <div className="redirect_container_result_utms">
                    {utmsArray.map((utm, i) => {
                      let keyUTM = Object.keys(utm)[0];
                      let valueUTM = utm[keyUTM];
                      return (
                        <React.Fragment key={i}>
                          <div key={`utm_${i}`} className="redirect_container_result_utms_utm">
                            <p className="redirect_container_result_utms_utm--key">{keyUTM}=</p>
                            <input
                              disabled={keyUTM === "discount_code" || keyUTM === "discount_t" || keyUTM === "discount_a" ? true : false}
                              className="redirect_container_result_utms_utm--value"
                              onChange={(e) => {
                                this.updateValues(keyUTM, e.target.value);
                              }}
                              type="text"
                              value={valueUTM || ''}
                              data-key={keyUTM}
                            />
                            {keyUTM === "utm_campaign" ? (
                              <div className="discount_container__content__code_line">
                                <div className="clean_input_container">
                                  <label className="clean_input_container__label">General campain</label>
                                  <select
                                    id="general_campain"
                                    className="clean_select_for_form"
                                    value={selectedCampaign ? selectedCampaign.id : ''}
                                    onChange={(e) => {
                                      let campain_prefix = this.campain_types.find((campain) => campain.id === e.target.value)["prefix"];
                                      let _copyData = this.state.data;
                                      let idxUtmCampaign = _copyData["utms"].findIndex((e) => Object.keys(e)[0] === "utm_campaign");
                                      let newCampaign = null;
                                      if (_copyData["utms"][idxUtmCampaign]["utm_campaign"].includes("-")) {
                                        newCampaign = _copyData["utms"][idxUtmCampaign]["utm_campaign"].split("-");
                                        newCampaign[0] = campain_prefix;
                                        newCampaign = newCampaign.join("");
                                      } else {
                                        newCampaign = campain_prefix + _copyData["utms"][idxUtmCampaign]["utm_campaign"];
                                      }
                                      this.updateValues("utm_campaign", newCampaign);
                                    }}>
                                    {this.campain_types.map((campain) => (
                                      <option key={campain.id} value={campain.id}>
                                        {campain.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <p className="">No params detected!</p>
                )
              ) : null}

              {!showAllUTM && source === SOURCE_DB ? (
                <div className="redirect_container_result_expand" onClick={() => this.setState({ showAllUTM: true })}>
                  <span className="material-icons-outlined">expand_more</span>
                </div>
              ) : null}

              {source !== "shopify" ? (
                <div className="redirect_container_result_update_container">
                  <button
                    disabled={!hasChanges}
                    className={`redirect_container_result_update ${
                      hasChanges
                        ? "redirect_container_result_update--update"
                        : "redirect_container_result_update--disabled"
                    }`}
                    type="text"
                    onClick={isNewRedirect 
                      ? this.createRedirect
                      : this.updateDBRedirect
                    }>
                    {hasChanges
                      ? isNewRedirect ? "CREATE REDIRECT" : "UPDATE REDIRECT" 
                      : "NOTHING TO UPDATE" }
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Redirect;
