import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { ALTable } from "components/ALComponents";
import "./TypeformInformation.css";

const TILabel = ({ title, isHidden, classSpecification, children }) => {
  if (isHidden) return null;
  return (
    <div className={classSpecification ? `ti-label ti-label--${classSpecification}` : "ti-label"}>
      <div className="ti-label__title">{title}</div>
      <div className="ti-label__value">{children}</div>
    </div>
  );
};

const TypeformInformation = ({ typeformResponse }) => {
  return (
    <div className="typeform-information">
      <div className="typeform-information__labels">
        <TILabel title="Email">
          <p>{typeformResponse?.email?.value}</p>
        </TILabel>
        <TILabel title="Email Paypal" isHidden={!typeformResponse?.email_paypal?.value}>
          <p>{typeformResponse?.email_paypal?.value}</p>
        </TILabel>
        <TILabel title="AM">
          <p>{typeformResponse?.am}</p>
        </TILabel>
        <TILabel title="Name">
          <p>
            {typeformResponse?.first_name?.value} {typeformResponse?.last_name?.value}
          </p>
        </TILabel>
        <TILabel title="Phone">
          <p>{typeformResponse?.phone_number?.value}</p>
        </TILabel>
        <TILabel title="Announced publish date" isHidden={!typeformResponse?.publish_date?.value}>
          <p>{typeformResponse?.publish_date?.value}</p>
        </TILabel>
        <TILabel title="Address" classSpecification="large">
          <p>{typeformResponse?.address_line_1?.value}</p>
          <p>{typeformResponse?.address_line_2?.value}</p>
          <p>
            {typeformResponse?.city?.value}, {typeformResponse?.state?.value} {typeformResponse?.zip_code?.value}
          </p>
          <p>{typeformResponse?.country?.value}</p>
        </TILabel>
        <TILabel title="Company" isHidden={!typeformResponse?.company?.value}>
          <p>{typeformResponse?.company?.value}</p>
        </TILabel>
        <TILabel title="Submitted at">
          <p>{format(new Date(typeformResponse?.submitted_at), "Ppp")}</p>
        </TILabel>
        <TILabel title="Ring Size" isHidden={typeformResponse?.ring_size?.value}>
          <p>{typeformResponse?.ring_size?.value}</p>
        </TILabel>
        <TILabel title="Products" classSpecification="large">
          {typeformResponse?.products?.value?.split("\n").map((productLine, idx) => (
            <p key={idx}>{productLine}</p>
          ))}
        </TILabel>
      </div>
      <ALTable
        title={"Other questions"}
        content={typeformResponse?.others?.map((otherQA) => [otherQA.question, otherQA.value])}
      />
    </div>
  );
};

TypeformInformation.propTypes = {
  typeformResponse: PropTypes.object.isRequired,
};

export default TypeformInformation;
