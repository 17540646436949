import React from "react";
import axios from "axios";
// import moment from 'moment';

import "./GiftNotes_v2.css";

import Loading from "./Loading";
import logoAnaLuisaA from "../assets/AnaLuisa-LOGO-A_300x300px.png";

import pdfToPrint from '../assets/Barcodes_to_print.pdf';


/*

function addScript(url) {
    var script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = url;
    document.head.appendChild(script);
}
addScript('https://raw.githack.com/eKoopmans/html2pdf/master/dist/html2pdf.bundle.js');
var opt = {
  margin:       [0,0],
  filename:     'TEST_FILE.pdf',
  pagebreak: { mode: 'avoid-all' },
  image:        { type: 'jpeg', quality: 0.98 },
  html2canvas:  { dpi: 96, letterRendering: true  },
  jsPDF:        { unit: "mm", format: [100, 144], orientation: 'portrait' }
};

// New Promise-based usage:

html2pdf().set(opt).from(document.getElementsByClassName("giftnotes_print")[0]).toPdf().get('pdf').then(pdf => {
  console.log(pdf);
  console.log(pdf.internal);
  console.log(pdf.internal.getNumberOfPages());
  pdf.deletePage(2);
  pdf.save();
});


arr.reduce((rows, key, index) => (index % 3 == 0 ? rows.push([key]) 
  : rows[rows.length-1].push(key)) && rows, []);

*/



/**************
HOW TO SETUP: USE WITH FIREFOX
1) Open Firefox
2) type in the search bar: about:config
3) Search for: print.always_print_silent
4) Turn it ON (value should be true)
*********/
class GiftNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      // notes: [
      //   "Test note one!",
      //   "Happy Birthday Sweety!",
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
      //   "Test note one!",
      //   "Happy Birthday Sweety!",
      //   "Test note one!",
      //   "Happy Birthday Sweety!"
      // ],
      notes: [],
      isLoadingNote: false,
    };
    this.scannerInput = React.createRef();
    this.handleScan = this.handleScan.bind(this);
    this.downloadPDFs = this.downloadPDFs.bind(this);
    /*this.handleUpdateChannel = this.handleUpdateChannel.bind(this);*/
  }
  componentDidMount() {

    console.log('componentDidMount GiftNotes');
    this.scannerInput.current.focus();



  }
  componentDidUpdate() {
    console.log('componentDidUpdate GiftNotes');
  }

  handleScan() {
    console.log("handleScan");
    let valueInput = this.scannerInput.current.value;
    if (valueInput == "PRINT") {
      window.print();

      // reset input
      this.scannerInput.current.value = "";
      this.scannerInput.current.focus();
    } else if (valueInput == "RESET LAST") {
      let copyNotes = this.state.notes;
      if (copyNotes.length > 0) {
        copyNotes.pop(); // remove last element
        this.setState({ notes: copyNotes });
      }
      // reset input
      this.scannerInput.current.value = "";
      this.scannerInput.current.focus();
    } else if (valueInput == "RESET ALL") {
      this.setState({ notes: [] });
      // reset input
      this.scannerInput.current.value = "";
      this.scannerInput.current.focus();
    } else {
      valueInput = valueInput.replace("#", "");
      //if (valueInput.length == 9 && !isNaN(parseInt(valueInput))) {
      if ((!valueInput.includes("GE") && valueInput.length == 9) || (valueInput.includes("GE") && valueInput.length >= 13)) {
        // Get order details
        console.log(valueInput, "valueInput");
        this.setState({ isLoadingNote: true });
        axios.get(`/api/v1/shop_order/${valueInput}`)
          .then((res) => {
            console.log(res, "==> RESULT");
            console.log(res.data.order, "order");
            console.log(res.data.order.note, "order note");
            if (res.data.order.note) {
              this.setState({ notes: [...this.state.notes, res.data.order.note], isLoadingNote: false });
            }
          })
          .catch(err => {
            // what now?
            console.log(err);
          })
        // reset input
        this.scannerInput.current.value = "";
        this.scannerInput.current.focus();
      }
    }
  }


  downloadPDFs() {
    console.log("=> downloadPDFs");

    let opt = {
      margin:       [0,0],
      filename:     'TEST_FILE.pdf',
      pagebreak: { mode: 'avoid-all' },
      image:        { type: 'jpeg', quality: 1 },
      html2canvas:  { dpi: 96, letterRendering: true  },
      jsPDF:        { unit: "mm", format: [100, 144], orientation: 'portrait' }
    };
    


    let noteContainers = document.getElementsByClassName("giftnotes_print");
    let pdfsBuffer = [];
    for (let i = 0; i < noteContainers.length; i++) {
      console.log(noteContainers[0]);
      window.html2pdf().set(opt).from(document.getElementsByClassName("giftnotes_print")[0]).toPdf().get('pdf').then(pdf => {
        pdf.deletePage(2);
        pdfsBuffer.push(pdf.output('arraybuffer'));
        console.log(pdf, "PDF");
        // pdf.save();
      });
    }

  
    
    // const mergedPdf = PDFDocument.create();
    // pdfsBuffer.map(pdfBuffer => {
    //   const pdf = PDFDocument.load(pdfBuffer);
    //   const copiedPages = mergedPdf.copyPages(pdf, pdf.getPageIndices());
    //   copiedPages.forEach((page) => {
    //     // console.log('page', page.getWidth(), page.getHeight());
    //     page.setWidth(210);
    //     mergedPdf.addPage(page);
    //   });
    // });
    // mergedPdf.save();






      // html2pdf().set(opt).from(document.getElementsByClassName("giftnotes_print")[0]).toPdf().get('pdf').then(pdf => {
      //   console.log(pdf);
      //   console.log(pdf.internal);
      //   console.log(pdf.internal.getNumberOfPages());
      //   pdf.deletePage(2);
      //   pdf.save();
      // });


  }



  render() {
    const { notes, isLoadingNote } = this.state;
    let convertArrNotes = notes.reduce((rows, key, index) => (index % 2 == 0 ? rows.push([key]) : rows[rows.length-1].push(key)) && rows, []);
    console.log(convertArrNotes, "==> convertArrNotes");

    return (
      <>
        <a className="gift_pdf" href={pdfToPrint} target="_blank" rel="noopener noreferrer">
          <span className="material-icons gift_pdf--icons">picture_as_pdf</span>
          <p className="gift_pdf--title">DOWNLOAD</p>
        </a>

        <div className="giftnotes">

          <div className="giftnotes__input">
            {(isLoadingNote) ? (
              <Loading />
            ) : (
              <input
                autoFocus
                type="text"
                className="giftnotes__input_input"
                aria-describedby="order number"
                placeholder="Scan"
                onChange={this.handleScan}
                defaultValue=''
                ref={this.scannerInput}
              />
            )}
          </div>

          <div onClick={this.downloadPDFs}>DOWNLOAD PDFs</div>

          {(notes.length > 0) ? (
            <div className="giftnotes_output_container">
              <div className="giftnotes_output">
                {notes.map((note, i) => {
                  return (
                    <textarea key={`textarea_note_${i}`} className="giftnotes_output_textarea">{note}</textarea>
                  );
                })}
              </div>
              <div className="giftnotes_result">
                {notes.map((note, i) => {
                  return (
                    <div key={`div_note_${i}`} className="giftnotes_result_textarea">{note}</div>
                  );
                })}
              </div>
            </div>
          ) : (
            null
          )}
        </div>
        {/* printing media-query */}
        {(notes.length > 0) ? (
          <>
          {/*<div className="giftnotes_print">
              <div key={`print_note_$}`} className="giftnotes_print_note">{notes[0]}</div>
              <div key={`print_note_$}`} className="giftnotes_print_note">{notes[1]}</div>
              {notes.map((note, i) => {
                return (
                  <div key={`print_note_${i}`} className="giftnotes_print_note">{note}</div>
                );
              })}
            </div>*/}
            {convertArrNotes.map((notes, i) => {
              return (
                <div className="giftnotes_print">
                  {notes.map((note, j) => {
                    console.log(note.length, "length");
                    let _fontSize = 0;
                    if (note.length < 25) {
                      _fontSize = 17;
                    } else if (note.length < 90) {
                      _fontSize = 16;
                    } else if (note.length < 150) {
                      _fontSize = 15;
                    } else {
                      _fontSize = 14;
                    }
                    // style={{ "fontSize": _fontSize + "px" }} ===>
                    return (
                      <div className={`giftnotes_print_note`} key={`print_note_${i}_${j}`}>
                        {note}
                        <div className="giftnotes_print_note_image">
                          <div className="giftnotes_print_note_image--border">&nbsp;</div>
                          <img className="giftnotes_print_note_image--img" src={logoAnaLuisaA} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )
            })}
          </>
        ) : (
          null
        )}
      </>
    );
  }
}

export default GiftNotes;
