import React from "react";
import "./Scrapping.css";
import Scrapping from './Scrapping';
import _ from 'lodash';
import axios from 'axios';
class ScrappingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: this.props.data,
      date: new Date(),
      scrapper_one: [],
      scrapper_two: [],
      scrapper_three: [],
      scrapper_four: [],
      scrapper_five: [],
      total_scrapped: 0,
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    let total_scrapped = 0;
    axios({
      method: 'GET',
      url: '/api/v1/scrapped_length'
    }).then((r) => {
      total_scrapped = r.data.count;
      axios({
        method: 'GET',
        // url: 'http://localhost:5000/proxy/youtube/UCcng5aBy0ZmX7g2PfHf4gJA'
        // url: 'http://localhost:5000/proxy/youtube/UCW3O1nLY6m14D82swOQU7YQ'
        // url: 'http://localhost:5000/proxy/youtube/UCcng5aBy0ZmX7g2PfHf4gJA'
        url: '/api/v1/channels?key=status&value=scrapped'
      }).then((r) => {
        /*var numberChannelsToScrap = r.data.channel.length;
        var nbOfElementForEachArr = (numberChannelsToScrap / 4).toFixed();
        var arrSplited = _.chunk(r.data.channel, nbOfElementForEachArr);
        // console.log(arrSplited);
        this.setState({
          // scrapper_one: r.data.channel
          scrapper_one: arrSplited[0],
          scrapper_two: arrSplited[1],
          scrapper_three: arrSplited[2],
          scrapper_four: arrSplited[3]
        });
        */
        let arrOne = this.pickRandomElements(62, r.data.channel);
        let arrTwo = this.pickRandomElements(62, r.data.channel);
        let arrThree = this.pickRandomElements(62, r.data.channel);
        let arrFour = this.pickRandomElements(62, r.data.channel);
        let arrFive = this.pickRandomElements(62, r.data.channel);
        this.setState({
          // scrapper_one: r.data.channel
          scrapper_one: arrOne,
          scrapper_two: arrTwo,
          scrapper_three: arrThree,
          scrapper_four: arrFour,
          scrapper_five: arrFive,
          total_scrapped: total_scrapped,
        });
      });

    });
  }

  pickRandomElements(number, arr) {
    let shuffledArr = _.shuffle(arr);
    let randomVal = shuffledArr.slice(0, number);
    return randomVal;
  }

  render() {
    const { scrapper_one, scrapper_two, scrapper_three, scrapper_four, scrapper_five, total_scrapped } = this.state;
    return (
      <div>
        <div className="scrapping__info">
          <p>There is {total_scrapped} channels waiting to be analysed!</p>
        </div>
        <div className="scrapping">
          {(scrapper_one.length > 0) ? <Scrapping id={0} channels={this.state.scrapper_one} reloadData={this.getData} /> : null}
          {(scrapper_two.length > 0) ? <Scrapping id={1} channels={this.state.scrapper_two} reloadData={this.getData} /> : null}
          {(scrapper_three.length > 0) ? <Scrapping id={2} channels={this.state.scrapper_three} reloadData={this.getData} /> : null}
          {(scrapper_four.length > 0) ? <Scrapping id={3} channels={this.state.scrapper_four} reloadData={this.getData} /> : null}
          {(scrapper_five.length > 0) ? <Scrapping id={4} channels={this.state.scrapper_five} reloadData={this.getData} /> : null}
        </div>
      </div>
    );
  }
}
export default ScrappingContainer;
