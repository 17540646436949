import React from "react";
import Loading from "../Loading";
import HeaderComponent from "../HeaderComponent";
import axios from "axios";
import "./VideosInformation.css";
import { ALButton, ALContainer, ALTable } from "../ALComponents";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class VideosInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInputSelected: false,
      videoTable: [],
      videoBeingProcessed: 0,

      permissionClipboardRead: false,
      permissionClipboardWrite: false,
      error: null,
    };
  }
  getVideosInformation() {
    axios({
      method: "GET",
      url: "/api/v1/videos_information",
    })
      .then((r) => {
        this.setState({});
      })
      .catch((err) => {
        console.error("Failed to retrieve videos information", err);
        alert("Failed to retrieve videos information");
      });
  }
  // clipboardState(type, permissionState) {
  //   console.log(type, permissionState);
  //   if (permissionState !== "granted") {
  //     // 'denied' or 'prompt'
  //     this.setState({error: "Please allow clipboard access for both read and write."});
  //   } else if (permissionState === "granted") {
  //     this.setState({[type]: true});
  //   }
  //   if (this.state.permissionClipboardRead && this.state.permissionClipboardWrite) {
  //     this.setState({error: null});
  //   }
  // }
  componentDidMount() {
    // navigator.permissions.query({
    //   name: 'clipboard-read'
    // }).then(permissionStatus => {
    //   this.clipboardState(permissionStatus.state);
    //   // Listen for changes to the permission state
    //   permissionStatus.onchange = () => {
    //     this.clipboardState("permissionClipboardRead", permissionStatus.state);
    //   };
    // });
  }
  async scrapVideos() {
    const MAX_CONCURRENCY = 10;

    for (let i = 0; i < this.state.videoTable.length; i++) {
      const handle = this.state.videoTable[i]["handle"]
      if (handle) {
        for (let i = 0; i < 60 && this.state.videoBeingProcessed >= MAX_CONCURRENCY ; i++) {
          await sleep(1000);
        }
        this.setState((state) => {
          if (state.videoTable[i]) {
            const newVideoTable = [...state.videoTable];
            newVideoTable[i] = { ...newVideoTable[i], "views": "Processing..." };
            return { videoTable: newVideoTable, videoBeingProcessed: state.videoBeingProcessed + 1 };
          }
        });
        axios.get(`/api/v1/youtube_scraper/video/${handle}/stats`)
        .then(result => {
          console.log(result.data);
          return this.setState((state) => {
            if (state.videoTable[i]) {
              const newVideoTable = [...state.videoTable];
              newVideoTable[i] = { ...newVideoTable[i], ...result.data.result};
              return { videoTable: newVideoTable, videoBeingProcessed: state.videoBeingProcessed - 1 };
            }
            return null;
          });
        })
        .catch(err => {
          console.error("Failed call for:", i, handle, err);
          return this.setState((state) => {
            const newVideoTable = [...state.videoTable];
            newVideoTable[i] = { ...newVideoTable[i], "views": "Failed" };
            return { videoTable: newVideoTable, videoBeingProcessed: state.videoBeingProcessed - 1 };
          });
        })
      }
    }
  }
  async onCopyTrigger() {
    await navigator.clipboard.writeText(this.state.videoTable.reduce((acc, line) => acc + `${line["url"]}\t${line["views"]}\n`, "URLs\tViews\n"));
  }
  onPasteTrigger(value) {
    console.log("Pasted:", value);
    const urls = value
      .split("\n")
      .filter((line) => line.includes("www.youtube.com") || line.includes("youtu.be"))
      .map((urlLine) => {
        const formatedUrl = urlLine.trim().replace('"', "").replace(" ", "");
        let handle = null;
        if (formatedUrl.includes("www.youtube.com")) {
          try {
            let qs = new URLSearchParams(formatedUrl.split("/watch")[1]);
            handle = qs.get("v");
          } catch {
            console.warn(`Failed to get handle for ${formatedUrl}`);
          }
          return {
            "url": formatedUrl,
            "handle": handle,
            "views": handle ? "Pending..." : "Error",
          };
        }
        if (formatedUrl.includes("youtu.be")) {
          handle = formatedUrl.split("youtu.be/")[1];
          return {
            "url": formatedUrl,
            "handle": handle,
            "views": "Pending...",
          };
        }
        return {
          "url": formatedUrl,
          "handle": null,
          "views": "Error",
        };
      });
    urls.map((line) => console.log(line));
    this.setState({ videoTable: urls }, () => setTimeout(() => this.scrapVideos()), 0);
    // if (!navigator.clipboard || !navigator.clipboard.read) {
    //   return alert("Browser clipboard isn't accessible!");
    // }
    // console.log(navigator.clipboard)
    // navigator.clipboard
    //   .read()
    //   .then((text) => {
    //     // `text` contains the text read from the clipboard
    //     console.log(text);
    //   })
    //   .catch((err) => {
    //     // maybe user didn't grant access to read from clipboard
    //     console.log("Something went wrong", err);
    //     return alert("Browser clipboard isn't accessible! You need to grant Oklos the clipboard access.");
    //   });
  }
  render() {
    console.log("state:", this.state);
    return (
      <div className="video_information_container">
        <HeaderComponent title="Videos Information" to="/a/dashboard/youtube" toTitle="YouTube" howToModal={() => this.props.showModal("ModalHowToVideosInformation")} />
        <ALContainer>
          {this.state.error ? <p>{this.state.error}</p> : null}
          <input
            className={!this.state.isInputSelected ? "video_information_container__input" : "video_information_container__input--selected"}
            placeholder={
              !this.state.isInputSelected
                ? "Click here to paste videos' URL from table sheet column"
                : "You can paste videos' URL (Ctrl + V or Cmd + V)"
            }
            onFocus={() => this.setState({ isInputSelected: true })}
            onBlur={() => this.setState({ isInputSelected: false })}
            onPaste={(e) => console.log("target:", e.target) || this.onPasteTrigger(e.clipboardData.getData("text"))}
            onChange={() => null}
            value=""
          />
          {this.state.videoTable.length === 0 ? null : <>
            <ALTable
              title={"Videos Information"}
              header={["URL", "Views"]}
              content={this.state.videoTable.map((videoObj) => [videoObj["url"], videoObj["views"] || ""])}
              column_style={[{ width: "500px" }, { width: "200px" }]}
            />
            <div className="video_information_container__bottom_actions">
              <ALButton type="text" material_icon="clear" style={{ marginRight: "15px" }} onClick={() => this.setState({videoTable: []})}>
                Clear
              </ALButton>
              <ALButton type="outlined" material_icon="content_copy" onClick={() => this.onCopyTrigger()}>
                Copy results
              </ALButton>
            </div>
          </>}
        </ALContainer>
      </div>
    );
  }
}

export default VideosInformation;
