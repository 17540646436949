import React from "react";
import _ from "lodash";
import { ALContainer, ALButton, ALDropdown, ALSwitch } from "../ALComponents";
import "./ModalSearchExport.css";

const ModalHead = (props) => (
  <div className="modal-head">
    <div className="modal-head__line">
      <h1 className="modal-head__line__title">Export options</h1>
      <div className="modal-head__line__button">
        <ALButton type="text" material_icon="clear" onClick={props.close} />
      </div>
    </div>
    <hr className="modal-head__separator" />
  </div>
);
const ModalFoot = (props) => (
  <div className="modal-foot">
    <ALButton type="text" material_icon="clear" onClick={props.close}>
      Cancel
    </ALButton>
    <div style={{ width: "20px" }}></div>
    <ALButton type="contained" onClick={props.confirm}>
      Export
    </ALButton>
  </div>
);

class ModalSearchExport extends React.Component {
  constructor(props) {
    super(props);
    this.dataTypes = [
      { id: "", name: "Choose data to add" },
      { id: "deal", name: "Deal" },
      { id: "youtube", name: "Youtube" },
      { id: "instagram", name: "Instagram" },
    ];
    this.fieldByType = {
      influencer: [
        { key: "emails", name: "Email", export: true },
        { key: "paypal_email", name: "Paypal email", export: true },
        { key: "youtube.accounts", name: "YouTube Channels id", export: true },
        { key: "instagram.accounts", name: "Instagram handles", export: true },
        { key: "country", name: "Country", export: true },
        { key: "deals", name: "Number of deals", export: true },
        { key: "gender", name: "Gender", export: true },
        { key: "age", name: "Age", export: true },
        { key: "notes", name: "Influencer notes", export: true },
        { key: "status", name: "Influencer status", export: true },
        { key: "tags", name: "All tags", export: true },
        { key: "campain_tags", name: "All campaign tags", export: true },
      ],
      deal: [
        { key: "name", name: "Name", export: false },
        { key: "am", name: "Account manager", export: true },
        { key: "notes", name: "Notes", export: false },
        { key: "status", name: "Status", export: true },
        { key: "price", name: "Final price", export: false },
        { key: "initial_price", name: "Initial price", export: false },
        { key: "number_pieces", name: "Number of pieces", export: false },
        { key: "payment_status", name: "Payment status", export: false },
        { key: "creation_date", name: "Creation date", export: true },
        { key: "date_first_outreach", name: "Date first outreach", export: false },
        { key: "date_last_outreach", name: "Date last outreach", export: false },
        { key: "date_follow_up", name: "Date follow up", export: false },
        { key: "date_sign", name: "Date sign", export: false },
      ],
      youtube: [
        { key: "title", name: "Name", export: true },
        { key: "channelId", name: "Channel ID", export: true },
        { key: "description", name: "Description", export: false },
        { key: "stats.views", name: "Views", export: false },
        { key: "stats.subscribers", name: "Subscribers", export: false },
      ],
      instagram: [
        { key: "fullName", name: "Name", export: true },
        { key: "profileId", name: "Profile ID", export: true },
        { key: "stats.posts", name: "Posts", export: true },
        { key: "stats.followers", name: "Followers", export: true },
        { key: "stats.following", name: "Following", export: false },
        { key: "description", name: "Description", export: false },
      ],
    };
    this.state = {
      addDataSelectedType: "",
      dealSwitch: "deal_last",
      positionInput: "",
      dataList: [
        {
          type: "influencer",
          fields: _.cloneDeep(this.fieldByType.influencer),
          isExpanded: false,
        },
      ],
    };
    this.addDataToExport = this.addDataToExport.bind(this);
  }
  addDataToExport() {
    this.setState((state) => {
      let dataToAdd = {
        type: state.addDataSelectedType,
        fields: _.cloneDeep(this.fieldByType[state.addDataSelectedType]),
        isExpanded: true,
      };
      if (state.addDataSelectedType === "deal") {
        dataToAdd["index"] = state.dealSwitch === "deal_last" ? -1 : state.positionInput - 1;
      }
      return { dataList: [...state.dataList, dataToAdd] };
    });
  }
  render() {
    const { resume } = this.props;
    const { addDataSelectedType, dealSwitch, positionInput, dataList } = this.state;
    return (
      <ALContainer className="search-export-modal">
        <ModalHead close={resume} />
        <div className="search-export-modal__content">
          <div className="search-export-modal__add_data">
            <div className="search-export-modal__add_data__option-wrapper">
              <ALDropdown
                options={this.dataTypes}
                useValue={"name"}
                useKey={"id"}
                value={addDataSelectedType}
                onChange={(key) => this.setState({ addDataSelectedType: key })}
                style={{
                  "dropdown_container__selected_value": "search-export-modal__dropdown-value",
                  "dropdown_container__list": "search-export-modal__dropdown-list",
                }}
              />
              {addDataSelectedType === "deal" ? (
                <>
                  <ALSwitch
                    options={[
                      { key: "deal_last", name: "Last" },
                      { key: "deal_number", name: "Number" },
                    ]}
                    value={dealSwitch}
                    onChange={(key) => this.setState({ dealSwitch: key })}
                  />
                </>
              ) : null}
              {addDataSelectedType === "deal" && dealSwitch === "deal_number" ? (
                <>
                  <input
                    className="search-options__input-number"
                    type="number"
                    placeholder="Number"
                    value={positionInput}
                    onChange={(e) =>
                      this.setState({ positionInput: isNaN(e.target.valueAsNumber) ? "" : e.target.valueAsNumber > 0 ? e.target.valueAsNumber : 1 })
                    }
                  />
                </>
              ) : null}
            </div>
            <ALButton
              type="contained"
              material_icon="add"
              onClick={this.addDataToExport}
              disable={addDataSelectedType === "deal" && dealSwitch === "deal_number" && positionInput === ""}>
              Add
            </ALButton>
          </div>
          <div className="search-export-modal__data_list">
            {dataList.map((dataObj, dIndex) => (
              <div key={dIndex} className="search-export-modal__data_to_export">
                <div className="search-export-modal__data_to_export__header">
                  <div className="search-export-modal__data_to_export__header-content">
                    <div className="search-export-modal__data_to_export__header__type">{_.capitalize(dataObj.type)}</div>
                    {dataObj.index !== undefined ? (
                      <div className="search-export-modal__data_to_export__header__index">
                        {dataObj.index === -1 ? "— last" : `— #${dataObj.index + 1}`}
                      </div>
                    ) : null}
                  </div>
                  <div className="search-export-modal__data_to_export__header-actions">
                    <div>{dataObj.fields.reduce((acc, field) => acc + (field.export ? 1 : 0), 0)} selected fields</div>
                    <input
                      className="search-export__checkbox"
                      type="checkbox"
                      checked={dataObj.fields.every((field) => field.export)}
                      onClick={() => {
                        this.setState((state) => {
                          const newDataList = [...state.dataList];
                          if (dataObj.fields.every((field) => field.export)) {
                            newDataList[dIndex].fields = [...newDataList[dIndex].fields].map((field) => ({ ...field, export: false }));
                          } else {
                            newDataList[dIndex].fields = [...newDataList[dIndex].fields].map((field) => ({ ...field, export: true }));
                          }
                          return { dataList: newDataList };
                        });
                      }}
                    />
                    <span
                      className="material-icons search-export-modal__data_to_export__header-actions__expand"
                      onClick={() => {
                        this.setState((state) => {
                          const newDataList = [...state.dataList];
                          newDataList[dIndex] = { ...newDataList[dIndex], isExpanded: !dataObj.isExpanded };
                          return { dataList: newDataList };
                        });
                      }}>
                      {dataObj.isExpanded ? "expand_less" : "expand_more"}
                    </span>
                  </div>
                </div>
                {dataObj.isExpanded ? (
                  <div className="search-export-modal__data_to_export__fields">
                    {dataObj.fields.map((field, fIndex) => (
                      <div key={fIndex} className="search-export-modal__data_to_export__field">
                        <div className="search-export-modal__data_to_export__field-content">
                          <div className="search-export-modal__data_to_export__field__name">{field.name}</div>
                        </div>
                        <div className="search-export-modal__data_to_export__field-actions">
                          <input
                            className="search-export__checkbox"
                            type="checkbox"
                            checked={field.export}
                            onClick={() =>
                              this.setState((state) => {
                                const newDataList = [...state.dataList];
                                newDataList[dIndex].fields = [...newDataList[dIndex].fields];
                                newDataList[dIndex].fields[fIndex] = {
                                  ...newDataList[dIndex].fields[fIndex],
                                  export: !newDataList[dIndex].fields[fIndex].export,
                                };
                                return { dataList: newDataList };
                              })
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <ModalFoot close={() => resume()} confirm={() => resume(this.state.dataList)} />
      </ALContainer>
    );
  }
}

export default ModalSearchExport;
