import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';

import NotificationManager from 'components/al_components/notification/NotificationManager';
import "./DealNotes.css";

const DealNotes = ({ deal, user, theme, onDealUpdated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tmpNote, setTmpNote] = useState('');
  const [tmpNoteEdition, setTmpNoteEdition] = useState(-1);
  const [hoverNote, setHoverNote] = useState(null);

  const createNewNote = () => {
    if (tmpNote && !isLoading) {
      setIsLoading(true);
      
      axios
        .post(`/api/v1/deals/${deal._id}/notes`, {
          content: tmpNote,
          author: user.email
        })
        .then(({ data }) => {
          axios
            .post('/api/v1/event', {
              type: 'ADD_NOTE_DEAL',
              user: user.email,
              sample: { object: deal._id }
            })
            .catch(
              err => console.log(err) || alert('Failed to fire event ADD_NOTE_DEAL')
          )
          setTmpNote('');
          onDealUpdated({ ...deal, notes: data.result });
          setIsLoading(false);
          NotificationManager.success(
            'Updated',
            'Note successfully added to the deal',
            1500
          )
        })
        .catch(err => {
          console.error(err);
          NotificationManager.error(
            'Error',
            'Server failed to add note, please try again later and send the page log to the tech team',
            6000
          );
          setIsLoading(false);
        })
    }
  }

  const deleteNote = idx => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    axios
      .delete(`/api/v1/deals/${deal._id}/notes/${idx}`)
      .then(({ data }) => {
        onDealUpdated({ ...deal, notes: data.result });
        setIsLoading(false);
        NotificationManager.success(
          'Updated',
          'Note successfully deleted',
          1500
        );
      })
      .catch(err => {
        console.error(err);
        NotificationManager.error(
          'Error',
          'Server failed to delete note, please try again later and send the page log to the tech team',
          6000
        );
        setIsLoading(false);
      })
  }

  const editNote = idx => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    axios
      .put(`/api/v1/deals/${deal._id}/notes/${idx}`, {
        content: tmpNote,
        author: user.email
      })
      .then(({ data }) => {
        onDealUpdated({ notes: data.result });
        setTmpNote('');
        setTmpNoteEdition(-1);
        setIsLoading(false);
        NotificationManager.success('Updated', 'Note successfully edited', 1500);
      })
      .catch(err => {
        console.error(err);
        NotificationManager.error(
          'Error',
          'Server failed to delete note, please try again later and send the page log to the tech team',
          6000
        )
        setIsLoading(false);
      })
  }

  return (
    <div className={`deal_notes ${theme === 'gray' ? 'deal_notes--gray' : ''}`}>
      <form className='deal_notes_input'>
        <textarea
          className='deal_notes_input_text'
          placeholder='Add note'
          value={tmpNote || ''}
          rows={theme === 'gray' ? '1' : ''}
          onChange={e => setTmpNote(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter' && e.shiftKey === false) {
              e.preventDefault();
              tmpNoteEdition === -1 ? createNewNote() : editNote(tmpNoteEdition);
            }
          }}
        />
        <div className='deal_notes_input_actions'>
          <div
            className="deal_notes_input_add"
            onClick={() => tmpNoteEdition === -1 ? createNewNote() : editNote(tmpNoteEdition)}
            >
            <span className='material-icons'>
              {tmpNoteEdition !== -1 ? 'create' : 'send'}
            </span>
          </div>
          {tmpNoteEdition !== -1 && (
            <div
              className='deal_notes_input_cancel'
              onClick={() => {
                setTmpNote(null)
                setTmpNoteEdition(-1)
              }}
            >
              Cancel
            </div>
          )}
        </div>
      </form>
      <div className='deal_notes_history'>
        {deal?.notes?.length > 0 &&
          deal.notes.map((note, i) => {
            const currDate = moment(note.date).format('MMM DD, YYYY');
            const currAuthor = note.author;
            let isSameDate = true;
            let isSameAuthor = true;
            if (i !== 0) {
              const prevDate = moment(deal.notes[i - 1].date).format('MMM DD, YYYY');
              const prevAuthor = deal.notes[i - 1].author;
              if (currDate !== prevDate) {
                isSameDate = false;
              }
              if (currAuthor !== prevAuthor) {
                isSameAuthor = false;
              }
            }
            const author = note.author
              ? note.author === user.email
                ? 'You'
                : note.author
              : 'Oklos Bot';
            return (
              <div key={`note_inf_${i}`}>
                {(!isSameDate || !isSameAuthor) ? <hr /> : null}
                <div
                  key={`note_deal_${i}`}
                  className="deal_notes_history_note"
                >
                  <div
                    className={
                      `deal_notes_history_note_content 
                      ${note.author === 'adam@analuisa.com' ? 'deal_notes_history_note_content--adam' : ''}
                      ${tmpNoteEdition === i ? 'deal_notes_history_note_content--edition' : ''}
                      ${hoverNote === i && tmpNoteEdition === -1 ? 'deal_notes_history_note_content--hover' : ''}`}
                    onMouseEnter={() => setHoverNote(i)}
                    onMouseLeave={() => setHoverNote(null)}
                  >
                    {note.content.split('\n').map((text, idx) => (
                      <React.Fragment key={idx}>
                        {text}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                  {tmpNoteEdition === -1 && (
                    <div
                      className={
                        hoverNote === i
                          ? 'deal_notes_history_note_delete--hover'
                          : 'deal_notes_history_note_delete'
                      }
                      onMouseEnter={() => setHoverNote(i)}
                      onMouseLeave={() => setHoverNote(null)}
                    >
                      <span
                        className='deal_notes_history_note_delete--icon material-icons'
                        onClick={() => deleteNote(i)}
                      >
                        close
                      </span>
                      <span
                        className='deal_notes_history_note_delete--icon material-icons'
                        onClick={() => {
                          setTmpNoteEdition(i)
                          setTmpNote(note.content)
                        }}
                      >
                        create
                      </span>
                    </div>
                  )}
                  {(!isSameDate || !isSameAuthor || i === 0) && (
                    <div className='deal_notes_history_note_meta'>
                      <p className='deal_notes_history_note_meta_date'>
                        {currDate} ({moment(note.date).fromNow()})
                      </p>
                      <p className='deal_notes_history_note_meta_author'>
                        {author}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

DealNotes.propTypes = {
  deal: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  theme: PropTypes.string,
  onDealUpdated: PropTypes.func.isRequired,
};

export default DealNotes
