import React from "react";
import { formatISO } from "date-fns";
import { ALLoading } from "components/ALComponents";
import Loading from "components/Loading";

const CurrentDealShippingInfo = ({ deal, updateDeal, showModal, dealShippings, isDealShippingLoaded, dealShippingLoading }) => {
  function onNewShipping() {
    showModal("ModalConfirmation", {
      title: "Caution!",
      text: "You are going to send this deal again in shipping!\nPlease make sure that this is really what you want to do.",
    }).then((res) => {
      if (res === "confirm") {
        updateDeal({
          shipping_typeform: { ...deal.shipping_typeform, type: deal.shipping_typeform.type + "__additional", date_added: new Date() },
          status: "to_ship",
        });
      }
    });
  }

  return (
    <div className="oi_deal_shipping__results">
      <div className="oi_deal_shipping__results__header">
        <p className="oi_deal_shipping__results__header__title">Shipping</p>
        {deal.status !== "to_ship" && dealShippings.length && (
          <button className="oi_deal_shipping__results__new_shipping" onClick={onNewShipping}>
            New shipping
          </button>
        )}
      </div>
      {dealShippingLoading ? (
        <div className="oi_deal_shipping__results__field oi_deal_shipping__results__field--oneline">
          <Loading />
        </div>
      ) : null}
      {isDealShippingLoaded ? (
        dealShippings.length ? (
          dealShippings.map((ds) => (
            <div key={ds._id} className="oi_deal_shipping__results__container">
              <div className="oi_deal_shipping__results__field">
                <div className="oi_deal_shipping__results__header">
                  <p className="oi_deal_shipping__results__header__title">Tracking #</p>
                </div>
                <div className="oi_deal_shipping__results__field__content">{ds.trackingNumber}</div>
              </div>
              <div className="oi_deal_shipping__results__field">
                <div className="oi_deal_shipping__results__header">
                  <p className="oi_deal_shipping__results__header__title">Order #</p>
                </div>
                <div className="oi_deal_shipping__results__field__content">{ds.orderId}</div>
              </div>
              <div className="oi_deal_shipping__results__field">
                <div className="oi_deal_shipping__results__header">
                  <p className="oi_deal_shipping__results__header__title">Creation date</p>
                </div>
                <div className="oi_deal_shipping__results__field__content">
                  {ds.date ? formatISO(new Date(ds.date), { representation: "date" }) : ""}
                </div>
              </div>
              <div className="oi_deal_shipping__results__field oi_deal_shipping__results__field--oneline">
                <div className="oi_deal_shipping__results__header">
                  <p className="oi_deal_shipping__results__header__title">Redirect</p>
                </div>
                <div className="oi_deal_shipping__results__field__content">{ds.redirect ? `https://shop.analuisa.com${ds.redirect}` : ""}</div>
              </div>
              <div className="oi_deal_shipping__results__field oi_deal_shipping__results__field--oneline">
                <div className="oi_deal_shipping__results__header">
                  <p className="oi_deal_shipping__results__header__title">Brief</p>
                </div>
                <div className="oi_deal_shipping__results__field__content">{ds?.brief}</div>
              </div>
            </div>
          ))
        ) : (
          "Deal shipping not found."
        )
      ) : (
        <ALLoading alt />
      )}
    </div>
  );
};

export default CurrentDealShippingInfo;