import React from "react";
import "./ModalHowToVideosInformation.css";
import { ALButton, ALContainer } from "../ALComponents";

const ModalHead = (props) => (
  <div className="modal-head">
    <div className="modal-head__line">
      <h1 className="modal-head__line__title">How to</h1>
      <div className="modal-head__line__button">
        <ALButton type="text" material_icon="clear" onClick={props.close} />
      </div>
    </div>
    <hr className="modal-head__separator" />
  </div>
);

const ModalFoot = (props) => (
  <div className="modal-foot">
    <ALButton type="text" material_icon="clear" onClick={props.close}>Back</ALButton>
  </div>
)

class ModalHowToVideosInformation extends React.Component {
  render() {
    return (
      <ALContainer className="how-to-videos-information-modal">
        <ModalHead close={() => this.props.resume()} />
        <div className="how-to-videos-information-modal__content">
          <h2>Purpose</h2>
          <p>Retrieving the number of views from a list of YouTube videos URLs. And exporting those directly in a Excel/Spreadsheet document.</p>
          <h2>Usage</h2>
          <p>Copy a <b>column</b> of videos URLs. Click on the box in this page, it should turn <i>green</i> then paste using Ctrl+V(Windows-Linux)/Cmd+V(MacOS).</p>
          <p>Wait for the videos to be processed, you can watch their status changing.</p>
          <p>Click on the copy button and paste the results on your favorite data sheet.</p>
          <h2>Notes</h2>
          <p>You must copy <b>columns</b> of URLs and not lines.</p>
          <p>You can copy and paste only one URL if you'd like to.</p>
          <p>Some videos may failed to be processed. If this happen you can simply copy those and retry. If the problem still occurs, consider going to the URL to check that you correctly input the URL and that the video is available.</p>
          <ModalFoot close={() => this.props.resume()} />
        </div>
      </ALContainer>
    );
  }
}

export default ModalHowToVideosInformation;
