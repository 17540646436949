import React from "react";
import "./ModalYesNoHistory.css";
import { ALButton, ALContainer, ALTable } from "./ALComponents";

const ModalHead = (props) => (
  <div className="modal-head">
    <div className="modal-head__line">
      <h1 className="modal-head__line__title">History</h1>
      <div className="modal-head__line__button">
        <ALButton type="text" material_icon="clear" onClick={props.close} />
      </div>
    </div>
    <hr className="modal-head__separator" />
  </div>
);

const ModalFoot = (props) => (
  <div className="modal-foot">
    <ALButton type="text" material_icon="clear" onClick={props.close}>
      Back
    </ALButton>
  </div>
);

class ModalHowToShipping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: props.history
    };
  }
  render() {
    const { history } = this.state;
    return (
      <ALContainer className="how-to-shipping-modal">
        <ModalHead close={() => this.props.resume()} />
        <div className="how-to-shipping-modal__content">
          <p>You can modify bellow your 30 previous actions:</p>
          <ALTable
            title={"Latest actions"}
            header={["", "ID", "Name", "Decision", "Actions"]}
            content={history.map((history) => [
              (<a href={`https://www.youtube.com/channel/${history.id}`} target="_blank" title="Go to channel" rel="noopener noreferrer">
                <img alt="profile" crossOrigin="anonymous" referrerPolicy="no-referrer"  src={history.thumbnail} width="50px" />
              </a>),
              history.id,
              history.name,
              history.decision,
              <div style={{ display: "flex" }}>
                {history.actions.map((action, idx) => (
                  <div style={{ margin: "10px" }} key={idx}>
                    <ALButton onClick={async () => this.setState({history: await this.props.actions[action](history)})}>{action}</ALButton>
                  </div>
                ))}
              </div>,
            ])}
            column_style={[{width: "100px"}, { width: "225px" }, { width: "200px" }, { width: "100px" }, { width: "300px" }]}
          />

          <ModalFoot close={() => this.props.resume()} />
        </div>
      </ALContainer>
    );
  }
}

export default ModalHowToShipping;
