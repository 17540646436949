import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import NotificationManager from "../../al_components/notification/NotificationManager";
import { ALDropdown } from "../../ALComponents";
import { DEAL_STATUSES } from "dictionaries";
import Tags from "components/Tags";

const DECLINE_TYPE = "DECLINE";

const CurrentDealStatus = ({
  isSuperUser,
  deal,
  showModal,
  updateDeal,
  updateStatusInf,
  hasOngoingTransactions,
}) => {
  const [dealTags, setDealTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const protectedStatuses = ["to_ship", "shipped", "in_transit"];
  const shippingStatuses = ["to_ship", "shipped", "in_transit", "shipment_received"];

  // get all deal tags when loading the deal status
  useEffect(() => {
    axios.get("/api/v1/deal_tags").then((res) => {
      if (res.status === 200) {
        setAllTags(res.data.dealTags);
        const dealTags = res.data.dealTags.filter((t) => deal.tags?.includes(t._id));
        setDealTags(dealTags.map((t) => t.name));
      }
    });
  }, [deal.tags]);

  // available tags are all tags except the ones already set to this deal
  useEffect(() => {
    const tags = allTags?.filter((item) => !deal.tags?.includes(item._id));
    setAvailableTags(tags);
  }, [deal, allTags]);

  const updateTagCount = (tagId, type) => {
    const increment = type === "ADD" ? 1 : -1;
    axios.put(`/api/v1/deal_tags/id/${tagId}`, { $inc: { count: increment } }).catch((err) => {
      console.error(err);
    });
  };

  const addTag = (tagName) => {
    const tag = allTags.find((tag) => tag.name === tagName);
    const updatedTagsId = [...deal.tags, tag._id];
    const updatedTagsName = [...dealTags, tag.name];
    setDealTags(updatedTagsName);
    updateDeal({ tags: updatedTagsId }).then(() => {
      updateTagCount(tag._id, "ADD");
    });
  };

  const removeTag = (tagName) => {
    const tag = allTags.find((tag) => tag.name === tagName);
    const updatedTagsName = dealTags.filter((r) => r !== tagName);
    const updatedTags = allTags.filter((t) => updatedTagsName.includes(t.name));
    setDealTags(updatedTagsName);
    updateDeal({ tags: updatedTags.map((t) => t._id) }).then(() => {
      updateTagCount(tag._id, "REMOVE");
    });
  };

  const hasDeclineTags = () => {
    const declinedTagsNames = allTags.filter((tag) => tag.type === DECLINE_TYPE).map((d) => d.name);

    return dealTags.some((d) => declinedTagsNames.includes(d));
  };

  return (
    <div className="outreach_influencer_deal_status">
      <div className="outreach_influencer_deal_status_dropdowns">
        <ALDropdown
          options={DEAL_STATUSES}
          useValue={"title"}
          useKey={"id"}
          value={deal.status}
          selectedKey={deal.status}
          onChange={(key) => {
            if (key === deal.status) {
              return;
            }

            if (key === "declined_outreach") {

              // Prevent setting the status to declined without any decline dealTags selected
              if (!hasDeclineTags()) {
                NotificationManager.error(
                  "To change the status to declined_outreach, you need to set at least one decline tag.",
                  "Error",
                  6000
                );
                return;
              }

              // Prevent setting the status to declined from to_ship
              if (!isSuperUser && deal.status === 'to_ship') {
                NotificationManager.warning(
                  "You don't have the permission to change deal status to declined when its status is to_ship",
                  "Operation not allowed"
                );
                return;
              }

              // Prevent setting the status from the shipping statuses to declined when there are ongoing transactions
              if (
                !isSuperUser &&
                shippingStatuses.includes(deal.status) &&
                hasOngoingTransactions
              ) {
                NotificationManager.warning(
                  "You don't have the permission to change deal status to declined when there are ongoing transactions",
                  "Operation not allowed"
                );
                return;
              }
            }

            if (!(deal.status !== "to_ship" && key === "published")) {

              if (!isSuperUser && protectedStatuses.includes(key)) {
                NotificationManager.warning(
                  "You don't have the permission to change deal status to this status",
                  "Operation not allowed"
                );
                return;
              }

              // Don't allow status changes from protected statuses to anything other than declined_outreach
              if (!isSuperUser && protectedStatuses.includes(deal.status) && key !== 'declined_outreach') {
                NotificationManager.warning(
                  "You don't have the permission to change deal status from this status",
                  "Operation not allowed"
                );
                return;
              }
            }

            if (key === "published") {
              let hasPostLink = true;
              deal.content.forEach((theContent) => {
                if (!theContent.link && theContent.platform !== "instagram") {
                  hasPostLink = false;
                }
              });
              if (!hasPostLink) {
                // show notification
                NotificationManager.error(
                  "To change the status to published, you need to set (all) the posts link in the content tab",
                  "Error",
                  6000
                );
                return;
              }
              if (deal.payment_status < 100 && deal.price !== 0) {
                return NotificationManager.error(
                  "The deal has to be 100% paid before being considered as published.",
                  "Error",
                  6000
                );
              }
            }

            if (key === "adam") {
              if (deal.initial_price === null && deal.price === null) {
                NotificationManager.error(
                  "To change the status to adam, you need to set the price (Initial + Final)",
                  "Error",
                  6000
                );
                return;
              }
            }

            if (protectedStatuses.includes(key) || protectedStatuses.includes(deal.status)) {
              showModal("ModalConfirmation", {
                text: "Are you sure you want to change the current status?",
              }).then((res) => {
                if (res === "confirm") {
                  updateStatusInf(deal._id, key, deal.status);
                }
              });
              return;
            }

            updateStatusInf(deal._id, key, deal.status);
          }}
        />

        <ALDropdown
          options={availableTags?.map((a) => a.name)}
          value={"Select tags"}
          onChange={(tag) => addTag(tag)}
        />
      </div>
      {dealTags?.length > 0 && <Tags tags={dealTags} removeTag={removeTag} />}
    </div>
  );
};

CurrentDealStatus.propTypes = {
  isSuperUser: PropTypes.bool.isRequired,
  deal: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  updateStatusInf: PropTypes.func.isRequired,
};

export default CurrentDealStatus;
