import React from "react";
import { ALButton } from "components/ALComponents";
import "./error_boundary_content.css";

function ErrorBoundaryContent(props) {
  const { error, componentStack, resetError } = props;
  return (
    <div className="route-error-boundary">
      <div className="route-error-boundary__title">💥 You have encountered an error</div>
      <div className="route-error-boundary__instructions">If this is not the first time, please report this error!</div>
      <div className="route-error-boundary__dev-info">
        <pre>{error.toString()}</pre>
        <pre>{componentStack}</pre>
      </div>
      <ALButton
        onClick={() => {
          console.log("componentStack", componentStack);
          console.log("click");
          {
            /* When resetError() is called it will remove the Fallback component */
          }
          {
            /* and render the Sentry ErrorBoundary's children in their initial state */
          }
          resetError();
        }}>
        Click here to dismiss this message and continue!
      </ALButton>
    </div>
  );
}

export default ErrorBoundaryContent;