import React from "react";
import { Link } from "react-router-dom";
import "./HeaderComponent.css"
import { ALButton } from "./ALComponents";

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }
  render() {
    return (
      <div className="header_component">
        <div className="container__navigation">
          <svg width="8px" height="16px" viewBox="0 0 8 16" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
              <g transform="translate(-270.000000, -101.000000)" stroke="#000000">
                <g transform="translate(270.000000, 102.000000)">
                  <polyline id="Path-2" points="8 0 0 7 8 14"></polyline>
                </g>
              </g>
            </g>
          </svg>
          <Link to={(this.props.to) ? this.props.to : '/a/dashboard'}>Back {(this.props.toTitle) ? `to ${this.props.toTitle}` : ''}</Link>
        </div>
        <div className={`container__header ${this.props.howToModal ? "" : "container__header--no_how_to"}`}>
          <h1>{(this.props.title) ? this.props.title : ''}</h1>
        </div>
        {this.props.howToModal ? <div className="container__how_to"><ALButton type="outlined" material_icon="description" onClick={this.props.howToModal}/></div> : null}
      </div>
    );
  }
}

export default HeaderComponent;