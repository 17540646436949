import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers"; //reducers/index.js
import reduxThunk from 'redux-thunk';
import NotificationContainer from "./components/al_components/notification/NotificationContainer";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: "https://715d2b17b42e46cba93463fb55d46926@o195067.ingest.sentry.io/5606233",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1,
  sampleRate: 1,
});

const store = createStore(reducers, compose(applyMiddleware(reduxThunk), sentryReduxEnhancer));
ReactDOM.render(
    <Provider store={store}>
      <App />
      <NotificationContainer />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
