import React from 'react';

class Empty extends React.Component {

  render() {
    return (
      <div className="">
      </div>
    )
  }
}

export default Empty;