import React from "react";
import axios from "axios";
import _ from "lodash";
import "./Search.css";
import SearchResults from "./SearchResults";
import SearchOptions from "./SearchOptions";
import Loading from "../Loading";
import { ALDropdown } from "../ALComponents";

// import moment from 'moment';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function filterESQuery(query) {
  if (!query || Object.keys(query).length === 0) {
    return null;
  }
  let newQuery = Object.keys(query).reduce((acc, key) => {
    let newValue = null;
    if (Array.isArray(query[key])) {
      newValue = query[key].map((v) => filterESQuery(v)).filter((v) => v);
    } else if (typeof query[key] === "object") {
      newValue = filterESQuery(query[key]);
    } else {
      newValue = query[key];
    }

    if (!newValue || (typeof newValue === "object" && Object.keys(newValue).length === 0)) {
      return acc;
    }
    acc[key] = newValue;
    return acc;
  }, {});
  if (!newQuery || Object.keys(newQuery).length === 0) {
    return null;
  }
  return newQuery;
}

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      youtubeSearch: {
        hasAccount: true,
        engagementMinimum: "",
        engagementMaximum: "",
        viewsVideoMinimum: "",
        viewsVideoMaximum: "",
      },
      instagramSearch: {
        hasAccount: false,
        engagementMinimum: "",
        engagementMaximum: "",
        followersMinimum: "",
        followersMaximum: "",
      },
      influencerSearch: {
        tags: [],
        // tagsBeta: "",
        tagsMode: "Or",
        campaigns: [],
        campaignsMode: "Or",
        countries: [],
        gender: [],
        ageMinimum: "",
        ageMaximum: "",
      },
      dealSearch: {
        workedWithBefore: "All", // "No",
        numberOfDealMinimum: "",
        numberOfDealMaximum: "",
        priceMinimum: "",
        priceMaximum: "",
        daysSinceLastDealMinimum: "",
        daysSinceLastDealMaximum: "",
        mostRecentStatus: "",
        atLeastOneStatus: [],
      },
      influencers: [],
      total: null,
      pageNumber: 1,
      isSearching: false,
      isExporting: false,
      isSorting: false,
      loadingReason: "",
      selectedInfluencers: new Set(),
      selectedSort: null,
    };
    this.sortOptions = [
      {
        id: "",
        name: "Default",
        func: (_a, _b) => 1,
      },
      {
        id: "deal<",
        name: "Number of deals <",
        func: (inf1, inf2) => (inf1.deals || []).length - (inf2.deals || []).length,
      },
      {
        id: "deal>",
        name: "Number of deals >",
        func: (inf1, inf2) => (inf2.deals || []).length - (inf1.deals || []).length,
      },
      {
        id: "country",
        name: "Country A-Z",
        func: (inf1, inf2) => inf1.country.localeCompare(inf2.country),
      },
      {
        id: "age_low",
        name: "Age low",
        func: (inf1, inf2) => (inf1.age.low || 0) - (inf2.age.low || 0),
      },
      {
        id: "age_high",
        name: "Age high",
        func: (inf1, inf2) => (inf1.age.high || 0) - (inf2.age.high || 0),
      },
    ];
    /*this.handleUpdateChannel = this.handleUpdateChannel.bind(this);*/
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
    this.updateInfluencerData = this.updateInfluencerData.bind(this);
    this.loadYTAverageViews = this.loadYTAverageViews.bind(this);
    this.onSortSelection = this.onSortSelection.bind(this);
  }
  componentDidMount() {
    console.log("componentDidMount Search");
  }
  componentDidUpdate() {
    console.log("componentDidUpdate Search");
  }
  onChange(searchObjectName, searchObjectFieldName, value) {
    const realsearchObjectName = searchObjectName + "Search";
    console.log("realsearchObjectName:", realsearchObjectName);
    console.log("searchObjectFieldName:", searchObjectFieldName);
    console.log("value:", value);
    // , [searchObjectFieldName]: value}
    this.setState((state) => ({ [realsearchObjectName]: { ...state[realsearchObjectName], [searchObjectFieldName]: value } }));
  }
  // queryCreator(currentQuery, newObject) {}
  updateInfluencerData(index, update) {
    this.setState((state) => {
      let updateInfluencers = [...state.influencers];
      updateInfluencers[index] = { ...updateInfluencers[index], ...update };
      return { influencers: updateInfluencers };
    });
  }
  async loadYTAverageViews(influencer) {
    try {
      if (typeof influencer.youtube.accounts[0] === "string") {
        const { data } = await axios.get(`/api/v1/youtube/channel/${influencer.youtube.accounts[0]}`);
        influencer.youtube.accounts[0] = data.channel || influencer.youtube.accounts[0];
      }
    } catch (err) {
      console.error(err);
    }
    let channel = influencer?.youtube.accounts[0];
    if (typeof channel === "object" && channel["latestVideos"] && !channel["latestVideos"]["averageViews"]) {
      if (!channel["latestVideos"]["areVideosLoaded"]) {
        const { data } = await axios.get(`/api/v1/youtube/channel/videos/${channel.channelId}`, { params: { no_scrap: true } });
        channel["latestVideos"]["list"] = channel["latestVideos"]["list"].map(
          (videoId) => data.videos.find((v) => typeof v === "object" && v.videoId === videoId) || videoId
        );
        channel["latestVideos"]["areVideosLoaded"] = true;
      }
      let sum = 0;
      let count = 0;
      channel["latestVideos"]["list"].forEach((video) => {
        if (typeof video === "object" && video.stats) {
          sum += video.stats.views;
          count += 1;
        }
      });
      channel["latestVideos"]["averageViews"] = count ? (sum / count).toFixed(1) : "0";
      channel["latestVideos"]["averageViewsCount"] = count;
      this.setState((state) => ({
        influencers: state.influencers.map((inf) => (inf._id === influencer._id ? { ...inf, youtube: influencer.youtube } : inf)),
      }));
    }
    return influencer;
  }
  async loadDeal(influencer, index) {
    const absoluteIndex = index < 0 ? influencer.deals.length + index : index;
    if (influencer.deals[absoluteIndex]._id) return;
    const { data } = await axios.get(`/api/v1/influencers/${influencer._id}/deals/${influencer.deals[absoluteIndex].id}`);
  }
  onSortSelection(id) {
    const { isSorting, isSearching, isExporting } = this.state;
    if (isSorting || isSearching || isExporting) return;
    this.setState({ selectedSort: id, loadingReason: "Sorting...", isSorting: true });
    const selectedOption = this.sortOptions.find((o) => o.id === id);
    this.setState((state) => {
      const newInfluencers = [...state.influencers].sort(selectedOption.func);
      return { influencers: newInfluencers, isSorting: false, loadingReason: "" };
    });
  }
  async search() {
    const { youtubeSearch, instagramSearch, influencerSearch, dealSearch, isSearching, isExporting } = this.state;
    let youtubeQuery = {};
    let instagramQuery = {};
    let influencerQuery = {};
    let dealQuery = {};

    if (isSearching || isExporting) return;
    this.setState({ isSearching: true, loadingReason: "Searching influencers..." });
    // let tagsBeta = influencerSearch.tagsBeta
    // if (tagsBeta) {
    //   const { data: tags } = await axios.get("/api/v1/tags");
    //   const { data: campaigns } = await axios.get("/api/v1/campain_tags");
    //   const tagReducer = (key, value) => (acc, v) => {
    //     acc[v[key].toLowerCase()] = v[value];
    //     return acc;
    //   };

    //   const tagsMapNameId = tags.tags.reduce(tagReducer("name", "_id"), {});
    //   const campaignsMapNameId = campaigns.tags.reduce(tagReducer("name", "_id"), {});

    //   tagsBeta = tagsBeta.toLowerCase();
    //   Object.keys(tagsMapNameId).forEach(tagName => {
    //     tagsBeta = tagsBeta.replaceAll(tagName, tagsMapNameId[tagName]);
    //   });
    // }
    influencerQuery = {
      "query": {
        "bool": {
          "must": [
            // { "query_string": {
            //   "query": tagsBeta,
            //   "default_field": "tags.id"
            // }},
            // { "term": { "tags.id": influencerSearch.tags.length > 0 ? influencerSearch.tags[0] : null } },
            // { "term": { "campain_tags.id": influencerSearch.campaigns.length > 0 ? influencerSearch.campaigns[0] : null } },
            {
              "bool": {
                [influencerSearch.tagsMode === "Or" ? "should" : "must"]: influencerSearch.tags.map((tag) => ({ "match": { "tags.id": tag } })),
              },
            },
            {
              "bool": {
                [influencerSearch.campaignsMode === "Or" ? "should" : "must"]: influencerSearch.campaigns.map((campaign) => ({ "match": { "campain_tags.id": campaign } })),
              },
            },

            // { "match": { "country": influencerSearch.countries.length > 0 ? influencerSearch.countries[0] : null } },
            {
              "bool": {
                "should": influencerSearch.countries.map((country) => ({ "match": { "country": country } })),
              },
            },

            {
              "bool": {
                "should": influencerSearch.gender.map((gender) => {
                  if (gender === "Unknown") return { "bool": { "must_not": { "exists": { "field": "gender.value" } } } };
                  return { "match": { "gender.value": gender } };
                }),
              },
            },
            // { "match": { "gender.value": influencerSearch.gender.map((gender) => (gender === "Unknown" ? [null, ""] : [gender])).flat() } },

            { "exists": { "field": dealSearch.workedWithBefore === "Yes" ? "deals" : null } },
            { "exists": { "field": instagramSearch.hasAccount ? "instagram.accounts" : null } },
            { "exists": { "field": youtubeSearch.hasAccount ? "youtube.accounts" : null } },
            { "range": { "age.low": { "gte": influencerSearch.ageMinimum ? parseInt(influencerSearch.ageMinimum) : null } } },
            { "range": { "age.high": { "lte": influencerSearch.ageMaximum ? parseInt(influencerSearch.ageMaximum) : null } } },
            {
              "range": {
                "deals.creation_date": { "gte": dealSearch.daysSinceLastDealMaximum ? `now-${dealSearch.daysSinceLastDealMaximum}d` : null },
              },
            },
          ],
          "must_not": [
            { "exists": { "field": dealSearch.workedWithBefore === "No" ? "deals" : null } },
            { "exists": { "field": instagramSearch.hasAccount ? null : "instagram.accounts" } },
            { "exists": { "field": youtubeSearch.hasAccount ? null : "youtube.accounts" } },
            {
              "range": {
                "deals.creation_date": { "gte": dealSearch.daysSinceLastDealMinimum ? `now-${dealSearch.daysSinceLastDealMinimum}d` : null },
              },
            },
          ],
          "filter": {
            "script": {
              "script": [
                dealSearch.workedWithBefore !== "No" && dealSearch.numberOfDealMinimum
                  ? `doc['deals.id.keyword'].length >= ${dealSearch.numberOfDealMinimum} `
                  : null,
                dealSearch.workedWithBefore !== "No" && dealSearch.numberOfDealMaximum
                  ? `doc['deals.id.keyword'].length <= ${dealSearch.numberOfDealMaximum} `
                  : null,
              ]
                .filter((v) => v)
                .join(" && "),
            },
          },
        },
      },
    };
    influencerQuery = filterESQuery(influencerQuery);
    console.log("influencerQuery", influencerQuery);

    dealQuery = {
      "query": {
        "bool": {
          "must": [
            {
              "range": {
                "price": {
                  "gte": dealSearch.priceMinimum ? parseInt(dealSearch.priceMinimum) : null,
                  "lte": dealSearch.priceMaximum ? parseInt(dealSearch.priceMaximum) : null,
                },
              },
            },
            { "bool": { "should": dealSearch.atLeastOneStatus.map((status) => ({ "term": { "status": status } })) } },
          ],
        },
      },
    };
    dealQuery = filterESQuery(dealQuery);
    console.log("dealQuery", dealQuery);

    // Youtube
    // influencerQuery = this.queryCreator(influencerQuery);
    // influencerQuery["youtube.accounts"] = instagramSearch.hasAccount
    //   ? { "exists": { "field": "youtube.accounts" } }
    //   : { "must_not": { "exists": { "field": "youtube.accounts" } } };
    // // Instagram
    // influencerQuery["instagram.accounts"] = instagramSearch.hasAccount
    //   ? { "exists": { "field": "instagram.accounts" } }
    //   : { "must_not": { "exists": { "field": "instagram.accounts" } } };

    // // Influencer
    // if (influencerSearch.tags.length > 0) influencerQuery["tags"] = { "match": { "tags.id": "5f7cc7eedca3b5c076e678a6" } };
    // if (dealSearch.workedWithBefore !== "All")
    //   influencerQuery["deals"] =
    //     dealSearch.workedWithBefore === "Yes" ? { "exists": { "field": "deals" } } : { "must_not": { "exists": { "field": "deals" } } };

    // if (dealSearch.workedWithBefore !== "All")
    //   influencerQuery["deals"] = dealSearch.workedWithBefore === "Yes" ? { $exists: true, $ne: [] } : { $eq: [] };
    // if (dealSearch.numberOfDealMinimum) influencerQuery[`deals.${parseInt(dealSearch.numberOfDealMinimum) - 1}`] = { $exists: true };
    // if (dealSearch.numberOfDealMaximum) influencerQuery[`deals.${parseInt(dealSearch.numberOfDealMaximum)}`] = { $exists: false };
    // if (influencerSearch.tags.length > 0) influencerQuery["tags"] = { $all: influencerSearch.tags.map((tag) => ({ id: tag })) };
    // if (influencerSearch.campaigns.length > 0) influencerQuery["campain_tags"] = { $all: influencerSearch.tags };
    // if (influencerSearch.countries.length > 0)
    //   influencerQuery["country"] = influencerSearch.countries.length > 1 ? { $in: influencerSearch.countries } : influencerSearch.countries[0];
    // if (influencerSearch.ageMinimum) influencerQuery["age.low"] = { $gte: parseInt(influencerSearch.ageMinimum) };
    // if (influencerSearch.ageMaximum) influencerQuery["age.high"] = { $lte: parseInt(influencerSearch.ageMaximum) };
    // if (influencerSearch.gender.length > 0)
    //   influencerQuery["gender.value"] = { $in: influencerSearch.gender.map((gender) => (gender === "Unknown" ? [null, ""] : [gender])).flat() };
    // TODO: daysSinceLastDeal
    // Deal

    // if (Object.keys(youtubeQuery).length == 0) youtubeQuery = null;
    // if (Object.keys(instagramQuery).length == 0) instagramQuery = null;
    // if (Object.keys(influencerQuery).length == 0) influencerQuery = null;
    // if (Object.keys(dealQuery).length == 0) dealQuery = null;

    let body = {
      youtube: youtubeQuery,
      instagram: instagramQuery,
      influencer: influencerQuery,
      deal: dealQuery,
    };
    console.log(body);
    // return;
    console.log("Sending...");
    try {
      // do {
      //   this.setState({ loadingReason: data.message });
      //   let result = await axios.post("/api/v1/search", body);
      //   let data = result.data;
      // } while (data.status === "processing");
      let result = await axios.post("/api/v1/search", body);
      let data = result.data;
      while (data.status === "processing") {
        this.setState({ loadingReason: data.result.message });
        await sleep(1500);
        result = await axios.post("/api/v1/search", body);
        data = result.data;
      }
      if (data.status === "error") {
        this.setState({ loadingReason: "ERROR." });
        setTimeout(() => this.setState({ isSearching: false, loadingReason: "" }));
        return;
      }
      this.setState({
        influencers: data.result.influencers,
        total: data.result.total,
        pageNumber: 1,
        isSearching: false,
        loadingReason: "",
        selectedInfluencers: new Set(),
      });
    } catch (err) {
      // TODO: Error notification
      console.error(err);
      this.setState({ isSearching: false, loadingReason: "" });
    }
  }
  // async export(dataList) {
  async export() {
    const { influencers, selectedInfluencers, isExporting } = this.state;
    // if (isExporting || !dataList) return;
    if (isExporting) return;
    this.setState({ isExporting: true, loadingReason: "Preparing export..." });
    const { data: tags } = await axios.get("/api/v1/tags");
    const { data: campaigns } = await axios.get("/api/v1/campain_tags");
    const tagReducer = (key, value) => (acc, v) => {
      acc[v[key].toLowerCase()] = v[value];
      return acc;
    };

    // const tagsMapNameId = tags.tags.reduce(tagReducer("name", "_id"), {});
    // const campaignsMapNameId = campaigns.tags.reduce(tagReducer("name", "_id"), {});
    if (influencers.length < 2000) {
      const batchSize = 30;
      const influencersChunked = _.chunk(influencers, batchSize);
      for (let batchIndex = 0; batchIndex < influencersChunked.length; batchIndex++) {
        const chunk = influencersChunked[batchIndex];
        const updatedInfluencers = await Promise.all(chunk.map((inf) => selectedInfluencers.size && selectedInfluencers.has(inf._id) && this.loadYTAverageViews(inf) || null));
        updatedInfluencers.forEach((updatedInf, idx) => {
          influencers[batchSize * batchIndex + idx] = { ...influencers[batchSize * batchIndex + idx], ...updatedInf };
        });
        this.setState({
          loadingReason: `Calculating average views... (${batchSize * batchIndex + updatedInfluencers.length}/${influencers.length})`,
        });
      }
      this.setState({ loadingReason: "Preparing export..." });
    }
    // loadYTAverageViews(influencer)

    const tagsMapIdName = tags.tags.reduce(tagReducer("_id", "name"), {});
    const campaignsMapIdName = campaigns.tags.reduce(tagReducer("_id", "name"), {});
    const metaStringArray = (e) => (e === null || e === "null" ? "" : e.join("\n"));
    const metaAccountArray = (e, idField) => {
      if (e === null || e === "null") return "";
      return e.map((account) => (typeof account === "string" ? account : account[idField])).join("\n");
    };
    let headerFields = [
      { key: "emails", name: "Emails", metaFun: metaStringArray },
      { key: "paypal_email", name: "Paypal email" },
      { key: "youtube.accounts", name: "YouTube", metaFun: (e) => metaAccountArray(e, "channelId") },
      { key: "instagram.accounts", name: "Instagram", metaFun: (e) => metaAccountArray(e, "profileId") },
      { key: "country", name: "Country" },
      { key: "deals", name: "Number of deals", metaFun: (e) => e.length },
      { key: "gender", name: "Gender", metaFun: (e) => (e && e.value) || "" },
      { key: "age", name: "Age", metaFun: (e) => (e && (e.exact || (e.low || e.high ? `${e.low || "?"}-${e.high || "?"}` : ""))) || "" },
      { key: "tags", name: "Tags", metaFun: (e) => (e && e.map((tag) => tagsMapIdName[tag.id]).join("\n")) || "" },
      { key: "campain_tags", name: "Campaign Tags", metaFun: (e) => (e && e.map((tag) => campaignsMapIdName[tag.id]).join("\n")) || "" },
      {
        key: "notes",
        name: "Influencer Notes",
        metaFun: (e) => (e && e.map((note) => note.content.replaceAll('"', "''").replaceAll(";", " ")).join("\n")) || "",
      },
      { key: "keyword", name: "Keyword" },
      { key: "status", name: "Influencer status" },
    ];
    if (influencers.length < 2000) {
      headerFields.push(
        {
          key: "youtube.accounts",
          name: "Average Views (Youtube first channel)",
          metaFun: (e) => e[0] && e[0]["latestVideos"] && e[0]["latestVideos"]["averageViews"],
        } || ""
      );
      headerFields.push(
        {
          key: "youtube.accounts",
          name: "Videos count for Average Views",
          metaFun: (e) => e[0] && e[0]["latestVideos"] && e[0]["latestVideos"]["averageViewsCount"],
        } || ""
      );
    }

    const header = headerFields.map((e) => e.name).join(",") + "\r\n";
    const content = influencers
      .map((influencer) => {
        if (selectedInfluencers.size && !selectedInfluencers.has(influencer._id)) return null;
        const line = headerFields.map(({ key, metaFun }) => {
          const object = _.get(influencer, key, "");
          if (metaFun) {
            return `"${metaFun(object)}"`;
          }
          return object === null || object === "null" ? '""' : `"${object}"`;
        });
        return line.join(",") + "\r\n";
      })
      .filter((e) => e !== null)
      .join("");
    let filename = "SearchInfluencers" + "_" + new Date().toISOString() + ".csv";
    let contentType = "text/csv;charset=utf-8;";
    let stringFile = header + content;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(stringFile))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href = "data:" + contentType + "," + encodeURIComponent(stringFile);
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    this.setState({ isExporting: false, loadingReason: "" });
  }
  render() {
    const {
      youtubeSearch,
      instagramSearch,
      influencerSearch,
      dealSearch,
      influencers,
      total,
      pageNumber,
      isSearching,
      isExporting,
      isSorting,
      loadingReason,
      selectedInfluencers,
      selectedSort,
    } = this.state;
    const influencersPage = influencers.slice((pageNumber - 1) * 10, pageNumber * 10);

    return (
      <div className="influencer-search">
        <SearchOptions youtube={youtubeSearch} instagram={instagramSearch} influencer={influencerSearch} deal={dealSearch} onChange={this.onChange} />

        <div className="influencer_search__search-button-container">
          {/* <button
            className="influencer_search__search-button"
            onClick={() => {
              this.props
                .showModal("ModalSearchExport")
                .then((result) => this.export(result))
                .catch((err) => console.error(err));
            }}>
            TEST MODAL
          </button> */}
          <button className="influencer_search__search-button" onClick={this.search}>
            SEARCH
          </button>
        </div>

        {isSearching || isExporting || isSorting ? (
          <div className="influencer_search__loading">
            <Loading />
            <p className="influencer_search__loading__reason">{loadingReason || "Please wait..."}</p>
          </div>
        ) : null}

        {influencers.length ? (
          <>
            <div className="influencer_search__page-info">
              <div className="influencer_search__page-info__number">
                {(pageNumber - 1) * 10 + 1}-{(pageNumber - 1) * 10 + influencersPage.length} of{" "}
                {total && total.relation === "eq" ? total.value : `more than ${total.value}` || 0}
              </div>
              <div className="influencer_search__page-info__nav">
                <span className="material-icons" onClick={() => (pageNumber > 1 ? this.setState({ pageNumber: pageNumber - 1 }) : null)}>
                  chevron_left
                </span>
                <span
                  className="material-icons"
                  onClick={() =>
                    (pageNumber - 1) * 10 + influencersPage.length < influencers.length ? this.setState({ pageNumber: pageNumber + 1 }) : null
                  }>
                  chevron_right
                </span>
              </div>
            </div>

            <div className="influencer_search__tool-line">
              <div className="influencer_search__tool-line-left">
                <input
                  className="search-results__influencer__checkbox"
                  type="checkbox"
                  checked={influencersPage.every((influencer) => selectedInfluencers.has(influencer._id))}
                  onClick={() => {
                    if (influencersPage.every((influencer) => selectedInfluencers.has(influencer._id))) {
                      return this.setState(({ selectedInfluencers }) => {
                        let newSelectedInfluencers = new Set(selectedInfluencers);
                        influencersPage.forEach((influencer) => newSelectedInfluencers.delete(influencer._id));
                        return { selectedInfluencers: newSelectedInfluencers };
                      });
                    }
                    this.setState(({ selectedInfluencers }) => {
                      let newSelectedInfluencers = new Set(selectedInfluencers);
                      influencersPage.forEach((influencer) => newSelectedInfluencers.add(influencer._id));
                      return { selectedInfluencers: newSelectedInfluencers };
                    });
                  }}
                />
                <button
                  className="influencer_search__tool-line__export"
                  onClick={() => {
                    this.export();
                    // this.props
                    //   .showModal("ModalSearchExport")
                    //   .then((result) => this.export(result))
                    //   .catch((err) => console.error(err));
                  }}>
                  EXPORT{" "}
                  {selectedInfluencers.size
                    ? `${selectedInfluencers.size} SELECTED`
                    : influencers.length === total.value
                    ? "ALL"
                    : `FIRST ${influencers.length}`}
                </button>
              </div>
              <div className="influencer_search__tool-line-right">
                <div className="influencer_search__tool-line-sort__title">Sort by</div>
                <ALDropdown
                  options={this.sortOptions}
                  useValue={"name"}
                  useKey={"id"}
                  value={selectedSort}
                  onChange={this.onSortSelection}
                  style={{
                    "dropdown_container__selected_value": "search-options__dropdown-value",
                    "dropdown_container__list": "search-options__dropdown-list",
                  }}
                />
              </div>
            </div>

            <SearchResults
              influencers={influencersPage}
              page={pageNumber}
              updateInfluencerData={this.updateInfluencerData}
              selectedInfluencers={selectedInfluencers}
              loadYTAverageViews={this.loadYTAverageViews}
              select={(id) => {
                if (selectedInfluencers.has(id)) {
                  return this.setState(({ selectedInfluencers }) => {
                    let newSelectedInfluencers = new Set(selectedInfluencers);
                    newSelectedInfluencers.delete(id);
                    return { selectedInfluencers: newSelectedInfluencers };
                  });
                }
                this.setState(({ selectedInfluencers }) => {
                  let newSelectedInfluencers = new Set(selectedInfluencers);
                  newSelectedInfluencers.add(id);
                  return { selectedInfluencers: newSelectedInfluencers };
                });
              }}
            />
            <div className="influencer_search__footer-pagination">
              <span
                className="material-icons influencer_search__footer-pagination__chevron"
                onClick={() => (pageNumber > 1 ? this.setState({ pageNumber: pageNumber - 1 }) : null)}>
                chevron_left
              </span>
              <div className="influencer_search__footer-pagination__page-numbers">
                {(() => {
                  const totalPages = Math.floor(influencers.length / 10) + (influencers.length % 10 !== 0 ? 1 : 0);
                  let numbers = [];
                  if (pageNumber < 4) {
                    for (let i = 1; i <= totalPages; i++) {
                      if (i <= 4 || i === totalPages) {
                        numbers.push(
                          <div
                            key={i}
                            className={`influencer_search__footer-pagination__page-numbers__cell${pageNumber === i ? "--selected" : ""}`}
                            onClick={() => this.setState({ pageNumber: i })}>
                            {i}
                          </div>
                        );
                      } else if (i === 5 && totalPages > 5) {
                        numbers.push(
                          <div key={i} className="influencer_search__footer-pagination__page-numbers__cell--unselectable">
                            ...
                          </div>
                        );
                      } else {
                        i = totalPages - 1;
                      }
                    }
                  } else if (pageNumber > totalPages - 4) {
                    for (let i = 1; i <= totalPages; i++) {
                      if (i >= totalPages - 4 || i === 1) {
                        numbers.push(
                          <div
                            key={i}
                            className={`influencer_search__footer-pagination__page-numbers__cell${pageNumber === i ? "--selected" : ""}`}
                            onClick={() => this.setState({ pageNumber: i })}>
                            {i}
                          </div>
                        );
                      } else if (i === 2) {
                        numbers.push(
                          <div key={i} className="influencer_search__footer-pagination__page-numbers__cell--unselectable">
                            ...
                          </div>
                        );
                        i = totalPages - 5;
                      }
                    }
                  } else {
                    for (let i = 1; i <= totalPages; i++) {
                      if (i === 1 || i === totalPages || (i >= pageNumber - 1 && i <= pageNumber + 1)) {
                        numbers.push(
                          <div
                            key={i}
                            className={`influencer_search__footer-pagination__page-numbers__cell${pageNumber === i ? "--selected" : ""}`}
                            onClick={() => this.setState({ pageNumber: i })}>
                            {i}
                          </div>
                        );
                      } else if (i === pageNumber - 2 || i === pageNumber + 2) {
                        numbers.push(
                          <div key={i} className="influencer_search__footer-pagination__page-numbers__cell--unselectable">
                            ...
                          </div>
                        );
                      }
                    }
                  }
                  return numbers;
                })()}
              </div>
              <span
                className="material-icons influencer_search__footer-pagination__chevron"
                onClick={() =>
                  (pageNumber - 1) * 10 + influencersPage.length < influencers.length ? this.setState({ pageNumber: pageNumber + 1 }) : null
                }>
                chevron_right
              </span>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default Search;
