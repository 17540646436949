import React from "react";
import "./ModalQualificationHistory.css";
import { ALButton, ALContainer, ALTable } from "./ALComponents";

import iconInsta from '../../src/assets/insta_logo.png';
import iconInstaDisable from '../../src/assets/insta--disabled.png';

import iconYouTube from '../../src/assets/youtube_logo.png';
import iconYouTubeDisable from '../../src/assets/youtube_logo_grey.png';

const ModalHead = (props) => (
  <div className="modal-head">
    <div className="modal-head__line">
      <h1 className="modal-head__line__title">History</h1>
      <div className="modal-head__line__button">
        <ALButton type="text" material_icon="clear" onClick={props.close} />
      </div>
    </div>
    {/* <hr className="modal-head__separator" /> */}
  </div>
);

const ModalFoot = (props) => (
  <div className="modal-foot">
    <ALButton type="text" material_icon="clear" onClick={props.close}>
      Back
    </ALButton>
  </div>
);

const RoundedButton = (props) => (
  <button onClick={props.onClick} className={"qualification_history-modal__rounded-button" + (props.selected ? "--selected" : "")}>
      {props.selected ? <i className="material-icons qualification_history-modal__rounded-button__icon">check_circle_outline</i> : null}
      <div className="qualification_history-modal__rounded-button__content">{props.children}</div>
  </button>
);

class ModalQualificationHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: props.history
    };
    this.actionNames = {
      "no": "No",
      "youtube": "YouTube",
      "instagram": "Instagram",
      "youtube_instagram": "YT & IG",
      "co_creation": "CO-CREATION"
    };
  }
  render() {
    const { history } = this.state;
    return (
      <ALContainer className="qualification_history-modal">
        <ModalHead close={() => this.props.resume()} />
        <div className="qualification_history-modal__content">
          <p>You can modify bellow your 30 previous actions:</p>
          <ALTable
            header={["", "Name", "", "Decision", "Actions"]}
            content={history.map((history) => {
              const instagram = history.instagram ? history.instagram[0] : null;
              const youtube = history.youtube ? history.youtube[0] : null;

              return [
              <img style={{borderRadius: "100%"}} alt="profile" crossOrigin="anonymous" referrerPolicy="no-referrer"  src={history.thumbnail} width="50px" />,
              history.name,
              <div className="qualification_history-modal__platform_link_container">
                <a href={youtube && `https://www.youtube.com/channel/${youtube}`} target="_blank" title="Go to channel" rel="noopener noreferrer">
                  <img className="qualification_history-modal__platform_link" src={(youtube) ? iconYouTube : iconYouTubeDisable} alt="youtube" />
                </a>
                <a href={instagram && `https://www.instagram.com/${instagram}`} target="_blank" title="Go to profile" rel="noopener noreferrer">
                  <img className="qualification_history-modal__platform_link" src={(instagram) ? iconInsta : iconInstaDisable} alt="insta" />
                </a>
              </div>,
              <RoundedButton selected={true}>{this.actionNames[history.decision]}</RoundedButton>,
              <div style={{ display: "flex" }}>
                {history.actions.map((action, idx) => {
                  if (action == "co_creation") {
                    return (<div style={{ margin: "10px" }} key={idx}>
                    {/* <div className="qualification_history-modal__co_creation_button" onClick={async () => this.setState({history: await this.props.actions[action](history)})}>{this.actionNames[action]}</div> */}
                    <ALButton  onClick={async () => this.setState({history: await this.props.actions[action](history)})}>{this.actionNames[action]}</ALButton>
                  </div>)
                  } else {
                    return (<div style={{ margin: "10px" }} key={idx}>
                    <RoundedButton onClick={async () => this.setState({history: await this.props.actions[action](history)})}>{this.actionNames[action]}</RoundedButton>
                  </div>)
                  }
                })}
              </div>,
            ]
            })}
            // content={history.map((history) => [
            //   (<a href={`https://www.youtube.com/channel/${history.id}`} target="_blank" title="Go to channel" rel="noopener noreferrer">
            //     <img alt="profile" crossOrigin="anonymous" referrerPolicy="no-referrer"  src={history.thumbnail} width="50px" />
            //   </a>),
            //   history.id,
            //   history.name,
            //   history.decision,
            //   <div style={{ display: "flex" }}>
            //     {history.actions.map((action, idx) => (
            //       <div style={{ margin: "10px" }} key={idx}>
            //         <ALButton onClick={async () => this.setState({history: await this.props.actions[action](history)})}>{action}</ALButton>
            //       </div>
            //     ))}
            //   </div>,
            // ])}
            column_style={[{width: "100px"}, { width: "170px" }, { width: "120px" }, { width: "100px" }, { width: "400px" }]}
          />
          <>{history.length ? null : "No history available yet."}</>

          <ModalFoot close={() => this.props.resume()} />
        </div>
      </ALContainer>
    );
  }
}

export default ModalQualificationHistory;
