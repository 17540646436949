import React from "react";
import axios from 'axios';
import Loading from "../Loading";
import InstaSwipe from "./InstaSwipe";
import "./InstagramYesNo.css";
import HeaderComponent from "../HeaderComponent";
import { storageAvailable } from '../../utils';
import { ALButton } from '../ALComponents';

// import moment from 'moment';

class InstagramYesNo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loadingData: true,
      numberProfilYesNo: null,
      haveLocalStorage: storageAvailable('localStorage'),
    };
    this.HISTORY_KEY = "ig_yes-no_history";
    /*this.handleUpdateChannel = this.handleUpdateChannel.bind(this);*/
    this.handleHistoryNo = this.handleHistoryNo.bind(this);
    this.handleHistoryYes = this.handleHistoryYes.bind(this);
  }
  componentDidMount() {
    // Init local storage for History
    if (!localStorage.getItem(this.HISTORY_KEY)) {
      localStorage.setItem(this.HISTORY_KEY, JSON.stringify([]));
    }

    axios({
      method: 'GET',
      url: '/api/v1/insta/length_inv',
    }).then((r) => {
      this.setState({ numberProfilYesNo: r.data.count || 0 });
    });
    axios({
      method: 'GET',
      url: '/api/v1/insta_inv'
    }).then((r) => {
      console.log(r);
      this.setState({ loadingData: false, data: r.data.instagram });
    });
  }

  componentDidUpdate() {
    console.log('componentDidUpdate');
    if (this.state.data && this.state.data.length < 5 && this.state.numberProfilYesNo > 5) {
      axios({
        method: 'GET',
        url: '/api/v1/insta/length_inv',
      }).then((r) => {
        this.setState({ numberProfilYesNo: r.data.count || 0 });
      });
      axios({
        method: 'GET',
        url: '/api/v1/insta_inv'
      }).then((r) => {
        console.log(r);
        // console.log(this.state.data);
        // console.log(r.data.instagram);
        // console.log([...this.state.data, ...r.data.instagram]);
        this.setState({ data: [...this.state.data, ...r.data.instagram] });
      });
    }
  }

  addHistory(profile, action) {
    const actions = ["Yes", "No"];
    let history = JSON.parse(localStorage.getItem(this.HISTORY_KEY)) || [];
    const new_history_obj = {
      thumbnail: profile.profilPicture,
      id: profile.handle,
      name: profile.handle,
      decision: action,
      actions: actions.filter(e => e !== action),
    };
    history.unshift(new_history_obj);
    history = history.reduce((acc, e) => !acc.find(new_e => new_e.id === e.id) ? [...acc, e] : acc, []);
    history = history.slice(0, 30);
    localStorage.setItem(this.HISTORY_KEY, JSON.stringify(history));
    return history;
  }
  updateHistory(profile, action) {
    const actions = ["Yes", "No"];
    let history = JSON.parse(localStorage.getItem(this.HISTORY_KEY));
    const idx = history.findIndex((e) => e.id === profile.id);
    const new_history_obj = {
      ...history.splice(idx, 1)[0],
      decision: action,
      actions: actions.filter(e => e !== action)
    };
    history.unshift(new_history_obj);
    localStorage.setItem(this.HISTORY_KEY, JSON.stringify(history));
    return history;
  }

  async processYes(insta) {
    // return // DEVELOPEMENT ONLY
    let updateInsta = {}
    updateInsta['status'] = 'toOutreach';
    console.log(updateInsta);

    try {
      const r = await axios({
        method: 'put',
        url: `/api/v1/instagram/${insta.handle}`,
        data: updateInsta
      });
      if (r.status === 200) {
        return;
      } else {
        alert("CALL FRANCOIS (ERROR: co-2501)");
      }
    } catch (err) {
      alert("CALL FRANCOIS (ERROR: co-2502):" + err);
    }
  }

  async processNo(insta) {
    // return // DEVELOPEMENT ONLY
    let updateInsta = {}
    updateInsta['status'] = 'declined';
    updateInsta['statusReason'] = 'declined yes or no';
    console.log(updateInsta);

    try {
      const r = await axios({
        method: 'put',
        url: `/api/v1/instagram/${insta.handle}`,
        data: updateInsta
      });
      if (r.status === 200) {
        return;
      } else {
        alert("CALL FRANCOIS (ERROR: co-2501)");
      }
    } catch (err) {
      alert("CALL FRANCOIS (ERROR: co-2502):" + err);
    }
  }

  handleDeclineInsta(insta) {
    let tmpData = this.state.data;

    // remove current channel from channel = []
    let handleToUpdate = tmpData.splice(tmpData.findIndex(function(i){
      return i.handle === insta.handle;
    }), 1);
    console.log(handleToUpdate);
    console.log(tmpData);
    console.log('declined');
    console.log(insta);

    this.processNo(insta)
    .then(() => {
      this.addHistory(insta, "No");
      this.setState({ data: tmpData });
    });

    // TODO: Remove this if things are working correctly
    // let updateInsta = {}
    // updateInsta['status'] = 'declined';
    // updateInsta['statusReason'] = 'declined yes or no';
    // console.log(updateInsta);

    // axios({
    //   method: 'put',
    //   url: `/api/v1/instagram/${insta.handle}`,
    //   data: updateInsta
    // }).then((r) => {
    //   if (r.status === 200) {
    //       this.setState({ data: tmpData });
    //   }
    // });
  }

  handleToOutreachInsta(insta) {
    let tmpData = this.state.data;

    // remove current channel from channel = []
    let handleToUpdate = tmpData.splice(tmpData.findIndex(function(i){
      return i.handle === insta.handle;
    }), 1);
    console.log(handleToUpdate);
    console.log(tmpData);

    console.log('outreach');
    console.log(insta);

    this.processYes(insta)
    .then(() => {
      this.addHistory(insta, "Yes");
      this.setState({ data: tmpData });
    });

    // TODO: Remove this if things are working correctly
    // let updateInsta = {}
    // updateInsta['status'] = 'toOutreach';
    // console.log(updateInsta);

    // axios({
    //   method: 'put',
    //   url: `/api/v1/instagram/${insta.handle}`,
    //   data: updateInsta
    // }).then((r) => {
    //   if (r.status === 200) {
    //     this.setState({ data: tmpData });
    //   }
    // });
  }

  async handleHistoryYes(history_obj) {
    const { data } = await axios.get(`/api/v1/instagram/${history_obj.id}`);
    const insta = data.profils;
    this.processYes(insta);
    const full_history = this.updateHistory(history_obj, "Yes");
    return full_history;
  }
  async handleHistoryNo(history_obj) {
    const { data } = await axios.get(`/api/v1/instagram/${history_obj.id}`);
    const insta = data.profils;
    this.processYes(insta);
    const full_history = this.updateHistory(history_obj, "No");
    return full_history;
  }


  render() {
    const { loadingData, data, numberProfilYesNo, haveLocalStorage } = this.state;
    return (
      <div className="instagram_container instagram_yesno">
        <HeaderComponent title="Yes/No" to="/a/dashboard/instagram" toTitle="Instagram" />
        {/* <div class="instagram_container__navigation">
          <svg width="8px" height="16px" viewBox="0 0 8 16" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
              <g transform="translate(-270.000000, -101.000000)" stroke="#000000">
                <g transform="translate(270.000000, 102.000000)">
                  <polyline id="Path-2" points="8 0 0 7 8 14"></polyline>
                </g>
              </g>
            </g>
          </svg>
          <Link to="/a/dashboard/instagram">Back to Instagram</Link>
        </div>
        <div className="instagram_container__header">
          <h1>Yes/No</h1>
        </div> */}
        <div className="instagram_yesno_content">
        <p className="instagram_yesno_content__result">{(numberProfilYesNo) ? (`${numberProfilYesNo} channels`) : (null)}</p>
        {(loadingData) ? (
          <Loading />
        ) : (
          <>
          {haveLocalStorage ?
            <ALButton type="text" material_icon="history" onClick={() => this.props.showModal("ModalYesNoHistory", {
              history: JSON.parse(localStorage.getItem(this.HISTORY_KEY)),
              actions: {
                "Yes": this.handleHistoryYes,
                "No": this.handleHistoryNo,
              }
            })}>HISTORY</ALButton>
            : <p style={{color: "orangered"}}>Permission to browser's local storage haven't been granted. History will not be available.</p> }


          {(data && data.length > 0) ? (
            data.map((insta) => {
              return (
                <InstaSwipe
                  instagramBase={(insta) ? insta : {}}
                  onDeclined={() => this.handleDeclineInsta(insta)}
                  onOutreach={() => this.handleToOutreachInsta(insta)}
                />
              )
            })
          ) : (
            <p className="instascrap__content--no-profil">There is no Instagram to qualify</p>
          )}
          </>
        )}
        </div>
      </div>

    );
  }
}

export default InstagramYesNo;
