import React from "react";
import { ALContainer, ALButton } from "components/ALComponents";
import TypeformInformation from "./TypeformInformation";
import "./ModalTypeformInformation.css";

const ModalTypeformInformation = ({ typeformResponse, resume }) => {
  return (
    <ALContainer className="modal-typeform-information">
      <ALButton type="text" material_icon="clear" onClick={() => resume("close")} />
      <TypeformInformation typeformResponse={typeformResponse} />
    </ALContainer>
  );
};

export default ModalTypeformInformation;
