import React, { useState, useCallback, useEffect } from "react";
import { format } from "date-fns";
import { GoogleSpreadsheet } from "google-spreadsheet";
import orderBy from "lodash/orderBy";
import axios from "axios";

import { triggerStockTrackerDealUpdate } from "utils";
import { ALDropdown, AutoCompleteInput } from "components/ALComponents";
import NotificationManager from "components/al_components/notification/NotificationManager";
import { TYPEFORM_ID_BY_NAME } from "dictionaries";
import Loading from "components/Loading";

const TYPEFORM_BY_SPREADSHEET = ["youtube", "instagram", "podcast", "tiktok", "bilibili"];

async function getGSheetDoc() {
  const SHIPSTATION_ID = "1M80ApO9V9TUFzsp1uPOxt8iIsUioaWjge6U2DPg45dU";
  const doc = new GoogleSpreadsheet(SHIPSTATION_ID);
  await doc.useServiceAccountAuth({
    client_email: "ship-influencer-oklos@analuisa-api.iam.gserviceaccount.com",
    private_key:
      "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCwlp92f7AH7Vu4\n6beqBJFb2X/tWCB5YjLfOtfXTf/R5iLFGKPxEQXyNQCGdeLbMGKs4IDLyUwmWSri\ncVMShpPUAkLVheeO44qGHZwTKlaAtJ41EHJoypMAP0ZywcAFyErth8T0yhVNG8GJ\n8HDwZhcAxMloRYBDKnvW0RdIyHqG0t0Ib+3neXFdb9asQ/Ew7vO3YXsescSmG6UD\nMWzGA+5O2QuwLD9yq6jda/dNoFCQ5hqO4SlcQIzXP30ztn5eLIG8e4ZDgRZkvUX6\njcJY+WM+4ROCaw7kOcrg5VPtiWgQPp+dwcn2ux/MzISBU6llhyrNREViS21ZS/Ci\nZBkSDfQNAgMBAAECggEAPzW7YOx183B4S6mFb9twuMMTMr8/6JNJCHUEu/IUp2rM\neWbHkEv6p+dnB7p8+N7KA33dPJUSqAt0+Vd9V/1KPzKL/+iNqa0zcax5qLaX/jwp\nAW+gtHgGxKjhq8RE2w0GHR5jVwhbNZ3A2oJxuuugQksumR9/uQslBxETV59DlKye\nDIhhd2gi5dkeTw+57VSHbNar4lpw5V4dBBIH4AtDUOoPlVNJ1oYw0WzWX6PoVKuZ\nh/AntPGIx3URowYQimJnTxNZd0UgzdV9+5QEPcpEeNM7LtXNehpMyNdjCXkGnDhe\nLaN5lOz2ZGUzGCN5Rbi2ihmbaMbGALCQWtro7l/klQKBgQD44oyUqdYzap4MUp0Q\nVvEki+LSuRzW2BNwGz6AvfCiDItya5XoTl1KL9xIJeCCZFKH6B8ySqreRZDUtBXF\nYk3Z9otJXqGVGUkN6B+bWUXbve8RO5LFXwHADs3U+hOoKTG5lLN1z17EtroTBctA\nOUjs+z1hw/hmCeY9Bflvzvt3QwKBgQC1ovmkpkiQvjWbViYFlTnPSTKtDmWheQ4b\nMZ6nshfSnRpsf/9GU9SXflL6ssjJa7qPw7v0w9npLliUAOnPNUKQZ2qu0L5fmMy2\nNesSTLTTNvX1jUZ60yYMbFy0jjYut8bpTyFCgO0IRu61vM5g2CZ+IXgtxAlWefYb\nuV1ywtbqbwKBgQDW3acmVTWSsBIAa3YjfBu1RHsvFxb5uBOX7j1YoiJTlGbzAkSq\nCLVrbcRMGT8o8vl5UYFVRrcbK42sxEgjXwn9CWcYkXUIJos2UVawJDM2UVRKWVcN\nxEbbiyxSGQ/NVrsSD3mbVMrpIyOjdpYRPSNjTEei1q/lT9iQiE7fIx9fxwKBgDY/\nL1nKQ7/e3vm+l+pw7+wFi3n948J++gSLJDwnR1AilYK0E5R4cvSvyK6Q+MCXSQeo\nyJjIaWtn6RKC11GIMZIZINaHB2RbqT/+CG/Vsuel8X1drR//scxiDKnsEyiOwHxr\nop5PDPu824wxkF2CELT44ACjHLrT//m1ucANisxHAoGAHO0cwSs8MPWmXA7xzv0N\nOzBE778AVbjFUVvbPJ2xZxNH2d1nqPOCB5NkJSPdJhuHNvC+0rG4ssCENy6NkgbC\nNmciSZOE6k5Vuin8C6YdaQpOyZvw59UESNoSkNYbnmgcOmjDYRIf9QDQmlMLxDN5\nK2fvtRqQfPYq9lemyuzQqHI=\n-----END PRIVATE KEY-----\n",
  });
  await doc.loadInfo();
  return doc;
}

async function getGSheetData(dealTypeform) {
  const doc = await getGSheetDoc();
  // RAW DATA sheet
  const sheetRAW_DATA = await getSheetRawData(doc);
  const sheetRowsOffset = {
    "instagram": 0,
    "youtube": 0,
    "podcast": 0,
    "tiktok": 0,
    "bilibili": 0,
  };
  const latestRawDataPadding = {
    "instagram": 0,
    "youtube": 10591,
    "podcast": 0,
    "tiktok": 0,
    "bilibili": 0,
  };
  const calculationPadding = {
    "instagram": 0,
    "youtube": 49,
    "podcast": 0,
    "tiktok": 0,
    "bilibili": 0,
  };
  // SHEET FINAL
  const rowsRAW_Data = await sheetRAW_DATA[dealTypeform.platform].getRows({ offset: sheetRowsOffset[dealTypeform.platform] });

  let cleanedRawData = [];
  // remove order already processed
  rowsRAW_Data.forEach((influencerInfo, i) => {
    let influencerInfoParsed = {};
    if (typeof influencerInfo["Address Line 1"] != "undefined" && influencerInfo["Buyer First Name"] !== "#REF!") {
      influencerInfoParsed["full_name"] =
        `[${influencerInfo["_rowNumber"] + latestRawDataPadding[dealTypeform.platform] - calculationPadding[dealTypeform.platform]}] ` +
        influencerInfo["Buyer First Name"] +
        " " +
        influencerInfo["Buyer Last Name"] +
        " (" +
        influencerInfo["Buyer Email"].trim().toLowerCase() +
        ")";
      influencerInfoParsed["date"] = influencerInfo["date"];
      influencerInfoParsed["order_number"] = influencerInfo["order_number"];
      influencerInfoParsed["uid"] = influencerInfo["uid"];
      influencerInfoParsed["Buyer First Name"] = influencerInfo["Buyer First Name"];
      influencerInfoParsed["Buyer Last Name"] = influencerInfo["Buyer Last Name"];
      influencerInfoParsed["Buyer Email"] = influencerInfo["Buyer Email"].trim().toLowerCase();
      influencerInfoParsed["Buyer Phone"] = influencerInfo["Buyer Phone"];
      influencerInfoParsed["Buyer Username"] = influencerInfo["Buyer Username"];
      influencerInfoParsed["Recipient Full Name"] = influencerInfo["Recipient Full Name"];
      influencerInfoParsed["Recipient First Name"] = influencerInfo["Recipient First Name"];
      influencerInfoParsed["Recipient Last Name"] = influencerInfo["Recipient Last Name"];
      influencerInfoParsed["Recipient Phone"] = influencerInfo["Recipient Phone"];
      influencerInfoParsed["Recipient Company"] = influencerInfo["Recipient Company"];
      influencerInfoParsed["Address Line 1"] = influencerInfo["Address Line 1"];
      influencerInfoParsed["Address Line 2"] = influencerInfo["Address Line 2"];
      influencerInfoParsed["City"] = influencerInfo["City"];
      influencerInfoParsed["State"] = influencerInfo["State"];
      influencerInfoParsed["Postal Code"] = influencerInfo["Postal Code"];
      influencerInfoParsed["Country Code"] = influencerInfo["Country Code"];
      influencerInfoParsed["products"] = influencerInfo["products"];
      influencerInfoParsed["email_paypal"] = influencerInfo["Paypal Email"].trim().toLowerCase();
      influencerInfoParsed["_rowNumber"] = influencerInfo["_rowNumber"];
      influencerInfoParsed["realRowNumber"] = influencerInfo["_rowNumber"] + latestRawDataPadding[dealTypeform.platform];
      influencerInfoParsed["token"] = influencerInfo["Token"];
      cleanedRawData.push(influencerInfoParsed);
    }
  });
  // order data A -> Z
  let orderedRawData = orderBy(cleanedRawData, ["full_name"], ["asc"]);

  return {
    typeformAnswers: orderedRawData.reverse(),
    typeformAnswersWithoutOrder: orderedRawData.filter((influencerInfo) => influencerInfo.order_number === ""),
    typeformAnswersLoading: false,
  };
}

async function getSheetRawData(doc) {
  return {
    "instagram": doc.sheetsByIndex[3],
    "youtube": doc.sheetsByIndex[0],
    "podcast": doc.sheetsByIndex[4],
    "tiktok": doc.sheetsByIndex[5],
    "bilibili": doc.sheetsByIndex[6],
  };
}

function useVerifyShippingReady(deal, influencer) {
  // TODO: Improve with useMemo

  const allVerifications = {
    isCampaignSet: !!deal.campaign_id,
    isNbPiecesSet: typeof deal.number_pieces === "number",
    isDealPriceSet: typeof deal.price === "number",
    isDiscountSet: !!influencer.discount_code,
    isContentAdded: deal.content.length > 0,
  };
  return { ...allVerifications, isShippingReady: Object.values(allVerifications).every((v) => v) };
}

async function getFilledTypeformResponses(dealTypeform, influencer, deal) {
  const { data: dataResponse } = await axios.get("/api/v1/typeform_response", {
    params: { "status": "filled", "form_id": TYPEFORM_ID_BY_NAME[dealTypeform.platform] },
  });
  const typeformResponses = dataResponse.result
    .map((response) => ({
      ...response,
      "full_name": `${response.first_name.value} ${response.last_name.value} ${response.email.value} ${
        response.email_paypal.value !== response.email.value ? response.email_paypal.value : ""
      } - ${format(new Date(response.submitted_at), "Pp")} - ${response.am}`,
    }))
    .sort((responseA, responseB) => {
      if (influencer.emails.includes(responseA?.email.value) && !influencer.emails.includes(responseB?.email.value)) {
        return -1;
      }
      if (influencer.emails.includes(responseB?.email.value) && !influencer.emails.includes(responseA?.email.value)) {
        return 1;
      }
      if (responseA?.am === deal.am && responseB?.am !== deal.am) {
        return -1;
      }
      if (responseB?.am === deal.am && responseA?.am !== deal.am) {
        return 1;
      }
      return new Date(responseB?.submitted_at) - new Date(responseA?.submitted_at);
    });
  return typeformResponses;
}

function setStyle(condition, className) {
  return condition ? className : undefined;
}

async function getSelectedTypeformResponse(dealTypeform) {
  const { data: dataResponse } = await axios.get(`/api/v1/typeform_response/id/${dealTypeform.entry_id}`);
  const newDealTypeformResponse = dataResponse.result;
  return {
    ...newDealTypeformResponse,
    "full_name": `${newDealTypeformResponse.first_name.value} ${newDealTypeformResponse.last_name.value} ${newDealTypeformResponse.email.value} ${
      newDealTypeformResponse.email_paypal.value !== newDealTypeformResponse.email.value ? newDealTypeformResponse.email_paypal.value : ""
    } - ${format(new Date(newDealTypeformResponse.submitted_at), "Pp")} - ${newDealTypeformResponse.am}`,
  };
}

const CurrentDealTypeformInfoVerification = ({ deal, influencer }) => {
  const { isCampaignSet, isNbPiecesSet, isDealPriceSet, isDiscountSet, isContentAdded } = useVerifyShippingReady(deal, influencer);

  return (
    <div>
      <p>Please verify the following information</p>
      <ul className="oi_deal_shipping__typeform__list">
        <li className={setStyle(isCampaignSet, "oi_deal_shipping__typeform__list__element--done")}>
          Campaign is set (Select "Without campaign" if the deal is outside any current campaign)
        </li>
        <li className={setStyle(isNbPiecesSet, "oi_deal_shipping__typeform__list__element--done")}>Number of pieces is set (if it is 0, please enter 0)</li>
        <li className={setStyle(isDealPriceSet, "oi_deal_shipping__typeform__list__element--done")}>Final price is set (if it is 0, please enter 0)</li>
        <li className={setStyle(isDiscountSet, "oi_deal_shipping__typeform__list__element--done")}>Influencer's discount code is set</li>
        <li className={setStyle(isContentAdded, "oi_deal_shipping__typeform__list__element--done")}>Deal should have at least one content</li>
      </ul>
    </div>
  );
};

const CurrentDealSelectedTypeform = ({ onShowDetail, showDelete, typeformData, dealTypeform, onRemoveTypeform }) => {
  return (
    <div className="oi_deal_shipping__typeform__selected_typeform__result">
      <div className="oi_deal_shipping__typeform__selected_typeform__result__content">{typeformData?.full_name}</div>
      {dealTypeform?.type?.startsWith("object_id") && (
        <div className="oi_deal_shipping__typeform__selected_typeform__result__actions">
          <button className="oi_deal_shipping__typeform__response-button" onClick={onShowDetail} disabled={!typeformData}>
            Show details
          </button>
          {showDelete && (
            <button
              className="oi_deal_shipping__typeform__response-button oi_deal_shipping__typeform__response-button--unset"
              disabled={!dealTypeform?.entry_id}
              onClick={onRemoveTypeform}>
              <span className="material-icons">close</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const CurrentDealTypeformSelection = ({ dealTypeform, setDealTypeform, typeformAnswersWithoutOrder, setTypeformData }) => {
  const dropdownOptions = [
    { key: "youtube v2", value: "YouTube v2"},
    { key: "instagram v2", value: "Instagram v2"},
    { key: "tiktok v2", value: "TikTok v2"},
    { key: "instagram pr pack", value: "Instagram PR Pack"},
    { key: "youtube", value: "YouTube"},
    { key: "instagram", value: "Instagram"},
    { key: "podcast", value: "Podcast"},
    { key: "tiktok", value: "TikTok"},
    { key: "bilibili", value: "BiliBili"},
  ];
  return (
    <div className="oi_deal_shipping__typeform__selection">
      <ALDropdown
        options={dropdownOptions}
        value={dealTypeform.platform}
        onChange={(obj) => {
          setTypeformData(null);
          setDealTypeform({ platform: obj, entry_id: null });
        }}
      />
      <AutoCompleteInput
        list={typeformAnswersWithoutOrder || []}
        placeholder="Select Typeform Answer"
        useValue="full_name"
        onSelection={(selectedTypeformAnswer) => {
          setTypeformData(selectedTypeformAnswer);
          if (TYPEFORM_BY_SPREADSHEET.includes(dealTypeform.platform)) {
            const nbOfSameToken = typeformAnswersWithoutOrder.reduce((acc, v) => (selectedTypeformAnswer["token"] === v["token"] ? acc + 1 : acc), 0);
            if (nbOfSameToken > 1) {
              return setDealTypeform({
                ...dealTypeform,
                entry_id: selectedTypeformAnswer["realRowNumber"],
                type: "row_number",
              });
            }
            return setDealTypeform({ ...dealTypeform, entry_id: selectedTypeformAnswer["token"], type: "token" });
          }
          return setDealTypeform({ ...dealTypeform, entry_id: selectedTypeformAnswer._id, type: "object_id" });
        }}
      />
    </div>
  );
};

const CurrentDealTypeformInfo = ({ user, deal, updateDeal, updateDateSign, influencer, showModal, isDealShippingLoaded, dealShippings }) => {
  const DEFAULT_TYPEFORM_PLATFORM = "youtube v2";
  const { isShippingReady } = useVerifyShippingReady(deal, influencer);
  const [dealTypeform, setDealTypeform] = useState(deal.shipping_typeform || { platform: DEFAULT_TYPEFORM_PLATFORM });
  const [loadedPlatform, setLoadedPlatform] = useState(null);
  const [typeformData, setTypeformData] = useState(null);
  const [isTypeformAnswersLoading, setIsTypeformAnswersLoading] = useState(false);
  const [typeformAnswersWithoutOrder, setTypeformAnswersWithoutOrder] = useState([]);
  const isSentToAdditionalShipping = deal.status === "to_ship" && dealTypeform?.type?.endsWith("__additional");
  const isFirstShipping = isDealShippingLoaded && !dealShippings.length;
  const isTypeformSelection = isShippingReady && deal.status === "done";
  const canDeleteTypeform = ["admin", "super-user", "billing-user"].includes(user.role) && isFirstShipping && deal.status === "to_ship";

  const showTypeformInformationModalCallback = useCallback(
    () => showModal("ModalTypeformInformation", { typeformResponse: typeformData }),
    [showModal, typeformData]
  );
  const onConfirmTypeform = useCallback(() => {
    if (!dealTypeform.type || !dealTypeform.entry_id) {
      NotificationManager.error("Please add the Typeform first. If you did, refresh the page or report the issue.", "Empty field", 6000);
      return;
    }
    if (!deal.number_pieces) {
      return showModal("ModalWarn", {
        title: "Error",
        text: "This deal has 0 pieces. Please set number of pieces before sending it to shipping!",
        noActions: true,
      });
    }

    showModal("ModalConfirmation", { text: `Are you sure you want to select this Typeform answer?\n${typeformData?.full_name}` }).then((res) => {
      if (res === "confirm") {
        updateDateSign();
        updateDeal({
          shipping_typeform: { ...dealTypeform, date_added: new Date() },
          status: "to_ship",
        });

        // Trigger Stock Tracker deal update at deal status update to to_ship
        triggerStockTrackerDealUpdate(deal._id);
      }
    });
  }, [dealTypeform, updateDeal, updateDateSign, showModal, deal, typeformData]);
  const onRemoveTypeform = useCallback(() => {
    if (!dealTypeform?.entry_id || dealTypeform?.type !== "object_id") {
      NotificationManager.error("Couldn't find typeform response to delete", "Empty field", 6000);
      return;
    }
    if (!canDeleteTypeform) {
      console.log("isFirstShipping", isDealShippingLoaded, dealShippings.length);
      console.log("canDeleteTypeform:", user.role, isFirstShipping, deal.status);
      NotificationManager.error("Cannot unset typeform response", "Operation not allowed", 6000);
      return;
    }

    showModal("ModalConfirmation", {
      title: "Caution!",
      text: "Make sure no shipping is currently being processed for this influencer before continuing.\nAre you sure you want to unlink this Typeform answer?",
    }).then((res) => {
      if (res === "confirm") {
        axios.put(`/api/v1/typeform_response/id/${dealTypeform.entry_id}`, { status: "filled", deal_id: null }).then(() => {
          updateDeal({ shipping_typeform: null, status: "done" });
          setDealTypeform({ ...dealTypeform, platform: dealTypeform.platform });
        });
      }
    });
  }, [canDeleteTypeform, deal.status, dealShippings.length, dealTypeform, isDealShippingLoaded, isFirstShipping, showModal, updateDeal, user.role]);

  // useEffect: Load Typeform responses
  useEffect(() => {
    if (isTypeformAnswersLoading || loadedPlatform === dealTypeform.platform) return;
    setIsTypeformAnswersLoading(true);
    if (TYPEFORM_BY_SPREADSHEET.includes(dealTypeform.platform)) {
      getGSheetData(dealTypeform).then(({ typeformAnswers, typeformAnswersWithoutOrder }) => {
        setTypeformAnswersWithoutOrder(typeformAnswersWithoutOrder);
        if (dealTypeform?.type?.startsWith("token")) {
          setTypeformData(typeformAnswers.find((answer) => answer["token"] === dealTypeform.entry_id));
        } else if (dealTypeform?.type?.startsWith("row_number")) {
          setTypeformData(typeformAnswers.find((answer) => answer["realRowNumber"] === dealTypeform.entry_id));
        }
        setIsTypeformAnswersLoading(false);
      });
    } else {
      getFilledTypeformResponses(dealTypeform, influencer, deal).then((typeformAnswersWithoutOrder) => {
        setTypeformAnswersWithoutOrder(typeformAnswersWithoutOrder);
        setIsTypeformAnswersLoading(false);
      });
    }
    setLoadedPlatform(dealTypeform.platform);
  }, [deal, dealTypeform, dealTypeform.platform, influencer, isTypeformAnswersLoading, loadedPlatform]);

  // useEffect: Load selected Typeform response
  useEffect(() => {
    if (dealTypeform?.type?.startsWith("object_id") && dealTypeform?.entry_id && dealTypeform?.entry_id !== typeformData?._id) {
      getSelectedTypeformResponse(dealTypeform).then((typeformResponse) => {
        setTypeformData(typeformResponse);
      });
    }
  }, [dealTypeform, typeformData]);

  return (
    <div className="oi_deal_shipping__typeform">
      <div className="oi_deal_shipping__typeform__header">
        <p className="oi_deal_shipping__typeform__header__title">Shipping typeform</p>
      </div>
      {!isShippingReady && <CurrentDealTypeformInfoVerification {...{ deal, influencer }} />}
      {isTypeformAnswersLoading && <Loading />}
      {isTypeformSelection && !isTypeformAnswersLoading && (
        <CurrentDealTypeformSelection {...{ dealTypeform, setDealTypeform, typeformAnswersWithoutOrder, setTypeformData }} />
      )}
      <CurrentDealSelectedTypeform
        {...{
          typeformData,
          dealTypeform,
          showDelete: canDeleteTypeform,
          onShowDetail: showTypeformInformationModalCallback,
          onRemoveTypeform,
        }}
      />
      {isTypeformSelection && !isTypeformAnswersLoading && (
        <button
          className={`oi_deal_shipping__typeform__selection__confirm ${
            dealTypeform.entry_id ? "" : "oi_deal_shipping__typeform__selection__confirm--disabled"
          }`}
          disabled={!dealTypeform.entry_id}
          onClick={onConfirmTypeform}>
          Confirm
        </button>
      )}
      {isSentToAdditionalShipping && (
        <div className="oi_deal_shipping__typeform__selected_typeform">
          <p style={{ marginBottom: "20px" }}>Deal sent for additional shipping ✅</p>
        </div>
      )}
    </div>
  );
};

export default CurrentDealTypeformInfo;
