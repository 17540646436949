import React from "react";
import HeaderComponent from "../HeaderComponent";
import "./ShippingContainer.css"
import { Link } from 'react-router-dom';

class ShippingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  render() {
    return (
      <div className="">
        <HeaderComponent title="Shipping" to="/a/dashboard/" toTitle="Home" />
        <div className="shipping_container__content">
          <div className="shipping_container__content__card">
            <Link to='/a/dashboard/shipping/create'>Create</Link>
          </div>
          <div className="shipping_container__content__card">
            <Link to='/a/dashboard/shipping/search'>Search</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ShippingContainer;
