import React from "react";
import axios from "axios";
import replace from "lodash/replace";
import "./OutreachHeader.css";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance"
import intervalToDuration from "date-fns/intervalToDuration";
import { ALTagInfluencer, ALCampaignInfluencer, ALSwitch, ALLoading } from "../ALComponents";
import Loading from "../Loading";
import NotificationManager from "../al_components/notification/NotificationManager";

import listCountries from "./countries.json";
import iconInsta from "../../../src/assets/insta_logo.png";
import iconYouTube from "../../../src/assets/youtube_logo.png";
import iconTikTok from "../../../src/assets/tiktok_logo.png";
import iconPodcast from "../../../src/assets/podcast_logo.png";
import iconPayPal from "../../../src/assets/paypal_logo.png";
import ALDropdown from "../al_components/dropdown";
import { ReactComponent as WarningIcon } from "assets/warning_icon.svg";

class OutreachHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      influencer: this.props.influencer,
      showNotesInfluencer: false,
      newInfluencerNote: "",
      newInstagramHandle: null,
      newTiktokHandle: null,
      newChannelId: null,
      newPodcastName: "",
      newPodcastURLs: "",
      newPodcastNetworks: "",
      inputPodcastWarning: "",
      inputPodcastError: "",
      isEditing: null,
      isEditingValue: "",
      isRefreshingInfluencer: false,
      tmpNoteEdition: -1,
      isAddingDiscountCode: false,
      ytData: {
        trimMeanViews: null,
        trimMeanLikes: null,
        trimMeanDislikes: null,
        trimMeanDuration: null,
        isLoading: true,
        hasError: false,
      },
      displayedPlatform: ["youtube", "instagram", "tiktok", "podcast"].find(
        (platform) => this.props.influencer[platform] && this.props.influencer[platform].accounts.length
      ),
      isUpdatingCountry: false,
      updateMessage: "",
    };
    this.getEngagementData = this.getEngagementData.bind(this);
    this.addDiscountCode = this.addDiscountCode.bind(this);
    this.onAddPodcast = this.onAddPodcast.bind(this);
  }

  createDiscount(code) {
    return axios
      .post("/api/v1/discount", {
        type: "percentage",
        code: code,
        value: "20",
      })
      .then(() => "ok")
      .catch((err) => {
        console.error(err);
        if (err?.response?.data?.message === "Already exist") return "Already exist";
        return `Error on discount creation ${err?.response?.data?.message || ""}`;
      });
  }
  addDiscountCode() {
    const { isEditingValue, influencer, isAddingDiscountCode } = this.state;
    if (!isEditingValue || isEditingValue.length < 3 || isAddingDiscountCode) return;
    this.setState({ isAddingDiscountCode: true });
    this.props
      .showModal("ModalConfirmation", {
        // text: `Are you sure you want to set ${isEditingValue} as discount code for this influencer?\nTHIS ACTION IS DEFINITIVE.`,
        content: (
          <div style={{ textAlign: "center" }}>
            <p style={{ marginBottom: "30px" }}>
              Are you sure you want to set <b>{isEditingValue}</b> as discount code for this influencer?
            </p>
            <p>THIS ACTION CANNOT BE UNDONE.</p>
          </div>
        ),
      })
      .then((r) => {
        if (r === "close") return this.setState({ isAddingDiscountCode: false });
        if (r === "confirm") {
          this.createDiscount(isEditingValue).then((result) => {
            if (result === "ok") {
              axios
                .post("/api/v1/event", {
                  type: "ADD_DISCOUNT_CODE",
                  user: this.props.user.email,
                  sample: {
                    object: influencer._id,
                    svalue: isEditingValue,
                  },
                })
                .catch((err) => console.error(err, "ADD_DISCOUNT_CODE"));
              NotificationManager.success("Discount code created", "Success");
              this.props.editInfoInfluencer("ADD", "discount_code", isEditingValue, null, influencer["_id"]);
              this.setState({ isAddingDiscountCode: false, isEditing: "", isEditingValue: "" });
            } else if (result === "Already exist") {
              axios.get("/api/v1/influencers", { params: { discount_code: isEditingValue } }).then(({ data }) => {
                const isAlreadyAttributedInOklos = data.result.length > 0;
                this.props
                  .showModal("ModalConfirmation", {
                    title: "HEADS UP!",
                    style: { container: "confirmation-modal--important" },
                    content: (
                      <div style={{ textAlign: "center" }}>
                        <div style={{ marginBottom: "10px" }}>
                          <p>
                            ⚠️ The discount <b>{isEditingValue}</b> already exist!
                            <br />
                            {isAlreadyAttributedInOklos
                              ? "❌ An Influencer already have this code"
                              : "✅ No influencer in Oklos curently have this code"}
                          </p>
                          <p>Do you still want to set it for this influencer?</p>
                        </div>
                        <p>THIS ACTION CANNOT BE UNDONE.</p>
                      </div>
                    ),
                  })
                  .then((res) => {
                    if (res === "confirm") {
                      this.props.editInfoInfluencer("ADD", "discount_code", isEditingValue, null, influencer["_id"]);
                      return this.setState({ isEditing: "", isEditingValue: "", isAddingDiscountCode: false });
                    }
                    return this.setState({ isAddingDiscountCode: false });
                  });
              });
            } else {
              NotificationManager.error(result, "Error");
            }
          });
        }
      });
  }

  getEngagementData() {
    let _this = this;
    let getTrimmedMean = (data, trimAmount) => {
      let trimCount = Math.floor(trimAmount * data.length);
      let sortedData = data.sort(function (a, b) {
        return b - a;
      });
      let trimData = sortedData.slice(trimCount, data.length - trimCount);
      return trimData.reduce((a, b) => a + b, 0) / trimData.length;
    };

    let listVideos = this.props.influencer.youtube.accounts[0].latestVideos && this.props.influencer.youtube.accounts[0].latestVideos.list;
    let queryVideos = [];

    if (!listVideos) {
      this.setState({
        "ytData": {
          "trimMeanViews": null,
          "trimMeanLikes": null,
          "trimMeanDislikes": null,
          "trimMeanDuration": null,
          "isLoading": false,
          "hasError": true,
        },
      });
      return;
    }
    listVideos.map((videoId, i) => {
      if (i > 14) {
        return;
      }
      queryVideos.push(axios.get(`/api/v1/youtube/video/${videoId}`));
    });

    let arrViews = [];
    let arrLikes = [];
    let arrDislikes = [];
    let arrDuration = [];
    let arrGenre = [];
    Promise.all(queryVideos)
      .then(function (resVideos) {
        resVideos.map((video) => {
          if (video.status == 200) {
            if (video.data["video"]) {
              arrViews.push(video.data["video"]["stats"].views);
              arrLikes.push(video.data["video"]["stats"].likes);
              arrDislikes.push(video.data["video"]["stats"].dislikes);
              arrDuration.push(video.data["video"].duration);
              arrGenre.push(video.data["video"].genre);
            }
          }
        });
        let trimMeanViews = getTrimmedMean(arrViews, 0.1).toFixed(2);
        let trimMeanLikes = getTrimmedMean(arrLikes, 0.1).toFixed(2);
        let trimMeanDislikes = getTrimmedMean(arrDislikes, 0.1).toFixed(2);
        let trimMeanDuration = parseInt(getTrimmedMean(arrDuration, 0.1));

        let durationVideo = intervalToDuration({ start: 0, end: parseInt(trimMeanDuration) * 1000});
        if (durationVideo.hours === 0) {
          trimMeanDuration = `${durationVideo.minutes}:${durationVideo.seconds < 10 ? `0${durationVideo.seconds}` : durationVideo.seconds}`;
        } else {
          trimMeanDuration = `${durationVideo.hours}:${durationVideo.minutes < 10 ? `0${durationVideo.minutes}` : durationVideo.minutes}:${
            durationVideo.seconds < 10 ? `0${durationVideo.seconds}` : durationVideo.seconds
          }`;
        }
        _this.setState({
          "ytData": {
            "trimMeanViews": trimMeanViews,
            "trimMeanLikes": trimMeanLikes,
            "trimMeanDislikes": trimMeanDislikes,
            "trimMeanDuration": trimMeanDuration,
            "isLoading": false,
            "hasError": false,
          },
        });
      })
      .catch((error) => {
        _this.setState({
          "ytData": {
            "trimMeanViews": null,
            "trimMeanLikes": null,
            "trimMeanDislikes": null,
            "trimMeanDuration": null,
            "isLoading": false,
            "hasError": true,
          },
        });
      });
  }
  getSearchPodcast(name, networks, urls) {
    function searchBy_podcastURL(searchValue) {
      return axios.get("/api/v1/podcasts?", { params: { "broadcasters.url": searchValue } }).then(async (res) => {
        const podcasts = res.data.result;
        if (podcasts.length === 0) return [];
        const { data } = await axios.get("/api/v1/influencers", {
          params: { "podcast.accounts": JSON.stringify({ "$in": podcasts.map((p) => p._id) }), "is_json_query": true },
        });
        return podcasts.map((podcast) => {
          let influencerObject = data.result.find((influencer) => influencer.podcast.accounts.includes(podcast._id)) || {};
          influencerObject.podcast = { ...influencerObject.podcast, found: podcast };
          return influencerObject;
        });
      });
    }
    function searchBy_podcastName(searchValue) {
      return axios.get("/api/v1/podcasts?", { params: { "name": searchValue } }).then(async (res) => {
        const podcasts = res.data.result;
        if (podcasts.length === 0) return [];
        const { data } = await axios.get("/api/v1/influencers", {
          params: { "podcast.accounts": JSON.stringify({ "$in": podcasts.map((p) => p._id) }), "is_json_query": true },
        });
        return podcasts.map((podcast) => {
          let influencerObject = data.result.find((influencer) => influencer.podcast.accounts.includes(podcast._id)) || {};
          influencerObject.podcast = { ...influencerObject.podcast, found: podcast };
          return influencerObject;
        });
      });
    }
    return async function searchBy_podcast() {
      const [resultByName, resultByURL] = await Promise.all([
        searchBy_podcastName(name),
        (async () => {
          const l = urls.map((url) => searchBy_podcastURL(url));
          const r = await Promise.all(l);
          return r.flat();
        })(),
      ]);

      const allResults = [...resultByName, ...resultByURL];
      return allResults.reduce((acc, influencer_1) => {
        // Remove duplicates
        let notFound = !acc.find(
          (inf2) => (influencer_1._id && inf2._id && influencer_1._id === inf2._id) || influencer_1?.podcast?.found?._id === inf2?.podcast?.found?._id
        );
        return notFound ? [...acc, influencer_1] : acc;
      }, []);
    };
  }

  async addPodcastInDB(name, networks, urls) {
    try {
      const broadcasters = urls.map((url) => {
        let broadcasterNameURL = new URL(url);
        let broadcasterName = broadcasterNameURL.hostname.replace(/^(.*\.)*(.+)\..+$/, "$2");
        return {
          name: broadcasterName,
          url: url,
        };
      });
      const podcastObject = {
        name: name,
        networks: networks,
        broadcasters: broadcasters,
      };
      const r = await axios.post("/api/v1/podcasts", podcastObject);
      if (r.data.status === "ok") {
        return r.data.result;
      }
    } catch (err) {
      NotificationManager.error("Error", "Server failed to create podcast. " + err, 2000);
      return;
    }
  }
  async onAddPodcast() {
    const { newPodcastName, newPodcastNetworks, newPodcastURLs, influencer, inputPodcastError } = this.state;
    if (inputPodcastError || !(newPodcastName && newPodcastName.length > 0 && newPodcastURLs && newPodcastURLs.length)) return;
    const name = newPodcastName.trim();
    const networks = (newPodcastNetworks || "").split(",").map((p) => p.trim());
    const urls = newPodcastURLs
      .split(",")
      .map((u) => u.trim())
      .filter((e) => e);
    const result = await this.props.showModal("ModalOutreachPodcast", {
      loadMatchingPodcasts: this.getSearchPodcast(name, networks, urls),
    });
    if (result === "new") {
      const podcast = await this.addPodcastInDB(name, networks, urls);
      this.props.editInfoInfluencer("ADD", "podcast", podcast._id, null, influencer["_id"]);
      this.setState({ newPodcastName: "", newPodcastNetworks: "", newPodcastURLs: "" });
    } else if (typeof result === "object") {
      const podcast = result;
      this.props.editInfoInfluencer("ADD", "podcast", podcast._id, null, influencer["_id"]);
      this.setState({ newPodcastName: "", newPodcastNetworks: "", newPodcastURLs: "" });
    }
  }
  getPodcastURLWarning(url) {
    if (!url) return "";
    if (url.includes("podcasts.apple.com")) {
      if (url.includes("?i=")) {
        return "Episode URL instead of profile URL.";
      }
    } else if (url.includes("music.amazon.com/podcasts")) {
      if (url.includes("/episodes/")) {
        return "Episode URL instead of profile URL.";
      }
    } else {
      return "Unknown URL source. Please use an Apple Podcast URL (podcasts.apple.com)(recommended), or an Amazon music URL (music.amazon.com) or ignore this message as last resort.";
    }
    return "";
  }
  podcastSearchInput(value, field) {
    this.setState((state) => {
      let warning = "";
      let error = "";
      if (field !== "newPodcastName" && field !== "newPodcastNetworks" && field !== "newPodcastURLs") {
        value = value.toLowerCase();
      }
      const nameInput = field === "newPodcastName" ? value : state.newPodcastName;
      if (nameInput && nameInput.startsWith("http")) error += "Name should NOT be a URL. ";
      const networksInput = field === "newPodcastNetworks" ? value : state.newPodcastNetworks;
      if (networksInput && networksInput.startsWith("http")) error += "Networks should NOT be a URL. ";
      const urlInput = field === "newPodcastURLs" ? value : state.newPodcastURLs;
      const urls = urlInput
        .split(",")
        .map((u) => u.split("?")[0].trim());
      warning += urls.map((url) => this.getPodcastURLWarning(url)).join(" ");
      if (field === "newPodcastURLs") value = urls.join(",");
      if (urlInput && urls.some((url) => !url.startsWith("http"))) error += "URLs should starts with 'http'. ";
      return { [field]: value, inputPodcastWarning: warning, inputPodcastError: error };
    });
  }
  createNewNote() {
    const { influencer, newInfluencerNote } = this.state;
    const { user } = this.props;

    if (newInfluencerNote) {
      axios
        .post(`/api/v1/influencers/${influencer._id}/notes`, { "content": newInfluencerNote, "author": user.email })
        .then(({ data }) => {
          axios
            .post("/api/v1/event", {
              type: "ADD_NOTE_INFLUENCER",
              user: user.email,
              sample: { object: influencer._id },
            })
            .catch((err) => console.log(err) || alert("Failed to fire event ADD_NOTE_INFLUENCER"));
          this.setState(({ influencer }) => ({ influencer: { ...influencer, notes: data.result }, newInfluencerNote: "" }));
          NotificationManager.success("Updated", `Note successfully added to the influencer`, 1500);
        })
        .catch((err) => {
          console.error(err);
          NotificationManager.error(
            "Error",
            "Server failed to update influencer, please try again later and send the page log to the tech team",
            6000
          );
        });
    }
  }
  deleteNote(idx) {
    const { influencer } = this.state;

    axios
      .delete(`/api/v1/influencers/${influencer._id}/notes/${idx}`)
      .then(({ data }) => {
        this.setState(({ influencer }) => ({ influencer: { ...influencer, notes: data.result } }));
        NotificationManager.success("Updated", `Note successfully deleted`, 1500);
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error("Error", "Server failed to delete note, please try again later and send the page log to the tech team", 6000);
      });
  }
  editNote(idx) {
    const { influencer, newInfluencerNote } = this.state;
    const { user } = this.props;

    axios
      .put(`/api/v1/influencers/${influencer._id}/notes/${idx}`, { "content": newInfluencerNote, "author": user.email })
      .then(({ data }) => {
        this.setState(({ influencer }) => ({ influencer: { ...influencer, notes: data.result }, newInfluencerNote: "", tmpNoteEdition: -1 }));
        NotificationManager.success("Updated", `Note successfully edited`, 1500);
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error("Error", "Server failed to delete note, please try again later and send the page log to the tech team", 6000);
      });
  }
  async updateCountry() {
    const { isEditingValue, influencer } = this.state;
    this.setState({ isUpdatingCountry: true, updateMessage: "Updating country and past briefs to corresponding language..." });
    await this.props.editInfoInfluencer("ADD", "country", isEditingValue, null, influencer["_id"]);
    NotificationManager.success("", "Briefs language updated", 1500);
    this.setState({ isUpdatingCountry: false, updateMessage: "" });
  }

  componentDidUpdate(_prevProps, _prevState) {
    if (this.props.influencer != _prevProps.influencer) {
      this.setState({
        influencer: this.props.influencer,
        showNotesInfluencer: false,
        ytData: {
          trimMeanViews: null,
          trimMeanLikes: null,
          trimMeanDislikes: null,
          trimMeanDuration: null,
          isLoading: true,
          hasError: false,
        },
      });
    }

    if (!this.props.isUpdatingInf && _prevProps.isUpdatingInf && this.state.isRefreshingInfluencer) {
      this.setState({ isRefreshingInfluencer: false });
    }

    if (this.state.influencer.youtube.accounts.length > 0) {
      if ((this.state.ytData.trimMeanViews === null || this.state.ytData.trimMeanDuration === null) && !this.state.ytData.hasError) {
        this.getEngagementData();
      }
    }
  }

  componentDidMount() {
    if (this.props.influencer.youtube.accounts.length > 0) {
      this.getEngagementData();
    }
  }

  render() {
    const {
      isEditing,
      isEditingValue,
      showNotesInfluencer,
      influencer,
      newInfluencerNote,
      newInstagramHandle,
      newTiktokHandle,
      newChannelId,
      newPodcastName,
      newPodcastNetworks,
      inputPodcastWarning,
      inputPodcastError,
      newPodcastURLs,
      tmpNoteEdition,
      displayedPlatform,
      isAddingDiscountCode,
      isUpdatingCountry,
      updateMessage,
    } = this.state;

    let hasInsta = false;
    let hasYouTube = false;
    let tiktokData = influencer?.tiktok?.accounts[0];
    let hasTiktok = !!tiktokData;
    let podcastData = influencer?.podcast?.accounts[0];
    let hasPodcast = !!podcastData;
    let youtubeData = null;
    let instaData = null;
    if (influencer && influencer.instagram.accounts.length > 0) {
      hasInsta = true;
      instaData = influencer.instagram.accounts[0];
      if (instaData == "") {
        hasInsta = false;
        instaData = null;
      }
    }
    if (influencer && influencer.youtube.accounts.length > 0) {
      hasYouTube = true;
      youtubeData = influencer.youtube.accounts[0];
      if (youtubeData === "") {
        hasYouTube = false;
        youtubeData = null;
      }
    }
    // insta first if insta then youtube
    let profilePictureToUse = null;
    if (hasYouTube && typeof youtubeData !== "string") {
      profilePictureToUse = youtubeData["pictureURL"];
    } else if (hasInsta && typeof instaData !== "string") {
      profilePictureToUse = instaData["pictureURL"];
    } else {
      // find default image
    }

    let influencerName = null;
    if (influencer.name != "") {
      influencerName = influencer.name;
    } else if (influencer.youtube.accounts.length > 0 && typeof influencer.youtube.accounts[0] === "string") {
      influencerName = influencer.youtube.accounts[0];
    } else if (influencer.youtube.accounts.length > 0 && influencer.youtube.accounts[0].title) {
      influencerName = influencer.youtube.accounts[0].title;
    } else if (typeof podcastData === "object") {
      influencerName = podcastData.name;
    } else if (influencer.instagram.accounts.length > 0 && typeof influencer.instagram.accounts[0] === "string") {
      influencerName = influencer.instagram.accounts[0];
    } else if (influencer.instagram.accounts.length > 0 && influencer.instagram.accounts[0].fullName) {
      influencerName = influencer.instagram.accounts[0].fullName;
    } else if (typeof tiktokData === "string") {
      influencerName = tiktokData;
    } else if (tiktokData) {
      influencerName = tiktokData?.fullName;
    }
    return (
      <div
        className="outreach_influencer_header"
        onClick={(e) =>
          e.metaKey ? navigator.clipboard.writeText(influencer._id).then(() => NotificationManager.info("Influencer ID copied!")) : null
        }>
        <div className="outreach_influencer_header_details">
          <p>
            <span className="outreach_influencer_header_details_title">Status:</span>
            <span>{influencer?.status}</span>
          </p>
          <p>
            <span className="outreach_influencer_header_details_title">Added:</span>
            <span>{format(new Date(influencer?.creation_date), "MMM dd, yyyy")} ({formatDistance(new Date(influencer?.creation_date), new Date())})</span>
          </p>
        </div>
        <div
          className="outreach_influencer_header_forceupdate"
          onClick={() => {
            this.props.forceUpdateInf();
            this.setState({ isRefreshingInfluencer: true });
          }}>
          <span className={`material-icons`}>update</span>
          <p className="outreach_influencer_header_forceupdate_info">Update Data for YT & Insta</p>
        </div>

        {showNotesInfluencer ? (
          <div className="outreach_influencer_header_notes">
            <div
              className="outreach_influencer_header_notes--close"
              onClick={() => this.setState({ showNotesInfluencer: false, newInfluencerNote: "" })}>
              <span className="material-icons">close</span>
            </div>
            <div className="outreach_influencer_header_notes_form">
              <input
                className="outreach_influencer_header_notes__input"
                placeholder="Add note"
                type="text"
                value={newInfluencerNote}
                onChange={(e) => {
                  this.setState({ newInfluencerNote: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    e.preventDefault();
                    tmpNoteEdition !== -1 ? this.editNote(tmpNoteEdition) : this.createNewNote();
                  }
                }}
              />
              <div className="deal_notes_input_actions">
                <div
                  className={`outreach_influencer_header_notes__add ${
                    !newInfluencerNote ? "outreach_influencer_header_notes___add--isDisable" : ""
                  } ${tmpNoteEdition !== -1 ? "deal_notes_input_add--edition" : ""}`}
                  onClick={() => (tmpNoteEdition !== -1 ? this.editNote(tmpNoteEdition) : this.createNewNote())}>
                  {tmpNoteEdition !== -1 ? <span className="material-icons">create</span> : <span className="material-icons">send</span>}
                </div>
                {tmpNoteEdition !== -1 ? (
                  <div className="deal_notes_input_cancel" onClick={() => this.setState({ newInfluencerNote: "", tmpNoteEdition: -1 })}>
                    Cancel
                  </div>
                ) : null}
              </div>
            </div>

            <div className="oi_header_notes_list">
              {influencer.notes.length > 0 ? (
                influencer.notes.map((note, i) => {
                  return (
                    <div className="oi_header_notes_list_note" key={`note_influencer_${i}`}>
                      <p
                        className={
                          "oi_header_notes_list_note_content" +
                          (tmpNoteEdition === i ? " deal_notes_history_note_content--edition" : "") +
                          (this.state.hover_note === i && tmpNoteEdition === -1 ? " deal_notes_history_note_content--hover" : "")
                        }
                        onMouseEnter={() => this.setState({ "hover_note": i })}
                        onMouseLeave={() => this.setState({ "hover_note": null })}>
                        {note.content}
                      </p>
                      {tmpNoteEdition === -1 ? (
                        <div
                          className={this.state.hover_note === i ? "deal_notes_history_note_delete--hover" : "deal_notes_history_note_delete"}
                          onMouseEnter={() => this.setState({ "hover_note": i })}
                          onMouseLeave={() => this.setState({ "hover_note": null })}>
                          <span className="deal_notes_history_note_delete--icon material-icons" onClick={() => this.deleteNote(i)}>
                            close
                          </span>
                          <span
                            className="deal_notes_history_note_delete--icon material-icons"
                            onClick={() => this.setState({ tmpNoteEdition: i, newInfluencerNote: note.content })}>
                            create
                          </span>
                        </div>
                      ) : null}
                      <div className="oi_header_notes_list_note_meta">
                        <p className="oi_header_notes_list_note_meta_date">{format(new Date(note.date), "MMM dd, yyyy")}</p>
                        <p className="oi_header_notes_list_note_meta_author">{note.author ? note.author : "Oklos Bot"}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No notes for this influencer</p>
              )}
            </div>
          </div>
        ) : null}

        <div className="outreach_influencer_header_info">
          <img className="oi_header_info_picture" src={profilePictureToUse} alt="profile" crossOrigin="anonymous" referrerPolicy="no-referrer" />
          <div className="oi_header_info_details">
            <p className="oi_header_info_details_name">{influencerName}</p>
            <div className="oi_header_info_details_email">
              <span className="oi_header_info_details_email--number">{influencer.emails.length}</span>
              <span className="oi_header_info_details_email--icon material-icons">mail_outline</span>
              {influencer.emails[0]}
              <span
                className={`oi_header_info_details--copy material-icons`}
                onClick={() => {
                  navigator.clipboard.writeText(influencer.emails[0]);
                  NotificationManager.info("Copied!", null, 1000);
                }}>
                content_copy
              </span>
              <span
                className={`oi_header_info_details--edit material-icons ${isEditing == "email" ? "oi_header_info_details--edit-visible" : null}`}
                onClick={() => this.setState({ isEditing: "email" })}>
                create
              </span>
              {isEditing === "email" ? (
                <div className="oi_header_details_editing">
                  <p className="oi_header_details_editing--title">Emails</p>
                  <span
                    className="oi_header_details_editing--close material-icons"
                    onClick={() => this.setState({ isEditing: "", isEditingValue: "" })}>
                    close
                  </span>
                  <div className="oi_header_details_editing_add">
                    <input
                      className="oi_header_details_editing_add_input"
                      type="text"
                      value={this.state.isEditingValue}
                      onChange={(e) => this.setState({ isEditingValue: e.target.value.trim().toLowerCase() })}
                    />
                    <div
                      className="oi_header_details_editing_add_button"
                      onClick={() => this.props.editInfoInfluencer("ADD", "email", isEditingValue, null, influencer["_id"])}>
                      ADD
                    </div>
                  </div>

                  {influencer.emails.map((email, i) => {
                    return (
                      <div className="oi_header_details_editing_value" key={`emails_${i}`}>
                        <p className="oi_header_details_editing_value--val">
                          {i}: {email}
                        </p>
                        <span
                          className="oi_header_details_editing_value--del material-icons"
                          onClick={() => this.props.editInfoInfluencer("DELETE", "email", influencer.emails, i, influencer["_id"])}>
                          delete
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>

            <div className="oi_header_info_details_paypal">
              <a href="https://www.paypal.com/myaccount/transfer/homepage/pay" target="_blank" rel="noopener noreferrer">
                <img className="oi_header_info_details_paypal_icon" src={iconPayPal} />
              </a>
              <p className="oi_header_info_details_paypal_email">{influencer.paypal_email || <WarningIcon />}</p>
              <span
                className={`oi_header_info_details--edit material-icons ${
                  isEditing == "email_paypal" ? "oi_header_info_details--edit-visible" : null
                }`}
                onClick={() => this.setState({ isEditing: "email_paypal" })}>
                create
              </span>
              {isEditing == "email_paypal" ? (
                <div className="oi_header_details_editing">
                  <p className="oi_header_details_editing--title">Paypal Email</p>
                  <span
                    className="oi_header_details_editing--close material-icons"
                    onClick={() => this.setState({ isEditing: "", isEditingValue: "" })}>
                    close
                  </span>
                  <div className="oi_header_details_editing_add">
                    <input
                      className="oi_header_details_editing_add_input"
                      type="text"
                      value={this.state.isEditingValue}
                      onChange={(e) => this.setState({ isEditingValue: e.target.value.trim() })}
                    />
                    <div
                      className="oi_header_details_editing_add_button"
                      onClick={() => this.props.editInfoInfluencer("ADD", "email_paypal", isEditingValue, null, influencer["_id"])}>
                      ADD
                    </div>
                  </div>

                  <div className="oi_header_details_editing_value">
                    <p className="oi_header_details_editing_value--val">{influencer.paypal_email}</p>
                    <span
                      className="oi_header_details_editing_value--del material-icons"
                      onClick={() => this.props.editInfoInfluencer("DELETE", "email_paypal", influencer.paypal_email, 0, influencer["_id"])}>
                      delete
                    </span>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="oi_header_info_details_country">
              <span className="material-icons">outlined_flag</span>
              <span className="oi_header_info_details_country--value">{influencer.country ? influencer.country : null}</span>
              <span
                className={`oi_header_info_details--edit material-icons ${isEditing == "country" ? "oi_header_info_details--edit-visible" : null}`}
                onClick={() => this.setState({ isEditing: "country" })}>
                create
              </span>
              {isEditing == "country" ? (
                <div className="oi_header_details_editing">
                  <p className="oi_header_details_editing--title">Country</p>
                  <span
                    className="oi_header_details_editing--close material-icons"
                    onClick={() => this.setState({ isEditing: "", isEditingValue: "" })}>
                    close
                  </span>
                  <div className="oi_header_details_editing_add">
                    <ALDropdown
                      options={listCountries}
                      useValue={"title"}
                      useKey={"id"}
                      value={this.state.isEditingValue ? this.state.isEditingValue : "null"}
                      onChange={(key) => this.setState({ isEditingValue: key })}
                    />
                    <div
                      className="oi_header_details_editing_add_button"
                      onClick={() => this.updateCountry()}>
                      ADD
                    </div>
                  </div>
                  {isUpdatingCountry ? <ALLoading text={updateMessage} alt /> : null}

                  <div className="oi_header_details_editing_value">
                    <p className="oi_header_details_editing_value--val">{influencer.country}</p>
                    <span
                      className="oi_header_details_editing_value--del material-icons"
                      onClick={() => this.props.editInfoInfluencer("DELETE", "country", influencer.country, 0, influencer["_id"])}>
                      delete
                    </span>
                  </div>
                </div>
              ) : null}
            </div>

            {influencer.gender && influencer.gender.value ? (
              <div className="oi_header_info_details_gender">
                <span className="material-icons">face</span>
                <span className="oi_header_info_details_gender--value">
                  {influencer.gender.value} ({influencer.age.low ? `${influencer.age.low} - ` : ""}{" "}
                  {influencer.age.high ? `${influencer.age.high}` : ""} )
                </span>
              </div>
            ) : null}
            <div className="oi_header_info_details_discount-code">
              <span className="material-icons-outlined">local_offer</span>
              <span className="oi_header_info_details_discount-code--value">
                {(influencer.discount_code && influencer.discount_code.toUpperCase()) || null}
              </span>
              {influencer.discount_code 
                ? <>
                  <span
                    className="oi_header_info_details--copy material-icons"
                    onClick={() => {
                      navigator.clipboard.writeText(influencer.discount_code);
                      NotificationManager.info("Copied!", null, 1000);
                    }}>
                    content_copy
                  </span>
                  {
                    ["super-user", "admin"].includes(this.props.user.role) &&
                      <span
                        className="oi_header_info_details--copy material-icons-outlined"
                        onClick={() => {
                          this.props.editInfoInfluencer("DELETE", "discount_code", influencer.discount_code, 0, influencer["_id"]);
                          NotificationManager.success("Discount code deleted!", null, 1000);
                        }}>
                        delete
                      </span>
                  }
                </>
                : <span className={`oi_header_info_details--edit material-icons ${
                      isEditing && isEditing !== "discount_code" && !influencer.discount_code ? "oi_header_info_details--edit-visible" : ""
                    }`}
                    onClick={() => !influencer.discount_code && this.setState({ isEditing: "discount_code" })}>
                    create
                  </span>
              }
              {isEditing === "discount_code" ? (
                <div className="oi_header_details_editing">
                  <p className="oi_header_details_editing--title">Discount Code</p>
                  <span
                    className="oi_header_details_editing--close material-icons"
                    onClick={() => this.setState({ isEditing: "", isEditingValue: "" })}>
                    close
                  </span>
                  <div className="oi_header_details_editing_add">
                    <input
                      className="oi_header_details_editing_add_input"
                      type="text"
                      value={this.state.isEditingValue}
                      onChange={(e) => this.setState({ isEditingValue: replace(e.target.value.trim().toUpperCase(), /[^A-Za-z0-9]/g, "") })}
                    />
                    <div
                      className={`oi_header_details_editing_add_button ${
                        isAddingDiscountCode ? "oi_header_details_editing_add_button--disabled" : ""
                      }`}
                      onClick={this.addDiscountCode}>
                      ADD
                    </div>
                  </div>
                  {isAddingDiscountCode ? <Loading /> : null}
                  <div className="oi_header_details_editing_value">
                    <p className="oi_header_details_editing_value--val">{influencer.discount_code}</p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="oi_header_info_details_notes" onClick={() => this.setState({ showNotesInfluencer: true })}>
              <span className="material-icons">comment</span>
              <p>notes</p>
            </div>
          </div>
        </div>

        <div className="outreach_influencer_header_tags">
          <ALCampaignInfluencer
            idInfluencer={influencer._id}
            campaignsInfluencer={influencer.campain_tags}
            showMostPopular={false}
            user={this.props.user}
          />

          <ALTagInfluencer idInfluencer={influencer._id} tagsInfluencer={influencer.tags} showMostPopular={false} user={this.props.user} />
        </div>
        <div className="outreach_influencer_header_platform">
          {this.state.isRefreshingInfluencer ? (
            <div className="oi_header_platform--loading">
              <Loading />
            </div>
          ) : (
            <>
              <ALSwitch
                options={[
                  {
                    key: "youtube",
                    name: (
                      <div className="oi_header_platform_logo_container">
                        <img className="oi_header_platform_logo" src={iconYouTube} alt="youtube" height="26px" />
                        {influencer.youtube.accounts.length ? (
                          <span className="oi_header_platform_link_account_number">{influencer.youtube.accounts.length}</span>
                        ) : null}
                      </div>
                    ),
                  },
                  {
                    key: "podcast",
                    name: (
                      <div className="oi_header_platform_logo_container">
                        <img className="oi_header_platform_logo invert-on-select" src={iconPodcast} alt="podcast" />
                        {influencer.podcast?.accounts?.length ? (
                          <span className="oi_header_platform_link_account_number">{influencer.podcast?.accounts?.length || ""}</span>
                        ) : null}
                      </div>
                    ),
                  },
                  {
                    key: "instagram",
                    name: (
                      <div className="oi_header_platform_logo_container">
                        <img className="oi_header_platform_logo" src={iconInsta} alt="instagram" />
                        {influencer.instagram?.accounts?.length ? (
                          <span className="oi_header_platform_link_account_number">{influencer.instagram?.accounts?.length || ""}</span>
                        ) : null}
                      </div>
                    ),
                  },
                  {
                    key: "tiktok",
                    name: (
                      <div className="oi_header_platform_logo_container">
                        <img className="oi_header_platform_logo" src={iconTikTok} alt="tiktok" />
                        {influencer.tiktok?.accounts?.length ? (
                          <span className="oi_header_platform_link_account_number">{influencer.tiktok?.accounts?.length || ""}</span>
                        ) : null}
                      </div>
                    ),
                  },
                ]}
                value={displayedPlatform}
                onChange={(key) => {
                  this.setState({ displayedPlatform: key });
                }}
              />
              {displayedPlatform === "podcast" ? (
                hasPodcast ? (
                  <div className="oi_header_platform">
                    <div className="oi_header_platform_details">
                      <p className="oi_header_platform_details_title">
                        <span
                          className={`oi_header_info_details--edit material-icons ${
                            isEditing == "podcast" ? "oi_header_info_details--edit-visible" : null
                          }`}
                          onClick={() => this.setState({ isEditing: "podcast" })}>
                          create
                        </span>
                        {podcastData?.name || podcastData}
                      </p>
                      <p className="oi_header_platform_details_broadcasters">
                        {podcastData.broadcasters.map((broadcaster, i) => (
                          <React.Fragment key={i}>
                            {i > 0 ? <span key={"span_" + i}>, </span> : null}
                            <a
                              key={i}
                              className="oi_header_platform_details_broadcasters_link"
                              href={broadcaster.url}
                              target="_blank"
                              rel="noopener noreferrer">
                              {broadcaster.name} profile
                            </a>
                          </React.Fragment>
                        ))}
                      </p>
                      <div className="oi_header_platform_details_networks">
                        {podcastData.networks?.map((network, i) => (
                          <React.Fragment key={i}>
                            {i > 0 ? <span key={"span_" + i}>, </span> : null}
                            <p key={network} className="oi_header_platform_details_networks_name">
                              {network}
                            </p>
                          </React.Fragment>
                        ))}
                      </div>
                      {isEditing === "podcast" ? (
                        <div className="oi_header_details_editing oi_header_details_editing--right oi_header_details_editing--podcast">
                          <p className="oi_header_details_editing--title">Podcast</p>
                          <span
                            className="oi_header_details_editing--close material-icons"
                            onClick={() => this.setState({ isEditing: "", isEditingValue: "" })}>
                            close
                          </span>
                          {inputPodcastWarning ? (
                            <p className="oi_header_details_editing_add_input-message oi_header_details_editing_add_input-message--warning">
                              {inputPodcastWarning}
                            </p>
                          ) : null}
                          {inputPodcastError ? (
                            <p className="oi_header_details_editing_add_input-message oi_header_details_editing_add_input-message--error">
                              {inputPodcastError}
                            </p>
                          ) : null}
                          <div className="oi_header_details_editing_add">
                            <div className="oi_header_details_editing_add_input-container">
                              <input
                                className="oi_header_details_editing_add_input"
                                type="text"
                                placeholder="Podcast Name"
                                value={newPodcastName}
                                onChange={(e) => this.podcastSearchInput(e.target.value, "newPodcastName")}
                              />
                              <input
                                className="oi_header_details_editing_add_input"
                                type="text"
                                placeholder="Networks separated by commas"
                                value={newPodcastNetworks}
                                onChange={(e) => this.podcastSearchInput(e.target.value, "newPodcastNetworks")}
                              />
                              <input
                                className="oi_header_details_editing_add_input oi_header_details_editing_add_input--full-line"
                                type="text"
                                placeholder="Podcast URLs separated by commas"
                                value={newPodcastURLs}
                                onChange={(e) => this.podcastSearchInput(e.target.value, "newPodcastURLs")}
                              />
                            </div>
                            <div
                              className={`oi_header_details_editing_add_button ${
                                !inputPodcastError && newPodcastName && newPodcastName.length > 0 && newPodcastURLs && newPodcastURLs.length
                                  ? ""
                                  : "oi_header_platform_update_button--disable"
                              }`}
                              onClick={this.onAddPodcast}>
                              ADD
                            </div>
                          </div>

                          {influencer.podcast.accounts.map((podcast, i) => {
                            return (
                              <div className="oi_header_details_editing_value" key={`emails_${i}`}>
                                {typeof podcast === "string" ? (
                                  <p className="oi_header_details_editing_value--val">
                                    {i}: {podcast.name || podcast}
                                  </p>
                                ) : (
                                  <div className="pd_edit_container">
                                    <a
                                      className="pd_edit_container_link"
                                      href={podcast.broadcasters[0].url}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      <img
                                        className="pd_edit_container_link_img"
                                        src={"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}
                                      />
                                    </a>
                                    <div className="pd_edit_container_content">
                                      <p className="pd_edit_container_content_title">{podcast.name}</p>
                                      <p className="pd_edit_container_content_subtitle">
                                        {podcast.broadcasters.map((broadcaster, i) => (
                                          <React.Fragment key={i}>
                                            {i > 0 ? <span key={"span_" + i}>, </span> : null}
                                            <a
                                              key={i}
                                              className="oi_header_platform_details_broadcasters_link"
                                              href={broadcaster.url}
                                              target="_blank"
                                              rel="noopener noreferrer">
                                              {broadcaster.name} profile
                                            </a>
                                          </React.Fragment>
                                        ))}
                                      </p>
                                      <div className="pd_edit_container_content_subtitle">
                                        {podcast.networks?.map((network, i) => (
                                          <React.Fragment key={i}>
                                            {i > 0 ? <span key={"span_" + i}>, </span> : null}
                                            <p key={network} className="oi_header_platform_details_networks_name">
                                              {network}
                                            </p>
                                          </React.Fragment>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <span
                                  className="oi_header_details_editing_value--del material-icons"
                                  onClick={() =>
                                    this.props.editInfoInfluencer(
                                      "DELETE",
                                      "podcast",
                                      influencer.podcast.accounts.map((account) => (typeof account === "string" ? account : account._id)),
                                      i,
                                      influencer["_id"]
                                    )
                                  }>
                                  delete
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="oi_header_platform_update">
                    {inputPodcastWarning ? (
                      <p className="oi_header_details_editing_add_input-message oi_header_details_editing_add_input-message--warning">
                        {inputPodcastWarning}
                      </p>
                    ) : null}
                    {inputPodcastError ? (
                      <p className="oi_header_details_editing_add_input-message oi_header_details_editing_add_input-message--error">
                        {inputPodcastError}
                      </p>
                    ) : null}
                    <input
                      className="oi_header_platform_update_input"
                      type="text"
                      placeholder="Podcast Name"
                      value={newPodcastName}
                      onChange={(e) => this.podcastSearchInput(e.target.value, "newPodcastName")}
                    />
                    <input
                      className="oi_header_platform_update_input"
                      type="text"
                      placeholder="Podcast URLs separated by commas"
                      value={newPodcastURLs}
                      onChange={(e) => this.podcastSearchInput(e.target.value, "newPodcastURLs")}
                    />
                    <input
                      className="oi_header_platform_update_input"
                      type="text"
                      placeholder="Networks separated by commas"
                      value={newPodcastNetworks}
                      onChange={(e) => this.podcastSearchInput(e.target.value, "newPodcastNetworks")}
                    />
                    <div
                      className={`oi_header_platform_update_button ${
                        !inputPodcastError && newPodcastName && newPodcastName.length > 0 && newPodcastURLs && newPodcastURLs.length
                          ? ""
                          : "oi_header_platform_update_button--disable"
                      }`}
                      onClick={this.onAddPodcast}>
                      Update
                    </div>
                  </div>
                )
              ) : null}
              {displayedPlatform === "tiktok" ? (
                hasTiktok ? (
                  <div className="oi_header_platform">
                    <div className="oi_header_platform_details">
                      <a
                        className="oi_header_platform_link"
                        href={`https://www.tiktok.com/@${tiktokData.profileId || tiktokData}`}
                        target="_blank"
                        title="Open TikTok profile"
                        rel="noopener noreferrer">
                        <span className="material-icons">launch</span>
                      </a>
                      <p className="oi_header_platform_details_title">
                        <span
                          className={`oi_header_info_details--edit material-icons ${
                            isEditing == "tiktok" ? "oi_header_info_details--edit-visible" : null
                          }`}
                          onClick={() => this.setState({ isEditing: "tiktok" })}>
                          create
                        </span>
                        {tiktokData?.fullName || tiktokData?.profileId || tiktokData}
                      </p>
                      {isEditing === "tiktok" ? (
                        <div className="oi_header_details_editing oi_header_details_editing--right">
                          <p className="oi_header_details_editing--title">Tiktok</p>
                          <span
                            className="oi_header_details_editing--close material-icons"
                            onClick={() => this.setState({ isEditing: "", isEditingValue: "" })}>
                            close
                          </span>
                          <div className="oi_header_details_editing_add">
                            <input
                              className="oi_header_details_editing_add_input"
                              type="text"
                              placeholder="Handle (without the @)"
                              value={this.state.isEditingValue}
                              onChange={(e) => this.setState({ isEditingValue: e.target.value })}
                            />
                            <div
                              className="oi_header_details_editing_add_button"
                              onClick={() => this.props.editInfoInfluencer("ADD", "tiktok", isEditingValue, null, influencer["_id"])}>
                              ADD
                            </div>
                          </div>

                          {influencer.tiktok.accounts.map((tiktok, i) => {
                            return (
                              <div className="oi_header_details_editing_value" key={`emails_${i}`}>
                                <p className="oi_header_details_editing_value--val">
                                  {i}: {tiktok.profileId || tiktok}
                                </p>
                                <span
                                  className="oi_header_details_editing_value--del material-icons"
                                  onClick={() =>
                                    this.props.editInfoInfluencer(
                                      "DELETE",
                                      "tiktok",
                                      influencer.tiktok.accounts.map((account) => (typeof account === "string" ? account : account.profileId)),
                                      i,
                                      influencer["_id"]
                                    )
                                  }>
                                  delete
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}

                      {tiktokData && tiktokData?.stats ? (
                        <>
                          <p className="oi_header_platform_details_views">{new Intl.NumberFormat().format(tiktokData?.stats?.followers)} followers</p>
                          <p className="oi_header_platform_details_views">{new Intl.NumberFormat().format(tiktokData?.stats?.following)} following</p>
                          <p className="oi_header_platform_details_views">{new Intl.NumberFormat().format(tiktokData?.stats?.likes)} likes</p>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="oi_header_platform_update">
                    <input
                      className="oi_header_platform_update_input"
                      type="text"
                      placeholder="Please add influencer handle without @"
                      value={newTiktokHandle || ""}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue.includes("tiktok.com/@")) {
                          newValue = newValue.split("tiktok.com/@")[1].split("/")[0].split("?")[0].split("#")[0].split("/")[0] || newValue;
                        }
                        this.setState({ newTiktokHandle: newValue });
                      }}
                    />
                    <div
                      className={`oi_header_platform_update_button ${
                        newTiktokHandle && newTiktokHandle.length > 0 ? "" : "oi_header_platform_update_button--disable"
                      }`}
                      onClick={() => {
                        if (newTiktokHandle.includes("tiktok.com")) {
                          NotificationManager.error("Couldn't add URL, expect handle instead.", "Failed", 1200);
                          return;
                        }
                        if (newTiktokHandle && newTiktokHandle.length > 0) {
                          this.props.updatePlatformId("tiktok", newTiktokHandle);
                        }
                      }}>
                      Update
                    </div>
                  </div>
                )
              ) : null}
              {displayedPlatform === "instagram" ? (
                hasInsta ? (
                  <div className="oi_header_platform">
                    <div className="oi_header_platform_details">
                      <a
                        className="oi_header_platform_link"
                        href={`https://instagram.com/${instaData.profileId || instaData}`}
                        target="_blank"
                        title="Open Instagram profile"
                        rel="noopener noreferrer">
                        <span className="material-icons">launch</span>
                      </a>
                      <p className="oi_header_platform_details_title">
                        <span
                          className={`oi_header_info_details--edit material-icons ${
                            isEditing == "instagram" ? "oi_header_info_details--edit-visible" : null
                          }`}
                          onClick={() => this.setState({ isEditing: "instagram" })}>
                          create
                        </span>
                        {instaData?.fullName || instaData?.profileId || instaData}
                      </p>
                      {isEditing == "instagram" ? (
                        <div className="oi_header_details_editing oi_header_details_editing--right">
                          <p className="oi_header_details_editing--title">Instagram</p>
                          <span
                            className="oi_header_details_editing--close material-icons"
                            onClick={() => this.setState({ isEditing: "", isEditingValue: "" })}>
                            close
                          </span>
                          <div className="oi_header_details_editing_add">
                            <input
                              className="oi_header_details_editing_add_input"
                              type="text"
                              placeholder="Handle (without the @)"
                              value={this.state.isEditingValue}
                              onChange={(e) => this.setState({ isEditingValue: e.target.value })}
                            />
                            <div
                              className="oi_header_details_editing_add_button"
                              onClick={() => this.props.editInfoInfluencer("ADD", "instagram", isEditingValue, null, influencer["_id"])}>
                              ADD
                            </div>
                          </div>

                          {influencer.instagram.accounts.map((instagram, i) => {
                            return (
                              <div className="oi_header_details_editing_value" key={`emails_${i}`}>
                                <p className="oi_header_details_editing_value--val">
                                  {i}: {typeof instagram === "string" ? instagram : instagram.profileId}
                                </p>
                                <span
                                  className="oi_header_details_editing_value--del material-icons"
                                  onClick={() =>
                                    this.props.editInfoInfluencer(
                                      "DELETE",
                                      "instagram",
                                      influencer.instagram.accounts.map((account) => (typeof account === "string" ? account : account.profileId)),
                                      i,
                                      influencer["_id"]
                                    )
                                  }>
                                  delete
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}

                      {instaData && instaData.stats ? (
                        <>
                          <p className="oi_header_platform_details_views">{new Intl.NumberFormat().format(instaData.stats.followers)} followers</p>
                          <p className="oi_header_platform_details_views">{new Intl.NumberFormat().format(instaData.stats.following)} following</p>
                          <p className="oi_header_platform_details_views">{new Intl.NumberFormat().format(instaData.stats.posts)} posts</p>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="oi_header_platform_update">
                    <input
                      className="oi_header_platform_update_input"
                      type="text"
                      placeholder="Please add influencer handle"
                      value={newInstagramHandle}
                      onChange={(e) => {
                        this.setState({ newInstagramHandle: e.target.value.toLowerCase() });
                      }}
                    />
                    <div
                      className={`oi_header_platform_update_button ${
                        newInstagramHandle && newInstagramHandle.length > 0 ? "" : "oi_header_platform_update_button--disable"
                      }`}
                      onClick={() =>
                        newInstagramHandle && newInstagramHandle.length > 0 ? this.props.updatePlatformId("instagram", newInstagramHandle) : null
                      }>
                      Update
                    </div>
                  </div>
                )
              ) : null}
              {displayedPlatform === "youtube" ? (
                hasYouTube ? (
                  <div className="oi_header_platform">
                    <div className="oi_header_platform_details">
                      <a
                        className="oi_header_platform_link"
                        href={`https://www.youtube.com/channel/${youtubeData.channelId || youtubeData}/videos`}
                        target="_blank"
                        title="Open YouTube channel"
                        rel="noopener noreferrer">
                        <span className="material-icons">launch</span>
                      </a>

                      <p className="oi_header_platform_details_title">
                        <span
                          className={`oi_header_info_details--edit material-icons ${
                            isEditing === "youtube" ? "oi_header_info_details--edit-visible" : null
                          }`}
                          onClick={() => this.setState({ isEditing: "youtube" })}>
                          create
                        </span>
                        {typeof youtubeData === "string" ? youtubeData : youtubeData.title || youtubeData.channelId}
                      </p>
                      {isEditing === "youtube" ? (
                        <div className="oi_header_details_editing oi_header_details_editing--youtube oi_header_details_editing--right">
                          <p className="oi_header_details_editing--title">YouTube</p>
                          <span
                            className="oi_header_details_editing--close material-icons"
                            onClick={() => this.setState({ isEditing: "", isEditingValue: "" })}>
                            close
                          </span>
                          <div className="oi_header_details_editing_add">
                            <input
                              className="oi_header_details_editing_add_input"
                              placeholder="ChannelId"
                              type="text"
                              value={this.state.isEditingValue}
                              onChange={(e) => this.setState({ isEditingValue: e.target.value })}
                            />
                            <div
                              className="oi_header_details_editing_add_button"
                              onClick={() => {
                                if (!isEditingValue) return;
                                if (isEditingValue.includes("youtube.com")) {
                                  return NotificationManager.error("Can't add URL, please input channel ID", "Error — Not supported");
                                }
                                const allAccountsID = influencer.youtube.accounts.map((account) =>
                                  typeof account === "string" ? account : account.channelId
                                );
                                if (allAccountsID.includes(isEditingValue)) {
                                  return NotificationManager.error("ID already linked to that influencer", "Error — Already exist");
                                }
                                this.props.editInfoInfluencer("ADD", "youtube", isEditingValue, null, influencer["_id"]);
                              }}>
                              ADD
                            </div>
                          </div>

                          {influencer.youtube.accounts.map((youtube, i) => {
                            return (
                              <div className="oi_header_details_editing_value" key={`emails_${i}`}>
                                {typeof youtube === "string" 
                                ? (
                                  <div className="yt_edit_container">
                                    <a
                                      style={{ color: "#000000" }}
                                      href={`https://www.youtube.com/channel/${youtube}/videos`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      title={`https://www.youtube.com/channel/${youtube}/videos`}>
                                      id: '{youtube}'
                                    </a>
                                  </div>
                                )
                                : (
                                  <div className="yt_edit_container">
                                    <a
                                      className="yt_edit_container_link"
                                      href={`https://www.youtube.com/channel/${youtube.channelId}/videos`}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      <img className="yt_edit_container_link_img" src={youtube.pictureURL} />
                                    </a>
                                    <div className="yt_edit_container_content">
                                      <p className="yt_edit_container_content_title">{youtube.title ? youtube.title : null}</p>
                                      <p className="yt_edit_container_content_subtitle">
                                        {youtube.stats && youtube.stats.views ? `${new Intl.NumberFormat().format(youtube.stats.views)} views` : null}
                                      </p>
                                      <p className="yt_edit_container_content_subtitle">
                                        {youtube.stats && youtube.stats.subscribers
                                          ? `${new Intl.NumberFormat().format(youtube.stats.subscribers)} subscribers`
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                  )}
                                <span
                                  className="oi_header_details_editing_value--del material-icons"
                                  onClick={() =>
                                    this.props.editInfoInfluencer(
                                      "DELETE",
                                      "youtube",
                                      influencer.youtube.accounts.map((account) => (typeof account === "string" ? account : account.channelId)),
                                      i,
                                      influencer["_id"]
                                    )
                                  }>
                                  delete
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                      <p className="oi_header_platform_details_views">
                        {youtubeData.stats ? new Intl.NumberFormat().format(youtubeData.stats.views) : "-"} views
                      </p>

                      {this.state.ytData.isLoading ? (
                        <div className="yt_data--loading">
                          <Loading />
                          <p className="yt_data--loading-details">Loading data...</p>
                        </div>
                      ) : this.state.ytData.hasError ? (
                        <div className="yt_data--error" onClick={() => {
                          this.props.forceUpdateInf();
                          this.setState({ isRefreshingInfluencer: true });
                        }}>
                          <span className="material-icons">cached</span>
                          <p className="yt_data--loading-details">Error (cannot get YouTube Data), refresh</p>
                        </div>
                      ) : (
                        <div className="yt_data_container">
                          <div className="yt_data_container_line">
                            <div className="yt_data_container_data">
                              <p className="yt_data_container_data-text">{this.state.ytData.trimMeanViews} avg views</p>
                              <p className="yt_data_container_data-info">
                                Trimmed Mean. Compute the mean of the middle 80% of your data, ignoring the top and bottom 10%
                              </p>
                            </div>
                            <div className="yt_data_container_data">
                              <p className="yt_data_container_data-text">{this.state.ytData.trimMeanLikes} avg likes</p>
                              <p className="yt_data_container_data-info">
                                Trimmed Mean. Compute the mean of the middle 80% of your data, ignoring the top and bottom 10%
                              </p>
                            </div>
                          </div>
                          <div className="yt_data_container_line">
                            <div className="yt_data_container_data">
                              <p className="yt_data_container_data-text">{this.state.ytData.trimMeanDuration} avg duration</p>
                              <p className="yt_data_container_data-info">
                                Trimmed Mean. Compute the mean of the middle 80% of your data, ignoring the top and bottom 10%
                              </p>
                            </div>
                            <div className="yt_data_container_data">
                              <p className="yt_data_container_data-text">{this.state.ytData.trimMeanDislikes} avg dislikes</p>
                              <p className="yt_data_container_data-info">
                                Trimmed Mean. Compute the mean of the middle 80% of your data, ignoring the top and bottom 10%
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="oi_header_platform_update">
                    <input
                      className="oi_header_platform_update_input"
                      type="text"
                      placeholder="Please add influencer channelId"
                      onChange={(e) => {
                        this.setState({ newChannelId: e.target.value });
                      }}
                    />
                    <div
                      className={`oi_header_platform_update_button ${
                        newChannelId && newChannelId.length > 0 ? "" : "oi_header_platform_update_button--disable"
                      }`}
                      onClick={() => (newChannelId && newChannelId.length > 0 ? this.props.updatePlatformId("youtube", newChannelId) : null)}>
                      Update
                    </div>
                  </div>
                )
              ) : null}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default OutreachHeader;
