import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { differenceInDays, format } from "date-fns";
import NotificationManager from "components/al_components/notification/NotificationManager";
import ALDateRange from "components/al_components/date_range";
import HeaderComponent from "../HeaderComponent";
import ScrappingGraph from "./ScrappingGraph";
import { ALLoading } from "components/ALComponents";
import { splitIntervalIntoChunks } from "utils";
import "./ScrappingReporting.css";

function ScrappingReporting({ ...props }) {
  const [loadingStatus, setLoadingStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dataScrapping, setDataScrapping] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [quickViewReporting, setQuickViewReporting] = useState(null);

  async function getData({ startDate, endDate }) {
    const batchSize = 5; // 5 days batch
    const chunksOfDates = splitIntervalIntoChunks(startDate, endDate, batchSize);
    const _types = "types=DECLINED_404&types=DECLINED_VIDEOS&types=DECLINED_RECENT&types=DECLINED_COUNTRY&types=DECLINED_VIEWS&types=QUALIFICATION";

    setIsLoading(true);
    let allData = [];
    for (const [index, chunk] of chunksOfDates.entries()) {
      const { start, end } = chunk;
      const data = await axios.get(`/api/v1/event/reports?day_from=${format(start, "yyyy-MM-dd")}&day_to=${format(end, "yyyy-MM-dd")}&${_types}`);
      if (data.status === 200) {
        allData = [...allData, ...(data?.data?.result || [])];
      } else {
        // trigger notification error
        NotificationManager.error("Couldn't process request", "Error", 2000);
        return null;
      }
      setLoadingStatus(`Days loaded: ${(index + 1) * batchSize}/${differenceInDays(endDate, startDate)}`);
    }
    setIsLoading(false);
    setLoadingStatus("");
    return allData;
  }

  useEffect(() => {
    // Get Data from Range
    getData(dateRange).then((r) => {
      setDataScrapping(r);
    });
  }, [dateRange]);

  useEffect(() => {
    if (dataScrapping) {
      let _quickViewData = [];

      let totalChannels = dataScrapping.reduce((red, r) => {
        red += r?.nsamples;
        return red;
      }, 0);
      _quickViewData.push({
        key: "TOTAL",
        value: totalChannels,
        percentage: 100,
      });

      // Concatenate Data per type (DECLINED_404, DECLINED_VIEW)
      let groupByType = _.groupBy(dataScrapping, "type");

      Object.keys(groupByType).map((type) => {
        let values = groupByType[type].reduce((red, i) => {
          red += i?.nsamples;
          return red;
        }, 0);

        _quickViewData.push({
          key: type,
          value: values,
          percentage: ((values / totalChannels) * 100).toFixed(2),
        });
      });
      setQuickViewReporting(_quickViewData);
    }
  }, [dataScrapping]);

  return (
    <>
      <HeaderComponent title="Report Scrapping" to="/a/dashboard/" toTitle="Home" />

      <ALDateRange onChange={setDateRange} {...dateRange} />

      {dataScrapping && !isLoading ? (
        <>
          {quickViewReporting && (
            <div className="scrapping_report">
              {quickViewReporting.map((r, i) => {
                return (
                  <div className="scrapping_report_data" key={`scrapping_report_data_${i}`}>
                    <p className="scrapping_report_data--title">{r?.key}</p>
                    <p className="scrapping_report_data--value">{Number(r?.value).toLocaleString()}</p>
                    <p className="scrapping_report_data--subvalue">{r?.percentage}%</p>
                  </div>
                );
              })}
            </div>
          )}

          <ScrappingGraph dataFromRange={dataScrapping} dateRange={dateRange} />
        </>
      ) : (
        <ALLoading alt text={`Loading data.... ${loadingStatus}`} />
      )}
    </>
  );
}

export default ScrappingReporting;
