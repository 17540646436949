import { useState } from "react";
import "./quickSnapshot/QuickSnapshot.css";

function QuickSnapshot({ isLoading, influencers }) {
  const [isExpanded, setIsExpanded] = useState(true);

  const deals = influencers?.flatMap((influencer) => influencer.deals) || [];
  let dealsPerStatus = {};
  deals.forEach((deal) => {
    // Exclude "declined" and "published" statuses
    if (deal.status === "declined" || deal.status === "published") {
      return;
    }

    if (dealsPerStatus[deal.status]) {
      dealsPerStatus[deal.status]++;
    } else {
      dealsPerStatus[deal.status] = 1;
    }
  });

  if (isLoading) {
    return null;
  }

  return (
    <section className="quick-snapshot">
      <div className="quick-snapshot-header">
        <h3>Quick snapshot</h3>
        <span
          onClick={() => setIsExpanded(!isExpanded)}
          className="material-icons"
        >
          {isExpanded ? "expand_less" : "expand_more"}
        </span>
      </div>
      {isExpanded && (
        <div className="quick-snapshot-elements">
          {Object.keys(dealsPerStatus).map((status) => (
            <div className="quick-snapshot-element" key={status}>
              <div className="quick-snapshot-element-title">{status}</div>
              <div className="quick-snapshot-element-count">
                {dealsPerStatus[status]}
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

export default QuickSnapshot;
