import React, { useState } from "react";
import { formatDistanceToNow, format, differenceInHours } from "date-fns";
import * as Sentry from "@sentry/react";
import { ErrorBoundaryContent } from "components/ALComponents";
import { parseMail } from "./helpers";

import "./Email.css";

function Email({ email, user, index, generateReplyAtIndex }) {
  const [openEmail, setOpenEmail] = useState(false);

  const parsedEmail = parseMail(email);

  if (!parsedEmail.htmlContent) {
    return <div>No HTML content found</div>;
  }

  const differenceInHoursFromNow = differenceInHours(new Date(), new Date(parseInt(email.internalDate)));
  const fromEmail = email.payload.headers.find((header) => header.name === 'From').value;
  const subjectEmail = email.payload.headers.find((header) => header.name === 'Subject').value;
  return (
    <div className="email">

      <div className="email_preview" onClick={() => setOpenEmail(!openEmail)}>
        <div className="email_preview_header">
          <p className="email_preview_header_subject">{subjectEmail}</p>
          <p className="email_preview_header_sender"><strong>From:</strong> {fromEmail}</p>
        </div>

        <div className="email_preview_time">
          {differenceInHoursFromNow > 24 ? (
            differenceInHoursFromNow > 120 ? (
              <span>{format(new Date(parseInt(email.internalDate)), "ccc M/d/yy")}</span>
            ) : (
              <span>{format(new Date(parseInt(email.internalDate)), "iii, p")}</span>
            )
          ) : (
            <span>{format(new Date(parseInt(email.internalDate)), "p")}</span>
          )}
          <br/>
          <span>({formatDistanceToNow(new Date(parseInt(email.internalDate)))})</span>
        </div>
      </div>

      <div className="email_content_trimmed">
        {parsedEmail.filteredTextContent.map((line, index) => {
          return (
            <React.Fragment key={index}>
              {line.trim() === "" ? <br/> : line}
            </React.Fragment>
          )
        })}
      </div>

      <div className={`email_content email_content${openEmail ? "--open" : "--close"}`}>
        <div dangerouslySetInnerHTML={{ __html: parsedEmail.htmlContent }} />
      </div>

      {index === 0 && user.email !== email.payload.headers.find((header) => header.name === 'From').value.match(/<([^>]+)>/)[1] && (
        <p className="email_content_warning">🚨🚨 Your answer might be needed! 🚨🚨</p>
      )}
      <button className={`email-icon email-icon--${openEmail ? "--open" : "--close"}`} onClick={() => setOpenEmail(!openEmail)}>
        {openEmail ? "CLOSE" : "OPEN"} ORIGINAL EMAIL THREAD<span className="material-icons">arrow_forward_ios</span>
      </button>

      {index !== 0 && !(fromEmail.includes("analuisa.com") || fromEmail.includes("analuisany.com")) && (
        <button
          className={`email_reply_ai`}
          onClick={() => generateReplyAtIndex(index)}
        >REPLY <span className="material-icons">assistant</span></button>
      )}
    </div>
  );
}

export default (props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag("tool", "Email");
      scope.setTag("user", props.user.email);
    }}
    fallback={ErrorBoundaryContent}>
    <Email {...props} />
  </Sentry.ErrorBoundary>
);
