import React from "react";
import HeaderComponent from "../HeaderComponent";
import axios from 'axios';
import Loading from "../Loading";
import "./ChannelAverageViews.css";
import moment from 'moment';
import _ from 'lodash';
import instaLogo from '../../assets/insta.png';
import ytLogo from '../../assets/youtube_logo.png';

class ChannelPredictionCocreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analuis_api__API_KEY: "AIzaSyBwtHEqT2ltrlL98tX1D2RGfquaYkriCQs", // key from "analuisa api" project
      isLoading: false,
      message: "",
      channelId: null,
      handle: null,
      isLoadingChannel: false,
      youtubeData: null,
      instagramData: null,
      dataPrediction: null,
    };
    this.handlePredictionCocreation = this.handlePredictionCocreation.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  handleChangeInput(event) {
    if (event.target) {
      const value = event.target.value;
      if (event.target.id === 'youtube_channelId') {
        this.setState({ channelId: value });
      }
      if (event.target.id === 'handle_instagram') {
        this.setState({ handle: value });
      }
    }
  }

  async handlePredictionCocreation() {
    const { channelId, handle } = this.state;
    this.setState({ isLoadingChannel: true });
    let subscriberYT = null;
    let listVideosYT = [];
    await axios.get(`/api/v1/aws/channel/${channelId}`, {params: {"force_update": true}})
      .then((rChannel) => {
        console.log(rChannel);
        subscriberYT = rChannel.data.result.stats.subscribers;
        listVideosYT = rChannel.data.result.latestVideos.list;
        this.setState({ youtubeData: rChannel.data.result });
      }).catch((error) => {
        console.log("catch /api/v1/aws/channel/:idChannel");
        console.log(error);
      });


    const listVideosYTStr = listVideosYT.join();
    const resYouTubeData = await axios.get(`https://www.googleapis.com/youtube/v3/videos?key=${this.state.analuis_api__API_KEY}&part=snippet,statistics&id=${listVideosYTStr}`)
      .then((resYouTube) => {
        return resYouTube.data.items;
      })
      .catch((error) => {
        console.log("catch YouTube videos");
        console.log(error);
      });


    const averageArr = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;


    let arrViews = [];
    let arrComments = [];
    let arrLikes = [];
    let arrDislikes = [];
    let nbVideosLastMonth = 0;
    resYouTubeData.map((video, i) => {
      console.log(video);
      arrViews.push(parseInt(video.statistics.viewCount));
      if (typeof(video.statistics.commentCount) == "undefined") {
        arrComments.push(0);
      } else {
        arrComments.push(parseInt(video.statistics.commentCount));
      }
      if (typeof(video.statistics.likeCount) == "undefined") {
        arrLikes.push(0);
      } else {
        arrLikes.push(parseInt(video.statistics.likeCount));
      }
      if (typeof(video.statistics.dislikeCount) == "undefined") {
        arrDislikes.push(0);
      } else {
        arrDislikes.push(parseInt(video.statistics.dislikeCount));
      }
      const nbDaysDiff = moment().diff(moment(video.snippet.publishedAt), 'days');
      if (nbDaysDiff <= 30) {
        nbVideosLastMonth += 1;
      }
    });
    let avgViews = averageArr(arrViews);
    let avgComments = averageArr(arrComments);
    let avgLikes = averageArr(arrLikes);
    let avgDislikes = averageArr(arrDislikes);
    console.log(avgViews);
    console.log(avgComments);
    console.log(avgLikes);
    console.log(avgDislikes);


    let engagementInstagram = null;
    let nbInstaPostLastMonth = 0;
    await axios({
      method: 'GET',
      url: `https://www.instagram.com/${handle}`
    })
      .then((r) => {
        if (r.status === 200) {
          let raw = r.data["split"]("window._sharedData = ")[1]["split"](";</scrip")[0];
          let user = JSON["parse"](raw)["entry_data"]["ProfilePage"][0]["graphql"]["user"];

          this.setState({ instagramData: user });

          const nbPosts = user["edge_owner_to_timeline_media"]["edges"].length;
          const followers = user["edge_followed_by"]["count"];
          let totalComment = 0;
          let totalLike = 0;

          user["edge_owner_to_timeline_media"]["edges"].map((post, i) => {
            // console.log(post);
            const nbDaysDiff = moment().diff(moment(new Date(post["node"]["taken_at_timestamp"] * 1000)), 'days');
            if (nbDaysDiff <= 30) {
              nbInstaPostLastMonth += 1;
            }
            totalComment += post["node"]["edge_media_to_comment"]["count"];
            totalLike += post["node"]["edge_liked_by"]["count"];
          });

          engagementInstagram = ((((totalLike + (totalComment * 2)) / followers) / nbPosts)* 100).toFixed(2);
        }
      })
      .catch((error) => {
        console.log("catch /api/v1/aws/channel/:idChannel");
        console.log(error);
      });

    // console.log(avgViews);
    // console.log(avgLikes);
    // console.log(avgDislikes);
    // console.log(avgComments);

    const avgViewsLast30_coef = 0.000284232212529246;
    const percentageLikes_coef = 8.84095281463047;
    const instaEng_coef = 7.65099396440131;
    const youtubeSubscriber_coef = 0.0000460818802707763;
    const avgCommentsLast30_coef = -0.11749654228673;
    const videosLastMonth_coef = 12.8541163418753;
    const intercept_coef = 136.060493613524;


    // BASE VALUE (AKA INPUT)
    // console.log("B = avgViews: ", avgViews);                          // B
    // console.log("C = avgLikes: ", avgLikes);                           // C
    // console.log("D = avgDislikes: ", avgDislikes);                    // D
    const instagramEng = (engagementInstagram / 100) * nbInstaPostLastMonth;
    // console.log("E = instagramEng: ", instagramEng);                  // E
    const youtubePercentageLikes = avgLikes / (avgLikes + avgDislikes);
    // console.log("F = youtubePercentageLikes", youtubePercentageLikes); // F
    // console.log("G = subscriberYT: ", subscriberYT);                  // G
    // console.log("H = engagementInstagram: ", engagementInstagram);     // H
    // console.log("I = avgComments: ", avgComments);                    // I
    const youtubeEngagement = (avgLikes + avgDislikes + (avgComments * avgComments)) / avgViews;
    // console.log("J = youtubeEngagement: ", youtubeEngagement);
    // console.log("K = nbVideosLastMonth: ", nbVideosLastMonth);        // K
    // console.log("L = nbInstaPostLastMonth: ", nbInstaPostLastMonth);  // L


    const avgViews_w_coef = ((avgViewsLast30_coef * avgViews) > 70) ? 70 : (avgViewsLast30_coef * avgViews);
    // console.log("B = avgViews_w_coef: ", avgViews_w_coef);
    const percentageLikes_w_coef = percentageLikes_coef * youtubePercentageLikes;
    // console.log("C = percentageLikes_w_coef: ", percentageLikes_w_coef);
    const instaEng_w_coef = instaEng_coef * instagramEng;
    // console.log("D = instaEng_w_coef", instaEng_w_coef);
    const youtubeSubscriber_w_coef = youtubeSubscriber_coef * subscriberYT;
    // console.log("E = youtubeSubscriber_w_coef", youtubeSubscriber_w_coef);
    const avgCommentsLast30_w_coef = ((avgCommentsLast30_coef * avgComments) < -150) ? -150 : (avgCommentsLast30_coef * avgComments);
    // console.log("F = avgCommentsLast30_w_coef", avgCommentsLast30_w_coef);
    const videosLastMonth_w_coef = ((videosLastMonth_coef * nbVideosLastMonth) > 140) ? 140 : (videosLastMonth_coef * nbVideosLastMonth);
    // console.log("G = videosLastMonth_w_coef", videosLastMonth_w_coef);
    const prediction = avgViews_w_coef + percentageLikes_w_coef + instaEng_w_coef + youtubeSubscriber_w_coef + avgCommentsLast30_w_coef + videosLastMonth_w_coef + intercept_coef
    // console.log("FINAL = prediction", prediction);
    this.setState({
      dataPrediction: {
        inputs: {
          avgViews,
          avgLikes,
          avgDislikes,
          instagramEng,
          youtubePercentageLikes,
          subscriberYT,
          engagementInstagram,
          youtubeEngagement,
          nbVideosLastMonth,
          nbInstaPostLastMonth,
        },
        coef: {
          avgViews_w_coef,
          percentageLikes_w_coef,
          instaEng_w_coef,
          youtubeSubscriber_w_coef,
          avgCommentsLast30_w_coef,
          videosLastMonth_w_coef,
        },
        prediction
      },
      isLoadingChannel: false
    });
  }

  render() {
    const { channelId, isLoadingChannel, handle, dataPrediction, instagramData, youtubeData } = this.state;
    return (
      <div className="youtube_cc_prediction">
        <h3 className="youtube_cc_prediction--title">Co-creation sales prediction</h3>
        <div className="youtube_cc_prediction__form">
          <div className="mdl-textfield mdl-js-textfield">
            <input className="mdl-textfield__input" type="text" id="youtube_channelId" value={channelId || ''} onChange={this.handleChangeInput} />
            <label className="mdl-textfield__label" forhtml="youtube_channelId">Enter Channel Id...</label>
          </div>
          <div className="mdl-textfield mdl-js-textfield">
            <input className="mdl-textfield__input" type="text" id="handle_instagram" value={handle || ''} onChange={this.handleChangeInput} />
            <label className="mdl-textfield__label" forhtml="handle_instagram">Enter Instagram handle...</label>
          </div>
          <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent" disabled={(channelId && handle) ? "" : "disabled"}  onClick={this.handlePredictionCocreation}>
            Get sales prediction
          </button>
        </div>
        <div className="youtube_cc_prediction__data">

            {(youtubeData) ? (
              <div className="prediction__data__youtube">
                <img alt="logo youtube" className="prediction__data__youtube--logo" src={ytLogo} />
                <img className="prediction__data__youtube--picture" src={youtubeData.pictureURL} alt="profile" />
                <div className="prediction__data__youtube__data">
                  <div className="youtube__data--title">{youtubeData.title}</div>
                  <div className="youtube__data--subs">{youtubeData.stats.subscribers.toLocaleString('en-US')} subscribers</div>
                  <div className="youtube__data--views">{youtubeData.stats.views.toLocaleString('en-US')} views</div>
                </div>
              </div>
            ) : (
              null
            )}
            {(instagramData) ? (
              <div className="prediction__data__instagram">
                <img alt="logo instagram" className="prediction__data__instagram--logo" src={instaLogo} />
                <img className="prediction__data__instagram--picture" src={instagramData.profile_pic_url} alt="profile" />
                <div className="prediction__data__instagram__data">
                  <div className="instagram__data--name">{instagramData.full_name}</div>
                  <div className="instagram__data_counts">
                    <div className="instagram__data_counts--posts">
                      <p>{instagramData.edge_owner_to_timeline_media.count.toLocaleString('en-US')}</p>
                      <p>Posts</p>
                    </div>
                    <div className="instagram__data_counts--followers">
                      <p>{instagramData.edge_followed_by.count.toLocaleString('en-US')}</p>
                      <p>Followers</p>
                    </div>
                    <div className="instagram__data_counts--following">
                      <p>{instagramData.edge_follow.count.toLocaleString('en-US')}</p>
                      <p>Following</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              null
            )}
            {(dataPrediction) ? (
              <div className="prediction__data_final">
                <div className="prediction__data_final__youtube">
                  <p className="prediction__data_final__youtube--avgViews">
                    <i className="material-icons">visibility</i>
                    <span>{dataPrediction.inputs.avgViews.toFixed()}</span>
                  </p>
                  <p className="prediction__data_final__youtube--avgLikes">
                    <i className="material-icons">thumb_up</i>
                    <span>{dataPrediction.inputs.avgLikes.toFixed()}</span>
                  </p>
                  <p className="prediction__data_final__youtube--avgDislikes">
                    <i className="material-icons">thumb_down</i>
                    <span>{dataPrediction.inputs.avgDislikes.toFixed()}</span>
                  </p>
                  <p className="prediction__data_final__youtube--videos">
                    <span>Number videos last month:</span>
                    <span>{dataPrediction.inputs.nbVideosLastMonth}</span>
                  </p>
                  <p className="prediction__data_final__youtube--engagement">
                    <i className="material-icons">show_chart</i>
                    <span>{dataPrediction.inputs.youtubeEngagement.toFixed(2)}%</span>
                  </p>
                </div>
                <div className="prediction__data_final__instagram">
                  <p className="prediction__data_final__instagram--posts">
                    <span>Number posts last month:</span>
                    <span>{dataPrediction.inputs.nbInstaPostLastMonth}</span>
                  </p>
                  <p className="prediction__data_final__instagram--engagement">
                    <i className="material-icons">show_chart</i>
                    <span>{dataPrediction.inputs.engagementInstagram}%</span>
                  </p>
                </div>
                <div className="prediction__data_final__value">
                  <p>{dataPrediction.prediction.toFixed()}</p>
                </div>
              </div>
            ) : (
              null
            )}
        </div>
      </div>
    );
  }
}








class ChannelAverageViews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChannel: null,
      dataVideos: [],
      isLoading: false,
      isLoadingChannel: false,
      channelId: "",
      shouldTemporizeButton: false
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleDataVideo = this.handleDataVideo.bind(this);
  }

  componentDidMount() {
    console.log('componentDidMount ChannelAverageViews');

    // this.testPrediction("UCy47Au63gFpnzb_959sCcyg", "robbyepicsauce");
  }



  handleChangeInput(event) {
    if (event.target) {
      const value = event.target.value;
      if (event.target.id === 'youtube_channelId') {
        this.setState({ channelId: value.trim(), errorChannelStr: '' });
      }
    }
  }

  async getVideoDetails(videoId, err=0) {
    if (err < 5) {
      try {
        const rVideo = await axios.get('/api/v1/aws/video/' + videoId);
        console.log(rVideo.data.result.videoId);
        this.setState({ dataVideos: [...this.state.dataVideos, rVideo.data.result] })
      } catch (error) {
        console.log(error);
        err += 1;
        this.getVideoDetails(videoId, err);
      }
    } else {
      this.setState({ dataVideos: [...this.state.dataVideos, {}] });
    }

  }

  handleDataVideo() {
    const { channelId } = this.state;
    console.log("handleDataVideo");
    console.log(channelId);
    if (channelId) {
      this.setState({
        shouldTemporizeButton: true,
        isLoadingChannel: true,
        dataChannel: null,
        dataVideos: []
      });
      this.getDataChannel(channelId);
    }
  }

  async getDataChannel(channelId, force_update=false) {
    console.log("getDataChannel", channelId, "force_update", force_update);
    axios.get(`/api/v1/aws/channel/${channelId}`, {params: {"force_update": force_update}})
      .then(async (r) => {
        console.log("channel:", r);
        this.setState({ dataChannel: r.data.result, isLoadingChannel: false });

        if (r.data.result.latestVideos) {
          const lastUpdate = Date.parse(r.data.result.latestVideos.lastUpdate)
          console.log("r.data.result.latestVideos.lastUpdate", r.data.result.latestVideos.lastUpdate);
          console.log("lastUpdate", lastUpdate);
          console.log("now", Date.now());
          if (!force_update && Date.now() - lastUpdate > 86400000) {
            this.setState({ isLoadingChannel: true });
            return this.getDataChannel(channelId, true);
          }
          const theVideos = r.data.result.latestVideos.list;
          console.log("theVideos", theVideos);
          // Retrieve video detail 5 by 5
          const theVideosChunked = _.chunk(theVideos, 5)
          for (const chunk of theVideosChunked) {
            await Promise.all(chunk.map((video) => {
              console.log(video);
              return this.getVideoDetails(video);
            }));
          }
        }
        setTimeout(() => this.setState({shouldTemporizeButton: false}), 2000);
      });
  }

  renderVideosData() {
    let { dataVideos, dataChannel } = this.state;
    // let dataVideosOrdered = _.orderBy(dataVideos, ['datePublished'], ['asc'])
    dataVideos.sort(function compare(a, b) {
      var dateA = new Date(a.datePublished);
      var dateB = new Date(b.datePublished);
      return dateB - dateA;
    });

    let totalViews = 0;

    let minView = null;
    let minViewDate = null;
    let maxView = null;
    let maxViewDate = null;
    let totalDaysBetweenVideos = 0;

    dataVideos.map((video, i) => {
      if (i > 0) {
        console.log(moment(video.datePublished));
        console.log(moment(dataVideos[i - 1].datePublished));
        console.log("====");
        totalDaysBetweenVideos += moment(dataVideos[i - 1].datePublished).diff(moment(video.datePublished), "days");
      }
      if (!video.stats) return;
      totalViews += video.stats.views;
      if (video.stats.views > maxView || maxView === null) {
        maxView = video.stats.views;
        maxViewDate = video.datePublished;
      }
      if (video.stats.views < minView || minView === null) {
        minView = video.stats.views;
        minViewDate = video.datePublished;
      }
    });
    console.log(totalDaysBetweenVideos);
    console.log(totalDaysBetweenVideos / (dataVideos.length - 1));
    let averageViews = totalViews / dataVideos.length;
    console.log(totalViews);
    // moment().diff(moment(dataVideos.datePublished), 'days') // 1

    // ----- NEW:
    let getTrimmedMean = (data, trimAmount) => {
      let trimCount = Math.floor(trimAmount*data.length);
      let sortedData = data.sort(function(a, b) {
        return b - a;
      });
      let trimData = sortedData.slice(trimCount, data.length-trimCount);
      return trimData.reduce((a, b) => a + b ,0)/trimData.length;
    }
    let arrViews = [];
    let arrLikes = [];
    let arrDislikes = [];
    let arrDuration = [];
    let arrGenre = [];
    dataVideos.forEach(video => {
      arrViews.push(video.stats.views);
      arrLikes.push(video.stats.likes);
      arrDislikes.push(video.stats.dislikes);
      arrDuration.push(video.stats.duration);
      arrGenre.push(video.stats.genre);
    })
    let trimMeanViews = getTrimmedMean(arrViews, 0.1).toFixed(2);
    let trimMeanLikes = getTrimmedMean(arrLikes, 0.1).toFixed(2);
    let trimMeanDislikes = getTrimmedMean(arrDislikes, 0.1).toFixed(2);
    let trimMeanDuration = parseInt(getTrimmedMean(arrDuration, 0.1));


    return (
      <div>
        <div className="youtube_views__videos__breakdown">
          <p className="youtube_views__videos__breakdown--title">Data on the last {dataVideos.length} videos ({dataVideos.length}/{dataChannel.latestVideos.list.length})</p>
          <div className="youtube_views__videos__breakdown__data">
            <p className="youtube_views__videos__breakdown__data__text">{trimMeanViews} avg views</p>
            <p className="youtube_views__videos__breakdown__data__info">Trimmed Mean. Compute the mean of the middle 80% of your data, ignoring the top and bottom 10%</p>
          </div>
          <div className="youtube_views__videos__breakdown__data">
            <p className="youtube_views__videos__breakdown__data__text">{trimMeanLikes} avg likes</p>
            <p className="youtube_views__videos__breakdown__data__info">Trimmed Mean. Compute the mean of the middle 80% of your data, ignoring the top and bottom 10%</p>
          </div>
          <div className="youtube_views__videos__breakdown__data">
            <p className="youtube_views__videos__breakdown__data__text">{trimMeanDislikes} avg dislikes</p>
            <p className="youtube_views__videos__breakdown__data__info">Trimmed Mean. Compute the mean of the middle 80% of your data, ignoring the top and bottom 10%</p>
          </div>
          {/* <p className="youtube_views__videos__breakdown--data">{Number((averageViews).toFixed(1)).toLocaleString()} views/videos</p> */}
          <p className="youtube_views__videos__breakdown--data">{Number((maxView).toFixed(1)).toLocaleString()} Max views ({moment().diff(moment(maxViewDate), 'days')} days ago)</p>
          <p className="youtube_views__videos__breakdown--data">{Number((minView).toFixed(1)).toLocaleString()} Min views ({moment().diff(moment(minViewDate), 'days')} days ago)</p>
          <p className="youtube_views__videos__breakdown--data">1 video published every {(totalDaysBetweenVideos / (dataVideos.length - 1)).toFixed(2)} days</p>
        </div>
        <a className="youtube_views__videos__view_more" target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/channel/${this.state.channelId}/videos`}>VIEW CHANNEL</a>
      </div>
    );
  }

  render() {
    const { isLoading, dataChannel, dataVideos, channelId, isLoadingChannel, shouldTemporizeButton } = this.state;

    return (
      <div className="youtube_views">
        <HeaderComponent title="Data YouTube channel" to="/a/dashboard/youtube" toTitle="YouTube" />
        {(isLoading) ? (
          <div className="youtube_views__content__loading">
            <Loading />
          </div>
        ) : (
          <div className="youtube_views__content">
            <div className="youtube_views__content__search">
              <h3 className="youtube_views__content__search--title">Average views</h3>
              <div className="mdl-textfield mdl-js-textfield">
                <input className="mdl-textfield__input" type="text" id="youtube_channelId" value={channelId || ''} onChange={this.handleChangeInput} />
                <label className="mdl-textfield__label" forhtml="youtube_channelId">Enter Channel Id...</label>
              </div>
              <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent" disabled={(!channelId || shouldTemporizeButton || (channelId && channelId.length !== 24)) ? "disabled" : ""}  onClick={this.handleDataVideo}>
                Get average views
              </button>
            </div>
            <div>
              {(dataChannel) ? (
                <div className="youtube_views__data">
                  <div className="youtube_views__data__user">
                    <img className="youtube_views__data__user--image" alt="user" src={dataChannel.pictureURL} />
                    <div className="youtube_views__data__user_info">
                      <p className="youtube_views__data__user_info--name">{dataChannel.title}</p>
                      <p className="youtube_views__data__user_info--views">{dataChannel.stats && !dataChannel.stats.views ? "-" : Number((dataChannel.stats.views).toFixed(1)).toLocaleString()} views</p>
                      <p className="youtube_views__data__user_info--subscribers">{dataChannel.stats && !dataChannel.stats.subscribers ? "-" : dataChannel.stats.subscribers} subscribers</p>
                    </div>
                    <div className="youtube_views__data__user_info">
                      {(dataChannel.country) ? (
                        <p className="youtube_views__data__user_info--country">
                          <span className="material-icons">outlined_flag</span>
                          {dataChannel.country}
                        </p>
                      ) : (null)}
                      {(dataChannel.instagramHandle) ? (
                      <p className="youtube_views__data__user_info--instagram">
                        <img alt="insta" src={instaLogo} />
                        <a href={`https://instagram.com/${dataChannel.instagramHandle}`} target="_blank" rel="noreferrer noopener">{dataChannel.instagramHandle}</a>
                      </p>
                      ) : (null)}
                      {(dataChannel.email) ? (
                        <p className="youtube_views__data__user_info--email">
                        <span className="material-icons">alternate_email</span>
                          {dataChannel.email}
                        </p>
                      ) : (null)}
                    </div>
                  </div>

                  <div className="youtube_views__videos">
                    {(dataVideos.length >= 5) ? (
                      this.renderVideosData()
                    ) : dataChannel.latestVideos.list.length >= 5 ? (
                      <div className="youtube_views__videos__loading">
                        <Loading />
                      </div>
                    ): "Not enough videos found"}
                  </div>
                </div>
              ) : (
                (isLoadingChannel) ? (<Loading />) : (null)
              )}
            </div>
          </div>
        )}
        {/* <ChannelPredictionCocreation /> */}
      </div>
    );
  }
}

export default ChannelAverageViews;