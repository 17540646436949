import React from "react";
import "./switch.css";

/*
 * Switch
 *
 * Props:
 * options=[{key: "key", name: "name"}]
 * onChange=(key) => {}
 * value:key
 */
class ALSwitch extends React.Component {
  render() {
    const { value, onChange, options } = this.props;
    return (
      <div className="input-switch-container">
        {options.map((option, i) => {
          return (
            <div
              key={i}
              className={`input-switch ${option.key === value ? "input-switch--selected" : ""} ${option._disabled ? "input-switch--disabled" : ""}`}
              onClick={() => !option._disabled && onChange(option.key)}>
              {option.name}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ALSwitch;
