import React from "react";
import moment from 'moment';
import InstagramPost from "./InstagramPost";
import Loading from "../Loading";
import axios from "axios";
import NotificationManager from "../al_components/notification/NotificationManager";
import "./InstagramContainer.css";

import iconVerified from '../../../src/assets/insta_verified.png';



class InstagramContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newProfileId: null,
      data: this.props.data,
      gender: this.props.gender,
      isLoading: true,
    };
  }

  componentDidMount() {
    console.log("InstagramContainer componentDidMount");
    console.log(this.props.data);
    if (typeof(this.props.data) == "string") {
      console.log("STRING!! NEED DATA =>");
      axios.get(`/api/v1/aws/handle/${this.props.data}`)
      .then((res) => {
        console.log(res);
        if (res && res.data.result.name !== "Error") {
          this.setState({
            data: res.data.result,
            isLoading: false
          });
        } else {
          NotificationManager.error('Error', 'Server failed to get Instagram data. ', 4000);
          this.setState({isLoading: false});
        }
      })
      .catch((err) => {
        NotificationManager.error('Error', 'Server failed to get Instagram data. ' + err, 4000);
        return err;
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.data && this.props.data) {
      return this.setState({
        data: this.props.data,
        gender: this.props.gender,
      });
    }
    if (prevProps.data && this.props.data && prevProps.data.profileId != this.props.data.profileId) {
      return this.setState({
        data: this.props.data,
        gender: this.props.gender,
      });
    }
  }

  render() {
    const { data, gender, isLoading } = this.state;

    let totalLikes = 0;
    let totalComments = 0;
    let nbPosts = 0
    let avgLikes = 0;
    let avgComments = 0;
    if (data && typeof data === "object" && data["latestPosts"]) {
      data.latestPosts.map((post, i) => {
        if (typeof(post) != "string") {
          totalLikes += post["stats"]["likes"];
          totalComments += post["stats"]["comments"];
          nbPosts += 1;
        } else {
          return;
        }
      });
      avgLikes = totalLikes / nbPosts;
      avgComments = totalComments / nbPosts;
    }
    console.log("data", data);

    return (
      <div className="q_instagram">
        {(data && typeof data === "object" && !isLoading) ? (
        <>
          <div className="q_instagram__header">
            {/* header informations */}
            <div className="q_instagram__header_meta">
              <a className="q_instagram__header_meta_link" href={`https://instagram.com/${data.profileId}`} target="_blank" rel="noopener noreferrer">
                <img className="q_instagram__header_meta_picture" src={data.pictureURL} />
              </a>
              <div className="q_instagram__header_meta_details">
                <p className="q_instagram__header_meta_details--name">
                  @{data.profileId} {(data.isPrivate) ? (<img alt="private" src={iconVerified} />) : ( (data.isVerified) ? (<img alt="verified" src={iconVerified} />) : (null) )}
                </p>
                <p className="q_instagram__header_meta_details--followers">{new Intl.NumberFormat().format(data?.stats?.followers) || "ø"} followers</p>
                <p className="q_instagram__header_meta_details--following">{new Intl.NumberFormat().format(data?.stats?.following) || "ø"} following</p>
                <p className="q_instagram__header_meta_details--posts">{new Intl.NumberFormat().format(data?.stats?.posts) || "ø"} posts</p>
              </div>
            </div>

            {(data.description) ? (
              <p className="q_instagram__header_description">{data.description}</p>
            ) : (null)}
            {(data.fullName) ? (
              <p className="q_instagram__header_name">{data.fullName}</p>
            ) : (null)}
            {(this.props.emails != []) ? (
              <p className="q_instagram__header_emails">{this.props.emails.map((e, i) => (
                  <span key={`email_${i}`}>{e}</span>
              ))}</p>
            ) : (null)}

            <div>
                {(data.country) ? (
                  <div className="q_instagram__header_general_det">
                    <span className="material-icons">outlined_flag</span>
                    <span className="q_instagram__header_general_det_value">{data.country}</span>
                  </div>
                ) : (null)}
                {(gender && gender.gender) ? (
                  <div className="q_instagram__header_general_det">
                    <span className="material-icons">face</span>
                    <span className="q_instagram__header_general_det_value">{gender.gender}</span>
                  </div>
                ) : (null)}
                {(this.props.keyword) ? (
                  <div className="q_instagram__header_general_det">
                    <span className="material-icons">label</span>
                    <span className="q_instagram__header_general_det_value">{this.props.keyword}</span>
                  </div>
                ) : (null)}
                {(avgLikes) ? (
                  <div className="q_instagram__header_general_det">
                    <span className="material-icons">show_chart</span>
                    <span className="q_instagram__header_general_det_value">{avgLikes.toFixed()} likes/post</span>
                  </div>
                ) : (null)}
                {(avgComments) ? (
                  <div className="q_instagram__header_general_det">
                    <span className="material-icons">show_chart</span>
                    <span className="q_instagram__header_general_det_value">{avgComments.toFixed()} comments/post</span>
                  </div>
                ) : (null)}
            </div>
          </div>
          <div className="q_instagram__posts" onScroll={this.handleScroll}>
            {(data.latestPosts).map((post, i) => {
              if (typeof(post) != "string") {
                return (
                  <InstagramPost post={post} key={`post_${i}`} />
                )
              }
            })}
            <div className="q_instagram__posts__load" onClick={() => { this.props.loadMore() }}>Load more posts</div>
          </div>
        </>
        ) : (
          (isLoading) ? (
            <div>
              <Loading />
            </div>
          ) : (typeof this.props.data === "string" ? <div>{this.props.data}</div> : (
            <div className="q_instagram__update">
              <input type="text" placeholder="Please add user handle / url" onChange={(e) => { this.setState({ newProfileId: e.target.value}); }} />
              <div className="q_instagram__update--button" onClick={() => this.props.updatePlatformId("instagram", this.state.newProfileId)}>Update</div>
            </div>
          ))
        )}
      </div>
    );
  }
}

export default InstagramContainer;
