import React from "react";
import * as Sentry from "@sentry/react";
import { format } from "date-fns";
import _ from "lodash";
import axios from "axios";
import { sleep } from "utils";
import NotificationManager from "../al_components/notification/NotificationManager";
import Loading from "../Loading";
import NoEmailInfluencers from "./NoEmailInfluencers";
import "./OutreachSplit.css";

const NETWORK_CHUNK_SIZE = 10;

function getTrimmedMean(data, trimAmount) {
  let trimCount = Math.floor(trimAmount * data.length);
  let sortedData = data.sort(function (a, b) {
    return b - a;
  });
  let trimData = sortedData.slice(trimCount, data.length - trimCount);
  return trimData.length > 0 ? trimData.reduce((a, b) => a + b, 0) / trimData.length : 0;
}
async function getAverageViews(ytChannelObj) {
  try {
    const listVideosData = ytChannelObj?.latestVideos?.list;
    if (!listVideosData?.length) return "0.00";
    const listVideoObjects = await Promise.all(
      listVideosData.map((videoData) =>
        typeof videoData === "string"
          ? axios
              .get(`/api/v1/youtube/video/${videoData}`, { params: { no_scrap: true } })
              .then(({ data }) => (data.message === "ok" ? data.video : null))
          : videoData
      )
    );
    const listVideosViews = listVideoObjects.reduce(
      (acc, videoObj) => (typeof videoObj?.stats?.views == "number" ? [...acc, videoObj["stats"].views] : acc),
      []
    );
    const trimMeanViews = getTrimmedMean(listVideosViews, 0.1).toFixed(2);
    return trimMeanViews || "0.00";
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    return "0.00";
  }
}

class OutreachSplit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      campaigns: [],

      team_youtube: [],
      team_youtube_instagram: [],
      team_cocreation: [],

      inf_youtube: [], // list of influencers with only YT
      inf_youtube_instagram: [], // list of influencers with YT & Insta
      // inf_instagram: [], // list of influencers with only Insta
      inf_cocreation: [], // list of influencers with YT and/or Insta => with avg views above X OR status cocreation (?)
      inf_no_email: [], // list of influencers without emails

      sumSplitChannels: 0,
      tabSelected: "youtube",

      loadingInfluencers: { total: 0 },
      loadingDownload: { active: false, message: "" },
    };
    this.changeSelectedTab = this.changeSelectedTab.bind(this);
    this.attachCampaignToAM = this.attachCampaignToAM.bind(this);
    this.updateChannelForAM = this.updateChannelForAM.bind(this);
    this.toggleCheckUser = this.toggleCheckUser.bind(this);

    this.validateSplitChannels = this.validateSplitChannels.bind(this);
    this.onClickDownloadOutreach = this.onClickDownloadOutreach.bind(this);
    this.retrieveInfluencersAverageViews = this.retrieveInfluencersAverageViews.bind(this);
  }
  componentDidMount() {
    // Get team members
    axios.get("/api/v1/team/account-manager?with=admin&with=super-user").then((res) => {
      if (res.status == 200) {
        let tmpUsers = res.data.users;

        let team_youtube = [];
        let team_youtube_instagram = [];
        let team_cocreation = [];
        // split them by team!
        tmpUsers.map((user, i) => {
          console.log(user);
          if (user["teams"].length > 0) {
            // add only people with a team assign
            if (user["teams"].indexOf("YouTube") != -1) {
              if (user["teams"].indexOf("Instagram") != -1) {
                team_youtube_instagram.push({ ...user, isSelected: false });
              }
              team_youtube.push({ ...user, isSelected: false });
            } else if (user["teams"].indexOf("Co-creation") != -1) {
              team_cocreation.push({ ...user, isSelected: false });
            }
          }
        });

        this.setState({
          users: tmpUsers,
          team_youtube: team_youtube,
          team_youtube_instagram: team_youtube_instagram,
          team_cocreation: team_cocreation,
        });
      }
    });

    // TODO: Get influencers to outreach
    this.getInfluencersToOutreach().then((influencersToOutreach) => {
      let inf_youtube = [];
      let inf_youtube_instagram = [];
      let inf_cocreation = []; // TODO: define process between instagram and youtube
      let inf_no_email = [];
      influencersToOutreach.forEach((inf, i) => {
        let isYesInsta = typeof inf.instagram.status == "undefined" || inf.instagram.status == "qualification_no" ? false : true;
        let isYesYouTube = typeof inf.youtube.status == "undefined" || inf.youtube.status == "qualification_no" ? false : true;
        if (inf.emails.length > 0) {
          // add to insta: if insta && NOT youtube
          if (isYesInsta && !isYesYouTube) {
            inf_youtube_instagram.push(inf);
          } else {
            // add to youtube if only youtube
            inf_youtube.push(inf);
          }
        } else {
          // no emails
          inf_no_email.push(inf);
        }
      });

      console.log("TOTAL youtube (+insta):", inf_youtube.length);
      console.log("TOTAL insta:", inf_youtube_instagram.length);
      console.log("TOTAL cc:", inf_cocreation.length);
      console.log("TOTAL no email:", inf_no_email.length);

      this.setState({
        inf_youtube: inf_youtube,
        inf_youtube_instagram: inf_youtube_instagram,
        inf_cocreation: inf_cocreation,
        inf_no_email: inf_no_email,
        loadingInfluencers: false,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // calculate sum of channels selected to be outreached
    const { tabSelected, team_youtube, team_youtube_instagram, team_cocreation } = this.state;
    let teamUserSelected = null;
    if (tabSelected == "youtube") {
      teamUserSelected = team_youtube;
    } else if (tabSelected == "youtube_instagram") {
      teamUserSelected = team_youtube_instagram;
    } else if (tabSelected == "co-creation") {
      teamUserSelected = team_cocreation;
    }

    if (prevState[teamUserSelected] !== this.state[teamUserSelected]) {
      // calculate current sum of channels selected
      let sumSplitChannels = _.sumBy(this.state[teamUserSelected], "nbChannels");
      // update this value
      this.setState({ sumSplitChannels: sumSplitChannels || 0 });
    }
  }
  changeSelectedTab(newTab) {
    const { tabSelected, team_youtube, team_youtube_instagram, team_cocreation } = this.state;
    console.log("CHANGE TABS");
    // reset all elements
    let copyTeam = null;
    let copyTeamValue = null;
    if (tabSelected == "youtube") {
      // reset team_youtube
      copyTeam = team_youtube;
      copyTeamValue = "team_youtube";
    } else if (tabSelected == "youtube_instagram") {
      // reset team_youtube_instagram
      copyTeam = team_youtube_instagram;
      copyTeamValue = "team_youtube_instagram";
    } else if (tabSelected == "co-creation") {
      // reset team_cocreation
      copyTeam = team_cocreation;
      copyTeamValue = "team_cocreation";
    }
    copyTeam.map((am, i) => {
      console.log(am, "am");
      if (am.isSelected) {
        delete am.isSelected;
      }
      if (am.campaignId) {
        delete am.campaignId;
      }
      if (am.nbChannels) {
        delete am.nbChannels;
      }
    });
    console.log(copyTeam);
    this.setState({
      sumSplitChannels: 0,
      copyTeamValue: copyTeam,
      tabSelected: newTab,
    });
  }

  async getInfluencersToOutreach() {
    let turningPages = true;
    let page = 1;
    let influencers = [];

    while (turningPages) {
      const res = await axios.get(
        `/api/v1/influencers_full?status="outreach_split"&page=${page}&limit=250&emails.0=${JSON.stringify({
          $exists: true,
        })}&is_json_query=true&with_yt_videos=true`
      );
      const retrievedInfluencers = res.data.result;
      if (retrievedInfluencers.length < 100) {
        turningPages = false;
      }
      influencers = [...influencers, ...retrievedInfluencers];
      this.setState({ loadingInfluencers: { total: influencers.length } });
      page += 1;
    }
    console.log("TOTAL:", influencers.length);
    return influencers;
  }

  attachCampaignToAM(email, campaignId) {
    console.log("attachCampaignToAM");

    const { tabSelected, team_youtube, team_youtube_instagram, team_cocreation } = this.state;
    let teamUserSelected = null;
    if (tabSelected == "youtube") {
      teamUserSelected = team_youtube;
    } else if (tabSelected == "youtube_instagram") {
      teamUserSelected = team_youtube_instagram;
    } else if (tabSelected == "co-creation") {
      teamUserSelected = team_cocreation;
    }

    // add nbChannels into user object
    var newArr = _.map(teamUserSelected, (user, i) => {
      if (user.email == email) {
        user["campaignId"] = campaignId;
        return user;
      } else {
        return user;
      }
    });

    // update user in the right team
    this.setState({ [teamUserSelected]: newArr });
  }
  updateChannelForAM(e, email) {
    console.log("updateChannelForAM");
    var nbChannels = null;
    if (!isNaN(e)) {
      nbChannels = e;
    } else {
      nbChannels = e.target.value;
    }
    // check if its number
    if (!isNaN(nbChannels)) {
      const { tabSelected, team_youtube, team_youtube_instagram, team_cocreation } = this.state;
      let teamUserSelected = null;
      if (tabSelected == "youtube") {
        teamUserSelected = team_youtube;
      } else if (tabSelected == "youtube_instagram") {
        teamUserSelected = team_youtube_instagram;
      } else if (tabSelected == "co-creation") {
        teamUserSelected = team_cocreation;
      }

      // add nbChannels into user object
      var newArr = _.map(teamUserSelected, (user, i) => {
        if (user.email == email) {
          user["nbChannels"] = parseInt(nbChannels);
          return user;
        } else {
          return user;
        }
      });

      // update user in the right team
      this.setState({ [teamUserSelected]: newArr });
    }
  }

  toggleCheckUser(idxUser) {
    console.log("toggleCheckUser");
    console.log(idxUser);
    const { tabSelected, team_youtube, team_youtube_instagram, team_cocreation } = this.state;
    let teamSelected = null;
    let keyName = "";
    if (tabSelected == "youtube") {
      teamSelected = team_youtube;
      keyName = "team_youtube";
    } else if (tabSelected == "youtube_instagram") {
      teamSelected = team_youtube_instagram;
      keyName = "team_youtube_instagram";
    } else if (tabSelected == "co-creation") {
      teamSelected = team_cocreation;
      keyName = "team_cocreation";
    }

    teamSelected[idxUser]["isSelected"] = !teamSelected[idxUser]["isSelected"];
    console.log(teamSelected);

    // TODO => AUTOMATIC SPLIT!!

    this.setState({ keyName: teamSelected });
  }

  validateSplitChannels() {
    console.log("validateSplitChannels");
    const { tabSelected, team_youtube, team_youtube_instagram, team_cocreation, inf_youtube, inf_youtube_instagram, inf_cocreation } = this.state;
    let teamSelected = null;
    let influencerList = null;
    if (tabSelected == "youtube") {
      teamSelected = team_youtube;
      influencerList = inf_youtube;
    } else if (tabSelected == "youtube_instagram") {
      teamSelected = team_youtube_instagram;
      influencerList = inf_youtube_instagram;
    } else if (tabSelected == "co-creation") {
      teamSelected = team_cocreation;
      influencerList = inf_cocreation;
    }

    console.log(teamSelected, "teamSelected");
    teamSelected.map((am, i) => {
      if (am.isSelected) {
        const amEmail = am["email"];
        const amCampaignId = am["campaignId"];
        const nbChannelsForAm = am["nbChannels"];
        console.log(amEmail + " - " + amCampaignId + " - " + nbChannelsForAm);
        let influencersToOutreach = influencerList.splice(0, nbChannelsForAm);
        console.log(influencersToOutreach, "channelsToOutreach");

        console.log(influencerList, "influencerList"); // remaining influencers
        // TODO MAIL SHAKE OPERATION
        // https://api-docs.mailshake.com/#Parameters28 (addAsNewList should be true)
        // +
        // Update Deal info ???
        // +
        // Set state when done influencerList

        // 1) build object addresses =>
        let addresses = [];
        influencersToOutreach.map((inf, j) => {
          if (tabSelected == "youtube") {
            addresses.push({
              // emailAddress: inf["emails"],
              emailAddress: "francoisd@analuisa.com",
              fields: {
                channel: "https://www.youtube.com/channel/" + inf["youtube"]["accounts"][0]["channelId"],
              },
            });
          }
          if (tabSelected == "youtube_instagram") {
            addresses.push({
              // emailAddress: inf["emails"],
              emailAddress: "francoisd@analuisa.com",
              fields: {
                link: "https://instagram.com/" + inf["instagram"]["accounts"][0]["profileId"],
              },
            });
          }
          if (tabSelected == "co-creation") {
            NotificationManager.error("Not handled yet", "Error", 2000);
          }
        });

        console.log(addresses, "addresses => params mail shake");
        console.log(amEmail + " - " + amCampaignId + " - " + nbChannelsForAm);
      }
    });
  }

  updateInfluencerAndCreateDeal(influencer, amEmail) {
    return axios
      .post("/api/v1/influencers/" + influencer["_id"] + "/deals", {
        status: "outreached",
        am: amEmail,
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("Created deal:", res.data.result);
          return axios
            .put("/api/v1/influencers", { status: "qualification_yes" }, { params: { _id: influencer["_id"] } })
            .then((r) => {
              if (r.status == 200) {
                console.log(`Influencer updated from status: ${influencer.status} to: ${r.data.result.status}`);
                return influencer;
              } else {
                console.error("status:", r.status);
                console.error("influencer ID:", influencer["_id"]);
                console.error(r.data);
                NotificationManager.error(
                  "Failed to change influencer status. Influencer ignored and NOT added to file.",
                  "Error on influencer update",
                  4000
                );
                Sentry.captureException(r, (scope) => {
                  scope.setTag("tool", "SplitOutreach");
                  scope.setTag("user action", "Download");
                  scope.setTag("code part", "Update influencer status (after)");
                  scope.setTage("influencer", influencer["_id"]);
                });
                return null;
              }
            })
            .catch((err) => {
              console.error(err);
              console.error("influencer ID:", influencer["_id"]);
              NotificationManager.error(
                "Failed to change influencer status.  Influencer ignored and NOT added to file.",
                "Error on influencer update",
                4000
              );
              Sentry.captureException(err, (scope) => {
                scope.setTag("tool", "SplitOutreach");
                scope.setTag("user action", "Download");
                scope.setTag("code part", "Update influencer status");
                scope.setTage("influencer", influencer["_id"]);
              });
              return null;
            });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        console.error(err?.response?.data || err);
        console.error("influencer ID:", influencer["_id"]);
        NotificationManager.error("Failed to change influencer status. Influencer ignored and NOT added to file.", "Error on deal creation", 4000);
        Sentry.captureException(err, (scope) => {
          scope.setTag("tool", "SplitOutreach");
          scope.setTag("user action", "Download");
          scope.setTag("code part", "Create deal");
          scope.setTage("influencer", influencer["_id"]);
        });
        NotificationManager.error("Couldn't create a new deal", "Error", 4000);
        return null;
      });
  }

  async retrieveInfluencersAverageViews(influencers, progressCallback = async () => {}) {
    const chunkedAMInfluencers = _.chunk(influencers, NETWORK_CHUNK_SIZE);
    for (const [i, chunkInfluencers] of chunkedAMInfluencers.entries()) {
      const chunkYoutubeChannels = await Promise.all(
        chunkInfluencers.map((influencer) => {
          const channel = influencer?.youtube?.accounts?.[0];
          if (typeof channel === "string") {
            return axios.get(`/api/v1/youtube/channel/${channel}`).then(({ data }) => data.channel);
          }
          if (typeof channel === "object") {
            return channel;
          }
        })
      );
      for (const [j, influencer] of chunkInfluencers.entries()) {
        const averageViews = chunkYoutubeChannels[j] ? await getAverageViews(chunkYoutubeChannels[j]) : "0.00";
        influencer.averageViews = averageViews;
        const currentCount = i * NETWORK_CHUNK_SIZE + (j + 1);
        const totalCount = influencers.length;
        await progressCallback(currentCount, totalCount);
      }
    }
    return influencers;
  }

  async onClickDownloadOutreach() {
    // will download CSV following this modele
    // file namne = YYYY-MM-DD__outreach__AM_batchNumber.csv
    // _id, email, instagram (profile url), youtube (channel url), download date
    this.setState({ loadingDownload: { active: true, message: "Starting..." } });

    const { tabSelected, team_youtube, team_youtube_instagram, team_cocreation, inf_youtube, inf_youtube_instagram, inf_cocreation } = this.state;
    let teamSelected = null;
    let influencerList = null;
    let listName = null;
    if (tabSelected == "youtube") {
      teamSelected = team_youtube;
      influencerList = inf_youtube;
      listName = "inf_youtube";
    } else if (tabSelected == "youtube_instagram") {
      teamSelected = team_youtube_instagram;
      influencerList = inf_youtube_instagram;
      listName = "inf_youtube_instagram";
    } else if (tabSelected == "co-creation") {
      teamSelected = team_cocreation;
      influencerList = inf_cocreation;
      listName = "inf_cocreation";
    }

    const {
      data: { value: batchNumber },
    } = await axios.get("/api/v1/redis/batch");
    for (const am of teamSelected.filter((am) => am.isSelected)) {
      this.setState({ loadingDownload: { active: true, message: `Processing ${am["email"]}...` } });
      const channelsToOutreach = influencerList.splice(0, am["nbChannels"]);
      console.log(
        "am",
        am["email"],
        "influencers",
        channelsToOutreach.map((inf) => inf._id)
      );
      let amInfluencersCSV = [];
      try {
        // Create deals 10 by 10 to avoid network overflow
        const chunkedChannels = _.chunk(channelsToOutreach, NETWORK_CHUNK_SIZE);
        for (const [i, chunk] of chunkedChannels.entries()) {
          const updateResult = await Promise.all(chunk.map((influencer) => this.updateInfluencerAndCreateDeal(influencer, am["email"])));
          const to_be_added_to_csv = updateResult.filter((i) => i);
          if (to_be_added_to_csv.length < chunk.length) {
            const influencers_in_error = chunk.filter((i) => !to_be_added_to_csv.find((inf) => inf._id === i._id)).map((i) => i._id);
            console.log(influencers_in_error);
            Sentry.captureMessage("[OutreachSplit] Failed influencers", Sentry.Severity.Error, {
              influencers: influencers_in_error,
            });
          }
          amInfluencersCSV = [...amInfluencersCSV, ...to_be_added_to_csv];
          this.setState({
            loadingDownload: { active: true, message: `Processing ${am["email"]} (${(i + 1) * NETWORK_CHUNK_SIZE}/${channelsToOutreach.length})...` },
          });
        }
        this.setState({
          loadingDownload: {
            active: true,
            message: `Processing ${am["email"]} - Influencers updated and deals created! Starting data retrieval for file export...`,
          },
        });
        await sleep(1000);
        this.setState({
          loadingDownload: {
            active: true,
            message: `Processing ${am["email"]} - Data retrieval for file export...`,
          },
        });
        await this.retrieveInfluencersAverageViews(amInfluencersCSV, async (currentCount, totalCount) =>
          this.setState({
            loadingDownload: {
              active: true,
              message: `Processing ${am["email"]} - Starting data retrieval for file export... Average views computing: ${currentCount}/${totalCount}`,
            },
          })
        );
      } finally {
        let strCsv = "";
        if (tabSelected == "youtube" || tabSelected == "co-creation") {
          // YouTube => channel as key
          strCsv = "channel,email,country,average views\r\n";
        } else if (tabSelected == "youtube_instagram") {
          strCsv = "link,email,country,average views\r\n"; // only insta
          // TODO: UPDATE ROWS FOR INSTAGRAM
        }
        amInfluencersCSV.map((influencer, j) => {
          let urlChannel = "";
          if (influencer.youtube.accounts[0].channelId) {
            urlChannel = "https://www.youtube.com/channel/" + influencer.youtube.accounts[0].channelId;
            let infCountry = "";
            if (influencer.country) {
              infCountry = influencer.country;
            }
            strCsv += urlChannel + "," + influencer.emails[0] + "," + infCountry + "," + influencer.averageViews + "\r\n";
          }
        });
        this.setState({ loadingDownload: { active: true, message: `Starting file download for ${am["email"]}...` } });
        await sleep(1000);
        let filename = format(new Date(), "ddMMyyyy") + "-batch" + batchNumber + "-" + am["firstName"] + ".csv";
        let contentType = "text/csv;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(strCsv))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var a = document.createElement("a");
          a.download = filename;
          a.href = "data:" + contentType + "," + encodeURIComponent(strCsv);
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        console.log(filename, "filename =>");
      }
    }
    const batchIncrRes = await axios.post("/api/v1/redis/batch/incr");
    console.log("POST INCR BATCH", batchIncrRes);
    NotificationManager.success("Influencers updated successfully", "Success!", 2000);
    this.setState({ loadingDownload: { active: false, message: "" }, [listName]: influencerList });
  }

  render() {
    const {
      sumSplitChannels,
      tabSelected,
      team_youtube,
      team_youtube_instagram,
      team_cocreation,
      campaigns,
      inf_youtube,
      inf_youtube_instagram,
      inf_cocreation,
      inf_no_email,
      loadingInfluencers,
      loadingDownload,
    } = this.state;
    let teamSelected = null;
    let listInfSelected = null;
    if (tabSelected == "youtube") {
      teamSelected = team_youtube;
      listInfSelected = inf_youtube;
    } else if (tabSelected == "youtube_instagram") {
      teamSelected = team_youtube_instagram;
      listInfSelected = inf_youtube_instagram;
    } else if (tabSelected == "co-creation") {
      teamSelected = team_cocreation;
      listInfSelected = inf_cocreation;
    }
    const nbAccountManagerSelected = _.sumBy(teamSelected, "isSelected");

    let hasErrorBeforeUpdate = false;
    if (loadingInfluencers) {
      return (
        <div className="qualification__empty_message">
          <Loading />
          <p>Loading influencer...</p>
          <p>{loadingInfluencers.total} retrieved</p>
        </div>
      );
    }
    return (
      <div className="outreach">
        <div className="outreach_platforms">
          <div
            className={`outreach_platforms__platform ${tabSelected == "youtube" ? "outreach_platforms__platform--isSelected" : null}`}
            onClick={() => this.changeSelectedTab("youtube")}
          >
            <p className="outreach_platforms__platform__title">
              YOUTUBE
              <span>({inf_youtube.length})</span>
            </p>
          </div>
          <div
            className={`outreach_platforms__platform ${tabSelected == "youtube_instagram" ? "outreach_platforms__platform--isSelected" : null}`}
            onClick={() => this.changeSelectedTab("youtube_instagram")}
          >
            <p className="outreach_platforms__platform__title">
              INSTAGRAM
              <span>({inf_youtube_instagram.length})</span>
            </p>
          </div>
          <div
            className={`outreach_platforms__platform ${tabSelected == "co-creation" ? "outreach_platforms__platform--isSelected" : null}`}
            onClick={() => this.changeSelectedTab("co-creation")}
          >
            <p className="outreach_platforms__platform__title">
              CO-CREATION
              <span>({inf_cocreation.length})</span>
            </p>
          </div>
        </div>

        <NoEmailInfluencers retrieveInfluencersAverageViews={this.retrieveInfluencersAverageViews} />

        <div className="outreach_container">
          <div className="outreach_container_users">
            <div className="outreach_container_users_header">
              <p className="outreach_container_users_header--title">Account Manager</p>
              {/* <p className="outreach_container_users_header--title">Campaign Selection</p> */}
            </div>
            {teamSelected
              ? teamSelected.map((user, i) => {
                  let userCampaign = [];
                  userCampaign = _.filter(campaigns, function (c) {
                    if (typeof c["sender"] != "undefined") {
                      if (typeof c["sender"]["emailAddress"] != "undefined") {
                        return c["sender"]["emailAddress"] === user.email;
                      }
                    }
                  });
                  userCampaign.unshift({ id: "null", title: "Select a campaign" });
                  if (userCampaign.length > 0) {
                    return (
                      <div className="outreach_container_user" key={`am_${i}`}>
                        <div
                          className="outreach_container_user__check"
                          onClick={() => {
                            this.toggleCheckUser(i);
                          }}
                        >
                          {user.isSelected ? <span className="material-icons">done</span> : ""}
                        </div>
                        <input
                          className="outreach_container_user_number"
                          min="0"
                          type="number"
                          onChange={(e) => {
                            this.updateChannelForAM(e, user.email);
                          }}
                        />
                        <img className="outreach_container_user__picture" src={user.picture} referrerPolicy="no-referrer" />
                        <div className="outreach_container_user__info">
                          <p className="outreach_container_user__info--name">
                            {user.firstName} {user.lastName}
                          </p>
                          <p className="outreach_container_user__info--email">({user.email})</p>
                        </div>
                        {/* <ALDropdown
                          options={userCampaign}
                          useValue={'title'}
                          useKey={'id'}
                          value={'null'}
                          onChange={(key) => { this.attachCampaignToAM(user.email, key)}}
                        /> */}
                      </div>
                    );
                  } else {
                    return <p>nop</p>;
                  }
                })
              : null}
          </div>
        </div>
        <div className="outreach_actions">
          {sumSplitChannels ? (
            <div className="outreach_actions_summary">
              <div className="outreach_actions_summary_container">
                <p className="outreach_actions_summary_container_title">Selected</p>
                <p className="outreach_actions_summary_container_value">{sumSplitChannels}</p>
              </div>
              <div className="outreach_actions_summary_container">
                <p className="outreach_actions_summary_container_title">Remaining</p>
                <p
                  className={`outreach_actions_summary_container_value ${
                    listInfSelected.length - sumSplitChannels < 0 ? "outreach_actions_summary_container_value--error" : ""
                  }`}
                >
                  {listInfSelected.length - sumSplitChannels}
                </p>
              </div>
            </div>
          ) : null}

          {teamSelected ? (
            <div className="outreach_actions_selected">
              {teamSelected.map((user, i) => {
                if (user.isSelected) {
                  return (
                    <div className="outreach_actions_selected_user" key={i}>
                      <div className="outreach_actions_selected_user_info">
                        <p className="outreach_actions_selected_user_info--name">
                          {user.firstName} {user.lastName}
                        </p>
                      </div>
                      {user.nbChannels ? (
                        <p className="outreach_actions_selected_user_info_nbChannel">{user.nbChannels}</p>
                      ) : (
                        <p className="outreach_actions_selected_user_info_nbChannel outreach_actions_selected_user_info_nbChannel--error">
                          <span className="material-icons">priority_high</span>
                        </p>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          ) : null}

          {loadingDownload.active ? (
            <div className="outreach_actions_processing">
              <Loading />
              <p>{loadingDownload.message}</p>
            </div>
          ) : null}

          {sumSplitChannels > 0 && nbAccountManagerSelected > 0 ? (
            // TODO: ADD DOWNLOAD BUTTON
            <>
              <div
                className={`outreach_actions_validate ${hasErrorBeforeUpdate ? "outreach_actions_validate--hasError" : ""}`}
                onClick={() =>
                  !hasErrorBeforeUpdate && !loadingDownload.active
                    ? this.onClickDownloadOutreach()
                    : NotificationManager.error(
                        loadingDownload.active ? "Download already launched, please wait" : "All the field are mandatory",
                        "",
                        3000
                      )
                }
              >
                DOWNLOAD
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default OutreachSplit;
