import React, { useState } from "react";
import axios from "axios";
import _ from "lodash";
import { differenceInDays, format } from "date-fns";
import { DateRange } from "react-date-range";

import { ALButton, ALLoading } from "components/ALComponents";

import HeaderComponent from "../HeaderComponent";
import ReportingTable from "./ReportingTable";
import KeywordGraph from "./KeywordGraph";

import { splitIntervalIntoChunks } from "utils";
import "react-date-range/dist/styles.css";

import "./KeywordReporting.css";

function ALDateRange(props) {
  const { startDate, endDate, onChange } = props;
  const [selectedRange, setSelectedRange] = useState({ startDate, endDate });
  const [isOpen, setIsOpen] = useState(false);

  const setDateRange = (dateRange) => {
    if (dateRange.range1.startDate !== dateRange.range1.endDate) {
      onChange(dateRange.range1);
      setIsOpen(false);
    }
    setSelectedRange(dateRange.range1);
  };

  return (
    <div className="al-date-range">
      <input
        className="al-date-range__input"
        type="button"
        value={
          selectedRange.startDate || selectedRange.endDate
            ? `${(selectedRange.startDate && format(selectedRange.startDate, "MM/dd/yyyy")) || ""} — ${
                (selectedRange.endDate && format(selectedRange.endDate, "MM/dd/yyyy")) || ""
              }`
            : "Select dates"
        }
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen ? (
        <div className="al-date-range__overlay">
          <DateRange
            onChange={setDateRange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            showDateDisplay={false}
            ranges={[{ startDate: selectedRange.startDate, endDate: selectedRange.endDate }]}
          />
          <i className="material-icons al-date-range__overlay__close" onClick={() => console.log("FFF") || setIsOpen(false)}>
            close
          </i>
        </div>
      ) : null}
    </div>
  );
}

class KeywordReporting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: { startDate: new Date(), endDate: new Date() },
      currentDayData: null,
      data: null,
      isLoadingData: false,
      loadingText: "",
    };
    this.setDateRange = this.setDateRange.bind(this);
    this.getTodaysData = this.getTodaysData.bind(this);
  }
  componentDidMount() {
    this.getTodaysData();
  }

  async getTodaysData() {
    let currentDay = format(new Date(), "yyyy-MM-dd");

    this.setState({ isLoadingData: true });
    const resKeyword = await axios.get(`/api/v1/keywords/report?day_from=${currentDay}&day_to=${currentDay}`);
    if (resKeyword.status == 200) {
      this.setState({ currentDayData: resKeyword?.data?.result || [], isLoadingData: false });
    } else {
      this.setState({ currentDayData: [], isLoadingData: false });
    }
  }

  async setDateRange(dateRange) {
    const batchSize = 5; // 5 days batch
    const chunksOfDates = splitIntervalIntoChunks(dateRange.startDate, dateRange.endDate, batchSize);

    this.setState({ isLoadingData: true });
    let allKeywords = [];
    for (const [index, chunk] of chunksOfDates.entries()) {
      const { start, end } = chunk;
      const resKeyword = await axios.get(`/api/v1/keywords/report?day_from=${format(start, "yyyy-MM-dd")}&day_to=${format(end, "yyyy-MM-dd")}`);
      console.log("# kw retrieved:", (resKeyword?.data?.result || []).length);
      if (resKeyword.status === 200) {
        allKeywords = [...allKeywords, ...(resKeyword?.data?.result || [])];
      }
      this.setState({ loadingText: `Days loaded: ${(index + 1) * batchSize}/${differenceInDays(dateRange.endDate, dateRange.startDate)}` });
    }
    this.setState({
      data: allKeywords,
      dateRange: dateRange,
      isLoadingData: false,
      loadingText: "",
    });
  }

  convertDataIntoTabs(data) {
    console.log(data);

    let _summary = data.reduce(
      (red, k) => {
        red["new"] += k["new"];
        red["duplicate"] += k["duplicate"];
        red["total"] += k["duplicate"] + k["new"];
        return red;
      },
      {
        new: 0,
        duplicate: 0,
        total: 0,
      }
    );

    return {
      data: data?.sort((k1, k2) => new Date(k1.day) - new Date(k2.day)),
      total: _summary["total"],
      new: _summary["new"],
      duplicate: _summary["duplicate"],
    };
  }

  downloadCSV() {
    const { currentDayData, data, dateRange } = this.state;
    let objArray = data ?? currentDayData;
    if (objArray.length === 0) {
      // NOTIFY 0 DATA TO EXPORT
    }

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    str += `${Object.keys(array[0]).toString(",")}\r\n`;
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }

    let filename = `keywords_report_${format(dateRange.startDate, "yyyy-MM-dd")}_${format(dateRange.endDate, "yyyy-MM-dd")}.csv`;
    let contentType = "text/csv;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(str))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href = "data:" + contentType + "," + encodeURIComponent(str);
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  render() {
    const { currentDayData, data, dateRange, isLoadingData, loadingText } = this.state;

    let dataFromRange = null;
    if (data) {
      dataFromRange = this.convertDataIntoTabs(data);
    } else if (currentDayData) {
      dataFromRange = this.convertDataIntoTabs(currentDayData);
    }

    return (
      <div className="reporting_qualification">
        <HeaderComponent title="Report Keywords" to="/a/dashboard/" toTitle="Home" />

        <ALDateRange onChange={this.setDateRange} {...dateRange} />

        {isLoadingData && <ALLoading alt text={loadingText} />}

        <div className="report_keyword_download">
          <ALButton onClick={() => this.downloadCSV()} disable={currentDayData || data ? false : true}>
            Download CSV
          </ALButton>
        </div>

        <p className="reporting_qualification_range">
          {data && data != []
            ? `${format(dateRange.startDate, "MMM dd, yyyy")} — ${format(dateRange.endDate, "MMM dd, yyyy")} — ${differenceInDays(
                dateRange.endDate,
                dateRange.startDate
              )} day(s)`
            : currentDayData && currentDayData != []
            ? `Today's Data (${format(new Date(), "MMM dd, yyyy")})`
            : ""}
        </p>

        <div className="reporting_qualification_content">
          {dataFromRange && dataFromRange.data && dataFromRange.data.length > 0 ? (
            <>
              <div className="qualification_report">
                <div className="qualification_report_data">
                  <p className="qualification_report_data--title"># KEYWORD SCRAPPED</p>
                  <p className="qualification_report_data--value">{Number(dataFromRange.data.length).toLocaleString()}</p>
                </div>
                <div className="qualification_report_data">
                  <p className="qualification_report_data--title"># CHANNEL SCRAPPED</p>
                  <p className="qualification_report_data--value">{Number(dataFromRange.total).toLocaleString()}</p>
                </div>
                <div className="qualification_report_data">
                  <p className="qualification_report_data--title"># NEW CHANNEL</p>
                  <p className="qualification_report_data--value">{Number(dataFromRange.new).toLocaleString()}</p>
                </div>
                <div className="qualification_report_data">
                  <p className="qualification_report_data--title">% NEW</p>
                  <p className="qualification_report_data--value">
                    {((dataFromRange.new / (dataFromRange.new + dataFromRange.duplicate)) * 100).toFixed(2)}%
                  </p>
                </div>
              </div>

              <KeywordGraph dataFromRange={dataFromRange} dateRange={dateRange} />

              <ReportingTable data={data ?? currentDayData} />
            </>
          ) : (
            "Please select a date range"
          )}
        </div>
      </div>
    );
  }
}

export default KeywordReporting;
