import React from "react";
import "./table_details.css";

class ALTableDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      openDetails: [],
    };
    this.toggleDetails = this.toggleDetails.bind(this);
    this.exportData = this.exportData.bind(this);
  }

  toggleDetails(key) {
    console.log("toggle Details");
    console.log(key);
    const { openDetails } = this.state;
    if (openDetails.includes(key)) {
      // already in => remove
      const index = openDetails.indexOf(key);
      if (index > -1) {
        let openDetailsTmp = openDetails;
        openDetailsTmp.splice(index, 1);
        this.setState({ openDetails: openDetailsTmp });
      }
    } else {
      // add it
      this.setState({ openDetails: [...openDetails, key] });
    }
  }

  exportData() {
    console.log("Export Data");
    let headerTmp = this.props.header;
    let contentTmp = this.props.content;
    headerTmp.pop(); // remove last element (=> details)
    let str = '';
    str += headerTmp.toString() + "\r\n";

    contentTmp.map(row => {
      const lengthRow = row.length;
      let strRow = "";
      row.map((el, i) => {
        if (i != (lengthRow - 1)) {
          if (i == (lengthRow - 2)) {
            strRow += el.toString().replace("\n", " ");
          } else {
            strRow += el.toString().replace("\n", " ") + ",";
          }
        }
      });
      str += strRow + "\r\n";
    });

    let contentType = "text/csv;charset=utf-8;";
    let filename = 'ALIP_' + new Date().toISOString() + '.csv';
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(str))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(str);
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }


  render() {
    const { content, details, details_header, column_style, header } = this.props;
    const { openDetails } = this.state;

    let indexDepletion = header.findIndex((e) => e == "depletion per day");
    let indexRev = header.findIndex((e) => e == "revenue per day");
    let leadTime = 42; // lead times in days (6 weeks * 7 days)
    // < 7 week = red
    // 7 - 9 = orange
    // > 9 = green
    let idxQTYOrdered = details_header.findIndex((e) => e == "QTY Ordered");
    let idxQTYReceived = details_header.findIndex((e) => e == "QTY Received");
    let idxQTYReceivedSecondShip = details_header.findIndex((e) => e == "QTY Received Second Shipment");
    let idxFactoryXF = header.findIndex((e) => e == "factory conf XF");
    return (
      <div className="al_table_detail__wrapper">
        {this.props.title ? (
          <div className="al_table_header">
            <div className="al_table__title">{this.props.title} {(this.props.content) ? (<span className="al_table__title--products_count">({this.props.content.length} products)</span>) : (null)}</div>
            <div className="al_table__export" onClick={() => this.exportData()}>Export <span className="material-icons">get_app</span></div>
          </div>
        ) : (null)}
        <div className="al_table_detail" id="tab">
          {/* Header */}
          <div className="al_table_detail__header">
            <div className="al_table_detail__header__row">
              {(this.props.header || []).map((elem, i) => {
                return (
                  <div key={i} className="al_table_detail__header__row__cell" style={column_style[i]} onClick={() => this.props.handleSort(elem)}>
                    {elem}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Body */}
          <div className="al_table_detail__body">
            {(content || []).map((row, i) => {
              return (
                <div key={i} className="al_table_detail__body__row">
                  {(row || []).map((elem, j) => {
                    if (elem != "details") {
                      let colorCode = null;;
                      if (j == indexDepletion) {
                        if ((leadTime + 3) < elem) {
                          // still have time to reorder 3 days!!!
                          colorCode = "green";
                        } else if (leadTime < elem) {
                          // need to reorder NOW
                          colorCode = "yellow";
                        } else {
                          // TOO LATE
                          colorCode = "red"
                        }
                      }
                      if (j == idxFactoryXF) {
                        if (elem) {
                          elem = elem.split("\n").map((e, k) => {
                            return (<span key={k}>{e}</span>);
                          });
                        }
                      }
                      return (
                        <div style={column_style[j]} key={j} className={`al_table_detail__body__row__cell ${(colorCode) ? (`al_table_detail__body__row__cell--${colorCode}`) : (null)} ${(j == idxFactoryXF) ? "al_table_detail__body__row__cell--factory" : null}`}>
                          {/* {(elem == 0) ? 0 : ((indexRev == j) ? (`$ ${elem}`) : (elem))} */}
                          {(elem == 0) ? ((j == idxFactoryXF) ? "" : 0) : ((indexRev == j) ? (`$ ${elem}`) : (elem))}
                        </div>
                      );
                    } else {
                      if (details[i].length == 0) {
                        return (
                          <div style={column_style[j]} key={j} className="al_table_detail__body__row__cell al_table_detail__body__row__cell--click">
                            &nbsp;
                          </div>
                        );
                      } else {
                        return (
                          <div style={column_style[j]} key={j} className="al_table_detail__body__row__cell al_table_detail__body__row__cell--click" onClick={() => this.toggleDetails(i)}>
                            {(openDetails.includes(i)) ? (
                              <span className="material-icons">expand_less</span>
                            ) : (
                              <span className="material-icons">expand_more</span>
                            )}
                          </div>
                        )
                      }
                    }
                  })}
                  {(openDetails.includes(i)) ? (
                    <div className="al_table_detail__details_container">
                      <div className="al_table_detail__details_container_header">
                        {details_header.map((name, i) => {
                          return (
                            <p key={i}>{name}</p>
                          )
                        })}
                      </div>
                      {details[i].map((detail, j) => {
                        return (
                          <div key={j} className="al_table_detail__details_container_content">
                            {detail.map((line, k) => {
                              let qtyReceivedOne = parseInt(line[idxQTYReceived].replace(",", ""));
                              let qtyReceivedTwo = parseInt(line[idxQTYReceivedSecondShip].replace(",", ""));
                              qtyReceivedOne = isNaN(qtyReceivedOne) ? 0 : qtyReceivedOne;
                              qtyReceivedTwo = isNaN(qtyReceivedTwo) ? 0 : qtyReceivedTwo;
                              let deliveryDone = qtyReceivedOne + qtyReceivedTwo;
                              let isDeliveryDone = deliveryDone == parseInt(line[idxQTYOrdered].replace(",", ""));
                              return (
                                <div key={k} className={`al_table_detail__details_container_content_element ${(isDeliveryDone) ? ('al_table_detail__details_container_content_element--disable') : (null)}`}>
                                  {line.map((el, l) => {
                                    return (
                                      <p key={l}>{el}</p>
                                    )
                                  })}
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  ) : (null)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ALTableDetails;
