import axios from "axios";
import { storageAvailable } from "utils";
// Import common dictionnary used by the server
// Reasons of current design:
// - dictionaries being in one place: no duplication between the front and back end
// - create-react-app does not allow getting file outside src/ consequently the
// server dictionaries had to be located in the client folder
// - server is using CommonJS import system, the front is using ESM
// - for cache purpose the front rely on logic implying local storage which the
// server does not have
import { INFLUENCER_COUNTRIES, COUNTRIES_CODES, COUNTRY_TO_LANGUAGE } from "./serverDictionaries";

const DEAL_STATUSES = [
  "negociation",
  "hold",
  "outreached",
  "details",
  "done",
  "published",
  "adam",
  "adam_yes",
  "adam_no",
  "declined_outreach",
  "created",
  "call",
  "lead",
  "to_ship",
  "shipped",
  "in_transit",
  "shipment_received",
];

const SIGNED_DEAL_STATUSES = ["to_ship", "shipped", "in_transit", "shipment_received", "published"];

const TYPEFORM_ID_BY_NAME = {
  "instagram pr pack": "eVQYn81L",
  "youtube v2": "uh35s5Dm",
  "instagram v2": "VUjlBbGD",
  "tiktok v2": "Aoyf4TQg",
};

const US_STATES_ISO = [
  "AA", // Armed Forces America
  "AE", // Armed Forces
  "AL",
  "AK",
  "AP", // Armed Forces Pacific
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const CA_STATES_ISO = ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"];

const AU_STATES_ISO = ["NSW", "QLD", "SA", "TAS", "VIC", "WA", "ACT", "NT"];

const NEW_TO_OLD_SKUS = {
  "180515": "B-0011",
  "B-191012": "B-0047",
  "E-191101": "E-0061",
  "E-191282": "E-0099",
  "E-191188": "E-0054",
  "E-190914": "E-0104",
  "E-191127": "E-0159",
  "E-190915-WH": "E-0175",
  "E-190915-NV": "E-0176",
  "E-191004": "E-0178",
  "E-191181": "E-0181",
  "E-191002": "E-0188",
  "E-191209": "E-0199",
  "E-191136-fwp": "E-0201",
  "E-191193": "E-0206",
  "E-191139": "E-0207",
  "C-191104": "N-0024",
  "C-191320-Rgd": "N-0031",
  "C-191320-Gd": "N-0032",
  "180501": "N-0049",
  "C-191105": "N-0061",
  "C-190913": "N-0068",
  "C-191225": "N-0074",
  "C-190805": "N-0079",
  "C-190804": "N-0080",
  "C-191219-Gd": "N-0089",
  "C-191194": "N-0104",
  "C-191141": "N-0106",
  "C-191213": "N-0110",
  "C-191211": "N-0112",
  "C-191198": "N-0114",
  "R-191208": "R-0007",
  "R-191207": "R-0028",
  "R-181013": "R-0029",
  "C-191199-Gd": "N-0083",
  "C-191320-Sl": "N-0030",
  "E-191336": "E-0063",
  "E-180509-Gd": "E-0154",
  "CS-191328": "C-191328",
  "R-191337": "R-0037",
  "E-191322": "E-0162",
  "E-191172": "E-0202",
  "E-190910-NV": "E-0174",
  "E-190910-WH": "E-0173",
  "E-190910-LB": "E-0172",
  "C-191009": "N-0073",
  "C-191192": "N-0107",
  "C-190807": "N-0019",
  "C-191011": "N-0081",
  "E-191014": "E-0184",
  "CS-191133": "N-0070",
  "CS-191007": "N-0064",
  "E-191015": "E-0185",
  "E-191137": "E-0203",
  "C-191179-Wh": "N-0113",
  "B-191013-MED": "B-0049-L",
  "B-191013-SML": "B-0048",
  "E-191008": "E-0179",
  "C-191190": "N-0111",
  // "EB-003": "",
  "EB-004": "E-0156",
  "C-191174": "E-0161",
  "C-191195-JAN": "N-0092",
  "C-191195-FEB": "N-0093",
  "C-191195-MAR": "N-0094",
  "C-191195-APR": "N-0095",
  "C-191195-MAY": "N-0096",
  "C-191195-JUN": "N-0097",
  "C-191195-JUL": "N-0098",
  "C-191195-AUG": "N-0099",
  "C-191195-SEP": "N-0100",
  "C-191195-OCT": "N-0101",
  "C-191195-NOV": "N-0102",
  "C-191195-DEC": "N-0103",
  "C-191179": "N-0084",
  "E-191102": "E-0137",
  "E-191016": "E-0073",
  "C-191106": "N-0054",
  "E-191107": "E-0177",
  "E-191001": "E-0187",
  // "E- 191103": "",
  "E-191010": "E-0180",
  "E-191135": "E-0151",
  "CS-190810": "N-0012",
  "B-190802-BW": "B-0046",
  "C-190809": "N-0018",
  "CS-190806": "N-0078",
  // "B-191314": "",
  "EB-001": "E-0156",
  "EB-002": "E-0156",
  // "180301": "E-0116", // duplicate with E-0117
  "180301": "E-0117",
  "180505": "N-0047",
  "190701": "E-0145",
  "C-190713": "N-0013",
  "C-180516": "N-0013",
  "C-190714": "N-0013",
  "C-190716": "N-0035",
  "E-190906": "E-0171",
  "B-190911": "ANK-0004",
  "B-190912": "ANK-0004",
  // "E-180301": "",
  "C-190715": "N-0022",
  "B-191180": "B-0050",
  "E-191206": "E-0196",
  "E-191207": "E-0197",
  "E-191205": "E-0195",
  "E-191208": "E-0198",
  "E-191210": "E-0200",
  "180509-BU": "E-0154",
  "E-180507-BU": "E-0149",
  "E-180507-GLD": "E-0148",
  "B-190209": "B-0033",
  // "B-191344": "",
  "E-191201": "E-0204",
  "E-191204": "E-0205",
};

const CAMPAIGNS = [
  { id: "bfcm24", name: "Black Friday & Cyber Monday 2024" },
  { id: "holy24", name: "Holiday 2024" },
  { id: "eoy24", name: "End of Year 2024" },
  { id: "ltk-anniversary", name: "LTK Anniversary" },
  { id: "ltk-bfcm", name: "LTK Black Friday & Cyber Monday" },
  { id: "ltk-holiday", name: "LTK Holiday" },
  { id: "ltk", name: "LTK" },
  { id: "vd23", name: "Valentine's Day 2023" },
  { id: "prpack", name: "PR Pack" },
  { id: "igga22-fr", name: "IG Giveaway FR 2022" },
  { id: "igga22-de", name: "IG Giveaway DE 2022" },
  { id: "glowdark22", name: "Glow 2022" },
  { id: "march22", name: "March Madness 2022" },
  { id: "md22-us", name: "US Mother's Day 2022" },
  { id: "md22-uk", name: "UK Mother's Day 2022" },
  { id: "md22-fr", name: "FR Mother's Day 2022" },
  { id: "spring22", name: "Spring Sale 2022" },
  { id: "vd22", name: "Valentine's Day 2022" },
  { id: "ch21", name: "Christmas 2021" },
  { id: "bf21", name: "Black Friday 2021" },
  { id: "sum21", name: "Summer sales 2021" },
  { id: "md21", name: "Mother's day 2021" },
  { id: "nov23", name: "November Sale 2023" },
  { id: "q423", name: "Q4 Sales 2023" },
  { id: "vd24", name: "Valentine's Day 2024" },
  { id: "md24", name: "Mother's Day 2024" },
];

const BRIEF_TYPES_BY_CAMPAIGN = {
  "vd23": "vd23",
  "prpack": "prpack",
  "igga22-fr": "igga22-fr",
  "igga22-de": "igga22-de",
  "glowdark22": "glowdark22",
  "march22": "march22",
  "md22-us": "md22-us",
  "md22-uk": "md22-uk",
  "md22-fr": "md22-fr",
  "spring22": "spring22",
  "vd22": "vd22",
  "ch21": "ch21",
  "bf21": "bf21",
  "sum21": "summer21",
  "NONE": "classic",
  "nov23": "nov23",
  "q423": "q423",
  "ltk": "ltk",
  "vd24": "vd24",
  "md24": "md24",
  "bfcm24": "bfcm24",
  "holy24": "holy24",
  "eoy24": "eoy24",
  "ltk-anniversary": "ltk-anniversary",
  "ltk-bfcm": "ltk-bfcm",
  "ltk-holiday": "ltk-holiday"
};

// Retrieve and cache tags for 1h
const GET_TAGS = async (forceRefresh = false) => {
  let result = null;
  const isStorageAvailable = storageAvailable("localStorage");
  const timestampNow = new Date().getTime();
  const millisecondsInAnHour = 3_600_000;
  if (isStorageAvailable) {
    const tagsStored = window.localStorage.getItem("influencer_tags");
    if (tagsStored) {
      const storageObject = JSON.parse(tagsStored);
      if (timestampNow - storageObject["last_get_timestamp"] < millisecondsInAnHour) {
        result = storageObject["data"];
      }
    }
  }
  if (!result || forceRefresh) {
    const { data } = await axios.get("/api/v1/tags");
    result = data.tags;
    if (isStorageAvailable) {
      window.localStorage.setItem(
        "influencer_tags",
        JSON.stringify({
          last_get_timestamp: timestampNow,
          data: result,
        })
      );
    }
  }
  return result;
};

const GET_CAMPAIGN_TAGS = async (forceRefresh = false) => {
  let result = null;
  const isStorageAvailable = storageAvailable("localStorage");
  const timestampNow = new Date().getTime();
  const millisecondsInAnHour = 3_600_000;
  if (isStorageAvailable) {
    const tagsStored = window.localStorage.getItem("influencer_campaign_tags");
    if (tagsStored) {
      const storageObject = JSON.parse(tagsStored);
      if (timestampNow - storageObject["last_get_timestamp"] < millisecondsInAnHour) {
        result = storageObject["data"];
      }
    }
  }
  if (!result || forceRefresh) {
    const { data } = await axios.get("/api/v1/campain_tags");
    result = data.tags;
    if (isStorageAvailable) {
      window.localStorage.setItem(
        "influencer_campaign_tags",
        JSON.stringify({
          last_get_timestamp: timestampNow,
          data: result,
        })
      );
    }
  }
  return result;
};

const USER_ROLES = ["super-user", "admin", "billing-user", "account-manager", "marketing", "disabled", ""];

const USER_TEAMS = ["Co-creation", "VIP", "YouTube", "Instagram", "Podcast", "TikTok", "marketing", "operation"];

const IM_GROUP = ["super-user", "admin", "billing-user", "account-manager", "marketing"];

const SHIPPING_COUNTRY_BY_STORE = {
  EU: [
    "AT", // Austria
    "BE", // Belgium
    "EE", // Estonia
    "FI", // Finland
    "FR", // France
    "DE", // Germany
    "GR", // Greece
    "IT", // Italy
    "LV", // Latvia
    "LT", // Lithuania
    "LU", // Luxembourg
    "MT", // Malta
    "NL", // Netherland
    "PT", // Portugal
    "SK", // Slovakia
    "SI", // Slovenia
    "ES", // Spain
  ],
};

export {
  DEAL_STATUSES,
  SIGNED_DEAL_STATUSES,
  INFLUENCER_COUNTRIES,
  COUNTRIES_CODES,
  COUNTRY_TO_LANGUAGE,
  NEW_TO_OLD_SKUS,
  US_STATES_ISO,
  CA_STATES_ISO,
  AU_STATES_ISO,
  CAMPAIGNS,
  BRIEF_TYPES_BY_CAMPAIGN,
  GET_TAGS,
  GET_CAMPAIGN_TAGS,
  USER_ROLES,
  USER_TEAMS,
  IM_GROUP,
  TYPEFORM_ID_BY_NAME,
  SHIPPING_COUNTRY_BY_STORE,
};
