import React from "react";
import axios from 'axios';
import Loading from "./Loading";
import moment from 'moment';
import "./ChannelData.css";

class ChannelData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
      startDate: '',
      endDate: '',
      isLoading: false,
      keywordChannel: ''
    };
    this.handleChange = this.handleChange.bind(this);
    // this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleClickDataDate = this.handleClickDataDate.bind(this);
    this.handleClickDataKeyword = this.handleClickDataKeyword.bind(this);
  }



  handleClickDataDate() {
    const { startDate, endDate } = this.state;
    if (startDate && endDate) {
      console.log('startDate AND endDate');
      console.log(startDate);
      console.log(endDate);
      this.setState({ isLoading: true });
      axios({
        method: 'GET',
        url: `/api/v1/data_date/${startDate}/${endDate}`
      }).then((r) => {
        console.log(r);
        this.setState({ data: r.data.data, isLoading: false });
      });
    }
  }
  handleClickDataKeyword() {
    const { keywordChannel } = this.state;
    if (keywordChannel) {
      axios({
        method: 'GET',
        url: `/api/v1/data_keyword/${keywordChannel}`
      }).then((r) => {
        console.log(r);
        this.setState({ data: r.data.data, isLoading: false });
      });
    }
  }

  handleChange(event) {
    event.preventDefault();
    if (event) {
      if (event.target.name === 'startDate') {
        var endDateValue = moment(event.target.value).add(1, 'days').format('YYYY-MM-DD');
        this.setState({ [event.target.name]: event.target.value, endDate: endDateValue });
      }
      if (event.target.name === 'keywordData') {
        this.setState({ keywordChannel: event.target.value });
      }
    }
  }


  render() {
    const { startDate, data, isLoading, keywordChannel } = this.state;
    return (
      <div className="channels-data">
        <h2>Report Channels</h2>
        <div className="channels-data__inputs">
          <div className="channels-data__inputs__name">
            <p>Start Date (ex: 12/11/2019):</p>
            <input type="date" name="startDate" value={startDate} min="2019-08-30" max={new Date()} onChange={this.handleChange} />
          </div>
          <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent" onClick={this.handleClickDataDate}>
            GET DATA
          </button>

          <span className="channels-data__inputs__separator">&nbsp;</span>

          <div className="channels-data__inputs__name">
            <p>Keyword:</p>
            <input name="keywordData" type="text" id="filter_value" value={keywordChannel} onChange={this.handleChange} />
          </div>
          <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent" onClick={this.handleClickDataKeyword}>
            GET DATA
          </button>
        </div>
        {(data || isLoading) ? (
          <div className="channels-upload__result">
            <h3>Data:</h3>
            {(isLoading) ? <Loading /> : null}

            {(!isLoading && data) ? (
              <div className="channels-upload__result__container">
                <div className="channels-upload__result__container__dataxml">
                  <p>declined, declined_ro, declined_average, toOutreach, hold, neg, done, inv_tmp, scrapped</p>
                  <p>
                    {(this.state.data.declined) ? this.state.data.declined : 0},
                    {(this.state.data.declined_ro) ? this.state.data.declined_ro : 0},
                    {(this.state.data.declined_average) ? this.state.data.declined_average : 0},
                    {(this.state.data.toOutreach) ? this.state.data.toOutreach : 0},
                    {(this.state.data.hold) ? this.state.data.hold : 0},
                    {(this.state.data.neg) ? this.state.data.neg : 0},
                    {(this.state.data.done) ? this.state.data.done : 0},
                    {(this.state.data.inv_tmp) ? this.state.data.inv_tmp : 0},
                    {(this.state.data.scrapped) ? this.state.data.scrapped : 0}
                  </p>
                </div>

                {Object.entries(data).map((el, i) => {
                  console.log(el);
                  return (
                    <div className="channels-upload__result__container__data" key={`result_${i}`}>
                      <p className="channels-upload__result__container__data__status">{el[0]}</p>
                      <p className="channels-upload__result__container__data__value">{el[1]}</p>
                    </div>
                  )
                })}
              </div>
            ) : null}
            <p>Add data into: https://docs.google.com/spreadsheets/d/1zIMGeiQDcJDprSfBYQkkO2BGk8Wrj5j6J4ep3ueeoeU/edit?usp=sharing</p>
          </div>
        ) : (null)}

      </div>
    );
  }
}

export default ChannelData;
