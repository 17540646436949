import React, { useState, useEffect } from "react";
import _ from "lodash";
import 'chart.js/auto';
import {Line} from 'react-chartjs-2';
import { eachDayOfInterval, format } from "date-fns";


function KeywordGraph({ ...props }) {
  const [graphOptions, setGraphOptions] = useState({
    title: {
      display: true,
      text:'Keywords scrapped per day',
      fontSize: 20
    },
    legend: {
      display:true,
      position:'top'
    },
    scales: {
      yAxes: [{
        id: 'customLeft',
        position: 'left',
      }]
    }
  });

  const [_data, updateData] = useState(null);

  useEffect(() => {
    const { dataFromRange, dateRange, data } = props;
    if (_data !== dataFromRange) {

      // group data by day
      let dataGroupedByDate = _.groupBy(dataFromRange?.data, v => format(new Date(v.day), "yyyy-MM-dd"));
      // get all dates in-between a range
      let emptyDates = eachDayOfInterval({ start: dateRange.startDate, end: dateRange.endDate }).map((d) => format(d, "yyyy-MM-dd"));

      // build object for graph (per day)
      // TODO
      // [] - build view day / month / week capabilities
      let dataGraph = null;
      if (Object.keys(dataGroupedByDate).length <= emptyDates.length) {
        // fill /w empty data
        dataGraph = emptyDates.reduce((red, d) => {
          if (dataGroupedByDate[d]) {
            red[d] = dataGroupedByDate[d];
          } else {
            red[d] = [];
          }
          return red;
        }, {});
      }

      let _dataGraph = null;
      if (dataGraph) {
        let _yesData = Object.keys(dataGraph).map((key) => {
          return dataGraph[key].reduce((red, kw) => {
            red += kw['new'];
            return red;
          }, 0);
        });
        let _kwData = Object.keys(dataGraph).map((key) => {
          return dataGraph[key].reduce((red, kw) => {
            red += 1;
            return red;
          }, 0);
        });
        let _percantageYesData = Object.keys(dataGraph).map((key) => {
          let d = dataGraph[key].reduce((red, kw) => {
            red['new'] += kw['new'];
            red['duplicate'] += kw['duplicate'];
            return red;
          }, {
            'new': 0,
            'duplicate': 0,
          });
          let percentageYes = ((d["new"] / (d["new"] + d["duplicate"])) * 100)

          return isNaN(percentageYes) ? 0 : percentageYes;
        });

        let hueYes = (50 + (Math.floor(Math.random() * (300 - 100 + 1) + 100))) % 360;
        let hueKw = (50 + (Math.floor(Math.random() * (300 - 100 + 1) + 100))) % 360;
        let huePercentageYes = (50 + (Math.floor(Math.random() * (300 - 100 + 1) + 100))) % 360;

        _dataGraph = {
          labels: Object.keys(dataGraph),
          datasets: [
            {
              label: 'New channels',
              data: _yesData,
              borderColor: `hsl(${hueYes}, 90%, 45%)`,
              backgroundColor: `hsla(${hueYes}, 85%, 40%, 0.1)`,
            },
            {
              label: '# keywords',
              data: _kwData,
              borderColor: `hsl(${hueKw}, 90%, 45%)`,
              backgroundColor: `hsla(${hueKw}, 85%, 40%, 0.1)`,
            },
            {
              label: '% New channels',
              data: _percantageYesData,
              borderColor: `hsl(${huePercentageYes}, 90%, 45%)`,
              backgroundColor: `hsla(${huePercentageYes}, 85%, 40%, 0.1)`,
            },
          ],
        };
      }
      updateData(_dataGraph);
    }
  }, [props.dataFromRange]);

  return (
    <div>
      {(_data) && (
        <Line
          data={_data}
          options={graphOptions}
        />
      )}
    </div>
  )
}
export default KeywordGraph;
