import React from "react";
import "./search.css";

function ALSearch({ searchInput, placeholder }) {
  return (
    <form className="al_search" onSubmit={e => e.preventDefault()}>
      <input
        id="search"
        name="search"
        placeholder={placeholder ? placeholder : null}
        className="al_search--input"
        type="text"
        onChange={(e) => {
          searchInput && searchInput(e.target.value);
        }}
      />
      <label className="al_search--icon material-icons" htmlFor="search">
        search
      </label>
    </form>
  );
}

export default ALSearch;
