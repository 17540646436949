import parseMessage from "gmail-api-parse-message";

export function parseMail(email) {

  // Decode base64url encoding which replaces "+" with "-" and "/" with "_"
  const decodeBase64 = (str) => {
    try {
      const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
      return decodeURIComponent(
        atob(base64)
          .split('')
          .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
    } catch (error) {
      console.error("Error decoding base64", error);
      return str;
    }
  };


  function extractEmailText(htmlString) {
    // Create a DOMParser to parse the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Function to clean text
    const cleanText = (text) => {
      return text
        .replace(/\s+/g, ' ')  // Replace multiple spaces with single space
        .trim();
    };

    // Function to check if node should be skipped
    const shouldSkipNode = (node) => {
      // Skip signature blocks and other non-essential elements
      const skipClasses = ['gmail_signature', 'gmail_quote'];
      const skipIds = ['lineBreakAtBeginningOfSignature'];
      
      if (!node || !node.classList) return false;
      
      // Skip blockquotes with type="cite"
      if (node.tagName === 'BLOCKQUOTE' && node.getAttribute('type') === 'cite') {
        return true;
      }
      
      return skipClasses.some(cls => node.classList.contains(cls)) || skipIds.some(id => node.id === id);
    };

    // Function to extract text from node
    const extractFromNode = (node) => {
      if (shouldSkipNode(node)) return '';

      // If it's a text node, return its content
      if (node.nodeType === Node.TEXT_NODE) {
        return cleanText(node.textContent);
      }

      // Skip certain elements completely
      if (node.tagName === 'STYLE' || node.tagName === 'SCRIPT' || node.tagName === 'NOSCRIPT' || node.tagName === 'IMG') {
        return '';
      }

      // Recursively process child nodes
      let text = '';
      node.childNodes.forEach(child => {
        const childText = extractFromNode(child);
        if (childText) {
          text += childText + ' ';
        }
      });

      // Preserve line breaks for block elements
      if (node.tagName === 'P' || node.tagName === 'DIV') {
        text += '\n';
      } else if (node.tagName === 'BR') {
        text += '\n';
      }

      return text;
    };

    // Extract text from the body
    const text = extractFromNode(doc.body);

    // Clean up the final result while preserving intentional line breaks
    return text
      .replace(/\s+\n/g, '\n')     // Remove spaces before newlines
      .replace(/\n\s+/g, '\n')     // Remove spaces after newlines
      .replace(/\n{3,}/g, '\n\n')  // Replace multiple newlines with double newlines
      .replace(/\.{3,}/g, '...')   // Replace multiple periods with ellipsis
      .trim();
  }

  // Find the HTML part of the email body
  const findHtmlPart = (parts) => {
    for (const part of parts) {
      if (part.mimeType === 'text/html') {
        return part.body.data;
      }
      if (part.parts) {
        const result = findHtmlPart(part.parts);
        if (result) return result;
      }
    }
    return null;
  };
  const htmlContent = findHtmlPart(email.payload.parts);



  function countArrows(str) {
    let count = 0;
    
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '>') {
        count++;
      } else if (str[i] === ' ') {
        break; // Stop the count as soon as we see a space
      }
    }
    
    return count;
  }

  let emailText = {};
  const finalMessageStr = parseMessage(email);

  if (typeof(finalMessageStr.textPlain) === "undefined") {
    const htmlEmail = decodeBase64(htmlContent);
    const fixedText = extractEmailText(htmlEmail);
    finalMessageStr.textPlain = fixedText;
  }

  finalMessageStr.textPlain.split("\n").forEach((line) => {
    let count = countArrows(line);
    if (!emailText[count]) {
      emailText[count] = [];
    }
    emailText[count].push(line);
  });

  const filteredTextContent = emailText[0].map((line, index) => {
    let strLine = "";
    const daysSent = ["On Mon", "On Tue", "On Wed", "On Thu", "On Fri", "On Sat", "On Sun"];
    if (daysSent.includes(line.slice(0, 6))) {
      strLine = "";
    } else {
      if (line.trim() === "wrote:") {
        strLine = "";
      } else {
        strLine = line.trim() === "" ? "" : line;
      }
    }
    return strLine;
  });

  return {
    email,
    htmlContent: htmlContent ? decodeBase64(htmlContent) : null,
    textContent: emailText[0],
    filteredTextContent,
  };
}

export function formatEmailText(array) {
  // Remove empty lines and \r characters
  const cleanedArray = array
    .filter(line => line !== '')
    .map(line => line.replace(/\r/g, '').trim());

  // Find the indices of structural elements
  const greetingIndex = 0;
  const messageIndex = 1;
  const signatureStartIndex = cleanedArray.findIndex(line => line === 'Best,');
  const nameIndex = signatureStartIndex + 1;
  const titleIndex = nameIndex + 1;

  return cleanedArray.reduce((result, line, index) => {
    result += line;

    // Add appropriate spacing based on position in email
    if (index === greetingIndex || 
        index === messageIndex || 
        index === signatureStartIndex || 
        index === nameIndex || 
        index === titleIndex) {
      result += '\n';
      // Add extra line break after "Best,"
      if (index === signatureStartIndex) {
        result += '\n';
      }
    } else if (index < cleanedArray.length - 1) {
      result += ' ';
    }

    return result;
  }, '').trim();
};