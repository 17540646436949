import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Notification extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    title: PropTypes.node,
    message: PropTypes.node,
    timeOut: PropTypes.number,
    onClick: PropTypes.func,
    onRequestHide: PropTypes.func
  };

  static defaultProps = {
    type: 'info',
    title: null,
    message: null,
    timeOut: 5000,
    onClick: () => {
    },
    onRequestHide: () => {
    }
  };

  componentDidMount = () => {
    const { timeOut } = this.props;
    if (timeOut !== 0) {
      this.timer = setTimeout(this.requestHide, timeOut);
    }
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
    this.requestHide();
  };

  requestHide = () => {
    const { onRequestHide } = this.props;
    if (onRequestHide) {
      onRequestHide();
    }
  };

  render() {
    const { type, message, timeOut } = this.props;
    let { title } = this.props;
    const className = classnames(['al_notification', `al_notification_${type}`]);
    let icon = null;
    if (type == "info") {
      icon = "info";
    } else if (type == "error") {
      icon = "error";
    } else if (type == "warning") {
      icon = "warning";
    } else if (type == "success") {
      icon = "done";
    } else {
      icon = "info";
    }
    
    title = title ? (
      <h4 className="al_notification_message--title">
        <span className="al_notification_message--icon material-icons">{icon}</span>
        {title}
      </h4>
    ) : null;
    let timerSeconds = (timeOut / 1000) + "s";
    return (
      <div className={className} onClick={this.handleClick}>
        <div className="al_notification_message" role="alert">
          {title}
          <div className="al_notification_message--description">
            {message}
            {/* on open popup set .progress to with = 100% */}
            <div className="al_progressbar_container">
              <div className="al_progressbar_container_bar" style={{ animationDuration: timerSeconds, 'WebkitAnimationDuration': timerSeconds }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Notification;