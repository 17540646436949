import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import NotificationManager from "../al_components/notification/NotificationManager";
import { ALLoading } from "../ALComponents";

import "./NoEmailInfluencers.css";

function NoEmailInfluencers({ retrieveInfluencersAverageViews }) {
  const [data, setDataExport] = useState(null);
  const [isDetailsOpen, showDetails] = useState(false);
  const [countLoaded, setCountLoaded] = useState(0);

  function parseDataNoEmail(data) {
    let str = "id,creation_date,country,channeldId,average views\r\n";
    if (!data) {
      return ""
    };
    data.forEach((inf) => {
      let _country = inf['country'] || "";
      str += `${inf['_id']},${inf['creation_date']},${_country},${inf["youtube"]["accounts"].join(";")},${inf.averageViews}\r\n`;
    });

    let filename = 'influencers_noemail_' + new Date().toISOString() + '.csv';
    let contentType = "text/csv;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(str))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(str);
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  useEffect(() => {
    axios
      .get("/api/v1/influencer_noemail?with_yt_videos=true")
      .then((res) => {
        if (res.status === 200) {
          retrieveInfluencersAverageViews(res?.data?.result, async (count) => setCountLoaded(count)).then((influencersWithAvgViews) =>
            setDataExport(influencersWithAvgViews)
          );
        }
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error("Couldn't process request", "Error", 2000);
      });
  }, []);



  return (
    <div className="split_no_influencer">
      {(data) ? (
        <div
          className="split_no_influencer_data"
          onMouseEnter={() => showDetails(true)}
          onMouseLeave={() => showDetails(false)}
        >
          <button
            type="contained"
            onClick={() => parseDataNoEmail(data)}
          >
            {`DOWNLOAD NO EMAIL (${data.length})`}
          </button>
        </div>
      ) : (
        <div className="split_no_influencer_loading">LOADING INF WITHOUT EMAIL {countLoaded > 0 ? `(${countLoaded})` : ""}<ALLoading /></div>
      )}

      <div className="split_no_influencer_details" style={(isDetailsOpen) ? {"display": "flex"} : {"display": "none"}}>
        <span>💡 Rules:</span>
        <span>- Status = qualification_yes</span>
        <span>- No emails</span>
        <span>- Has a YouTube Channel</span>
        <span>- Doesn't contains "STOP" tag</span>
      </div>
    </div>
  )
}

export default NoEmailInfluencers;