import React from "react";
import axios from "axios";
import { DEAL_STATUSES, INFLUENCER_COUNTRIES } from "../../dictionaries";
import { ALDropdown, ALButton, AutoCompleteInput } from "../ALComponents";
import "./SearchOptions.css";

class SearchOptionLine extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="search-option-line">
        <div className="search-option-line__title">{this.props.title}</div>
        <div className="search-option-line__content">{this.props.children}</div>
      </div>
    );
  }
}

class SearchOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      campaigns: [],
    };
  }
  handleOnChangeNumber(e, searchObjectName, searchObjectFieldName) {
    const value = (e.target.value && parseInt(e.target.value)) || "";
    if (value && value < 0) return;
    this.props.onChange(searchObjectName, searchObjectFieldName, value);
  }
  handleOnClickGender(gender, key) {
    if (gender.includes(key)) {
      return this.props.onChange(
        "influencer",
        "gender",
        gender.filter((g) => g !== key)
      );
    }
    return this.props.onChange("influencer", "gender", [...gender, key]);
  }
  componentDidMount() {
    axios.get("/api/v1/tags").then((res) => {
      if (res.status == 200) {
        this.setState({ tags: res.data.tags.sort((a, b) => b.count - a.count) });
      }
    });
    axios.get("/api/v1/campain_tags").then((res) => {
      if (res.status == 200) {
        this.setState({ campaigns: res.data.tags.sort((a, b) => b.count - a.count) });
      }
    });
  }
  renderPlatforms() {
    const { youtube, instagram } = this.props;
    return (
      <div className="search-options__line">
        <div>
          <div className="search-options-option">
            <div className="search-options-option__title">Youtube</div>
            <div className="search-options-option__content">
              <div className="search-options__input-switch-container">
                <div
                  className={`search-options__input-switch${youtube.hasAccount ? "--selected" : ""}`}
                  onClick={() => (youtube.hasAccount ? null : this.props.onChange("youtube", "hasAccount", true))}>
                  Yes
                </div>
                <div
                  className={`search-options__input-switch${youtube.hasAccount ? "" : "--selected"}`}
                  onClick={() => (youtube.hasAccount ? this.props.onChange("youtube", "hasAccount", false) : null)}>
                  No
                </div>
              </div>
            </div>
          </div>
          <div className="search-options-suboption">
            {youtube.hasAccount ? (
              <>
                <div className="search-options__line" hidden={true}>
                  <div className="search-options-option__title">Engagement</div>
                  <div className="search-options-option__content">
                    <input
                      className="search-options__input-number"
                      type="number"
                      placeholder="Minimum"
                      value={youtube.engagementMinimum}
                      onChange={(e) => this.handleOnChangeNumber(e, "youtube", "engagementMinimum")}
                    />
                    <div className="search-options__input-number-separator">—</div>
                    <input
                      className="search-options__input-number"
                      type="number"
                      placeholder="Maximum"
                      value={youtube.engagementMaximum}
                      onChange={(e) => this.handleOnChangeNumber(e, "youtube", "engagementMaximum")}
                    />
                  </div>
                </div>
                <div className="search-options__line" hidden={true}>
                  <div className="search-options-option__title">Views/Video</div>
                  <div className="search-options-option__content">
                    <input
                      className="search-options__input-number"
                      type="number"
                      placeholder="Minimum"
                      value={youtube.viewsVideoMinimum}
                      onChange={(e) => this.handleOnChangeNumber(e, "youtube", "viewsVideoMinimum")}
                    />
                    <div className="search-options__input-number-separator">—</div>
                    <input
                      className="search-options__input-number"
                      type="number"
                      placeholder="Maximum"
                      value={youtube.viewsVideoMaximum}
                      onChange={(e) => this.handleOnChangeNumber(e, "youtube", "viewsVideoMaximum")}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div>
          <div className="search-options-option">
            <div className="search-options-option__title">Instagram</div>
            <div className="search-options-option__content">
              <div className="search-options__input-switch-container">
                <div
                  className={`search-options__input-switch${instagram.hasAccount ? "--selected" : ""}`}
                  onClick={() => (instagram.hasAccount ? null : this.props.onChange("instagram", "hasAccount", true))}>
                  Yes
                </div>
                <div
                  className={`search-options__input-switch${instagram.hasAccount ? "" : "--selected"}`}
                  onClick={() => (instagram.hasAccount ? this.props.onChange("instagram", "hasAccount", false) : null)}>
                  No
                </div>
              </div>
            </div>
          </div>
          <div className="search-options-suboption">
            {instagram.hasAccount ? (
              <>
                <div className="search-options__line" hidden={true}>
                  <div className="search-options-option__title">Engagement</div>
                  <div className="search-options-option__content">
                    <input
                      className="search-options__input-number"
                      type="number"
                      placeholder="Minimum"
                      value={instagram.engagementMinimum}
                      onChange={(e) => this.handleOnChangeNumber(e, "instagram", "engagementMinimum")}
                    />
                    <div className="search-options__input-number-separator">—</div>
                    <input
                      className="search-options__input-number"
                      type="number"
                      placeholder="Maximum"
                      value={instagram.engagementMaximum}
                      onChange={(e) => this.handleOnChangeNumber(e, "instagram", "engagementMaximum")}
                    />
                  </div>
                </div>
                <div className="search-options__line" hidden={true}>
                  <div className="search-options-option__title">Followers</div>
                  <div className="search-options-option__content">
                    <input
                      className="search-options__input-number"
                      type="number"
                      placeholder="Minimum"
                      value={instagram.followersMinimum}
                      onChange={(e) => this.handleOnChangeNumber(e, "instagram", "followersMinimum")}
                    />
                    <div className="search-options__input-number-separator">—</div>
                    <input
                      className="search-options__input-number"
                      type="number"
                      placeholder="Maximum"
                      value={instagram.followersMaximum}
                      onChange={(e) => this.handleOnChangeNumber(e, "instagram", "followersMaximum")}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  renderInfluencer() {
    const { influencer } = this.props;
    const { tags, campaigns } = this.state;

    return (
      <div>
        <div className="search-options__line">
          <div className="search-options-option">
            <div className="search-options-option__title">Tag</div>
            <div className="search-options-option__content">
              {/* <AutoCompleteInput
                list={DEAL_STATUSES.filter(status => !influencer.tags.includes(status))}
                placeholder="Choose tag"
                onSelection={(elem) => {
                  if (DEAL_STATUSES.includes(elem)) {
                    console.log("On selection")
                    this.props.onChange("influencer", "tags", [...new Set([...influencer.tags, elem])]);
                  }
                }}
              /> */}
              {/* <select
                value={(influencer.tags && influencer.tags[0]) || ""}
                onChange={(e) => this.props.onChange("influencer", "tags", [e.target.value])}>
                <option value="">Choose tag</option>
                {tags.map((tag, idx) => (
                  <option key={idx} value={tag._id}>
                    {tag.name}
                  </option>
                ))}
              </select> */}
              {/* <input
                className="search-options__input-text"
                type="text"
                placeholder="Tags query"
                value={influencer.tagsBeta}
                onChange={(e) => {
                  // let search = e.target.value;
                  this.props.onChange("influencer", "tagsBeta", e.target.value)
                }}
              /> */}

              {/* <ALDropdown
                options={[{ "_id": "", "name": "Choose tag" }, ...tags]}
                useValue={"name"}
                useKey={"_id"}
                value={(influencer.tags && influencer.tags[0]) || ""}
                onChange={(key) => this.props.onChange("influencer", "tags", [key])}
                style={{
                  "dropdown_container__selected_value": "search-options__dropdown-value",
                  "dropdown_container__list": "search-options__dropdown-list",
                }}
              /> */}
              <AutoCompleteInput
                list={tags.filter((tag) => !influencer.tags.length || !influencer.tags.includes(tag._id)).map((tag) => tag.name)}
                placeholder="Choose tags"
                onSelection={(name) => this.props.onChange("influencer", "tags", [...influencer.tags, tags.find((tag) => tag.name === name)._id])}
              />
              <div className="search-options-option__content__chip-list">
                {influencer.tags.map((tag, key) => (
                  <div className="search-options-option__content__chip-list__chip" key={key}>
                    <label>{tags.find((t) => t._id === tag).name}</label>
                    <span
                      className="material-icons"
                      onClick={() =>
                        this.props.onChange(
                          "influencer",
                          "tags",
                          influencer.tags.filter((t) => t !== tag)
                        )
                      }>
                      close
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="search-options-option">
            <div className="search-options-option__title search-options-option__title--secondary">Country</div>
            <div className="search-options-option__content">
              {/* <select
                value={(influencer.countries && influencer.countries[0]) || ""}
                onChange={(e) => this.props.onChange("influencer", "countries", [e.target.value])}>
                <option value="">Choose country</option>
                <option value="France">France</option>
                <option value="United States">United States</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Australia">Australia</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="China">China</option>
              </select> */}
              {/* <ALDropdown
                options={[{ key: "", value: "Choose country" }, ...INFLUENCER_COUNTRIES.map((s) => ({ key: s, value: s }))]}
                value={influencer.countries[0] || ""}
                onChange={(key) => this.props.onChange("influencer", "countries", [key])}
                style={{
                  "dropdown_container__selected_value": "search-options__dropdown-value",
                  "dropdown_container__list": "search-options__dropdown-list",
                }}
              /> */}
              <AutoCompleteInput
                list={INFLUENCER_COUNTRIES.filter((country) => !influencer.countries.length || !influencer.countries.includes(country))}
                placeholder="Choose countries"
                onSelection={(key) => this.props.onChange("influencer", "countries", [...influencer.countries, key])}
              />
              <div className="search-options-option__content__chip-list">
                {influencer.countries.map((country, key) => (
                  <div className="search-options-option__content__chip-list__chip" key={key}>
                    <label>{country}</label>
                    <span
                      className="material-icons"
                      onClick={() =>
                        this.props.onChange(
                          "influencer",
                          "countries",
                          influencer.countries.filter((c) => c !== country)
                        )
                      }>
                      close
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {influencer.tags.length > 1 ? (
          <div className="search-options__line">
            <div className="search-options-option">
              <div className="search-options-option__title"></div>
              <div className="search-options-option__content">
                <div className="search-options__input-switch-container search-options__input-switch-container--small">
                  <div
                    className={`search-options__input-switch${influencer.tagsMode === "And" ? "--selected" : ""}`}
                    onClick={() => (influencer.tagsMode === "And" ? null : this.props.onChange("influencer", "tagsMode", "And"))}>
                    And
                  </div>
                  <div
                    className={`search-options__input-switch${influencer.tagsMode === "Or" ? "--selected" : ""}`}
                    onClick={() => (influencer.tagsMode === "Or" ? null : this.props.onChange("influencer", "tagsMode", "Or"))}>
                    Or
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="search-options__line">
          <div className="search-options-option">
            <div className="search-options-option__title">Campaign</div>
            <div className="search-options-option__content">
              {/* <select
                value={(influencer.campaigns && influencer.campaigns[0]) || ""}
                onChange={(e) => this.props.onChange("influencer", "campaigns", [e.target.value])}>
                <option value="">Choose campaign</option>
                {campaigns.map((campaign, idx) => (
                  <option key={idx} value={campaign._id}>
                    {campaign.name}
                  </option>
                ))}
              </select> */}

              {/* <ALDropdown
                options={[{ "_id": "", "name": "Choose campaign" }, ...campaigns]}
                useValue={"name"}
                useKey={"_id"}
                value={(influencer.campaigns && influencer.campaigns[0]) || ""}
                onChange={(key) => this.props.onChange("influencer", "campaigns", [key])}
                style={{
                  "dropdown_container__selected_value": "search-options__dropdown-value",
                  "dropdown_container__list": "search-options__dropdown-list",
                }}
              /> */}
              <AutoCompleteInput
                list={campaigns
                  .filter((campaign) => !influencer.campaigns.length || !influencer.campaigns.includes(campaign._id))
                  .map((campaign) => campaign.name)}
                placeholder="Choose campaigns"
                onSelection={(name) =>
                  this.props.onChange("influencer", "campaigns", [...influencer.campaigns, campaigns.find((campaign) => campaign.name === name)._id])
                }
              />
              <div className="search-options-option__content__chip-list">
                {influencer.campaigns.map((campaign, key) => (
                  <div className="search-options-option__content__chip-list__chip" key={key}>
                    <label>{campaigns.find((t) => t._id === campaign).name}</label>
                    <span
                      className="material-icons"
                      onClick={() =>
                        this.props.onChange(
                          "influencer",
                          "campaigns",
                          influencer.campaigns.filter((t) => t !== campaign)
                        )
                      }>
                      close
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="search-options-option">
            <div className="search-options-option__title search-options-option__title--secondary">Gender</div>
            <div className="search-options-option__content">
              <div
                className={`search-options__button${influencer.gender.includes("Female") ? "--selected" : ""}`}
                onClick={() => this.handleOnClickGender(influencer.gender, "Female")}>
                Female
              </div>
              <div
                className={`search-options__button${influencer.gender.includes("Male") ? "--selected" : ""}`}
                onClick={() => this.handleOnClickGender(influencer.gender, "Male")}>
                Male
              </div>
              <div
                className={`search-options__button${influencer.gender.includes("Unknown") ? "--selected" : ""}`}
                onClick={() => this.handleOnClickGender(influencer.gender, "Unknown")}>
                Unknown
              </div>
            </div>
          </div>
          <div className="search-options-option">
            <div className="search-options-option__title search-options-option__title--secondary">Age</div>
            <div className="search-options-option__content">
              <input
                className="search-options__input-number"
                type="number"
                placeholder="Min"
                value={influencer.ageMinimum}
                onChange={(e) => this.handleOnChangeNumber(e, "influencer", "ageMinimum")}
              />
              <div className="search-options__input-number-separator">—</div>
              <input
                className="search-options__input-number"
                type="number"
                placeholder="Max"
                value={influencer.ageMaximum}
                onChange={(e) => this.handleOnChangeNumber(e, "influencer", "ageMaximum")}
              />
            </div>
          </div>
        </div>
        {influencer.campaigns.length > 1 ? (
          <div className="search-options__line">
            <div className="search-options-option">
              <div className="search-options-option__title"></div>
              <div className="search-options-option__content">
                <div className="search-options__input-switch-container search-options__input-switch-container--small">
                  <div
                    className={`search-options__input-switch${influencer.campaignsMode === "And" ? "--selected" : ""}`}
                    onClick={() => (influencer.campaignsMode === "And" ? null : this.props.onChange("influencer", "campaignsMode", "And"))}>
                    And
                  </div>
                  <div
                    className={`search-options__input-switch${influencer.campaignsMode === "Or" ? "--selected" : ""}`}
                    onClick={() => (influencer.campaignsMode === "Or" ? null : this.props.onChange("influencer", "campaignsMode", "Or"))}>
                    Or
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  renderDeal() {
    const { deal } = this.props;
    return (
      <div>
        <div className="search-options__line">
          <div className="search-options-option">
            <div className="search-options-option__title">Worked with before?</div>
            <div className="search-options-option__content">
              <div className="search-options__input-switch-container">
                <div
                  className={`search-options__input-switch${deal.workedWithBefore === "No" ? "--selected" : ""}`}
                  onClick={() => (deal.workedWithBefore === "No" ? null : this.props.onChange("deal", "workedWithBefore", "No"))}>
                  No
                </div>
                <div
                  className={`search-options__input-switch${deal.workedWithBefore === "Yes" ? "--selected" : ""}`}
                  onClick={() => (deal.workedWithBefore === "Yes" ? null : this.props.onChange("deal", "workedWithBefore", "Yes"))}>
                  Yes
                </div>
                <div
                  className={`search-options__input-switch${deal.workedWithBefore === "All" ? "--selected" : ""}`}
                  onClick={() => (deal.workedWithBefore === "All" ? null : this.props.onChange("deal", "workedWithBefore", "All"))}>
                  All
                </div>
              </div>
            </div>
          </div>
        </div>
        {deal.workedWithBefore === "Yes" ? (
          <>
            <div className="search-options__line">
              <div className="search-options-option">
                <div className="search-options-option__title"># of Deals</div>
                <div className="search-options-option__content">
                  <input
                    className="search-options__input-number"
                    type="number"
                    placeholder="Minimum"
                    value={deal.numberOfDealMinimum}
                    onChange={(e) => this.handleOnChangeNumber(e, "deal", "numberOfDealMinimum")}
                  />
                  <div className="search-options__input-number-separator">—</div>
                  <input
                    className="search-options__input-number"
                    type="number"
                    placeholder="Maximum"
                    value={deal.numberOfDealMaximum}
                    onChange={(e) => this.handleOnChangeNumber(e, "deal", "numberOfDealMaximum")}
                  />
                </div>
              </div>
              <div className="search-options-option">
                <div className="search-options-option__title search-options-option__title--secondary">Price</div>
                <div className="search-options-option__content">
                  <input
                    className="search-options__input-number"
                    type="number"
                    placeholder="Minimum"
                    value={deal.priceMinimum}
                    onChange={(e) => this.handleOnChangeNumber(e, "deal", "priceMinimum")}
                  />
                  <div className="search-options__input-number-separator">—</div>
                  <input
                    className="search-options__input-number"
                    type="number"
                    placeholder="Maximum"
                    value={deal.priceMaximum}
                    onChange={(e) => this.handleOnChangeNumber(e, "deal", "priceMaximum")}
                  />
                </div>
              </div>
              <div className="search-options-option">
                <div className="search-options-option__title search-options-option__title--secondary">Days since last deal</div>
                <div className="search-options-option__content">
                  <input
                    className="search-options__input-number"
                    type="number"
                    placeholder="Minimum"
                    value={deal.daysSinceLastDealMinimum}
                    onChange={(e) => this.handleOnChangeNumber(e, "deal", "daysSinceLastDealMinimum")}
                  />
                  <div className="search-options__input-number-separator">—</div>
                  <input
                    className="search-options__input-number"
                    type="number"
                    placeholder="Maximum"
                    value={deal.daysSinceLastDealMaximum}
                    onChange={(e) => this.handleOnChangeNumber(e, "deal", "daysSinceLastDealMaximum")}
                  />
                </div>
              </div>
            </div>
            <div className="search-options__line">
              <div className="search-options-option" hidden={true}>
                <div className="search-options-option__title">Status (most recent)</div>
                <div className="search-options-option__content">
                  {/* <select value={deal.mostRecentStatus} onChange={(e) => this.props.onChange("deal", "mostRecentStatus", e.target.value)}>
                <option value="">Choose status</option>
                {DEAL_STATUSES.map((status, idx) => (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                ))}
              </select> */}
                  <ALDropdown
                    options={[{ key: "", value: "Choose status" }, ...DEAL_STATUSES.map((s) => ({ key: s, value: s }))]}
                    value={deal.mostRecentStatus}
                    onChange={(key) => this.props.onChange("deal", "mostRecentStatus", key)}
                    style={{
                      "dropdown_container__selected_value": "search-options__dropdown-value",
                      "dropdown_container__list": "search-options__dropdown-list",
                    }}
                  />
                </div>
              </div>
              <div className="search-options-option">
                <div className="search-options-option__title">At least one status</div>
                <div className="search-options-option__content">
                  {/* <select
                value={(deal.atLeastOneStatus && deal.atLeastOneStatus[0]) || ""}
                onChange={(e) => this.props.onChange("deal", "atLeastOneStatus", e.target.value ? [e.target.value] : [])}>
                <option value="">Choose status</option>
                {DEAL_STATUSES.map((status, idx) => (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                ))}
              </select> */}
                  <ALDropdown
                    options={[{ key: "", value: "Choose status" }, ...DEAL_STATUSES.map((s) => ({ key: s, value: s }))]}
                    value={(deal.atLeastOneStatus && deal.atLeastOneStatus[0]) || ""}
                    onChange={(key) => this.props.onChange("deal", "atLeastOneStatus", key ? [key] : [])}
                    style={{
                      "dropdown_container__selected_value": "search-options__dropdown-value",
                      "dropdown_container__list": "search-options__dropdown-list",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
  render() {
    return (
      <div className="search-options">
        <SearchOptionLine title="Platforms" isExpended={true}>
          {this.renderPlatforms()}
        </SearchOptionLine>
        <hr className="search-options__separator" />
        <SearchOptionLine title="Influencer" isExpended={true}>
          {this.renderInfluencer()}
        </SearchOptionLine>
        <hr className="search-options__separator" />
        <SearchOptionLine title="Deal" isExpended={true}>
          {this.renderDeal()}
        </SearchOptionLine>
      </div>
    );
  }
}

export default SearchOptions;
