import axios from "axios";
import * as Sentry from "@sentry/react";
import {
  addDays,
  eachDayOfInterval,
  startOfDay,
  differenceInDays,
} from "date-fns";
import { chunk } from "lodash";

export function storageAvailable(type) {
  var storage;
  try {
    storage = window[type];
    var x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export function capitalize(string) {
  return string && string[0].toUpperCase() + string.slice(1);
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getAgeAndGenderFromRekognition(pictureURL) {
  try {
    const { data } = await axios.post("/api/v1/rekognito", {
      imageUrl: pictureURL,
    });
    const faceAWS = data.faces;
    let theGender = null;
    let theAge = null;
    if (faceAWS) {
      for (var i = 0; i < faceAWS["FaceDetails"].length; i++) {
        if (
          faceAWS["FaceDetails"][i]["Gender"]["Value"] == "Female" &&
          faceAWS["FaceDetails"][i]["AgeRange"]["Low"] > 12
        ) {
          theGender = {
            value: faceAWS["FaceDetails"][i]["Gender"]["Value"],
            confidence: faceAWS["FaceDetails"][i]["Gender"]["Confidence"],
          };
          theAge = {
            low: faceAWS["FaceDetails"][i]["AgeRange"]["Low"],
            high: faceAWS["FaceDetails"][i]["AgeRange"]["High"],
            exact: null,
          };
          break;
        }
        if (
          faceAWS["FaceDetails"][i]["Gender"]["Value"] == "Male" &&
          faceAWS["FaceDetails"][i]["AgeRange"]["Low"] > 12
        ) {
          theGender = {
            value: faceAWS["FaceDetails"][i]["Gender"]["Value"],
            confidence: faceAWS["FaceDetails"][i]["Gender"]["Confidence"],
          };
          theAge = {
            low: faceAWS["FaceDetails"][i]["AgeRange"]["Low"],
            high: faceAWS["FaceDetails"][i]["AgeRange"]["High"],
            exact: null,
          };
        }
      }
    }
    return { gender: theGender, age: theAge };
  } catch (e) {
    console.error(e);
    return null;
  }
}

// This function provide a simple way of hashing strings
// with a therorical 64bit collision probability
// and padded on 14 char
// it is named 'unsecure' to not use it to hash password
// or anything that should cryptographically be protected
export function unsecureHash(stringToHash) {
  // Based on Java's String.hashCode() algorithm
  function unsecure32bitIntegerHash(stringToHash) {
    let hash = 0;
    if (stringToHash.length == 0) {
      return hash;
    }
    for (let i = 0; i < stringToHash.length; i++) {
      const charCode = stringToHash.charCodeAt(i);
      hash = (hash << 5) - hash + charCode;
      hash = hash & hash;
    }
    return hash;
  }
  const firstHash = unsecure32bitIntegerHash(stringToHash).toString(36); // returns 32 bit as hex string
  return (
    firstHash + unsecure32bitIntegerHash(firstHash + stringToHash).toString(36)
  )
    .toUpperCase()
    .padEnd(14, "="); // 64 bit hex string padded on 14 char
}
export function reportError(err) {
  Sentry.captureException(err);
}

export function splitIntervalIntoChunks(startDate, endDate, chunkSize) {
  const interval = eachDayOfInterval({ start: startDate, end: endDate });
  const chunks = [];

  for (let i = 0; i < interval.length; i += chunkSize) {
    const chunkStart = interval[i];
    const chunkEnd = addDays(
      interval[i],
      chunkSize - 1 > interval.length - 1 - i
        ? interval.length - 1 - i
        : chunkSize - 1
    );
    chunks.push({ start: chunkStart, end: chunkEnd });
  }

  return chunks;
}

// do GET request to Stock Tracker google apps script to trigger a deal update
export function triggerStockTrackerDealUpdate(dealId) {
  const GOOGLE_APPS_API =
    "https://script.google.com/macros/s/AKfycbwIC79-Tbl4d8IRhNETQzcqb_OsnkPUVh1A4wfkaKdeyJjcJ_ssAgVOkDKhwOfMNyDm/exec";

  return fetch(`${GOOGLE_APPS_API}?action=update&deal_id=${dealId}`, {
    redirect: "follow",
    method: "GET",
    headers: {
      "Content-Type": "text/plain;charset=utf-8",
    },
  });
}

export async function getInfluencers(currentUser) {
  const limit = 100;
  let influencers = [];
  let page = 0;

  let response;
  do {
    response = await axios.get("/api/v1/influencers_full_by_deal?", {
      params: {
        deal_am: '"' + currentUser + '"',
        deal_status:
          '{"$in":["negociation", "details", "hold", "call", "lead", "created", "adam", "adam_yes", "adam_no", "shipped", "to_ship", "in_transit", "shipment_received"]}',
        dealsort_date_last_outreach: '"1"',
        page: page,
        limit: limit,
      },
    });
    console.log("response", response);
    if (response.status !== 200) return [];
    influencers = [...influencers, ...response.data.result];
    page += 1;
  } while (response.data.result.length > 0);

  return influencers;
}


export async function getTransactions(transactionIds) {
  let allTransactions = [];

  // Get all transactions, split in chunks of 100
  const chunkedTransactionIds = chunk(transactionIds, 100);

  // Query the transactions sequentially so we don't overload the db
  for (const ids of chunkedTransactionIds) {
    const results = await axios.get("/api/v1/transactions", {
      params: { _id: { $in: ids } },
    });
    allTransactions.push(...results.data.result);
  }

  return allTransactions;
}

export function getProfileImage(influencer) {
  let hasYoutubeAccount = influencer.youtube.accounts.length > 0;
  let youtubeData = influencer.youtube.accounts[0];
  if (youtubeData === "") {
    hasYoutubeAccount = false;
    youtubeData = null;
  }

  if (hasYoutubeAccount) {
    return youtubeData.pictureURL;
  }

  let hasInstagramAccount = influencer.instagram.accounts.length > 0;
  let instagramData = influencer.instagram.accounts[0];
  if (instagramData === "") {
    hasInstagramAccount = false;
    instagramData = null;
  }

  if (hasInstagramAccount) {
    return instagramData.pictureURL;
  }
}

export function getSocialMedia(influencer) {
  return {
    youtube:
      influencer.youtube?.accounts.length > 0
        ? influencer.youtube.accounts[0]
        : false,
    instagram:
      influencer.instagram?.accounts.length > 0
        ? influencer.instagram.accounts[0]
        : false,
    tiktok:
      influencer.tiktok?.accounts.length > 0
        ? influencer.tiktok.accounts[0]
        : false,
    podcast:
      influencer.podcast?.accounts.length > 0
        ? influencer.podcast.accounts[0]
        : false,
  };
}

export function getInfluencerName(influencer) {
  const youtubeData = influencer.youtube?.accounts[0];
  const instagramData = influencer.instagram?.accounts[0];
  const tiktokData = influencer.tiktok?.accounts[0];
  const podcastData = influencer.podcast?.accounts[0];

  if (influencer.name !== "") {
    return influencer.name;
  } else if (
    influencer.youtube.accounts.length > 0 &&
    typeof youtubeData === "string"
  ) {
    return youtubeData;
  } else if (influencer.youtube.accounts.length > 0 && youtubeData.title) {
    return youtubeData.title;
  } else if (typeof podcastData === "object") {
    return podcastData.name;
  } else if (
    influencer.instagram.accounts.length > 0 &&
    typeof instagramData === "string"
  ) {
    return instagramData;
  } else if (
    influencer.instagram.accounts.length > 0 &&
    instagramData.fullName
  ) {
    return instagramData.fullName;
  } else if (typeof tiktokData === "string") {
    return tiktokData;
  } else if (tiktokData) {
    return tiktokData?.fullName;
  }
}

export function getDifferenceInDays(date) {
  const today = startOfDay(new Date());
  return differenceInDays(startOfDay(new Date(date)), today);
}

export async function getAccountManagers() {
  let accountManagers = [];
  const res = await axios.get(
    "/api/v1/team/account-manager?with=admin&with=super-user"
  );
  if (res.data.message === "ok") {
    res.data.users.forEach((user) => {
      if (
        user.teams.includes("YouTube") ||
        user.teams.includes("Co-creation") ||
        user.teams.includes("Instagram") ||
        user.teams.includes("TikTok") ||
        user.teams.includes("Podcast")
      )
        accountManagers.push({
          id: user.email,
          title: `${user.firstName} ${user.lastName}`,
          ...user,
        });
    });
  }
  return accountManagers;
}

export function isDealActive(deal) {
  return !["published", "declined_outreach"].includes(deal.status);
}

export default {
  storageAvailable,
  capitalize,
  getAgeAndGenderFromRekognition,
  sleep,
  unsecureHash,
  splitIntervalIntoChunks,
  triggerStockTrackerDealUpdate,
  getInfluencers,
  getInfluencerName,
  getSocialMedia,
  getProfileImage,
  getDifferenceInDays,
  getAccountManagers,
  isDealActive,
  getTransactions,
};
