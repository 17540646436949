import React, { useState, useEffect } from "react";
import axios from "axios";
import { ALDropdown, ALLoading } from "./ALComponents";
import Loading from "./Loading";
import "./UserManagement.css";
import { USER_ROLES, USER_TEAMS } from "dictionaries";
import NotificationManager from "./al_components/notification/NotificationManager";

const UserManagementSearchAndFilter = ({ searchFilter, setSearchFilter }) => {
  return (
    <div className="um-search">
      <div className="um-search__filter">
        <input
          className="um-search__filter__value"
          type="text"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder="Search"
        />
        <span className="um-search__filter__icon material-icons" onClick={() => null}>
          {searchFilter?.length ? "search_off" : "search"}
        </span>
      </div>
    </div>
  );
};

const UserLine = ({ user, isInactive, removeUser, updateUser }) => {
  const [isAddTeamOpen, setIsAddTeamOpen] = useState(false);

  return (
    <div className={`um-user-line ${isInactive ? "um-user-line--inactive" : ""}`} key={user.email}>
      <div className={`um-user-line__remove ${user.role !== "" ? "um-user-line__remove--hidden" : ""}`}>
        <span className="um-user-line__remove__icon material-icons" onClick={() => user.role === "" && removeUser(user._id)}>
          close
        </span>
      </div>
      <div className="um-user-line__picture">
        <img className="um-user-line__picture__img" src={user.picture} alt="picture" crossOrigin="anonymous" referrerPolicy="no-referrer" />
      </div>
      <div className="um-user-line__name">
        {user.firstName || user.username || ""} {user.lastName}
      </div>
      <div className="um-user-line__email">{user.email}</div>
      <div className="um-user-line__level">
        <ALDropdown
          options={USER_ROLES.map((role) => (role === "" ? "UNASSIGNED" : role.replace("-", " ")))}
          value={user.role || "UNASSIGNED"}
          onChange={(key) => {
            const chosenRole = key === "UNASSIGNED" ? "" : key.replace(" ", "-");
            updateUser(user, { "role": chosenRole });
          }}
        />
      </div>
      <div className="um-user-line__team">
        {user.teams.map((team, idx) => (
          <div className="um-user-line__team__chip" key={idx}>
            <div className="um-user-line__team__chip__text">{team}</div>
            <span
              className="um-user-line__team__chip__close material-icons"
              onClick={() => updateUser(user, { "teams": user.teams.filter((t) => t !== team) })}>
              close
            </span>
          </div>
        ))}
      </div>
      <div className="um-user-line__team_add">
        <span className="um-user-line__team_add__icon material-icons" onClick={() => setIsAddTeamOpen(true)}>
          add
        </span>
        <div className={`um-user-line__team_add__menu ${!isAddTeamOpen ? "um-user-line__team_add__menu--hidden" : ""}`}>
          <span className="um-user-line__team_add__menu__close material-icons" onClick={() => setIsAddTeamOpen(false)}>
            close
          </span>
          <div className="um-user-line__team_add__menu__filter"></div>
          <div className="um-user-line__team_add__menu__team-list">
            {USER_TEAMS.map((team, idx) => (
              <div
                className="um-user-line__team_add__menu__team-list__chip"
                key={idx}
                onClick={() => updateUser(user, { "teams": [...user.teams, team] })}>
                <div className="um-user-line__team_add__menu__team-list__text">{team}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserManagementList = ({ users, updateUser, removeUser }) => {
  console.log("users", users);
  const pendingUsers = users.filter((user) => !user.role);
  const activeUsers = users.filter((user) => !!user.role);
  return (
    <div className="um-list">
      <div className="um-list__header">
        <div className="um-list__header__item um-list__header__picture"></div>
        <div className="um-list__header__item um-list__header__name">Name</div>
        <div className="um-list__header__item um-list__header__email">Email</div>
        <div className="um-list__header__item um-list__header__level">Level</div>
        <div className="um-list__header__item um-list__header__team">Team</div>
        <div className="um-list__header__item um-list__header__team_add"></div>
      </div>
      {pendingUsers.length ? (
        <div className="um-list__pending">
          {pendingUsers.map((user) => (
            <UserLine key={user._id} user={user} isInactive={true} updateUser={updateUser} removeUser={removeUser} />
          ))}
        </div>
      ) : null}
      <div className="um-list__active">
        {activeUsers.map((user) => (
          <UserLine key={user._id} user={user} isInactive={user.role === "disabled"} updateUser={updateUser} removeUser={removeUser} />
        ))}
      </div>
    </div>
  );
};

const UserManagement = (props) => {
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");

  function formatUser(user) {
    return {
      ...user,
      "teams": user.teams || [],
      "role": user.role || "",
      "filterField": `${user.email || ""}${user.role || ""}${user.firstName || ""}${user.lastName || ""}${(user.teams || []).reduce(
        (acc, team) => acc + team,
        ""
      )}${user.username || ""}`,
    };
  }

  useEffect(() => {
    axios.get("/api/v1/users").then((userResponse) => {
      if (userResponse.data.status === "ok") {
        setUsers(
          userResponse.data.result.map((user) => formatUser(user)).sort((a, b) => USER_ROLES.indexOf(a.role || "") - USER_ROLES.indexOf(b.role || ""))
        );
      }
      setIsLoadingUsers(false);
    });
  }, []);

  function removeUser(userId) {
    axios
      .delete(`/api/v1/users/${userId}`)
      .then(() => {
        NotificationManager.success("User successfully deleted!");
        setUsers(users.filter((user) => user._id !== userId));
      })
      .catch((err) => {
        const errStatus = err?.response?.data?.status;
        if (errStatus === "not allowed") {
          return NotificationManager.error("Operation not allowed");
        }
        console.error(err);
        NotificationManager.error("Failed");
      });
  }

  function updateUser(user, update) {
    axios
      .put(`/api/v1/users/${user._id}`, update)
      .then((response) => {
        const updatedUser = formatUser(response.data.result);
        NotificationManager.success("User updated!");
        setUsers(users.map((u) => (u._id === user._id ? updatedUser : u)));
      })
      .catch((err) => {
        const errStatus = err?.response?.data?.status;
        if (errStatus === "not allowed") {
          return NotificationManager.error("Operation not allowed");
        }
        console.error(err);
        NotificationManager.error("Failed");
      });
  }

  return (
    <div className="user-management">
      {isLoadingUsers ? (
        <ALLoading text={`Hi ${props.user.firstName}!\nWe are retrieving all Oklos users...`} />
      ) : (
        <div>
          <UserManagementSearchAndFilter searchFilter={searchFilter} setSearchFilter={setSearchFilter} />
          <UserManagementList
            users={users.filter((user) => user.filterField.includes(searchFilter))}
            removeUser={removeUser}
            updateUser={updateUser}
          />
        </div>
      )}
    </div>
  );
};

export default UserManagement;
