import React from "react";
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import "./Scrapping.css";
class Scrapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: this.props.data,
      date: new Date(),
      channels: this.props.channels,
      inv: 0,
      no_enough: 0,
      no_recent: 0,
      less_average: 0,
      proxiesValues: [],
      proxiesError: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.channels !== this.state.channels) {
      let i = 0;
      this.setState({ channels: this.props.channels });
      const rdnChannel = this.randomChannelId();
      let that = this;
      setTimeout(function(){
        that.checkChannels(i, rdnChannel, 0);
      }, 250);
    }
  }
  checkChannels(i, rdnChannel, errorCount) {
    // console.log('--------');
    // console.log('state.channels.length');
    // console.log(this.state.channels.length);
    // console.log('random channel');
    // console.log(rdnChannel);
    // console.log('the channel selected');
    // console.log(this.state.channels[rdnChannel]);
    let channelsLength = this.state.channels.length
    if (this.state.channels.length === 0) {
      this.props.reloadData();
      return;
    }
    if (rdnChannel > channelsLength || rdnChannel === channelsLength) {
      rdnChannel = channelsLength - 1;
    }
    if (!("channelId" in this.state.channels[rdnChannel])) {
      rdnChannel -= 1;
    }

    const channelId = this.state.channels[rdnChannel].channelId;

    const { inv, no_enough, no_recent, less_average } = this.state;
    let totalChannelsDone = inv + no_enough + no_recent + less_average;
    /*
    if (totalChannelsDone % 50 === 0 && totalChannelsDone !== 0) {
      // time to get new channels from db
      console.log('modulo 50????');
      this.updateNewChannels();
      return;
    }
    */
    if (errorCount > 3) {
      axios({
        method: 'put',
        url: `/api/v1/youtube/${channelId}`,
        data: {
          status: 'scrapped',
          statusReason: 'Error xml',
        }
      }).then((response) => {
        // console.log('response (step 3) -> ERROR so update');
        var newChannels = this.state.channels;
        newChannels.splice(rdnChannel,1);
        this.setState({ channels: newChannels });
        if (newChannels.length !== 0) {
          const rdnChannelId = this.randomChannelId();

          let that = this;
          setTimeout(function(){
            // here
            that.checkChannels(i+1, rdnChannelId, 0);
          }, 250);
        }
      });
    }
    var listProxy = {
      "proxies": [
        {"ip": "204.15.243.234", "port": "51323" },
        {"ip": "35.247.189.6", "port": "8000" },
        {"ip": "50.247.207.5", "port": "49788" },
        {"ip": "74.113.169.129", "port": "47208" },
        {"ip": "54.211.101.131", "port": "8888" },
        {"ip": "198.211.96.170", "port": "3128" },
        {"ip": "205.185.115.100", "port": "8080"},
        {"ip": "167.71.105.166", "port": "3128"},
        {"ip": "70.56.138.51", "port": "8080"},
        {"ip": "209.146.45.46", "port": "8080"},
        {"ip": "209.159.158.234", "port": "8080"},
        {"ip": "192.99.191.236", "port": "1080"},
        {"ip": "209.90.63.108", "port": "80"},
        {"ip": "24.172.34.114", "port": "40327"},
        {"ip": "98.172.141.251", "port": "8080"},
        {"ip": "69.130.120.228", "port": "46439"},
        {"ip": "192.99.191.238", "port": "1080"},
        {"ip": "24.106.221.230", "port": "53281"},
        {"ip": "167.71.94.127", "port": "3128"},
        {"ip": "167.71.106.246", "port": "3128"},
        {"ip": "70.56.138.51", "port": "8080"},
        {"ip": "209.146.45.46", "port": "8080"},
        {"ip": "192.99.191.236", "port": "1080"},
        {"ip": "38.134.10.58", "port": "53281"},
        {"ip": "198.143.179.130", "port": "3128"},
        {"ip": "167.71.97.146", "port": "3128"},
        {"ip": "167.71.103.168", "port": "3128"},
        {"ip": "167.71.97.177", "port": "3128"},
        {"ip": "167.71.182.183", "port": "3128"},
        {"ip": "50.233.228.147", "port": "8080"},
        {"ip": "49.51.195.24", "port": "1080"},
        {"ip": "45.79.57.202", "port": "8125"},
        {"ip": "49.51.193.134", "port": "1080"},
        {"ip": "66.82.22.79", "port": "80"},
        {"ip": "76.87.101.188", "port": "38875"},
        {"ip": "67.205.172.48", "port": "8118"},
        {"ip": "49.51.193.128", "port": "1080"},
        {"ip": "24.241.136.242", "port": "59702"},
        {"ip": "35.192.7.115", "port": "8080"},
        {"ip": "173.46.67.172", "port": "58517"},
        {"ip": "198.98.51.240", "port": "8080"},
        {"ip": "162.243.130.63", "port": "3128"},
        {"ip": "104.236.248.219", "port": "3128"},
        {"ip": "167.71.182.191", "port": "3128"},
        {"ip": "104.244.75.26", "port": "8080"},
        {"ip": "167.71.250.73", "port": "3128"},
        {"ip": "35.235.75.244", "port": "3128"},
        {"ip": "20.188.75.207", "port": "3128"},
        {"ip": "50.192.195.69", "port": "52018"},
        {"ip": "50.242.113.57	", "port": "58619"},
        {"ip": "167.71.161.102", "port": "8080"},
        {"ip": "198.50.152.64", "port": "23500"},
        {"ip": "24.113.38.149", "port": "48678"},
        {"ip": "191.96.42.184", "port": "3129"},
        {"ip": "207.246.127.136", "port": "8080"},
        {"ip": "74.84.255.88", "port": "53281"},
        {"ip": "38.134.10.106", "port": "53281"},
        {"ip": "75.80.242.9", "port": "41007"},
        {"ip": "35.192.7.115", "port": "8080"},
        {"ip": "102.129.249.67", "port": "3129"},
        {"ip": "192.99.191.232", "port": "1080"},
        {"ip": "12.218.209.130", "port": "53281"},
        {"ip": "206.214.211.166", "port": "80"},
        {"ip": "165.138.225.250", "port": "8080"},
        {"ip": "216.255.170.14", "port": "48678"},
        {"ip": "34.90.211.79", "port": "3128"},
        {"ip": "167.71.186.105", "port": "3128"},
        {"ip": "167.71.186.103", "port": "3128"},
        {"ip": "138.68.53.94", "port": "3128"},
        {"ip": "198.255.114.226", "port": "3128"},
        {"ip": "167.71.252.107", "port": "3128"},
        {"ip": "167.71.182.175", "port": "3128"},
        {"ip": "167.71.105.170", "port": "3128"},
        {"ip": "148.77.34.196", "port": "45146"},
        {"ip": "208.84.63.81", "port": "43026"},
        {"ip": "107.178.4.215", "port": "44826"},
        {"ip": "24.172.225.122", "port": "53281"},
        {"ip": "12.8.246.57", "port": "41845"},
        {"ip": "35.247.248.71", "port": "3128"},
        {"ip": "185.243.56.173", "port": "8080"},
        {"ip": "185.243.56.170", "port": "8080"},
        {"ip": "198.98.54.241", "port": "8080"},
        {"ip": "207.235.20.1", "port": "32517"},
        {"ip": "50.113.25.114", "port": "3128"},
        {"ip": "70.37.111.187", "port": "80"},
        {"ip": "73.55.10.186", "port": "8080"},
        {"ip": "174.138.38.128", "port": "80"},
        {"ip": "35.198.47.12", "port": "3128"},
        {"ip": "35.188.3.254", "port": "3128"},
        {"ip": "159.203.87.130", "port": "3128"},
        {"ip": "165.166.60.62", "port": "3128"},
        {"ip": "96.9.238.135", "port": "3129"},
        {"ip": "191.96.42.82", "port": "3129"},
        {"ip": "66.7.113.39", "port": "3128"},
        {"ip": "50.244.252.166", "port": "8081"},
        {"ip": "34.237.153.232", "port": "3128"},
        {"ip": "162.144.250.249", "port": "8888"},
        {"ip": "129.146.181.251", "port": "3128"},
        {"ip": "199.195.248.24", "port": "8080"},
        {"ip": "165.22.182.87", "port": "1080"},
        {"ip": "49.51.70.42", "port": "1080"},
        {"ip": "35.247.128.90", "port": "8000"},
        {"ip": "73.204.77.238", "port": "8080"},
        {"ip": "24.35.1.5", "port": "52867"},
        {"ip": "167.99.144.160", "port": "80"},
        {"ip": "47.254.36.167", "port": "808"},
        {"ip": "69.65.65.178", "port": "59720"},
        {"ip": "199.119.158.2", "port": "56319"},
        {"ip": "72.250.28.64", "port": "36851"},
        {"ip": "45.79.46.57", "port": "8118"},
        {"ip": "157.230.175.53", "port": "8118"},
        {"ip": "73.138.184.9", "port": "37008"},
        {"ip": "13.65.103.109", "port": "80"},
        {"ip": "45.79.37.155", "port": "3128"},
        {"ip": "165.22.185.178", "port": "3128"},
        {"ip": "207.246.127.100", "port": "8080"},
        {"ip": "52.10.146.243", "port": "3838"},
        {"ip": "173.196.152.235", "port": "5005"},
        {"ip": "50.246.120.125", "port": "8080"},
        {"ip": "205.202.32.210", "port": "8080"},
        {"ip": "167.71.254.71", "port": "3128"},
        {"ip": "35.198.41.37", "port": "3128"},
        {"ip": "129.146.180.238", "port": "3128"},
        {"ip": "102.165.53.62", "port": "8080"},
        {"ip": "204.29.115.149", "port": "8080"},
        {"ip": "35.199.116.146", "port": "3128"},
        {"ip": "167.71.254.86", "port": "3128"},
        {"ip": "191.96.43.58", "port": "3129"},
        {"ip": "72.35.40.34", "port": "8080"},
        {"ip": "167.71.97.196", "port": "3128"},
        {"ip": "167.99.52.107", "port": "8888"},
        {"ip": "157.245.9.78", "port": "3128"},
        {"ip": "198.98.56.71", "port": "8080"},
        {"ip": "157.245.6.102", "port": "3128"},
        {"ip": "206.82.18.72", "port": "8080"},
        {"ip": "157.245.4.19", "port": "3128"},
        {"ip": "207.191.15.166", "port": "38528"},
        {"ip": "191.96.42.86", "port": "3129"},
        {"ip": "24.237.75.79", "port": "8080"},
        {"ip": "72.21.66.222", "port": "58983"},
        {"ip": "167.71.182.13", "port": "3128"},
        {"ip": "157.245.0.154", "port": "3128"},
        {"ip": "198.98.55.168", "port": "8080"},
        {"ip": "157.245.0.181", "port": "3128"},
        {"ip": "138.68.17.62", "port": "8888"},
        {"ip": "23.98.148.163", "port": "8080"},
        {"ip": "64.33.171.19", "port": "8080"},
        {"ip": "68.177.71.128", "port": "47230"},
        {"ip": "216.189.147.133", "port": "8080"},
        {"ip": "35.186.144.101", "port": "8080"},
        {"ip": "24.172.82.94", "port": "53281"},
        {"ip": "207.148.18.124", "port": "30563"},
        {"ip": "23.98.144.61", "port": "80"},
        {"ip": "157.245.14.4", "port": "3128"},
        {"ip": "50.200.55.118", "port": "8080"},
        {"ip": "18.233.174.32", "port": "8080"},
        {"ip": "181.214.240.54", "port": "3120"},
        {"ip": "96.72.82.25", "port": "43844"},
        {"ip": "208.180.237.55", "port": "31012"},
        {"ip": "63.249.67.70", "port": "53281"},
        {"ip": "12.31.192.18", "port": "1337"},
        {"ip": "50.197.20.162", "port": "55389"},
        {"ip": "181.214.240.116", "port": "3120"},
        {"ip": "204.15.243.233", "port": "31546"},
        {"ip": "24.227.248.226", "port": "32336"},
        {"ip": "136.25.2.43", "port": "49126"},
        {"ip": "68.183.169.43", "port": "3128"},
        {"ip": "167.99.24.49", "port": "8118"},
        {"ip": "162.248.247.153", "port": "32592"},
        {"ip": "162.223.89.94", "port": "8080"},
        {"ip": "50.236.148.254", "port": "39970"},
        {"ip": "104.32.43.13", "port": "53281"},
        {"ip": "67.75.2.39", "port": "3128"},
        {"ip": "198.8.93.44", "port": "3128"},
        {"ip": "207.144.111.230", "port": "8080"},
        {"ip": "181.214.240.168", "port": "3120"},
        {"ip": "166.249.185.131", "port": "35518"},
        {"ip": "157.245.135.93", "port": "3128"},
        {"ip": "204.98.224.62", "port": "8080"},
        {"ip": "70.169.149.231", "port": "48678"},
        {"ip": "49.51.68.122", "port": "1080"},
        {"ip": "205.158.57.2", "port": "53281"},
        {"ip": "166.249.185.136", "port": "54656"},
        {"ip": "50.200.55.118", "port": "8080"},
        {"ip": "157.245.88.52", "port": "3128"},
        {"ip": "204.116.211.76", "port": "8080"},
        {"ip": "157.55.199.147", "port": "80"},
        {"ip": "65.152.119.226", "port": "44844"},
        {"ip": "157.55.201.215", "port": "80"},
        {"ip": "96.9.239.184", "port": "3129"},
        {"ip": "208.108.120.58", "port": "8080"},
        {"ip": "206.176.107.163", "port": "8080"},
        {"ip": "138.68.53.44", "port": "8118"},
        {"ip": "50.197.38.230", "port": "60724"},
        {"ip": "204.116.211.75", "port": "8080"},
        {"ip": "205.201.49.151", "port": "53281"},
        {"ip": "97.79.174.222", "port": "53281"},
        {"ip": "162.249.248.218", "port": "53281"},
        {"ip": "96.9.238.235", "port": "3129"},
        {"ip": "167.71.243.93", "port": "3128"},
        {"ip": "96.9.212.167", "port": "3129"},
        {"ip": "96.9.212.147", "port": "3129"},
        {"ip": "96.9.214.220", "port": "3129"},
        {"ip": "96.9.212.175", "port": "3129"},
        {"ip": "96.9.215.234", "port": "3129"},
        {"ip": "104.128.206.123", "port": "57763"},
        {"ip": "66.82.22.51", "port": "80"},
        {"ip": "45.56.68.205", "port": "3128"},
        {"ip": "96.9.212.234", "port": "3129"},
        {"ip": "96.9.239.31", "port": "3129"},
        {"ip": "4.34.50.189", "port": "55656"},
        {"ip": "67.60.137.219", "port": "35979"},
        {"ip": "74.113.173.78", "port": "47208"},
        {"ip": "76.185.16.94", "port": "54079"},
        {"ip": "96.9.215.46", "port": "3129"},
        {"ip": "75.151.213.85", "port": "8080"},
        {"ip": "207.244.139.89", "port": "39272"},
        {"ip": "209.203.130.51", "port": "8080"},
        {"ip": "96.9.237.178", "port": "3129"},
        {"ip": "96.9.213.122", "port": "3129"}
        // {"ip": "", "port": ""},
      ]
    }
    const randomNumber = Math.floor(Math.random() * (listProxy.proxies.length - 1));
    const proxy = listProxy.proxies[randomNumber];

    var startProxy = new Date();
    axios({
      method: 'GET',
      // url: 'http://localhost:5000/proxy/youtube/UCcng5aBy0ZmX7g2PfHf4gJA'
      // url: 'http://localhost:5000/proxy/youtube/UCW3O1nLY6m14D82swOQU7YQ'
      // url: `http://localhost:5000/proxy/youtube/${channelId}/${proxy.ip}/${proxy.port}`
      url: `/proxy/youtube/${channelId}/${proxy.ip}/${proxy.port}`
    }).then((r) => {
      var diffStartEndProxy = moment().diff(moment(startProxy));
      var proxyDiff = {
        ip: proxy.ip,
        seconds: diffStartEndProxy
      }

      if (r.data.status === 'inv_tmp') {
        this.setState({ inv: this.state.inv + 1, proxiesValues: [...this.state.proxiesValues, proxyDiff] });
      }
      if (r.data.status === 'declined') {
        if (r.data.statusReason === 'Average views less than 1800') {
          this.setState({ less_average: this.state.less_average + 1, proxiesValues: [...this.state.proxiesValues, proxyDiff] });
        }
        if (r.data.statusReason === 'No recent publish') {
          this.setState({ no_recent: this.state.no_recent + 1, proxiesValues: [...this.state.proxiesValues, proxyDiff] });
        }
        if (r.data.statusReason === 'Not enough videos') {
          this.setState({ no_enough: this.state.no_enough + 1, proxiesValues: [...this.state.proxiesValues, proxyDiff] });
        }
      }
      axios({
        method: 'put',
        url: `/api/v1/youtube/${channelId}`,
        data: r.data
      }).then((response) => {
        const rdnChannelId = this.randomChannelId();
        var newChannels = this.state.channels;
        newChannels.splice(rdnChannel,1);

        this.setState({ channels: newChannels });
        if (newChannels.length !== 0) {
          let that = this;
          setTimeout(function(){
            that.checkChannels(i+1, rdnChannelId, 0);
          }, 250);
        }
      });
    }).catch(error => {
      this.setState({ proxiesError: [...this.state.proxiesError, {'proxy': `${proxy.ip}/${proxy.port}`}]});
      let that = this;
      setTimeout(function(){
        that.checkChannels(i, rdnChannel, errorCount+1);
      }, 250);
    });
  }

  componentDidMount() {
    // this.updateNewChannels();
    let i = 0;
    const rdnChannel = this.randomChannelId();
    this.checkChannels(i, rdnChannel, 0);
  }

  randomChannelId() {
    let randomNumber = Math.floor(Math.random() * this.state.channels.length - 1);
    // return this.state.channels[randomNumber].channelId;
    if (randomNumber === -1) {
      return 0;
    }
    return randomNumber;
  }


  render() {
    const { channels, inv, no_enough, no_recent, less_average, date, proxiesValues, proxiesError } = this.state;
    const totalDone = inv + no_enough + no_recent + less_average;
    const timePeriod = moment().diff(moment(date), 'minutes');
    const timeLeft = (channels.length / (totalDone/timePeriod)).toFixed();
    // if (totalDone % 10 === 0) {
    //   console.log('PROXY VALUES');
    //   console.log(this.state.proxiesValues);
    // }
    let groupProxies = _.groupBy(proxiesValues, (p) => { return p.ip });
    let arrProxyTimeAverage = [];
    Object.keys(groupProxies).forEach((key,index) => {
      // key: the name of the object key
      // index: the ordinal position of the key within the object
      let sumTimeProxies = _.sumBy(groupProxies[key], (p) => { return p.seconds});
      let averageTimeProxy = sumTimeProxies / groupProxies[key].length;
      arrProxyTimeAverage.push(`${key} - ${averageTimeProxy / 1000}s`);
    });

    let groupErrorProxy = _.groupBy(proxiesError, 'proxy');
    let errorArr = [];
    Object.keys(groupErrorProxy).forEach(function (key) {
      errorArr.push({ name: key, count: groupErrorProxy[key].length });
    });
    errorArr = _.orderBy(errorArr, ['count'], ['desc']);
    /*
    groupErrorProxy.map((proxy, i) => {
      return {proxy: i, number_error: proxy.length}
    });
    */



    // if (totalDone > 60) {
    //   this.props.reloadData();
    // }
    return (
      <div className="scrapping-container">
        <p className="scrapping-container__title">Scrapping {this.props.id}</p>
        <div className="scrapping-container__content">
          <p>Todo: {channels.length}</p>
          <p>Added: {inv}</p>
          <p>Not enough videos: {no_enough}</p>
          <p>No recent publish: {no_recent}</p>
          <p>Average views less than 1800: {less_average}</p>
          <p>-------------------------</p>
          <p>Total done: {totalDone} in {timePeriod} minutes {(timePeriod > 0) ? `(${(totalDone/timePeriod).toFixed(2)}/min)` : null}</p>
          <p>{(timePeriod > 0) ? `Over in ${(timeLeft > 60) ? `${timeLeft/60} hour(s)`: `${timeLeft} minutes`}` : null}</p>
          <p>------------------------</p>
          {arrProxyTimeAverage.map((p) => {
            return (<p className="proxy">{p}</p>);
          })}
        </div>
        <div className="scrapping-container__content">
          <p>------------------------</p>
          {errorArr.map((el) => {
            return <p>{el.name} - {el.count} error</p>
          })}
        </div>
      </div>
    );
  }
}
export default Scrapping;
