import React, { useState, useEffect } from "react";
import { useTable, useSortBy, usePagination } from 'react-table'
import _ from "lodash";
import moment from "moment";

import "./ReportingTable.css";

function ReportingTable({ ...props }) {
  const [columns, setColumnsTable] = useState([]);

  function sortByDate(rowA, rowB, id) {
    var a = new Date(rowA[id]);
    var b = new Date(rowB[id]);
    return (a < b) ? 1 : (a > b) ? -1 : 0;
  }

  let DATE_KEYS = ["day", "date"];

  useEffect(() => {
    const { data } = props;
    if (data && data.length > 0) {
      let columns = Object.keys(data[0]).reduce((red, key) => {
        if (key !== "_id") {
          let column = {
            Header: key,
            accessor: key,
          }
          if (DATE_KEYS.includes(key)) {
            column['sortType'] = sortByDate
          }
          red.push({
            Header: key,
            accessor: key,
          });
        }
        return red;
      }, []);
      setColumnsTable(columns);
    }
  }, [props.data]);

  const tableData = React.useMemo(() => props.data, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    page,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageCount,
    pageIndex,
    pageOptions,
    setPageSize,
    pageSize,
  } = useTable({
    columns: columns,
    data: tableData,
    initialState: { pageIndex: 1 },
  }, useSortBy, usePagination);

  // const firstPageRows = rows.slice(0, 20)
  // console.log(firstPageRows, ' => firstPageRows');
  return (
    <>
    {(columns) && (
      <>
        <table className="report_table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="report_table_tr" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th className="report_table_th" {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr className="report_table_tr" {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      // parse dates
                      if (DATE_KEYS.includes(cell?.column?.Header)) {
                        return (
                          <td className="report_table_td" {...cell.getCellProps()}>{moment(cell.value).format("MM/DD (hh:mm:ss a)")}</td>
                        )
                      }
                      return (
                        <td className="report_table_td" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )}
            )}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    )}
    </>


  )
}

export default ReportingTable;