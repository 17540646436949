import React from "react";
import axios from "axios";
import { getWeek, add, format, formatISO } from "date-fns";
import DatePicker from "react-datepicker";
import NotificationManager from "components/al_components/notification/NotificationManager";

import "react-datepicker/dist/react-datepicker.css";
import "./FollowupPicker.css";

function getEstimatedDateText(today, date) {
  if (getWeek(today, { weekStartsOn: 1 }) === getWeek(date, { weekStartsOn: 1 })) {
    return format(date, "EEEE");
  }
  return format(date, "MM/dd");
}

/**
 * Component to pick a follow-up date for a deal
 * @param {boolean} open - Whether the picker is open
 * @param {string} dealId - The ID of the deal to update
 * @param {function} onFollowupSelected - Callback function to call when a follow-up date is selected. Can be used to close the picker and update displayed data.
 */
function FollowupPicker({ open, dealId, onFollowupSelected }) {
  const today = new Date();
  const options = [
    { label: "in 1 day", date: add(today, { days: 1 }) },
    { label: "in 2 days", date: add(today, { days: 2 }) },
    { label: "in 3 days", date: add(today, { days: 3 }) },
    { label: "in 5 days", date: add(today, { days: 5 }) },
    { label: "in 1 week", date: add(today, { weeks: 1 }) },
    { label: "in 2 weeks", date: add(today, { weeks: 2 }) },
  ];

  const onCustomDateSelected = (selectedFollowupDate) => {
    axios
      .put(`/api/v1/deals/${dealId}`, { date_follow_up: formatISO(selectedFollowupDate), date_last_outreach: formatISO(today) })
      .then((res) => {
        NotificationManager.success("Success", "Follow-up date updated successfully", 3000);
        typeof onFollowupSelected === "function" && onFollowupSelected({ selectedFollowupDate, updatedDeal: res.data.result });
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error("Error", "Failed to update follow-up date", 3000);
      });
  };

  if (!open || !dealId) return null;

  return (
    <div className="followup-picker">
      <p className="followup-picker__title">Set new follow-up date:</p>
      {options.map((option) => (
        <button key={option.label} className="followup-picker__button" onClick={() => onCustomDateSelected(option.date)}>
          {option.label} ({getEstimatedDateText(today, option.date)})
        </button>
      ))}
      <DatePicker
        onChange={onCustomDateSelected}
        customInput={<button className="followup-picker__button">custom</button>}
        minDate={add(today, { days: 1 })}
        selected={add(today, { days: 1 })}
      />
    </div>
  );
}

export default FollowupPicker;
