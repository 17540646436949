import React from "react";
import axios from 'axios';
import moment from 'moment';
import "../App.css";
import "./ChannelInvestigate.css";
import Loading from "./Loading";

class ChannelInvestigateSwipe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channelBase: this.props.channelBase,
      channel: null,
      loading: true,
      updated: false,
      isUpdatedDb: false,
      confidenceYESNO: null,
      hoverVideo: null,
      hoverChannel: null,
    };
    if (props.type === "validation") {
      this.state.confidenceYESNO = this.confidenceValue(this.props.channelBase);
    }
    console.log("this.state.confidenceYESNO", this.state.confidenceYESNO);
    this.updateChannelDeclined = this.updateChannelDeclined.bind(this);
    this.confidenceValue = this.confidenceValue.bind(this);
    this.fmtMSS = this.fmtMSS.bind(this);
  }
  // componentDidMount() {
  //   console.log("did mount");
  //   console.log(this.props);
  //   console.log(this.props.channelBase);
  // }

  confidenceValue(channelBase) {
    // const { channelBase } = this.state;

    if (typeof(channelBase.dataUser.subscriber) == "undefined" || channelBase.dataVideos.length < 5) {
      return null;
    } else {
      const averageArr = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;
      const avg_views_coef = -0.000004807765424;
      const views_1_coef = 0.000003786964169;
      const views_2_coef = 0.0000008006909591;
      const views_3_coef = 0.0000003142695166;
      const views_4_coef = -0.0000003923279816;
      const views_5_coef = 0.0000008567105352;
      const likes_1_coef = -0.000051124197;
      const likes_2_coef = 0.00002432485948;
      const likes_3_coef = 0.00004501945521;
      const likes_4_coef = 0.0001430539383;
      const likes_5_coef = 0.0000148711938;
      const gender_conf_coef = 0.07309656529;
      const avg_likes_coef = -0.0000002038136885;
      const frequency_2_coef = -0.001613199683;
      const frequency_1_coef = -0.0008114842917;
      const frequency_3_coef = -0.0005608557028;
      const frequency_4_coef = 0.001200379828;
      const subscribers_coef = 0.0000003801397656;
      const intercept_coef = -5.90381095;

      let avg_views = [];
      let avg_likes = [];
      channelBase.dataVideos.map((vid, i) => {
        avg_views.push(vid.like);
        avg_likes.push(parseInt(vid.views));
      });
      avg_views = averageArr(avg_views);
      avg_likes = averageArr(avg_likes);
      let views_1 = parseInt(channelBase.dataVideos[0].views);
      let views_2 = parseInt(channelBase.dataVideos[1].views);
      let views_3 = parseInt(channelBase.dataVideos[2].views);
      let views_4 = parseInt(channelBase.dataVideos[3].views);
      let views_5 = parseInt(channelBase.dataVideos[4].views);

      let likes_1 = channelBase.dataVideos[0].like;
      let likes_2 = channelBase.dataVideos[1].like;
      let likes_3 = channelBase.dataVideos[2].like;
      let likes_4 = channelBase.dataVideos[3].like;
      let likes_5 = channelBase.dataVideos[4].like;
      let diff_days_1 = moment(channelBase.dataVideos[0].published).diff(moment(channelBase.dataVideos[1].published), 'days');
      let diff_days_2 = moment(channelBase.dataVideos[1].published).diff(moment(channelBase.dataVideos[2].published), 'days');
      let diff_days_3 = moment(channelBase.dataVideos[2].published).diff(moment(channelBase.dataVideos[3].published), 'days');
      let diff_days_4 = moment(channelBase.dataVideos[3].published).diff(moment(channelBase.dataVideos[4].published), 'days');

      let subscriber = 0;
      if (typeof(channelBase.dataUser.subscriber) != "undefined") {
        if (channelBase.dataUser.subscriber.includes("K")) {
          subscriber = parseFloat(channelBase.dataUser.subscriber.replace("K", "")) * 1000;
        } else if (channelBase.dataUser.subscriber.includes("M")) {
          subscriber = parseFloat(channelBase.dataUser.subscriber.replace("M", "")) * 1000000;
        } else {
          subscriber = parseFloat(channelBase.dataUser.subscriber);
        }
      }
      let gender_confidence = null;
      if (typeof(channelBase.genderConfidence) == "undefined") {
        gender_confidence = 50;
      } else {
        gender_confidence = channelBase.genderConfidence;
      }

      const avg_views_w_coef = avg_views_coef * avg_views;
      const views_1_w_coef = views_1 * views_1_coef;
      const views_2_w_coef = views_2 * views_2_coef;
      const views_3_w_coef = views_3 * views_3_coef;
      const views_4_w_coef = views_4 * views_4_coef;
      const views_5_w_coef = views_5 * views_5_coef;
      const likes_1_w_coef = likes_1 * likes_1_coef;
      const likes_2_w_coef = likes_2 * likes_2_coef;
      const likes_3_w_coef = likes_3 * likes_3_coef;
      const likes_4_w_coef = likes_4 * likes_4_coef;
      const likes_5_w_coef = likes_5 * likes_5_coef;
      const gender_w_coef = gender_confidence * gender_conf_coef; // TODO
      const avg_likes_w_coef = avg_likes * avg_likes_coef;
      const diff_days_1_w_coef = diff_days_1 * frequency_1_coef;
      const diff_days_2_w_coef = diff_days_2 * frequency_2_coef;
      const diff_days_3_w_coef = diff_days_3 * frequency_3_coef;
      const diff_days_4_w_coef = diff_days_4 * frequency_4_coef;
      const subscriber_w_coef = subscriber * subscribers_coef;

      const scalar_product = (
        avg_views_w_coef +
        views_1_w_coef +
        views_2_w_coef +
        views_3_w_coef +
        views_4_w_coef +
        views_5_w_coef +
        likes_1_w_coef +
        likes_2_w_coef +
        likes_3_w_coef +
        likes_4_w_coef +
        likes_5_w_coef +
        gender_w_coef +
        avg_likes_w_coef +
        diff_days_1_w_coef +
        diff_days_2_w_coef +
        diff_days_3_w_coef +
        diff_days_4_w_coef +
        subscriber_w_coef) + intercept_coef;

      const confidence = Math.exp(scalar_product) / (1 + Math.exp(scalar_product));
      console.log(channelBase.dataUser.nameChannel);
      console.log(confidence * 100, "confidence");
      return confidence;
    }

  }
  updateChannelDeclined() {
    const { confidenceYESNO, channelBase } = this.state;
    this.props.onDeclined(channelBase.channelId, channelBase, confidenceYESNO);
    this.setState({ isUpdatedDb: true });
  }
  updateChannelValidate() {
    const { confidenceYESNO, channelBase } = this.state;
    this.props.onValidate(channelBase.channelId, channelBase, confidenceYESNO);
    this.setState({ isUpdatedDb: true });
  }
  updateChannelCocreation() {
    const { channelBase, confidenceYESNO } = this.state;
    this.props.onCocreation(channelBase.channelId, channelBase, confidenceYESNO);
    this.setState({ isUpdatedDb: true });
  }
  updateChannelSponsorship() {
    const { channelBase, confidenceYESNO } = this.state;
    this.props.onSponsorship(channelBase.channelId, channelBase, confidenceYESNO);
    this.setState({ isUpdatedDb: true });
  }

  /*
  async addRowSheet(type, channelId) {
    await this.state.gDoc.addRow({
      date: moment().format("MM/DD/YYYY"),
      channelId: channelId,
      confidence:,
      response: type
    });
  }
  */
  toggleViewDetails(ev, type) {
    console.log("HERE");
    console.log(ev);
    console.log(type);
    if (type == "open") {
      axios({
        method: 'get',
        url: `/api/v1/youtube/video/${ev}`
      }).then((r) => {
        console.log(r);
        this.setState({ hoverVideo: r.data.video });
      });
    }
    if (type == "close") {
      this.setState({ hoverVideo: null });
    }
  }

  toggleViewDetailsChannel(channelId) {
    if (!channelId) {
      console.log("CLOSE");
      this.setState({ hoverChannel: null });
    } else {
      console.log("OPEN");
      axios({
        method: 'get',
        url: `/api/v1/youtube/channel/${channelId}`
      }).then((r) => {
        console.log(r);
        this.setState({ hoverChannel: r.data.channel });
      });
    }
  }

  fmtMSS(s) {
    return(s-(s%=60))/60+(9<s?':':':0')+s
  }

  openEmbededVideo(videoId) {
    axios({
      method: 'get',
      url: `/api/v1/youtube/video/${videoId}`
    }).then((r) => {
      console.log(r);
      this.props.onToggleForm(r.data.video);
    });
  }
  renderVideosHtml(dataVideos, channelId) {
    if (dataVideos.length > 3) {
      return dataVideos.map((video, i) => {
        const video_id = video.link.split("watch?v=")[1]
        if (i < 4) {
          return (
            <div className="channel__image" key={`video_${i}`}>
              <div>
                <div className="channel__image__container--image" onClick={() => this.openEmbededVideo(video_id)}>
                  <img className="channel__image--image" referrerPolicy="no-referrer" alt="thumbnail" src={`https://i.ytimg.com/vi/${video_id}/hqdefault.jpg`} />
                  <div className="channel__image--image--hover">
                    <span className="material-icons">play_arrow</span>
                  </div>
                </div>
                <a href={video.link} target="_blank" rel="noopener noreferrer" className="channel__image--title">{video.title}</a>
              </div>
              <div>
                <p className="channel__image--views">
                  <i className="material-icons">visibility</i>{video.views}
                </p>
                <div className="channel__image__thumbs">
                  <p className="channel__image--like">
                    <i className="material-icons">thumb_up</i>{(video.like) ? parseInt(video.like) : 0}
                  </p>
                  <p className="channel__image--dislike">
                    <i className="material-icons">thumb_down</i>{(video.dislikes) ? parseInt(video.dislike) : 0}
                  </p>
                </div>
                {/* {<p className="channel__image--date">{moment().subtract(parseInt(video.published), 'days').fromNow()}</p>} */}
                <p className="channel__image--date">{moment(video.published).from(moment())}</p>
                <p className="channel__view--more" onClick={() => this.toggleViewDetails(video_id, "open")}>View details</p>
              </div>
            </div>
          )
        }
      })
    } else {
      if (this.state.channelBase["dataVideosRes"] && this.state.channelBase["dataVideosRes"] == "reload") {
        return (
          <button className="channel_database--reload" onClick={() => {
            let theChannel = this.state.channelBase;
            theChannel["dataVideosRes"] = "";
            this.setState({ channelBase: theChannel });
          }}>LOAD VIDEOS<span className="material-icons">replay</span></button>
        );
      } else {
        // query Youtube custom API
        axios.get("/api/v1/youtube/channel/videos/" + channelId, { timeout: 5000 }).then((r) => {
          let arrVideos = [];
          let theChannel = this.state.channelBase;
          r.data.videos.map((video) => {
            arrVideos.push({
              "title": video.title,
              "thumbnail": video.pictureURL,
              "link": "https://www.youtube.com/watch?v=" + video.videoId,
              "views": (video.stats.views) ? video.stats.views : '',
              "like": (video.stats.likes) ? video.stats.likes : '',
              "dislike": (video.stats.dislikes) ? video.stats.dislikes : '',
              "published": video.datePublished
            });
          });
          theChannel["dataVideos"] = arrVideos;
          this.setState({ channelBase: theChannel });
        }).catch((err) => {
          console.log(err.code);
          let theChannel = this.state.channelBase;
          theChannel["dataVideosRes"] = "reload";
          this.setState({ channelBase: theChannel });
        });
        return (
          <Loading />
        );
      }
    }
  }

  render() {
    const { loading, channelBase, isUpdatedDb, confidenceYESNO, hoverVideo, hoverChannel } = this.state;
    const averageLastFiveVideos = channelBase.dataVideos.reduce(function (avg, value, _, { length }) {
      return avg + parseInt(value.views) / length;
    }, 0).toFixed();

    let totalViews = null;
    if (channelBase.dataUser.views) {
      totalViews = channelBase.dataUser.views.split(" ")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    let subChannel = null;
    if (channelBase.dataUser.subscriber) {
      subChannel = channelBase.dataUser.subscriber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    let theChannelId = channelBase.channelId;
    if (hoverChannel != null) {
      theChannelId = null;
    }


    return (
      <div className="channel-investigate-page-wrapper">
        {(loading && !channelBase) ?
          <Loading /> :
          <div className="channel_swipe_container">
            {(this.props.user.googleId === "103171517035742139365" && this.props.type === "validation") ? (
              <div className="swipe_container__confidenceyesno"><p>{(confidenceYESNO * 100).toFixed(3)}%</p></div>
            ) : (null)}
            {/* <button className="channel_database--viewChannel">
              <a href={`https://www.youtube.com/channel/${channelBase.channelId}`} target="_blank" rel="noopener noreferrer">VIEW CHANNEL</a>
            </button> */}
            <div className="channel_swipe_container__data">
              {(hoverVideo) ? (
                <div className="channel_swipe_container__data__videoInformation">
                  <div className="data__videoInformation--close" onClick={() => this.toggleViewDetails(null, "close")}><i className="material-icons">close</i></div>
                  <div className="channel__image">
                    <div>
                      <div className="channel__image__container--image">
                        <p className="channel__image--duration">{this.fmtMSS(hoverVideo.duration)}</p>
                        <img className="channel__image--image" alt="thumbnail" crossOrigin="anonymous" referrerPolicy="no-referrer" src={`https://i.ytimg.com/vi/${hoverVideo.videoId}/hqdefault.jpg`} />
                      </div>
                      <p className="channel__image--title">{hoverVideo.title}</p>
                    </div>
                    <div>
                      <p className="channel__image--views">
                        <i className="material-icons">visibility</i>{hoverVideo.stats[0].views}
                      </p>
                      <div className="channel__image__thumbs">
                        <p className="channel__image--like">
                          <i className="material-icons">thumb_up</i>{parseInt(hoverVideo.stats[0].likes)}
                        </p>
                        <p className="channel__image--dislike">
                          <i className="material-icons">thumb_down</i>{parseInt(hoverVideo.stats[0].dislikes)}
                        </p>
                      </div>
                      <p className="channel__image--date">{moment(hoverVideo.datePublished).from(moment())}</p>
                    </div>
                  </div>
                  <div className="data__videoInformation__infos">
                    <p className="data__videoInformation__title">Video Description:</p>
                    <div className="data__videoInformation__content">
                      {(hoverVideo.description) ? (
                        hoverVideo.description
                      ) : (
                        <span className="data__videoInformation__content--empty">No description available</span>
                      )}
                    </div>
                  </div>
                </div>
              ) : (null)}
              <div className="channel_swipe_container__data__information">
                {(channelBase.dataUser) ? (
                <div className="channel_container__channel">
                  <a href={`https://www.youtube.com/channel/${channelBase.channelId}`} target="_blank" rel="noopener noreferrer" >
                    <img alt="profile" crossOrigin="anonymous" referrerPolicy="no-referrer"  src={channelBase.dataUser.thumbnail} />
                  </a>
                  <div className="channel_container__channel__information">
                    {(channelBase.dataUser.nameChannel) ? (
                      <a href={`https://www.youtube.com/channel/${channelBase.channelId}`} target="_blank" rel="noopener noreferrer" className="channel_container__channel__content--title channel__content--titleChannel">
                        {channelBase.dataUser.nameChannel}
                      </a>
                    ) : null}
                    {(totalViews) ? (
                      <p className="channel_container__channel__content--title channel__content--views">
                        <span>{totalViews} views</span>
                      </p>
                    ) : null}
                    {(subChannel && subChannel != "None") ? (
                      <p className="channel_container__channel__content--subscriber channel__content--subscriber">
                        <span>{subChannel} subscribers</span>
                      </p>
                    ) : null}
                  </div>
                </div>) : ''}

                <div className="channel_swipe_container__details">
                  <div className="channel_container__channel__content">
                    {(channelBase.instagram) ? (
                      <a href={`https://www.instagram.com/${channelBase.instagram}`} className="channel_container__channel__content--instagram channel__content--title">
                        <span className="channel__content--instagram__logo">&nbsp;</span>
                        {channelBase.instagram}
                      </a>
                    ) : null}
                    {(channelBase.country) ? (
                      <p className="channel_container__channel__content--country channel__content--title">
                        <span><i className="material-icons">outlined_flag</i></span>
                        {channelBase.country}
                      </p>
                    ) : null}

                    {(channelBase.gender) ? (
                      <p className="channel__content--gender channel__content--title">
                        <span><i className="material-icons">face</i></span>
                        {channelBase.gender}
                      </p>
                    ) : null}

                    {(channelBase.keyword) ? (
                      <p className="channel__content--keyword channel__content--title">
                        <span><i className="material-icons">label</i></span>
                        {channelBase.keyword}
                      </p>
                    ) : null}

                    {(averageLastFiveVideos) ? (
                      <p className="channel_container__channel__content--average channel__content--title">
                        <span><i className="material-icons">show_chart</i></span>
                        {averageLastFiveVideos} avg/views
                      </p>
                    ) : null}

                    {(channelBase.engagement) ? (
                      <p className="channel_container__channel__content--engagement channel__content--title">
                        <span><i className="material-icons">phonelink</i></span>
                        {channelBase.engagement}
                      </p>
                    ) : null}
                    <div className="channel_container__channel__content--more channel__content--title" onClick={() => this.toggleViewDetailsChannel(theChannelId)}>
                      {(hoverChannel) ? (
                        <div>Close details<i className="material-icons">close</i></div>
                      ) : (
                        <div>View details<i className="material-icons">more_vert</i></div>
                      )}
                    </div>
                  </div>
                </div>
                {(hoverChannel) ? (
                  <div className="data__information__more">
                    <p className="data__information__more_title">Description</p>
                    <div className="data__information__more_description">{(hoverChannel.description) ? (
                      hoverChannel.description
                    ): (
                      <span className="data__information__more_description--more">No description available</span>
                    )}</div>
                  </div>
                ) : (null)}
              </div>
              <div className="channel_container__channels">
                {this.renderVideosHtml(channelBase.dataVideos, channelBase.channelId)}
              </div>
            </div>



            {(!isUpdatedDb) ? (
            <div className="channel_database">
              <button className="channel_database--no" onClick={() => this.updateChannelDeclined()}>
                NO <i className="material-icons">clear</i>
              </button>
              <button className="channel_database--yes" onClick={() => this.updateChannelValidate()}>
                YES <i className="material-icons">favorite</i>
              </button>
              {this.props.type === "validation" ? <button className="channel_database--cocreation" onClick={() => this.updateChannelCocreation()}>
                CO-CREATION
              </button> : null}
              {this.props.type === "cocreation" ? <button className="channel_database--cocreation" onClick={() => this.updateChannelSponsorship()}>
                SPONSORSHIP
              </button> : null}
            </div>
            ) : (
              <Loading />
            )}
          </div>
        }
      </div>
    );
  }
}

export default ChannelInvestigateSwipe;
