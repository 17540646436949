import React from "react";
import "./button.css";

class ALButton extends React.Component {
  constructor(props) {
    super(props);
    this.defaultStyle = {
      contained: "al_button contained",
      outlined: "al_button outlined",
      text: "al_button text",
    };
  }
  withIcon() {
    return (
      <div className="al_button__info_container">
        <i className={`material-icons al_button__info_container__icon${this.props.children ? "" : "--no-child"}`}>{this.props.material_icon}</i>
        {this.props.children ? <div className="al_button__info_container__children">{this.props.children}</div> : null}
      </div>
    );
  }
  render() {
    return (
      <button
        className={(this.props.className || this.defaultStyle[this.props.type || "contained"]) + (this.props.disable ? " al_button--disable" : "")}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        style={(!this.props.disable && this.props.style) || null}>
        {this.props.material_icon ? this.withIcon() : this.props.children}
      </button>
    );
  }
}

export default ALButton;
