import React from "react";
import HeaderComponent from "../HeaderComponent";
import { ALSearch, ALButton } from "../ALComponents";
import axios from 'axios';
import _ from "lodash";
import moment from 'moment';
import "./ShippingSearch.css";

class ShippingSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputSearch: null,
      orderedDates: [],
      orders: [],
    };
    this.onClickSearch = this.onClickSearch.bind(this);
  }

  _handleKeyDown = (event) => {
    console.log(event);
    if (event.keyCode == 13) {
      this.onClickSearch();
    }
  }
  componentDidMount() {
    console.log("comp did mount");
    document.addEventListener("keydown", this._handleKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  onClickSearch() {
    const { inputSearch } = this.state;
    if (inputSearch) {
      axios.get("/api/v1/influencer_orders?email=" + inputSearch).then((r) => {
        let orders = r.data.orders;
        orders = _.orderBy(orders, ["dateOrder"], ["desc"]);
        this.setState({ orders });
      });
    }
  }

  render() {
    const { orders } = this.state;
    return (
      <div className="shipping_search">
        <HeaderComponent title="Search Order" to="/a/dashboard/shipping" toTitle="Shipping" />
        <div className="shipping_search_container">
          <div className="shipping_search_container_header">
            <ALSearch placeholder="Email" searchInput={(input) => { this.setState({ inputSearch: input })}} />
            <ALButton type="contained" onClick={this.onClickSearch}>Search</ALButton>
          </div>
          {(orders) ? (
            <div className="shipping_search_container_content">
              {orders.map((order, i) => {
                let totalPrice = 0;
                return (
                  <div className="content__order" key={i}>
                    <p className="content__order_id">Order #: {order["orderId"]}</p>
                    <p className="content__order_date">Date: {moment(order["dateOrder"]).format("MMMM Do, YYYY")} ({moment(order["dateOrder"]).fromNow()})</p>
                    <div className="content__order__products">
                      <p className="content__order__products__header">
                        <span className="content__order__products__header_name">Product Name</span>
                        <span className="content__order__products__header_price">Price</span>
                      </p>
                      {order["listProduct"].map((product, j) => {
                        totalPrice += product["price"];
                        return (
                          <div className="content__order__products_product" key={j}>
                            <p className="content__order__products_product_name">{j+1}) {product["productName"]}</p>
                            <p className="content__order__products_product_price">${product["price"]}</p>
                          </div>
                        )
                      })}
                      <p className="content__order__products_total">Total: ${totalPrice}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (null)}
        </div>
      </div>
    );
  }
}

export default ShippingSearch;