import React from "react";
import axios from "axios";
import { ALDragAndDrop } from "./ALComponents";
import NotificationManager from "./al_components/notification/NotificationManager";
import Loading from "./Loading";
import _ from "lodash";
import "./BrandAmbassador.css";

// import moment from 'moment';
const Klaviyo = require('klaviyo-node');
const client = new Klaviyo("JasiTs");

class BrandAmbassador extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAmbassador: null,
      nbAmbassadorDone: 0,
    };
    this.handleBrandAmbassador = this.handleBrandAmbassador.bind(this);
    this.createDiscount = this.createDiscount.bind(this);
  }
  componentDidMount() {
    console.log('componentDidMount BrandAmbassador');
    console.log(client);
  }
  componentDidUpdate() {
    console.log('componentDidUpdate BrandAmbassador');
  }

  sleeper(ms) {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
  }

  createDiscount(discountCode, i) {
    console.log(discountCode, "discountCode");
    if (!discountCode || !i) {
      NotificationManager.error('Error', 'No discount code in the function createDiscount()', 6000);
    }

    let _this = this;
    let copydataAmbassador = this.state.dataAmbassador;
    // a: create
    this.sleeper(i * 1200)
      .then(() => {
        console.log("then....");
        console.log(discountCode);
        axios.post('/api/v1/discount', {
          "type": "Percentage",
          "code": discountCode,
          "value": 15
        })
          .then(function(response) {
            console.log(response);
            if (response.status == 200) {
              copydataAmbassador[i]["discountCreated"] = true;
              copydataAmbassador[i]["discountCode"] = discountCode;
              let nbAmbassadorDone = _.sumBy(copydataAmbassador, function(o) { return o.discountCreated; });
              _this.setState({
                dataAmbassador: copydataAmbassador,
                nbAmbassadorDone: nbAmbassadorDone
              });
              // b. Identify on Klaviyo (create discount_audience)
              client.identify(copydataAmbassador[i]["email"], {
                'discount_audience': discountCode,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            NotificationManager.error('Error', 'Creating discount: ' + error, 6000);
          });
      })
  }

  handleBrandAmbassador(data) {
    console.log(data);
    if (data) {
      this.setState({ dataAmbassador: data });
      // 1: clean (remove empty)
      data.map((profilAmbassador, i) => {
        console.log(profilAmbassador);
        if (profilAmbassador["instagram"].indexOf("instagram.com") != -1 || profilAmbassador["instagram"].indexOf("tiktok") != -1) {
          console.log("BAD INSTA");
        } else {
          if (profilAmbassador["instagram"].length > 0) {
            // validate instagram
            let discountAmbassador = profilAmbassador["instagram"].replace(new RegExp(/\./g, 'g'), '').replace(new RegExp(/\_/g, 'g'), '').replace(/\s/g, '').replace('@', '').toUpperCase() + "15";
            if (discountAmbassador.indexOf("instagram") != -1 || discountAmbassador.indexOf(" ") != -1) {
              console.log("BAD INSTAGRAM VALUE => ");
              console.log(discountAmbassador);
              // 3: create discount code shopify
            } else {
              this.createDiscount(discountAmbassador, i);
            }
          }
        }
      });
    }

  }

  render() {
    const { dataAmbassador, nbAmbassadorDone } = this.state;
    return (
      <div>
        <p>Brand Ambassador</p>
        <ALDragAndDrop type="text/csv" handleFile={(data) => {this.handleBrandAmbassador(data)}} />
        {(dataAmbassador) ? (
          (dataAmbassador.length > 0) ? (
            <div className="ambassador_tab">
              <p className="ambassador_tab_count">{nbAmbassadorDone}/{dataAmbassador.length}</p>
              <div className="ambassador_tab_header">
                <p>e-mail</p>
                <p>followers count</p>
                <p>instagram handle</p>
                <p>link insta</p>
                <p>discount code created</p>
                <p>updated</p>
              </div>
              {dataAmbassador.map((amb, i) => {
                return (
                  <div className="ambassador_tab_values" key={`amb_${i}`}>
                    <p className="ambassador_tab_values_value">{(amb.email != "") ? (amb.email) : ('NOT VALID')}</p>
                    <p className="ambassador_tab_values_value">{(amb.follower_count != "") ? (amb.follower_count) : ('NOT VALID')}</p>
                    <p className="ambassador_tab_values_value">{(amb.instagram != "") ? (amb.instagram) : ('NOT VALID')}</p>
                    {(amb.instagram != "") ? (
                      <a className="ambassador_tab_values_value" href={`https://www.instagram.com/${amb.instagram.replace("@", "")}`} target="_blank" rel="noopener noreferrer">
                        {amb.instagram.replace("@", "")}
                      </a>
                    ) : (
                      <p className="ambassador_tab_values_value">NOT VALID</p>
                    )}
                    <p className="ambassador_tab_values_value">{(amb.discountCode != "") ? (amb.discountCode) : ('NOT VALID')}</p>
                    <p className="ambassador_tab_values_value ambassador_tab_values_value_icon">{(amb.discountCreated) ? (
                      <span className="ambassador_tab_values_value--done material-icons">done</span>
                    ) : (
                      <Loading />
                    )}</p>
                  </div>
                )
              })}
            </div>
          ) : (
            <p>No Ambassador in this CSV please check the format</p>
          )
        ) : (null)}

      </div>
    );
  }
}

export default BrandAmbassador;
