import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import 'chart.js/auto';
import {Line} from 'react-chartjs-2';

// import moment from "moment";

function random_rgba() {
  var o = Math.round, r = Math.random, s = 255;
  return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
}

function ScrappingGraph(props) {
  const [graphOptions, setGraphOptions] = useState({
    title: {
      display: true,
      text:'Channels Scrapped',
      fontSize: 20
    },
    legend: {
      display:true,
      position:'top'
    },
    scales: {
      yAxes: [{
        id: 'customLeft',
        position: 'left',
      }]
    }
  });

  const [data, updateData] = useState(null);

  const { dataFromRange, dateRange} = props;

  function getDaysArray(start, end) {
    for (var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  useEffect(() => {
    const { dataFromRange, dateRange} = props;

    // group data by day
    let dataGroupedByDate = _.groupBy(dataFromRange, v => moment(v.day).format("YYYY-MM-DD"));
    let dataGroupedByType = _.groupBy(dataFromRange, 'type');

    // get all dates in-between a range
    let emptyDates = getDaysArray(moment(dateRange?.startDate)?.format("YYYY-MM-DD"), moment(dateRange?.endDate)?.format("YYYY-MM-DD")).map(d => moment(d).format("YYYY-MM-DD"));

    if (emptyDates.length !== 1) {
      // build object for graph (per day)
      // TODO
      // [] - build view day / month / week capabilities
      let dataGraph = null;
      if (Object.keys(dataGroupedByDate).length < emptyDates.length) {
        // fill /w empty data
        dataGraph = emptyDates.reduce((red, d) => {
          if (dataGroupedByDate[d]) {
            red[d] = dataGroupedByDate[d];
          } else {
            red[d] = [];
          }
          return red;
        }, {});
      }

      let _dataGraph = null;
      if (dataGraph) {
        let hue = 300;
        let _dataSets = Object.keys(dataGroupedByType).map((type) => {
          let dataPerType = Object.keys(dataGraph).map((day) => {
            return dataGraph[day].reduce((red, d) => {
              if (d?.type === type) {
                red += d?.nsamples;
              }
              return red;
            }, 0)
          });

          hue = (50 + hue) % 360;
          return {
              label: type,
              data: dataPerType,
              borderColor: `hsl(${hue}, 90%, 45%)`,
              backgroundColor: `hsla(${hue}, 85%, 40%, 0.1)`,
          };
        });
        _dataGraph = {
          labels: Object.keys(dataGraph),
          datasets: _dataSets
        }
      }
      // console.log(_dataGraph, ' =>>> _dataGraph');

      updateData(_dataGraph);
    }

  }, [dateRange, dataFromRange]);

  return (
    <div className="scrapping_graph">
      {(data) && (
        <Line
          data={data}
          options={graphOptions}
        />
      )}
    </div>
  )
}
export default ScrappingGraph;
