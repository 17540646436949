import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import _ from "lodash";
import axios from "axios";
import HeaderComponent from "../HeaderComponent";
import NotificationManager from "../al_components/notification/NotificationManager";
import { AddManyInfluencers, AddInfluencersResults } from "../AddInfluencer/AddManyInfluencers";
import { getAgeAndGenderFromRekognition } from "../../utils";
import { ALSwitch, ALButton } from "../ALComponents";

import "./NewInfluencer.css";

class NewInfluencer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      searchWarning: "",
      searchError: "",
      searchResult: null,
      searchPodcastValue: {
        name: "",
        url: "",
        email: "",
        networks: "",
      },
      influencerData: null,
      isLoadingInfluencer: false,
      isScraping: false,
      isAddingInfluencer: false,
      error: null,
      influencersProcessing: [],
      platformAccountMayNotExistWarning: false,
      platform: "youtube",
    };
    this._searchInfluencer = this._searchInfluencer.bind(this);
    this._createInfluencer = this._createInfluencer.bind(this);
    this._addInfluencerDB = this._addInfluencerDB.bind(this);
    this._resetInfluencer = this._resetInfluencer.bind(this);
    this.isEmail = this.isEmail.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
  }

  _resetInfluencer() {
    this.setState({
      searchValue: "",
      searchWarning: "",
      searchError: "",
      searchResult: null,
      searchPodcastValue: {
        name: "",
        url: "",
        email: "",
        networks: "",
      },
      influencerData: null,
      isScraping: false,
      isAddingInfluencer: false,
      error: null,
      influencersProcessing: [],
      platformAccountMayNotExistWarning: false,
    });
  }

  async _addPodcastDB(podcastObject) {
    try {
      const r = await axios.post("/api/v1/podcasts", podcastObject);
      if (r.data.status === "ok") {
        return r.data.result;
      }
    } catch (err) {
      NotificationManager.error("Error", "Server failed to create podcast. " + err, 2000);
      return;
    }
  }

  async _addInfluencerDB(influencerData = null) {
    let tmpInfluencerData = influencerData || { ...this.state.influencerData };
    this.setState({ isAddingInfluencer: true });

    if (tmpInfluencerData.podcast?.accounts?.length > 0) {
      const dbPodcast = await this._addPodcastDB(tmpInfluencerData.podcast.accounts[0]);
      if (!dbPodcast) return;
      tmpInfluencerData.podcast.accounts[0] = dbPodcast._id;
    }

    axios
      .post("/api/v1/influencers", tmpInfluencerData)
      .then((r) => {
        if (r.data.status == "ok") {
          tmpInfluencerData["_id"] = r.data.result["_id"];
          this.setState({
            isAddingInfluencer: false,
            influencerData: tmpInfluencerData,
            influencersProcessing: [{ influencer: tmpInfluencerData, status: "Done!" }],
          });
        }
      })
      .catch((err) => {
        NotificationManager.error("Error", "Server failed to add influencer. " + err, 2000);
        this.setState({
          isAddingInfluencer: false,
        });
        return err;
      });
  }

  async _addInfluencerDBWithExistingPodcast(existingPodcastObject) {
    this.setState({ isAddingInfluencer: true });
    const { email } = this.state.searchPodcastValue;
    const tmpInfluencerData = {
      "status": "qualification_yes", // by pass qualification
      "emails": [email],
      "podcast": {
        "accounts": [existingPodcastObject._id],
      },
      "notes": [
        {
          "date": new Date().toISOString(),
          "content": `create from /a/dashboard/new_influencer by ${this.props.user.email}`,
        },
      ],
    };

    return axios
      .post("/api/v1/influencers", tmpInfluencerData)
      .then((r) => {
        if (r.data.status == "ok") {
          tmpInfluencerData["_id"] = r.data.result["_id"];
          NotificationManager.success("Successfully created new influencer with linked podcast!", "Success!", 2000);
          this.setState({
            isAddingInfluencer: false,
            influencerData: tmpInfluencerData,
            influencersProcessing: [{ influencer: tmpInfluencerData, status: "Done!" }],
          });
        }
      })
      .catch((err) => {
        NotificationManager.error("Server failed to add influencer. " + err, "Error", 2000);
        this.setState({
          isAddingInfluencer: false,
        });
        return err;
      });
  }

  _searchInfluencer() {
    const { searchValue, platform, searchPodcastValue } = this.state;
    this.setState({ isLoadingInfluencer: true });
    let _this = this;

    function searchBy_instaHandle(searchValue) {
      return axios.get("/api/v1/influencers?instagram.accounts=" + searchValue).then((res) => {
        return res.data.result;
      });
    }
    function searchBy_youtubeId(searchValue) {
      return axios.get("/api/v1/influencers?youtube.accounts=" + searchValue).then((res) => {
        return res.data.result;
      });
    }
    function searchBy_emails(searchValue) {
      return axios.get("/api/v1/influencers?emails=" + searchValue).then((res) => {
        return res.data.result;
      });
    }
    function searchBy_tiktokHandle(searchValue) {
      return axios.get("/api/v1/influencers?tiktok.accounts=" + searchValue).then((res) => {
        return res.data.result;
      });
    }
    function searchBy_podcastURL(searchValue) {
      return axios.get("/api/v1/podcasts?", { params: { "broadcasters.url": searchValue } }).then(async (res) => {
        const podcasts = res.data.result;
        if (podcasts.length === 0) return [];
        const { data } = await axios.get("/api/v1/influencers", {
          params: { "podcast.accounts": JSON.stringify({ "$in": podcasts.map((p) => p._id) }), "is_json_query": true },
        });
        console.log("searchBy_podcastURL", data);
        return podcasts.map((podcast) => {
          let influencerObject = data.result.find((influencer) => influencer.podcast.accounts.includes(podcast._id)) || {};
          influencerObject.podcast = { ...influencerObject.podcast, found: podcast };
          return influencerObject;
        });
      });
    }
    function searchBy_podcastName(searchValue) {
      return axios.get("/api/v1/podcasts?", { params: { "name": searchValue } }).then(async (res) => {
        const podcasts = res.data.result;
        if (podcasts.length === 0) return [];
        const { data } = await axios.get("/api/v1/influencers", {
          params: { "podcast.accounts": JSON.stringify({ "$in": podcasts.map((p) => p._id) }), "is_json_query": true },
        });
        console.log("searchBy_podcastName", data);
        return podcasts.map((podcast) => {
          let influencerObject = data.result.find((influencer) => influencer.podcast.accounts.includes(podcast._id)) || {};
          influencerObject.podcast = { ...influencerObject.podcast, found: podcast };
          return influencerObject;
        });
      });
    }
    async function searchBy_podcast(_, searchPodcastValue) {
      let { name, url, email } = searchPodcastValue;
      name = name.trim();
      url = url.trim();
      email = email.trim().toLowerCase();
      if (email !== searchPodcastValue.email || name !== searchPodcastValue.name || url !== searchPodcastValue.url) {
        _this.setState({ searchPodcastValue: { ...searchPodcastValue, name: name, url: url, email: email } });
      }
      const [resultByName, resultByURL, resultByEmail] = await Promise.all([
        searchBy_podcastName(name),
        searchBy_podcastURL(url),
        searchBy_emails(email),
      ]);
      const resultByEmailFull = await Promise.all(
        resultByEmail
          .map((influencer) => (influencer?.podcast?.accounts && influencer.podcast.accounts.length ? influencer : null))
          .filter((influencer) => influencer)
          .map(async (influencer) => {
            const { data } = await axios.get("/api/v1/podcasts", { params: { "_id": influencer.podcast.accounts[0] } });
            influencer.podcast.accounts[0] = data.result[0];
            influencer.podcast.found = data.result[0];
            return influencer;
          })
      );
      console.log("resultByEmail", resultByEmailFull);
      const allResults = [...resultByName, ...resultByURL, ...resultByEmailFull];
      console.log("allResults", allResults);
      return allResults.reduce((acc, influencer_1) => {
        // Remove duplicates
        let notFound = !acc.find(
          (inf2) => (influencer_1._id && inf2._id && influencer_1._id === inf2._id) || influencer_1?.podcast?.found?._id === inf2?.podcast?.found?._id
        );
        return notFound ? [...acc, influencer_1] : acc;
      }, []);
    }
    const searchByPlatform = {
      "youtube": searchBy_youtubeId,
      "instagram": searchBy_instaHandle,
      "tiktok": searchBy_tiktokHandle,
      "podcast": searchBy_podcast,
    };

    // let arrSearch = [];
    // let byEmail = searchBy_emails(searchValue);
    // arrSearch.push(byEmail);
    // let byYouTubeId = searchBy_youtubeId(searchValue);
    // arrSearch.push(byYouTubeId);
    // let byInsta = searchBy_instaHandle(searchValue);
    // arrSearch.push(byInsta);
    searchByPlatform[platform](searchValue, searchPodcastValue).then((influencers) => {
      console.log("result:", influencers);
      _this.setState({
        searchResult: influencers,
        isLoadingInfluencer: false,
      });
    });
    // Promise.all(arrSearch).then((val) => {
    //   let tmpResultSearchWithDuplicate = _.flattenDeep(val);
    //   let uniqResultSearch = _.uniq(tmpResultSearchWithDuplicate);
    //   _this.setState({
    //     searchResult: uniqResultSearch,
    //     isLoadingInfluencer: false,
    //   });
    // });
  }

  _createPodcastObject(podcastData) {
    let { name, url, networks } = podcastData;
    name = name.trim();
    url = url.trim();
    networks = networks.split(",").map((network) => network.trim());
    let broadcasterNameURL = new URL(url);
    let broadcasterName = broadcasterNameURL.hostname.replace(/^(.*\.)*(.+)\..+$/, "$2");

    const podcastObject = {
      name: name,
      networks: networks,
      broadcasters: [
        {
          name: broadcasterName,
          url: url,
        },
      ],
    };

    return podcastObject;
  }

  async _createInfluencer(youtubeData, instaData, tiktokData, podcastData) {
    let preferredImage = null;
    let emailInfluencer = [];
    let channelIds = [];
    let instagramHandles = [];
    let tiktokHandles = [];
    let podcasts = [];
    let countryInfluencer = "";
    let nameInfluencer = "";
    console.log("instaData:", instaData);
    if (instaData) {
      if (instaData.email) {
        emailInfluencer.push(instaData.email);
      }
      if (instaData.profileId) {
        instagramHandles.push(instaData.profileId);
      }
      if (instaData.pictureURL) {
        preferredImage = instaData.pictureURL;
      }
      if (instaData.fullName) {
        nameInfluencer = instaData.fullName;
      }
      if (typeof instaData === "string") {
        instagramHandles.push(instaData);
      }
    }
    if (youtubeData) {
      if (youtubeData.email) {
        emailInfluencer.push(youtubeData.email);
      }
      if (youtubeData.channelId) {
        channelIds.push(youtubeData.channelId);
      }
      if (!preferredImage) {
        preferredImage = youtubeData.pictureURL;
      }
      if (youtubeData.country) {
        countryInfluencer = youtubeData.country;
      }
      if (!nameInfluencer) {
        nameInfluencer = youtubeData.title;
      }
      if (youtubeData.instagramhandle && !instaData) {
        instagramHandles.push(youtubeData.instagramhandle);
      }
      if (youtubeData.instagramhandle && !instaData) {
        instagramHandles.push(youtubeData.instagramhandle);
      }
    }
    if (tiktokData) {
      if (typeof tiktokData === "string") {
        tiktokHandles.push(tiktokData);
      }
    }
    if (podcastData) {
      const cleanPodcastData = this._createPodcastObject(podcastData);
      podcasts = [cleanPodcastData];
      if (podcastData.email) {
        emailInfluencer.push(podcastData.email.trim().toLowerCase());
      }
    }

    let theGender = null;
    let theAge = null;
    if (preferredImage) {
      let ageGenderResult = await getAgeAndGenderFromRekognition(preferredImage);
      if (!ageGenderResult && youtubeData?.pictureURL && preferredImage !== youtubeData.pictureURL) {
        ageGenderResult = await getAgeAndGenderFromRekognition(youtubeData.pictureURL);
      }
      if (ageGenderResult) {
        theGender = ageGenderResult.gender;
        theAge = ageGenderResult.age;
      }
    }


    let currentDate = new Date().toISOString();
    let influencerData = {
      "status": "qualification_yes", // by pass qualification
      "emails": emailInfluencer,
      "paypal_email": "",
      "creation_date": currentDate,
      "keyword": "",
      "name": "",
      "youtube": {
        "accounts": channelIds,
      },
      "instagram": {
        "accounts": instagramHandles.map((handle) => handle.toLowerCase()),
      },
      "tiktok": {
        "accounts": tiktokHandles,
      },
      "podcast": {
        "accounts": podcasts,
      },
      "gender": theGender,
      "country": countryInfluencer,
      "age": theAge,
      "notes": [
        {
          "date": currentDate,
          "content": `create from /a/dashboard/new_influencer by ${this.props.user.email}`,
        },
      ],
    };

    this.setState({
      influencerData: influencerData,
      isScraping: false,
      platformAccountMayNotExistWarning: typeof instaData === "string",
    });
  }

  isEmail(inputText) {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  _scrapChannel(type) {
    this.setState({ isScraping: true });
    const { searchValue, searchPodcastValue } = this.state;

    function __scrapInstagram(handle) {
      return axios
        .get(`/api/v1/aws/handle/${handle}`)
        .then((data) => {
          if (data.result.name === "Error") {
            throw new Error(data.result.message);
          }
          return data;
        })
        .catch((err) => {
          NotificationManager.error("Server failed to get Instagram data. " + err, "Error", 4000);
          console.log("RETHROW");
          throw err;
        });
    }

    function __scrapYouTube(channelId) {
      return axios
        .get(`/api/v1/aws/channel/${channelId}`)
        .then((data) => {
          if (data?.result?.name === "Error") {
            throw new Error(data.result.message);
          }
          return data;
        })
        .catch((err) => {
          NotificationManager.error("Server failed to get YouTube data. " + err, "Error", 4000);
          throw err;
        });
    }

    if (type === "youtube") {
      __scrapYouTube(searchValue)
        .then((res) => {
          if (res.data.message == "ok") {
            if (Array.isArray(res.data.result) && res.data.result.length === 0) {
              this.setState({ isScraping: false });
              NotificationManager.error("Please check the provided channel and try again.", "Channel not found", 4000);
              return;
            }
            // check if instagram handle
            if (res.data.result.instagramHandle) {
              __scrapInstagram(res.data.result.instagramHandle)
                .then((res2) => {
                  this._createInfluencer(
                    res.data.result,
                    (res2.data && res2.data.result) || res.data.result.instagramHandle || null,
                    res.data.result.tiktokhandle || null,
                    null
                  );
                })
                .catch((err) => {
                  console.error(err);
                  this._createInfluencer(res.data.result, res.data.result.instagramHandle || null, res.data.result.tiktokhandle || null, null);
                });
            } else {
              this._createInfluencer(res.data.result, null, res.data.result.tiktokhandle || null, null);
            }
          }
        })
        .catch(() => this.setState({ isScraping: false }));
    } else if (type === "instagram") {
      __scrapInstagram(searchValue)
        .then((res) => {
          console.log("res.data.result", res.data.result);
          this._createInfluencer(null, res.data.result, null, null);
        })
        .catch(() => {
          this._createInfluencer(null, searchValue, null, null);
        });
    } else if (type === "tiktok") {
      this._createInfluencer(null, null, searchValue, null);
    } else if (type === "podcast") {
      this._createInfluencer(null, null, null, searchPodcastValue);
    }
  }
  onSearchInput(e) {
    const targetValue = e.target.value;
    this.setState((state) => {
      let newValue = targetValue;
      let warning = "";
      if (state.platform === "tiktok") {
        newValue = newValue.trim().toLowerCase();
        if (newValue.includes("tiktok.com")) {
          if (newValue.includes("tiktok.com/@")) {
            newValue = newValue.split("tiktok.com/@")[1].split("/")[0].split("?")[0].split("#")[0].split("/")[0] || newValue;
          } else {
            warning = "Wrong input. Expect an handle or a profile URL.";
          }
        }
      } else if (state.platform === "instagram") {
        newValue = newValue.trim().toLowerCase();
      }
      return { searchValue: newValue, searchWarning: warning };
    });
  }
  getPodcastURLWarning(url) {
    if (!url) return "";
    if (url.includes("podcasts.apple.com")) {
      if (url.includes("?i=")) {
        return "Episode URL instead of profile URL.";
      }
    } else if (url.includes("music.amazon.com/podcasts")) {
      if (url.includes("/episodes/")) {
        return "Episode URL instead of profile URL.";
      }
    } else {
      return "Unknown URL source. Please use an Apple Podcast URL (podcasts.apple.com)(recommended), or an Amazon music URL (music.amazon.com) or ignore this message as last resort.";
    }
    return "";
  }
  podcastSearchInput(value, field) {
    this.setState((state) => {
      let warning = "";
      let error = "";
      // value = value.trim();
      if (field !== "name" && field !== "networks" && field !== "url") {
        value = value.toLowerCase();
      }
      const url = field === "url" ? value : state.searchPodcastValue.url;
      warning += this.getPodcastURLWarning(url);
      if (field === "url" && !warning) {
        value = value.split("?")[0];
      }
      const email = field === "email" ? value : state.searchPodcastValue.email;
      const emailRegex = /\S+@\S+\.\S+/;
      if (email && !emailRegex.test(email)) {
        error += " Invalid email.";
      }
      return { searchPodcastValue: { ...state.searchPodcastValue, [field]: value }, searchWarning: warning, searchError: error };
    });
  }

  render() {
    const {
      searchValue,
      searchWarning,
      searchError,
      searchResult,
      searchPodcastValue,
      influencerData,
      isLoadingInfluencer,
      error,
      influencersProcessing,
      platform,
      platformAccountMayNotExistWarning,
    } = this.state;
    const searchByPlaceholder = {
      "youtube": "YouTube Channel ID",
      "instagram": "Instagram handle",
      "tiktok": "TikTok handle",
    };
    console.log("influencersProcessing:", influencersProcessing);
    return (
      <div className="yt_newinfluencer">
        {/* <HeaderComponent title="Add Influencer to Outreach" to="/a/dashboard/outreach" toTitle="Outreach" howToModal={() => this.props.showModal("ModalHowToNewInfluencer")} /> */}
        <div className="yt_newinfluencer__process-type">
          <div className="yt_newinfluencer__process-type__left">
            <ALSwitch
              options={[
                { key: "youtube", name: "YouTube" },
                { key: "instagram", name: "Instagram" },
                { key: "tiktok", name: "TikTok" },
                { key: "podcast", name: "Podcast" },
              ]}
              value={platform}
              onChange={(key) => {
                this._resetInfluencer();
                this.setState({ platform: key });
              }}
            />
            <div className={`yt_newinfluencer_search ${platform === "podcast" ? "yt_newinfluencer_search--podcast" : ""}`}>
              {!!searchWarning ? <div className="yt_newinfluencer_search_warning">{searchWarning}</div> : null}
              {!!searchError ? <div className="yt_newinfluencer_search_error">{searchError}</div> : null}
              {platform === "podcast" ? (
                // <p style={{ color: "grey", width: "100%", fontSize: "16px", margin: "0" }}>Work in progress – available soon :)</p>
                <>
                  <div className="newinfluencer_search_input-container">
                    <input
                      className="newinfluencer_search_input newinfluencer_search_input--podcast"
                      placeholder={`Podcast Name`}
                      type="text"
                      value={searchPodcastValue.name}
                      onChange={(e) => this.podcastSearchInput(e.target.value, "name")}
                    />
                    {searchPodcastValue.name.length > 0 ? (
                      <span
                        className="newinfluencer_search_input--remove material-icons"
                        onClick={() => this.setState((state) => ({ searchPodcastValue: { ...state.searchPodcastValue, name: "" } }))}>
                        search_off
                      </span>
                    ) : null}
                  </div>
                  <div className="newinfluencer_search_input-container">
                    <input
                      className="newinfluencer_search_input newinfluencer_search_input--podcast"
                      placeholder={`Podcast profile URL`}
                      type="text"
                      value={searchPodcastValue.url}
                      onChange={(e) => this.podcastSearchInput(e.target.value, "url")}
                    />
                    {searchPodcastValue.url.length > 0 ? (
                      <span
                        className="newinfluencer_search_input--remove material-icons"
                        onClick={() => this.setState((state) => ({ searchPodcastValue: { ...state.searchPodcastValue, url: "" } }))}>
                        search_off
                      </span>
                    ) : null}
                  </div>
                  <div className="newinfluencer_search_input-container">
                    <input
                      className="newinfluencer_search_input newinfluencer_search_input--podcast"
                      placeholder={`Podcast email`}
                      type="text"
                      value={searchPodcastValue.email}
                      onChange={(e) => this.podcastSearchInput(e.target.value, "email")}
                    />
                    {searchPodcastValue.email.length > 0 ? (
                      <span
                        className="newinfluencer_search_input--remove material-icons"
                        onClick={() => this.setState((state) => ({ searchPodcastValue: { ...state.searchPodcastValue, email: "" } }))}>
                        search_off
                      </span>
                    ) : null}
                  </div>
                  <div className="newinfluencer_search_input-container">
                    <input
                      className="newinfluencer_search_input newinfluencer_search_input--podcast"
                      placeholder={`Networks separated by comma (optional)`}
                      type="text"
                      value={searchPodcastValue.networks}
                      onChange={(e) => this.podcastSearchInput(e.target.value, "networks")}
                    />
                    {searchPodcastValue.networks.length > 0 ? (
                      <span
                        className="newinfluencer_search_input--remove material-icons"
                        onClick={() => this.setState((state) => ({ searchPodcastValue: { ...state.searchPodcastValue, networks: "" } }))}>
                        search_off
                      </span>
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  <div className="newinfluencer_search_input-container">
                    <input
                      className="newinfluencer_search_input"
                      placeholder={`Search by: ${searchByPlaceholder[platform]}`}
                      type="text"
                      value={searchValue}
                      onChange={this.onSearchInput}
                    />
                    {searchValue.length > 0 ? (
                      <span className="newinfluencer_search_input--remove material-icons" onClick={() => this._resetInfluencer()}>
                        search_off
                      </span>
                    ) : null}
                  </div>
                  {/* <span
                    className="newinfluencer_search_input--search material-icons"
                    onClick={() => {
                      this._searchInfluencer();
                    }}>
                    play_circle_outline
                  </span> */}
                </>
              )}
              <div className="newinfluencer_search__search_actions">
                <ALButton
                  className="newinfluencer_search__search_actions__search"
                  disable={!!searchError || !(searchValue || (searchPodcastValue.name && searchPodcastValue.url && searchPodcastValue.email))}
                  onClick={() => {
                    this._searchInfluencer();
                  }}>
                  Search
                </ALButton>
              </div>
            </div>

            {isLoadingInfluencer ? (
              <div className="yt_newinfluencer_loading">
                <Loading />
                <p className="al-loading-text">Searching influencer</p>
              </div>
            ) : null}

            {platform === "podcast" && !influencerData ? (
              <>
                {searchResult ? (
                  <div className="newinfluencer__existing_list">
                    {searchResult &&
                      searchResult.map((influencer, idx) => (
                        <div key={idx} className="newinfluencer__existing_list__container">
                          <div className="newinfluencer__existing_list__container__content">
                            {influencer._id ? (
                              <a
                                className="newinfluencer__existing_list__container__content__link"
                                href={`/a/dashboard/outreach?type=id&value=${influencer._id}`}
                                title="Open Outreach profile"
                                target="_blank">
                                <span className="material-icons">launch</span>
                              </a>
                            ) : null}
                            <h2 className="newinfluencer__existing_list__container__content__title">{influencer.podcast.found.name}</h2>
                            {influencer.podcast.found.broadcasters.map((broadcaster, i) => (
                              <a
                                key={i}
                                className="newinfluencer__existing_list__container__content__info newinfluencer__existing_list__container__content__info--link"
                                href={broadcaster.url}
                                target="_blank"
                                rel="noopener noreferrer">
                                {broadcaster.name} profile
                              </a>
                            ))}
                            {influencer.emails?.map((email) => (
                              <p key={email} className="newinfluencer__existing_list__container__content__info">
                                {email}
                              </p>
                            ))}
                            {influencer.podcast.found.networks?.map((network) => (
                              <p key={network} className="newinfluencer__existing_list__container__content__info">
                                {network}
                              </p>
                            ))}
                          </div>
                          {!influencer._id ? (
                            <div className="newinfluencer__existing_list__container__not_linked">
                              <p className="newinfluencer__existing_list__container__not_linked__description">
                                If this is the correct podcast and you would like to add an influencer, click the link below
                              </p>
                              <p
                                className="newinfluencer__existing_list__container__not_linked__action"
                                onClick={() => this._addInfluencerDBWithExistingPodcast(influencer.podcast.found)}>
                                Add influencer to this podcast
                              </p>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    <div className="newinfluencer__existing_list__container newinfluencer__existing_list__container--not_listed">
                      <div className="newinfluencer__existing_list__container__content">
                        <h2 className="newinfluencer__existing_list__container__content__title">
                          {searchResult && searchResult.length === 0 ? "Unknown podcast" : "Other Podcast Not Listed Above"}
                        </h2>
                        <p className="newinfluencer__existing_list__container__content__question">Do you want to add it in the database?</p>
                      </div>
                      <div className="newinfluencer__existing_list__actions">
                        <div
                          className="newinfluencer__existing_list__actions__button newinfluencer__existing_list__actions__button--green"
                          onClick={() => {
                            this._scrapChannel(platform);
                          }}>
                          Build influencer object
                        </div>
                        <div
                          className="newinfluencer__existing_list__actions__button newinfluencer__existing_list__actions__button--red"
                          onClick={() => this._resetInfluencer()}>
                          CANCEL
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}

            {platform !== "podcast" && !influencerData ? (
              searchResult && searchResult.length === 0 ? (
                <div className="yt_newinfluencer_unknown">
                  <div className="yt_newinfluencer_unknown_container">
                    <p className="yt_newinfluencer_unknown_title">1) Unknow influencer</p>
                    <p className="yt_newinfluencer_unknown_subtitle">Do you want to add it in the DataBase?</p>
                    <div className="yt_newinfluencer_unknown_actions">
                      <div
                        className="yt_newinfluencer_unknown_add"
                        onClick={() => {
                          this._scrapChannel(platform);
                        }}>
                        {["youtube", "instagram"].includes(platform) ? "Scrap Data & " : ""}Build influencer object
                      </div>
                      <div className="yt_newinfluencer_unknown_cancel" onClick={() => this._resetInfluencer()}>
                        CANCEL
                      </div>
                    </div>

                    {/* {searchValue.substring(0, 2) == "UC" && searchValue.length > 20 ? (
                    <div className="yt_newinfluencer_unknown_actions">
                      <div
                        className="yt_newinfluencer_unknown_add"
                        onClick={() => {
                          this._scrapChannel("youtube");
                        }}>
                        Scrap YouTube Data & build influencer object
                      </div>
                      <div className="yt_newinfluencer_unknown_cancel" onClick={() => this._resetInfluencer()}>
                        CANCEL
                      </div>
                    </div>
                  ) : !this.isEmail(searchValue) ? (
                    <div className="yt_newinfluencer_unknown_actions">
                      <div
                        className="yt_newinfluencer_unknown_add"
                        onClick={() => {
                          this._scrapChannel("instagram");
                        }}>
                        Scrap Instagram Data & build influencer object
                      </div>
                      <div className="yt_newinfluencer_unknown_cancel" onClick={() => this._resetInfluencer()}>
                        CANCEL
                      </div>
                    </div>
                  ) : (
                    <p className="yt_newinfluencer_unknown_subtitle">
                      Please do not input an email if you want to add a channel, please add a YouTube Channel Id OR an Instagram Handle
                    </p>
                  )} */}
                  </div>
                </div>
              ) : searchResult && searchResult.length > 0 ? (
                <div className="yt_newinfluencer_found">
                  <p className="yt_newinfluencer_found_title">This influencer already exist!</p>
                  <p className="yt_newinfluencer_found_subtitle">With this {platform} account</p>
                  <p className="yt_newinfluencer_found_subtitle">If you want to create a new deal, create it from the outreach.</p>
                  <a
                    className="yt_newinfluencer_found_subtitle yt_newinfluencer_found_subtitle--link"
                    href={`/a/dashboard/outreach${
                      searchResult[0].emails.length > 0
                        ? `?type=email&value=${searchResult[0].emails[0]}`
                        : searchResult[0].youtube.accounts.length > 0
                        ? `?type=channelId&value=${searchResult[0].youtube.accounts[0]}`
                        : searchResult[0].instagram.accounts.length > 0
                        ? `?type=handle&value=${searchResult[0].instagram.accounts[0]}`
                        : searchResult[0].tiktok.accounts.length > 0
                        ? `?type=tiktok_handle&value=${searchResult[0].tiktok.accounts[0]}`
                        : `?type=id&value=${searchResult[0]._id}`
                    }`}>
                    Click here to visit influencer profile
                  </a>
                </div>
              ) : null
            ) : null}
            {this.state.isScraping ? (
              <div className="yt_newinfluencer_loading">
                <Loading />
                <p className="al-loading-text">Scraping influencer data</p>
              </div>
            ) : null}

            {this.state.isAddingInfluencer ? (
              <div className="yt_newinfluencer_loading">
                <Loading />
                <p className="al-loading-text">Adding influencer</p>
              </div>
            ) : null}

            {influencerData && !influencerData["_id"] ? (
              <div className="yt_newinfluencer_unknown_container">
                <div className="yt_newinfluencer_data">
                  <p className="yt_newinfluencer_data_title">Influencers informations</p>
                  {platformAccountMayNotExistWarning ? (
                    <div className="yt_newinfluencer_data__warning">
                      <span>
                        <i className="material-icons yt_newinfluencer_data__warning--icon">report_problem</i>
                      </span>
                      <label className="yt_newinfluencer_data__warning--text">
                        We couln't verify the existence of the provided platform account.
                        <br />
                        Please double check and make sure you entered it correctly.
                      </label>
                    </div>
                  ) : null}
                  <p className="yt_newinfluencer_data_text">
                    YouTube channel ID: {influencerData.youtube?.accounts?.length ? influencerData.youtube.accounts[0] : "ø"}
                  </p>
                  <p className="yt_newinfluencer_data_text">
                    Instagram handle: {influencerData.instagram?.accounts?.length ? influencerData.instagram.accounts[0] : "ø"}
                  </p>
                  <p className="yt_newinfluencer_data_text">
                    TikTok handle: {influencerData.tiktok?.accounts?.length ? influencerData.tiktok.accounts[0] : "ø"}
                  </p>
                  <p className="yt_newinfluencer_data_text">
                    Podcast name: {influencerData.podcast?.accounts?.length ? influencerData.podcast.accounts[0]?.name : "ø"}
                  </p>
                  <p className="yt_newinfluencer_data_text">Country: {influencerData?.country?.length > 0 ? influencerData?.country : "ø"}</p>
                  <p className="yt_newinfluencer_data_text">
                    Email(s):{" "}
                    {influencerData.emails.length > 0
                      ? influencerData.emails.map((email, i) => {
                          return (
                            <span className="yt_newinfluencer_data_text--email" key={`influencer_email_${i}`}>
                              {email}
                            </span>
                          );
                        })
                      : "ø"}
                  </p>
                  <p className="yt_newinfluencer_data_text">
                    Gender: {influencerData?.gender && influencerData?.gender?.value ? influencerData.gender.value : "ø"}
                  </p>

                  <div className="yt_newinfluencer_data_actions">
                    <div className="yt_newinfluencer_data_actions_add" onClick={() => this._addInfluencerDB()}>
                      ADD
                    </div>
                    <div className="yt_newinfluencer_data_actions_cancel" onClick={() => this._resetInfluencer()}>
                      CANCEL
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {influencerData && influencerData["_id"] ? (
              <div className="yt_newinfluencer_done">
                <p className="yt_newinfluencer_done_title">
                  Done! <span className="material-icons">done_all</span>
                </p>
                <p className="yt_newinfluencer_done_subtitle">Please check the influencer in the Outreach page</p>
                <Link
                  className="yt_newinfluencer_done_subtitle yt_newinfluencer_done_subtitle--link"
                  to={`/a/dashboard/outreach${
                    platform === "podcast"
                      ? `?type=id&value=${influencerData._id}`
                      : influencerData.emails.length > 0
                      ? `?type=email&value=${influencerData.emails[0]}`
                      : influencerData.youtube.accounts.length > 0
                      ? `?type=channelId&value=${influencerData.youtube.accounts[0]}`
                      : influencerData.instagram.accounts.length > 0
                      ? `?type=handle&value=${influencerData.instagram.accounts[0]}`
                      : influencerData.tiktok.accounts.length > 0
                      ? `?type=tiktok_handle&value=${influencerData.tiktok.accounts[0]}`
                      : `?type=id&value=${influencerData._id}`
                  }`}>
                  Click here to visit influencer profile
                </Link>
                <div
                  className="newinfluencer__existing_list__actions__button newinfluencer__existing_list__actions__button--blue"
                  onClick={() => {
                    this._resetInfluencer();
                  }}>
                  Add another influencer
                </div>
              </div>
            ) : null}
          </div>
          <div className="yt_newinfluencer__process-type__right">
            <AddManyInfluencers
              {...this.props}
              influencersProcessing={influencersProcessing}
              setError={(newError) =>
                typeof newError === "function" ? this.setState((state) => newError(state.error)) : this.setState({ error: newError })
              }
              setInfluencersProcessing={(newInfluencersProcessing) =>
                typeof newInfluencersProcessing === "function"
                  ? this.setState((state) => {
                      console.log("newInfluencersProcessing", newInfluencersProcessing(state.influencersProcessing));
                      return { influencersProcessing: newInfluencersProcessing(state.influencersProcessing) };
                    })
                  : this.setState(() => {
                      console.log("NEW influencersProcessing");
                      return { influencersProcessing: newInfluencersProcessing };
                    })
              }
            />
          </div>
        </div>
        <AddInfluencersResults error={error} influencersProcessing={influencersProcessing} />
      </div>
    );
  }
}

export default NewInfluencer;
