import React from "react";
import "./table.css";

class ALTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    const header = this.props.header || [];
    const content = this.props.content_limit && !this.state.isOpen ? this.props.content.slice(0, this.props.content_limit) : this.props.content;
    return (
      <div className="al_table__wrapper">
        {this.props.title ? <div className="al_table__title">{this.props.title}</div> : null}
        <table className="al_table" id="tab">
          <colgroup>
            {(this.props.column_style || []).map((elem, i) => (
              <col key={i} span="1" style={elem} />
            ))}
          </colgroup>
          {/* Header */}
          <thead className={`al_table__header ${header.length === 0 ? "al_table__header--hidden" : ""}`}>
            <tr className="al_table__header__row">
              {header.map((elem, i) => {
                return (
                  <th key={i} className="al_table__header__row__cell" onClick={() => this.props.handleSort && this.props.handleSort(elem)}>
                    {elem}
                  </th>
                );
              })}
            </tr>
          </thead>

          {/* Body */}
          <tbody className="al_table__body">
            {(content || []).map((row, i) => {
              return (
                <tr key={i} className={`al_table__body__row ${i % 2 === 0 ? "al_table__body__row--even" : ""}`}>
                  {(row || []).map((elem, j) => {
                    return (
                      <td key={j} className="al_table__body__row__cell">
                        {elem}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {this.props.content_limit && this.props.content.length > this.props.content_limit ? (
          this.state.isOpen ? (
            <div className="al_table__more" onClick={() => this.setState({ isOpen: false })}>
              <label className="al_table__more--label">See less</label>
              <span className="material-icons isOpen">expand_more</span>
            </div>
          ) : (
            <div className="al_table__more" onClick={() => this.setState({ isOpen: true })}>
              <label className="al_table__more--label">See more</label>
              <span className="material-icons">expand_more</span>
            </div>
          )
        ) : null}
      </div>
    );
  }
}

export default ALTable;
