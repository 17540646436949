import React, { useState, useEffect } from "react";
import axios from "axios";
import csv from "csvtojson";
import { formatISO } from "date-fns";
import { chunk } from "lodash";
import NotificationManager from "../al_components/notification/NotificationManager";
import { ALSwitch, ALContainer, ALTable, ALButton, ALDropdown, ALLoading } from "components/ALComponents";
import { sleep } from "utils";

import "./Payment.css";

const PaymentImport = (props) => {
  const [importedFiles, setImportedFiles] = useState([]);
  const [fileReaders, setFileReaders] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  // const [loadingDescription, setLoadingDescription] = useState("Loading things...");
  const [transactionsByFiles, setTransactionsByFiles] = useState([]);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingDescription, setLoadingDescription] = useState("");
  // const [transactionsByFiles, setTransactionsByFiles] = useState([[{}], [{}, {}]]);
  const [nbProcessedFile, setNbProcessedFile] = useState(0);

  async function updateAllTransactions() {
    // Update all transactions to 'processed' state
    for (const [fileIdx, transactionFile] of transactionsByFiles.entries()) {
      for (const [lineIdx, transactionLine] of transactionFile.entries()) {
        setLoadingDescription(
          `Processing transaction [File: ${fileIdx + 1}/${transactionsByFiles.length}] [Transaction: ${lineIdx + 1}/${transactionFile.length}]...`
        );
        const transactionId = transactionLine["Reference ID (optional)"];
        await axios.put(`/api/v1/transactions/${transactionId}`, { status: "processed" });
      }
    }
    NotificationManager.success("", "Successfully processed transactions!", 1500);

    setIsLoading(false);
    setLoadingDescription("");
    // setImportedFiles([]);
    // setFileReaders([]);
    // setTransactionsByFiles([]);
    // setNbProcessedFile(0);
  }

  async function verifyTransactionFile(fileIndex, transactionFile, batchId = null) {
    for (const [lineIdx, transactionLine] of transactionFile.entries()) {
      setLoadingDescription(
        `Verifying transactions [File: ${fileIndex + 1}/${transactionsByFiles.length}] [Transaction: ${lineIdx + 1}/${transactionFile.length}]...`
      );
      const transactionId = transactionLine["Reference ID (optional)"];
      const response = await axios.get(`/api/v1/transactions/${transactionId}`);
      const transaction = response?.data?.result;
      // Wrong batch ID
      if (!transaction || transaction.batch_id !== (batchId || transactionLine["Batch ID"])) {
        const error = `Error [File: ${fileIndex + 1}/${transactionsByFiles.length}] [Transaction: ${lineIdx + 1}/${
          transactionFile.length
        }] ID: ${transactionId} Status: ${transaction?.status}`;
        NotificationManager.error(error, "Error while verifying transactions", 6000);
        setLoadingDescription(error);
        const res = await props.showModal("ModalWarn", {
          title: "Error",
          text: `A Transaction does not have the right batch ID!\nImport is cancelled.\n \nFile ${fileIndex + 1} Transaction: ${
            lineIdx + 1
          } ID: ${transactionId} Status: ${transaction?.status}`,
          noActions: true,
        });
        return;
      }
      // Already processed
      if (!transaction || transaction.status !== "processing") {
        const error = `Error [File: ${fileIndex + 1}/${transactionsByFiles.length}] [Transaction: ${lineIdx + 1}/${
          transactionFile.length
        }] ID: ${transactionId} Status: ${transaction?.status}`;
        NotificationManager.error(error, "Error while verifying transactions", 6000);
        setLoadingDescription(error);
        const res = await props.showModal("ModalWarn", {
          title: "Warning",
          text: `A Transaction have already been processed!\nDo you still wish to proceed?\n \nFile ${fileIndex + 1} Transaction: ${
            lineIdx + 1
          } ID: ${transactionId} Status: ${transaction?.status}`,
        });
        if (res !== "confirm") {
          return;
        }
      }
      // Amounts differ
      if (transaction.amount / 100 !== parseFloat(transactionLine["Amount"])) {
        const error = `Error [File: ${fileIndex + 1}/${transactionsByFiles.length}] [Transaction: ${lineIdx + 1}/${
          transactionFile.length
        }] ID: ${transactionId} —> Amount differs`;
        NotificationManager.error(error, "Error while verifying transactions", 6000);
        setLoadingDescription(error);
        await props.showModal("ModalWarn", {
          title: "Error",
          text: `Transaction (ID: ${transactionId}) amounts differ. Import is cancelled.\n(File ${fileIndex + 1})`,
          noActions: true,
        });
        return;
      }
    }
  }

  async function processTransactionByFiles() {
    setIsLoading(true);
    const regex = /^payment-export_(?:batch_([0-9]{10})_USD([0-9\.]+)|processing_([0-9\-]{10})).csv/;

    // Verify that all transactions are in 'processing' state
    for (const [fileIdx, transactionFile] of transactionsByFiles.entries()) {
      await verifyTransactionFile(fileIdx, transactionFile);
    }

    updateAllTransactions();

    // // Update all transactions to 'processed' state
    // for (const [fileIdx, transactionFile] of transactionsByFiles.entries()) {
    //   for (const [lineIdx, transactionLine] of transactionFile.entries()) {
    //     setLoadingDescription(
    //       `Processing transaction [File: ${fileIdx + 1}/${transactionsByFiles.length}] [Transaction: ${lineIdx + 1}/${transactionFile.length}]...`
    //     );
    //     const transactionId = transactionLine["Reference ID (optional)"];
    //     await axios.put(`/api/v1/transactions/${transactionId}`, { status: "processed" });
    //   }
    // }
  }

  useEffect(() => {
    const filesLoaded = transactionsByFiles.filter((f) => f !== null);
    if (filesLoaded.length === 0 || filesLoaded.length !== importedFiles.length) return;
    setIsLoadingImport(false);
    processTransactionByFiles();
  }, [transactionsByFiles.filter((f) => f !== null).length]);

  async function parseFileTransactions(idx, event) {
    const dataCSV = event.target.result;
    const transactions = await csv().fromString(dataCSV);
    if (!transactionsByFiles.length) {
      let newTransactionsByFilesArray = new Array(importedFiles.length).map(() => null);
      newTransactionsByFilesArray[idx] = transactions;
      setTransactionsByFiles(newTransactionsByFilesArray);
    } else {
      setTransactionsByFiles(transactionsByFiles.map((f, arrayIdx) => (arrayIdx === idx ? transactions : f)));
    }
  }

  function onImportFiles() {
    setNbProcessedFile(0);
    setIsLoadingImport(true);
    setIsLoading(true);
    setLoadingDescription("Parsing files...");
    importedFiles.forEach((file, idx) => {
      fileReaders[idx].onloadend = (e) => parseFileTransactions(idx, e);
      fileReaders[idx].readAsText(file);
    });
  }

  function onInputFile(event) {
    const regex = /^payment-export_(?:batch_([0-9]{10})_USD([0-9\.]+)|processing_([0-9\-]{10})).csv/;
    setTransactionsByFiles([]);
    setNbProcessedFile(0);
    const filesList = Array.from(event.target.files);
    if (filesList.some((f) => !regex.test(f.name))) {
      event.target.value = null;
      return props.showModal("ModalWarn", {
        title: "Error",
        text: `Incorrect file name. Please keep the same format as the exported one.`,
        noActions: true,
      });
    }
    setImportedFiles(filesList);
    setFileReaders(filesList.map(() => new FileReader()));
  }

  console.log(importedFiles.map((f) => f.name.substring(0, 15)).join(", "));

  return (
    <div className="payment-import">
      <ALContainer className="payment-import__input-form">
        <label className="payment-import__input-form__area">
          <div className="payment-import__input-form__area__placeholder">
            {importedFiles.length ? importedFiles.map((f, idx) => <p key={idx}>{f.name}</p>) : "Choose file(s)"}
          </div>
          <input
            className="payment-import__input-form__area__input"
            id="file-upload"
            type="file"
            placeholder="Choose file"
            accept=".csv"
            multiple
            disabled={isLoadingImport || isLoading}
            onChange={onInputFile}
          />
          <span className="material-icons">search</span>
        </label>
        <ALButton disable={!importedFiles.length || isLoadingImport} onClick={onImportFiles}>
          {isLoadingImport ? <ALLoading alt /> : "IMPORT"}
        </ALButton>
      </ALContainer>
      <ALContainer className="payment-import__processing">
        {isLoading ? <ALLoading alt /> : null}
        {isLoading ? <div className="payment-import__processing__loading-description">{loadingDescription}</div> : null}
        <div className="payment-import__processing__line">
          <div className="payment-import__processing__line__description">Total Number of Files</div>
          <div className="payment-import__processing__line__value">{transactionsByFiles.length}</div>
        </div>
        <div className="payment-import__processing__line">
          <div className="payment-import__processing__line__description">Total Transactions</div>
          <div className="payment-import__processing__line__value">
            {nbProcessedFile}/{transactionsByFiles.reduce((acc, fileTransactions) => acc + fileTransactions.length, 0)}
          </div>
        </div>
      </ALContainer>
    </div>
  );
};

const PaymentExport = () => {
  const [requestedTransactions, setRequestedTransactions] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedMaxAmountPerFile, setSelectedMaxAmountPerFile] = useState("");
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [paymentTransactionTable, setPaymentTransactionTable] = useState([
    ["Paypal", "0", "$0"],
    ["Wire", "0", "$0"],
    ["Total", "0", "$0"],
  ]);
  const [transactionsByFiles, setTransactionsByFiles] = useState([]);
  const [selectedNbFiles, setSelectedNbFiles] = useState("");
  const [totalPriceOfSelectedFiles, setTotalPriceOfSelectedFiles] = useState(0);
  const [isExportingFiles, setIsExportingFiles] = useState(false);
  const paymentTypes = [
    { key: "paypal", value: "PayPal" },
    { key: "wire", value: "Wire" },
  ];

  function calculateTable() {
    let paymentTypeTable = paymentTypes.reduce((paymentTypeTable, paymentType) => {
      const values = requestedTransactions.reduce(
        ([nbTransaction, totalAmount], transaction) => {
          return paymentType.key === transaction.method ? [nbTransaction + 1, totalAmount + transaction.amount] : [nbTransaction, totalAmount];
        },
        [0, 0]
      ); // [# of requests, amount of those request]
      return [...paymentTypeTable, [paymentType.value, ...values]];
    }, []); // [line1, line2]
    const lineTotal = paymentTypeTable.reduce(
      ([_title, totalNbTransaction, totalTotalAmount], [_transactionMethod, nbTransaction, totalAmount]) => [
        _title,
        totalNbTransaction + nbTransaction,
        totalTotalAmount + totalAmount,
      ],
      ["Total", 0, 0]
    );
    paymentTypeTable.push(lineTotal);
    setPaymentTransactionTable(paymentTypeTable.map((line) => [line[0], `${line[1]}`, `$${(line[2] / 100).toFixed(2)}`]));
  }

  function calculateTransactionsPerFiles() {
    if (!selectedPaymentType) {
      setTransactionsByFiles([]);
      return;
    }
    const [newTransactionsByFiles, _] = requestedTransactions.reduce(
      ([transactionsFiles, currentFileTotalPrice], transaction) => {
        if (transaction.method !== selectedPaymentType) {
          return [transactionsFiles, currentFileTotalPrice];
        }
        let newCurrentFileTotalPrice = currentFileTotalPrice + transaction.amount;
        let newTransactionsFiles = [...transactionsFiles];
        const maxAmountByFile = selectedMaxAmountPerFile === "" ? 0 : Math.round(parseInt(selectedMaxAmountPerFile) * 100);
        if (transactionsFiles.length === 0 || (maxAmountByFile && newCurrentFileTotalPrice > maxAmountByFile)) {
          newTransactionsFiles = [[], ...newTransactionsFiles];
          newCurrentFileTotalPrice = transaction.amount;
        }
        newTransactionsFiles[0].push(transaction);
        return [newTransactionsFiles, newCurrentFileTotalPrice];
      },
      [[], 0]
    );
    setTransactionsByFiles(newTransactionsByFiles.reverse());
  }

  function getSocialMediaIdentifier(inf) {
    // Social platforms hierarchy: Youtube > Tik Tok > IG
    const platformHierarchy = ["youtube", "tiktok", "instagram"];
    for (const platform of platformHierarchy) {
      if (inf?.[platform]?.accounts?.length) {
        return {
          platform: platform,
          handle: inf[platform].accounts[0],
        };
      }
    }
    return {
      platform: "",
      handle: "",
    };
  }

  useEffect(() => {
    console.log("Fire table calculation useEffect()");
    calculateTable();
  }, [requestedTransactions.length]);

  useEffect(() => {
    console.log("Fire transactions by file calculation useEffect()");
    calculateTransactionsPerFiles();
  }, [requestedTransactions.length, selectedMaxAmountPerFile, selectedPaymentType]);

  useEffect(() => {
    console.log("Fire total price calculation for selected # of files useEffect()");
    const newTotalPriceOfSelectedFiles = transactionsByFiles.slice(0, selectedNbFiles).reduce((acc, file) => {
      return acc + file.reduce((fileAcc, transaction) => fileAcc + transaction.amount, 0);
    }, 0);
    setTotalPriceOfSelectedFiles(newTotalPriceOfSelectedFiles);
  }, [selectedNbFiles]);

  function onMaxAmountPerFileInputChange(event) {
    const value = event.target.value;
    if (value === "") {
      setSelectedMaxAmountPerFile("");
      return;
    }
    const number = parseInt(value);
    setSelectedNbFiles("");
    setSelectedMaxAmountPerFile(number > 0 ? `${number}` : "");
  }
  async function onClickUpdate() {
    if (isLoadingUpdate) return;
    setIsLoadingUpdate(true);
    const { data } = await axios.get("/api/v1/transactions", { params: { status: "requested" } });
    setRequestedTransactions(data.result);
    setIsLoadingUpdate(false);
  }

  function onPaymentTypeChange(newType) {
    if (newType === selectedPaymentType) return;
    setSelectedNbFiles("");
    setSelectedMaxAmountPerFile("");
    setTotalPriceOfSelectedFiles(0);
    setSelectedPaymentType(newType);
  }

  async function getInfluencersTransactions(transactions) {
    let transactionsInfluencers = [];
    const transactionsChunks = chunk(transactions, 10); // Chunk to reduce network load on both client and server
    for (const transactionsChunk of transactionsChunks) {
      const transactionsInfluencersChunk = (
        await Promise.all(transactionsChunk.map((transaction) => axios.get(`/api/v1/influencers/${transaction.influencer_id}`)))
      ).map(({ data }) => data.result);
      transactionsInfluencers = [...transactionsInfluencers, ...transactionsInfluencersChunk];
    }
    return transactionsInfluencers;
  }

  async function onExportFile() {
    if (isExportingFiles) return;
    setIsExportingFiles(true);

    const selectedFilesTransactions = transactionsByFiles.slice(0, selectedNbFiles);

    // Check that no change happened between data pull ('Update') and Export actions
    for (const file of selectedFilesTransactions) {
      const transactionsChunks = chunk(file, 10); // Chunk to reduce network load on both client and server
      for (const transactions of transactionsChunks) {
        const recentFileTransactions = (
          await Promise.all(transactions.map((transaction) => axios.get(`/api/v1/transactions/${transaction._id}`)))
        ).map(({ data }) => data.result);
        const transactionsUpdatedByAgents = transactions.some((transaction, idx) => transaction.amount !== recentFileTransactions[idx].amount);
        if (transactionsUpdatedByAgents) {
          console.error("A transaction amount differ with its most recent version");
          NotificationManager.error("A transaction amount differ with its most recent version. Please update and try again.", "Export failed", 6000);
          return;
        }
      }
    }

    const {
      data: { result: batchNumber },
    } = await axios.get("/api/v1/redis/transaction_batch_number");

    // Update and export transactions
    let fileNb = 0;
    for (const file of selectedFilesTransactions) {
      let fullBatchId = `${batchNumber}${fileNb.toString().padStart(2, "0")}`;
      let fileLines = [];
      try {
        const transactionsInfluencers = await getInfluencersTransactions(file);
        for (const [idx, transaction] of file.entries()) {
          await axios.put(`/api/v1/transactions/${transaction._id}`, {
            status: "processing",
            batch_id: fullBatchId,
          });
          const socialMediaInfo = getSocialMediaIdentifier(transactionsInfluencers[idx]);

          fileLines.push([
            transactionsInfluencers[idx].paypal_email,
            (transaction.amount / 100).toFixed(2),
            transaction.currency,
            transaction._id,
            "Ana Luisa",
            transaction.method === "paypal" ? "PayPal" : transaction.method,
            "",
            "",
            "",
            fullBatchId,
            transaction.deal_id,
            socialMediaInfo.platform,
            socialMediaInfo.handle,
          ]);
        }
      } finally {
        let strCsv =
          "Email/Phone,Amount,Currency code,Reference ID (optional),Note to recipient,Recipient wallet, Social Feed Privacy (optional), Holler URL (optional), Logo URL (optional), Batch ID, Deal Id, Platform, Handle\r\n";
        strCsv += fileLines.reduce((acc, line) => acc + line.join(",") + "\r\n", "");
        await sleep(2000);
        const filename = `payment-export_batch_${fullBatchId}_USD${(file.reduce((acc, t) => acc + t.amount, 0) / 100).toFixed(2)}.csv`;
        const contentType = "text/csv;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([decodeURIComponent(encodeURI(strCsv))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          let a = document.createElement("a");
          a.download = filename;
          a.href = "data:" + contentType + "," + encodeURIComponent(strCsv);
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
      fileNb += 1;
    }

    setIsExportingFiles(false);
    setTotalPriceOfSelectedFiles(0);
    setSelectedNbFiles("");
    setTransactionsByFiles([]);
    setSelectedMaxAmountPerFile([]);
    setSelectedPaymentType("");
    setRequestedTransactions([]);
  }

  async function onExportProcessing() {
    const {
      data: { result: transactionsProcessing },
    } = await axios.get("/api/v1/transactions", { params: { status: "processing" } });
    console.log("transactionsProcessing", transactionsProcessing);
    const selectedFilesTransactions = [transactionsProcessing];
    console.log("selectedFilesTransactions", selectedFilesTransactions);
    // Update and export transactions
    for (const file of selectedFilesTransactions) {
      let fileLines = [];
      try {
        const transactionsInfluencers = await getInfluencersTransactions(file);
        for (const [idx, transaction] of file.entries()) {
          const socialMediaInfo = getSocialMediaIdentifier(transactionsInfluencers[idx]);

          fileLines.push([
            transactionsInfluencers[idx].paypal_email,
            (transaction.amount / 100).toFixed(2),
            transaction.currency,
            transaction._id,
            "Ana Luisa",
            transaction.method === "paypal" ? "PayPal" : transaction.method,
            "",
            "",
            "",
            transaction.batch_id,
            transaction.deal_id,
            socialMediaInfo.platform,
            socialMediaInfo.handle,
          ]);
        }
      } finally {
        let strCsv =
          "Email/Phone,Amount,Currency code,Reference ID (optional),Note to recipient,Recipient wallet, Social Feed Privacy (optional), Holler URL (optional), Logo URL (optional),Batch ID, Deal Id, Platform, Handle\r\n";
        strCsv += fileLines.reduce((acc, line) => acc + line.join(",") + "\r\n", "");
        await sleep(2000);
        const filename = `payment-export_processing_${formatISO(new Date(), { representation: "date" })}.csv`;
        const contentType = "text/csv;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([decodeURIComponent(encodeURI(strCsv))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          let a = document.createElement("a");
          a.download = filename;
          a.href = "data:" + contentType + "," + encodeURIComponent(strCsv);
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    }
  }
  return (
    <ALContainer>
      <div className="payment__section payment__section--retrieve">
        <div className="payment__section__left">
          <div className="payment__section__title">1. Retrieve Requested Transaction Data</div>
          <div className="payment__section__left__line">
            <ALButton onClick={onClickUpdate} disabled={isLoadingUpdate}>
              {isLoadingUpdate ? <ALLoading alt /> : "UPDATE"}
            </ALButton>
            <ALButton className="payment__section__left__export-processing-btn" onClick={onExportProcessing}>
              EXPORT PROCESSING STATUS ONLY
            </ALButton>
          </div>
        </div>
        <div className="payment__section__right">
          {requestedTransactions.length > 0 ? (
            <ALTable
              header={["Payment Type", "Qty", "Price"]}
              content={paymentTransactionTable}
              column_style={[{ width: "63%" }, { width: "16%" }, { width: "19%" }]}
            />
          ) : null}
        </div>
      </div>
      {requestedTransactions.length > 0 ? (
        <div className="payment__section">
          <div className="payment__section__left">
            <div className="payment__section__title">2. Choose Type of Payment and Max Payment of Each File</div>
            <div className="payment__section__left__line">
              <div className="payment__section__input_container payment__section__input_container--payment_type">
                <div className="payment__section__input_container__title">Type of Payment</div>
                <ALDropdown options={[{ key: "", value: "Choose" }, ...paymentTypes]} value={selectedPaymentType} onChange={onPaymentTypeChange} />
              </div>
              <div className="payment__section__input_container payment__section__input_container--fmax_amount">
                <div className="payment__section__input_container__title">Maximum Total Payment of Each File</div>
                <input type="number" onChange={onMaxAmountPerFileInputChange} value={selectedMaxAmountPerFile} min="1" placeholder="Input Price" />
              </div>
            </div>
          </div>
          <div className="payment__section__right">
            <div className="payment__section__right__info">
              <div className="payment__section__right__info__description">Resulting Number of Files</div>
              <div className="payment__section__right__info__value">{transactionsByFiles.length || "-"}</div>
            </div>
          </div>
        </div>
      ) : null}
      {transactionsByFiles.length > 0 ? (
        <div className="payment__section">
          <div className="payment__section__left">
            <div className="payment__section__title">3. Choose Number of Files to Export</div>
            <div className="payment__section__left__line">
              <div className="payment__section__input_container payment__section__input_container--nb_files">
                <ALDropdown
                  options={[{ key: "", value: "# of files" }, ...transactionsByFiles.map((_, idx) => ({ key: `${idx + 1}`, value: `${idx + 1}` }))]}
                  value={selectedNbFiles}
                  onChange={setSelectedNbFiles}
                />
              </div>
            </div>
          </div>
          <div className="payment__section__right">
            <div className="payment__section__right__info">
              <div className="payment__section__right__info__description">Total Price of files</div>
              <div className="payment__section__right__info__value">
                {totalPriceOfSelectedFiles ? `$${(totalPriceOfSelectedFiles / 100).toFixed(2)}` : "-"}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="payment__actions">
        {transactionsByFiles.length > 0 ? (
          <ALButton disabled={!selectedNbFiles || isExportingFiles} onClick={onExportFile}>
            {isExportingFiles ? <ALLoading alt /> : `EXPORT ${selectedNbFiles} FILES`}
          </ALButton>
        ) : null}
      </div>
    </ALContainer>
  );
};

function getURLHash() {
  return (window.location.hash && window.location.hash.substring(1)) || null;
}
function setURLHash(token) {
  window.location.hash = `#${token}`;
}

const Payment = (props) => {
  const [pageTab, setPageTab] = useState(getURLHash() || "export");

  return (
    <div className="payment">
      <React.StrictMode>
        <ALSwitch
          options={[
            { key: "export", name: "EXPORT" },
            { key: "import", name: "IMPORT" },
          ]}
          value={pageTab}
          onChange={(key) => {
            setPageTab(key);
            setURLHash(key);
          }}
        />
        {pageTab === "export" ? <PaymentExport {...props} /> : null}
        {pageTab === "import" ? <PaymentImport {...props} /> : null}
      </React.StrictMode>
    </div>
  );
};

export default Payment;
