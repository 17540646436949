import React from "react";
import "./dropdown.css";

class ALDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedKey: this.props.value || "",
      useKey: this.props.useKey || "key",
      useValue: this.props.useValue || "value",
      hoveringIndex: -1,
    };
    this.select = this.select.bind(this);
    this.handleOnClickButton = this.onClickButton.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  select(key) {
    let nextState = { isOpen: false };
    // Keep unmanaged dropdowns working
    if (this.props.value === undefined) {
      nextState["selectedKey"] = key;
    }
    this.setState(nextState, () => this.props.onChange && this.props.onChange(key));
  }
  onClickButton(e) {
    if (this.props.disabled) return e.target.blur();
    e.target.focus();
    this.setState({ isOpen: !this.state.isOpen });
  }
  handleOnBlur(e) {
    // Firefox onBlur issue
    if (e.nativeEvent.explicitOriginalTarget && e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
      return;
    }
    if (this.state.isOpen) {
      setTimeout(() => this.setState({ isOpen: false }), 200);
    }
  }
  handleKeyDown(e) {
    const up = 38;
    const down = 40;
    const enter = 13;
    const tab = 9;
    if (this.state.isOpen && e.keyCode == tab) return setTimeout(() => this.setState({ isOpen: false }), 100);
    if (!this.state.isOpen && (e.keyCode == enter || e.keyCode == down || e.keyCode == up))
      return setTimeout(() => this.setState({ isOpen: true }), 100);
    if (e.keyCode != up && e.keyCode != down && e.keyCode != enter) return;
    e.preventDefault();
    e.stopPropagation();
    if (e.keyCode === up && this.state.hoveringIndex > 0) {
      return this.setState({ hoveringIndex: this.state.hoveringIndex - 1 });
    }
    if (e.keyCode === down && this.state.hoveringIndex < this.props.options.length - 1) {
      return this.setState({ hoveringIndex: this.state.hoveringIndex + 1 });
    }
    if (e.keyCode === enter && this.state.hoveringIndex >= 0) {
      let elem = this.props.options[this.state.hoveringIndex];
      this.select(typeof elem === "string" ? elem : elem[this.state.useKey]);
    }
    console.log("Got key:", e.keyCode);
    return;
  }

  componentDidUpdate(prevProps, _prevState) {
    if (
      prevProps.value != this.props.value ||
      (this.props.value && this.props.value != this.state.selectedKey) ||
      prevProps.useKey != this.props.useKey ||
      prevProps.useValue != this.props.useValue
    ) {
      this.setState({
        selectedKey: this.props.value || "",
        useKey: this.props.useKey || "key",
        useValue: this.props.useValue || "value",
      });
    }
  }

  render() {
    let value =
      typeof this.props.options[0] === "string"
        ? this.state.selectedKey
        : this.props.options.find((opt) => opt[this.state.useKey] === this.state.selectedKey);
    if (typeof this.props.options[0] !== "string") {
      value = value ? value[this.state.useValue] : this.state.selectedKey;
    }
    const options =
      typeof this.props.options[0] === "string"
        ? this.props.options.filter((option) => this.props.disableOptionsFiltering || option !== this.state.selectedKey)
        : this.props.options.filter((option) => this.props.disableOptionsFiltering || option[this.state.useKey] !== this.state.selectedKey);
    return (
      <div className={`dropdown_container ${(this.props.style && this.props.style["dropdown_container"]) || ""}`}>
        <label
          className={`dropdown_container__selected_value ${(this.props.style && this.props.style["dropdown_container__selected_value"]) || ""} ${
            this.props.disabled ? "dropdown_container__selected_value--disable" : ""
          } ${this.state.isOpen ? "dropdown_container__selected_value--icon_open" : "dropdown_container__selected_value--icon"}`}>
          <div className="dropdown_container__selected_value__input_wrapper">
            <input
              type="button"
              value={" "}
              className={`dropdown_container__selected_value__input`}
              disabled={!!this.props.disabled}
              onClick={this.handleOnClickButton}
              onBlur={this.handleOnBlur}
              onKeyDown={this.handleKeyDown}
            />
            <div className="dropdown_container__selected_value__content">{value}</div>
          </div>
          {this.props.selectionChips?.length
            ? this.props.selectionChips.map((chip, key) => (
                <div className="dropdown_container__selection_chips__chip" key={key} onClick={(e) => e.stopPropagation()}>
                  <label>{typeof chip === "string" ? chip : chip[this.state.useValue]}</label>
                  <span
                    className="material-icons"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.onChipClose(typeof chip === "string" ? chip : chip[this.state.useKey]);
                    }}>
                    close
                  </span>
                </div>
              ))
            : null}
          {/* {this.props.selectionChips?.length  ? (
            <div className="dropdown_container__selection_chips">
              {this.props.selectionChips.map((chip, key) => (
                <div className="dropdown_container__selection_chips__chip" key={key}>
                  <label>{typeof chip === "string" ? chip : chip[this.state.useValue]}</label>
                  <span className="material-icons" onClick={() => this.props.onChipClose(typeof chip === "string" ? chip : chip[this.state.useKey])}>
                    close
                  </span>
                </div>
              ))}
            </div>
          ) : null} */}
        </label>
        <ul
          hidden={!this.state.isOpen}
          className={`dropdown_container__list ${(this.props.style && this.props.style["dropdown_container__list"]) || ""}`}
          onMouseOut={() => this.setState({ hoveringIndex: -1 })}>
          {options.map((elem, i) => (
            <li
              key={i}
              className={`dropdown_container__list__value ${
                (this.props.style && this.props.style["dropdown_container__list__value"]) || ""
              } dropdown_container__list__value ${
                this.state.hoveringIndex === i
                  ? `dropdown_container__list__value--hover ${(this.props.style && this.props.style["dropdown_container__list__value"]) || ""}`
                  : ""
              }`}
              // className={(this.props.style && this.props.style["dropdown_container__list__value"]) || ("dropdown_container__list__value" + (this.state.hoveringIndex === i ? " dropdown_container__list__value--hover" : "") )}
              onMouseDown={() => this.select(typeof elem === "string" ? elem : elem[this.state.useKey])}
              onMouseOver={() => this.setState({ hoveringIndex: i })}>
              {typeof elem === "string" ? elem : elem[this.state.useValue]}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default ALDropdown;
