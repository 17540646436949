import React from "react";
import axios from "axios";
import moment from "moment";
import iconInsta from "../../assets/insta_logo.png";
import iconYouTube from "../../assets/youtube_logo.png";
import "./SearchResults.css";

class SearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingFullData: false,
      isExpandedByIndex: [],
    };
  }
  componentDidMount() {
    this.loadPageFullData();
  }
  componentDidUpdate(prevProps) {
    if (
      !this.state.isLoadingFullData &&
      this.props.influencers.length &&
      (prevProps.influencers.length !== this.props.influencers.length || !this.props.influencers[0].isFull)
    ) {
      this.loadPageFullData();
    }
  }
  async loadPageFullData() {
    if (this.state.isLoadingFullData) return;
    this.setState({ isLoadingFullData: true, isExpandedByIndex: Array(this.props.influencers.length).fill(false) });
    await Promise.all(
      this.props.influencers.map((influencer, index) => {
        if (influencer["isFull"]) return;
        return axios.get("/api/v1/influencers_full?_id=" + influencer._id).then(({ data }) => {
          const influencerFull = data.result[0];
          this.props.loadYTAverageViews(influencerFull);
          this.props.updateInfluencerData(index + (this.props.page - 1) * 10, { ...influencerFull, isFull: true });
        });
      })
    );
    this.setState({ isLoadingFullData: false });
  }
  render() {
    const { influencers, selectedInfluencers } = this.props;
    const { isExpandedByIndex } = this.state;

    return (
      <div className="search-results">
        {influencers.map((influencer, idx) => {
          const { youtube: youtubeInfo, instagram: instagramInfo, deals: dealsInfo } = influencer;
          const hasYouTube = youtubeInfo && youtubeInfo.accounts && youtubeInfo.accounts.length;
          const hasInstagram = instagramInfo && instagramInfo.accounts && instagramInfo.accounts.length;
          const pictureURL =
            (hasYouTube && typeof youtubeInfo.accounts[0] === "object" && youtubeInfo.accounts[0].pictureURL) ||
            (hasInstagram && typeof instagramInfo.accounts[0] === "object" && instagramInfo.accounts[0].pictureURL) ||
            null;
          const name =
            (hasYouTube && typeof youtubeInfo.accounts[0] === "string" && youtubeInfo.accounts[0]) ||
            (hasYouTube && youtubeInfo.accounts[0].title) ||
            (hasInstagram && typeof instagramInfo.accounts[0] === "string" && instagramInfo.accounts[0]) ||
            (hasInstagram && instagramInfo.accounts[0].fullName);
          const handleYoutube =
            (hasYouTube && typeof youtubeInfo.accounts[0] === "object" && youtubeInfo.accounts[0].channelId) || youtubeInfo.accounts[0];
          const handleInstagram =
            (hasInstagram && typeof instagramInfo.accounts[0] === "object" && instagramInfo.accounts[0].profileId) || instagramInfo.accounts[0];
          return (
            <div key={idx} className="search-results__influencer">
              <div className="search-results__influencer-head">
                <div className="search-results__influencer-head__left">
                  <div className="search-results__influencer__checkbox-container">
                    <input
                      className="search-results__influencer__checkbox"
                      type="checkbox"
                      checked={selectedInfluencers.has(influencer._id)}
                      onClick={() => this.props.select(influencer._id)}
                    />
                  </div>
                  <div className="search-results__influencer__image">
                    {pictureURL ? (
                      <img
                        className="search-results__influencer__image-content"
                        src={pictureURL}
                        alt="profile"
                        crossOrigin="anonymous"
                        referrerPolicy="no-referrer"
                      />
                    ) : null}
                  </div>
                  <div className="search-results__influencer__name" title={influencer["_id"]}>
                    {name}
                  </div>
                  <div className="search-results__influencer__section">
                    <div className="search-results__influencer__section__title"># of Deals</div>
                    <div className="search-results__influencer__section__content">{influencer.deals.length}</div>
                  </div>
                  <div className="search-results__influencer__section">
                    <div className="search-results__influencer__section__title">Last Price</div>
                    <div className="search-results__influencer__section__content">
                      ${influencer.deals && influencer.deals.length > 0 ? influencer.deals[influencer.deals.length - 1].price || "0" : "-"}
                    </div>
                  </div>
                  {hasYouTube ? (
                    <>
                      <div className="search-results__influencer__icon-container">
                        <a
                          className="search-results__influencer__icon-container__link"
                          href={`https://www.youtube.com/channel/${handleYoutube}/videos`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <img className="search-results__influencer__youtube-icon" src={iconYouTube} />
                        </a>
                      </div>
                      {typeof youtubeInfo.accounts[0] === "object" ? (
                        <>
                          <div className="search-results__influencer__section">
                            <div className="search-results__influencer__section__title">Average views</div>
                            <div className="search-results__influencer__section__content" title={"average (number of videos averaged)"}>
                              {(() => {
                                const channel = youtubeInfo.accounts[0];
                                if (channel["latestVideos"] && channel["latestVideos"]["list"].length) {
                                  if (channel["latestVideos"]["averageViews"]) {
                                    return `${channel["latestVideos"]["averageViews"]} (${channel["latestVideos"]["averageViewsCount"]})`;
                                  }
                                  return "...";
                                }
                                return "-";
                              })()}
                            </div>
                          </div>
                          <div className="search-results__influencer__section">
                            <div className="search-results__influencer__section__title">Subscriber</div>
                            <div className="search-results__influencer__section__content">
                              {(youtubeInfo.accounts[0]["stats"] && youtubeInfo.accounts[0]["stats"]["subscribers"]) || "-"}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {hasInstagram ? (
                    <>
                      <div className="search-results__influencer__icon-container">
                        <a
                          className="search-results__influencer__icon-container__link"
                          href={`https://instagram.com/${handleInstagram}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <img className="search-results__influencer__instagram-icon" src={iconInsta} />
                        </a>
                      </div>
                      {typeof instagramInfo.accounts[0] === "object" ? (
                        <>
                          <div className="search-results__influencer__section" hidden={true}>
                            <div className="search-results__influencer__section__title">Engagement</div>
                            <div className="search-results__influencer__section__content">-</div>
                          </div>
                          <div className="search-results__influencer__section">
                            <div className="search-results__influencer__section__title">Followers</div>
                            <div className="search-results__influencer__section__content">
                              {(instagramInfo.accounts[0]["stats"] && instagramInfo.accounts[0]["stats"]["followers"]) || "-"}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
                <div className="search-results__influencer-head__right">
                  <span
                    className="material-icons search-results__influencer-head__expand"
                    onClick={() => {
                      let newIsExpandedByIndex = [...isExpandedByIndex];
                      newIsExpandedByIndex[idx] = !newIsExpandedByIndex[idx];
                      this.setState({ isExpandedByIndex: newIsExpandedByIndex });
                    }}>
                    {isExpandedByIndex[idx] ? "expand_less" : "expand_more"}
                  </span>
                </div>
              </div>
              <div className={`search-results__influencer-body${!isExpandedByIndex[idx] ? " search-results__influencer-body--collapsed" : ""}`}>
                {(() => {
                  const dealsJSX = dealsInfo
                    .filter((deal) => !["published", "declined_outreach"].includes(deal.status))
                    .map((deal, idx) => (
                      <div className="search-results__influencer-body__active-deals-line" key={idx}>
                        <div className="search-results__influencer-body__active-deals-line__name">{deal.name || `Deal n*${idx + 1}`}</div>
                        <div className="search-results__influencer-body__active-deals-line__group">
                          <div className="search-results__influencer-body__active-deals-line__date">
                            {moment(deal.creation_date).format("MM/DD/YYYY") || "-"}
                          </div>
                          <div className="search-results__influencer-body__active-deals-line__am">by {deal.am || "-"}</div>
                        </div>
                        <div className="search-results__influencer-body__active-deals-line__group">
                          <div className="search-results__influencer-body__active-deals-line__final-price-label">Final Price</div>
                          <div className="search-results__influencer-body__active-deals-line__final-price">
                            ${typeof deal.price === "number" ? deal.price : "-"}
                          </div>
                        </div>
                        <div className="search-results__influencer-body__active-deals-line__group">
                          <div className="search-results__influencer-body__active-deals-line__deal-type-label">Deal Type</div>
                          <div className="search-results__influencer-body__active-deals-line__deal-type">
                            {deal.content
                              ? deal.content.length === 1
                                ? `${deal.content[0].platform} ${deal.content[0].type}`
                                : `${deal.content.length} content(s)`
                              : "-"}
                          </div>
                        </div>
                        <div className="search-results__influencer-body__active-deals-line__group">
                          <div className="search-results__influencer-body__active-deals-line__status-label">Status:</div>
                          <div className="search-results__influencer-body__active-deals-line__status">{deal.status || "-"}</div>
                        </div>
                      </div>
                    ));
                  return dealsJSX.length > 0 ? dealsJSX : "No active deals";
                })()}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SearchResults;
