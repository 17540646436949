import React from "react";
import "./loading.css";
import loadingImg from "assets/loading.png";

class ALLoading extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="al_loading">
        {this.props.alt ? <img className="al_loading_icon al_loading_icon--reverse al_loading_icon--fast" src={loadingImg} /> : <i className="al_loading_icon material-icons">loop</i>}
        {this.props.text ? (
          <div className="al_loading_text">
            {this.props.text.split("\n").map((txt, idx) => (
              <p key={idx} className="al_loading_text_line">
                {txt}
              </p>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

export default ALLoading;
