import React from "react";
import axios from "axios";
import moment from "moment";
import { ALLoading } from "../ALComponents";
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import chunk from 'lodash/chunk';
import { formatISO, add } from 'date-fns';

import NotificationManager from "../al_components/notification/NotificationManager";
import iconInsta from '../../../src/assets/insta_logo.png';
import iconYouTube from '../../../src/assets/youtube_logo.png';
import iconTikTok from '../../../src/assets/tiktok_logo.png';
import iconPodcast from '../../../src/assets/podcast_logo.png';
import "./QuickOutreach.css";

class QuickOutreach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isLoadingInfluencers: true,
      tmpDeals: [], // temporary structure used when incrementally fetching the data
      allDeals: [], // list of all deals 
      displayedDeals: [], // list of displayed deals (filtered by search and/or ordered)

      order: {
        key: null,
        value: null,
      },

      pagination: {
        limit: 100,
        page: 0,
        isDone: false,
      },

      newGiftNote: {
        deal_id: null,
        content_note: null,
      },

      searchValue: '',
    }
    this._orderDealsBy = this._orderDealsBy.bind(this);
    this._updateDatesDeal = this._updateDatesDeal.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.pagination.isDone !== this.state.pagination.isDone) {
      let allDeals = [];
      let transactionIds = [];

      // flatten all deal so we can filter them
      this.state.tmpDeals.forEach((influencer, i) => {
        influencer.deals.forEach((deal, j) => {
          if (!["published", "declined_outreach"].includes(deal.status) && deal.am == this.state.currentUser) {

            // get deal transaction ids
            if (deal.payment_transactions.length > 0) {
              transactionIds = [
                ...transactionIds, 
                ...deal.payment_transactions.map((t) => t.id || t._id)
              ];
            }

            allDeals.push({
              "influencer_id": influencer["_id"],
              "influencer_channelId": this.getPlatformAccountId(influencer.youtube?.accounts ?? [], "youtube"),
              "influencer_handle": this.getPlatformAccountId(influencer.instagram?.accounts ?? [], "instagram"),
              "influencer_tiktok": this.getPlatformAccountId(influencer.tiktok?.accounts ?? [], "tikTok"),
              "influencer_podcast": this.getPlatformAccountId(influencer.podcast?.accounts ?? [], "podcast"),
              "influencer_email": (influencer["emails"].length > 0) ? (influencer["emails"][0].toLowerCase()) : null,
              "deal_name": (influencer.deals.length > 1 && deal["name"] == "") ? (`Deal n°${j+1}`) : ((deal["name"] != "") ? deal["name"] : ""),
              "deal_id": deal["_id"],
              "deal_am": deal["am"],
              "deal_date_follow_up": deal["date_follow_up"],
              "deal_date_last_outreach": deal["date_last_outreach"],
              "deal_status": deal["status"],
              "price": deal["price"],
              "payment_percentage": 0, // Will be set when transactions are fetched
              "payment_status": null // Will be set when transactions are fetched
            });
          }
        });
      });

      let allTransactions = [];

      // Get all transactions, split in chunks of 10
      const chunkedTransactionIds = chunk(transactionIds, 10);

      // Query the transactions sequentially so we don't overload the db
      for (const ids of chunkedTransactionIds) {
        const results = await axios.get("/api/v1/transactions", {
          params: {"_id": { $in: ids }}
        });
        allTransactions = [...allTransactions, ...results.data.result];
      }
        
      // Once all transactions are retrieved, update the deals
      allDeals = allDeals.map(deal => {
        const dealTransactions = allTransactions.filter(t => t.deal_id === deal.deal_id);
        if (dealTransactions.length > 0) {
          let paymentStatus = null;
          let paidPrice = 0;
          let latestTransactionDate = null;

          dealTransactions.forEach(transaction => {
            const { amount, last_modification_date, status } = transaction;
            if (!paymentStatus || latestTransactionDate < last_modification_date) {
              paymentStatus = status; // get the status of the latest transaction
              latestTransactionDate = last_modification_date;
            }
            
            paidPrice += amount / 100; // amount value comes in cents
          });

          deal = {
            ...deal,
            payment_percentage: Math.round(paidPrice / deal["price"] * 100),
            payment_status: paymentStatus
          }
        }
        return deal;
      });

      allDeals = orderBy(allDeals, ["deal_date_follow_up"], ["asc"]);
      
      this.setState({
        allDeals: allDeals,
        displayedDeals: allDeals, // all deals are initially visible
        order: {
          key: "deal_date_follow_up",
          value: "asc",
        },
        isLoadingInfluencers: false,
      });
    }

    if (prevState.searchValue !== this.state.searchValue) {
      let newDisplayedDeals;

      // when there's no search term, show all the deals
      if (this.state.searchValue.length === 0) {
        newDisplayedDeals = [...this.state.allDeals];
      } else {
        const { searchValue, allDeals } = this.state;
        let resSearch = filter(allDeals, function(d) {
          if ((d["influencer_channelId"] || "").includes(searchValue) || (d["influencer_handle"] || "").toLowerCase().includes(searchValue.toLocaleLowerCase()) || (d["influencer_email"] || "").toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || (d["deal_status"] || "").toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
            return d;
          }
        });
        if (resSearch.length > 0) {
          newDisplayedDeals = resSearch;
        }
      }
      if (newDisplayedDeals) {
        newDisplayedDeals = orderBy(newDisplayedDeals, [this.state.order.key], [this.state.order.value]);
        this.setState({ displayedDeals: newDisplayedDeals });
      }
    }
  }

  getPlatformAccountId(accounts, platform) {
    if (!accounts || accounts.length === 0) {
      return null;
    }

    const platformKey = {
      youtube: 'channelId',
      instagram: 'profileId',
      tikTok: 'profileId',
    }

    if (platform === 'podcast') {
      return accounts[0].broadcasters[0]?.url;
    }

    return typeof accounts[0] === "string" 
      ? accounts[0]
      : accounts[0][platformKey[platform]];
  }

  _orderDealsBy(key) {
    const { order, displayedDeals } = this.state;
    let orderValue = null;
    if (order["key"] === key) {
      orderValue = (order["value"] === "asc") ? "desc" : "asc";
    } else {
      orderValue = "asc";
    }

    let orderKeys = [key];
    let orderDirections = [orderValue];

    // when sorting by payment percentage, show "processing" status first for equal percentage values
    if (key === "payment_percentage") {
      orderKeys.push((d) => {
        const rank = {
          "processing": 1,
          "requested": 2,
          "completed": 3,
        };
        return rank[d.payment_status];
      });
    }
    const orderedList = orderBy(displayedDeals, orderKeys, orderDirections);

    this.setState({
      displayedDeals: orderedList,
      order: {
        key: key,
        value: orderValue,
      }
    });
  }

  _updateDatesDeal(dealId) {
    let dealUpdate = {
      date_last_outreach: formatISO(new Date()),
      date_follow_up: formatISO(add(new Date(), { days: 3 })),
    }

    return axios.put(`/api/v1/deals/${dealId}`, dealUpdate)
      .then((res) => {
        if (res.status === 200) {
          axios.post("/api/v1/event", {
            type: "UPDATE_DATE_OUTREACH_DEAL",
            user: this.props.user.email,
            sample: { object: dealId },
          }).catch((err) => console.log(err)||alert("Failed to fire event UPDATE_DATE_OUTREACH_DEAL"));

          // update Dates on displayedDeals + allDeals
          let copyDisplayedDeals = [];
          if (this.state.displayedDeals && this.state.displayedDeals.length > 0) {
            var indexDisplayedDeal = this.state.displayedDeals.findIndex(function(d) {
              return d["deal_id"] === dealId;
            });

            copyDisplayedDeals = [...this.state.displayedDeals];
            copyDisplayedDeals[indexDisplayedDeal]["deal_date_last_outreach"] = dealUpdate["date_last_outreach"];
            copyDisplayedDeals[indexDisplayedDeal]["deal_date_follow_up"] = dealUpdate["date_follow_up"];
          }

          var indexAllDeal = this.state.allDeals.findIndex(function(d) {
            return d["deal_id"] === dealId
          });

          let copyAll = [...this.state.allDeals];
          copyAll[indexAllDeal]["deal_date_last_outreach"] = dealUpdate["date_last_outreach"];
          copyAll[indexAllDeal]["deal_date_follow_up"] = dealUpdate["date_follow_up"];

          this.setState({
            displayedDeals: copyDisplayedDeals,
            allDeals: copyAll
          });
        }
        NotificationManager.success('Updated', `Successfully updated deal`, 1500);
      })
      .catch(err => {
        console.error(err);
        NotificationManager.error('Error', 'Server failed to update Deal, please try again later and send the page log to the tech team', 6000);
      });
  }

  loadAllDeals() {
    const _this = this;

    let currentUser = this.props.user.email;

    const { pagination, tmpDeals } = _this.state;
    axios.get('/api/v1/influencers_full_by_deal?', {
      params: {
        "deal_am": '"' + currentUser + '"',
        "deal_status": '{"$in":["negociation", "done", "details", "hold", "call", "lead", "created", "adam", "adam_yes", "adam_no", "shipped", "to_ship", "in_transit", "shipment_received"]}',
        "dealsort_date_follow_up": -1,
        "limit": pagination.limit,
        "page": pagination.page
      }
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.result.length === 0) {
          _this.setState({
            currentUser: currentUser,
            pagination: {
              ...pagination,
              isDone: true,
            }
          });
          return;
        } else {
          _this.setState({
            tmpDeals: [...tmpDeals, ...res.data.result],
            pagination: {
              ...pagination,
              page: pagination.page + 1,
            }
          });
          return this.loadAllDeals();
        }
      }
    });
  }

  componentDidMount() {
    this.loadAllDeals();
  }

  render() {
    const { isLoadingInfluencers, allDeals, order, searchValue, displayedDeals } = this.state;
    return (
      <div>
        {(allDeals.length > 0) ? (
          <div className="quick_outreach_infoheader">
            <div className="quick_outreach_infoheader_search">
              <p className="quick_outreach_infoheader_search--title">Search</p>
              <input className="quick_outreach_infoheader_search--input" type="text" value={searchValue || ''} placeholder="By Channel Id, Handle, E-mail, Status" onChange={(e) => this.setState({ searchValue: e.target.value })} />
              {(searchValue.length > 0) &&
                <span className="quick_outreach_infoheader_search--search_off material-icons" onClick={(e) => this.setState({ searchValue: "" })}>search_off</span>
              }
            </div>
            {(allDeals) ? (
              <div className="quick_outreach_infoheader_count_deal">
                {(searchValue.length > 0) ? (<p>{displayedDeals.length} search result</p>) : (null)}
                <p>{allDeals.length} active deals</p>
              </div>
            ) : (null)}
          </div>
        ) : (null)}

        {(isLoadingInfluencers) ? (
          <ALLoading text={`We are loading you influencers ${this.props.user.firstName}, please wait (page ${this.state.pagination.page})`} />
        ) : (
          (allDeals && allDeals.length === 0) ? (
            <p>You don't have any influencers to outreach. If you think this is an error, please contact @Francois or @Quentin</p>
          ) : (
            <div className="quick_outreach">
              <div className="quick_outreach_header">
                <p className="quick_outreach-status" data-order="deal_status" onClick={(e) => { this._orderDealsBy(e.target.getAttribute('data-order')) }}>
                  Status
                  {(order["key"] === "deal_status") && (
                    <span className="quick_outreach_order__icon material-icons" data-order="deal_status">
                      {order["value"] === "asc" ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                    </span>
                  )}
                </p>
                <p className="quick_outreach-lastoutreach" data-order="deal_date_last_outreach" onClick={(e) => { this._orderDealsBy(e.target.getAttribute('data-order')) }}>
                  Last Outreach
                  {(order["key"] === "deal_date_last_outreach") && (
                    <span className="quick_outreach_order__icon material-icons" data-order="deal_date_last_outreach">
                      {order["value"] === "asc" ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                    </span>
                  )}
                </p>
                <p className="quick_outreach-followup" data-order="deal_date_follow_up" onClick={(e) => { this._orderDealsBy(e.target.getAttribute('data-order')) }}>
                  Follow-up
                  {(order["key"] === "deal_date_follow_up") && (
                    <span className="quick_outreach_order__icon material-icons" data-order="deal_date_follow_up">
                      {order["value"] === "asc" ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                    </span>
                  )}
                </p>
                <p className="quick_outreach-payment" data-order="payment_percentage" onClick={(e) => { this._orderDealsBy(e.target.getAttribute('data-order')) }}>
                  Payment
                  {(order["key"] === "payment_percentage") && (
                    <span className="quick_outreach_order__icon material-icons" data-order="payment_percentage">
                      {order["value"] === "asc" ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                    </span>
                  )}
                </p>
                <p className="quick_outreach-channel">Platforms</p>
                <p className="quick_outreach-email">Email</p>
                <p className="quick_outreach-actions">Actions</p>
                <p className="quick_outreach-view_outreach">&nbsp;</p>
              </div>
              {displayedDeals.map((deal) => {
                return (
                  <div className="quick_outreach_deal" key={deal.deal_id}>
                    <div className="quick_outreach-status">
                      <p className="quick_outreach-status-name">
                        {deal["deal_status"] ? deal["deal_status"] : ""}
                      </p>
                      {deal["deal_name"] && 
                       <p className="quick_outreach_deal-name">{deal["deal_name"]}</p>
                      }
                    </div>
                    <p className="quick_outreach-lastoutreach">{moment(deal["deal_date_last_outreach"]).format("YYYY-MM-DD")}</p>
                    <p className="quick_outreach-followup">{moment(deal["deal_date_follow_up"]).format("YYYY-MM-DD")}</p>
                    <div className="quick_outreach-payment">
                      {`${deal.payment_percentage}%`}
                      {deal.payment_status &&
                        <span className={`quick_outreach-payment-status--${deal.payment_status} material-icons`} title={deal.payment_status}>circle</span>
                      }
                    </div>
                    <div className="quick_outreach-channel">
                      {deal.influencer_channelId 
                        ? <a href={`https://www.youtube.com/channel/${deal.influencer_channelId}/videos`} target="_blank" rel="noopener noreferrer">
                            <img className="" src={iconYouTube} alt="youtube" />
                          </a>
                        : <span></span>
                      }
                      {deal.influencer_tiktok 
                        ? <a href={`https://www.tiktok.com/@${deal["influencer_tiktok"]}`} target="_blank" rel="noopener noreferrer">
                            <img className="" src={iconTikTok} alt="TikTok" />
                          </a>
                        : <span></span>
                      }
                      {deal.influencer_handle
                        ? <a href={`https://www.instagram.com/${deal.influencer_handle}`} target="_blank" rel="noopener noreferrer">
                          <img className="" src={iconInsta} alt="instagram" />
                          </a>
                        : <span></span>
                       }
                      {deal.influencer_podcast 
                        ? <a href={deal.influencer_podcast} target="_blank" rel="noopener noreferrer">
                            <img className="" src={iconPodcast} alt="podcast" />
                          </a>
                        : <span></span>
                      }
                    </div>
                    <div className="quick_outreach-email">
                      {(deal["influencer_email"]) ? (
                        <>
                          <a href={`https://mail.google.com/mail/?tab=mm#search/from%3A${encodeURIComponent(deal["influencer_email"])}`} target="_blank" rel="noopener noreferrer">
                            {deal["influencer_email"]}
                          </a>
                          <span className="quick_outreach-email_copy-email material-icons" onClick={() => {
                            navigator.clipboard.writeText(deal["influencer_email"]);
                            NotificationManager.info('Copied!', null, 1000);
                          }}>content_copy</span>
                        </>
                      ) : ('No email')}
                    </div>
                    <div className="quick_outreach-actions">
                      <button onClick={(e) => this._updateDatesDeal(deal["deal_id"])}>UPDATE</button>
                    </div>
                    <a className="quick_outreach-view_outreach" href={`/a/dashboard/outreach?type=id&value=${deal["influencer_id"]}`} target="_blank" rel="noopener noreferrer">
                      <span className="material-icons">launch</span>
                    </a>
                  </div>
                )
              })}
            </div>
          )
        )}
      </div>
    )
  }
}

export default QuickOutreach;