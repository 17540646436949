import React from "react";
import "./InstagramPost.css";
import moment from 'moment';

import instaCarrousel from '../../../src/assets/insta_carrousel.png';
import instaVideo from '../../../src/assets/insta_video.png';

// import instaComment from '../../../src/assets/insta_comment.png';
// import instaLike from '../../../src/assets/insta_like.png';

class InstagramPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: this.props.post,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.post != this.state.post) {
      this.setState({ post: this.props.post });
    }
  }
  render() {
    const { post } = this.state;
    return (
      <div className="q_instagram__posts_post">
        <a className="q_instagram__posts_post_image" href={`https://www.instagram.com/p/${post.postId}/`} target="_blank" rel="noopener noreferrer">
          <img className="q_instagram__posts_post_image_picture" alt="instagram post" src={post.contentURLs[0]} />
          {(post.type == "sidecar") ? (
            <img className="q_instagram__posts_post_image--type" alt="sidecar" src={instaCarrousel} />
          ) : (
            (post.type == "video") ? (
              <img className="q_instagram__posts_post_image--type" alt="video" src={instaVideo} />
            ) : (null)
          )}
          <div className="q_instagram__posts_post_image_stats">
            <div className="q_instagram__posts_post_image_stats_container">
              <span className="material-icons stats_container--likes">favorite</span>
              {post.stats.likes}
            </div>
            <div className="q_instagram__posts_post_image_stats_container">
              {post.stats.comments}
              <span className="material-icons stats_container--comments">mode_comment</span>
            </div>
          </div>
        </a>
        <div className="q_instagram__posts_post_details">
          <p className="q_instagram__posts_post_details_description">{post.description}</p>
          <p className="q_instagram__posts_post_details_ago">{moment(post.date, "YYYY-MM-DDTHH:mm:ss").from(moment())}</p>
        </div>
      </div>
    );
  }
}

export default InstagramPost;

