import React from "react";
import Loading from "./Loading";
import axios from 'axios';
import "./ScrapperWorking.css";

class ScrapperWorking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfChannels: 0,
      numberOfChannelsXml: 0,
      channelsToScrap: 0,
      isDisable: false,
    };
    this.forceChannelScrapper = this.forceChannelScrapper.bind(this);
  }

  fetchData = () => {
    axios({
      method: 'GET',
      url: '/api/v1/scrapped_length',
    }).then((r) => {
      this.setState({ numberOfChannelsXml: r.data.count || 0});
    });
  }

  componentDidMount() {
    axios.get("/auth/current_user").then(res => {
      if (res.data.role === "super-user") {
        this.fetchData();
      }
    });
    this.getChannelsToSend();

    // this.interval = setInterval(() => {
    //   this.fetchData();
    // }, 60000);

  }
  componentWillUnmount () {
    // It's necessary to do this otherwise the interval
    // will be executed even if the component is not present anymore. 
    clearInterval(this.interval);
  }

  getChannelsToSend() {
    axios({
      method: 'GET',
      url: '/api/v1/channels?key=status&value=toScrap',
    }).then((r) => {
      if (r.data.channel) {
        let tmpBool = false;
        let lengthChannels = r.data.channel.length;
        if (r.data.channel === 'no channels') {
          lengthChannels = 0;
          tmpBool = true;
        }
        // 'no channels'
        this.setState({ channelsToScrap: lengthChannels, isDisable: tmpBool });
      }

    });
  }

  forceChannelScrapper() {
    this.setState({ isDisable: true });
    // if (this.state.numberOfChannels > 0) {
    //  alert('CONTACT @FRANCOIS (ERR 1234)');
    // } else {
      axios({
        method: 'GET',
        url: '/api/v1/scrap_email',
      }).then((r) => {
        console.log(r);
        axios({
          method: 'POST',
          url: '/api/v1/scrap',
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            channels: r.data.channel
          }
        }).then((r) => {
          this.getChannelsToSend();
          this.setState({ isDisable: false });
        })
        .catch((err) => {
          alert('CONTACT @FRANCOIS (ERR 1233): ' + err);
          // console.log(err);
        });
      })
      .catch((err) => {
        alert('CONTACT @FRANCOIS (ERR 1236): ' + err);
        // console.log(err);
      })
    // }
  }

  render() {
    const { numberOfChannelsXml, numberOfChannels, channelsToScrap, isDisable} = this.state;
    return (
      <div className="scrapper-working">
        {(numberOfChannelsXml > 0) ?
          <p className="scrapper-working__xml">
            <span>{numberOfChannelsXml} channels waiting to be qualified (XML)</span>
          </p>
        :
        null }
        {(numberOfChannels > 0) ?
          <p className="scrapper-working__processing" style={{marginBottom: '0px'}}>
            <i className="material-icons scrapper-working--loading">autorenew</i>
            <span>{numberOfChannels} channels on AWS</span>
          </p>
        :
        null }
        {(numberOfChannels === 0) ?
          <div className="scrapper-working__add">
            <span>{channelsToScrap} channels to add</span>
            <button className="mdl-button mdl-js-button mdl-button--fab mdl-button--colored" disabled={isDisable} onClick={this.forceChannelScrapper}>
              <i className="material-icons">add</i>
            </button>
          </div>
        : null }
      </div>
    );
  }
}
export default ScrapperWorking;