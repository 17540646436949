import React from "react";
import axios from "axios";
import "./InfluencerLogin.css";

class InfluencerLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'username': '',
            'password': '',
            'error': ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log("Submit login");
        axios.post('/auth/influencer', this.state)
        .then(res => {
            console.log(res);
            this.props.refresh();
        })
        .catch(err => {
            console.log("received error:", err);
            this.setState({ "error": "The username or password is incorrect" });
        });
    }

    render() {
        const {  username, password, error } = this.state;
        return (
            <form className="influencer_login" onSubmit={this.handleSubmit}>
                {(error) ? (<p className="influencer_login--error">{error}</p>) : (null)}
                <h2 className={`influencer_login--title ${(error) ? ('influencer_login--title--error') : (null)}`}>Username</h2>
                <input className={`influencer_login--input ${(error) ? ('influencer_login--input--error') : (null)}`} type="text" value={username} onChange={(e) => this.setState({'username': e.target.value})} required/>
                <h2 className={`influencer_login--title ${(error) ? ('influencer_login--title--error') : (null)}`}>Password</h2>
                <input className={`influencer_login--input ${(error) ? ('influencer_login--input--error') : (null)}`} type="password" value={password} onChange={(e) => this.setState({'password': e.target.value})} required/>
                <input className="influencer_login--submit" type="submit" value="Login" />
            </form>
        )
    }
}

export default InfluencerLogin;