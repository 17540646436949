import React, { useState, useEffect } from "react";
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { format } from "date-fns";
import axios from "axios";
import PropTypes from 'prop-types';

import NotificationManager from "../../al_components/notification/NotificationManager";
import './CurrentDealName.css';

const CurrentDealName = ({ isOpen, amName, deal, initialDealName, onDealUpdated, toggleDeal }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [dealName, setDealName] = useState(initialDealName);

  useEffect(() => {
    setDealName(initialDealName);
  }, [initialDealName]);

  const updateDeal = () => {
    if (initialDealName === dealName) {
      setIsEditing(false);
      return;
    }

    axios.put(`/api/v1/deals/${deal._id}`, { name: dealName })
      .then(({ data }) => {
        NotificationManager.success(
          "Updated",
          "Successfully updated deal name",
          1500
        );
        setIsEditing(false);
        onDealUpdated({ name: data.result.name });
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Error",
          "Server failed to update deal name, please try again later and send the page log to the tech team",
          6000
        );
      });
  }

  return (
    <div className="outreach_influencer_deal_header">
      {isEditing ? (
        <div className="oi_deal_header_name_edit">
          <input
            className="oi_deal_header_name_edit--name"
            type="text"
            placeholder="Enter deal name"
            onChange={(e) => setDealName(e.target.value)}
            value={dealName}
          />
          <div
            className="oi_deal_header_name_edit--button"
            onClick={() => {
              setIsEditing(false);
              setDealName(initialDealName);
            }}
          >
            <span className="material-icons">close</span>
          </div>
          <div className="oi_deal_header_name_edit--button" onClick={updateDeal}>
            <span className="material-icons">send</span>
          </div>
        </div>
      ) : (
      <div className="oi_deal_header_name">
        <p className="oi_deal_header_name--value">
          {dealName}
        </p>
        <div className="oi_deal_header_name--editing" onClick={() => setIsEditing(true)}>
          <span className="material-icons">create</span>
        </div>
      </div>
      )}

      {!isOpen &&
        <>
          <div className="oi_deal_header_name_date" onClick={toggleDeal}>
          <p className="oi_deal_header_name_date--value">
            {distanceInWordsToNow(new Date(deal.creation_date), { addSuffix: true })}
            {" "}
            ({format(new Date(deal.creation_date), 'MM/dd/yyyy')})
            <span>{`by ${amName ? amName : deal.am}`}</span>
          </p>
          </div>
          <div className="oi_deal_header_name_status">
            <p>{deal.status}</p>
          </div>
        </>
      }

      <div className="oi_deal_header_name_toggle-deal" onClick={toggleDeal}>
        <span className="material-icons">
          {isOpen ? "expand_less" : "expand_more"}
        </span>
      </div>
    </div>
  )
}

CurrentDealName.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  amName: PropTypes.string,
  deal: PropTypes.object.isRequired,
  initialDealName: PropTypes.string.isRequired,
  onDealUpdated: PropTypes.func.isRequired,
  toggleDeal: PropTypes.func.isRequired,
};

export default CurrentDealName;