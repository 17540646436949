import { ALSearch } from "components/ALComponents";
import { useMemo, useState } from "react";
import { getSocialMedia } from "utils";

export function useFilteredDeals(deals, currentAM) {
  const [search, setSearch] = useState("");

  const searchFormatted = search.trim().toLowerCase();
  const filteredDeals = useMemo(
    () =>
      deals
        .map((deal) => {
          const influencer = deal.influencer;
          if (!searchFormatted) {
            return deal;
          }
          const socialMedia = Object.entries(getSocialMedia(influencer))
            .map(([key, value]) => {
              if (!value) {
                return null;
              }
              if (typeof value === "string") {
                return value;
              }
              switch (key) {
                case "youtube":
                  return [value.title, value.channelId, value.username]
                    .filter((v) => v)
                    .join(" ");
                case "instagram":
                  return [value.fullName, value.profileId]
                    .filter((v) => v)
                    .join(" ");
                case "tiktok":
                  return [value.fullName, value.profileId]
                    .filter((v) => v)
                    .join(" ");
                case "podcast":
                  return value.name;
                default:
                  return null;
              }
            })
            .filter((v) => v);
          const isMatchingDeal =
            !searchFormatted ||
            [
              influencer.name || "",
              deal.name || "",
              influencer.emails?.[0] || "",
              ...socialMedia,
            ].some(
              (searchField) =>
                searchField &&
                searchField.trim().toLowerCase().includes(searchFormatted)
            );
          if (isMatchingDeal) {
            return deal;
          }
          return null;
        })
        .filter((deal) => deal && deal.am === currentAM),
    [deals, searchFormatted, currentAM]
  );

  return useMemo(() => [filteredDeals, setSearch], [filteredDeals]);
}

export function FilterSearch({ setSearch }) {
  return <ALSearch placeholder="Search" searchInput={setSearch} />;
}
