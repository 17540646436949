import React from "react";
import axios from 'axios';
import _ from 'lodash';
import Loading from "../Loading";
import "./tags_influencer.css";

class ALTagInfluencer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      tags: null,
      searchValue: '',
      resultSearch: null,
      tagsInfluencer: this.props.tagsInfluencer,
      showMostPopular: this.props.showMostPopular
    };
    this.searchInput = this.searchInput.bind(this);
    this.addTagInfluencer = this.addTagInfluencer.bind(this);
    this.removeTagInfluencer = this.removeTagInfluencer.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tagsInfluencer != this.props.tagsInfluencer || prevProps.showMostPopular != this.props.showMostPopular) {
      this.setState({
        tagsInfluencer: this.props.tagsInfluencer,
        showMostPopular: this.props.showMostPopular
      });
    }
  }

  componentDidMount() {
    axios.get("/api/v1/tags", { headers: { "Cache-Control": "max-age=300" } }).then(res => {
      if (res.status === 200) {
        this.setState({ tags: res.data.tags });
      }
    });
  }

  searchInput(value) {
    let tags = this.state.tags;
    if (value == "") {
      this.setState({ resultSearch: tags, searchValue: "" });
      return;
    }
    value = value.toLocaleLowerCase();
    if (value[value.length - 1] == " ") {
      // check if last value is a "SPACE"
      let listSearchTags = tags.filter((el) => {
        return el["name"].toLocaleLowerCase() === value.trim();
      });
      // if found ONLY ONE element in list add it + reset search
      if (listSearchTags.length === 1) {
        this.addTagInfluencer(listSearchTags[0]["_id"]);
        this.setState({
          searchValue: "",
          resultSearch: this.state.tags,
        });
        return;
      }
    }

    let listSearchTags = tags.filter((el) => {
      return el["name"].toLocaleLowerCase().indexOf(value) > -1;
    });
    this.setState({
      searchValue: value,
      resultSearch: listSearchTags,
    });
  }


  addTagInfluencer(tagId) {
    if (this.state.updating || this.state.tagsInfluencer.some(e => e._id === tagId)) return;
    this.setState({updating: true});
    console.log("addTagInfluencer: " + " - " + tagId + " - " + this.props.idInfluencer);
    axios.post(`/api/v1/influencers/${this.props.idInfluencer}/tags`, { id: tagId })
    .then(() => {
      console.log("tag added!");
      axios.post("/api/v1/event", {
        type: "ADD_TAG_INFLUENCER",
        user: this.props.user.email,
        sample: { object: this.props.idInfluencer, svalue: tagId },
      }).catch((err) => {
        console.log(err);
        alert("Failed to fire event ADD_TAG_INFLUENCER");
        this.setState({updating: false});
      });
      this.setState({tagsInfluencer: [...this.state.tagsInfluencer, this.state.tags.find(tag => tagId === tag._id)], updating: false});
    })
    .catch(err => {
      console.log(err);
      alert("Failed to add tag");
      this.setState({updating: false});
    });
  }

  removeTagInfluencer(tagId) {
    console.log("removeTagInfluencer: " + this.props.idInfluencer);
    // TODO: REMOVE TAG + DECREASE COUNT
    axios.delete(`/api/v1/influencers/${this.props.idInfluencer}/tags/id/${tagId}`)
    .then(() => {
      console.log("tag deleted!");
      axios.post("/api/v1/event", {
        type: "REMOVE_TAG_INFLUENCER",
        user: this.props.user.email,
        sample: { object: this.props.idInfluencer, svalue: tagId },
      }).catch((err) => console.log(err)||alert("Failed to fire event REMOVE_TAG_INFLUENCER"));
      this.setState({tagsInfluencer: this.state.tagsInfluencer.filter(tag => tagId !== tag._id)})
    })
  }

  render() {
    const { tags, resultSearch, showMostPopular, searchValue } = this.state;
    const allowedTags = tags && tags.filter(tag => !this.state.tagsInfluencer.some(influencer_tag => influencer_tag._id === tag._id));
    const allowedResultSearch = resultSearch && resultSearch.filter(tag => !this.state.tagsInfluencer.some(influencer_tag => influencer_tag._id === tag._id));

    return (
      <div className="al_tag_influencer">
        <p className="al_tag_influencer--title">Tags:</p>
        <div className="al_tag_influencer_tags">
          {/* TODO LOOP ON TAGS (+ pass good tag id) */}
          {this.state.tagsInfluencer.map((tag, i) => <div className="al_tag_influencer_tags--tag" key={i}>
            <p>{tag.name}</p>
            <span className="material-icons" onClick={() => {this.removeTagInfluencer(tag._id)}}>close</span>
          </div>)}
        </div>
        {(showMostPopular) ? (
          <p className="al_tag_influencer--title">Most popular:</p>
        ) : (null)}
        {(allowedTags) ? (
          <div>
            {(showMostPopular) ? (
              <div className="al_tag_influencer_popular">
                {allowedTags.map((tag, i) => {
                  if (i < 5) {
                    return (
                      <div
                        className="al_tag_influencer_popular--tag"
                        key={`tag_${i}`}
                        onClick={() => this.addTagInfluencer(tag._id)}
                      >
                        <p>{tag.name}</p>
                      </div>
                    )
                  }
                })}
              </div>
            ) : (null)}
            <div className="al_tag_influencer_search">
              <div className="al_tag_influencer_search_input">
                <input className="al_tag_influencer_search_input--field" onFocus={() => { this.searchInput("") }} value={searchValue} placeholder="Search tag" onChange={(e) => { this.searchInput(e.target.value); }} />
                <span className="al_tag_influencer_search--icon material-icons">search</span>
              </div>
              {(allowedResultSearch) ? (
                <div className="al_tag_influencer_search__result">
                  <div className="al_tag_influencer_search__result--close" onClick={() => { this.setState({ resultSearch: null }); }}>
                    <span className="material-icons">close</span>
                  </div>
                  {(allowedResultSearch.length == 0) ? (
                    <p className="al_tag_influencer_search__result--empty">No result for this search...</p>
                  ) : (
                    allowedResultSearch.map((tag, i) => {
                      return (
                        <div
                          className="al_tag_influencer_popular--tag"
                          key={`search_tag_${i}`}
                          onClick={() => { this.addTagInfluencer(tag._id); } }
                        >
                          <p>{tag.name}</p>
                        </div>
                      );
                    })
                  )}
                </div>
              ) : (
                null
              )}
            </div>
          </div>
        ) : (
          <div className="al_tag_influencer_loading">
            <Loading />
          </div>
        )}

        
      </div>
    );
  }
}
export default ALTagInfluencer;