import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";

import { ALLoading, ErrorBoundaryContent } from "components/ALComponents";

import "./Reply.css";
function Reply({setShowReply, setGeneratedReply, parsedEmails, newEmailContent = null }) {
  const [emailContent, setEmailContent] = useState(newEmailContent ? newEmailContent : "");
  const [runWorkflowAi, setRunWorkflowAi] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setError(""); // Reset error
    setEmailContent(e.target.value);
  };

  const handleSubmit = () => {
    if (emailContent === "") {
      setError("Email content is required");
      return;

    }
    // Implement send or process email logic here
    console.log("Email content submitted:", emailContent);
  };


  async function generateReply() {
    console.log("Test generate reply");
    setIsGenerating(true);
    // Generate task_input
    let task_input = {
      list: []
    };
    parsedEmails.forEach((e) => {
      task_input.list.push({
        email_body: e.textContent.join("\n"),
        email_from: e.email.payload.headers.find((header) => header.name === "From").value,
        email_to: e.email.payload.headers.find((header) => header.name === "To").value,
        email_subject: e.email.payload.headers.find((header) => header.name === 'Subject').value,
        date_send: new Date(parseInt(e.email.internalDate)),
      });
    });
    console.log(task_input, " => task_input");

    let data = JSON.stringify({
      task_input,
      "version": "staging"
    });
    console.log(data, " => data");
    let config = {
      method: "POST",
      url: "https://api.workflowai.com/v1/ana-luisa/tasks/respond-to-email/schemas/2/run",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer eyJhbGciOiJFUzI1NiJ9.eyJ0ZW5hbnQiOiJhbmFsdWlzYS5jb20iLCJvcmdJZCI6Im9yZ18ybzc1Z3BtM1FnVFFKNWxUMERldkVST0xldmsiLCJzdWIiOiJmcmFuY29pc2RAYW5hbHVpc2EuY29tIiwib3JnU2x1ZyI6ImFuYS1sdWlzYSIsInVzZXJJZCI6InVzZXJfMm83NWRvUUdESzdFYVNsRTRsVThTNXRvY2Q5IiwiaWF0IjoxNzMwMjA5OTcxLCJleHAiOjIwNDU1Njk5NzF9.U_Z10UdJtqwEu2V2FZz-VB_5k0NyoeYbvxF9KfEfd_xL8dbr3m0tdhuXX8K5nmgVs95L0SkbYAbMUl3X103yMg"
      },
      data : data
    };

    await axios.request(config)
      .then((response) => {
        console.log("RESPONSE WORKFLOWAI");
        setGeneratedReply(response.data);
        setEmailContent(response.data.task_output.response);
        setRunWorkflowAi(response.data.id);
        setIsGenerating(false);
      })
      .catch((error) => {
        console.log(error);
        setError("Failed to generate reply");
        setIsGenerating(false);
      });
  }


  return (
    <div className="reply_container">
      <div className="reply_container_textarea">
        <textarea
          value={emailContent}
          onChange={handleChange}
          placeholder="Write your email here..."
          rows="10"
          disabled={isGenerating}
        />
        {isGenerating ? <ALLoading text={`Generating email...`} /> : null}
      </div>

      {error && <p className="reply_container_error">{error}</p>}
      {runWorkflowAi && <a href={`https://workflowai.com/ana-luisa/tasks/respond-to-email/2/runs?taskRunId=${runWorkflowAi}`} className="reply_container_feedback" target="_blank" rel="noreferrer">Provide feedback on the output.</a>}
      <div className="reply_container_actions">
        <button className="reply_container_generate" onClick={() => generateReply()}>
          Generate Email
          <span className="material-icons">bolt</span>
        </button>
        {/* <button className="reply_container_send" onClick={() => handleSubmit()}>
          Send Email
          <span className="material-icons">send</span>
        </button> */}
      </div>
    </div>
  );
}

export default (props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag("tool", "Reply");
      scope.setTag("user", props.user.email);
    }}
    fallback={ErrorBoundaryContent}>
    <Reply {...props} />
  </Sentry.ErrorBoundary>
);
