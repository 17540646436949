import csvParse from "csvtojson";

import { CSV_COLUMNS } from "./constants";

export function exportConflicts(influencersProcessing) {
  const allConflictLineObj = influencersProcessing.map((infObject) => infObject.mergeStrategy === "CONFLICT" && infObject.lineObj).filter((e) => e);

  if (!allConflictLineObj.length) return;
  let filename = `add-influencers_conflicts_${allConflictLineObj.length}.csv`;
  let contentType = "text/csv;charset=utf-8;";
  let stringFile = CSV_COLUMNS.join(",") + "\n";
  stringFile = allConflictLineObj.reduce((acc, lineObj) => acc + CSV_COLUMNS.map((key) => lineObj[key] || "").join(",") + "\n", stringFile);

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(stringFile))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement("a");
    a.download = filename;
    a.href = "data:" + contentType + "," + encodeURIComponent(stringFile);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function generateTemplate() {
  let filename = `Template add many influencers.csv`;
  let contentType = "text/csv;charset=utf-8;";
  let stringFile = CSV_COLUMNS.join(",") + "\n";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(stringFile))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement("a");
    a.download = filename;
    a.href = "data:" + contentType + "," + encodeURIComponent(stringFile);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function extractInfluencer(lineObj, tags, campaignTags, user) {
  let age = null;
  if (lineObj["age(exact or min-high)"] && lineObj["age(exact or min-high)"].includes("-")) {
    const [ageLow, ageHigh] = lineObj["age(exact or min-high)"]
      .trim()
      .split("-")
      .map((n) => parseInt(n));
    age = { low: ageLow, high: ageHigh };
  } else if (lineObj["age(exact or min-high)"]) {
    age = { exact: parseInt(lineObj["age(exact or min-high)"]) };
  }
  const destinationToStatus = {
    split: "outreach_split",
    outreach: "qualification_yes",
  };
  const currentDate = new Date().toISOString();
  const influencer = {
    status: destinationToStatus[lineObj["destination(outreach or split)"]],
    creation_date: currentDate,
    notes: [
      {
        date: currentDate,
        content: `create from /a/dashboard/new_influencer by ${user.email}`,
      },
    ],
    emails: lineObj["emails"]
      .split(" ")
      .filter((e) => e)
      .map((e) => e.trim().toLowerCase()),
    country: (lineObj["country"] && lineObj["country"].trim()) || "",
    gender: lineObj["gender(F or M)"] ? { type: lineObj["gender(F or M)"] === "F" ? "Female" : "Male", confidence: 100 } : null,
    age: age,
    youtube: {
      accounts:
        (lineObj["youtube channels id"] &&
          lineObj["youtube channels id"]
            .trim()
            .split(" ")
            .filter((e) => e)) ||
        [],
    },
    instagram: {
      accounts:
        (lineObj["instagram profiles handle"] &&
          lineObj["instagram profiles handle"]
            .trim()
            .split(" ")
            .filter((e) => e)
            .map((handle) => handle.toLowerCase())) ||
        [],
    },
    tiktok: {
      accounts:
        (lineObj["tiktok profiles handle"] &&
          lineObj["tiktok profiles handle"]
            .trim()
            .split(" ")
            .filter((e) => e)
            .map((handle) => handle.toLowerCase())) ||
        [],
    },
    tags:
      (lineObj["tags"] &&
        lineObj["tags"]
          .trim()
          .split(" ")
          .filter((e) => e)
          .map((inputTag) => ({ id: tags.find((tag) => tag.name.toLowerCase() === inputTag.toLowerCase())._id }))) ||
      [],
    campain_tags:
      (lineObj["campaign tags"] &&
        lineObj["campaign tags"]
          .trim()
          .split(" ")
          .filter((e) => e)
          .map((inputTag) => ({ id: campaignTags.find((campaignTag) => campaignTag.name.toLowerCase() === inputTag.toLowerCase())._id }))) ||
      [],
  };
  return influencer;
}

export function extractDeals(lineObj, user) {
  const deals = [];
  if (lineObj["should create deal(yes or no)"].trim().toLowerCase() !== "yes") return deals;
  const currentDate = new Date().toISOString();
  const newDeal = {
    am: user.email,
    status: lineObj["deal status"].trim().replaceAll(" ", "_").toLowerCase(),
  };
  const note = lineObj["deal note"]?.trim();
  if (note) {
    newDeal["notes"] = [
      {
        date: currentDate,
        content: note,
      },
    ];
  }
  deals.push(newDeal);
  return deals;
}

export async function csvToJSON(csv, _fileName) {
  let csvFile = await csvParse().fromString(csv);
  return csvFile;
}

export async function textToCSVObject(textData) {
  // Check if header is included
  if (textData.startsWith("emails")) {
    const csvFile = await csvParse({ delimiter: "\t" }).fromString(textData);
    return csvFile;
  } else {
    const csvFile = await csvParse({
      delimiter: "\t",
      headers: CSV_COLUMNS,
    }).fromString(textData);
    return csvFile;
  }
}