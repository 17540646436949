import React, { useState } from "react";
import { formatISO, parseISO } from "date-fns";
import PropTypes from 'prop-types';
import { ALDropdown } from "components/ALComponents";
import CONTENT_TYPES from "../currentDealContent/contentTypes.json";
import DELIVERABLE_TYPES from "../currentDealContent/deliverableTypes.json";
import REACH_TYPES from "../currentDealContent/reachTypes.json";

const Content = ({ index, dealContent, updateDealContent, deleteDealContent }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [link, setLink] = useState(dealContent.link);
  const [linkAnaluisa, setLinkAnaluisa] = useState(dealContent.link_analuisa);
  const [dateExpected, setDateExpected] = useState(dealContent.date_expected);
  const [dateRelease, setDateRelease] = useState(dealContent.date_release);
  const [quantity, setQuantity] = useState(dealContent.quantity || null);
  const [duration, setDuration] = useState(dealContent.duration || null);
  const [deliverable, setDeliverable] = useState(dealContent.deliverable || null);
  const [reach, setReach] = useState(dealContent.reach || null);

  const resetForm = () => {
    setIsEditing(!isEditing);
    setLink(dealContent.link);
    setLinkAnaluisa(dealContent.link_analuisa);
    setDateExpected(dealContent.date_expected);
    setDateRelease(dealContent.date_release);
    setQuantity(dealContent.quantity || null);
    setDuration(dealContent.duration || null);
  };

  return (
    <div className="oi_deal_content_container_content">
      <div className="oi_deal_content_container_content_edit">
        {isEditing ? (
          <>
            <span className="material-icons" onClick={() => {
              deleteDealContent(index);
              setIsEditing(false);
            }}>
              delete
            </span>
            <span className="material-icons" onClick={resetForm}>
              close
            </span>
          </>
        ) : (
          <span className="material-icons" onClick={() => setIsEditing(true)}>
            create
          </span>
        )}
      </div>
      <div className="oi_deal_content_container_content_type">
        {CONTENT_TYPES.find((e) => e.value === `${dealContent.platform}_${dealContent.type}`)?.title || `${dealContent.platform} ${dealContent.type}`}
      </div>
      {!(dealContent.type === "pr-pack" && dealContent.platform === "instagram") && <div className="oi_deal_content_container_content_detail">
        <p className="oi_deal_content_container_content_title">Link</p>
        {isEditing ? (
          <input
            className="oi_influencer_edit_input--text"
            type="text"
            onChange={(e) => setLink(e.target.value)}
            value={link || ""}
          />
        ) : (
          <a
            className="oi_deal_content_container_content_value"
            href={link || "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link || ""}
          </a>
        )}
      </div>}
      <div className="oi_deal_content_container_content_detail">
        <p className="oi_deal_content_container_content_title">
          Link Ana Luisa
        </p>
        {isEditing ? (
          <input
            className="oi_influencer_edit_input--text"
            type="text"
            onChange={(e) => setLinkAnaluisa(e.target.value)}
            value={linkAnaluisa || ""}
          />
        ) : (
          <a
            className="oi_deal_content_container_content_value"
            href={linkAnaluisa || ""}
          >
            {linkAnaluisa || ""}
          </a>
        )}
      </div>
      <div className="oi_deal_content_container_content_detail">
        <p className="oi_deal_content_container_content_title">
          Date expected
        </p>
        {isEditing ? (
          <input
            className="oi_influencer_edit_input--date"
            type="date"
            onChange={(e) => {
              if (!e.target.value) return;
              const value = parseISO(e.target.value).toISOString();
              setDateExpected(value);
            }}
            value={
              dateExpected
                ? formatISO(new Date(dateExpected), { representation: "date" })
                : ""
            }
          />
        ) : (
          <p className="oi_deal_content_container_content_value">
            {dateExpected
              ? formatISO(new Date(dateExpected), {representation: "date"})
              : null}
          </p>
        )}
      </div>

      <div className="oi_deal_content_container_content_detail">
        <p className="oi_deal_content_container_content_title">
          Date release
        </p>
        {isEditing ? (
          <input
            className="oi_influencer_edit_input--date"
            type="date"
            onChange={(e) => {
              if (!e.target.value) return;
              const value = parseISO(e.target.value).toISOString();
              setDateRelease(value);
            }}
            value={
              dateRelease
                ? formatISO(new Date(dateRelease), { representation: "date" })
                : ""
            }
          />
        ) : (
          <p className="oi_deal_content_container_content_value">
            {dateRelease
              ? formatISO(new Date(dateRelease), {representation: "date"})
              : null}
          </p>
        )}
      </div>
      {dealContent.type === "story" && (
        <div className="oi_deal_content_container_content_detail">
          <p className="oi_deal_content_container_content_title">
            Quantity
          </p>
          {isEditing ? (
            <input
              className="oi_influencer_edit_input--text"
              type="number"
              onChange={(e) => {
                if (e.target.value && parseInt(e.target.value) >= 1) {
                  setQuantity(e.target.value);
                }
              }}
              value={quantity || ""}
            />
          ) : (
            <p className="oi_deal_content_container_content_value">
              {quantity}
            </p>
          )}
        </div>
      )}
      {dealContent.type === "mention" && dealContent.platform === "youtube" && (
        <div className="oi_deal_content_container_content_detail">
          <p className="oi_deal_content_container_content_title">
            Duration
          </p>
          {isEditing ? (
            <ALDropdown
              options={[{key: "", value: "Select duration"}, ...[60, 90, 120].map(d => ({key: `${d}`, value: `${d}s`}))]}
              onChange={(key) => setDuration(key ? parseInt(key) : null)}
              value={duration ? `${duration}` : ""}
            />
          ) : (
            <p className="oi_deal_content_container_content_value">
              {duration ? `${duration}s` : ""}
            </p>
          )}
        </div>
      )}
      <div className="oi_deal_content_container_content_detail">
        <p className="oi_deal_content_container_content_title">
          Deliverables
        </p>
        {isEditing ? (
          <ALDropdown
            options={DELIVERABLE_TYPES.map(d => ({key: `${d.value}`, value: `${d.title}`}))}
            onChange={(key) => setDeliverable(key ? key : null)}
            value={deliverable ? `${deliverable}` : ""}
          />
        ) : (
          <p className="oi_deal_content_container_content_value">
            {deliverable ? `
              ${DELIVERABLE_TYPES.find((e) => e.value === dealContent.deliverable)?.title || dealContent.deliverable}
            ` : ""}
          </p>
        )}
      </div>

      <div className="oi_deal_content_container_content_detail">
        <p className="oi_deal_content_container_content_title">
          Reach
        </p>
        {isEditing ? (
          <input
            className="oi_influencer_edit_input--text"
            type="number"
            onChange={(e) => {
              setReach(e.target.value);
            }}
            value={reach || ""}
          />
        ) : (
          <p className="oi_deal_content_container_content_value">
            {reach ? `
              ${REACH_TYPES.find((e) => e.value === dealContent.reach)?.title || dealContent.reach}
            ` : ""}
          </p>
        )}
      </div>

      {isEditing && (
        <div
          className="outreach_influencer_deal_update_button"
          onClick={() => updateDealContent(
            index,
            {
              platform: dealContent.platform,
              type: dealContent.type,
              link: link,
              link_analuisa: linkAnaluisa,
              date_expected: dateExpected,
              date_release: dateRelease,
              quantity: quantity,
              duration: duration,
              deliverable: deliverable,
              reach: reach,
            },
            setIsEditing
          )}
        >
          UPDATE <span className="material-icons">add</span>
        </div>
      )}
    </div>
  );
}

Content.propTypes = {
  index: PropTypes.number.isRequired,
  dealContent: PropTypes.object.isRequired,
  updateDealContent: PropTypes.func.isRequired,
  deleteDealContent: PropTypes.func.isRequired,
};

export default Content;