import React from "react";
import moment from "moment";
import "./InventoryVelocity.css";

class InventoryVelocity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  componentDidMount() {
    //console.log(this.props.data)
    this.updateVelocity();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.updateVelocity();
    }
  }

  updateVelocity = () => {
    const dataRange = this.props.data;

    const oldestInv = dataRange.inventory[0];
    const newestInv =  dataRange.inventory[dataRange.inventory.length - 1];
    const diffOldestNewest = moment(newestInv.date).diff(moment(oldestInv.date), "days");

    let valuesVelocity = [];
    let outOfStockDays = null;

    oldestInv.variants.map((variant, i) => {
      const velocity = (variant.inventory_quantity - newestInv.variants[i].inventory_quantity) / diffOldestNewest;
      this.props.currentStock.map((varCurrentStock, j) => {
        if (variant.id === varCurrentStock.id) {
          outOfStockDays = varCurrentStock.inventory_quantity / velocity;
          valuesVelocity.push({
            'name': `Size ${varCurrentStock.title}`,
            'velocity': velocity.toFixed(2),
            'out_of_stock': outOfStockDays.toFixed(),
            'current_stock': varCurrentStock.inventory_quantity
          });
          return true;
        }
      })
    });
    this.setState({ dataVariants: valuesVelocity });
  };

  render() {
    return (
      <div className="inventory-velocity">
        {/* -- current stock -- */}
        <div className="velocity__current">
          <div className="demo-card-wide mdl-card mdl-shadow--2dp">
            <div
              className="mdl-card__title"
              style={{ backgroundImage: `url(${this.props.img})`,height:"270px" }}
            >
            </div>
            <div className="mdl-card__supporting-text">
              <h4 className="current__title">{this.props.data.title}</h4>
              <p className="current__type">{this.props.data.product_type}</p>
              {(this.state.dataVariants) ? (this.state.dataVariants.map((obj) => {
                return (
                  <p className="current__values"><span className="current__values__size">{obj.name}:</span> {obj.current_stock} piece{(obj.current_stock > 1) ? 's' : null } in stock</p>
                )
              })) : null}
            </div>
          </div>
        </div>
        {/* -- velocity data -- */}
        <div className="velocity__projection">
          <div className="demo-card-wide mdl-card mdl-shadow--2dp">
            <div className="mdl-card__title" style={{ backgroundImage: `url(${require("../assets/data_inventory.jpg")})`, height:"270px" }}>
              <h2 className="mdl-card__title-text">Statistics</h2>
            </div>
            <div className="mdl-card__supporting-text">
            {(this.state.dataVariants) ? (this.state.dataVariants.map((obj) => {
                return (
                  <p className="current__values"><span className="current__values__size">{obj.name}:</span> {obj.velocity} sold/day — Out of stock in {obj.out_of_stock} day{(obj.out_of_stock > 0) ? 's' : null}</p>
                )
              })) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InventoryVelocity;
