import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import _ from 'lodash';

import NotificationManager from "components/al_components/notification/NotificationManager";
import Email from "./inbox/Email";
import Reply from "./inbox/Reply";
import { ErrorBoundaryContent } from "../ALComponents";
// import History from "./inbox/History";
import { parseMail, formatEmailText } from "./inbox/helpers";

import "./Emails.css";

const MAX_RESULTS = 10;

function Emails({ user, isTabInfluencerClosed, influencer }) {
  const [emails, setEmails] = useState([]);
  
  const [newPageToken, setNewPageToken] = useState(null);
  const [message, setMessage] = useState(null);

  const [showReply, setShowReply] = useState(false);

  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [customEmails, setCustomEmails] = useState([]);
  const [originalEmails, setOriginalEmails] = useState({});

  const [feedbackContent, setFeedbackContent] = useState("");

  const [generatedReply, setGeneratedReply] = useState({});

  const memoizedInfluencer = useMemo(() => influencer, [influencer?.emails]); // Memoize based on emails array

  const fetchEmailsWithThrottle = async (emailIds) => {
    const emails = [];

    let i = 0;
    for (const id of emailIds) {
      try {
        i += 1;
        const response = await axios.get(`/api/v1/mail/messages/${id}`);
        emails.push(response.data);
      } catch (error) {
        console.error('Error fetching email:', error);
      }
      setMessage(`Pulling emails from Gmail (${i}/${emailIds.length})...`);
    }

    return emails;
  };

  const handleChangeFeedback = (e) => {
    setFeedbackContent(e.target.value);
  };

  const fetchEmails = useCallback(async (token) => {
    try {
      // https://support.google.com/mail/answer/7190
      const url = `/api/v1/mail/messages?maxResults=${MAX_RESULTS}${token ? `&pageToken=${token}` : ""}${memoizedInfluencer ? `&q=${memoizedInfluencer.emails.join(" OR ")}` : ''}`;

      const response = await axios.get(url);
      // console.log(response.data, " => response.data");
      if (response.data.resultSizeEstimate === 0) {
        setMessage(`No emails found for this influencer (${memoizedInfluencer.emails.toString()}) on user's account (${user.email})`);
      } else {
        const emailIds = response.data.messages.map((msg) => msg.id);
        const emailsData = await fetchEmailsWithThrottle(emailIds, user.accessToken);

        setEmails((prevEmails) => {
          // Combine previous emails and new emailsData
          const combinedEmails = prevEmails.concat(emailsData);

          // Remove duplicates based on 'id' (just in case)
          const uniqueEmails = _.uniqBy(combinedEmails, 'id');

          // Sort by date in descending order (newest first)
          return uniqueEmails.sort((a, b) => {
            return new Date(parseInt(b.internalDate)) - new Date(parseInt(a.internalDate))
          });
        });

        if (response.data.nextPageToken) {
          setNewPageToken(response.data.nextPageToken);
        }

        setMessage(null);
      }
    } catch (err) {
      setMessage('Failed to fetch emails');
    }
  }, [memoizedInfluencer, user.accessToken]);

  useEffect(() => {
    if (memoizedInfluencer) {
      // Reset emails, newPageToken, and message whenever the influencer changes
      setEmails([]);  // Reset emails
      setNewPageToken(null);  // Reset pagination token
      setMessage(null);  // Clear any messages

      // Reset reply comparaison popup
      setCustomEmails([]);
      setShowReplyPopup(false);
      setOriginalEmails({});
      if (memoizedInfluencer.emails.length > 0) {
        fetchEmails();
      } else if (memoizedInfluencer) {
        setMessage("No email associated with the influencer");
      }
    }
  }, [fetchEmails, memoizedInfluencer]);

  if (!user.accessToken) return <div>Access token not found</div>;
  // if (!memoizedInfluencer) return null;

  // function generateReply() {
  //   console.log("Generate reply...");
  //   const parsedEmails = emails.map((email) => parseMail(email));
  //   console.log(parsedEmails, " => parsedEmails");
  // };

  function generateReplyAtIndex(index) {
    const tmpEmails = emails.slice(index);
    const originalEmails = emails.slice(index-1, index);

    setCustomEmails(tmpEmails);
    setShowReplyPopup(true);
    setOriginalEmails(parseMail(originalEmails[0]));
  }

  async function handleSubmitFeedback() {
    const obj = {
      cost_usd: generatedReply.cost_usd,
      task_id: generatedReply.id,
      email: user.email,
      original_email: formatEmailText(originalEmails.filteredTextContent),
      generated_email: generatedReply.task_output.response,
      feedback: feedbackContent
    };
    await axios.post("/api/v1/feedback", obj).then((response) => {
      setFeedbackContent("");
      setCustomEmails([]);
      setShowReplyPopup(false);
      setOriginalEmails({});

      NotificationManager.success("", "Feedback successfully submitted", 1000);
    });
  }

  return (
    <div className="emails_container">
      <div className="emails_container_header">
        <p>Emails ({emails.length})</p>

        {/* <History emails={emails} /> */}

        {(emails.length > 0) && (
          <>
            <button className="email_reply" onClick={() => setShowReply(!showReply)}>
              {showReply ? (
                <>
                  Close
                  <span className="material-icons">close</span>
                </>
              ) : (
                <>
                  Reply
                  <span className="material-icons">reply</span>
                </>
              )}
            </button>

          </>
          
        )}
      </div>

      {(showReply && emails.length > 0) && (
        <Reply
          setShowReply={setShowReply}
          parsedEmails={emails.map((email) => parseMail(email))}
          setGeneratedReply={setGeneratedReply}
        />
      )}
      {showReplyPopup && customEmails && customEmails.length > 0 && (
        <div className="reply_popup">
          <div className="reply_popup_header">
            <p>AI</p>
            <p>ORIGINAL</p>
            <button className="reply_popup_header_close" onClick={() => {
              setCustomEmails([]);
              setShowReplyPopup(false);
              setOriginalEmails({});
              setFeedbackContent("");
            }}>
              <span className="material-icons">close</span>
            </button>
          </div>

          <div className="reply_popup_container">
            <Reply
              parsedEmails={customEmails.map((email) => parseMail(email))}
              setGeneratedReply={setGeneratedReply}
            />
            <div className="reply_popup_feedback">
              <p>Feedback</p>
              <textarea
                value={feedbackContent}
                onChange={handleChangeFeedback}
                placeholder="Write your feedback here..."
                rows="10"
              />
              <button
                className="reply_popup_feedback_submit"
                disabled={feedbackContent.length === 0 || Object.keys(originalEmails).length === 0}
                onClick={() => handleSubmitFeedback()}
              >SUBMIT</button>
            </div>
          </div>

          <div className="reply_popup_original">
            <div className="reply_popup_original_content">
              {originalEmails && (
                originalEmails.filteredTextContent.map((line, index) => {
                  return (
                    <React.Fragment key={index}>
                      {line.trim() === "" ? <br/> : line}
                    </React.Fragment>
                  )
                })
              )}
            </div>
          </div>

        </div>
      )}
      <div className="emails">
        {emails && emails.map((email, i) => <Email key={email.id} email={email} user={user} index={i} generateReplyAtIndex={generateReplyAtIndex} />)}

        {newPageToken && (
          <div className="emails_load_more" onClick={() => fetchEmails(newPageToken)}>
            <p>Download more emails</p>
            <span className="material-icons">sync</span>
          </div>
        )}

        {message && <div>{message}</div>}
      </div>
    </div>
  );
}

export default (props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag("tool", "Emails");
      scope.setTag("user", props.user.email);
    }}
    fallback={ErrorBoundaryContent}>
    <Emails {...props} />
  </Sentry.ErrorBoundary>
);

