import React from "react";
import "./LabelStatus.css";

class LabelStatus extends React.Component {
  render() {
    let colorLabel = '';
    let status = this.props.status;
    console.log(status);
    switch(status) {
      case 'inv_tmp':
        colorLabel = 'purple'; // YES / NO
        break;
      case 'published':
        colorLabel = 'green'; // PUBLISHED
        break;
      case 'toOutreach':
        colorLabel = 'grey'; // To Outreach
        break;
      case 'neg':
      case 'hold':
      case 'det':
      case 'done':
      case 'adam':
      case 'adam_yes':
      case 'adam_no':
        colorLabel = 'yellow'; // outreach v2
        break;
      default:
        colorLabel = 'unknown';
    }

    if (status.includes("declined")) {
      colorLabel = "red"; // DECLINED
    }

    return (
      <div className={`label_status label_status--${colorLabel}`}>
        <p>{status}</p>
      </div>
    );
  }
}

export default LabelStatus;
