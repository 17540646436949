import React from "react";
import InventoryChart from "./InventoryChart";
import InventoryVelocity from './InventoryVelocity';
import { DateRange } from 'react-date-range';
import Loading from "./Loading";
import moment from "moment";
import "../App.css";

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestedId: this.props.match.params.id,
      response: null,
      dataRange: null,
      daysDiff: 0,
      stockObject: {},
      velocityObject: {},
      globalStock: 0,
      globalStockDays: 0,
      dateRangePicker: {
        selection: {
          startDate: null,
          endDate: null,
          key: 'selection',
        },
      },
    };
    this.updateResultDate = this.updateResultDate.bind(this);
  }
  componentDidMount() {
    this.fetchRequestedId();
    // const startDate = moment(this.state.dateRangePicker.startDate);
    // const endDate = moment(this.state.dateRangePicker.endDate);
    // moment(el.date).isBetween(startDate, endDate)
  }


  updateResultDate() {
    const startDate = moment(this.state.dateRangePicker.startDate);
    const endDate = moment(this.state.dateRangePicker.endDate);
    let theData = this.state.response;
    let dataRange = {};
    dataRange['variants'] = theData.variants;
    dataRange['title'] = theData.title;
    dataRange['product_type'] = theData.product_type;
    dataRange['id'] = theData.id;
    dataRange['inventory'] = [];
    theData.inventory.map((inventory, j) => {
      if (moment(inventory.date).isBetween(startDate, endDate)) {
        dataRange['inventory'].push(inventory);
      }
    });
    this.setState({ dataRange: dataRange });
  }

  fetchRequestedId = async props => {
    const response = await fetch(
      `/api/v1/inventory/${this.state.requestedId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        /*data.map((product, i) => {
          product.inventory.map((inventory, j) => {
            inventory['display'] = true;
          });
        });*/

        this.setState({
          response: data[0],
          dataRange: data[0]
        });

        this.updateData();
      });
  };

  updateData() {
    const data = this.state.response;
    // this.daysDiffFinder();
    // calcul diff dates between first and last result
    const oldestDate = data.inventory[0].date;
    const newestDate = data.inventory[data.inventory.length - 1].date;
    var oDate = moment(oldestDate);
    var nDate = moment(newestDate);
    var diff = nDate.diff(oDate, "days");

    // this.getInStock();
    // get in stock
    var inStockGlobal = {};
    const mostUpdatedInv = data.inventory[data.inventory.length - 1];
    var globalStock = 0;
    mostUpdatedInv.variants.map(item => {
      console.log(item, "item");
      globalStock = globalStock + item.inventory_quantity;
      inStockGlobal[item.id] = {
        title: item.title,
        inventory_quantity: item.inventory_quantity
      };
    });

    // this.velocityFinder();
    // calc velocity values
    // ------
    const oldestInv = data.inventory[0];
    const newestInv = data.inventory[data.inventory.length - 1];
    var velocityObject = {};
    // ------
    let oldInv = null;
    let newInv = null;
    data.inventory.map((inv) => {
      if (inv.display === true) {
        if (oldInv) {
          newInv = inv;
        }
        oldInv = inv;
      }
    })
    var avgOOS = 0;
    const velocityData = oldestInv.variants.map((inv, i) => {
      const velocity = (inv.inventory_quantity - newestInv.variants[i].inventory_quantity) / diff;
      const title = data.variants[i].title;

      velocityObject[inv.id] = {
        title: title,
        velocity: velocity
      };
      var avgOutOfStock = inStockGlobal[inv.id].inventory_quantity / velocityObject[inv.id].velocity;
      avgOOS = avgOOS + avgOutOfStock;
    });


    this.setState({
      // response: data,
      daysDiff: diff,
      stockObject: inStockGlobal,
      globalStock: globalStock,
      velocityObject: velocityObject,
      globalStockDays: avgOOS / newestInv.variants.length
    });
  }


  inStockRender = () => {
    if (Object.keys(this.state.stockObject).length) {
      var objectOfInv = this.state.stockObject;
      const toBeReturned = this.state.response.variants.map((variant, i) => {
        // var oneItemStock = objectOfInv[variant.id].inventory_quantity;
        // console.log(objectOfInv[variant.id].inventory_quantity == 0, "inventory quantity");
        if (objectOfInv[variant.id].inventory_quantity === 0) {
          var style = {
            color: "red"
          };
        }
        return (
          <div key={`stock_render_${i}`}>
            <h6 style={{ style }}>
              {objectOfInv[variant.id].title == "Default Title"
                ? " "
                : `Size: ${objectOfInv[variant.id].title}`}{" "}
              -
              {objectOfInv[variant.id].inventory_quantity == 0
                ? "Out of stock"
                : `In stock: ${objectOfInv[variant.id].inventory_quantity}`}
            </h6>
          </div>
        );
      });
      return toBeReturned;
    }
  };
  /*
  getInStock() {
    if (this.state.response) {
      var inStockGlobal = {};
      const mostUpdatedInv = this.state.response[0].inventory[
        this.state.response[0].inventory.length - 1
      ];
      var globalStock = 0;
      mostUpdatedInv.variants.map(item => {
        console.log(item, "item");
        globalStock = globalStock + item.inventory_quantity;
        inStockGlobal[item.id] = {
          title: item.title,
          inventory_quantity: item.inventory_quantity
        };
        // tempArr.push(item.inventory_quantity);
        // var style = {};
        // var stockText = "In Stock:";
        // if (item.inventory_quantity == 0) {
        //   style = { color: "red" };
        //   stockText = "Out of stock";
        // }
        // return (
        //   <h6 style={style}>
        //     {item.title == "Default Title" ? " " : "Size: " + item.title}{" "}
        //     {stockText}{" "}
        //     {item.inventory_quantity == 0 ? " " : item.inventory_quantity}
        //   </h6>
        // );
      });
      this.setState({ stockObject: inStockGlobal });
      this.setState({ globalStock: globalStock });
      console.log(this.state.stockObject, "state");
    }
  }
  */
  /*
  daysDiffFinder = () => {
    const oldestDate = this.state.response[0].inventory[0].date;
    const newestDate = this.state.response[0].inventory[
      this.state.response[0].inventory.length - 1
    ].date;
    var oDate = moment(oldestDate);
    var nDate = moment(newestDate);
    console.log(oldestDate);
    console.log(newestDate);
    var diff = nDate.diff(oDate, "days");
    console.log(diff);
    this.setState({ daysDiff: diff });
    console.log(diff);
  };
  */
  /*
  velocityFinder = () => {
    if (this.state.response) {
      console.log(this.state.response == null);
      const oldestInv = this.state.response[0].inventory[0];
      const newestInv = this.state.response[0].inventory[
        this.state.response[0].inventory.length - 1
      ];
      var velocityObject = {};
      var inStockState = this.state.stockObject;
      console.log(oldestInv, newestInv);
      var avgOOS = 0;
      const velocityData = oldestInv.variants.map((inv, i) => {
        const velocity =
          (inv.inventory_quantity - newestInv.variants[i].inventory_quantity) /
          this.state.daysDiff;
        console.log("--------------------------------");
        const title = this.state.response[0].variants[i].title;
        console.log(this.state.response);
        console.log(title);
        console.log("--------------------------------");
        console.log(inv.id, "id");
        console.log(inv, "inv");
        console.log(inStockState, "inStockState");
        velocityObject[inv.id] = {
          title: title,
          velocity: velocity
        };
        var avgOutOfStock =
          inStockState[inv.id].inventory_quantity /
          velocityObject[inv.id].velocity;
        avgOOS = avgOOS + avgOutOfStock;
      });

      this.setState({ velocityObject: velocityObject });
      this.setState({ globalStockDays: avgOOS / newestInv.variants.length });
      console.log(velocityObject, "velocity Object");
    }
  };
  */

  velocityRender = () => {
    if (Object.keys(this.state.velocityObject).length && Object.keys(this.state.stockObject).length) {
      var velocityState = this.state.velocityObject;
      const inStockState = this.state.stockObject;
      var velocityVar = this.state.response.variants.map((variant, i) => {
        var outOfStockDays = (inStockState[variant.id].inventory_quantity / velocityState[variant.id].velocity).toFixed(0);
        return (
          <div key={`velocity_${i}`}>
            <h6>
              {velocityState[variant.id].title == "Default Title"
                ? " "
                : `Size: ${velocityState[variant.id].title}`}{" "}
              - Velocity: {velocityState[variant.id].velocity}
            </h6>
            <h6>
              {outOfStockDays != NaN && outOfStockDays != Infinity
                ? `\n- Out of stock in: ${outOfStockDays} days`
                : " "}
            </h6>
          </div>
        );
      });
      return velocityVar;
    } else {
      return false;
    }
  };

  handleRangeChange(which, payload) {
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
  }
  renderContent() {
    if (this.state.response) {
      /************html*************/
      // const imageURL = this.state.response.image;
      //   let stock = '';
      // if (this.state.stock) {
      //   oerijgeoirjg.map((vv) =>{
      //     stock+=(<p>feokroke</p>)
      //   })
      // }
      return (
        <div>
          <div className="backButton">
            <a href="/a/dashboard/products">
            <button className="mdl-button mdl-js-button mdl-button--fab back-button">
              <i class="material-icons">arrow_back</i>
            </button>
            </a>
          </div>

          <DateRange
            onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className='PreviewArea'
            months={1}
            direction="horizontal"
            linkedCalendars={true}
            minDate={moment(new Date('2019-08-05'))}
            maxDate={moment(new Date())}
          />
          <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent dateRange__update" onClick={this.updateResultDate}>Update</button>

          <InventoryVelocity data={this.state.dataRange} img={this.state.response.image} currentStock={this.state.response.inventory[this.state.response.inventory.length - 1].variants} />

          <InventoryChart data={this.state.dataRange} />
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          marginTop: "150px",
        }}
      >
        <Loading />
      </div>
    );
  }

  render() {
    return <div className="page_container">{this.renderContent()}</div>;
  }
}
export default Inventory;
