export const CSV_COLUMNS = [
  "destination(outreach or split)",
  "emails",
  "country",
  "gender(F or M)",
  "age(exact or min-high)",
  "youtube channels id",
  "instagram profiles handle",
  "tiktok profiles handle",
  "tags",
  "campaign tags",
  "should create deal(yes or no)",
  "deal status",
  "deal note",
];

export const SUPPORTED_PLATFORMS = ["youtube", "instagram", "tiktok"];
