import React, { useState, useEffect } from "react";
import { format, formatISO, add } from "date-fns";
import axios from "axios";
import PropTypes from 'prop-types';

import NotificationManager from "../../al_components/notification/NotificationManager";
import "./CurrentDealDates.css";

const formatDate = (date, dateFormat = "yyyy-MM-dd") => {
  if (date) {
    return format(new Date(date), dateFormat)
  }
  return null;
};

const dateToIso = (date) => {
  if (date) {
    return formatISO(new Date(date));
  }
  return "";
};

const CurrentDealDates = ({ user, deal, onDealUpdated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [lastOutreach, setLastOutreach] = useState(formatDate(deal.date_last_outreach));
  const [firstOutreach, setFirstOutreach] = useState(formatDate(deal.date_first_outreach));
  const [followUp, setFollowUp] = useState(formatDate(deal.date_follow_up));
  const [dateSign, setDateSign] = useState(formatDate(deal.date_sign));

  useEffect(() => {
    setLastOutreach(formatDate(deal.date_last_outreach));
    setFirstOutreach(formatDate(deal.date_first_outreach));
    setFollowUp(formatDate(deal.date_follow_up));
    setDateSign(formatDate(deal.date_sign));
    setIsEditing(false);
  }, [deal]);

  const updateDeal = () => {
    let dealUpdate = {
      date_last_outreach: dateToIso(lastOutreach),
      date_first_outreach: dateToIso(firstOutreach),
      date_follow_up: dateToIso(followUp),
      date_sign: dateToIso(dateSign),
    };

    axios
      .put(`/api/v1/deals/${deal._id}`, dealUpdate)
      .then(() => {
        axios
          .post("/api/v1/event", {
            type: "UPDATE_DATE_OUTREACH_DEAL",
            user: user.email,
            sample: { object: deal._id },
          })
          .catch((err) => console.log(err) || alert("Failed to fire events"));

        setIsEditing(false);
        onDealUpdated(dealUpdate);
        NotificationManager.success(
          "Updated",
          "Successfully updated deal",
          1500
        );
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Error",
          "Server failed to update deal dates, please try again later and send the page log to the tech team",
          6000
        );
      });
  };

  const quickUpdateDates = () => {
    let updatedDates = {
      date_last_outreach: formatISO(new Date()),
      date_follow_up: formatISO(add(new Date(), { days: 3 })),
    };

    axios
      .put(`/api/v1/deals/${deal._id}`, updatedDates)
      .then((data) => {
        if (data.status === 200) {
          onDealUpdated(updatedDates);
          NotificationManager.success(
            "Updated",
            `Successfully updated deal`,
            1500
          );

          axios
            .post("/api/v1/event", {
              type: "UPDATE_DATE_OUTREACH_DEAL",
              user: user.email,
              sample: { object: deal._id },
            })
            .catch((err) => {
              console.error(err);
              alert("Failed to fire event UPDATE_DATE_OUTREACH_DEAL");
            });
        }
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Error",
          "Server failed to update Deal, please try again later and send the page log to the tech team",
          6000
        );
      });
  };

  // resets all the dates with their initial values and exits edit mode
  const resetDates = () => {
    if (isEditing) {
      setLastOutreach(formatDate(deal.date_last_outreach));
      setFirstOutreach(formatDate(deal.date_first_outreach));
      setFollowUp(formatDate(deal.date_follow_up));
      setDateSign(formatDate(deal.date_sign));
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="outreach_influencer_deal_dates">
      <div
        className="outreach_influencer_deal_dates_edit"
        onClick={resetDates}
      >
        <span className="material-icons">
          {isEditing ? "close" : "create"}
        </span>
      </div>

      {isEditing && (
        <div
          className="outreach_influencer_deal_update_button"
          onClick={updateDeal}
        >
          UPDATE <span className="material-icons">add</span>
        </div>
      )}

      <div className="outreach_influencer_deal_dates_date">
        <p className="outreach_influencer_deal_dates_date_title">
          Creation Date
        </p>
        <p className="outreach_influencer_deal_dates_date_value">
          {formatDate(deal.creation_date, "MM/dd/yyyy")}
        </p>
      </div>

      <div className="outreach_influencer_deal_dates_date">
        <p className="outreach_influencer_deal_dates_date_title">
          First Outreach Date
        </p>
        {isEditing &&
        !["to_ship", "shipped", "in_transit", "shipment_received"].includes(
          deal.status
        ) ? (
          <input
            className="oi_influencer_edit_input--date"
            type="date"
            data-key="date_first_outreach"
            onChange={(e) => setFirstOutreach(e.target.value)}
            value={
              firstOutreach
                ? firstOutreach
                : deal.date_first_outreach
                  ? formatDate(deal.date_first_outreach, "yyyy-MM-dd")
                  : ""
            }
          />
        ) : (
          <p className="outreach_influencer_deal_dates_date_value">
            {formatDate(deal.date_first_outreach, "MM/dd/yyyy")}
          </p>
        )}
      </div>

      <div className="outreach_influencer_deal_dates_date">
        <p className="outreach_influencer_deal_dates_date_title">
          Last Outreach Date
        </p>
        {isEditing ? (
          <input
            className="oi_influencer_edit_input--date"
            type="date"
            data-key="date_last_outreach"
            onChange={(e) => setLastOutreach(e.target.value)}
            value={
              lastOutreach
                ? lastOutreach
                : deal.date_last_outreach
                  ? formatDate(deal.date_last_outreach, "yyyy-MM-dd")
                  : ""
            }
          />
        ) : (
          <div className="outreach_influencer_deal_dates_container">
            <p className="outreach_influencer_deal_dates_date_value">
              {formatDate(deal.date_last_outreach, "MM/dd/yyyy")}
            </p>

            <button
              type="button"
              className="al_button outreach_influencer_deal_dates_update-btn"
              onClick={quickUpdateDates}
            >
              UPDATE
            </button>
          </div>
        )}
      </div>

      <div className="outreach_influencer_deal_dates_date">
        <p className="outreach_influencer_deal_dates_date_title">
          Follow up Date
        </p>
        {isEditing ? (
          <input
            className="oi_influencer_edit_input--date"
            type="date"
            data-key="date_follow_up"
            onChange={(e) => setFollowUp(e.target.value)}
            value={
              followUp
                ? followUp
                : deal.date_follow_up
                  ? formatDate(deal.date_follow_up, "yyyy-MM-dd")
                  : ""
            }
          />
        ) : (
          <p className="outreach_influencer_deal_dates_date_value">
            {formatDate(deal.date_follow_up, "MM/dd/yyyy")}
          </p>
        )}
      </div>

      <div className="outreach_influencer_deal_dates_date">
        <p className="outreach_influencer_deal_dates_date_title">Date Sign</p>
        {isEditing ? (
          <input
            className="oi_influencer_edit_input--date"
            type="date"
            data-key="date_sign"
            onChange={(e) => setDateSign(e.target.value)}
            value={
              dateSign
                ? dateSign
                : deal.date_sign
                  ? formatDate(deal.date_sign, "yyyy-MM-dd")
                  : ""
            }
          />
        ) : (
          <p className="outreach_influencer_deal_dates_date_value">
            {formatDate(deal.date_sign, "MM/dd/yyyy")}
          </p>
        )}
      </div>
    </div>
  );
};

CurrentDealDates.propTypes = {
  user: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired,
  onDealUpdated: PropTypes.func.isRequired,
};

export default CurrentDealDates;
