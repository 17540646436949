import Axios from "axios";
import { DEAL_STATUSES } from "dictionaries";
import { chunk } from "lodash";
import { extractDeals, extractInfluencer } from "./csvHelpers";

function checkObjectFormat(lineObj, tags, campaignTags) {
  const emailRegex = /\S+@\S+\.\S+/;
  if (
    !lineObj["emails"].trim() &&
    !lineObj["youtube channels id"].trim() &&
    !lineObj["instagram profiles handle"].trim() &&
    !lineObj["tiktok profiles handle"].trim()
  )
    return { status: "error", message: "Missing at least one email or platform" };
  if (
    lineObj["emails"]
      .split(" ")
      .filter((e) => e)
      .map((e) => e.trim())
      .some((e) => !emailRegex.test(e))
  )
    return { status: "error", message: "Invalid emails" };
  if (!lineObj["destination(outreach or split)"] || !["outreach", "split"].includes(lineObj["destination(outreach or split)"].trim().toLowerCase()))
    return { status: "error", message: "Invalid destination" };
  if (lineObj["gender(F or M)"] && !["M", "F"].includes(lineObj["gender(F or M)"].trim())) return { status: "error", message: "Invalid gender" };
  if (
    lineObj["youtube channels id"]
      .trim()
      .split(" ")
      .filter((e) => e)
      .some((e) => e.length !== 24)
  )
    return { status: "error", message: "Invalid youtube id" };
  if (
    lineObj["tags"]
      .trim()
      .split(" ")
      .filter((e) => e)
      .some((inputTag) => !tags.find((tag) => tag.name.toLowerCase() === inputTag.toLowerCase()))
  )
    return { status: "error", message: "Invalid tag" };
  if (
    lineObj["campaign tags"]
      .trim()
      .split(" ")
      .filter((e) => e)
      .some((inputTag) => !campaignTags.find((campaignTag) => campaignTag.name.toLowerCase() === inputTag.toLowerCase()))
  )
    return { status: "error", message: "Invalid tag" };
  if (lineObj["should create deal(yes or no)"] && !["yes", "no"].includes(lineObj["should create deal(yes or no)"].trim().toLowerCase()))
    return { status: "error", message: "Invalid 'should create deal' value" };
  if (lineObj["should create deal(yes or no)"] === "yes" && !DEAL_STATUSES.includes(lineObj["deal status"].trim().replaceAll(" ", "_").toLowerCase()))
    return { status: "error", message: "Invalid deal status" };
  if (lineObj["should create deal(yes or no)"] !== "yes" && (lineObj["deal status"].trim() || lineObj["deal note"].trim()))
    return { status: "error", message: "Deal status and Deal note should be empty if deal creation is not set to 'yes'" };
  return { status: "ok" };
}

export function checkAllObjectFormat(data, tags, campaignTags) {
  for (const [index, lineObj] of data.entries()) {
    const checkResult = checkObjectFormat(lineObj, tags, campaignTags);
    if (checkResult.status === "error") {
      return { line: index + 1, message: checkResult.message };
    }
  }
  return null;
}

export function convertObjectLineToInfluencersAndDeals(data, tags, campaignTags, user) {
  let influencersAndDeals = [];
  for (const [index, lineObj] of data.entries()) {
    try {
      influencersAndDeals.push({
        influencer: extractInfluencer(lineObj, tags, campaignTags, user),
        deals: extractDeals(lineObj, user),
        lineObj,
      });
    } catch (err) {
      return { status: "error", result: { line: index + 1, message: "Failed to create influencer object." } };
    }
  }
  return { status: "success", result: influencersAndDeals };
}

export async function determineInfluencersOperation(influencersAndDeals, setInfluencersProcessing) {
  const chunks = chunk(influencersAndDeals, 500);
  for (const chunk of chunks) {
    const chunkInfluencers = chunk.map((influencerAndDeals) => influencerAndDeals.influencer);
    chunk.forEach((influencer) => {
      influencer.status = "⏳ Retrieving existing data...";
    });
    setInfluencersProcessing(influencersAndDeals);
    const results = await Axios.post("/api/v1/search_influencers", { influencers: chunkInfluencers });
    const matchingInfluencers = results.data.result;
    chunk.forEach((influencerAndDeals, index) => {
      const matchedInfluencers = matchingInfluencers[index] || [];
      if (matchedInfluencers.length > 1) {
        influencerAndDeals.mergeStrategy = "CONFLICT";
      } else if (matchedInfluencers.length === 1) {
        influencerAndDeals.mergeStrategy = "MERGE";
        influencerAndDeals.destinationInfluencer = matchedInfluencers[0];
      } else {
        influencerAndDeals.mergeStrategy = "NEW";
      }
      influencerAndDeals.status = `✅ Pre-processing done. [${influencerAndDeals.mergeStrategy}]`;
    });
    setInfluencersProcessing(influencersAndDeals);
  }
}
