import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import { DateRange } from "react-date-range";
import Loading from "../Loading";
import { ALSwitch, ALDropdown } from "../ALComponents";

import "./Reporting.css";

async function getAccountManagers() {
  const res = await axios.get("/api/v1/team/account-manager?with=admin&with=super-user");
  if (res.status == 200) {
    const tmpUsers = res.data.users;
    return tmpUsers
      .map((user) => {
        if (user.teams.includes("YouTube") || user.teams.includes("Co-creation") || user.teams.includes("Instagram") || user.teams.includes("TikTok") || user.teams.includes("Podcast")) {
          return {
            "id": user.email,
            "title": `${user.firstName} ${user.lastName}`,
          };
        }
        return null;
      })
      .filter((o) => o);
  }
  return [];
}

async function getReportingData(platform, accountManager, dateRange) {
  const currentDateStart = moment.utc(dateRange.startDate.format("YYYY-MM-DDTHH:mm:ss"));
  const currentDateEnd = moment.utc(dateRange.endDate.format("YYYY-MM-DDTHH:mm:ss"));
  const daysInRange = currentDateEnd.diff(currentDateStart, "days");
  const previousDateEnd = moment(currentDateStart).subtract(1, "days");
  const previousDateStart = moment(previousDateEnd).subtract(daysInRange, "days");
  const currentBody = {
    platform: platform,
    accountManager: accountManager,
    dateRange: { start: currentDateStart, end: currentDateEnd },
  };
  const previousBody = {
    ...currentBody,
    dateRange: { start: previousDateStart, end: previousDateEnd },
  };
  const [currentResult, previousResult] = (
    await Promise.all([axios.post("/api/v1/reporting", currentBody), axios.post("/api/v1/reporting", previousBody)])
  ).map((res) => res.data);
  const metrics = ["numberOfContent", "averagePrice", "totalPrice"];
  return {
    current: currentResult,
    previous: previousResult,
    compare: metrics.reduce(
      (acc, metric) => ({
        ...acc,
        [metric]: !previousResult[metric] || !currentResult[metric] ? null : (100 * currentResult[metric]) / previousResult[metric],
      }),
      {}
    ),
  };
}

function defaultFmt(value, prefix = "", suffix = "") {
  if (value == null) return "-";
  if (suffix === "%")
    return (
      console.log("value:", value) ||
      (value - 100 < 0 ? "-" : "+") + prefix + Math.abs(value - 100).toLocaleString(undefined, { maximumFractionDigits: 1 }) + suffix
    );
  return prefix + value.toLocaleString(undefined, { maximumFractionDigits: 1 }) + suffix;
}

async function loadInfluencerData(influencer) {
  if (influencer.status) return influencer; // Check if it already loaded
  const { data: dataInfluencer } = await axios.get(`/api/v1/influencers`, { params: { _id: influencer._id } });
  let loadedInfluencer = { ...dataInfluencer.result[0], ...influencer };
  if (loadedInfluencer.youtube?.accounts[0]) {
    const { data: dataChannel } = await axios.get(`/api/v1/youtube/channel/${loadedInfluencer.youtube.accounts[0]}`);
    loadedInfluencer.youtube.accounts[0] = dataChannel.channel || loadedInfluencer.youtube.accounts[0];
  }
  return loadedInfluencer;
}

function ReportingResult(props) {
  const { influencers, selectedInfluencers, setSelectedInfluencers, setInfluencers, page } = props;
  const [isExpandedByIndex, setIsExpandedByIndex] = useState([]);
  const [isLoadingFullData, setIsLoadingFullData] = useState(false);

  return (
    <div className="reporting-result">
      <>
        <div className="reporting-result__influencer-title">
          <div className="reporting-result__influencer-head">
            <div className="reporting-result__influencer-head__left">
              {/* <div className="reporting-result__influencer__checkbox-container"></div>
              <div className="reporting-result__influencer__image"></div>
              <div className="reporting-result__influencer__name"></div> */}
              <div className="reporting-result__influencer-title__space__checkbox"></div>
              <div className="reporting-result__influencer-title__space__image"></div>
              <div className="reporting-result__influencer-title__space__name"></div>
              <div className="reporting-result__influencer__section">
                <div className="reporting-result__influencer__value"># DEALS</div>
              </div>
              <div className="reporting-result__influencer__section">
                <div className="reporting-result__influencer__value">PRICE PAID</div>
              </div>
              <div className="reporting-result__influencer__section">
                <div className="reporting-result__influencer__value">CPV</div>
              </div>
              <div className="reporting-result__influencer__section">
                <div className="reporting-result__influencer__value">CPC</div>
              </div>
              <div className="reporting-result__influencer__section">
                <div className="reporting-result__influencer__value">AOV</div>
              </div>
              <div className="reporting-result__influencer__section">
                <div className="reporting-result__influencer__value">CR</div>
              </div>
            </div>
          </div>
        </div>
      </>
      {influencers.map((influencer, idx) => {
        // console.log("influencer", influencer);
        const { youtube: youtubeInfo, instagram: instagramInfo, deals: dealsInfo } = influencer;
        const hasYouTube = youtubeInfo?.accounts?.length;
        const hasInstagram = instagramInfo?.accounts?.length;
        const name =
          (hasYouTube && typeof youtubeInfo.accounts[0] === "string" && youtubeInfo.accounts[0]) ||
          (hasYouTube && youtubeInfo.accounts[0].title) ||
          (hasInstagram && typeof instagramInfo.accounts[0] === "string" && instagramInfo.accounts[0]) ||
          (hasInstagram && instagramInfo.accounts[0].fullName) ||
          influencer._id;
        const pictureURL =
          (hasYouTube && typeof youtubeInfo.accounts[0] === "object" && youtubeInfo.accounts[0].pictureURL) ||
          (hasInstagram && typeof instagramInfo.accounts[0] === "object" && instagramInfo.accounts[0].pictureURL) ||
          null;

        const titleHover = "Based on content of the selected time period with estimated value";
        const current = influencer?.current;
        const previous = influencer?.previous;
        const currentDealNumber = current?.selectedDeals?.length || 0;
        const currentTotalPrice = current?.metrics.totalPrice || 0;

        return (
          <div key={idx} className="reporting-result__influencer">
            <div className="reporting-result__influencer-head">
              <div className="reporting-result__influencer-head__left">
                <div className="reporting-result__influencer__checkbox-container">
                  <input
                    className="reporting-result__influencer__checkbox"
                    type="checkbox"
                    checked={selectedInfluencers.has(influencer._id)}
                    onClick={() => {
                      let newSelectedInfluencers = new Set(selectedInfluencers);
                      if (selectedInfluencers.has(influencer._id)) {
                        newSelectedInfluencers.delete(influencer._id);
                      } else {
                        newSelectedInfluencers.add(influencer._id);
                      }
                      setSelectedInfluencers(newSelectedInfluencers);
                    }}
                  />
                </div>
                <div className="reporting-result__influencer__image">
                  {pictureURL ? (
                    <img
                      className="reporting-result__influencer__image-content"
                      src={pictureURL}
                      alt="profile"
                      crossOrigin="anonymous"
                      referrerPolicy="no-referrer"
                    />
                  ) : null}
                </div>
                <div className="reporting-result__influencer__name" title={influencer["_id"]}>
                  {name}
                </div>
                <div className="reporting-result__influencer__section" title={titleHover}>
                  <div className="reporting-result__influencer__value">{currentDealNumber}</div>
                  <div className="reporting-result__influencer__diff">
                    {_.isNumber(previous?.selectedDeals?.length)
                      ? defaultFmt((100 * currentDealNumber) / previous.selectedDeals.length, "", "%")
                      : ""}
                  </div>
                </div>
                <div className="reporting-result__influencer__section" title={titleHover}>
                  <div className="reporting-result__influencer__value">{currentTotalPrice}</div>
                  <div className="reporting-result__influencer__diff">
                    {_.isNumber(previous?.metrics?.totalPrice) && previous.metrics.totalPrice > 0
                      ? defaultFmt((100 * currentTotalPrice) / previous.metrics.totalPrice, "", "%")
                      : ""}
                  </div>
                </div>
                <div className="reporting-result__influencer__section">
                  <div className="reporting-result__influencer__value">$X.XX</div>
                  <div className="reporting-result__influencer__diff">+XX%</div>
                </div>
                <div className="reporting-result__influencer__section">
                  <div className="reporting-result__influencer__value">$X.XX</div>
                  <div className="reporting-result__influencer__diff">+XX%</div>
                </div>
                <div className="reporting-result__influencer__section">
                  <div className="reporting-result__influencer__value">$X.XX</div>
                  <div className="reporting-result__influencer__diff">+XX%</div>
                </div>
                <div className="reporting-result__influencer__section">
                  <div className="reporting-result__influencer__value">$X.XX</div>
                  <div className="reporting-result__influencer__diff">+XX%</div>
                </div>
              </div>
              <div className="reporting-result__influencer-head__right">
                <span
                  className="material-icons reporting-result__influencer-head__expand"
                  onClick={() => {
                    let newIsExpandedByIndex = [...isExpandedByIndex];
                    newIsExpandedByIndex[idx] = !newIsExpandedByIndex[idx];
                    setIsExpandedByIndex(newIsExpandedByIndex);
                  }}>
                  {isExpandedByIndex[idx] ? "expand_less" : "expand_more"}
                </span>
              </div>
            </div>
            <div className={`search-results__influencer-body ${!isExpandedByIndex[idx] ? "search-results__influencer-body--collapsed" : ""}`}>
              Deals information will be display here in the future...
            </div>
          </div>
        );
      })}
    </div>
  );
}

const resultSortOptions = [
  {
    id: "",
    name: "Default",
    func: (_a, _b) => 1,
  },
];

function ReportingToolLine(props) {
  const { influencersPage, selectedInfluencers, setSelectedInfluencers, selectedSort, setSelectedSort, influencersCount, handleExport } = props;
  return (
    <div className="reporting-tool-line">
      <div className="reporting-tool-line-left">
        <input
          className="search-results__influencer__checkbox"
          type="checkbox"
          checked={selectedInfluencers.size !== 0 && influencersPage.every((influencer) => selectedInfluencers.has(influencer._id))}
          onClick={() => {
            let newSelectedInfluencers = new Set(selectedInfluencers);
            if (influencersPage.every((influencer) => selectedInfluencers.has(influencer._id))) {
              influencersPage.forEach((influencer) => newSelectedInfluencers.delete(influencer._id));
            } else {
              influencersPage.forEach((influencer) => newSelectedInfluencers.add(influencer._id));
            }
            setSelectedInfluencers(newSelectedInfluencers);
          }}
        />
        <button className={`reporting-tool-line__export`} onClick={handleExport}>
          EXPORT {selectedInfluencers.size === influencersCount || selectedInfluencers.size === 0 ? "ALL" : `${selectedInfluencers.size} SELECTED`}
        </button>
      </div>
      <div className="reporting-tool-line-right">
        <div className="reporting-tool-line__sort__title">Sort by</div>
        <ALDropdown
          options={resultSortOptions}
          useValue={"name"}
          useKey={"id"}
          value={selectedSort}
          onChange={setSelectedSort}
          disabled={true}
          style={{
            "dropdown_container__selected_value": "search-options__dropdown-value",
            "dropdown_container__list": "search-options__dropdown-list",
          }}
        />
      </div>
    </div>
  );
}

function ALDateRange(props) {
  const { startDate, endDate, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="al-date-range">
      <input
        className="al-date-range__input"
        type="button"
        value={
          startDate || endDate
            ? `${(startDate && startDate.format("MM/DD/YYYY")) || ""} — ${(endDate && endDate.format("MM/DD/YYYY")) || ""}`
            : "Select dates"
        }
        onClick={() => setIsOpen(!isOpen)}
        // onBlur={(e) => console.log(e) || setIsOpen(false)}
      />
      {isOpen ? (
        <div className="al-date-range__overlay">
          <DateRange
            onChange={(e) => console.log("DateRange:", e) || onChange(e)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            // onlyClasses={true}
            theme={{
              DateRange: {
                background: "unset",
              },
              Calendar: {
                backgroundColor: "#ffffff",
                width: "290px",
                // borderRadius: "20px",
                // boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                marginRight: "10px",
              },
              Weekday: {
                color: "#3373C3",
              },
              DayStartEdge: {
                background: "#D0D7E1",
                // borderRadius: "12px",
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
                color: "#3373C3",
              },
              DayEndEdge: {
                background: "#D0D7E1",
                // borderRadius: "12px",
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
                color: "#3373C3",
              },
              DayInRange: {
                background: "#D0D7E1",
                color: "#000000",
              },
              Day: {
                height: "30px",
                lineHeight: "30px",
              },
              MonthButton: {
                cursor: "pointer",
              },
            }}
            startDate={startDate}
            endDate={endDate}
          />
          <i className="material-icons al-date-range__overlay__close" onClick={() => console.log("FFF") || setIsOpen(false)}>
            close
          </i>
        </div>
      ) : null}
    </div>
  );
}

function ReportingTable(props) {
  function makeColumns(contentList, classNameList = []) {
    return (
      <>
        {contentList.map((content, idx) => (
          <div
            key={idx}
            className={`reporting-table__column reporting-table__column--col${idx + 1} ${
              classNameList[idx] ? `reporting-table__column--${classNameList[idx]}` : ""
            }`}>
            {content}
          </div>
        ))}
      </>
    );
  }

  const { current, previous, compare } = props.data || { current: null, previous: null, compare: null };
  return (
    <div className="reporting-table">
      <div className="reporting-table__line reporting-table__line--header">
        {makeColumns(["", "Current", "Previous", "Compare"], [null, "header", "header", "header"])}
      </div>
      <div className="reporting-table__higlighted">
        <div className="reporting-table__line reporting-table__line--highlight">
          {makeColumns(
            [
              "Number of videos",
              defaultFmt(current?.numberOfContent),
              defaultFmt(previous?.numberOfContent),
              defaultFmt(compare?.numberOfContent, "", "%"),
            ],
            ["highlight", "highlight", "highlight", compare?.numberOfContent ? (compare?.numberOfContent >= 100 ? "green" : "red") : "highlight"]
          )}
        </div>
        <div className="reporting-table__line reporting-table__line--highlight">
          {makeColumns(
            [
              "Average Price",
              defaultFmt(current?.averagePrice, "$"),
              defaultFmt(previous?.averagePrice, "$"),
              defaultFmt(compare?.averagePrice, "", "%"),
            ],
            ["highlight", "highlight", "highlight", compare?.averagePrice ? (compare?.averagePrice <= 100 ? "green" : "red") : "highlight"]
          )}
        </div>
        <div className="reporting-table__line reporting-table__line--highlight">
          {makeColumns(
            ["Total Price", defaultFmt(current?.totalPrice, "$"), defaultFmt(previous?.totalPrice, "$"), defaultFmt(compare?.totalPrice, "", "%")],
            ["highlight", "highlight", "highlight", compare?.totalPrice ? (compare?.totalPrice <= 100 ? "green" : "red") : "highlight"]
          )}
        </div>
      </div>
      <div className="reporting-table__line">{makeColumns(["CPV", "$X.XX", "$X.XX", "XX%"], [null, null, null, null])}</div>
      <div className="reporting-table__line reporting-table__line--odd">
        {makeColumns(["CPC", "$X.XX", "$X.XX", "XX%"], [null, null, null, null])}
      </div>
      <div className="reporting-table__line">{makeColumns(["CTR", "X.XX%", "X.XX%", "XX%"], [null, null, null, null])}</div>
      <div className="reporting-table__line reporting-table__line--odd">
        {makeColumns(["CVR", "X.XX%", "$X.XX", "XX%"], [null, null, null, null])}
      </div>
      <div className="reporting-table__line">{makeColumns(["AOV", "$X.XX", "$X.XX", "XX%"], [null, null, null, null])}</div>
      <div className="reporting-table__line reporting-table__line--odd">{makeColumns(["CR", "X.XX%", "$X.XX", "XX%"], [null, null, null, null])}</div>
      <div className="reporting-table__line">{makeColumns(["CPA", "$X.XX", "$X.XX", "XX%"], [null, null, null, null])}</div>
    </div>
  );
}

function Reporting(props) {
  const [type, setType] = useState("youtube");
  const [accountManagers, setAccountManagers] = useState([]);
  const [selectedAccountManager, setSelectedAccountManager] = useState("");
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [reportingData, setReportingData] = useState(null);
  const [influencers, setInfluencers] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [influencersPage, setInfluencersPage] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState(new Set());
  const [loading, setLoading] = useState({ isLoading: false, reason: "" });

  const typeOptions = [
    { key: "youtube", name: "YouTube" },
    { key: "instagram", name: "Instagram" },
    { key: "long-term", name: "Long term", _disabled: true },
    { key: "co-creation", name: "Co-creation", _disabled: true },
  ];

  function updateInfluencerData(index, update) {
    this.setState((state) => {
      let updateInfluencers = [...state.influencers];
      updateInfluencers[index] = { ...updateInfluencers[index], ...update };
      return { influencers: updateInfluencers };
    });
  }

  function loadInfluencersPage(influencer_list, influencers) {
    Promise.all(influencer_list.map((influencer) => loadInfluencerData(influencer))).then((resultInfluencerList) => {
      setInfluencers(
        resultInfluencerList.reduce((acc, resultInfluencer) => ({ ...acc, [resultInfluencer._id]: resultInfluencer }), { ...influencers })
      );
      setInfluencersPage(resultInfluencerList);
    });
  }

  const handleExport = async (influencers, selectedInfluencers) => {
    if (loading.isLoading) return;
    setLoading({ isLoading: true, reason: "Preparing export..." });
    const metaStringArray = (e) => (e === null || e === "null" ? "" : e.join("\n"));
    const metaAccountArray = (e, idField) => {
      if (e === null || e === "null") return "";
      return e.map((account) => (typeof account === "string" ? account : account[idField])).join("\n");
    };
    let headerFields = [
      { key: "emails", name: "Emails", metaFun: metaStringArray },
      { key: "youtube.accounts", name: "YouTube", metaFun: (e) => metaAccountArray(e, "channelId") },
      { key: "instagram.accounts", name: "Instagram", metaFun: (e) => metaAccountArray(e, "profileId") },
      { key: "current.metrics.averagePrice", name: "Average Price" },
      { key: "current.metrics.numberOfContent", name: "Number of content" },
      { key: "current.metrics.totalPrice", name: "Total Price" },
    ];
    const influencersIDToExport = selectedInfluencers.size ? Array.from(selectedInfluencers) : Object.keys(influencers);
    const resultInfluencerListToExport = await Promise.all(influencersIDToExport.map((id) => loadInfluencerData(influencers[id])));
    influencers = resultInfluencerListToExport.reduce((acc, resultInfluencer) => ({ ...acc, [resultInfluencer._id]: resultInfluencer }), {});
    const header = headerFields.map((e) => e.name).join(",") + "\r\n";
    const content = influencersIDToExport
      .map((influencer_id) => {
        const influencer = influencers[influencer_id];
        const line = headerFields.map(({ key, metaFun }) => {
          console.log("id", influencer_id, influencer);
          const object = _.get(influencer, key, "");
          if (metaFun) {
            return `"${metaFun(object)}"`;
          }
          return object === null || object === "null" ? '""' : `"${object}"`;
        });
        return line.join(",") + "\r\n";
      })
      .filter((e) => e !== null)
      .join("");
    // const content = influencers
    //   .map((influencer) => {
    //     if (selectedInfluencers.size && !selectedInfluencers.has(influencer._id)) return null;
    //     const line = headerFields.map(({ key, metaFun }) => {
    //       const object = _.get(influencer, key, "");
    //       if (metaFun) {
    //         return `"${metaFun(object)}"`;
    //       }
    //       return object === null || object === "null" ? '""' : `"${object}"`;
    //     });
    //     return line.join(",") + "\r\n";
    //   })
    //   .filter((e) => e !== null)
    //   .join("");
    let filename = `Reporting_${dateRange.startDate.format("MM.DD.YYYY")}-${dateRange.endDate.format("MM.DD.YYYY")}.csv`;
    let contentType = "text/csv;charset=utf-8;";
    let stringFile = header + content;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(stringFile))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href = "data:" + contentType + "," + encodeURIComponent(stringFile);
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    setLoading({ isLoading: false, reason: "" });
  };

  // Initial data load
  useEffect(() => {
    getAccountManagers().then((res) => setAccountManagers(res));
  }, []);

  // Search data
  useEffect(() => {
    if (!dateRange.startDate || !dateRange.endDate) return;
    setLoading({ isLoading: true, reason: "Calculating report..." });
    getReportingData(type, selectedAccountManager, dateRange).then((res) => {
      let newInfluencers = Object.keys(res.current.dealsByInfluencers).reduce(
        (acc, inf_id) => ({ ...acc, [inf_id]: { _id: inf_id, current: res.current.dealsByInfluencers[inf_id] } }),
        {}
      );
      newInfluencers = Object.keys(res.previous.dealsByInfluencers).reduce(
        (acc, inf_id) => (acc[inf_id] ? { ...acc, [inf_id]: { ...acc[inf_id], previous: res.previous.dealsByInfluencers[inf_id] } } : acc),
        newInfluencers
      );
      // newInfluencers = Object.keys(res.previous.dealsByInfluencers).reduce(
      //   (acc, inf_id) => ({ ...acc, [inf_id]: { ...acc[inf_id], _id: inf_id, previous: res.previous.dealsByInfluencers[inf_id] } }),
      //   newInfluencers
      // );
      const newInfluencersPage = Object.keys(newInfluencers)
        // .filter((inf_id) => newInfluencers[inf_id]?.current)
        .sort()
        .slice((1 - 1) * 10, 1 * 10)
        .map((inf_id) => newInfluencers[inf_id]);
      setInfluencers(newInfluencers);
      setInfluencersPage(newInfluencersPage);
      setPageNumber(1);
      setSelectedInfluencers(new Set());
      setReportingData(res);
      loadInfluencersPage(newInfluencersPage, newInfluencers);
      setLoading({ isLoading: false, reason: "" });
    });
  }, [type, selectedAccountManager, dateRange.startDate, dateRange.endDate]);

  // Change page
  useEffect(() => {
    const newInfluencersPage = Object.keys(influencers)
      .sort()
      .slice((pageNumber - 1) * 10, pageNumber * 10)
      .map((inf_id) => influencers[inf_id]);
    setInfluencersPage(newInfluencersPage);
    loadInfluencersPage(newInfluencersPage, influencers);
  }, [pageNumber]);

  console.log(dateRange);

  // if (dateRange.startDate) {
  //   console.log("start:", dateRange.startDate, dateRange.startDate.utc(false));
  // }
  console.log("influencersPage:", influencersPage);

  return (
    <div className="reporting">
      <ALSwitch options={typeOptions} value={type} onChange={setType} />
      <div className="reporting__filters">
        <ALDateRange onChange={setDateRange} {...dateRange} />
        <ALDropdown
          options={[{ "id": "", "title": "Select account manager" }, ...accountManagers]}
          useValue={"title"}
          useKey={"id"}
          value={selectedAccountManager}
          onChange={setSelectedAccountManager}
          style={{
            "dropdown_container__selected_value": "reporting__filters__dropdown-value",
            "dropdown_container__list": "reporting__filters__dropdown-list",
          }}
        />
        <ALDropdown
          options={[{ "key": "", "value": "Select influencer" }]}
          value={""}
          onChange={() => console.log("change")}
          disabled={true}
          style={{
            "dropdown_container__selected_value": "reporting__filters__dropdown-value",
            "dropdown_container__list": "reporting__filters__dropdown-list",
          }}
        />
      </div>

      {loading.isLoading ? (
        <div className="influencer_search__loading">
          <Loading />
          <p className="influencer_search__loading__reason">{loading.reason || "Please wait..."}</p>
        </div>
      ) : null}

      <div className="reporting__summary-result">
        <ReportingTable data={reportingData} />
      </div>

      {loading.isLoading ? (
        <div className="influencer_search__loading">
          <Loading />
          <p className="influencer_search__loading__reason">{loading.reason || "Please wait..."}</p>
        </div>
      ) : null}

      {!_.isEmpty(influencers) ? (
        <>
          <div className="influencer_search__page-info">
            <div className="influencer_search__page-info__number">
              {(pageNumber - 1) * 10 + 1} of {Object.keys(influencers).length} results
            </div>
            <div className="influencer_search__page-info__nav">
              <span className="material-icons" onClick={() => (pageNumber > 1 ? setPageNumber(pageNumber - 1) : null)}>
                chevron_left
              </span>
              <span
                className="material-icons"
                onClick={() =>
                  (pageNumber - 1) * 10 + influencersPage.length < Object.keys(influencers).length ? setPageNumber(pageNumber + 1) : null
                }>
                chevron_right
              </span>
            </div>
          </div>
          <ReportingToolLine
            influencersPage={influencersPage}
            selectedInfluencers={selectedInfluencers}
            setSelectedInfluencers={setSelectedInfluencers}
            selectedSort={null}
            setSelectedSort={null}
            influencersCount={influencers.length}
            handleExport={() => handleExport(influencers, selectedInfluencers)}
          />
          <ReportingResult
            influencers={influencersPage}
            page={pageNumber}
            setInfluencers={setInfluencers}
            selectedInfluencers={selectedInfluencers}
            setSelectedInfluencers={setSelectedInfluencers}
          />
        </>
      ) : null}
      {/* <div className="influencer_search__tool-line">
        <div className="influencer_search__tool-line-left">
          <input
            className="search-results__influencer__checkbox"
            type="checkbox"
            checked={influencersPage.every((influencer) => selectedInfluencers.has(influencer._id))}
            onClick={() => {
              if (influencersPage.every((influencer) => selectedInfluencers.has(influencer._id))) {
                return this.setState(({ selectedInfluencers }) => {
                  let newSelectedInfluencers = new Set(selectedInfluencers);
                  influencersPage.forEach((influencer) => newSelectedInfluencers.delete(influencer._id));
                  return { selectedInfluencers: newSelectedInfluencers };
                });
              }
              this.setState(({ selectedInfluencers }) => {
                let newSelectedInfluencers = new Set(selectedInfluencers);
                influencersPage.forEach((influencer) => newSelectedInfluencers.add(influencer._id));
                return { selectedInfluencers: newSelectedInfluencers };
              });
            }}
          />
          <button
            className="influencer_search__tool-line__export"
            onClick={() => {
              this.export();
              // this.props
              //   .showModal("ModalSearchExport")
              //   .then((result) => this.export(result))
              //   .catch((err) => console.error(err));
            }}>
            EXPORT{" "}
            {selectedInfluencers.size
              ? `${selectedInfluencers.size} SELECTED`
              : influencers.length === total.value
              ? "ALL"
              : `FIRST ${influencers.length}`}
          </button>
        </div>
        <div className="influencer_search__tool-line-right">
          <div className="influencer_search__tool-line-sort__title">Sort by</div>
          <ALDropdown
            options={this.sortOptions}
            useValue={"name"}
            useKey={"id"}
            value={selectedSort}
            onChange={this.onSortSelection}
            style={{
              "dropdown_container__selected_value": "search-options__dropdown-value",
              "dropdown_container__list": "search-options__dropdown-list",
            }}
          />
        </div>
      </div> */}
    </div>
  );
}

export default Reporting;
