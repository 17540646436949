import React from "react";
import { ALContainer, ALButton } from "./ALComponents";
import "./ModalConfirmation.css";

const ModalHead = (props) => (
  <div className="modal-head">
    <div className="modal-head__line">
      <h1 className="modal-head__line__title">{ props.title || "Confirmation"}</h1>
      <div className="modal-head__line__button">
        <ALButton type="text" material_icon="clear" onClick={props.close} />
      </div>
    </div>
    {/* <hr className="modal-head__separator" /> */}
  </div>
);
const ModalFoot = (props) => (
  <div className="modal-foot">
    <div className="modal-foot__cancel">
      <ALButton type="contained" onClick={props.close}>
        {props.textCancel || "Cancel"}
      </ALButton>
    </div>
    <div className="modal-foot__separator"></div>
    <div className="modal-foot__continue">
      <ALButton type="contained" onClick={props.confirm}>
        {props.textContinue || "Continue"}
      </ALButton>
    </div>
  </div>
);

const ModalConfirmation = (props) => (
  <ALContainer className={`confirmation-modal ${props.style?.container || ""}`}>
    <ModalHead close={() => props.resume("close")} title={props.title} />
    <div className="confirmation-modal__content">
      {props.content || null}
      {props.text && props.text.split("\n").map((text, i) => <p key={i}>{text}</p>)}
    </div>
    {props.noActions ? null : <ModalFoot close={() => props.resume("close")} confirm={() => props.resume("confirm")} />}
  </ALContainer>
);

export default ModalConfirmation;
