import { useCallback, useMemo, useState } from "react";
import { orderBy } from "lodash";

export default function useSortedDeals(deals) {
  const [sortKey, setSortKey] = useState("date_follow_up");
  const [sortDirection, setSortDirection] = useState("asc");

  const sortedDeals = useMemo(() => {
    let orderKeys = [sortKey];

    if ("payment_percentage" === sortKey) {
      // Sort first by 'payment_percentage', then last transaction status, then by 'price'
      orderKeys.push((deal) => {
        const rank = {
          "processing": 1,
          "requested": 2,
          "completed": 3,
        };
        return rank[deal.last_transaction_status] || 4;
      }, "price");
    }

    return orderBy(deals, orderKeys, orderKeys.map(() => sortDirection));
  }, [deals, sortKey, sortDirection]);

  const setSort = useCallback(
    (newSortKey, newSortDirection) => {
      if (!newSortDirection && sortKey === newSortKey) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      }
      if (newSortDirection) {
        setSortDirection(newSortDirection);
      }
      if (newSortKey) {
        setSortKey(newSortKey);
      }
    },
    [sortDirection, sortKey]
  );

  return useMemo(
    () => [sortedDeals, sortKey, sortDirection, setSort],
    [setSort, sortDirection, sortKey, sortedDeals]
  );
}
