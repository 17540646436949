import axios from "axios";
import { chunk, uniq } from "lodash";

import { sleep } from "../../utils";

import { SUPPORTED_PLATFORMS } from "./constants";

async function createInfluencerInDB(influencer) {
  await sleep(Math.floor(Math.random() * 1000)); // Mitigate duplicate
  try {
    const influencerResult = await axios.post("/api/v1/influencers?allow_no_platform=true", influencer);
    if (influencerResult.status !== 200) {
      throw influencerResult.data.message;
    }
    return influencerResult.data.result;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function createDealInDB(deal, influencerId) {
  try {
    const dealsResult = await axios.post(`/api/v1/influencers/${influencerId}/deals`, deal);
    if (dealsResult.status !== 200) {
      throw dealsResult.data.message;
    }
    return dealsResult.data;
  } catch (error) {
    throw error.response.data.message;
  }
}

async function mergeInfluencerInDB(newData, existingInfluencer) {
  const updatedInfluencer = {};

  // Merge platforms
  SUPPORTED_PLATFORMS.forEach((platform) => {
    const existingInfluencerPlatform = { ...existingInfluencer[platform], accounts: existingInfluencer[platform]?.accounts || [] };
    updatedInfluencer[platform] = {
      ...existingInfluencerPlatform,
      accounts: uniq([...existingInfluencerPlatform.accounts, ...(newData[platform]?.accounts || [])]),
    };
  });

  // Merge emails
  updatedInfluencer["emails"] = uniq([...(existingInfluencer["emails"] || []), ...(newData["emails"] || [])]);

  // Merge tags
  const currentTagIDs = existingInfluencer["tags"].map((tag) => tag.id);
  const newTagIDs = newData["tags"].map((tag) => tag.id);
  updatedInfluencer["tags"] = uniq([...currentTagIDs, ...newTagIDs]).map((tagID) => ({ id: tagID }));

  // Merge campaign tags
  const currentCampaignTagIDs = existingInfluencer["campain_tags"].map((tag) => tag.id);
  const newCampaignTagIDs = newData["campain_tags"].map((tag) => tag.id);
  updatedInfluencer["campain_tags"] = uniq([...currentCampaignTagIDs, ...newCampaignTagIDs]).map((tagID) => ({ id: tagID }));

  // Update status
  updatedInfluencer["status"] = newData["status"];

  try {
    const influencerResult = await axios.put("/api/v1/influencers?", updatedInfluencer, { params: { _id: existingInfluencer._id } });
    if (influencerResult.status !== 200) {
      throw influencerResult.data.message;
    }
    return influencerResult.data.result;
  } catch (error) {
    throw error.response.data.message;
  }
}

// Process the correct operation (merge or create) then create a deal if needed
async function processInfluencer(influencerAndDeals) {
  let influencerId = null;
  if (influencerAndDeals.mergeStrategy === "MERGE") {
    await mergeInfluencerInDB(influencerAndDeals.influencer, influencerAndDeals.destinationInfluencer);
    influencerId = influencerAndDeals.destinationInfluencer._id;
  } else if (influencerAndDeals.mergeStrategy === "NEW") {
    const createdInfluencer = await createInfluencerInDB(influencerAndDeals.influencer);
    influencerId = createdInfluencer._id;
  }
  if (influencerAndDeals.deals.length) {
    await Promise.all(influencerAndDeals.deals.map((deal) => createDealInDB(deal, influencerId)));
  }
}

export async function createAndMergeInfluencers(influencersProcessing, setInfluencersProcessing) {
  const chunks = chunk(
    influencersProcessing.filter((influencerAndDeals) => ["MERGE", "NEW"].includes(influencerAndDeals.mergeStrategy)),
    10
  );

  for (const chunk of chunks) {
    chunk.forEach((influencerAndDeals) => {
      influencerAndDeals.status = `[${influencerAndDeals.mergeStrategy}] DB Update...`;
    });
    setInfluencersProcessing(influencersProcessing);
    await Promise.all(chunk.map((influencerAndDeals) => processInfluencer(influencerAndDeals)));
    chunk.forEach((influencerAndDeals) => {
      influencerAndDeals.status = `[${influencerAndDeals.mergeStrategy}] DB Update...Done!`;
    });
    setInfluencersProcessing(influencersProcessing);
  }
}
