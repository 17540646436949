import React from "react";
import "./ModalHowToNewInfluencer.css";
import { ALButton, ALContainer } from "../ALComponents";

const ModalHead = (props) => (
  <div className="modal-head">
    <div className="modal-head__line">
      <h1 className="modal-head__line__title">How to</h1>
      <div className="modal-head__line__button">
        <ALButton type="text" material_icon="clear" onClick={props.close} />
      </div>
    </div>
    <hr className="modal-head__separator" />
  </div>
);

const ModalFoot = (props) => (
  <div className="modal-foot">
    <ALButton type="text" material_icon="clear" onClick={props.close}>Back</ALButton>
  </div>
)

class ModalHowToNewInfluencer extends React.Component {
  render() {
    return (
      <ALContainer className="how-to-new-influencer-modal">
        <ModalHead close={() => this.props.resume()} />
        <div className="how-to-new-influencer-modal__content">
          <h2>Purpose</h2>
          <p>Add influencer if they do not exist in <a href="/a/dashboard/outreach">Outreach</a></p>
          <h2>Usage</h2>
          <p>1) Insert YouTube Channel Id or Instagram Handle</p>
          <p>Keep in mind that we always get more information from a YouTube (Country, Emails, ...). Try to add an influencer using the channel Id. If it's not possible, use Influencer's Instagram Handle (without the @)</p>
          <p>2) Click on <span className="material-icons" style={{ fontSize: '22px'}}>play_circle_outline</span> to search the ChannelId / Handle in the influencer DataBase</p>
          <p>Then follow the steps displayed on the screen.</p>
          <p>This feature will NOT create a new deal. If you want to do so, please <a href="/a/dashboard/outreach">click here</a></p>
          <ModalFoot close={() => this.props.resume()} />
        </div>
      </ALContainer>
    );
  }
}

export default ModalHowToNewInfluencer;
