import { isToday, isPast } from "date-fns";

import { FILTER_CRITERIA } from "../DealsList";

import "./TabbedNavigation.css";
import { isDealActive } from "utils";

function TabbedNavigation({ deals, selectedTab, setSelectedTab }) {
  return (
    <div className="tabbed-navigation">
      {Object.keys(FILTER_CRITERIA).map((key) => {
        const numberOfDeals = deals.filter((deal) => {
          if (key === "date_follow_up" && isDealActive(deal)) {
            const dateFollowUp = new Date(deal.date_follow_up);
            return isToday(dateFollowUp) || isPast(dateFollowUp);
          }
          return deal.status === key;
        }).length;

        return (
          <button
            type="button"
            key={key}
            className={`${selectedTab === key ? "active" : ""}`}
            onClick={() => setSelectedTab(key)}
          >{`${FILTER_CRITERIA[key]} (${numberOfDeals})`}</button>
        );
      })}
    </div>
  );
}

export default TabbedNavigation;
