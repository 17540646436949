import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import chunk from "lodash/chunk";
import InfiniteScroll from "react-infinite-scroll-component";

import { ALDropdown, ALLoading } from "./ALComponents";
import NotificationManager from "./al_components/notification/NotificationManager";
import "./ScrappingKeywords.css";

const KEYWORDS_CHUNK_SIZE = 2000;

function DisplayKeywords({ keywords, removeKeyword }) {
  const pageSize = 100;
  const [page, setPage] = useState(1);
  const displayedKeywords = keywords.slice(0, pageSize * page);

  return (
    <InfiniteScroll
      dataLength={displayedKeywords.length}
      next={() => setPage(page + 1)}
      hasMore={displayedKeywords.length < keywords.length}
      endMessage={<p>- End -</p>}
      scrollableTarget={"keywords"}
    >
      {displayedKeywords.map((k, i) => {
        return (
          <div className="keyword" key={k["uid"]}>
            <span className="keyword-id">{i + 5}</span>
            <span className="keyword-kw">{k["keyword"]}</span>
            <span className="keyword-author">{k["author"]}</span>
            <span className="keyword-date">{moment(k["date"]).from(moment())}</span>
            <div className="keyword-delete" onClick={() => removeKeyword(k["keyword"])}>
              <span className="material-icons">delete</span>
            </div>
          </div>
        );
      })}
    </InfiniteScroll>
  );
}

class ScrappingKeywords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseKeywords: [],
      keywords: [],
      newKeyword: "",
      isUploading: false,
      keyworkUploadStrategy: "",
      uploadingMessageStatus: "",
    };
    this.hiddenFileInput = React.createRef();
    this.removeKeyword = this.removeKeyword.bind(this);
    this.addKeyword = this.addKeyword.bind(this);
    this.getKeywords = this.getKeywords.bind(this);
    this._onFocusNewKeyword = this._onFocusNewKeyword.bind(this);
    this._onBlurNewKeyword = this._onBlurNewKeyword.bind(this);
    this._uuidv4 = this._uuidv4.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  _handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (!this.state.keyworkUploadStrategy) {
        return this.handlePositionNotSelected();
      }
      this.addKeyword();
    }
  };
  _uuidv4() {
    return "xxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  _onFocusNewKeyword() {
    document.addEventListener("keydown", this._handleKeyDown);
  }
  _onBlurNewKeyword() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  componentDidMount() {
    this.getKeywords();
  }

  getKeywords() {
    let _this = this;
    axios
      .get("/api/v1/redis/keywords")
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          let keywords = res.data.data;

          keywords.forEach((k, i) => {
            k["uid"] = _this._uuidv4();
          });

          let _fullKeywords = keywords.splice(4, keywords.length);
          this.setState({
            baseKeywords: keywords,
            keywords: _fullKeywords,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error("Error", "Please contact Francois (Redis Down)", 6000);
      });
  }

  removeKeyword(kw) {
    let _this = this;
    axios.delete(`/api/v1/redis/keyword/${kw}`).then(function (response) {
      console.log(response);
      if (response.data.message === "error") {
        NotificationManager.error(`Type: ${response.data.type}`, response.data.error, 1000);
      } else {
        let keywords = response.data.data;
        keywords.forEach((k, i) => {
          k["uid"] = _this._uuidv4();
        });
        _this.setState({
          keywords: keywords,
        });
        NotificationManager.success("", response.data.message, 1000);
      }
    });
  }

  addKeyword() {
    let _this = this;
    axios
      .post(
        `/api/v1/redis/keyword?keyword=${this.state.newKeyword}&author=${this.props.user.email || "oklos@analuisa.com"}&strategy=${
          this.state.keyworkUploadStrategy
        }`
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.message === "error") {
            NotificationManager.error("Error", response.data.error, 6000);
          } else {
            _this.getKeywords();
            _this.setState({ newKeyword: "" });
          }
        }
      });
  }

  async handleFileUpload(event) {
    let _this = this;
    this.setState({ isUploading: true });

    var file = event.target.files[0];
    var reader = new FileReader();

    const onFileRead = async (event) => {
      let allKeywords = event.target.result || "";

      // Create an array of keywords, remove any "," or ";" separators, trailing spaces or empty strings
      allKeywords = allKeywords
        .split("\n")
        .map((k) => k.replace(/,|;/g, "").trim())
        .filter((k) => !!k);

      // Chunk request so that if the list is too large it wont fail because of payload too large
      const keywordChunks = chunk(allKeywords, KEYWORDS_CHUNK_SIZE);
      let responses = [];

      for (let i = 0; i < keywordChunks.length; i++) {
        const response = await axios
          .post(`/api/v1/redis/keyword_batch?author=${_this.props.user.email}&strategy=${_this.state.keyworkUploadStrategy}`, {
            keywords: keywordChunks[i],
          })
          .catch(() => {
            return { data: { message: "error" } };
          });
        responses.push(response);
        _this.setState({ uploadingMessageStatus: `(${(i + 1) * KEYWORDS_CHUNK_SIZE}/${allKeywords.length})` });
      }

      const errorResponses = responses.filter((r) => r.data.message === "error");
      const allAreSuccessful = errorResponses.length === 0;
      const allAreFailure = errorResponses.length === responses.length;

      if (allAreSuccessful) {
        NotificationManager.success("", "Keywords uploaded successfully", 1000);
      } else if (allAreFailure) {
        NotificationManager.error("Error", "Encountered error, try again", 6000);
      } else {
        NotificationManager.warning("", "Some keywords were not uploaded, try again", 6000);
      }

      _this.setState({ isUploading: false, uploadingMessageStatus: "" });
      // Refetch keywords to keep list up to date
      _this.getKeywords();
    };

    reader.onload = function (event) {
      onFileRead(event);
    };
    reader.readAsText(file);
  }

  handlePositionNotSelected() {
    NotificationManager.error("Error", "Choose position first", 2000);
  }

  handleClick() {
    this.hiddenFileInput.current.click();
  }

  render() {
    const { keywords, baseKeywords, newKeyword } = this.state;

    return (
      <div className="scrapping_keyword">
        {keywords == null ? (
          <ALLoading text={`We are loading the keywords ${this.props.user.firstName}, please wait...`} />
        ) : (
          <>
            <div className="scrapping_keyword_wrapper">
              <div className="scrapping_keywords_position">
                <ALDropdown
                  options={[
                    { key: "", value: "Select add position" },
                    { key: "start", value: "Add to top" },
                    { key: "end", value: "Add to bottom" },
                  ]}
                  value={this.state.keyworkUploadStrategy}
                  onChange={(key) => this.setState({ keyworkUploadStrategy: key })}
                />
              </div>
              <div className="scrapping_keyword_new">
                <input
                  className="scrapping_keyword_new__input"
                  type="text"
                  onFocus={this._onFocusNewKeyword}
                  onBlur={this._onBlurNewKeyword}
                  value={newKeyword || ""}
                  placeholder="Add keyword..."
                  onChange={(e) => this.setState({ newKeyword: e.target.value })}
                />
                {newKeyword.length > 0 && (
                  <span className="scrapping_keyword_new__remove material-icons" onClick={(e) => this.setState({ newKeyword: "" })}>
                    search_off
                  </span>
                )}
                <div
                  className={`scrapping_keyword_new__add ${!this.state.keyworkUploadStrategy ? "button_disabled" : ""}`}
                  onClick={this.state.keyworkUploadStrategy ? this.addKeyword : this.handlePositionNotSelected}
                >
                  ADD
                  <span className="material-icons">add</span>
                </div>
              </div>
              <div className="scrapping_keywords_bulk">
                {this.state.isUploading ? (
                  <div className="scrapping_keywords_bulk_loading">
                    <p>Uploading keywords... {this.state.uploadingMessageStatus}</p>
                    <i className="al_loading_icon material-icons">loop</i>
                  </div>
                ) : (
                  <>
                    <span>Upload csv file with one keyword per line:</span>
                    <button
                      onClick={this.state.keyworkUploadStrategy ? this.handleClick : this.handlePositionNotSelected}
                      className={`al_button contained scrapping_keywords_bulk_button ${!this.state.keyworkUploadStrategy ? "button_disabled" : ""}`}
                    >
                      <span className="material-icons">cloud_upload</span>
                      Upload file
                    </button>
                    <input
                      type="file"
                      accept=".csv,.xlsx,.xls"
                      onChange={this.state.keyworkUploadStrategy && this.handleFileUpload}
                      ref={this.hiddenFileInput}
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </div>
            </div>
            {baseKeywords && baseKeywords.length === 0 && keywords && keywords.length === 0 ? (
              <p>No keywords to scrap</p>
            ) : (
              <div className="keywords" id="keywords">
                <p className="keywords--title">Current list ({baseKeywords.length + keywords.length} keywords)</p>
                <div className="keyword_headers">
                  <span className="keyword-sort">&nbsp;</span>
                  <span className="keyword-kw">Keyword</span>
                  <span className="keyword-author">Author</span>
                  <span className="keyword-date">Date</span>
                  <span className="keyword-delete">&nbsp;</span>
                </div>
                {baseKeywords.map((k, i) => {
                  if (i < 4) {
                    return (
                      <div className="keyword keyword--current" key={i}>
                        <span className="keyword-id">&nbsp;</span>
                        <span className="keyword-kw">{k["keyword"]}</span>
                        <span className="keyword-author">{k["author"]}</span>
                        <span className="keyword-date">{moment(k["date"]).from(moment())}</span>
                        <div className="keyword-delete">
                          <span className="material-icons"></span>
                        </div>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
                {keywords && keywords.length === 0 ? null : <DisplayKeywords keywords={keywords} removeKeyword={(kw) => this.removeKeyword(kw)} />}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default ScrappingKeywords;
