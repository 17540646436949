import React from "react";
import moment from 'moment';
import axios from 'axios';
import YouTubeModal from "./YouTubeModal";
import carrousel from '../../assets/insta_carrousel.png';
import comment from '../../assets/insta_comment.png';
import like from '../../assets/insta_like.png';
import verified from '../../assets/insta_verified.png';
import video from '../../assets/insta_video.png';
import logoYouTube from '../../assets/youtube_logo.png';
import logoYouTubeGrey from '../../assets/youtube_logo_grey.png';
import _ from 'lodash';
import "./InstaSwipe.css";

class InstaSwipe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instaBase: this.props.instagramBase,
      date: new Date(),
      isYouTubeMobalOpen: false,
      youtubeData: null
    };
    this.toggleYouTubeModal = this.toggleYouTubeModal.bind(this);
    this.checkYouTubeChannel = this.checkYouTubeChannel.bind(this);
  }

  componentDidMount() {
    const { handle, linkAccount } = this.props.instagramBase;
    this.checkYouTubeChannel(handle, linkAccount);
  }

  checkYouTubeChannel(handle, linkAccount) {
    axios({
      method: 'GET',
      url: '/api/v1/yt_insta/' + handle,
    }).then((r) => {
      if (r.data.message === 'ok') {
        // found channel
        this.setState({ youtubeData: r.data.channel });
      } else {
        console.log("NO DATA FOUND FROM YT COLLECTION FOR THIS CHANNEL 1");
        // check if link in account is a YouTube URL
        if (linkAccount && (linkAccount.includes("youtube") || linkAccount.includes("youtu.be"))) {
          if (linkAccount.includes("/channel/")) {
            var regexChannelId = /^(?:(http|https):\/\/[a-zA-Z-]*\.{0,1}[a-zA-Z-]{3,}\.[a-z]{2,})\/channel\/([a-zA-Z0-9_]{3,})$/
            var resultChannelId = regexChannelId.exec(linkAccount);
            if (resultChannelId) {
              if (resultChannelId[2]) {
                let channelId = resultChannelId[2];
                axios({
                  method: 'GET',
                  url: '/api/v1/youtube/' + channelId
                }).then((rChannel) => {
                  this.setState({ youtubeData: rChannel.data.channel });
                });
              }
            }
          } else {
            var regexVideoId = /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&]{10,12})/;
            var resultVideoId = regexVideoId.exec(linkAccount);
            if (resultVideoId) {
              if (resultVideoId[1]) {
                axios({
                  method: 'GET',
                  url: '/api/v1/aws/video/' + resultVideoId[1]
                }).then((rVideo) => {
                  const { channelId } = rVideo.data.result
                  if (rVideo.status === 200) {
                    axios({
                      method: 'GET',
                      url: '/api/v1/youtube/' + channelId
                    }).then((rChannel) => {
                      this.setState({ youtubeData: rChannel.data.channel });
                    });
                  }
                });
              }
            } else {
              // SHOULD BE USER!!
              console.log("TODO: user url");
            }
          }
        }
      }
    });
  }

  componentWillUnmount() {
    console.log('unmount');
  }

  nFormatter(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num;
  }


  toggleYouTubeModal() {
    this.setState({ isYouTubeMobalOpen: !this.state.isYouTubeMobalOpen });
  }

  updateInstagramDeclined() {
    this.props.onDeclined(this.state.instaBase);
  }

  updateInstagramValidate() {
    this.props.onOutreach(this.state.instaBase);
  }

  outreachYouTube(channelId) {
    const theChannel = {
      "channelId": channelId,
      "status": "toScrap"
    };

    axios({
      method: 'put',
      url: `/api/v1/youtube/${theChannel.channelId}`,
      data: theChannel
    }).then((r) => {
      if (r.status === 200) {
        this.toggleYouTubeModal();
        this.props.onDeclined(this.state.instaBase);
      }
    })
  }

  render() {
    const { bio, youtube, isVerified, email, engagementRate, followers, following, handle, name, numberPosts, posts, profilPicture, linkAccount} = this.props.instagramBase;
    const { youtubeData } = this.state;
    const avgLike = _.meanBy(posts, (p) => p.nbLike).toFixed(2);
    console.log('like');
    console.log(avgLike);
    return (
      <div className="instaswipe-wrapper">
        {(this.state.isYouTubeMobalOpen) ? (
        <div className="instaswipe-wrapper__youtubeData">
          {/* {"aliandreeamakeup" || "UCcng5aBy0ZmX7g2PfHf4gJA"} */}
          <YouTubeModal onOutreachYouTube={(channelId) => this.outreachYouTube(channelId)} channelId={(youtube) ? youtube : ""} youtubeData={(youtubeData) ? youtubeData : null} handleInsta={(handle) ? handle : ""} instaData={(this.props.instagramBase) ? this.props.instagramBase : null } closeModal={this.toggleYouTubeModal} />
        </div>
        ) : null}
        <div className="instaswipe-wrapper_info">
          <div className="instaswipe-wrapper_info__image">
            <a href={`https://www.instagram.com/${handle}`} target="_blank" rel="noopener noreferrer">
              <img src={profilPicture} alt="profile user" />
            </a>

            <div style={{"width": "calc(100% - 100px)"}}>
              <div className="wrapper_info__content__moreinfo">
                <p className="wrapper_info__content--handle">{handle}</p>
                {(isVerified) ? <img className="wrapper_info__content--verified" src={verified} alt="verifed" /> : null}
              </div>
              <div className="wrapper_info__content--email">{(email) ? (
                <div>
                  <span className="material-icons">email</span>{email}
                </div>
                ) : (null)}
              </div>
              <div className="wrapper_info__content__youtube" onClick={this.toggleYouTubeModal}>
                {(youtubeData) ? (
                  <img className="" src={logoYouTube} alt="youtube" />
                ) : (
                  <img className="" src={logoYouTubeGrey} alt="youtube" />
                )}
              </div>
            </div>
          </div>

          <div className="instaswipe-wrapper_info__content">
            <p className="wrapper_info__content--name">{name}</p>

            <p className="wrapper_info__content--bio">{bio}</p>
            {(linkAccount) ? (
              <p className="wrapper_info__content--link">
                <span>External Link:</span>
                <a href={linkAccount} rel="noopener noreferrer" target="_blank">{linkAccount}</a>
              </p>
            ) : (null)}



            <div className="wrapper_info__content--data">
              <p><span>{numberPosts}</span><span>Posts</span></p>
              <p><span>{engagementRate.toFixed(2)}%</span><span>Engagement</span></p>
              <p><span>{this.nFormatter(followers)}</span><span>Followers</span></p>
              <p><span>{avgLike}</span><span>Avg Likes</span></p>
              <p><span>{this.nFormatter(following)}</span><span>Following</span></p>
            </div>
          </div>
        </div>
        <div className="instaswipe-wrapper_posts">
          {posts.map((post, i) => {
            if (i < 4) {
              return (
                <div className="instaswipe-wrapper_posts__post" key={`insta_post_${i}`}>
                  <a href={`https://www.instagram.com/p/${post.shortcode}`} className="wrapper_posts__post__image" target="_blank" rel="noopener noreferrer">
                    <img src={post.image} alt="content" />
                    {(post.type === 'carousel') ? <img className="wrapper_posts__post__image--type" src={carrousel} alt="carrousel" /> : null}
                    {(post.type === 'video') ? <img className="wrapper_posts__post__image--type" src={video} alt="video" /> : null}
                    <div className="wrapper_posts__post__content_likes">
                      <p><img src={like} alt="like" />{post.nbLike}</p>
                      <p><img src={comment} alt="comment" />{post.nbComment}</p>
                    </div>
                  </a>
                  <p style={{ textAlign: "center", marginBottom: "0px", fontSize: "12px" }}>{moment(post.datePublished, "YYYY-MM-DDTHH:mm:ss").from(moment())}</p>
                  <div className="wrapper_posts__post__content">
                    <p>{post.description}</p>
                  </div>
                </div>
              );
            }
          })}
          <div className="instaswipe-wrapper_buttons" ref={(el) => { this.messagesEnd = el; }}>
            <button className="mdl-button mdl-js-button channel_database--no" onClick={() => this.updateInstagramDeclined()}>
              NO <i className="material-icons">clear</i>
            </button>
            <button className="mdl-button mdl-js-button channel_database--yes" onClick={() => this.updateInstagramValidate()}>
              YES <i className="material-icons">favorite</i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default InstaSwipe;
