import axios from "axios";

const FETCH_USER = 'fetch_user';
export const fetchUser = () => {
    return function(dispatch) {
        axios.get("/auth/current_user").then(res => dispatch({ type: FETCH_USER, payload: res.data })).catch(err => {
            console.log(err);
            dispatch({ type: FETCH_USER, payload: false })
        });
    };
};
