import React from "react";
import axios from 'axios';
import _ from 'lodash';
import Loading from "../Loading";
import "./tags_influencer.css";

class ALCampaignInfluencer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      campaigns: null,
      searchValue: '',
      resultSearch: null,
      campaignsInfluencer: this.props.campaignsInfluencer,
      showMostPopular: this.props.showMostPopular
    };
    this.searchInput = this.searchInput.bind(this);
    this.addCampaignInfluencer = this.addCampaignInfluencer.bind(this);
    this.removeCampaignInfluencer = this.removeCampaignInfluencer.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.campaignsInfluencer != this.props.campaignsInfluencer || prevProps.showMostPopular != this.props.showMostPopular) {
      this.setState({
        campaignsInfluencer: this.props.campaignsInfluencer,
        showMostPopular: this.props.showMostPopular
      });
    }
  }

  componentDidMount() {
    axios.get("/api/v1/campain_tags").then(res => {
      if (res.status === 200) {
        this.setState({ campaigns: res.data.tags });
      }
    });
  }

  searchInput(value) {
    let campaigns = this.state.campaigns;

    if (value == "") {
      this.setState({ resultSearch: campaigns, searchValue: "" });
      return;
    }
    value = value.toLocaleLowerCase();

    value = value.toLocaleLowerCase();
    if (value[value.length - 1] == " ") {
      // check if last value is a "SPACE"
      let listSearchCampaigns = campaigns.filter((el) => {
        return el["name"].toLocaleLowerCase() === value.trim();
      });
      // if found ONLY ONE element in list add it + reset search
      if (listSearchCampaigns.length === 1) {
        this.addCampaignInfluencer(listSearchCampaigns[0]["_id"]);
        this.setState({
          searchValue: "",
          resultSearch: this.state.campaigns,
        });
        return;
      }
    }

    let listSearchCampaigns = campaigns.filter((el) => {
      return el["name"].toLocaleLowerCase().indexOf(value) > -1;
    });

    this.setState({
      searchValue: value,
      resultSearch: listSearchCampaigns,
    });
  }

  addCampaignInfluencer(campaignId) {
    if (this.state.updating || this.state.campaignsInfluencer.some(e => e._id === campaignId)) return;
    this.setState({updating: true});
    console.log("addCampaignInfluencer: " + " - " + campaignId + " - " + this.props.idInfluencer);
    axios.post(`/api/v1/influencers/${this.props.idInfluencer}/campain_tags`, { id: campaignId })
    .then(() => {
      console.log("campaign added!");
      axios.post("/api/v1/event", {
        type: "ADD_CAMPAIGN_INFLUENCER",
        user: this.props.user.email,
        sample: { object: this.props.idInfluencer, svalue: campaignId },
      }).catch((err) => {
        console.log(err);
        alert("Failed to fire event ADD_CAMPAIGN_INFLUENCER");
        this.setState({updating: false});
      });
      this.setState({campaignsInfluencer: [...this.state.campaignsInfluencer, this.state.campaigns.find(campaign => campaignId === campaign._id)], updating: false});
    })
    .catch(err => {
      console.log(err);
      alert("Failed to add campaign");
      this.setState({updating: false});
    });
  }

  removeCampaignInfluencer(campaignId) {
    console.log("removeCampaignInfluencer: " + this.props.idInfluencer);
    // TODO: REMOVE TAG + DECREASE COUNT
    axios.delete(`/api/v1/influencers/${this.props.idInfluencer}/campain_tags/id/${campaignId}`)
    .then(() => {
      console.log("campaigns deleted!");
      axios.post("/api/v1/event", {
        type: "REMOVE_CAMPAIGN_INFLUENCER",
        user: this.props.user.email,
        sample: { object: this.props.idInfluencer, svalue: campaignId },
      }).catch((err) => console.log(err)||alert("Failed to fire event REMOVE_CAMPAIGN_INFLUENCER"));

      this.setState({campaignsInfluencer: this.state.campaignsInfluencer.filter(campaign => campaignId !== campaign._id)})
    })
  }

  render() {
    const { campaigns, resultSearch, showMostPopular, searchValue } = this.state;
    const allowedCampaigns = campaigns && campaigns.filter(campaign => !this.state.campaignsInfluencer.some(influencer_campaign => influencer_campaign._id === campaign._id));
    const allowedResultSearch = resultSearch && resultSearch.filter(campaign => !this.state.campaignsInfluencer.some(influencer_campaign => influencer_campaign._id === campaign._id));

    return (
      <div className="al_tag_influencer">
        <p className="al_tag_influencer--title">Campaigns:</p>
        <div className="al_tag_influencer_tags">
          {this.state.campaignsInfluencer.map((campaign, i) => <div className="al_tag_influencer_tags--tag" key={i}>
            <p>{campaign.name}</p>
            <span className="material-icons" onClick={() => {this.removeCampaignInfluencer(campaign._id)}}>close</span>
          </div>)}
        </div>
        
        {(showMostPopular) ? (
          <p className="al_tag_influencer--title">Most popular:</p>
        ) : (null)}
        {(allowedCampaigns) ? (
          <div>
            {(showMostPopular) ? (
              <div className="al_tag_influencer_popular">
                {allowedCampaigns.map((campaign, i) => {
                  if (i < 5) {
                    return (
                      <div
                        className="al_tag_influencer_popular--tag"
                        key={`campaign_${i}`}
                        onClick={() => this.addCampaignInfluencer(campaign._id)}
                      >
                        <p>{campaign.name}</p>
                      </div>
                    )
                  }
                })}
              </div>
            ) : (null)}
            <div className="al_tag_influencer_search">
              <div className="al_tag_influencer_search_input">
                <input className="al_tag_influencer_search_input--field" placeholder="Search tag" value={searchValue} onFocus={() => { this.searchInput("") }} onChange={(e) => { this.searchInput(e.target.value); }} />
                <span className="al_tag_influencer_search--icon material-icons">search</span>
              </div>
              {(allowedResultSearch) ? (
                <div className="al_tag_influencer_search__result">
                  <div className="al_tag_influencer_search__result--close" onClick={() => { this.setState({ resultSearch: null }); }}>
                    <span className="material-icons">close</span>
                  </div>
                  {(allowedResultSearch.length == 0) ? (
                    <p className="al_tag_influencer_search__result--empty">No result for this search...</p>
                  ) : (
                    allowedResultSearch.sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date)).map((campaign, i) => {
                      return (
                        <div
                          className="al_tag_influencer_popular--tag"
                          key={`search_campaign_${i}`}
                          onClick={() => this.addCampaignInfluencer(campaign._id)}
                        >
                          <p>{campaign.name}</p>
                        </div>
                      );
                    })
                  )}
                </div>
              ) : (
                null
              )}
            </div>
          </div>
        ) : (
          <div className="al_tag_influencer_loading">
            <Loading />
          </div>
        )}

      </div>
    );
  }
}
export default ALCampaignInfluencer;