import React, { useState } from "react";
import axios from "axios";
import PropTypes from 'prop-types';

import { ALDropdown } from "../../ALComponents";
import NotificationManager from "../../al_components/notification/NotificationManager";
import { triggerStockTrackerDealUpdate } from "../../../utils";
import Content from './currentDealContent/Content';
import "./CurrentDealContent.css";
import CONTENT_TYPES from "./currentDealContent/contentTypes.json";

const CurrentDealContent = ({ user, deal, onDealUpdated }) => {
  const [isAddingContent, setIsAddingContent] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [type, setType] = useState(null);
  const [quantity, setQuantity] = useState('');

  const addNewContent = () => {
    const content = { platform, type, quantity };

    axios
      .put(`/api/v1/deals/${deal._id}`, { $push: { content: content } })
      .then(({ data }) => {
        axios
          .post("/api/v1/event", {
            type: "ADD_CONTENT_DEAL",
            user: user.email,
            sample: { object: deal._id },
          })
          .then(() => {
            NotificationManager.success(
              "Updated",
              "Successfully added new content to deal",
              1500
            );
            setIsAddingContent(false);
            onDealUpdated({ content: data.result.content });
          });
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Error",
          "Server failed to update deal, please try again later and send the page log to the tech team",
          6000
        );
      });
  };

  const updateDealContent = (index, dealContent, setIsEditing) => {
    let updatedDealContent = deal.content;
    updatedDealContent[index] = dealContent;

    axios
      .put(`/api/v1/deals/${deal._id}`, { content: updatedDealContent })
      .then(({ data }) => {
        axios
          .post("/api/v1/event", {
            type: "EDIT_CONTENT_DEAL",
            user: user.email,
            sample: { object: deal._id },
          })
          .then(() => {
            NotificationManager.success(
              "Updated",
              "Successfully updated content to deal",
              1500
            );
            setIsEditing(false);
            onDealUpdated({ content: data.result.content });

            // Trigger Stock Tracker deal update at deal content update
            triggerStockTrackerDealUpdate(deal._id);
          });
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Error",
          "Server failed to update deal content, please try again later and send the page log to the tech team",
          6000
        );
      });
  }

  const deleteDealContent = (index) => {
    let newDealContent = [...deal.content];
    delete newDealContent[index];

    axios
      .put(`/api/v1/deals/${deal._id}`, {
        $set: { content: newDealContent.filter((e) => e) },
      })
      .then(({ data }) => {
        axios
          .post("/api/v1/event", {
            type: "REMOVE_CONTENT_DEAL",
            user: user.email,
            sample: { object: deal._id },
          })
          .catch(
            (err) => console.log(err) || alert("Failed to fire event REMOVE_CONTENT_DEAL")
          );

        onDealUpdated({ content: data.result.content });
        NotificationManager.success(
          "Updated",
          "Successfully deleted deal content",
          1500
        );
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Error",
          "Server failed to update deal, please try again later and send the page log to the tech team",
          6000
        );
      });
  };

  const resetForm = () => {
    setIsAddingContent(!isAddingContent);
    setPlatform(null);
    setType(null);
  };

  return (
    <div className="oi_deal_content_container">
      <div className="oi_deal_content_container_header">
        <p className="oi_deal_content_container_header_title">Content</p>
        {!isAddingContent && (
          <div
            className="oi_deal_content_container_header_add"
            onClick={resetForm}
          >
            Add content
          </div>
        )}
      </div>

      {/* Add content form */}
      {isAddingContent && (
        <div className="oi_deal_content_container_content oi_deal_content_container_content_newcontent">
          <ALDropdown
            options={CONTENT_TYPES}
            useValue="title"
            useKey="value"
            value={type ? `${platform}_${type}` : CONTENT_TYPES[0]["value"]}
            onChange={(key) => {
              let arrType = key.split("_");
              setPlatform(arrType[0]);
              setType(arrType[1]);
            }}
          />

          {type === "story" && (
            <input
              placeholder="Quantity"
              className="oi_deal_content_container_content_newcontent_quantity"
              type="number"
              value={quantity}
              onChange={(e) => {
                if (e.target.value === "") {
                  setQuantity("");
                }
                if (parseInt(e.target.value) >= 0) {
                  setQuantity(parseInt(e.target.value));
                }
              }}
            />
          )}

          <div
            className="outreach_influencer_deal_update_button"
            onClick={addNewContent}
          >
            ADD NEW CONTENT <span className="material-icons">add</span>
          </div>
        </div>
      )}

      {deal.content.map((dealContent, i) => {
        return (
          dealContent && 
            <Content
              key={i}
              index={i}
              dealContent={{...dealContent}}
              deleteDealContent={deleteDealContent}
              updateDealContent={updateDealContent}
            />
        )
      })}
    </div>
  );
};

CurrentDealContent.propTypes = {
  user: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired,
  onDealUpdated: PropTypes.func.isRequired,
};

export default CurrentDealContent;