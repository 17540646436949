import React from "react";
import moment from 'moment';
import ModalEmbededVideo from "../YouTube/ModalEmbededVideo";
import "./YouTubeVideo.css";

class YouTubeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: this.props.data,
      embedVideo: false,
      isLoadingFullData: false,
    };
    this.openEmbededVideo = this.openEmbededVideo.bind(this);
    this.closeEmbededVideo = this.closeEmbededVideo.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.data !== this.state.video) {
      this.setState({ video: this.props.data });
    }
    if (this.state.isLoadingFullData && !this.props.data?._partialScrapping) {
      this.setState({ isLoadingFullData: false });
    }
  }
  openEmbededVideo() {
    if (this.state.video?._partialScrapping) {
      this.setState({ isLoadingFullData: true });
      this.props.getYouTubeVideoData(this.state.video.videoId, false);
    }
    this.setState({ embedVideo: true });
  }
  closeEmbededVideo() {
    this.setState({ embedVideo: false });
  }
  render() {
    const { video, embedVideo } = this.state;
    let strDuration = "";
    if (typeof(video) != "string" && video["duration"] !== null) {
      let durationVideo = moment.duration(parseInt(video.duration), 'seconds');
      if (durationVideo.hours() == 0) {
        strDuration = `${durationVideo.minutes()}:${(durationVideo.seconds() < 10) ? (`0${durationVideo.seconds()}`) : (durationVideo.seconds())}`;
      } else {
        strDuration = `${durationVideo.hours()}:${(durationVideo.minutes() < 10) ? (`0${durationVideo.minutes()}`) : (durationVideo.minutes())}:${(durationVideo.seconds() < 10) ? (`0${durationVideo.seconds()}`) : (durationVideo.seconds())}`;
      }
    }
    return (
      <div className="q_youtube__videos__video">
        {(embedVideo) ? (
          <ModalEmbededVideo
            video={this.state.embedVideo && this.state.video}
            closeModal={this.closeEmbededVideo}
            isLoadingFullData={this.state.isLoadingFullData}
            style={{
              right: "calc(27% + 31px)",
              backgroundColor: "#e8ecf1",
              width: "35%",
              borderLeft: "1px solid #D4D4D4",
              borderRight: "1px solid #D4D4D4",
              borderRop: "1px solid #D4D4D4"
            }}
          />
        ) : (null)}
        {(typeof(video) != "string") ? (
          <div>
            <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer" className="q_youtube__videos__video_image">
              <img src={video.pictureURL.replace("hqdefault", "mqdefault")} />
              <span className="q_youtube__videos__video_image--duration">{strDuration}</span>
              <p className="q_youtube__videos__video_image--genre">{video.genre}</p>
            </a>
            <div className="q_youtube__videos__video_infos">
              <div style={{width: '100%'}}>
                <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer" className="q_youtube__videos__video_infos--title">{video.title}</a>
                <p className="q_youtube__videos__video_infos--date">{moment(video.datePublished).from(moment())}</p>
                <p className="q_youtube__videos__video_infos--views">{(video.stats?.views !== undefined && new Intl.NumberFormat().format(video.stats.views)) || "NA"} views</p>
                {video.stats?.likes !== undefined || video.stats?.dislikes !== undefined && <div className="q_youtube__videos__video_infos_stats">
                  {video.stats?.likes !== undefined && <div className="q_youtube__videos__video_infos_stats--likes">
                    <span className="material-icons">thumb_up</span>
                    {new Intl.NumberFormat().format(video.stats.likes) || "NA"}
                  </div>}
                  {video.stats?.dislikes !== undefined && <div className="q_youtube__videos__video_infos_stats--dislikes">
                    <span className="material-icons">thumb_down</span>
                    {new Intl.NumberFormat().format(video.stats.dislikes) || "NA"}
                  </div>}
                </div>}
              </div>
              <div className="q_youtube__videos__video_infos--open_modal" onClick={() => this.openEmbededVideo(video.videoId)}>
                <span className="material-icons">open_in_new</span>
              </div>
            </div>
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

export default YouTubeVideo;

