import React from "react";
import HeaderComponent from "../HeaderComponent";
import axios from "axios";
import Barcode from 'react-barcode';
import { Link } from 'react-router-dom';
import Loading from "../Loading";
import {ALButton, ALLoading} from '../ALComponents';


import "./EngravablesContainer.css";

import slimNecklaceJIG from 'assets/engravables/engravable-bar-necklace-id-slim-necklace-JIG.png';
import circleJIG from 'assets/engravables/engravable-circle-pendant-circle-JIG.png';
import DogTagMediumJIG from 'assets/engravables/engravable-dog-tag-necklace-dog-tag-medium-JIG.png';
import DogTagMiniJIG from 'assets/engravables/engravable-dog-tag-necklace-dog-tag-mini-JIG.png';
import IDBoldBraceletJIG from 'assets/engravables/engravable-id-bracelet-id-bold-bracelet-JIG.png';
import IDSlimBraceletJIG from 'assets/engravables/engravable-id-bracelet-id-slim-bracelet-JIG.png';


import slimNecklacePDP from 'assets/engravables/210015-710-25-1.jpg';
import circlePDP from 'assets/engravables/210014-710-25-1.jpg';
import DogTagMediumPDP from 'assets/engravables/210013-710-25-1.jpg';
import DogTagMiniPDP from 'assets/engravables/210012-710-25-1.jpg';
import IDBoldBraceletPDP from 'assets/engravables/310003-710-20-1.jpg';
import IDSlimBraceletPDP from 'assets/engravables/310002-710-20-1.jpg';

import slimNecklacePreview from 'assets/engravables/210015-710-25-1_preview.jpg';
import circlePreview from 'assets/engravables/210014-710-25-1_preview.jpg';
import DogTagMediumPreview from 'assets/engravables/210013-710-25-1_preview.jpg';
import DogTagMiniPreview from 'assets/engravables/210012-710-25-1_preview.jpg';
import IDBoldBraceletPreview from 'assets/engravables/310003-710-20-1_preview.jpg';
import IDSlimBraceletPreview from 'assets/engravables/310002-710-20-1_preview.jpg';


function LeftPanel(props) {
  const {currentEngravableItem, numberEngravableItem, orderNumber, product} = props;
  return (
    <div className="engravable_leftpanel">
      <p className="engravable_leftpanel_title" style={{fontWeight: 'bold'}}>{product['name']}</p>
      <br />
      <p className="engravable_leftpanel_title">Text: {product['engraveText']}</p>
      <p className="engravable_leftpanel_title">Font: {product['engraveFont']}</p>
      <p className="engravable_leftpanel_title">Product {currentEngravableItem} of {numberEngravableItem}</p>
      <br />
      <p className="engravable_leftpanel_title">Order: {orderNumber}</p>
    </div>
  );
}

class EngravablesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      isLoadingOrder: false,
      numberEngravableItem: null,
      order: null,
      initStateEngravableItems: null,
      currentEngravableItem: null,
    };

    this.scannerInput = React.createRef();
    this.handleScan = this.handleScan.bind(this);
  }
  componentDidMount() {
    console.log('componentDidMount');
  }
  componentDidUpdate() {
    console.log('componentDidUpdate');
  }

  handleScan() {
    console.log("handleScan");
    let valueInput = this.scannerInput.current.value;

    valueInput = valueInput.replace("#", "");
    if ((!valueInput.includes("GE") && valueInput.length == 9) || (valueInput.includes("GE") && valueInput.length >= 13)) {
      // Get order details
      this.setState({ isLoadingNote: true });
      axios.get(`/api/v1/shop_order/${valueInput}`)
        .then((res) => {
          if (res.data.order) {
            console.log(res.data.order);
            let _order = res.data.order;
            // TODO:
            // [] - Build array of products to engrave
            // [] - Get number of engravable products
            let numberEngravableItem = 0;
            let initStateEngravableItems = [];
            _order.line_items.map((item, i) => {
              console.log(item.properties.some(p => p['name'] == 'engravable' && p['value'] == 'true'));
              if (item.properties.some(p => p['name'] == 'engravable' && p['value'] == 'true')) {
                numberEngravableItem += item['quantity'];
                let idxFont = item.properties.findIndex(p => p['name'] == "engravable_font");
                let idxText = item.properties.findIndex(p => p['name'] == "engravable_text");
                let idxBarcode = item.properties.findIndex(p => p['name'] == "line_item_barcode");
                for (var i = 0; i < item['quantity']; i++) {
                  initStateEngravableItems.push({
                    lineItemId: item['id'],
                    lineItemVariantId: item['variant_id'],
                    name: item['name'],
                    sku: item['sku'],
                    engraveFont: item.properties[idxFont]['value'],
                    engraveText: item.properties[idxText]['value'],
                    barcode: item.properties[idxBarcode]['value'],
                    showedJIG: false,
                    showedPreview: false,
                    showedEngraving: false,
                    showedConfirm: false,
                    showBarCode: false,
                    isDone: false,
                    isLoading: false,
                    userPrinted: false,
                  });
                }
              }
            });

            this.setState({
              order: res.data.order,
              currentEngravableItem: 0,
              numberEngravableItem: numberEngravableItem,
              initStateEngravableItems: initStateEngravableItems,
              isLoadingOrder: false,
              step: 1,
            });
          }
        })
        .catch(err => {
          // what now?
          console.log(err);
        })
      // reset input
      this.scannerInput.current.value = "";
      this.scannerInput.current.focus();
    }
  }


  // ORDER SHOWED
  // 1:   showedJIG
  // 2:   showedPreview
  // 3:   showedEngraving
  // 4:   showedConfirm
  // 5:   showBarCode
  // 6:   isDone

  render() {
    const { step, isLoadingOrder, currentEngravableItem, numberEngravableItem, order, initStateEngravableItems } = this.state;
    return (
      <div className="engravables_container">
        {/* STEP 0 - SCAN */}
        {(step === 0) ? (
          <div>
            <p>Scan Order</p>
            <div className="giftnotes__input">
              {(isLoadingOrder) ? (
                <Loading />
              ) : (
                <input
                  autoFocus
                  type="text"
                  className="giftnotes__input_input"
                  aria-describedby="order number"
                  placeholder="Scan"
                  onChange={this.handleScan}
                  defaultValue=''
                  ref={this.scannerInput}
                />
              )}
            </div>
          </div>
        ) : (null)}

        {/* STEP 1 - JIG + PRODUCT */}
        {(step === 1) ? (
          <>
            <LeftPanel
              currentEngravableItem={currentEngravableItem + 1}
              numberEngravableItem={numberEngravableItem}
              orderNumber={order.name}
              product={initStateEngravableItems[currentEngravableItem]}
            />
            
            {initStateEngravableItems[currentEngravableItem] ? (
              // STEP 1
              (
                initStateEngravableItems[currentEngravableItem]['name'] &&
                !initStateEngravableItems[currentEngravableItem]['showedJIG'] &&
                !initStateEngravableItems[currentEngravableItem]['showedPreview'] &&
                !initStateEngravableItems[currentEngravableItem]['showedEngraving'] &&
                !initStateEngravableItems[currentEngravableItem]['showedConfirm'] &&
                !initStateEngravableItems[currentEngravableItem]['showBarCode'] &&
                !initStateEngravableItems[currentEngravableItem]['isDone']
              ) ? (
                <div className='engravable_step'>
                  <div className='engravable_step_images'>
                    <div className='engravable_step_image'>
                      <p className='engravable_step_image_title'>Product</p>
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Bar Necklace - ID Slim Necklace') ? (
                        <img className='engravable_step_1_image_img' src={slimNecklacePDP} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Circle Pendant - Circle') ? (
                        <img className='engravable_step_1_image_img' src={circlePDP} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Dog Tag Necklace - Dog Tag Medium') ? (
                        <img className='engravable_step_1_image_img' src={DogTagMediumPDP} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Dog Tag Necklace - Dog Tag Mini') ? (
                        <img className='engravable_step_1_image_img' src={DogTagMiniPDP} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable ID Bracelet - ID Bold Bracelet') ? (
                        <img className='engravable_step_1_image_img' src={IDBoldBraceletPDP} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable ID Bracelet - ID Slim Bracelet') ? (
                        <img className='engravable_step_1_image_img' src={IDSlimBraceletPDP} />
                      ) : (null)}
                    </div>
                    <div className='engravable_step_image'>
                      <p className='engravable_step_image_title'>Jig Placement</p>
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Bar Necklace - ID Slim Necklace') ? (
                        <img className='engravable_step_1_image_img' src={slimNecklaceJIG} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Circle Pendant - Circle') ? (
                        <img className='engravable_step_1_image_img' src={circleJIG} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Dog Tag Necklace - Dog Tag Medium') ? (
                        <img className='engravable_step_1_image_img' src={DogTagMediumJIG} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Dog Tag Necklace - Dog Tag Mini') ? (
                        <img className='engravable_step_1_image_img' src={DogTagMiniJIG} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable ID Bracelet - ID Bold Bracelet') ? (
                        <img className='engravable_step_1_image_img' src={IDBoldBraceletJIG} />
                      ) : (null)}
                      {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable ID Bracelet - ID Slim Bracelet') ? (
                        <img className='engravable_step_1_image_img' src={IDSlimBraceletJIG} />
                      ) : (null)}
                    </div>
                  </div>
                  <div className='engravable_step_buttons'>
                    <ALButton
                      type="contained"
                      onClick={() => {
                        this.setState({
                          step: 0,
                          isLoadingOrder: false,
                          numberEngravableItem: null,
                          order: null,
                          initStateEngravableItems: null,
                          currentEngravableItem: null,
                        })
                      }}
                    >
                      Back
                    </ALButton>
                    <ALButton
                      className='al_button engravable_button_confirm'
                      type="contained"
                      onClick={() => {
                        this.setState(prevState => {
                          const initStateEngravableItems = [...prevState.initStateEngravableItems];
                          initStateEngravableItems[prevState.currentEngravableItem] = {
                            ...initStateEngravableItems[prevState.currentEngravableItem],
                            'showedJIG': true
                          };
                          return { initStateEngravableItems };
                        });
                      }}
                    >
                      Confirm
                    </ALButton>
                  </div>
                </div>
              ) : (
                // STEP 2
                (
                  initStateEngravableItems[currentEngravableItem]['name'] &&
                  initStateEngravableItems[currentEngravableItem]['showedJIG'] &&
                  !initStateEngravableItems[currentEngravableItem]['showedPreview'] &&
                  !initStateEngravableItems[currentEngravableItem]['showedEngraving'] &&
                  !initStateEngravableItems[currentEngravableItem]['showedConfirm'] &&
                  !initStateEngravableItems[currentEngravableItem]['showBarCode'] &&
                  !initStateEngravableItems[currentEngravableItem]['isDone']
                ) ? (
                  <div className='engravable_step'>
                    <div className='engravable_step_images'>
                      <div className='engravable_step_image'>
                        <p className='engravable_step_image_title'>File Name</p>
                        <div className='engravable_step_image_file'>
                          {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Bar Necklace - ID Slim Necklace') ? (
                            <p className='engravable_step_image_file_title'>ID Slim Necklace.gnh</p>
                          ) : (null)}
                          {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Circle Pendant - Circle') ? (
                            <p className='engravable_step_image_file_title'>circle Necklace.gnh</p>
                          ) : (null)}
                          {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Dog Tag Necklace - Dog Tag Medium') ? (
                            <p className='engravable_step_image_file_title'>Dog tag medium necklace.gnh</p>
                          ) : (null)}
                          {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Dog Tag Necklace - Dog Tag Mini') ? (
                            <p className='engravable_step_image_file_title'>Dog tag MINI necklace.gnh</p>
                          ) : (null)}
                          {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable ID Bracelet - ID Bold Bracelet') ? (
                            <p className='engravable_step_image_file_title'>ID BOLD Bracelet.gnh</p>
                          ) : (null)}
                          {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable ID Bracelet - ID Slim Bracelet') ? (
                            <p className='engravable_step_image_file_title'>ID SLIM Bracelet.gnh</p>
                          ) : (null)}
                          <p className='engravable_step_image_file_info'>Files can be located on the dekstop of the computer under the folder called: Engravables</p>
                        </div>
                      </div>
                      <div className='engravable_step_image'>
                        <p className='engravable_step_image_title'>Text Preview</p>
                        <div className='engravable_step_image_file'>
                          <p className='engravable_step_image_file_preview'>{initStateEngravableItems[currentEngravableItem]['engraveText']}</p>
                        </div>
                      </div>
                    </div>
                    <div className='engravable_step_buttons'>
                      <ALButton
                        type="contained"
                        onClick={() => {
                          this.setState(prevState => {
                            const initStateEngravableItems = [...prevState.initStateEngravableItems];
                            initStateEngravableItems[prevState.currentEngravableItem] = {
                              ...initStateEngravableItems[prevState.currentEngravableItem],
                              'showedJIG': false
                            };
                            return { initStateEngravableItems };
                          });
                        }}
                      >
                        Back
                      </ALButton>
                      <ALButton
                        className='al_button engravable_button_confirm'
                        type="contained"
                        onClick={() => {
                          this.setState(prevState => {
                            const initStateEngravableItems = [...prevState.initStateEngravableItems];
                            initStateEngravableItems[prevState.currentEngravableItem] = {
                              ...initStateEngravableItems[prevState.currentEngravableItem],
                              'showedPreview': true,
                              'isLoading': true
                            };
                            return { initStateEngravableItems };
                          });
                          let _this = this;
                          setTimeout(function(){
                            _this.setState(prevState => {
                              const initStateEngravableItems = [...prevState.initStateEngravableItems];
                              initStateEngravableItems[prevState.currentEngravableItem] = {
                                ...initStateEngravableItems[prevState.currentEngravableItem],
                                'isLoading': false
                              };
                              return { initStateEngravableItems };
                            });
                          // }, 10000);
                        }, 500);
                        }}
                      >
                        Confirm
                      </ALButton>
                    </div>
                  </div>
                ) : (
                  // STEP 3
                  (
                    initStateEngravableItems[currentEngravableItem]['name'] &&
                    initStateEngravableItems[currentEngravableItem]['showedJIG'] &&
                    initStateEngravableItems[currentEngravableItem]['showedPreview'] &&
                    !initStateEngravableItems[currentEngravableItem]['showedEngraving'] &&
                    !initStateEngravableItems[currentEngravableItem]['showedConfirm'] &&
                    !initStateEngravableItems[currentEngravableItem]['showBarCode'] &&
                    !initStateEngravableItems[currentEngravableItem]['isDone']
                  ) ? (
                    <div className='engravable_step'>
                      <div className='engravable_step_engraving'>
                        <p className='engravable_step_engraving_title'>Engraving</p>
                        <div className='engravable_step_engraving_loading'>
                          <ALLoading />
                        </div>
                      </div>
                      <div className='engravable_step_buttons_single'>
                        <ALButton
                          className={`al_button ${(initStateEngravableItems[currentEngravableItem]['isLoading']) ? 'engravable_button_disabled' : 'engravable_button_confirm'}`}
                          type="contained"
                          onClick={() => {
                            if (!initStateEngravableItems[currentEngravableItem]['isLoading']) {
                              this.setState(prevState => {
                                const initStateEngravableItems = [...prevState.initStateEngravableItems];
                                initStateEngravableItems[prevState.currentEngravableItem] = {
                                  ...initStateEngravableItems[prevState.currentEngravableItem],
                                  'showedEngraving': true
                                };
                                return { initStateEngravableItems };
                              });
                            }
                          }}
                          disabled={initStateEngravableItems[currentEngravableItem]['isLoading']}
                        >
                          Confirm
                        </ALButton>
                      </div>
                    </div>
                  ) : (
                    // STEP 4
                    (
                      initStateEngravableItems[currentEngravableItem]['name'] &&
                      initStateEngravableItems[currentEngravableItem]['showedJIG'] &&
                      initStateEngravableItems[currentEngravableItem]['showedPreview'] &&
                      initStateEngravableItems[currentEngravableItem]['showedEngraving'] &&
                      !initStateEngravableItems[currentEngravableItem]['showedConfirm'] &&
                      !initStateEngravableItems[currentEngravableItem]['showBarCode'] &&
                      !initStateEngravableItems[currentEngravableItem]['isDone']
                    ) ? (
                      <div className='engravable_step'>
                        <div className='engravable_step_engraving'>
                          <p className='engravable_step_engraving_title'>Confirm?</p>
                          <div className='engravable_step_engraving_confirm'>
                            <div className='engravable_step_engraving_confirm_img'>
                              {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Bar Necklace - ID Slim Necklace') ? (
                                <img className='engravable_step_1_image_img' src={slimNecklacePreview} />
                              ) : (null)}
                              {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Circle Pendant - Circle') ? (
                                <img className='engravable_step_1_image_img' src={circlePreview} />
                              ) : (null)}
                              {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Dog Tag Necklace - Dog Tag Medium') ? (
                                <img className='engravable_step_1_image_img' src={DogTagMediumPreview} />
                              ) : (null)}
                              {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable Dog Tag Necklace - Dog Tag Mini') ? (
                                <img className='engravable_step_1_image_img' src={DogTagMiniPreview} />
                              ) : (null)}
                              {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable ID Bracelet - ID Bold Bracelet') ? (
                                <img className='engravable_step_1_image_img' src={IDBoldBraceletPreview} />
                              ) : (null)}
                              {(initStateEngravableItems[currentEngravableItem]['name'] == 'Engravable ID Bracelet - ID Slim Bracelet') ? (
                                <img className='engravable_step_1_image_img' src={IDSlimBraceletPreview} />
                              ) : (null)}
                            </div>
                            <span className='engravable_step_engraving_confirm_preview'>{initStateEngravableItems[currentEngravableItem]['engraveText']}</span>
                          </div>
                        </div>
                        <div className='engravable_step_buttons'>
                          <ALButton
                            type="contained"
                            onClick={() => {
                              this.setState(prevState => {
                                const initStateEngravableItems = [...prevState.initStateEngravableItems];
                                initStateEngravableItems[prevState.currentEngravableItem] = {
                                  ...initStateEngravableItems[prevState.currentEngravableItem],
                                  'showedJIG': false,
                                  'showedPreview': false,
                                  'showedEngraving': false
                                };
                                return { initStateEngravableItems };
                              });
                            }}
                          >
                            NO
                          </ALButton>
                          <ALButton
                            className='al_button engravable_button_confirm'
                            type="contained"
                            onClick={() => {
                              this.setState(prevState => {
                                const initStateEngravableItems = [...prevState.initStateEngravableItems];
                                initStateEngravableItems[prevState.currentEngravableItem] = {
                                  ...initStateEngravableItems[prevState.currentEngravableItem],
                                  'showedConfirm': true
                                };
                                return { initStateEngravableItems };
                              });
                            }}
                          >
                            YES
                          </ALButton>
                        </div>
                      </div>
                    ) : (
                      // STEP 5
                      (
                        initStateEngravableItems[currentEngravableItem]['name'] &&
                        initStateEngravableItems[currentEngravableItem]['showedJIG'] &&
                        initStateEngravableItems[currentEngravableItem]['showedPreview'] &&
                        initStateEngravableItems[currentEngravableItem]['showedEngraving'] &&
                        initStateEngravableItems[currentEngravableItem]['showedConfirm'] &&
                        !initStateEngravableItems[currentEngravableItem]['showBarCode'] &&
                        !initStateEngravableItems[currentEngravableItem]['isDone']
                      ) ? (
                        <div className='engravable_step'>
                          <div className='engravable_step_engraving'>
                            <p className='engravable_step_engraving_title'>Print barcode label?</p>
                            <div id='barcode' className='engravable_step_engraving_print'>
                              <Barcode
                                value={initStateEngravableItems[currentEngravableItem]['barcode']}
                                width={4}
                                height={100}
                                displayValue={true}
                              />
                            </div>
                          </div>

                          <iframe id="ifmcontentstoprint" style={{height: '0px', width: '0px', position: 'absolute'}}></iframe>

                          <div className='engravable_step_buttons' style={{ alignItems: 'selfEnd'}}>
                            <ALButton
                            className='al_button engravable_button_no'
                              type="contained"
                              onClick={() => {
                                this.setState(prevState => {
                                  const initStateEngravableItems = [...prevState.initStateEngravableItems];
                                  initStateEngravableItems[prevState.currentEngravableItem] = {
                                    ...initStateEngravableItems[prevState.currentEngravableItem],
                                    'showedJIG': false,
                                    'showedPreview': false,
                                    'showedEngraving': false,
                                    'showedConfirm': false,
                                    'userPrinted': false,
                                  };
                                  return { initStateEngravableItems };
                                });
                              }}
                            >
                              REDO
                            </ALButton>
                            <div className='engravable_buttons_finish'>
                              <ALButton
                                className='al_button engravable_button_confirm'
                                type="contained"
                                onClick={() => {
                                  this.setState(prevState => {
                                    const initStateEngravableItems = [...prevState.initStateEngravableItems];
                                    initStateEngravableItems[prevState.currentEngravableItem] = {
                                      ...initStateEngravableItems[prevState.currentEngravableItem],
                                      'userPrinted': true
                                    };
                                    return { initStateEngravableItems };
                                  });

                                  var content = document.getElementById("barcode");
                                  var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                                  pri.document.open();
                                  pri.document.write(content.innerHTML);
                                  pri.document.close();
                                  pri.focus();
                                  pri.print();
                                }}
                              >
                                PRINT
                              </ALButton>

                              <ALButton
                                className={`al_button ${(!initStateEngravableItems[currentEngravableItem]['userPrinted']) ? 'engravable_button_disabled' : 'engravable_button_next'}`}
                                disabled={!initStateEngravableItems[currentEngravableItem]['userPrinted']}
                                type="contained"
                                onClick={() => {
                                  if (!initStateEngravableItems[currentEngravableItem]['userPrinted']) {
                                    alert('PLEASE PRINT THE BARCODE BEFORE BEFORE GOING TO THE NEXT PRODUCT!');
                                  } else {
                                    if ((currentEngravableItem + 1) === initStateEngravableItems.length) {
                                      // next order
                                        // => reset state
                                      this.setState({
                                        step: 0,
                                        isLoadingOrder: false,
                                        numberEngravableItem: null,
                                        order: null,
                                        initStateEngravableItems: null,
                                        currentEngravableItem: null,
                                      });
                                    } else {
                                      // next product to engrave
                                      this.setState({currentEngravableItem: this.state.currentEngravableItem + 1});
                                    }
                                  }
                                }}
                              >
                                {((currentEngravableItem + 1) === initStateEngravableItems.length) ? (
                                  "NEXT ORDER"
                                ) : (
                                  "NEXT PRODUCT"
                                )}
                              </ALButton>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p>TODO</p>
                      )
                    )
                  )
                )
              )
            ) : (
              <p>Error #101</p>
            )}
          </>
        ) : (null)}
      </div>
    );
  }
}

export default EngravablesContainer;
